import {
  S3Client,
  CreateMultipartUploadCommand,
  UploadPartCommand,
  CompleteMultipartUploadCommand,
  ListPartsCommand,
} from '@aws-sdk/client-s3';
import * as AWS from 'aws-sdk';
import S3ClientSingleton from './s3ClientSingleton';

import { HashWorkerPool } from './hash-worker-pool';

// eslint-disable-next-line no-unused-vars
const hashWorkerPool = new HashWorkerPool(new URL('./hash-worker.ts', import.meta.url), 4);

// eslint-disable-next-line no-unused-vars
const CryptoJS = require('crypto-js');

// eslint-disable-next-line import/no-extraneous-dependencies, no-unused-vars
const asyncBatch = require('async-batch').default;

type GetCredentialsFunction = () => Promise<AWS.Credentials | null>;

export type UploadInput = {
  file: File,
  bucket: string,
  partSize: number,
  // eslint-disable-next-line no-unused-vars
  onProgress: (progress: number) => void,
  getCredentials: GetCredentialsFunction,
  onUploadFail?: () => void,
  // eslint-disable-next-line no-unused-vars
  onUploadSuccess?: (fileName: string) => void,
  key?: string,
  // eslint-disable-next-line no-unused-vars
  getUploadIdFromExternalSource?: (fileName: string) => Promise<string | null> | null,
  // eslint-disable-next-line no-unused-vars
  saveUploadIdToExternalStorage?: (uploadId: string, fileName: string) => Promise<void> | null,
}

export class MultipartUploader {
    private s3ClientSingleton: S3ClientSingleton;
    private file: File;
    private bucket: string;
    private key?: string;
    private uploadId: string | null = null;
    private partSize: number;
    private partETags: { PartNumber: number; ETag: string, ChecksumSHA256: string }[] = [];
    private failedParts: {partNumber: number, filePart: Blob}[] = [];
    private uploadedSize: number = 0;
    private localStorageKey: string;
    private retryCounter: number = 0;
    private maxRetries: number = 3;

    // eslint-disable-next-line no-unused-vars
    private onProgress: (progress: number) => void;
    private onUploadFail: (() => void) | undefined;
    // eslint-disable-next-line no-unused-vars
    private onUploadSuccess: ((fileName: string) => void) | undefined;

    // eslint-disable-next-line no-unused-vars
    private getUploadIdFromExternalSource?: ((fileName: string) => Promise<string | null> | null) | undefined;
    // eslint-disable-next-line no-unused-vars
    private saveUploadIdToExternalStorage?: ((uploadId: string, fileName: string) => Promise<void> | null) | undefined;

    constructor(
      uploadInput: UploadInput,
    ) {
      this.s3ClientSingleton = S3ClientSingleton.getInstance(uploadInput.getCredentials);
      this.file = uploadInput.file;
      this.bucket = uploadInput.bucket;
      this.partSize = uploadInput.partSize;
      this.onProgress = uploadInput.onProgress;
      this.onUploadFail = uploadInput.onUploadFail;
      this.onUploadSuccess = uploadInput.onUploadSuccess;
      this.localStorageKey = `multipart-upload-${this.file.name}-${this.file.size}`;
      this.key = uploadInput.key;
      this.getUploadIdFromExternalSource = uploadInput.getUploadIdFromExternalSource;
      this.saveUploadIdToExternalStorage = uploadInput.saveUploadIdToExternalStorage;
    }

    async startUpload(): Promise<void> {
      try {
        console.log('Want to start upload');
        const existingUploadId = await this.getUploadIdFromStorage(this.file.name);
        console.log('existingUploadId :>> ', existingUploadId);

        if (existingUploadId) {
          this.uploadId = existingUploadId;
          await this.listUploadedPartsAndResume();
        } else {
          await this.createNewMultipartUpload();
        }
        if (!this.notAllPartsUploaded()) this.onUploadSuccess?.(this.file.name);
      } catch (error) {
        console.error(error);
        if (await this.retry()) {
          console.log('Retry succeeded. Restarting upload.');
          this.startUpload();
        } else {
          console.error('Retry failed. Upload failed.');
          this.onUploadFail?.();
        }
      }
    }

    private async createNewMultipartUpload(): Promise<void> {
      const createCommand = new CreateMultipartUploadCommand({
        Bucket: this.bucket,
        Key: this.key,
        ChecksumAlgorithm: 'SHA256',
      });
      const response = await (await this.s3ClientSingleton.getS3Client())?.send(createCommand);
      if (!response) {
        throw new Error('No response from createCommand');
      }
      this.uploadId = response?.UploadId!;
      console.log('new this.uploadId :>> ', this.uploadId);

      this.saveUploadIdToStorage(this.uploadId);

      await this.uploadParts();
    }

    private async listUploadedPartsAndResume(): Promise<void> {
      const listPartsCommand = new ListPartsCommand({
        Bucket: this.bucket,
        Key: this.key,
        UploadId: this.uploadId!,
      });

      const response = await (await this.s3ClientSingleton.getS3Client())?.send(listPartsCommand);
      if (!response) {
        throw new Error('No response from listPartsCommand');
      }
      console.log('response in listUploadedPartsAndResume :>> ', response);
      const uploadedParts = response.Parts || [];
      console.log('uploadedParts :>> ', uploadedParts);

      this.partETags = uploadedParts.map((part) => ({
        PartNumber: part.PartNumber!,
        ETag: part.ETag!,
        ChecksumSHA256: part.ChecksumSHA256!,
      }));

      this.uploadedSize = uploadedParts.reduce((sum, part) => sum + part.Size!, 0);
      this.onProgress((this.uploadedSize / this.file.size) * 100);

      const lastUploadedPartNumber: number = parseInt(response.NextPartNumberMarker!, 10);
      const missingPartObjects: {partNumber: number, filePart: Blob, instance: any }[] = this.checkAndHandleMissingParts(lastUploadedPartNumber);

      await this.uploadParts(lastUploadedPartNumber + 1, missingPartObjects);
    }

    private checkAndHandleMissingParts(lastUploadedPartNumber: number): {partNumber: number, filePart: Blob, instance: any }[] {
      const missingParts = this.findMissingParts(lastUploadedPartNumber);
      if (missingParts.length === 0) return [];

      const missingPartObjects: {partNumber: number, filePart: Blob, instance: any }[] = this.handleMissingParts(missingParts);
      return missingPartObjects;
    }

    private findMissingParts(lastUploadedPart: number): number[] {
      const uploadedPartNumbers = this.partETags.map((part) => part.PartNumber);
      const missingParts: number[] = [];

      for (let i = 1; i <= lastUploadedPart; i += 1) {
        if (!uploadedPartNumbers.includes(i)) {
          missingParts.push(i);
        }
      }

      return missingParts;
    }

    private handleMissingParts(missingParts: number[]): { partNumber: number, filePart: Blob, instance: any }[] {
      // eslint-disable-next-line no-unused-vars
      const missingPartPromises: Promise<void>[] = [];
      const missingPartObjects: { partNumber: number, filePart: Blob, instance: any }[] = [];
      console.log('Creating missing part promises:', missingParts);
      for (const missingPartNumber of missingParts) {
        const start = (missingPartNumber - 1) * this.partSize;
        const end = Math.min(start + this.partSize, this.file.size);
        // eslint-disable-next-line no-unused-vars
        const filePart = this.file.slice(start, end);

        const instance = this;
        // missingPartPromises.push(this.uploadPart(missingPartNumber, filePart));
        missingPartObjects.push({ partNumber: missingPartNumber, filePart, instance });
      }
      // return missingPartPromises;
      return missingPartObjects;
    }

    private async uploadParts(startPartNumber: number = 1, missingPartObjects: { partNumber: number, filePart: Blob, instance: any }[] = []): Promise<void> {
      console.log('missingPartObjects :>> ', missingPartObjects);
      // eslint-disable-next-line no-unused-vars
      const totalParts = Math.ceil(this.file.size / this.partSize);
      // eslint-disable-next-line no-unused-vars
      const parts: {partNumber: any, filePart: any, instance: any}[] = missingPartObjects;

      for (let partNumber = startPartNumber; partNumber <= totalParts; partNumber += 1) {
        const start = (partNumber - 1) * this.partSize;
        const end = Math.min(start + this.partSize, this.file.size);
        const filePart = this.file.slice(start, end);
        const instance = this;
        parts.push({ partNumber, filePart, instance });
      }
      
      await asyncBatch(parts, this.uploadPart, 100);

      if (this.checkForFailedParts()) {
        await this.retryFailedParts();
      }
      if (this.notAllPartsUploaded()) {
        console.error('Stooping completion. Some parts failed to upload.');
        this.onUploadFail?.();
        return;
      }

      await this.completeUpload();
    }

    // eslint-disable-next-line class-methods-use-this
    private async getChecksumInWorker(partBuffer: ArrayBuffer): Promise<string> {
      return new Promise((resolve, reject) => {
        const worker = new Worker(new URL('./hash-worker-basic.ts', import.meta.url), {
          type: 'module',
        });
        
        worker.onmessage = (event) => {
          const { checksumSHA256 } = event.data;
          resolve(checksumSHA256);
          worker.terminate();
        };
    
        worker.onerror = (error) => {
          reject(error);
          worker.terminate();
        };
    
        worker.postMessage({ partBuffer }, [partBuffer]); 
      });
    }

    // eslint-disable-next-line class-methods-use-this
    private async uploadPart({ partNumber, filePart, instance }: { partNumber: number; filePart: Blob; instance:any }): Promise<void> {
      try {
        // console.log('comp :>> ', comp);
        // console.log('comp.calculateSHA256Base64 :>> ', comp.calculateSHA256Base64);
        const partBuffer: ArrayBuffer = await filePart.arrayBuffer();
        // const wordArray = CryptoJS.lib.WordArray.create(partBuffer);
        // const checksumSHA256: string = CryptoJS.SHA256(wordArray).toString(CryptoJS.enc.Base64);
        // const checksumSHA256 = await this.getChecksumInWorker(partBuffer);
        const checksumSHA256 = await instance.getChecksumInWorker(partBuffer);
        // const checksumSHA256 = await hashWorkerPool.enqueueHashJob(partBuffer);

        const uploadPartCommand = new UploadPartCommand({
          Bucket: instance.bucket,
          Key: instance.key,
          UploadId: instance.uploadId!,
          PartNumber: partNumber,
          Body: filePart,
          ChecksumSHA256: checksumSHA256,
        });

        console.log('Uploading part: ', partNumber);

        const response = await (await instance.s3ClientSingleton.getS3Client())?.send(uploadPartCommand);
        if (!response) {
          throw new Error('No response from uploadPartCommand');
        }

        instance.partETags.push({ PartNumber: partNumber, ETag: response.ETag!, ChecksumSHA256: checksumSHA256 });

        // eslint-disable-next-line no-param-reassign
        instance.uploadedSize += filePart.size;
        instance.onProgress((instance.uploadedSize / instance.file.size) * 100);
      } catch (err) {
        console.error('Error uploading part:', err);
        instance.failedParts.push({ partNumber, filePart });
      }
    }

    private async completeUpload(): Promise<void> {
      try {
        this.partETags.sort((a, b) => a.PartNumber - b.PartNumber);
        console.log('this.partETags :>> ', this.partETags);

        const completeCommand = new CompleteMultipartUploadCommand({
          Bucket: this.bucket,
          Key: this.key,
          UploadId: this.uploadId!,
          MultipartUpload: {
            Parts: this.partETags.map(({ PartNumber, ETag, ChecksumSHA256 }) => ({
              PartNumber,
              ETag,
              ChecksumSHA256,
            })), 
          },
        });

        const response = await (await this.s3ClientSingleton.getS3Client())?.send(completeCommand);
        if (!response) {
          throw new Error('No response from completeMultipartUploadCommand');
        }
        console.log('Upload completed:', response);

        this.removeUploadIdFromStorage();
      } catch (err) {
        console.error('Error completing upload:', err);
      }
    }

    private checkForFailedParts(): boolean {
      return this.failedParts.length > 0;
    }

    private async retryFailedParts(): Promise<void> {
      try {
        console.log('this.failedParts :>> ', this.failedParts);
        const retryStatus = this.retry();
        if (!retryStatus) throw new Error('Retry failed');
        const partToRetry: { partNumber: number; filePart: Blob; instance:any }[] = [];
  
        for (const failedPart of this.failedParts) {
          const instance = this;
          partToRetry.push({ partNumber: failedPart.partNumber, filePart: failedPart.filePart, instance });
        }
  
        await asyncBatch(partToRetry, this.uploadPart, 100);
      } catch (error) {
        console.error('Failed in retryFailedParts');
        console.error(error);
      }
    }

    private saveUploadIdToStorage(uploadId: string): void {
      if (this.saveUploadIdToExternalStorage) {
        this.saveUploadIdToExternalStorage(uploadId, this.file.name);
        return;
      }
      localStorage.setItem(this.localStorageKey, uploadId);
    }

    private async getUploadIdFromStorage(fileName: string): Promise<string | null> {
      if (this.getUploadIdFromExternalSource) return this.getUploadIdFromExternalSource(fileName);
      return localStorage.getItem(this.localStorageKey);
    }

    private removeUploadIdFromStorage(): void {
      localStorage.removeItem(this.localStorageKey);
    }

    private notAllPartsUploaded(): boolean {
      return this.uploadedSize < this.file.size;
    }

    private async retry(): Promise<boolean> {
      while (this.retryCounter < this.maxRetries) {
        this.retryCounter += 1;
        const newS3Client: S3Client | null = await this.s3ClientSingleton.refreshClient();
        if (newS3Client) {
          return this.retrySucceeded();
        }
        await this.wait(1000);
      }
      console.error(`After ${this.maxRetries} retries, upload failed.`);
      return this.retryFailed();
    }

    // eslint-disable-next-line class-methods-use-this
    private retrySucceeded(): boolean {
      this.retryCounter = 0;
      return true;
    }


    private retryFailed(): boolean {
      console.error(`After ${this.maxRetries} retries, upload failed.`);
      return false;
    }

    setKey(key: string): void {
      this.key = key;
    }

    // eslint-disable-next-line class-methods-use-this
    private async wait(time: number): Promise<void> {
      // eslint-disable-next-line no-promise-executor-return
      return new Promise((resolve) => setTimeout(resolve, time));
    }
}
