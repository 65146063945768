
import {
  validateString, validateFloat, validateInteger,
} from '@/utils';
import { defineComponent } from 'vue';
import {
  EntityQAObject, ExperimentSpecificVariable, entryESV, // sampleESV, // MetadataGeneralTemplate, // AnalysisType,
} from '@/models/customModels';
import * as customModels from '@/models/customModels';
// eslint-disable-next-line import/no-extraneous-dependencies, no-unused-vars
import {
  MetaDataValidationSchemaTemplate, Phase, Study, StudyPhase,
} from '@/models';

import { graphqlOperation, API } from 'aws-amplify';
import * as customMutations from '@/graphql/customMutations';

export default defineComponent({
  name: 'Samples Dialog',
  emits: ['sampleMetadata'],

  props: {
    studyPhaseForUpdate: { type: StudyPhase, required: false },
    sampleMetadata: { type: Object, required: false },
    analysisType: { type: Object, required: false },
    parsedValidationSchema: { type: Object, required: false },
    metadataAnalysis: { type: String, required: false },
    expSpecVariables: { type: Object, required: false },
  },
  data() {
    return {
      loading: false as boolean,
      showing: false as boolean,
      entryValudation: false as boolean,
      description: null as unknown as string | undefined,
      studyPhaseName: null as unknown as string | null,
      availableMetadataTemplates: [] as MetaDataValidationSchemaTemplate[],
      selectedMetadataTemplate: null as unknown as MetaDataValidationSchemaTemplate,
      parentStudy: null as unknown as Study,
      activeOrgName: '',
      activeTabIndex: 0,
      phase: null as unknown as Phase | null,
      experimentSpecificVariables: [
        {
          name: '',
          type: '',
          description: '',
          value: null,
        },
      ] as ExperimentSpecificVariable[],
      questions: {} as unknown as EntityQAObject | null,
      questionData: {} as { [key: string] : string },
      columnData: null as unknown,
      columnsMetadata: [] as any,
      validationSch: null as any,
      updateMetadataTemplate: {} as any, // customModels.MetadataRnaSeqTemplate,
      sampleTemplate: {} as customModels.SampleTemplate,
      metadataGeneral: null as any,
      parsedVariables: {} as any,
      dropdownOptions: [{ name: 'True', value: 'true' },
        { name: 'False', value: 'false' }],
      dropdown: null as any,
    };
  },
  async beforeMount() {
    await this.main();
  },
  methods: {
    async main() {
      if (this.sampleMetadata !== null && this.sampleMetadata !== undefined) {
        console.log('in component');
        console.log(this.sampleMetadata);
        console.log(this.parsedValidationSchema);
        console.log(this.analysisType);
        console.log(this.expSpecVariables);
        this.parseExpSpecVar(this.expSpecVariables, this.sampleMetadata.experimentSpecificVariables);
      }
      console.log(this.metadataGeneral);
      return null;
    },
    async parseExpSpecVar(studyPhaseESV: any, experimentSpecificVariables: any) {
      let parsedVariables = {} as any;
      type ObjectKey = keyof typeof parsedVariables;
      if (experimentSpecificVariables !== null) {
        console.log(experimentSpecificVariables);
        parsedVariables = JSON.parse(experimentSpecificVariables);
        studyPhaseESV.forEach((variable: any) => {
          const entry = {} as entryESV;
          const name = variable.name as keyof ObjectKey;
          if (parsedVariables[name] === undefined) {
            entry.type = variable.type;
            entry.description = variable.description;
            entry.value = variable.value;
            parsedVariables[name] = entry;
            console.log('here', entry);
          } else if (parsedVariables[name].type === 'boolean') {
            if (parsedVariables[name].value) {
              this.dropdown = { name: 'True', value: 'true' };
            } else {
              this.dropdown = { name: 'False', value: 'false' };
            }
          }
        });
      } else {
        studyPhaseESV.forEach((variable: any) => {
          const entry = {} as entryESV;
          const name = variable.name as keyof ObjectKey;
          entry.type = variable.type;
          entry.description = variable.description;
          entry.value = variable.value;
          parsedVariables[name] = entry;
          console.log(variable);
        });
      }
      this.parsedVariables = parsedVariables;
    },
    test() {
      console.log('test');
    },
    updateValidateData(column: any, data: any, updateMetadataTemplate: any) {
      type ObjectKey = keyof typeof updateMetadataTemplate;
      const dbColumn = column.db_column_name as keyof ObjectKey;

      if (column.value_type === 'int' || column.value_type === 'float') {
        const editedValue = Number(data[dbColumn]);
        if (!isNaN(editedValue)) {
          this.entryValudation = false;
          (this.updateMetadataTemplate[dbColumn] as any) = Number(data[dbColumn]);
        } else {
          this.entryValudation = true;
          this.$toast.add({
            severity: 'error', summary: 'Error', detail: `${dbColumn} column must be ${column.value_type}.`, life: 3000,
          });
        }
      } else {
        this.updateMetadataTemplate[dbColumn] = data[dbColumn];
      }
      console.log(this.updateMetadataTemplate);
      console.log(data[dbColumn]);
      console.log(dbColumn);
      // this.$emit('input', { id, value });
      // console.log(this.updateMetadataTemplate);
    },
    async updateSampleMetadata(updateMetadataTemplate: any, sampleTemplate: any) {
      let validation = true;
      this.expSpecVariables!.forEach((column: any) => {
        const editedValue = this.parsedVariables[column.name].value;
        // console.log(editedValue);
        this.sampleMetadata![column.name] = editedValue;
        if (editedValue !== null && editedValue !== '') {
          if (column.type === 'number') {
            if (!this.validateInteger(this.parsedVariables, column.name)) {
              validation = false;
              this.$toast.add({
                severity: 'warn', summary: 'Warning', detail: `Variable "${column.name}" must be integer.`, life: 3000,
              });
            }
          } else if (column.type === 'float') {
            if (!this.validateFloat(this.parsedVariables, column.name)) {
              validation = false;
              this.$toast.add({
                severity: 'warn', summary: 'Warning', detail: `Variable "${column.name}" must be ${column.type}.`, life: 3000,
              });
            }
          } else if (column.type === 'string') {
            if (!this.validateString(this.parsedVariables, column.name)) {
              validation = false;
              this.$toast.add({
                severity: 'warn', summary: 'Warning', detail: `Variable "${column.name}" must be ${column.type}.`, life: 3000,
              });
            }
          }
        }
      });
      if (!validation) {
        return;
      }
      console.log(this.parsedVariables);
      try {
        let updatedMetadata;
        this.updateMetadataTemplate.id = this.sampleMetadata!.id;
        if (this.analysisType!.value === 'rnaseq') {
          updatedMetadata = await API.graphql(graphqlOperation(customMutations.updateMetadataRnaSeq, { input: updateMetadataTemplate }));
        } else if (this.analysisType!.value === 'nanostring') {
          updatedMetadata = await API.graphql(graphqlOperation(customMutations.updateMetadataNanoString, { input: updateMetadataTemplate }));
        } else {
          updatedMetadata = await API.graphql(graphqlOperation(customMutations.updateMetadataImmunopeptidomics, { input: updateMetadataTemplate }));
        }
        console.log(updatedMetadata);

        this.sampleTemplate.id = this.sampleMetadata!.sampleId;
        this.sampleMetadata!.experimentSpecificVariables = JSON.stringify(this.parsedVariables);
        this.sampleTemplate.experimentSpecificVariables = JSON.stringify(this.parsedVariables);
        const updatedSampleTemplate = await API.graphql(graphqlOperation(customMutations.updateSample, { input: sampleTemplate }));
        console.log(updatedSampleTemplate);
        this.$emit('sampleMetadata');
        this.$toast.add({
          severity: 'success', summary: 'Success', detail: 'Sample updated successfuly!', life: 3000,
        });
      } catch (error) {
        console.error(error);
        this.loading = false;
        this.$toast.add({
          severity: 'error', summary: 'Error', detail: 'Failed to update Sample!', life: 3000,
        });
      }
      this.hideDialog();
    },
    validateString(parsedVars: any, column: string) {
      return validateString(parsedVars as any, column as string);
    },
    validateFloat(parsedVars: any, column: string) {
      return validateFloat(parsedVars as any, column as string);
    },
    validateInteger(parsedVars: any, column: string) {
      return validateInteger(parsedVars as any, column as string);
    },
    updateDropdown(column: any) {
      (this.parsedVariables[column.name].value as any) = this.dropdown.value;
    },
    hideDialog() {
      this.studyPhaseName = null;
      this.phase = null;
      this.loading = false;
      this.activeTabIndex = 0;
      this.description = undefined;
      this.$store.dispatch('setShowingSamplesDialog', false);
      // this.parsedValidationSchema = null;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingSamplesDialog': async function () {
      this.showing = this.$store.state.showingSamplesDialog;
      if (this.showing) this.main();
    },
  },
});
