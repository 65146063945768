
// eslint-disable-next-line import/no-extraneous-dependencies, no-unused-vars
// @ts-ignore
import DraggableResizableVue from 'draggable-resizable-vue3';
// eslint-disable-next-line import/no-extraneous-dependencies
// @ts-ignore
import { v4 as uuidV4 } from 'uuid';
import Visualization from '@/components/Visualization/Visualization.vue';
import { defineComponent } from 'vue';
import { setPrecedence, getHomeRouteForBreadcrumb, getOrgNameAndIdForRoute } from '@/utils';
import VisualizationLaunchDialog from '@/components/Visualization/VisualizationLaunchDialog.vue';
import * as customSubscriptions from '@/graphql/customSubscriptions';
import { API, graphqlOperation } from 'aws-amplify';
import { Pipeline, VisualizationSession } from '@/models';
import {
  OpenVisualization, Visualizations, CustomVisualizationSession, PipelineTemplate, 
} from '@/models/customModels';
import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';

const CryptoJS = require('crypto-js');

export default defineComponent({
  name: 'Visualize',
  props: ['passedPipeline', 'passedVisualization'],
  components: { Visualization, DraggableResizableVue, VisualizationLaunchDialog },
  data() {
    return {
      breadcrumbHome: { icon: 'pi pi-home', to: getHomeRouteForBreadcrumb(this.$route) },
      breadcrumbItems: [] as {label: string, to: string}[],
      openVisualizationsMap: {} as { [key: string] : OpenVisualization },
      projectForPipelineTable: null,
      visualizationToAddId: null as string | null,
      loading: false,
      vizSessionSubscription: null as any,
      savingSession: false as boolean,
      sessionDescription: null as unknown as string | undefined,
      sessionId: null as unknown as string | null,
      sessionAction: null as unknown as string | null,
    };
  },
  async mounted() {
    console.log('Mounted in wrapper');
    console.log('this.$route.params :>> ', this.$route.params);
    console.log('this.passedVisualization :>> ', this.passedVisualization);
    console.log('this.passedPipeline :>> ', this.passedPipeline);
    console.log('this.$route.params.vizDataToken :>> ', this.$route.params.vizDataToken);
    this.main();
  },
  methods: {
    async main() {
      this.loading = true;
      if (this.$route.params.vizDataToken) {
        if (this.$route.params.vizDataToken[0].length > 36) {
          await this.tokenPassedWorkflow(this.$route.params.vizDataToken[0]);
        } else {
          await this.loadedSessionWorkflow(this.$route.params.vizDataToken[0]);
        }

        this.loading = false;
        return;
      }
      if (this.passedPipeline && this.passedVisualization) {
        this.populatePipelineAndVisualization(this.passedPipeline, this.passedVisualization);
        this.loading = false;
      }
    },
    async tokenPassedWorkflow(vizDataToken: string) {
      console.log('vizDataToken :>> ', vizDataToken);
      await setPrecedence();
      const cipherText = vizDataToken.replace(/!/g, '/');
      const secretKey = process.env.VUE_APP_ROUTE_ENCRYPTION_KEY;
      const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
      const originalText = bytes.toString(CryptoJS.enc.Utf8);
      const data = JSON.parse(originalText);
      console.log('data :>> ', data);
      this.breadcrumbItems = this.populateBreadcrumbItems(data.pipelines);
      this.populatePipelineAndVisualization(data.pipelines, data.visualization);
    },
    populatePipelineAndVisualization(pipelines: Pipeline[], visualization: Visualizations) {
      if (!pipelines || !visualization) console.error('Viz or pipeline is null');
      this.addBasicViz({ visualization, pipelines });
    },
    addBasicViz({ visualization, pipelines }: { visualization: Visualizations, pipelines: Pipeline[] }) {
      const id: string = uuidV4();
      this.openVisualizationsMap[id] = {
        visualization,
        pipelines,
        w: this.calculateInitialWidth(),
        h: this.calculateInitialHeight(),
        x: 0,
        y: this.calculateY(),
        draggable: false,
      };
      this.extendParent();
      console.log('this.openVisualizationsMap :>> ', this.openVisualizationsMap);
    },
    addPreBuiltViz(id: string, openVisualization: OpenVisualization) {
      this.openVisualizationsMap[id] = openVisualization;
      this.extendParent();
      console.log('this.openVisualizationsMap :>> ', this.openVisualizationsMap);
    },
    addNewVisualization() {
      try {
        console.log('Adding');
        this.$store.dispatch('setShowingLaunchVisualizationDialog', true);
      } catch (error) {
        console.error(error);
      }
    },
    visualizationAdded(data: { visualization: Visualizations, pipelines: Pipeline[] }) {
      this.visualizationToAddId = uuidV4();
      this.addVisualization(data);
    },
    addVisualization(dataToVisualize: { visualization: Visualizations, pipelines: Pipeline[] }) {
      let w = this.calculateInitialWidth();
      let x = 0;
      let y = this.calculateY();
      let h = this.calculateInitialHeight();
      if (Object.keys(this.openVisualizationsMap).length % 2 === 1) {
        const vizBefore = Object.values(this.openVisualizationsMap).slice(-1)[0];
        w /= 2;
        x = vizBefore.x + w;
        y = vizBefore.y as number;
        vizBefore.w = vizBefore.w ?? 0 / 2;
        h -= 15; // -15 pixels for the button offset
      }
      if (!this.visualizationToAddId) throw new Error('Viz to add Id is null');
      this.openVisualizationsMap[this.visualizationToAddId] = {
        visualization: dataToVisualize.visualization,
        pipelines: dataToVisualize.pipelines,
        w,
        h,
        x,
        y,
        draggable: false,
      };
      this.extendParent();
      this.visualizationToAddId = null;
    },
    extendParentByNPixels(n: number) {
      const newParentHeight = parseInt((this.$refs.par as any).style.height.replace('px', ''), 10) + n;
      (this.$refs.par as any).style.height = `${newParentHeight}px`;
    },
    calculateY() {
      return this.getLowestVisBottom(this.openVisualizationsMap) + 15; // +15 pixels for the button offset
    },
    // onResizeCallback(handle, x, y, width, height) {
    onResizeCallback() {
      return this.extendParent();
    },
    // eslint-disable-next-line no-unused-vars
    // onDragCallback(x, y) {
    onDragCallback() {
      return this.extendParent();
    },
    extendParent() {
      this.extendParentHeightByLowestChild();
      if (!this.extendParentWidthByRightestChild()) return false;
      return true;
    },
    extendParentHeightByLowestChild() {
      const lowestChildPoint = this.getLowestVisBottom(this.openVisualizationsMap);
      (this.$refs.par as any).style.height = `${lowestChildPoint}px`;
      return true;
    },
    extendParentWidthByRightestChild() {
      const rightestChildPoint = this.getRightestVis(this.openVisualizationsMap);
      (this.$refs.par as any).style.width = `${rightestChildPoint}px`;
      document.body.style.width = `${rightestChildPoint + 60}px`;
      (document.body.style as any)['min-width'] = '99.5vw';
      return true;
    },
    getLowestVisBottom(openVisualizationsMap: { [key: string] : OpenVisualization }) {
      let max = -1;
      // eslint-disable-next-line no-unused-vars
      for (const [id, value] of Object.entries(openVisualizationsMap)) {
        const res = (value.y ?? 0) + (value.h ?? 0);
        if (res > max) {
          max = res;
        }
      }
      return Math.max(0, max);
    },
    getRightestVis(openVisualizationsMap: { [key: string] : OpenVisualization }) {
      let max = -1;
      // eslint-disable-next-line no-unused-vars
      for (const [id, value] of Object.entries(openVisualizationsMap)) {
        const res = (value.x ?? 0) + (value.w ?? 0);
        if (res > max) {
          max = res;
        }
      }
      return Math.max(0, max);
    },
    getMaxScreenWidth() {
      // return document.body.offsetWidth;
      return 300;
    },
    calculateInitialHeight() {
      try {
        if ((this.$refs.par as any).offsetHeight === 0) return window.innerHeight;
        return (this.$refs.par as any).offsetHeight;
      } catch (error) {
        console.error('Bad calculateInitialHeight');
        return 100;
      }
    },
    calculateInitialWidth() {
      try {
        if (this.$refs.par && (this.$refs.par as any).offsetWidth === 0) return window.innerWidth;
        return (this.$refs.par as any).offsetWidth;
      } catch (error) {
        console.error('Bad calculateInitialWidth');
        return 100;
      }
    },
    closeVis(id: string) {
      try {
        const fullWidth = this.calculateInitialWidth();
        if (Object.keys(this.openVisualizationsMap).length % 2 === 0) {
          const prevVisIndex = this.getPrevVisIndex(Object.keys(this.openVisualizationsMap), id);
          if (!prevVisIndex) throw new Error('prevVisIndex is null');
          this.checkPrevVis(prevVisIndex, fullWidth);
          this.checkNextVis(prevVisIndex + 1, fullWidth);
        }
        if (this.openVisualizationsMap[id]?.w === fullWidth && this.isNotLastViz()) {
        // eslint-disable-next-line no-unsafe-optional-chaining
          const height: number = this.openVisualizationsMap[id]?.h as number;
          this.extendParentByNPixels(-height);
        }
      } catch (error) {
        console.error(error);
      }
      delete this.openVisualizationsMap[id];
    },
    checkPrevVis(prevVisIndex: number, fullWidth: number) {
      try {
        const prevVis = this.openVisualizationsMap[Object.keys(this.openVisualizationsMap)[prevVisIndex]];
        if (!prevVis) return;
        if (prevVis.w === (fullWidth / 2)) prevVis.w *= 2;
      } catch (error) {
        console.error(error);
      }
    },
    checkNextVis(nextIndex: number, fullWidth: number) {
      try {
        const nextVis = this.openVisualizationsMap[Object.keys(this.openVisualizationsMap)[nextIndex]];
        if (!nextVis) return;
        if (nextVis.w === (fullWidth / 2)) {
          nextVis.w *= 2;
          nextVis.x = nextVis.x ?? 0;
        }
      } catch (error) {
        console.error(error);
      }
    },
    getPrevVisIndex(visIds: string[], visId: string) {
      try {
        let prevInd = -1;
        let currInd = 0;
        while (visIds[currInd] !== visId) {
          prevInd = currInd;
          currInd += 1;
          if (currInd === visIds.length) break;
        }
        if (prevInd > -1) return prevInd;
        return null;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    isNotLastViz() {
      return Object.keys(this.openVisualizationsMap).length > 1;
    },
    toggleOverlayPanel(event: any) {
      (this.$refs.op as any).toggle(event);
    },
    setVizToken({ id, token }: {id: string, token : string}) {
      this.openVisualizationsMap[id].token = token;
    },
    async loadedSessionWorkflow(sessionId: string) {
      const session: VisualizationSession = (await API.graphql(graphqlOperation(queries.getVisualizationSession, { id: sessionId })) as any).data.getVisualizationSession;
      if (!session || !session.data) {
        console.error('Session or session data is null');
        return;
      }
      const data: { [key: string] : OpenVisualization } = JSON.parse(session.data as string);
      this.openVisualizationsMap = {}; // Test
      for (const [id, openVisualization] of Object.entries(data)) {
        this.addPreBuiltViz(id, openVisualization);
      }
      this.addSessionInformationToVisualizations(sessionId, 'loadSession');
    },
    async saveSession(): Promise<void> {
      this.savingSession = true;
      this.toggleOverlayPanel({});
      try {
        const vizSession: VisualizationSession | null = await this.createVizSession();
        if (!vizSession) {
          console.error('Error with session');
          return;
        }
        this.vizSessionSubscription = this.startVizSessionSubscription(vizSession.id);
        this.addSessionInformationToVisualizations(vizSession.id, 'saveSession');
      } catch (error) {
        console.error(error);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to save session!',
          life: 3000,
        });
        this.unsubscribe(this.vizSessionSubscription);
        this.savingSession = false;
      }
    },
    startVizSessionSubscription(sessionId: string): void {
      return (API.graphql(
        graphqlOperation(customSubscriptions.onUpdateVisualizationSession),
      ) as { subscribe: any }).subscribe({
        next: ({ value }: { value: any }) => {
          if (value.data.onUpdateVisualizationSession.id !== sessionId) {
            console.log('Not my session');
            return;
          }
          this.handleUpdatedVisualizationSession(value.data.onUpdateVisualizationSession);
        },
        error: (error: any) => console.error(error),
      });
    },
    handleUpdatedVisualizationSession(session: VisualizationSession) {
      try {
        const statuses = JSON.parse(session.statuses as string);
        if (Object.values(statuses).some((stat) => stat === 'WAITING')) {
          console.log('Not all done');
          return;
        }
        console.log('All viz done saving data. Proceed');
        this.sessionAction = null;
        this.sessionId = null;
        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Session successfully saved!',
          life: 6000,
        });
        this.unsubscribe(this.vizSessionSubscription);
        this.savingSession = false;
      } catch (error) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to save session!',
          life: 3000,
        });
        this.unsubscribe(this.vizSessionSubscription);
        this.savingSession = false;
      }
    },
    unsubscribe(subscription: any) {
      try {
        if (subscription) subscription.unsubscribe();
      } catch (error) {
        console.error(error);
      }
    },
    async createVizSession(): Promise< VisualizationSession | null > {
      const vizSessionObj: CustomVisualizationSession = {
        data: JSON.stringify(this.makeVizSessionData(this.openVisualizationsMap)),
        statuses: JSON.stringify(this.makeVizSessionStatuses(this.openVisualizationsMap)),
        description: this.sessionDescription,
      };
      try {
        const result = await API.graphql(graphqlOperation(mutations.createVisualizationSession, { input: { ...vizSessionObj } }));
        return (result as { data: { createVisualizationSession: VisualizationSession } }).data.createVisualizationSession as VisualizationSession | null;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    makeVizSessionData(openVisualizationsMap : { [key: string] : OpenVisualization }) {
      const data = {} as { [key: string]: any };
      for (const [id, openVisualization] of Object.entries(openVisualizationsMap)) {
        data[id] = {
          ...openVisualization,
          pathToSavedSessionFile: null,
        };
      }
      return data;
    },
    makeVizSessionStatuses(openVisualizationsMap : { [key: string] : OpenVisualization }): {[key: string] : string} {
      const statuses = {} as {[key: string] : string};
      Object.keys(openVisualizationsMap).forEach((id: string) => {
        statuses[id] = 'WAITING';
      });
      return statuses;
    },
    addSessionInformationToVisualizations(sessionId: string, action: string) {
      this.sessionId = sessionId;
      this.sessionAction = action;
    },
    populateBreadcrumbItems(pipelines: PipelineTemplate[]): {label: string, to: string}[] {
      if (!pipelines || pipelines.length === 0) {
        console.error('No pipelines in populateBreadcrumbItems');
        return [];
      }
      const study: { id: string, studyName: string } = pipelines[0].study as any;
      let studyPhase: { id: string, studyPhaseName: string } | null = null;
      for (let i = 0; i < pipelines.length; i += 1) {
        const pipeline = pipelines[i];
        if (pipeline.studyPhase) {
          if (!studyPhase) {
            studyPhase = pipeline.studyPhase;
          }
          if (pipeline.studyPhase?.id !== studyPhase!.id) {
            // If there are pipelines from multiple study phases, just omit the study phase
            return this.getStudyBreadcrumbs(study); 
          }
        } else {
          // Exists pipeline without study phase
          return this.getStudyBreadcrumbs(study); 
        }
      }
      // Will probably never get triggered but just to be safe
      if (!studyPhase) {
        return this.getStudyBreadcrumbs(study);
      }
      return this.getStudyPhaseBreadcrumbs(study, studyPhase);
    },
    getStudyBreadcrumbs(study: { id: string, studyName: string }) {
      return [{ label: 'Studies', to: `${getOrgNameAndIdForRoute(this.$route)}studyBrowse` }, { label: `Study: ${study.studyName} | Batches`, to: `${getOrgNameAndIdForRoute(this.$route)}batchBrowse/study/${study.id}` }, { label: 'Visualizations', to: this.$route.fullPath }];
    },
    getStudyPhaseBreadcrumbs(study: { id: string, studyName: string }, studyPhase: { id: string, studyPhaseName: string } | null) {
      return [{ label: 'Studies', to: `${getOrgNameAndIdForRoute(this.$route)}studyBrowse` }, { label: `Study: ${study.studyName} | Study Phases`, to: `${getOrgNameAndIdForRoute(this.$route)}studyPhaseBrowse/${study.id}` }, { label: `Study Phase: ${studyPhase?.studyPhaseName} | Batches`, to: `${getOrgNameAndIdForRoute(this.$route)}batchBrowse/studyPhase/${studyPhase?.id}` }, { label: 'Visualizations', to: this.$route.fullPath }];
    },
  },
  watch: {
    $route: {
      // eslint-disable-next-line no-unused-vars
      handler(to, from) {
        console.log('Triggering');
        if (to.fullPath.includes('/visualize/')) this.main();
      },
      deep: true,
    },
  },
});
