/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://v6tt5hbr0m.execute-api.eu-west-2.amazonaws.com/uat",
            "region": "eu-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://uwh3xzry45g25g442k4btkksjy.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-2:042345d3-4545-4cfc-a501-8b247c4f07f8",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_DrxRDlQG7",
    "aws_user_pools_web_client_id": "5mg4jr1popv661vd4sa8k7jgbl",
    "oauth": {
        "domain": "m2m4b515745-4b515745-uat.auth.eu-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://dev.d2d8q2a937e8rv.amplifyapp.com/",
        "redirectSignOut": "https://dev.d2d8q2a937e8rv.amplifyapp.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
