// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const Phase = {
  "PRE_CLINICAL": "PRE_CLINICAL",
  "PHASE_1": "PHASE_1",
  "PHASE_2": "PHASE_2",
  "PHASE_3": "PHASE_3"
};

const EntityType = {
  "ORGANIZATION": "ORGANIZATION",
  "STUDY": "STUDY",
  "STUDY_PHASE": "STUDY_PHASE"
};

const AnalysisType = {
  "RNASEQ": "RNASEQ",
  "IMMUNOPEPTIDOMICS": "IMMUNOPEPTIDOMICS",
  "NANOSTRING": "NANOSTRING"
};

const PipelineStatus = {
  "INITIATED": "INITIATED",
  "SCHEDULED": "SCHEDULED",
  "SCHEDULED_FAILED": "SCHEDULED_FAILED",
  "RUNNING": "RUNNING",
  "COMPLETED": "COMPLETED",
  "CANCELLED": "CANCELLED",
  "CANCELLED_FAILED": "CANCELLED_FAILED",
  "ABORTED": "ABORTED",
  "ABORTED_FAILED": "ABORTED_FAILED",
  "FINISHED": "FINISHED",
  "FAILED": "FAILED",
  "ERROR": "ERROR"
};

const QcStatus = {
  "AWAITING": "AWAITING",
  "PASSED": "PASSED",
  "FAILED": "FAILED"
};

const SequenceType = {
  "PAIRED_END": "PAIRED_END",
  "SINGLE_END": "SINGLE_END"
};

const DataLabEc2InstancesStatus = {
  "INITIATED": "INITIATED",
  "STOPPED": "STOPPED",
  "RUNNING": "RUNNING",
  "TERMINATED": "TERMINATED",
  "SETTING_UP": "SETTING_UP",
  "FAILED": "FAILED"
};

const DataLabEc2InstancesType = {
  "RSTUDIO": "RSTUDIO",
  "JUPYTER": "JUPYTER"
};

const DataLabEc2InstanceSessionStatus = {
  "LOADING": "LOADING",
  "LOADING_FAILED": "LOADING_FAILED",
  "LOADED": "LOADED",
  "STAND_BY": "STAND_BY",
  "SAVING": "SAVING",
  "SAVING_FAILED": "SAVING_FAILED",
  "SAVED": "SAVED"
};

const UploadFileStatus = {
  "PENDING": "PENDING",
  "UPLOADING": "UPLOADING",
  "COMPLETED": "COMPLETED",
  "FAILED": "FAILED",
  "MISSING": "MISSING",
  "OMITTED": "OMITTED"
};

const { Organization, Study, StudyPhase, EntityQuestions, MetaDataValidationSchemaTemplate, Group, Patient, Timepoint, Sample, Batch, Pipeline, AuxiliaryAnalysis, MetadataImmunopeptidomics, MetadataNanoString, MetadataRnaSeq, Treatment, LaunchablePipelines, User, UploadLog, Visualizations, VisualizationSession, DataLabEC2Instances, DataLabEC2InstanceSession, DataLabEC2InstanceStatusChange, UploadBundle, UploadBundleAnalysisSession, UploadSample, UploadSampleAnalysisSession, UploadFile, OrganizationLaunchablePipelines, ModelUploadFileConnection, ModelBatchConnection, ModelPipelineConnection, ModelStudyConnection, ModelStudyPhaseConnection, ModelAuxiliaryAnalysisConnection, ModelEntityQuestionsConnection, ModelGroupConnection, ModelLaunchablePipelinesConnection, ModelMetaDataValidationSchemaTemplateConnection, ModelMetadataImmunopeptidomicsConnection, ModelMetadataNanoStringConnection, ModelMetadataRnaSeqConnection, ModelOrganizationConnection, ModelPatientConnection, ModelSampleConnection, ModelTimepointConnection, ModelTreatmentConnection, ModelUploadLogConnection, ModelUserConnection, ModelVisualizationSessionConnection, ModelVisualizationsConnection } = initSchema(schema);

export {
  Organization,
  Study,
  StudyPhase,
  EntityQuestions,
  MetaDataValidationSchemaTemplate,
  Group,
  Patient,
  Timepoint,
  Sample,
  Batch,
  Pipeline,
  AuxiliaryAnalysis,
  MetadataImmunopeptidomics,
  MetadataNanoString,
  MetadataRnaSeq,
  Treatment,
  LaunchablePipelines,
  User,
  UploadLog,
  Visualizations,
  VisualizationSession,
  DataLabEC2Instances,
  DataLabEC2InstanceSession,
  DataLabEC2InstanceStatusChange,
  UploadBundle,
  UploadBundleAnalysisSession,
  UploadSample,
  UploadSampleAnalysisSession,
  UploadFile,
  OrganizationLaunchablePipelines,
  Phase,
  EntityType,
  AnalysisType,
  PipelineStatus,
  QcStatus,
  SequenceType,
  DataLabEc2InstancesStatus,
  DataLabEc2InstancesType,
  DataLabEc2InstanceSessionStatus,
  UploadFileStatus,
  ModelUploadFileConnection,
  ModelBatchConnection,
  ModelPipelineConnection,
  ModelStudyConnection,
  ModelStudyPhaseConnection,
  ModelAuxiliaryAnalysisConnection,
  ModelEntityQuestionsConnection,
  ModelGroupConnection,
  ModelLaunchablePipelinesConnection,
  ModelMetaDataValidationSchemaTemplateConnection,
  ModelMetadataImmunopeptidomicsConnection,
  ModelMetadataNanoStringConnection,
  ModelMetadataRnaSeqConnection,
  ModelOrganizationConnection,
  ModelPatientConnection,
  ModelSampleConnection,
  ModelTimepointConnection,
  ModelTreatmentConnection,
  ModelUploadLogConnection,
  ModelUserConnection,
  ModelVisualizationSessionConnection,
  ModelVisualizationsConnection
};