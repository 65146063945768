import { S3Client } from '@aws-sdk/client-s3';
import * as AWS from 'aws-sdk';

type GetCredentialsFunction = () => Promise<AWS.Credentials | null>;

class S3ClientSingleton {
  private static instance: S3ClientSingleton | null = null;
  private s3Client: S3Client | null = null;
  private getCredentials: GetCredentialsFunction;

  private constructor(getCredentials: GetCredentialsFunction) {
    this.getCredentials = getCredentials;
  }

  public static getInstance(getCredentials: GetCredentialsFunction): S3ClientSingleton {
    if (!S3ClientSingleton.instance) {
      S3ClientSingleton.instance = new S3ClientSingleton(getCredentials);
    }
    return S3ClientSingleton.instance;
  }

  public async getS3Client(): Promise<S3Client | null> {
    if (!this.s3Client) {
      await this.createS3Client();
    }
    return this.s3Client;
  }

  private async createS3Client(): Promise<void> {
    try {
      console.log('creating s3 client without credentials');
      const credentials = await this.getCredentials();
      if (credentials) {
        this.s3Client = new S3Client({
          region: 'eu-west-2',
          credentials,
        });
      } else {
        console.error('No credentials available for S3 client.');
        this.s3Client = null;
      }
    } catch (error) {
      console.error('Failed to create S3 client:', error);
      this.s3Client = null;
    }
  }

  public async createS3ClientWithCredentials(credentials: AWS.Credentials): Promise<void> {
    try {
      console.log('creating s3 client with credentials');
      if (credentials) {
        this.s3Client = new S3Client({
          region: 'eu-west-2',
          credentials,
        });
      } else {
        console.error('No credentials available for S3 client.');
        this.s3Client = null;
      }
    } catch (error) {
      console.error('Failed to create S3 client:', error);
      this.s3Client = null;
    }
  }

  public async refreshClient(): Promise<S3Client | null> {
    this.s3Client = null;
    return this.getS3Client();
  }

  public destroyClient(): void {
    console.warn('S3 client destroyed for simulation.');
    this.s3Client = null;
  }
}

export default S3ClientSingleton;
