/* tslint:disable */
/* eslint-disable */
import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getStudyIdAndValidationSchemaAndPhaseAndOrganizationIdByStudyPhaseId = /* GraphQL */ `
  query getStudyIdAndOrganizationIdByStudyPhaseId($id: ID!) {
    getStudyPhase(id: $id) {
      validationSchema
      phase
      study {
        id
        organization {
          id
          }

      }
  }
  }
`;

export const getTimepointIdPatientIdGroupIdBySampleId = /* GraphQL */ `
  query getTimepointIdPatientIdGroupIdBySampleId($id: ID!) {
    getSample(id: $id) {

      timepoint {
        id
        patient {
          id
          group {
            id
            }
        }
      }

    }
  }
`;

export const listOrganizationsForGroupSelection = /* GraphQL */ `
  query ListOrganizations(
    $id: ID
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrganizations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        organizationName
        active
        description
      }
      nextToken
    }
  }
`;

export const listOrganizationsIdAndDesc = /* GraphQL */ `
  query ListOrganizations(
    $id: ID
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrganizations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        organizationName
        description
      }
      nextToken
    }
  }
`;

export const studiesByOrganizationForGroupSelection = /* GraphQL */ `
  query StudiesByOrganization(
    $organizationId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studiesByOrganization(
      organizationId: $organizationId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studyName
        organizationId
        organizationStudiesId
        studyPhases {
          items {
            id
            studyPhaseName
            studyId
            studyStudyPhasesId
          }
        }
      }
      nextToken
    }
  }
`;

export const studyPhasesByStudyForGroupSelection = /* GraphQL */ `
  query StudyPhasesByStudy(
    $studyId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudyPhaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studyPhasesByStudy(
      studyId: $studyId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studyId
        description
        studyPhaseName
        phase
      }
      nextToken
    }
  }
`;

export const studyPhasesByStudySimple = /* GraphQL */ `
  query StudyPhasesByStudy(
    $studyId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudyPhaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studyPhasesByStudy(
      studyId: $studyId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;

export const getOrganizationNameOnly = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      organizationName
      description
    }
  }
`;

export const getOrgForNotificationEmail = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      organizationName
      notificationEmail
    }
  }
`;

export const getStudyNameOnly = /* GraphQL */ `
  query GetStudy($id: ID!) {
    getStudy(id: $id) {
      id
      studyName
    }
  }
`;

export const getStudyPhaseNameOnly = /* GraphQL */ `
  query GetStudyPhase($id: ID!) {
    getStudyPhase(id: $id) {
      id
      studyPhaseName
    }
  }
`;

export const listStudiesWithOrganization = /* GraphQL */ `
  query ListStudies(
    $id: ID
    $filter: ModelStudyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStudies(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        studyName
        organizationId
        organization {
          id
          readGroups
          writeGroups
        }
        description
        studyCode
        assay
        cellType
        database
        disease
        tissue
        sample
        created
        organizationStudiesId
      }
      nextToken
    }
  }
`;

export const listStudyPhasesForFixGroups = /* GraphQL */ `
  query ListStudyPhases(
    $id: ID
    $filter: ModelStudyPhaseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStudyPhases(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        studyId
        description
        studyPhaseName
        phase
        study {
          id
          studyName
          readGroups
          writeGroups
          adminGroups
          croGroups
          organization {
            id
            organizationName
            readGroups
            writeGroups
          }
        }
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyStudyPhasesId
      }
      nextToken
    }
  }
`;

export const listStudyPhasesForFixCompositeFields = /* GraphQL */ `
  query ListStudyPhases(
    $id: ID
    $filter: ModelStudyPhaseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStudyPhases(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        groupNames
        specimens
        sampleTypes
        species
        labAssayTechnologies
      }
      nextToken
    }
  }
`;

export const getOrganizationBasic = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      organizationName
      active
    }
  }
`;

export const getOrganizationGroups = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      organizationName
      active
      readGroups
      writeGroups
    }
  }
`;

export const getStudyBasic = /* GraphQL */ `
  query GetStudy($id: ID!) {
    getStudy(id: $id) {
      id
      studyName
      organization {
        id
        organizationName
        active
      }
    }
  }
`;

export const getStudyForOrgId = /* GraphQL */ `
  query GetStudy($id: ID!) {
    getStudy(id: $id) {
      id
      organization {
        id
      }
    }
  }
`;

export const getStudyBasicWithGroups = /* GraphQL */ `
  query GetStudy($id: ID!) {
    getStudy(id: $id) {
      id
      studyName
      readGroups
      writeGroups
      adminGroups
      croGroups
      organization {
        id
        organizationName
        active
        readGroups
        writeGroups
      }
    }
  }
`;

export const getStudyPhaseBasic = /* GraphQL */ `
  query GetStudyPhase($id: ID!) {
    getStudyPhase(id: $id) {
      id
      studyId
      study {
        id
        studyName
        organization {
          id
          organizationName
        }
      }
      studyPhaseName
    }
  }
`;

export const getStudyPhaseForStudyAndOrgId = /* GraphQL */ `
  query GetStudyPhase($id: ID!) {
    getStudyPhase(id: $id) {
      id
      studyId
      study {
        id
        organization {
          id
        }
      }
    }
  }
`;

export const getStudyPhaseIdAndName = /* GraphQL */ `
  query GetStudyPhase($id: ID!) {
    getStudyPhase(id: $id) {
      id
      studyPhaseName
    }
  }
`;

export const getStudySamples = /* GraphQL */ `
  query GetStudy($id: ID!) {
    getStudy(id: $id) {
      studyPhases {
        items {
          samples {
            items {
              id
              created
              domainSampleId
              MetadataNanoStringList {
                items {
                  id
                  created
                  isControl
                  barcodesMappedToControl
                  barcode
                }
              }
              MetadataRnaSeqList {
                items {
                  id
                  created
                  isControl
                  barcodesMappedToControl
                  barcode
                }
              }
              metadataImmunopeptidomicsList {
                items {
                  id
                  created
                  isControl
                  barcodesMappedToControl
                  barcode
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getBatchParents = /* GraphQL */ `
  query GetBatch($id: ID!) {
    getBatch(id: $id) {
      readGroups
      writeGroups
      adminGroups
      studyPhase {
        id
      }
      study {
        id
        studyName
        organization {
          id
          organizationName
        }
      }
      id
      batchName
    }
  }
`;

export const getPipelineParents = /* GraphQL */ `
  query GetPipeline($id: ID!) {
    getPipeline(id: $id) {
      batch {
        id
        batchName
        study {
          id
          studyName
          organization {
            id
            organizationName
          }
        }
      }
      id
      name
    }
  }
`;


export const getStudyPhaseIdNameSchemaMetadataTemplate = /* GraphQL */ `
  query GetStudyPhase($id: ID!) {
    getStudyPhase(id: $id) {
      id
      studyPhaseName
      validationSchema
      phase
      study {
        id
        studyName
        organization {
          id
        }
      }
      metaDataValidationSchemaTemplate {
        id
        version
        description
        templateFiles
        validationSchemaTemplate
      }
    }
  }
`;

export const studyPhasesByStudyForStudyPhaseBrowse = /* GraphQL */ `
  query StudyPhasesByStudy(
    $studyId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudyPhaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studyPhasesByStudy(
      studyId: $studyId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studyId
        description
        experimentSpecificVariables
        studyPhaseName
        studyPhaseAnswers
        groupNames
        labAssayTechnologies
        sampleTypes
        species
        batches {
          items {
            id
          }
        }
        pipelines {
          items {
            id
          }
        }
        specimens
        phase
        created
        study {
          organizationId
        }
      }
      nextToken
    }
  }
`;

export const studyPhasesByStudyForStudyPhaseBrowseWithSamples = /* GraphQL */ `
  query StudyPhasesByStudy(
    $studyId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudyPhaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studyPhasesByStudy(
      studyId: $studyId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studyId
        description
        experimentSpecificVariables
        studyPhaseName
        studyPhaseAnswers
        groupNames
        labAssayTechnologies
        sampleTypes
        species
        samples {
          items {
            id
          }
        }
        batches {
          items {
            id
          }
        }
        pipelines {
          items {
            id
          }
        }
        specimens
        phase
        created
        study {
          organizationId
          studyName
        }
      }
      nextToken
    }
  }
`;

export const samplesBystudyPhaseForSamplesTable = /* GraphQL */ `
  query samplesByStudyPhase(
    $studyPhaseId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSampleFilterInput
    $limit: Int
    $nextToken: String
    ) {
    samplesByStudyPhase(
      studyPhaseId: $studyPhaseId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      ) {
      items {
        id
        created
        collectionDate
        domainSampleId
        experimentSpecificVariables
        MetadataNanoStringList {
          items {
            id
            glacierBackup
            qcStatus
            rawDataS3
            sampleId
            sampleType
            species
            tumorVolume
            metaDataTemplateS3Path
            subtypeOfAnalysis
            created
            isControl
            barcodesMappedToControl
            barcode
            s3Path
            dv200Percentage
            concNanoGramPerMicroL
            rnaMassNanoGram
            rnaVolumeMicroL
            rin
          }
        }
        MetadataRnaSeqList {
          items {
            id
            glacierBackup
            qcStatus
            rawDataS3
            sampleId
            sampleType
            species
            tumorVolume
            metaDataTemplateS3Path
            subtypeOfAnalysis
            created
            isControl
            barcodesMappedToControl
            barcode
            s3Path
            sequenceType
            strandSpecific
            UMI
            adaptorsRemoved
            concNanoGramPerMicroL
            rnaMassNanoGram
            rnaVolumeMicroL
            rin
          }
        }
        metadataImmunopeptidomicsList {
          items {
            id
            glacierBackup
            qcStatus
            rawDataS3
            sampleId
            sampleType
            species
            tumorVolume
            metaDataTemplateS3Path
            subtypeOfAnalysis
            created
            isControl
            barcodesMappedToControl
            barcode
            s3Path
          }
        }
      }
      nextToken
    }
  }
`;

export const batchByStudyForBatchTable = /* GraphQL */ `
  query batchesByStudy(
    $studyId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBatchFilterInput
    $limit: Int
    $nextToken: String
    ) {
      batchesByStudy(
      studyId: $studyId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      ) {
      items {
        id
        description
        batchAnnotations
        batchGroups
        batchName
        studyId
        studyBatchesId
        studyPhase {
          id
          studyPhaseName
          study {
            studyName
          }
        }
        study {
          studyName
        }
        created
      }
      nextToken
    }
  }
`;

export const samplesBystudyPhaseForSamplesBrowse = /* GraphQL */ `
  query GetStudyPhase(
    $id: ID!
    ) {
    getStudyPhase(
      id: $id
      ) {
      id
      experimentSpecificVariables
      samples {
        items {
          id
          created
          collectionDate
          domainSampleId
          experimentSpecificVariables
          }
        }
      }
    }
  }
`;

export const samplesByStudyPhaseForBatch = /* GraphQL */ `
query samplesByStudyPhase(
  $studyPhaseId: ID!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSampleFilterInput
  $limit: Int
  $nextToken: String
  ) {
  samplesByStudyPhase(
    studyPhaseId: $studyPhaseId
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    ) {
    items {
      id
      created
      domainSampleId
      MetadataNanoStringList {
        items {
          id
          created
          isControl
          barcodesMappedToControl
          subtypeOfAnalysis
          barcode
        }
      }
      MetadataRnaSeqList {
        items {
          id
          created
          isControl
          barcodesMappedToControl
          subtypeOfAnalysis
          barcode
        }
      }
      metadataImmunopeptidomicsList {
        items {
          id
          created
          isControl
          barcodesMappedToControl
          subtypeOfAnalysis
          barcode
        }
      }
    }
    nextToken
  }
}
`;

export const getBatch = /* GraphQL */ `
  query GetBatch($id: ID!) {
    getBatch(id: $id) {
      id
      batchAnnotations
      batchGroups
      readGroups
      writeGroups
      adminGroups
      studyId
      filters
      annotations
      created
      updated
      studyBatchesId
    }
  }
`;

export const organisationEmailBystudyPhaseForSamplesBrowse = /* GraphQL */ `
  query GetStudyPhase(
    $id: ID!
    ) {
    getStudyPhase(
      id: $id
      ) {
      id
      studyPhaseName
      experimentSpecificVariables
      study {
        id
        studyName
        organization {
          id
          notificationEmail
        }
      }
    }
  }
`;

export const studyPhaseForParentIds = /* GraphQL */ `
  query GetStudyPhase(
    $id: ID!
    ) {
    getStudyPhase(
      id: $id
      ) {
      id
      study {
        id
        organization {
          id
        }
      }
    }
  }
`;

export const studyPhaseForSampleReportDialog = /* GraphQL */ `
  query GetStudyPhase(
    $id: ID!
    ) {
    getStudyPhase(
      id: $id
      ) {
      id
      description
      studyPhaseName
      study {
        id
        studyName
      }
      pipelines {
        items {
          id
          name
          status
          parameters
          description
          s3ParamsFile
          s3Results
          analysisType
          userEmail
          runId
        }
      }
    }
  }
`;

export const studyForSampleReportDialog = /* GraphQL */ `
  query GetStudy(
    $id: ID!
    ) {
    getStudy(
      id: $id
      ) {
      id
      description
      pipelines {
        items {
          id
          name
          status
          parameters
          description
          s3ParamsFile
          s3Results
          analysisType
          userEmail
          runId
        }
      }
    }
  }
`;

export const pipelinesByBatchForSamplesTableCheck = /* GraphQL */ `
  query PipelinesByBatch(
    $batchId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPipelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pipelinesByBatch(
      batchId: $batchId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        status
        parameters
        description
        s3ParamsFile
        s3Results
        analysisType
        runId
      }
    }
  }
`;


export const samplePathByStudyPhaseForSamplesBrowse = /* GraphQL */ `
  query SamplesByStudyPhase(
    $id: ID!
    ) {
    samplesByStudyPhase(
      studyPhaseId: $id
      ) {
      items {
        id
        domainSampleId
        StudyPhase {
          id
          phase
          study {
            id
            organization {
              id
            }
          }
        }
        timepoint {
          id
          patient {
            id
            group {
              id
            }
          }
        }
      }
    }
  }
`;



export const metaDataValidationSchemaTemplateByStudyPhase = /* GraphQL */ `
  query MetaDataValidationSchemaTemplateByStudyPhase(
    $studyPhaseId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMetaDataValidationSchemaTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    MetaDataValidationSchemaTemplateByStudyPhase(
      studyPhaseId: $studyPhaseId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studyPhaseId
        validationSchemaTemplate
      }
      nextToken
    }
  }
`;

export const listMetaDataValidationSchemaTemplatesForStudyPhaseCreation = /* GraphQL */ `
  query ListMetaDataValidationSchemaTemplates(
    $id: ID
    $filter: ModelMetaDataValidationSchemaTemplateFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMetaDataValidationSchemaTemplates(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        version
        description
        templateFiles
        validationSchemaTemplate
      }
      nextToken
    }
  }
`;

export const getStudyAnswers = /* GraphQL */ `
  query GetStudy($id: ID!) {
    getStudy(id: $id) {
      id
      studyName
      studyAnswers
    }
  }
`;

export const getStudyPhaseAnswers = /* GraphQL */ `
  query GetStudyPhase($id: ID!) {
    getStudyPhase(id: $id) {
      id
      studyPhaseName
      studyPhaseAnswers
    }
  }
`;

export const getSampleESV = /* GraphQL */ `
  query GetSample($id: ID!) {
    getSample(id: $id) {
      id
      experimentSpecificVariables
    }
  }
`;

export const listStudyPhasesForCroM2MAdmin = /* GraphQL */ `
  query ListStudyPhases(
    $id: ID
    $filter: ModelStudyPhaseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStudyPhases(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        studyPhaseName
        validationSchema
        phase
        study {
          id
          studyName
          organization {
            id
          }
        }
        metaDataValidationSchemaTemplate {
          id
          version
          description
          templateFiles
          validationSchemaTemplate
        }
      }
      nextToken
    }
  }
`;
export const listStudyPhasesForUpdateValidationSchema = /* GraphQL */ `
  query ListStudyPhases(
    $id: ID
    $filter: ModelStudyPhaseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStudyPhases(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        validationSchema
        metaDataValidationSchemaTemplate {
          id
          validationSchemaTemplate
        }
      }
      nextToken
    }
  }
`;

export const studiesByOrganizationForUpload = /* GraphQL */ `
  query StudiesByOrganization(
    $organizationId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studiesByOrganization(
      organizationId: $organizationId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studyName
      }
      nextToken
    }
  }
`;

export const studyPhasesByStudyForUpload = /* GraphQL */ `
  query StudyPhasesByStudy(
    $studyId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudyPhaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studyPhasesByStudy(
      studyId: $studyId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studyPhaseName
        validationSchema
        phase
        study {
          id
          studyName
          organization {
            id
          }
        }
        metaDataValidationSchemaTemplate {
          id
          version
          description
          templateFiles
          validationSchemaTemplate
        }
      }
      nextToken
    }
  }
`;

export const getStudyPhaseForOrgId = /* GraphQL */ `
  query GetStudyPhase($id: ID!) {
    getStudyPhase(id: $id) {
      id
      studyId
      study {
        id
        organizationId
      }
    }
  }
`;

export const pipelinesByStudyForPipelinesTable = /* GraphQL */ `
  query PipelinesByStudy(
    $studyId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPipelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pipelinesByStudy(
      studyId: $studyId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        status
        description
        s3Event
        s3Results
        analysisType
        parameters
        subtypeOfAnalysis
        studyId
        studyPhase {
          id
          studyPhaseName
        }
        study {
          id
          studyName
          organization {
            id
          }
        }
        scheduledPipeline
        scheduledDateTime
        launchablePipelineId
        launchablePipeline {
          id
          pipelineName
          pipelineVersion
        }
        batchId
        batch {
          id
          batchName
        }
        runId
        userEmail
        pipelineStarted
        pipelineCompleted
        visFileInfoS3path
        readGroups
        writeGroups
        adminGroups
        created
        updated
      }
      nextToken
    }
  }
`;

export const batchesByStudyPhaseForBatchTable = /* GraphQL */ `
  query BatchesByStudyPhase(
    $studyPhaseId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    batchesByStudyPhase(
      studyPhaseId: $studyPhaseId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        batchAnnotations
        batchGroups
        batchName
        studyId
        studyBatchesId
        study {
          studyName
        }
        studyPhase {
          id
          studyPhaseName
        }
        created
      }
      nextToken
    }
  }
`;

export const pipelinesByStudyPhaseForPipelinesTable = /* GraphQL */ `
  query PipelinesByStudyPhase(
    $studyPhaseId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPipelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pipelinesByStudyPhase(
      studyPhaseId: $studyPhaseId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        status
        description
        s3Event
        subtypeOfAnalysis
        s3Results
        analysisType
        parameters
        studyId
        studyPhase {
          id
          studyPhaseName
        }
        study {
          id
          studyName
          organization {
            id
          }
        }
        scheduledPipeline
        scheduledDateTime
        launchablePipelineId
        launchablePipeline {
          id
          pipelineName
          pipelineVersion
        }
        batchId
        batch {
          id
          batchName
        }
        runId
        userEmail
        pipelineStarted
        pipelineCompleted
        visFileInfoS3path
        readGroups
        writeGroups
        adminGroups
        created
        updated
      }
      nextToken
    }
  }
`;

export const listVisualizations = /* GraphQL */ `
  query ListVisualizations(
    $id: ID
    $filter: ModelVisualizationsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVisualizations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        visualizationName
        description
        version
        deploymentPath
        supportedAnalyses
      }
      nextToken
    }
  }
`;

export const pipelinesByBatchForVisualizationBatch = /* GraphQL */ `
  query PipelinesByBatch(
    $batchId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPipelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pipelinesByBatch(
      batchId: $batchId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        status
        description
        s3Event
        s3ParamsFile
        s3Results
        visFileInfoS3path
        subtypeOfAnalysis
        s3NfLog
        s3NfTasksLogs
        analysisType
        parameters
        studyId
        study {
          id
          studyName
        }
        studyPhaseId
        studyPhase {
          id
          studyPhaseName
        }
        scheduledPipeline
        scheduledDateTime
        launchablePipelineId
        launchablePipeline {
          id
          pipelineName
          pipelineVersion
        }
        batchId
        batch {
          id
          batchName
        }
        runId
        userEmail
        pipelineStarted
        pipelineCompleted
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPipelinesId
        studyPhasePipelinesId
        batchPipelinesId
        launchablePipelinesPipelinesId
      }
      nextToken
    }
  }
`;

export const pipelinesByStudySmaller = /* GraphQL */ `
  query PipelinesByStudy(
    $studyId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPipelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pipelinesByStudy(
      studyId: $studyId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        status
        description
        s3Event
        s3ParamsFile
        s3Results
        visFileInfoS3path
        s3NfLog
        s3NfTasksLogs
        analysisType
        parameters
        studyId
        study {
          id
        }
        studyPhaseId
        studyPhase {
          id
        }
        scheduledPipeline
        scheduledDateTime
        launchablePipelineId
        launchablePipeline {
          id
          pipelineName
          pipelineVersion
        }
        batchId
        batch {
          id
          batchName
        }
        runId
        userEmail
        pipelineStarted
        pipelineCompleted
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPipelinesId
        studyPhasePipelinesId
        batchPipelinesId
        launchablePipelinesPipelinesId
      }
      nextToken
    }
  }
`;

export const pipelinesByStudyPhaseSmaller = /* GraphQL */ `
  query PipelinesByStudyPhase(
    $studyPhaseId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPipelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pipelinesByStudyPhase(
      studyPhaseId: $studyPhaseId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        status
        description
        s3Event
        s3ParamsFile
        s3Results
        visFileInfoS3path
        s3NfLog
        s3NfTasksLogs
        analysisType
        parameters
        studyId
        study {
          id
        }
        studyPhaseId
        studyPhase {
          id
        }
        scheduledPipeline
        scheduledDateTime
        launchablePipelineId
        launchablePipeline {
          id
          pipelineName
          pipelineVersion
        }
        batchId
        batch {
          id
          batchName
        }
        runId
        userEmail
        pipelineStarted
        pipelineCompleted
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPipelinesId
        studyPhasePipelinesId
        batchPipelinesId
        launchablePipelinesPipelinesId
      }
      nextToken
    }
  }
`;

export const studiesByOrganizationForStudyTable = /* GraphQL */ `
  query StudiesByOrganization(
    $organizationId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studiesByOrganization(
      organizationId: $organizationId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studyName
        organizationId
        organization {
          id
          organizationName
          organizationCode
          active
          notificationEmail
          description
          organizationAnswers
          url
          s3Path
          readGroups
          writeGroups
          created
          updated
        }
        studyPhases {
          nextToken
        }
        batches {
          items {
            id
          }
          nextToken
        }
        pipelines {
          items {
            id
          }
          nextToken
        }
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
      }
      nextToken
    }
  }
`;

export const listVisualizationSessionsForLoad = /* GraphQL */ `
  query ListVisualizationSessions(
    $filter: ModelVisualizationSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVisualizationSessions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        statuses
        data
        description
        created
        updated
      }
      nextToken
    }
  }
`;

export const getBatchForSamplesTable = /* GraphQL */ `
  query GetBatch($id: ID!) {
    getBatch(id: $id) {
      id
      batchName
      batchAnnotations
      batchGroups
      annotations
      controlSamples
      filters
      description
      samples {
        items {
          id
          timepointId
          studyPhaseId
          BatchId
          domainSampleId
          experimentSpecificVariables
          collectionDate
          processingDate
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyPhaseSamplesId
          timepointSamplesId
          batchSamplesId
        }
        nextToken
      }
      pipelines {
        items {
          id
          name
          status
          description
          s3Event
          s3ParamsFile
          s3Results
          visFileInfoS3path
          s3NfLog
          s3NfTasksLogs
          analysisType
          parameters
          studyId
          studyPhaseId
          scheduledPipeline
          scheduledDateTime
          launchablePipelineId
          batchId
          runId
          userEmail
          pipelineStarted
          pipelineCompleted
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyPipelinesId
          studyPhasePipelinesId
          batchPipelinesId
          launchablePipelinesPipelinesId
        }
        nextToken
      }
      studyId
      study {
        id
        studyName
        organizationId
        organization {
          id
          organizationName
          organizationCode
          active
          notificationEmail
          description
          organizationAnswers
          url
          s3Path
          readGroups
          writeGroups
          created
          updated
        }
        studyPhases {
          nextToken
        }
        batches {
          nextToken
        }
        pipelines {
          nextToken
        }
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
      }
      studyPhaseId
      studyPhase {
        id
        studyId
        study {
          id
          studyName
          organizationId
          description
          studyCode
          studyAnswers
          s3Path
          croGroups
          readGroups
          writeGroups
          adminGroups
          created
          updated
          organizationStudiesId
        }
        metaDataValidationSchemaTemplateId
        metaDataValidationSchemaTemplate {
          id
          version
          description
          templateFiles
          validationSchemaTemplate
          created
          updated
        }
        groups {
          nextToken
        }
        samples {
          nextToken
        }
        batches {
          nextToken
        }
        pipelines {
          nextToken
        }
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
      }
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyBatchesId
      studyPhaseBatchesId
    }
  }
`;

export const getMetadataRnaSeqForQC = /* GraphQL */ `
  query GetMetadataRnaSeq($id: ID!) {
    getMetadataRnaSeq(id: $id) {
      id
      qcStatus
    }
  }
`;

export const getMetadataNanoStringForQC = /* GraphQL */ `
  query GetMetadataNanoString($id: ID!) {
    getMetadataNanoString(id: $id) {
      id
      qcStatus
    }
  }
`;
export const getMetadataImmunopeptidomicsForQC = /* GraphQL */ `
  query GetMetadataImmunopeptidomics($id: ID!) {
    getMetadataImmunopeptidomics(id: $id) {
      id
      qcStatus
    }
  }
`;

export const getSampleForSamplesTableFromBatch = /* GraphQL */ `
  query GetSample($id: ID!) {
    getSample(id: $id) {
      id
      created
      collectionDate
      domainSampleId
      experimentSpecificVariables
      MetadataNanoStringList {
        items {
          id
          glacierBackup
          qcStatus
          rawDataS3
          sampleId
          sampleType
          species
          tumorVolume
          metaDataTemplateS3Path
          subtypeOfAnalysis
          created
          isControl
          barcodesMappedToControl
          barcode
          s3Path
          dv200Percentage
          concNanoGramPerMicroL
          rnaMassNanoGram
          rnaVolumeMicroL
          rin
        }
      }
      MetadataRnaSeqList {
        items {
          id
          glacierBackup
          qcStatus
          rawDataS3
          sampleId
          sampleType
          species
          tumorVolume
          metaDataTemplateS3Path
          subtypeOfAnalysis
          created
          isControl
          barcodesMappedToControl
          barcode
          s3Path
          sequenceType
          strandSpecific
          UMI
          adaptorsRemoved
          concNanoGramPerMicroL
          rnaMassNanoGram
          rnaVolumeMicroL
          rin
        }
      }
      metadataImmunopeptidomicsList {
        items {
          id
          glacierBackup
          qcStatus
          rawDataS3
          sampleId
          sampleType
          species
          tumorVolume
          metaDataTemplateS3Path
          subtypeOfAnalysis
          created
          isControl
          barcodesMappedToControl
          barcode
          s3Path
        }
      }
    }
  }
`;

export const listLaunchablePipelines = /* GraphQL */ `
  query ListLaunchablePipelines(
    $id: ID
    $filter: ModelLaunchablePipelinesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLaunchablePipelines(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        pipelineName
        pipelineVersion
        parameters
        description
        inputFileInfo
        supportedAnalyses
        pipelines {
          nextToken
        }
        gitRepo
        workflowId
        created
        updated
      }
      nextToken
    }
  }
`;

export const getBatchForReport = /* GraphQL */ `
  query GetBatch($id: ID!) {
    getBatch(id: $id) {
      id
      batchName
      batchAnnotations
      batchGroups
      annotations
      controlSamples
      filters
      description
      studyPhase{
        id
        studyPhaseName
        description
      }
      study {
        id
        studyName
        description
        organization {
          id
        }
      }
      pipelines {
        items {
          id
          name
          status
          description
          s3Event
          s3ParamsFile
          s3Results
          visFileInfoS3path
          s3NfLog
          s3NfTasksLogs
          analysisType
          parameters
          studyId
          studyPhaseId
          scheduledPipeline
          scheduledDateTime
          launchablePipelineId
          batchId
          runId
          userEmail
          pipelineStarted
          pipelineCompleted
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyPipelinesId
          studyPhasePipelinesId
          batchPipelinesId
          launchablePipelinesPipelinesId
        }
        nextToken
      }
      studyId
      studyPhaseId
    }
  }
`;

export const lisAllLaunchablePipelinesWithOrganizations = /* GraphQL */ `
  query ListLaunchablePipelines(
    $id: ID
    $filter: ModelLaunchablePipelinesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLaunchablePipelines(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        gitRepo
        description
        created
        inputFileInfo
        parameters
        pipelineName
        pipelineVersion
        supportedAnalyses
        supportedSubtypeOfAnalyses
        workflowId
        organizations {
          items {
            id
            organization {
              id
              organizationName
              description
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const organizationLaunchablePipelinesByOrganizationId = /* GraphQL */ `
  query OrganizationLaunchablePipelinesByOrganizationId(
    $organizationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationLaunchablePipelinesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    organizationLaunchablePipelinesByOrganizationId(
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        launchablePipelinesId
        organization {
          id
          organizationName
          organizationCode
          active
          notificationEmail
          description
          organizationAnswers
          url
          s3Path
          readGroups
          writeGroups
          created
          updated
        }
        launchablePipelines {
          id
          pipelineName
          pipelineVersion
          parameters
          description
          inputFileInfo
          supportedAnalyses
          supportedSubtypeOfAnalyses
          gitRepo
          workflowId
          created
          updated
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const studiesByOrganizationForRStudio = /* GraphQL */ `
  query StudiesByOrganization(
    $organizationId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studiesByOrganization(
      organizationId: $organizationId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studyName
        batches {
          items {
            id
            batchName
            studyPhaseId
            pipelines {
              items {
                status
              }
            }
          }
        }
        studyPhases {
          items {
            id
            studyPhaseName
            batches {
              items {
                id
                batchName
                pipelines {
                  items {
                    status
                  }
                }
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const batchesByStudyPhaseForRStudio = /* GraphQL */ `
  query BatchesByStudyPhase(
    $studyPhaseId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    batchesByStudyPhase(
      studyPhaseId: $studyPhaseId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        batchName
      }
      nextToken
    }
  }
`;

export const listDataLabEC2InstanceSessions = /* GraphQL */ `
  query ListDataLabEC2InstanceSessions(
    $filter: ModelDataLabEC2InstanceSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDataLabEC2InstanceSessions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dataLabInstanceId
        dataLabInstance {
          id
        }
        s3Path
        instanceLaunchEvent
        status
        title
        description
        created
        updated
        dataLabEC2InstancesDataLabInstanceSessionsId
      }
      nextToken
    }
  }
`;

export const dataLabEC2InstancesByTypeWithLastStatusChangeEvent = /* GraphQL */ `
  query DataLabEC2InstancesByType(
    $type: DataLabEC2InstancesType!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDataLabEC2InstancesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dataLabEC2InstancesByType(
      type: $type
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        creationTimestamp
        stoppedTimestamp
        startTimestamp
        terminatedTimestamp
        stoppedTimePeriod
        runningTimePeriod
        userEmail
        status
        type
        dataLabInstanceSessions {
          nextToken
        }
        dataLabInstanceStatusChanges(sortDirection: DESC, limit: 1) {
          items {
            id
            dataLabInstanceId
            organizationId
            previousStatus
            newStatus
            initiatedBy
            action
            performedBy
            additionalNotes
            created
          }
        }
        organizationId
        organizationName
        instanceUrl
        instanceId
        infrastructureRelatedEntities
        instanceLaunchEvent
        adminGroups
        created
        updated
      }
      nextToken
    }
  }
`;


// export const listIncompleteUploadBundles = /* GraphQL */ `
//   query ListUploadBundles(
//     $filter: ModelUploadBundleFilterInput
//     $limit: Int
//     $nextToken: String
//   ) {
//     listUploadBundles(filter: $filter, limit: $limit, nextToken: $nextToken) {
//       items {
//         id
//         bundleLog
//         validationErrors
//         status
//         uploadBundleAnalysisSession {
//           id
//           metadataFileS3Path
//           step
//           listOfRawFileNames
//           analysisType
//           subtypeOfAnalysis
//           studyPhaseId
//           metadataHelperLambdaResponseS3Path
//           barcodeToFilesToUploadS3Path
//           adminGroups
//           created
//           updated
//         }
        // uploadSamples {
        //   items {
        //     id
        //     uploadFiles {
        //       items {
        //         id
        //         fileName
        //         status
        //         multipartUploadId
        //       }
        //     }
        //   }
        // }
        // uploadFiles {
        //   items {
        //     id
        //     fileName
        //     status
        //     multipartUploadId
        //   }
        // }
//         adminGroups
//         created
//         updated
//       }
//       nextToken
//     }
//   }
// `;

export const listUploadBundlesByStudyPhase = /* GraphQL */ `
  query UploadBundlesByStudyPhase(
    $studyPhaseId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUploadBundleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    uploadBundlesByStudyPhase(
      studyPhaseId: $studyPhaseId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studyPhaseId
        studyPhase {
          id
        }
        bundleLog
        validationErrors
        status
        userEmail
        uploadBundleAnalysisSession {
          id
          metadataFileS3Path
          step
          listOfRawFileNames
          analysisType
          subtypeOfAnalysis
          studyPhaseId
          barcodeToFilesToUploadS3Path
          adminGroups
          created
          updated
        }
        uploadSamples {
          items {
            id
            barcode
            writeMetadata
            uploadFiles {
              items {
                id
                fileName
                status
                multipartUploadId
                toSkipUpload
                s3Path
                size
              }
            }
          }
        }
        uploadFiles {
          items {
            id
            fileName
            status
            multipartUploadId
            toSkipUpload
            s3Path
            size
            uploadSample {
              id
              barcode
            }
          }
        }
        adminGroups
        created
        updated
        studyPhaseUploadBundlesId
        uploadBundleUploadBundleAnalysisSessionId
      }
      nextToken
    }
  }
`;

export const getUpdatedUploadBundle = /* GraphQL */ `
  query GetUploadBundle($id: ID!) {
    getUploadBundle(id: $id) {
              id
        studyPhaseId
        studyPhase {
          id
        }
        bundleLog
        validationErrors
        status
        userEmail
        uploadBundleAnalysisSession {
          id
          metadataFileS3Path
          step
          listOfRawFileNames
          analysisType
          subtypeOfAnalysis
          studyPhaseId
          barcodeToFilesToUploadS3Path
          adminGroups
          created
          updated
        }
        uploadSamples {
          items {
            id
            barcode
            writeMetadata
            uploadFiles {
              items {
                id
                fileName
                status
                multipartUploadId
                s3Path
                size
                toSkipUpload
              }
            }
          }
        }
        uploadFiles {
          items {
            id
            fileName
            status
            multipartUploadId
            s3Path
            size
            toSkipUpload
            uploadSample {
              id
              barcode
            }
          }
        }
        adminGroups
        created
        updated
        studyPhaseUploadBundlesId
        uploadBundleUploadBundleAnalysisSessionId
    }
  }
`;


export const listUploadBundlesForDataUploadsTable = /* GraphQL */ `query ListUploadBundles(
  $filter: ModelUploadBundleFilterInput
  $limit: Int
  $nextToken: String
) {
  listUploadBundles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      studyPhaseId
      studyPhase {
        id
        studyPhaseName
        study {
          organization {
            description
          }
        }
      }
      bundleLog
      validationErrors
      status
      userEmail
      uploadBundleAnalysisSession {
        analysisType
        subtypeOfAnalysis
      }
      adminGroups
      readGroups
      completionTime
      created
      updated
      studyPhaseUploadBundlesId
      uploadBundleUploadBundleAnalysisSessionId
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListUploadBundlesQueryVariables,
  APITypes.ListUploadBundlesQuery
>;
