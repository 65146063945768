export const onUpdatePipelineForStatus = /* GraphQL */ `
  subscription OnUpdatePipeline($filter: ModelSubscriptionPipelineFilterInput) {
    onUpdatePipeline(filter: $filter) {
      id
      status
      created
      updated
  }
}
`;
export const onUpdateVisualizationSession = /* GraphQL */ `
  subscription OnUpdateVisualizationSession(
    $filter: ModelSubscriptionVisualizationSessionFilterInput
    $owner: String
  ) {
    onUpdateVisualizationSession(filter: $filter, owner: $owner) {
      id
      statuses
      data
    }
  }
`;

export const onUpdateDataLabEC2Instances = /* GraphQL */ `
  subscription OnUpdateDataLabEC2Instances(
    $filter: ModelSubscriptionDataLabEC2InstancesFilterInput
    $owner: String
  ) {
    onUpdateDataLabEC2Instances(filter: $filter, owner: $owner) {
      id
      status
      created
      updated
      instanceUrl
    }
  }
`;

export const onUpdateUploadBundleAnalysisSession = /* GraphQL */ `
  subscription OnUpdateUploadBundleAnalysisSession(
    $filter: ModelSubscriptionUploadBundleAnalysisSessionFilterInput,
    $owner: String
  ) {
    onUpdateUploadBundleAnalysisSession(filter: $filter, owner: $owner) {
      id
      step
      readGroups
      adminGroups
      owner
    }
  }
`;

export const onUpdateUploadBundle = /* GraphQL */ `
  subscription OnUpdateUploadBundle(
    $filter: ModelSubscriptionUploadBundleFilterInput,
    $owner: String
  ) {
    onUpdateUploadBundle(filter: $filter, owner: $owner) {
      id
      status
      validationErrors
      readGroups
      adminGroups
      owner
    }
  }
`;

export const onUpdateUploadSample = /* GraphQL */ `subscription OnUpdateUploadSample(
  $filter: ModelSubscriptionUploadSampleFilterInput
  $owner: String
) {
  onUpdateUploadSample(filter: $filter, owner: $owner) {
    id
    status
    adminGroups
    readGroups
    owner
  }
}`;
