/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const doNotTouchItDoesNothing = /* GraphQL */ `mutation DoNotTouchItDoesNothing($input: CreateUserInput!) {
  doNotTouchItDoesNothing(input: $input) {
    id
    pipelineName
    organizations {
      items {
        id
        organizationId
        launchablePipelinesId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    pipelineVersion
    parameters
    description
    inputFileInfo
    supportedAnalyses
    supportedSubtypeOfAnalyses
    pipelines {
      items {
        id
        name
        status
        description
        s3Event
        s3ParamsFile
        s3Results
        visFileInfoS3path
        s3NfLog
        s3NfTasksLogs
        analysisType
        subtypeOfAnalysis
        parameters
        studyId
        studyPhaseId
        scheduledPipeline
        scheduledDateTime
        launchablePipelineId
        batchId
        runId
        userEmail
        pipelineStarted
        pipelineCompleted
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPipelinesId
        studyPhasePipelinesId
        batchPipelinesId
        launchablePipelinesPipelinesId
        __typename
      }
      nextToken
      __typename
    }
    gitRepo
    workflowId
    created
    updated
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DoNotTouchItDoesNothingMutationVariables,
  APITypes.DoNotTouchItDoesNothingMutation
>;
export const createOrganization = /* GraphQL */ `mutation CreateOrganization(
  $input: CreateOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  createOrganization(input: $input, condition: $condition) {
    id
    organizationName
    organizationCode
    active
    studies {
      items {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      nextToken
      __typename
    }
    launchablePipelines {
      items {
        id
        organizationId
        launchablePipelinesId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    notificationEmail
    description
    organizationAnswers
    url
    gitUrl
    s3Path
    readGroups
    writeGroups
    created
    updated
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrganizationMutationVariables,
  APITypes.CreateOrganizationMutation
>;
export const updateOrganization = /* GraphQL */ `mutation UpdateOrganization(
  $input: UpdateOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  updateOrganization(input: $input, condition: $condition) {
    id
    organizationName
    organizationCode
    active
    studies {
      items {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      nextToken
      __typename
    }
    launchablePipelines {
      items {
        id
        organizationId
        launchablePipelinesId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    notificationEmail
    description
    organizationAnswers
    url
    gitUrl
    s3Path
    readGroups
    writeGroups
    created
    updated
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrganizationMutationVariables,
  APITypes.UpdateOrganizationMutation
>;
export const deleteOrganization = /* GraphQL */ `mutation DeleteOrganization(
  $input: DeleteOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  deleteOrganization(input: $input, condition: $condition) {
    id
    organizationName
    organizationCode
    active
    studies {
      items {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      nextToken
      __typename
    }
    launchablePipelines {
      items {
        id
        organizationId
        launchablePipelinesId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    notificationEmail
    description
    organizationAnswers
    url
    gitUrl
    s3Path
    readGroups
    writeGroups
    created
    updated
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrganizationMutationVariables,
  APITypes.DeleteOrganizationMutation
>;
export const createStudy = /* GraphQL */ `mutation CreateStudy(
  $input: CreateStudyInput!
  $condition: ModelStudyConditionInput
) {
  createStudy(input: $input, condition: $condition) {
    id
    studyName
    organizationId
    organization {
      id
      organizationName
      organizationCode
      active
      studies {
        nextToken
        __typename
      }
      launchablePipelines {
        nextToken
        __typename
      }
      notificationEmail
      description
      organizationAnswers
      url
      gitUrl
      s3Path
      readGroups
      writeGroups
      created
      updated
      __typename
    }
    studyPhases {
      items {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      nextToken
      __typename
    }
    batches {
      items {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      nextToken
      __typename
    }
    pipelines {
      items {
        id
        name
        status
        description
        s3Event
        s3ParamsFile
        s3Results
        visFileInfoS3path
        s3NfLog
        s3NfTasksLogs
        analysisType
        subtypeOfAnalysis
        parameters
        studyId
        studyPhaseId
        scheduledPipeline
        scheduledDateTime
        launchablePipelineId
        batchId
        runId
        userEmail
        pipelineStarted
        pipelineCompleted
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPipelinesId
        studyPhasePipelinesId
        batchPipelinesId
        launchablePipelinesPipelinesId
        __typename
      }
      nextToken
      __typename
    }
    description
    studyCode
    studyAnswers
    s3Path
    croGroups
    readGroups
    writeGroups
    adminGroups
    created
    updated
    organizationStudiesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateStudyMutationVariables,
  APITypes.CreateStudyMutation
>;
export const updateStudy = /* GraphQL */ `mutation UpdateStudy(
  $input: UpdateStudyInput!
  $condition: ModelStudyConditionInput
) {
  updateStudy(input: $input, condition: $condition) {
    id
    studyName
    organizationId
    organization {
      id
      organizationName
      organizationCode
      active
      studies {
        nextToken
        __typename
      }
      launchablePipelines {
        nextToken
        __typename
      }
      notificationEmail
      description
      organizationAnswers
      url
      gitUrl
      s3Path
      readGroups
      writeGroups
      created
      updated
      __typename
    }
    studyPhases {
      items {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      nextToken
      __typename
    }
    batches {
      items {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      nextToken
      __typename
    }
    pipelines {
      items {
        id
        name
        status
        description
        s3Event
        s3ParamsFile
        s3Results
        visFileInfoS3path
        s3NfLog
        s3NfTasksLogs
        analysisType
        subtypeOfAnalysis
        parameters
        studyId
        studyPhaseId
        scheduledPipeline
        scheduledDateTime
        launchablePipelineId
        batchId
        runId
        userEmail
        pipelineStarted
        pipelineCompleted
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPipelinesId
        studyPhasePipelinesId
        batchPipelinesId
        launchablePipelinesPipelinesId
        __typename
      }
      nextToken
      __typename
    }
    description
    studyCode
    studyAnswers
    s3Path
    croGroups
    readGroups
    writeGroups
    adminGroups
    created
    updated
    organizationStudiesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStudyMutationVariables,
  APITypes.UpdateStudyMutation
>;
export const deleteStudy = /* GraphQL */ `mutation DeleteStudy(
  $input: DeleteStudyInput!
  $condition: ModelStudyConditionInput
) {
  deleteStudy(input: $input, condition: $condition) {
    id
    studyName
    organizationId
    organization {
      id
      organizationName
      organizationCode
      active
      studies {
        nextToken
        __typename
      }
      launchablePipelines {
        nextToken
        __typename
      }
      notificationEmail
      description
      organizationAnswers
      url
      gitUrl
      s3Path
      readGroups
      writeGroups
      created
      updated
      __typename
    }
    studyPhases {
      items {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      nextToken
      __typename
    }
    batches {
      items {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      nextToken
      __typename
    }
    pipelines {
      items {
        id
        name
        status
        description
        s3Event
        s3ParamsFile
        s3Results
        visFileInfoS3path
        s3NfLog
        s3NfTasksLogs
        analysisType
        subtypeOfAnalysis
        parameters
        studyId
        studyPhaseId
        scheduledPipeline
        scheduledDateTime
        launchablePipelineId
        batchId
        runId
        userEmail
        pipelineStarted
        pipelineCompleted
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPipelinesId
        studyPhasePipelinesId
        batchPipelinesId
        launchablePipelinesPipelinesId
        __typename
      }
      nextToken
      __typename
    }
    description
    studyCode
    studyAnswers
    s3Path
    croGroups
    readGroups
    writeGroups
    adminGroups
    created
    updated
    organizationStudiesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteStudyMutationVariables,
  APITypes.DeleteStudyMutation
>;
export const createStudyPhase = /* GraphQL */ `mutation CreateStudyPhase(
  $input: CreateStudyPhaseInput!
  $condition: ModelStudyPhaseConditionInput
) {
  createStudyPhase(input: $input, condition: $condition) {
    id
    studyId
    study {
      id
      studyName
      organizationId
      organization {
        id
        organizationName
        organizationCode
        active
        notificationEmail
        description
        organizationAnswers
        url
        gitUrl
        s3Path
        readGroups
        writeGroups
        created
        updated
        __typename
      }
      studyPhases {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      description
      studyCode
      studyAnswers
      s3Path
      croGroups
      readGroups
      writeGroups
      adminGroups
      created
      updated
      organizationStudiesId
      __typename
    }
    metaDataValidationSchemaTemplateId
    metaDataValidationSchemaTemplate {
      id
      studyPhases {
        nextToken
        __typename
      }
      version
      description
      templateFiles
      validationSchemaTemplate
      created
      updated
      __typename
    }
    groups {
      items {
        id
        studyPhaseId
        type
        groupName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseGroupsId
        __typename
      }
      nextToken
      __typename
    }
    samples {
      items {
        id
        timepointId
        studyPhaseId
        BatchId
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
        __typename
      }
      nextToken
      __typename
    }
    batches {
      items {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      nextToken
      __typename
    }
    uploadBundles {
      items {
        id
        studyPhaseId
        bundleLog
        validationErrors
        status
        userEmail
        adminGroups
        readGroups
        completionTime
        created
        updated
        studyPhaseUploadBundlesId
        uploadBundleUploadBundleAnalysisSessionId
        owner
        __typename
      }
      nextToken
      __typename
    }
    pipelines {
      items {
        id
        name
        status
        description
        s3Event
        s3ParamsFile
        s3Results
        visFileInfoS3path
        s3NfLog
        s3NfTasksLogs
        analysisType
        subtypeOfAnalysis
        parameters
        studyId
        studyPhaseId
        scheduledPipeline
        scheduledDateTime
        launchablePipelineId
        batchId
        runId
        userEmail
        pipelineStarted
        pipelineCompleted
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPipelinesId
        studyPhasePipelinesId
        batchPipelinesId
        launchablePipelinesPipelinesId
        __typename
      }
      nextToken
      __typename
    }
    validationSchema
    description
    studyPhaseName
    studyPhaseAnswers
    experimentSpecificVariables
    sampleTypes
    specimens
    species
    s3Path
    groupNames
    labAssayTechnologies
    phase
    readGroups
    writeGroups
    adminGroups
    croGroups
    created
    updated
    studyStudyPhasesId
    metaDataValidationSchemaTemplateStudyPhasesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateStudyPhaseMutationVariables,
  APITypes.CreateStudyPhaseMutation
>;
export const updateStudyPhase = /* GraphQL */ `mutation UpdateStudyPhase(
  $input: UpdateStudyPhaseInput!
  $condition: ModelStudyPhaseConditionInput
) {
  updateStudyPhase(input: $input, condition: $condition) {
    id
    studyId
    study {
      id
      studyName
      organizationId
      organization {
        id
        organizationName
        organizationCode
        active
        notificationEmail
        description
        organizationAnswers
        url
        gitUrl
        s3Path
        readGroups
        writeGroups
        created
        updated
        __typename
      }
      studyPhases {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      description
      studyCode
      studyAnswers
      s3Path
      croGroups
      readGroups
      writeGroups
      adminGroups
      created
      updated
      organizationStudiesId
      __typename
    }
    metaDataValidationSchemaTemplateId
    metaDataValidationSchemaTemplate {
      id
      studyPhases {
        nextToken
        __typename
      }
      version
      description
      templateFiles
      validationSchemaTemplate
      created
      updated
      __typename
    }
    groups {
      items {
        id
        studyPhaseId
        type
        groupName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseGroupsId
        __typename
      }
      nextToken
      __typename
    }
    samples {
      items {
        id
        timepointId
        studyPhaseId
        BatchId
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
        __typename
      }
      nextToken
      __typename
    }
    batches {
      items {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      nextToken
      __typename
    }
    uploadBundles {
      items {
        id
        studyPhaseId
        bundleLog
        validationErrors
        status
        userEmail
        adminGroups
        readGroups
        completionTime
        created
        updated
        studyPhaseUploadBundlesId
        uploadBundleUploadBundleAnalysisSessionId
        owner
        __typename
      }
      nextToken
      __typename
    }
    pipelines {
      items {
        id
        name
        status
        description
        s3Event
        s3ParamsFile
        s3Results
        visFileInfoS3path
        s3NfLog
        s3NfTasksLogs
        analysisType
        subtypeOfAnalysis
        parameters
        studyId
        studyPhaseId
        scheduledPipeline
        scheduledDateTime
        launchablePipelineId
        batchId
        runId
        userEmail
        pipelineStarted
        pipelineCompleted
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPipelinesId
        studyPhasePipelinesId
        batchPipelinesId
        launchablePipelinesPipelinesId
        __typename
      }
      nextToken
      __typename
    }
    validationSchema
    description
    studyPhaseName
    studyPhaseAnswers
    experimentSpecificVariables
    sampleTypes
    specimens
    species
    s3Path
    groupNames
    labAssayTechnologies
    phase
    readGroups
    writeGroups
    adminGroups
    croGroups
    created
    updated
    studyStudyPhasesId
    metaDataValidationSchemaTemplateStudyPhasesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStudyPhaseMutationVariables,
  APITypes.UpdateStudyPhaseMutation
>;
export const deleteStudyPhase = /* GraphQL */ `mutation DeleteStudyPhase(
  $input: DeleteStudyPhaseInput!
  $condition: ModelStudyPhaseConditionInput
) {
  deleteStudyPhase(input: $input, condition: $condition) {
    id
    studyId
    study {
      id
      studyName
      organizationId
      organization {
        id
        organizationName
        organizationCode
        active
        notificationEmail
        description
        organizationAnswers
        url
        gitUrl
        s3Path
        readGroups
        writeGroups
        created
        updated
        __typename
      }
      studyPhases {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      description
      studyCode
      studyAnswers
      s3Path
      croGroups
      readGroups
      writeGroups
      adminGroups
      created
      updated
      organizationStudiesId
      __typename
    }
    metaDataValidationSchemaTemplateId
    metaDataValidationSchemaTemplate {
      id
      studyPhases {
        nextToken
        __typename
      }
      version
      description
      templateFiles
      validationSchemaTemplate
      created
      updated
      __typename
    }
    groups {
      items {
        id
        studyPhaseId
        type
        groupName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseGroupsId
        __typename
      }
      nextToken
      __typename
    }
    samples {
      items {
        id
        timepointId
        studyPhaseId
        BatchId
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
        __typename
      }
      nextToken
      __typename
    }
    batches {
      items {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      nextToken
      __typename
    }
    uploadBundles {
      items {
        id
        studyPhaseId
        bundleLog
        validationErrors
        status
        userEmail
        adminGroups
        readGroups
        completionTime
        created
        updated
        studyPhaseUploadBundlesId
        uploadBundleUploadBundleAnalysisSessionId
        owner
        __typename
      }
      nextToken
      __typename
    }
    pipelines {
      items {
        id
        name
        status
        description
        s3Event
        s3ParamsFile
        s3Results
        visFileInfoS3path
        s3NfLog
        s3NfTasksLogs
        analysisType
        subtypeOfAnalysis
        parameters
        studyId
        studyPhaseId
        scheduledPipeline
        scheduledDateTime
        launchablePipelineId
        batchId
        runId
        userEmail
        pipelineStarted
        pipelineCompleted
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPipelinesId
        studyPhasePipelinesId
        batchPipelinesId
        launchablePipelinesPipelinesId
        __typename
      }
      nextToken
      __typename
    }
    validationSchema
    description
    studyPhaseName
    studyPhaseAnswers
    experimentSpecificVariables
    sampleTypes
    specimens
    species
    s3Path
    groupNames
    labAssayTechnologies
    phase
    readGroups
    writeGroups
    adminGroups
    croGroups
    created
    updated
    studyStudyPhasesId
    metaDataValidationSchemaTemplateStudyPhasesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteStudyPhaseMutationVariables,
  APITypes.DeleteStudyPhaseMutation
>;
export const createEntityQuestions = /* GraphQL */ `mutation CreateEntityQuestions(
  $input: CreateEntityQuestionsInput!
  $condition: ModelEntityQuestionsConditionInput
) {
  createEntityQuestions(input: $input, condition: $condition) {
    id
    entityType
    questions
    created
    updated
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEntityQuestionsMutationVariables,
  APITypes.CreateEntityQuestionsMutation
>;
export const updateEntityQuestions = /* GraphQL */ `mutation UpdateEntityQuestions(
  $input: UpdateEntityQuestionsInput!
  $condition: ModelEntityQuestionsConditionInput
) {
  updateEntityQuestions(input: $input, condition: $condition) {
    id
    entityType
    questions
    created
    updated
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEntityQuestionsMutationVariables,
  APITypes.UpdateEntityQuestionsMutation
>;
export const deleteEntityQuestions = /* GraphQL */ `mutation DeleteEntityQuestions(
  $input: DeleteEntityQuestionsInput!
  $condition: ModelEntityQuestionsConditionInput
) {
  deleteEntityQuestions(input: $input, condition: $condition) {
    id
    entityType
    questions
    created
    updated
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEntityQuestionsMutationVariables,
  APITypes.DeleteEntityQuestionsMutation
>;
export const createMetaDataValidationSchemaTemplate = /* GraphQL */ `mutation CreateMetaDataValidationSchemaTemplate(
  $input: CreateMetaDataValidationSchemaTemplateInput!
  $condition: ModelMetaDataValidationSchemaTemplateConditionInput
) {
  createMetaDataValidationSchemaTemplate(input: $input, condition: $condition) {
    id
    studyPhases {
      items {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      nextToken
      __typename
    }
    version
    description
    templateFiles
    validationSchemaTemplate
    created
    updated
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMetaDataValidationSchemaTemplateMutationVariables,
  APITypes.CreateMetaDataValidationSchemaTemplateMutation
>;
export const updateMetaDataValidationSchemaTemplate = /* GraphQL */ `mutation UpdateMetaDataValidationSchemaTemplate(
  $input: UpdateMetaDataValidationSchemaTemplateInput!
  $condition: ModelMetaDataValidationSchemaTemplateConditionInput
) {
  updateMetaDataValidationSchemaTemplate(input: $input, condition: $condition) {
    id
    studyPhases {
      items {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      nextToken
      __typename
    }
    version
    description
    templateFiles
    validationSchemaTemplate
    created
    updated
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMetaDataValidationSchemaTemplateMutationVariables,
  APITypes.UpdateMetaDataValidationSchemaTemplateMutation
>;
export const deleteMetaDataValidationSchemaTemplate = /* GraphQL */ `mutation DeleteMetaDataValidationSchemaTemplate(
  $input: DeleteMetaDataValidationSchemaTemplateInput!
  $condition: ModelMetaDataValidationSchemaTemplateConditionInput
) {
  deleteMetaDataValidationSchemaTemplate(input: $input, condition: $condition) {
    id
    studyPhases {
      items {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      nextToken
      __typename
    }
    version
    description
    templateFiles
    validationSchemaTemplate
    created
    updated
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMetaDataValidationSchemaTemplateMutationVariables,
  APITypes.DeleteMetaDataValidationSchemaTemplateMutation
>;
export const createGroup = /* GraphQL */ `mutation CreateGroup(
  $input: CreateGroupInput!
  $condition: ModelGroupConditionInput
) {
  createGroup(input: $input, condition: $condition) {
    id
    studyPhaseId
    studyPhase {
      id
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      metaDataValidationSchemaTemplateId
      metaDataValidationSchemaTemplate {
        id
        version
        description
        templateFiles
        validationSchemaTemplate
        created
        updated
        __typename
      }
      groups {
        nextToken
        __typename
      }
      samples {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      uploadBundles {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      validationSchema
      description
      studyPhaseName
      studyPhaseAnswers
      experimentSpecificVariables
      sampleTypes
      specimens
      species
      s3Path
      groupNames
      labAssayTechnologies
      phase
      readGroups
      writeGroups
      adminGroups
      croGroups
      created
      updated
      studyStudyPhasesId
      metaDataValidationSchemaTemplateStudyPhasesId
      __typename
    }
    type
    groupName
    s3Path
    patients {
      items {
        id
        groupId
        patientStudyPhaseId
        patientStudyId
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        groupPatientsId
        __typename
      }
      nextToken
      __typename
    }
    readGroups
    writeGroups
    adminGroups
    created
    updated
    studyPhaseGroupsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGroupMutationVariables,
  APITypes.CreateGroupMutation
>;
export const updateGroup = /* GraphQL */ `mutation UpdateGroup(
  $input: UpdateGroupInput!
  $condition: ModelGroupConditionInput
) {
  updateGroup(input: $input, condition: $condition) {
    id
    studyPhaseId
    studyPhase {
      id
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      metaDataValidationSchemaTemplateId
      metaDataValidationSchemaTemplate {
        id
        version
        description
        templateFiles
        validationSchemaTemplate
        created
        updated
        __typename
      }
      groups {
        nextToken
        __typename
      }
      samples {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      uploadBundles {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      validationSchema
      description
      studyPhaseName
      studyPhaseAnswers
      experimentSpecificVariables
      sampleTypes
      specimens
      species
      s3Path
      groupNames
      labAssayTechnologies
      phase
      readGroups
      writeGroups
      adminGroups
      croGroups
      created
      updated
      studyStudyPhasesId
      metaDataValidationSchemaTemplateStudyPhasesId
      __typename
    }
    type
    groupName
    s3Path
    patients {
      items {
        id
        groupId
        patientStudyPhaseId
        patientStudyId
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        groupPatientsId
        __typename
      }
      nextToken
      __typename
    }
    readGroups
    writeGroups
    adminGroups
    created
    updated
    studyPhaseGroupsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGroupMutationVariables,
  APITypes.UpdateGroupMutation
>;
export const deleteGroup = /* GraphQL */ `mutation DeleteGroup(
  $input: DeleteGroupInput!
  $condition: ModelGroupConditionInput
) {
  deleteGroup(input: $input, condition: $condition) {
    id
    studyPhaseId
    studyPhase {
      id
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      metaDataValidationSchemaTemplateId
      metaDataValidationSchemaTemplate {
        id
        version
        description
        templateFiles
        validationSchemaTemplate
        created
        updated
        __typename
      }
      groups {
        nextToken
        __typename
      }
      samples {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      uploadBundles {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      validationSchema
      description
      studyPhaseName
      studyPhaseAnswers
      experimentSpecificVariables
      sampleTypes
      specimens
      species
      s3Path
      groupNames
      labAssayTechnologies
      phase
      readGroups
      writeGroups
      adminGroups
      croGroups
      created
      updated
      studyStudyPhasesId
      metaDataValidationSchemaTemplateStudyPhasesId
      __typename
    }
    type
    groupName
    s3Path
    patients {
      items {
        id
        groupId
        patientStudyPhaseId
        patientStudyId
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        groupPatientsId
        __typename
      }
      nextToken
      __typename
    }
    readGroups
    writeGroups
    adminGroups
    created
    updated
    studyPhaseGroupsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGroupMutationVariables,
  APITypes.DeleteGroupMutation
>;
export const createPatient = /* GraphQL */ `mutation CreatePatient(
  $input: CreatePatientInput!
  $condition: ModelPatientConditionInput
) {
  createPatient(input: $input, condition: $condition) {
    id
    groupId
    group {
      id
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      type
      groupName
      s3Path
      patients {
        nextToken
        __typename
      }
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseGroupsId
      __typename
    }
    timepoints {
      items {
        id
        patientId
        timepointName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        patientTimepointsId
        __typename
      }
      nextToken
      __typename
    }
    patientStudyPhaseId
    patientStudyId
    s3Path
    readGroups
    writeGroups
    adminGroups
    created
    updated
    groupPatientsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePatientMutationVariables,
  APITypes.CreatePatientMutation
>;
export const updatePatient = /* GraphQL */ `mutation UpdatePatient(
  $input: UpdatePatientInput!
  $condition: ModelPatientConditionInput
) {
  updatePatient(input: $input, condition: $condition) {
    id
    groupId
    group {
      id
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      type
      groupName
      s3Path
      patients {
        nextToken
        __typename
      }
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseGroupsId
      __typename
    }
    timepoints {
      items {
        id
        patientId
        timepointName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        patientTimepointsId
        __typename
      }
      nextToken
      __typename
    }
    patientStudyPhaseId
    patientStudyId
    s3Path
    readGroups
    writeGroups
    adminGroups
    created
    updated
    groupPatientsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePatientMutationVariables,
  APITypes.UpdatePatientMutation
>;
export const deletePatient = /* GraphQL */ `mutation DeletePatient(
  $input: DeletePatientInput!
  $condition: ModelPatientConditionInput
) {
  deletePatient(input: $input, condition: $condition) {
    id
    groupId
    group {
      id
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      type
      groupName
      s3Path
      patients {
        nextToken
        __typename
      }
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseGroupsId
      __typename
    }
    timepoints {
      items {
        id
        patientId
        timepointName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        patientTimepointsId
        __typename
      }
      nextToken
      __typename
    }
    patientStudyPhaseId
    patientStudyId
    s3Path
    readGroups
    writeGroups
    adminGroups
    created
    updated
    groupPatientsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePatientMutationVariables,
  APITypes.DeletePatientMutation
>;
export const createTimepoint = /* GraphQL */ `mutation CreateTimepoint(
  $input: CreateTimepointInput!
  $condition: ModelTimepointConditionInput
) {
  createTimepoint(input: $input, condition: $condition) {
    id
    patientId
    patient {
      id
      groupId
      group {
        id
        studyPhaseId
        type
        groupName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseGroupsId
        __typename
      }
      timepoints {
        nextToken
        __typename
      }
      patientStudyPhaseId
      patientStudyId
      s3Path
      readGroups
      writeGroups
      adminGroups
      created
      updated
      groupPatientsId
      __typename
    }
    samples {
      items {
        id
        timepointId
        studyPhaseId
        BatchId
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
        __typename
      }
      nextToken
      __typename
    }
    timepointName
    s3Path
    readGroups
    writeGroups
    adminGroups
    created
    updated
    patientTimepointsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTimepointMutationVariables,
  APITypes.CreateTimepointMutation
>;
export const updateTimepoint = /* GraphQL */ `mutation UpdateTimepoint(
  $input: UpdateTimepointInput!
  $condition: ModelTimepointConditionInput
) {
  updateTimepoint(input: $input, condition: $condition) {
    id
    patientId
    patient {
      id
      groupId
      group {
        id
        studyPhaseId
        type
        groupName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseGroupsId
        __typename
      }
      timepoints {
        nextToken
        __typename
      }
      patientStudyPhaseId
      patientStudyId
      s3Path
      readGroups
      writeGroups
      adminGroups
      created
      updated
      groupPatientsId
      __typename
    }
    samples {
      items {
        id
        timepointId
        studyPhaseId
        BatchId
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
        __typename
      }
      nextToken
      __typename
    }
    timepointName
    s3Path
    readGroups
    writeGroups
    adminGroups
    created
    updated
    patientTimepointsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTimepointMutationVariables,
  APITypes.UpdateTimepointMutation
>;
export const deleteTimepoint = /* GraphQL */ `mutation DeleteTimepoint(
  $input: DeleteTimepointInput!
  $condition: ModelTimepointConditionInput
) {
  deleteTimepoint(input: $input, condition: $condition) {
    id
    patientId
    patient {
      id
      groupId
      group {
        id
        studyPhaseId
        type
        groupName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseGroupsId
        __typename
      }
      timepoints {
        nextToken
        __typename
      }
      patientStudyPhaseId
      patientStudyId
      s3Path
      readGroups
      writeGroups
      adminGroups
      created
      updated
      groupPatientsId
      __typename
    }
    samples {
      items {
        id
        timepointId
        studyPhaseId
        BatchId
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
        __typename
      }
      nextToken
      __typename
    }
    timepointName
    s3Path
    readGroups
    writeGroups
    adminGroups
    created
    updated
    patientTimepointsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTimepointMutationVariables,
  APITypes.DeleteTimepointMutation
>;
export const createSample = /* GraphQL */ `mutation CreateSample(
  $input: CreateSampleInput!
  $condition: ModelSampleConditionInput
) {
  createSample(input: $input, condition: $condition) {
    id
    timepointId
    timepoint {
      id
      patientId
      patient {
        id
        groupId
        patientStudyPhaseId
        patientStudyId
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        groupPatientsId
        __typename
      }
      samples {
        nextToken
        __typename
      }
      timepointName
      s3Path
      readGroups
      writeGroups
      adminGroups
      created
      updated
      patientTimepointsId
      __typename
    }
    studyPhaseId
    StudyPhase {
      id
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      metaDataValidationSchemaTemplateId
      metaDataValidationSchemaTemplate {
        id
        version
        description
        templateFiles
        validationSchemaTemplate
        created
        updated
        __typename
      }
      groups {
        nextToken
        __typename
      }
      samples {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      uploadBundles {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      validationSchema
      description
      studyPhaseName
      studyPhaseAnswers
      experimentSpecificVariables
      sampleTypes
      specimens
      species
      s3Path
      groupNames
      labAssayTechnologies
      phase
      readGroups
      writeGroups
      adminGroups
      croGroups
      created
      updated
      studyStudyPhasesId
      metaDataValidationSchemaTemplateStudyPhasesId
      __typename
    }
    BatchId
    Batch {
      id
      batchName
      batchAnnotations
      batchGroups
      annotations
      controlSamples
      filters
      description
      samples {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyBatchesId
      studyPhaseBatchesId
      __typename
    }
    metadataImmunopeptidomicsList {
      items {
        id
        sampleId
        metaDataTemplateS3Path
        species
        subtypeOfAnalysis
        sampleType
        qcStatus
        glacierBackup
        rawDataS3
        tumorVolume
        readGroups
        writeGroups
        adminGroups
        created
        updated
        isControl
        barcodesMappedToControl
        barcode
        s3Path
        sampleMetadataImmunopeptidomicsListId
        __typename
      }
      nextToken
      __typename
    }
    MetadataNanoStringList {
      items {
        id
        sampleId
        metaDataTemplateS3Path
        subtypeOfAnalysis
        species
        sampleType
        qcStatus
        glacierBackup
        rawDataS3
        tumorVolume
        dv200Percentage
        concNanoGramPerMicroL
        rnaMassNanoGram
        rnaVolumeMicroL
        rin
        readGroups
        writeGroups
        adminGroups
        created
        updated
        isControl
        barcodesMappedToControl
        barcode
        s3Path
        sampleMetadataNanoStringListId
        __typename
      }
      nextToken
      __typename
    }
    MetadataRnaSeqList {
      items {
        id
        sampleId
        metaDataTemplateS3Path
        subtypeOfAnalysis
        species
        sampleType
        qcStatus
        glacierBackup
        rawDataS3
        tumorVolume
        sequenceType
        strandSpecific
        UMI
        adaptorsRemoved
        adaptorsS3Path
        indexSequences
        concNanoGramPerMicroL
        rnaMassNanoGram
        rnaVolumeMicroL
        rin
        readGroups
        writeGroups
        adminGroups
        created
        updated
        isControl
        barcodesMappedToControl
        barcode
        s3Path
        sampleMetadataRnaSeqListId
        __typename
      }
      nextToken
      __typename
    }
    AuxiliaryAnalysislList {
      items {
        id
        sampleId
        auxiliaryFileS3Path
        vendorId
        specimenType
        parameters
        collectionDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        sampleAuxiliaryAnalysislListId
        __typename
      }
      nextToken
      __typename
    }
    domainSampleId
    experimentSpecificVariables
    collectionDate
    processingDate
    readGroups
    writeGroups
    adminGroups
    created
    updated
    studyPhaseSamplesId
    timepointSamplesId
    batchSamplesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSampleMutationVariables,
  APITypes.CreateSampleMutation
>;
export const updateSample = /* GraphQL */ `mutation UpdateSample(
  $input: UpdateSampleInput!
  $condition: ModelSampleConditionInput
) {
  updateSample(input: $input, condition: $condition) {
    id
    timepointId
    timepoint {
      id
      patientId
      patient {
        id
        groupId
        patientStudyPhaseId
        patientStudyId
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        groupPatientsId
        __typename
      }
      samples {
        nextToken
        __typename
      }
      timepointName
      s3Path
      readGroups
      writeGroups
      adminGroups
      created
      updated
      patientTimepointsId
      __typename
    }
    studyPhaseId
    StudyPhase {
      id
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      metaDataValidationSchemaTemplateId
      metaDataValidationSchemaTemplate {
        id
        version
        description
        templateFiles
        validationSchemaTemplate
        created
        updated
        __typename
      }
      groups {
        nextToken
        __typename
      }
      samples {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      uploadBundles {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      validationSchema
      description
      studyPhaseName
      studyPhaseAnswers
      experimentSpecificVariables
      sampleTypes
      specimens
      species
      s3Path
      groupNames
      labAssayTechnologies
      phase
      readGroups
      writeGroups
      adminGroups
      croGroups
      created
      updated
      studyStudyPhasesId
      metaDataValidationSchemaTemplateStudyPhasesId
      __typename
    }
    BatchId
    Batch {
      id
      batchName
      batchAnnotations
      batchGroups
      annotations
      controlSamples
      filters
      description
      samples {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyBatchesId
      studyPhaseBatchesId
      __typename
    }
    metadataImmunopeptidomicsList {
      items {
        id
        sampleId
        metaDataTemplateS3Path
        species
        subtypeOfAnalysis
        sampleType
        qcStatus
        glacierBackup
        rawDataS3
        tumorVolume
        readGroups
        writeGroups
        adminGroups
        created
        updated
        isControl
        barcodesMappedToControl
        barcode
        s3Path
        sampleMetadataImmunopeptidomicsListId
        __typename
      }
      nextToken
      __typename
    }
    MetadataNanoStringList {
      items {
        id
        sampleId
        metaDataTemplateS3Path
        subtypeOfAnalysis
        species
        sampleType
        qcStatus
        glacierBackup
        rawDataS3
        tumorVolume
        dv200Percentage
        concNanoGramPerMicroL
        rnaMassNanoGram
        rnaVolumeMicroL
        rin
        readGroups
        writeGroups
        adminGroups
        created
        updated
        isControl
        barcodesMappedToControl
        barcode
        s3Path
        sampleMetadataNanoStringListId
        __typename
      }
      nextToken
      __typename
    }
    MetadataRnaSeqList {
      items {
        id
        sampleId
        metaDataTemplateS3Path
        subtypeOfAnalysis
        species
        sampleType
        qcStatus
        glacierBackup
        rawDataS3
        tumorVolume
        sequenceType
        strandSpecific
        UMI
        adaptorsRemoved
        adaptorsS3Path
        indexSequences
        concNanoGramPerMicroL
        rnaMassNanoGram
        rnaVolumeMicroL
        rin
        readGroups
        writeGroups
        adminGroups
        created
        updated
        isControl
        barcodesMappedToControl
        barcode
        s3Path
        sampleMetadataRnaSeqListId
        __typename
      }
      nextToken
      __typename
    }
    AuxiliaryAnalysislList {
      items {
        id
        sampleId
        auxiliaryFileS3Path
        vendorId
        specimenType
        parameters
        collectionDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        sampleAuxiliaryAnalysislListId
        __typename
      }
      nextToken
      __typename
    }
    domainSampleId
    experimentSpecificVariables
    collectionDate
    processingDate
    readGroups
    writeGroups
    adminGroups
    created
    updated
    studyPhaseSamplesId
    timepointSamplesId
    batchSamplesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSampleMutationVariables,
  APITypes.UpdateSampleMutation
>;
export const deleteSample = /* GraphQL */ `mutation DeleteSample(
  $input: DeleteSampleInput!
  $condition: ModelSampleConditionInput
) {
  deleteSample(input: $input, condition: $condition) {
    id
    timepointId
    timepoint {
      id
      patientId
      patient {
        id
        groupId
        patientStudyPhaseId
        patientStudyId
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        groupPatientsId
        __typename
      }
      samples {
        nextToken
        __typename
      }
      timepointName
      s3Path
      readGroups
      writeGroups
      adminGroups
      created
      updated
      patientTimepointsId
      __typename
    }
    studyPhaseId
    StudyPhase {
      id
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      metaDataValidationSchemaTemplateId
      metaDataValidationSchemaTemplate {
        id
        version
        description
        templateFiles
        validationSchemaTemplate
        created
        updated
        __typename
      }
      groups {
        nextToken
        __typename
      }
      samples {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      uploadBundles {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      validationSchema
      description
      studyPhaseName
      studyPhaseAnswers
      experimentSpecificVariables
      sampleTypes
      specimens
      species
      s3Path
      groupNames
      labAssayTechnologies
      phase
      readGroups
      writeGroups
      adminGroups
      croGroups
      created
      updated
      studyStudyPhasesId
      metaDataValidationSchemaTemplateStudyPhasesId
      __typename
    }
    BatchId
    Batch {
      id
      batchName
      batchAnnotations
      batchGroups
      annotations
      controlSamples
      filters
      description
      samples {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyBatchesId
      studyPhaseBatchesId
      __typename
    }
    metadataImmunopeptidomicsList {
      items {
        id
        sampleId
        metaDataTemplateS3Path
        species
        subtypeOfAnalysis
        sampleType
        qcStatus
        glacierBackup
        rawDataS3
        tumorVolume
        readGroups
        writeGroups
        adminGroups
        created
        updated
        isControl
        barcodesMappedToControl
        barcode
        s3Path
        sampleMetadataImmunopeptidomicsListId
        __typename
      }
      nextToken
      __typename
    }
    MetadataNanoStringList {
      items {
        id
        sampleId
        metaDataTemplateS3Path
        subtypeOfAnalysis
        species
        sampleType
        qcStatus
        glacierBackup
        rawDataS3
        tumorVolume
        dv200Percentage
        concNanoGramPerMicroL
        rnaMassNanoGram
        rnaVolumeMicroL
        rin
        readGroups
        writeGroups
        adminGroups
        created
        updated
        isControl
        barcodesMappedToControl
        barcode
        s3Path
        sampleMetadataNanoStringListId
        __typename
      }
      nextToken
      __typename
    }
    MetadataRnaSeqList {
      items {
        id
        sampleId
        metaDataTemplateS3Path
        subtypeOfAnalysis
        species
        sampleType
        qcStatus
        glacierBackup
        rawDataS3
        tumorVolume
        sequenceType
        strandSpecific
        UMI
        adaptorsRemoved
        adaptorsS3Path
        indexSequences
        concNanoGramPerMicroL
        rnaMassNanoGram
        rnaVolumeMicroL
        rin
        readGroups
        writeGroups
        adminGroups
        created
        updated
        isControl
        barcodesMappedToControl
        barcode
        s3Path
        sampleMetadataRnaSeqListId
        __typename
      }
      nextToken
      __typename
    }
    AuxiliaryAnalysislList {
      items {
        id
        sampleId
        auxiliaryFileS3Path
        vendorId
        specimenType
        parameters
        collectionDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        sampleAuxiliaryAnalysislListId
        __typename
      }
      nextToken
      __typename
    }
    domainSampleId
    experimentSpecificVariables
    collectionDate
    processingDate
    readGroups
    writeGroups
    adminGroups
    created
    updated
    studyPhaseSamplesId
    timepointSamplesId
    batchSamplesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSampleMutationVariables,
  APITypes.DeleteSampleMutation
>;
export const createBatch = /* GraphQL */ `mutation CreateBatch(
  $input: CreateBatchInput!
  $condition: ModelBatchConditionInput
) {
  createBatch(input: $input, condition: $condition) {
    id
    batchName
    batchAnnotations
    batchGroups
    annotations
    controlSamples
    filters
    description
    samples {
      items {
        id
        timepointId
        studyPhaseId
        BatchId
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
        __typename
      }
      nextToken
      __typename
    }
    pipelines {
      items {
        id
        name
        status
        description
        s3Event
        s3ParamsFile
        s3Results
        visFileInfoS3path
        s3NfLog
        s3NfTasksLogs
        analysisType
        subtypeOfAnalysis
        parameters
        studyId
        studyPhaseId
        scheduledPipeline
        scheduledDateTime
        launchablePipelineId
        batchId
        runId
        userEmail
        pipelineStarted
        pipelineCompleted
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPipelinesId
        studyPhasePipelinesId
        batchPipelinesId
        launchablePipelinesPipelinesId
        __typename
      }
      nextToken
      __typename
    }
    studyId
    study {
      id
      studyName
      organizationId
      organization {
        id
        organizationName
        organizationCode
        active
        notificationEmail
        description
        organizationAnswers
        url
        gitUrl
        s3Path
        readGroups
        writeGroups
        created
        updated
        __typename
      }
      studyPhases {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      description
      studyCode
      studyAnswers
      s3Path
      croGroups
      readGroups
      writeGroups
      adminGroups
      created
      updated
      organizationStudiesId
      __typename
    }
    studyPhaseId
    studyPhase {
      id
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      metaDataValidationSchemaTemplateId
      metaDataValidationSchemaTemplate {
        id
        version
        description
        templateFiles
        validationSchemaTemplate
        created
        updated
        __typename
      }
      groups {
        nextToken
        __typename
      }
      samples {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      uploadBundles {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      validationSchema
      description
      studyPhaseName
      studyPhaseAnswers
      experimentSpecificVariables
      sampleTypes
      specimens
      species
      s3Path
      groupNames
      labAssayTechnologies
      phase
      readGroups
      writeGroups
      adminGroups
      croGroups
      created
      updated
      studyStudyPhasesId
      metaDataValidationSchemaTemplateStudyPhasesId
      __typename
    }
    readGroups
    writeGroups
    adminGroups
    created
    updated
    studyBatchesId
    studyPhaseBatchesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBatchMutationVariables,
  APITypes.CreateBatchMutation
>;
export const updateBatch = /* GraphQL */ `mutation UpdateBatch(
  $input: UpdateBatchInput!
  $condition: ModelBatchConditionInput
) {
  updateBatch(input: $input, condition: $condition) {
    id
    batchName
    batchAnnotations
    batchGroups
    annotations
    controlSamples
    filters
    description
    samples {
      items {
        id
        timepointId
        studyPhaseId
        BatchId
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
        __typename
      }
      nextToken
      __typename
    }
    pipelines {
      items {
        id
        name
        status
        description
        s3Event
        s3ParamsFile
        s3Results
        visFileInfoS3path
        s3NfLog
        s3NfTasksLogs
        analysisType
        subtypeOfAnalysis
        parameters
        studyId
        studyPhaseId
        scheduledPipeline
        scheduledDateTime
        launchablePipelineId
        batchId
        runId
        userEmail
        pipelineStarted
        pipelineCompleted
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPipelinesId
        studyPhasePipelinesId
        batchPipelinesId
        launchablePipelinesPipelinesId
        __typename
      }
      nextToken
      __typename
    }
    studyId
    study {
      id
      studyName
      organizationId
      organization {
        id
        organizationName
        organizationCode
        active
        notificationEmail
        description
        organizationAnswers
        url
        gitUrl
        s3Path
        readGroups
        writeGroups
        created
        updated
        __typename
      }
      studyPhases {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      description
      studyCode
      studyAnswers
      s3Path
      croGroups
      readGroups
      writeGroups
      adminGroups
      created
      updated
      organizationStudiesId
      __typename
    }
    studyPhaseId
    studyPhase {
      id
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      metaDataValidationSchemaTemplateId
      metaDataValidationSchemaTemplate {
        id
        version
        description
        templateFiles
        validationSchemaTemplate
        created
        updated
        __typename
      }
      groups {
        nextToken
        __typename
      }
      samples {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      uploadBundles {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      validationSchema
      description
      studyPhaseName
      studyPhaseAnswers
      experimentSpecificVariables
      sampleTypes
      specimens
      species
      s3Path
      groupNames
      labAssayTechnologies
      phase
      readGroups
      writeGroups
      adminGroups
      croGroups
      created
      updated
      studyStudyPhasesId
      metaDataValidationSchemaTemplateStudyPhasesId
      __typename
    }
    readGroups
    writeGroups
    adminGroups
    created
    updated
    studyBatchesId
    studyPhaseBatchesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBatchMutationVariables,
  APITypes.UpdateBatchMutation
>;
export const deleteBatch = /* GraphQL */ `mutation DeleteBatch(
  $input: DeleteBatchInput!
  $condition: ModelBatchConditionInput
) {
  deleteBatch(input: $input, condition: $condition) {
    id
    batchName
    batchAnnotations
    batchGroups
    annotations
    controlSamples
    filters
    description
    samples {
      items {
        id
        timepointId
        studyPhaseId
        BatchId
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
        __typename
      }
      nextToken
      __typename
    }
    pipelines {
      items {
        id
        name
        status
        description
        s3Event
        s3ParamsFile
        s3Results
        visFileInfoS3path
        s3NfLog
        s3NfTasksLogs
        analysisType
        subtypeOfAnalysis
        parameters
        studyId
        studyPhaseId
        scheduledPipeline
        scheduledDateTime
        launchablePipelineId
        batchId
        runId
        userEmail
        pipelineStarted
        pipelineCompleted
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPipelinesId
        studyPhasePipelinesId
        batchPipelinesId
        launchablePipelinesPipelinesId
        __typename
      }
      nextToken
      __typename
    }
    studyId
    study {
      id
      studyName
      organizationId
      organization {
        id
        organizationName
        organizationCode
        active
        notificationEmail
        description
        organizationAnswers
        url
        gitUrl
        s3Path
        readGroups
        writeGroups
        created
        updated
        __typename
      }
      studyPhases {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      description
      studyCode
      studyAnswers
      s3Path
      croGroups
      readGroups
      writeGroups
      adminGroups
      created
      updated
      organizationStudiesId
      __typename
    }
    studyPhaseId
    studyPhase {
      id
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      metaDataValidationSchemaTemplateId
      metaDataValidationSchemaTemplate {
        id
        version
        description
        templateFiles
        validationSchemaTemplate
        created
        updated
        __typename
      }
      groups {
        nextToken
        __typename
      }
      samples {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      uploadBundles {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      validationSchema
      description
      studyPhaseName
      studyPhaseAnswers
      experimentSpecificVariables
      sampleTypes
      specimens
      species
      s3Path
      groupNames
      labAssayTechnologies
      phase
      readGroups
      writeGroups
      adminGroups
      croGroups
      created
      updated
      studyStudyPhasesId
      metaDataValidationSchemaTemplateStudyPhasesId
      __typename
    }
    readGroups
    writeGroups
    adminGroups
    created
    updated
    studyBatchesId
    studyPhaseBatchesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBatchMutationVariables,
  APITypes.DeleteBatchMutation
>;
export const createPipeline = /* GraphQL */ `mutation CreatePipeline(
  $input: CreatePipelineInput!
  $condition: ModelPipelineConditionInput
) {
  createPipeline(input: $input, condition: $condition) {
    id
    name
    status
    description
    s3Event
    s3ParamsFile
    s3Results
    visFileInfoS3path
    s3NfLog
    s3NfTasksLogs
    analysisType
    subtypeOfAnalysis
    parameters
    studyId
    study {
      id
      studyName
      organizationId
      organization {
        id
        organizationName
        organizationCode
        active
        notificationEmail
        description
        organizationAnswers
        url
        gitUrl
        s3Path
        readGroups
        writeGroups
        created
        updated
        __typename
      }
      studyPhases {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      description
      studyCode
      studyAnswers
      s3Path
      croGroups
      readGroups
      writeGroups
      adminGroups
      created
      updated
      organizationStudiesId
      __typename
    }
    studyPhaseId
    studyPhase {
      id
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      metaDataValidationSchemaTemplateId
      metaDataValidationSchemaTemplate {
        id
        version
        description
        templateFiles
        validationSchemaTemplate
        created
        updated
        __typename
      }
      groups {
        nextToken
        __typename
      }
      samples {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      uploadBundles {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      validationSchema
      description
      studyPhaseName
      studyPhaseAnswers
      experimentSpecificVariables
      sampleTypes
      specimens
      species
      s3Path
      groupNames
      labAssayTechnologies
      phase
      readGroups
      writeGroups
      adminGroups
      croGroups
      created
      updated
      studyStudyPhasesId
      metaDataValidationSchemaTemplateStudyPhasesId
      __typename
    }
    scheduledPipeline
    scheduledDateTime
    launchablePipelineId
    launchablePipeline {
      id
      pipelineName
      organizations {
        nextToken
        __typename
      }
      pipelineVersion
      parameters
      description
      inputFileInfo
      supportedAnalyses
      supportedSubtypeOfAnalyses
      pipelines {
        nextToken
        __typename
      }
      gitRepo
      workflowId
      created
      updated
      __typename
    }
    batchId
    batch {
      id
      batchName
      batchAnnotations
      batchGroups
      annotations
      controlSamples
      filters
      description
      samples {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyBatchesId
      studyPhaseBatchesId
      __typename
    }
    runId
    userEmail
    pipelineStarted
    pipelineCompleted
    readGroups
    writeGroups
    adminGroups
    created
    updated
    studyPipelinesId
    studyPhasePipelinesId
    batchPipelinesId
    launchablePipelinesPipelinesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePipelineMutationVariables,
  APITypes.CreatePipelineMutation
>;
export const updatePipeline = /* GraphQL */ `mutation UpdatePipeline(
  $input: UpdatePipelineInput!
  $condition: ModelPipelineConditionInput
) {
  updatePipeline(input: $input, condition: $condition) {
    id
    name
    status
    description
    s3Event
    s3ParamsFile
    s3Results
    visFileInfoS3path
    s3NfLog
    s3NfTasksLogs
    analysisType
    subtypeOfAnalysis
    parameters
    studyId
    study {
      id
      studyName
      organizationId
      organization {
        id
        organizationName
        organizationCode
        active
        notificationEmail
        description
        organizationAnswers
        url
        gitUrl
        s3Path
        readGroups
        writeGroups
        created
        updated
        __typename
      }
      studyPhases {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      description
      studyCode
      studyAnswers
      s3Path
      croGroups
      readGroups
      writeGroups
      adminGroups
      created
      updated
      organizationStudiesId
      __typename
    }
    studyPhaseId
    studyPhase {
      id
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      metaDataValidationSchemaTemplateId
      metaDataValidationSchemaTemplate {
        id
        version
        description
        templateFiles
        validationSchemaTemplate
        created
        updated
        __typename
      }
      groups {
        nextToken
        __typename
      }
      samples {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      uploadBundles {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      validationSchema
      description
      studyPhaseName
      studyPhaseAnswers
      experimentSpecificVariables
      sampleTypes
      specimens
      species
      s3Path
      groupNames
      labAssayTechnologies
      phase
      readGroups
      writeGroups
      adminGroups
      croGroups
      created
      updated
      studyStudyPhasesId
      metaDataValidationSchemaTemplateStudyPhasesId
      __typename
    }
    scheduledPipeline
    scheduledDateTime
    launchablePipelineId
    launchablePipeline {
      id
      pipelineName
      organizations {
        nextToken
        __typename
      }
      pipelineVersion
      parameters
      description
      inputFileInfo
      supportedAnalyses
      supportedSubtypeOfAnalyses
      pipelines {
        nextToken
        __typename
      }
      gitRepo
      workflowId
      created
      updated
      __typename
    }
    batchId
    batch {
      id
      batchName
      batchAnnotations
      batchGroups
      annotations
      controlSamples
      filters
      description
      samples {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyBatchesId
      studyPhaseBatchesId
      __typename
    }
    runId
    userEmail
    pipelineStarted
    pipelineCompleted
    readGroups
    writeGroups
    adminGroups
    created
    updated
    studyPipelinesId
    studyPhasePipelinesId
    batchPipelinesId
    launchablePipelinesPipelinesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePipelineMutationVariables,
  APITypes.UpdatePipelineMutation
>;
export const deletePipeline = /* GraphQL */ `mutation DeletePipeline(
  $input: DeletePipelineInput!
  $condition: ModelPipelineConditionInput
) {
  deletePipeline(input: $input, condition: $condition) {
    id
    name
    status
    description
    s3Event
    s3ParamsFile
    s3Results
    visFileInfoS3path
    s3NfLog
    s3NfTasksLogs
    analysisType
    subtypeOfAnalysis
    parameters
    studyId
    study {
      id
      studyName
      organizationId
      organization {
        id
        organizationName
        organizationCode
        active
        notificationEmail
        description
        organizationAnswers
        url
        gitUrl
        s3Path
        readGroups
        writeGroups
        created
        updated
        __typename
      }
      studyPhases {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      description
      studyCode
      studyAnswers
      s3Path
      croGroups
      readGroups
      writeGroups
      adminGroups
      created
      updated
      organizationStudiesId
      __typename
    }
    studyPhaseId
    studyPhase {
      id
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      metaDataValidationSchemaTemplateId
      metaDataValidationSchemaTemplate {
        id
        version
        description
        templateFiles
        validationSchemaTemplate
        created
        updated
        __typename
      }
      groups {
        nextToken
        __typename
      }
      samples {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      uploadBundles {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      validationSchema
      description
      studyPhaseName
      studyPhaseAnswers
      experimentSpecificVariables
      sampleTypes
      specimens
      species
      s3Path
      groupNames
      labAssayTechnologies
      phase
      readGroups
      writeGroups
      adminGroups
      croGroups
      created
      updated
      studyStudyPhasesId
      metaDataValidationSchemaTemplateStudyPhasesId
      __typename
    }
    scheduledPipeline
    scheduledDateTime
    launchablePipelineId
    launchablePipeline {
      id
      pipelineName
      organizations {
        nextToken
        __typename
      }
      pipelineVersion
      parameters
      description
      inputFileInfo
      supportedAnalyses
      supportedSubtypeOfAnalyses
      pipelines {
        nextToken
        __typename
      }
      gitRepo
      workflowId
      created
      updated
      __typename
    }
    batchId
    batch {
      id
      batchName
      batchAnnotations
      batchGroups
      annotations
      controlSamples
      filters
      description
      samples {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyBatchesId
      studyPhaseBatchesId
      __typename
    }
    runId
    userEmail
    pipelineStarted
    pipelineCompleted
    readGroups
    writeGroups
    adminGroups
    created
    updated
    studyPipelinesId
    studyPhasePipelinesId
    batchPipelinesId
    launchablePipelinesPipelinesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePipelineMutationVariables,
  APITypes.DeletePipelineMutation
>;
export const createAuxiliaryAnalysis = /* GraphQL */ `mutation CreateAuxiliaryAnalysis(
  $input: CreateAuxiliaryAnalysisInput!
  $condition: ModelAuxiliaryAnalysisConditionInput
) {
  createAuxiliaryAnalysis(input: $input, condition: $condition) {
    id
    sampleId
    sample {
      id
      timepointId
      timepoint {
        id
        patientId
        timepointName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        patientTimepointsId
        __typename
      }
      studyPhaseId
      StudyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      BatchId
      Batch {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      metadataImmunopeptidomicsList {
        nextToken
        __typename
      }
      MetadataNanoStringList {
        nextToken
        __typename
      }
      MetadataRnaSeqList {
        nextToken
        __typename
      }
      AuxiliaryAnalysislList {
        nextToken
        __typename
      }
      domainSampleId
      experimentSpecificVariables
      collectionDate
      processingDate
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseSamplesId
      timepointSamplesId
      batchSamplesId
      __typename
    }
    auxiliaryFileS3Path
    vendorId
    specimenType
    parameters
    collectionDate
    readGroups
    writeGroups
    adminGroups
    created
    updated
    sampleAuxiliaryAnalysislListId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAuxiliaryAnalysisMutationVariables,
  APITypes.CreateAuxiliaryAnalysisMutation
>;
export const updateAuxiliaryAnalysis = /* GraphQL */ `mutation UpdateAuxiliaryAnalysis(
  $input: UpdateAuxiliaryAnalysisInput!
  $condition: ModelAuxiliaryAnalysisConditionInput
) {
  updateAuxiliaryAnalysis(input: $input, condition: $condition) {
    id
    sampleId
    sample {
      id
      timepointId
      timepoint {
        id
        patientId
        timepointName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        patientTimepointsId
        __typename
      }
      studyPhaseId
      StudyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      BatchId
      Batch {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      metadataImmunopeptidomicsList {
        nextToken
        __typename
      }
      MetadataNanoStringList {
        nextToken
        __typename
      }
      MetadataRnaSeqList {
        nextToken
        __typename
      }
      AuxiliaryAnalysislList {
        nextToken
        __typename
      }
      domainSampleId
      experimentSpecificVariables
      collectionDate
      processingDate
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseSamplesId
      timepointSamplesId
      batchSamplesId
      __typename
    }
    auxiliaryFileS3Path
    vendorId
    specimenType
    parameters
    collectionDate
    readGroups
    writeGroups
    adminGroups
    created
    updated
    sampleAuxiliaryAnalysislListId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAuxiliaryAnalysisMutationVariables,
  APITypes.UpdateAuxiliaryAnalysisMutation
>;
export const deleteAuxiliaryAnalysis = /* GraphQL */ `mutation DeleteAuxiliaryAnalysis(
  $input: DeleteAuxiliaryAnalysisInput!
  $condition: ModelAuxiliaryAnalysisConditionInput
) {
  deleteAuxiliaryAnalysis(input: $input, condition: $condition) {
    id
    sampleId
    sample {
      id
      timepointId
      timepoint {
        id
        patientId
        timepointName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        patientTimepointsId
        __typename
      }
      studyPhaseId
      StudyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      BatchId
      Batch {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      metadataImmunopeptidomicsList {
        nextToken
        __typename
      }
      MetadataNanoStringList {
        nextToken
        __typename
      }
      MetadataRnaSeqList {
        nextToken
        __typename
      }
      AuxiliaryAnalysislList {
        nextToken
        __typename
      }
      domainSampleId
      experimentSpecificVariables
      collectionDate
      processingDate
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseSamplesId
      timepointSamplesId
      batchSamplesId
      __typename
    }
    auxiliaryFileS3Path
    vendorId
    specimenType
    parameters
    collectionDate
    readGroups
    writeGroups
    adminGroups
    created
    updated
    sampleAuxiliaryAnalysislListId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAuxiliaryAnalysisMutationVariables,
  APITypes.DeleteAuxiliaryAnalysisMutation
>;
export const createMetadataImmunopeptidomics = /* GraphQL */ `mutation CreateMetadataImmunopeptidomics(
  $input: CreateMetadataImmunopeptidomicsInput!
  $condition: ModelMetadataImmunopeptidomicsConditionInput
) {
  createMetadataImmunopeptidomics(input: $input, condition: $condition) {
    id
    sampleId
    sample {
      id
      timepointId
      timepoint {
        id
        patientId
        timepointName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        patientTimepointsId
        __typename
      }
      studyPhaseId
      StudyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      BatchId
      Batch {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      metadataImmunopeptidomicsList {
        nextToken
        __typename
      }
      MetadataNanoStringList {
        nextToken
        __typename
      }
      MetadataRnaSeqList {
        nextToken
        __typename
      }
      AuxiliaryAnalysislList {
        nextToken
        __typename
      }
      domainSampleId
      experimentSpecificVariables
      collectionDate
      processingDate
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseSamplesId
      timepointSamplesId
      batchSamplesId
      __typename
    }
    treatment {
      items {
        id
        metadataImmunopeptidomicsId
        metadataNanoStringId
        metadataRnaSeqId
        treatmentName
        dose
        doseUnit
        readGroups
        writeGroups
        adminGroups
        created
        updated
        metadataImmunopeptidomicsTreatmentId
        metadataNanoStringTreatmentId
        metadataRnaSeqTreatmentId
        __typename
      }
      nextToken
      __typename
    }
    metaDataTemplateS3Path
    species
    subtypeOfAnalysis
    sampleType
    qcStatus
    glacierBackup
    rawDataS3
    tumorVolume
    readGroups
    writeGroups
    adminGroups
    created
    updated
    isControl
    barcodesMappedToControl
    barcode
    s3Path
    sampleMetadataImmunopeptidomicsListId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMetadataImmunopeptidomicsMutationVariables,
  APITypes.CreateMetadataImmunopeptidomicsMutation
>;
export const updateMetadataImmunopeptidomics = /* GraphQL */ `mutation UpdateMetadataImmunopeptidomics(
  $input: UpdateMetadataImmunopeptidomicsInput!
  $condition: ModelMetadataImmunopeptidomicsConditionInput
) {
  updateMetadataImmunopeptidomics(input: $input, condition: $condition) {
    id
    sampleId
    sample {
      id
      timepointId
      timepoint {
        id
        patientId
        timepointName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        patientTimepointsId
        __typename
      }
      studyPhaseId
      StudyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      BatchId
      Batch {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      metadataImmunopeptidomicsList {
        nextToken
        __typename
      }
      MetadataNanoStringList {
        nextToken
        __typename
      }
      MetadataRnaSeqList {
        nextToken
        __typename
      }
      AuxiliaryAnalysislList {
        nextToken
        __typename
      }
      domainSampleId
      experimentSpecificVariables
      collectionDate
      processingDate
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseSamplesId
      timepointSamplesId
      batchSamplesId
      __typename
    }
    treatment {
      items {
        id
        metadataImmunopeptidomicsId
        metadataNanoStringId
        metadataRnaSeqId
        treatmentName
        dose
        doseUnit
        readGroups
        writeGroups
        adminGroups
        created
        updated
        metadataImmunopeptidomicsTreatmentId
        metadataNanoStringTreatmentId
        metadataRnaSeqTreatmentId
        __typename
      }
      nextToken
      __typename
    }
    metaDataTemplateS3Path
    species
    subtypeOfAnalysis
    sampleType
    qcStatus
    glacierBackup
    rawDataS3
    tumorVolume
    readGroups
    writeGroups
    adminGroups
    created
    updated
    isControl
    barcodesMappedToControl
    barcode
    s3Path
    sampleMetadataImmunopeptidomicsListId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMetadataImmunopeptidomicsMutationVariables,
  APITypes.UpdateMetadataImmunopeptidomicsMutation
>;
export const deleteMetadataImmunopeptidomics = /* GraphQL */ `mutation DeleteMetadataImmunopeptidomics(
  $input: DeleteMetadataImmunopeptidomicsInput!
  $condition: ModelMetadataImmunopeptidomicsConditionInput
) {
  deleteMetadataImmunopeptidomics(input: $input, condition: $condition) {
    id
    sampleId
    sample {
      id
      timepointId
      timepoint {
        id
        patientId
        timepointName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        patientTimepointsId
        __typename
      }
      studyPhaseId
      StudyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      BatchId
      Batch {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      metadataImmunopeptidomicsList {
        nextToken
        __typename
      }
      MetadataNanoStringList {
        nextToken
        __typename
      }
      MetadataRnaSeqList {
        nextToken
        __typename
      }
      AuxiliaryAnalysislList {
        nextToken
        __typename
      }
      domainSampleId
      experimentSpecificVariables
      collectionDate
      processingDate
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseSamplesId
      timepointSamplesId
      batchSamplesId
      __typename
    }
    treatment {
      items {
        id
        metadataImmunopeptidomicsId
        metadataNanoStringId
        metadataRnaSeqId
        treatmentName
        dose
        doseUnit
        readGroups
        writeGroups
        adminGroups
        created
        updated
        metadataImmunopeptidomicsTreatmentId
        metadataNanoStringTreatmentId
        metadataRnaSeqTreatmentId
        __typename
      }
      nextToken
      __typename
    }
    metaDataTemplateS3Path
    species
    subtypeOfAnalysis
    sampleType
    qcStatus
    glacierBackup
    rawDataS3
    tumorVolume
    readGroups
    writeGroups
    adminGroups
    created
    updated
    isControl
    barcodesMappedToControl
    barcode
    s3Path
    sampleMetadataImmunopeptidomicsListId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMetadataImmunopeptidomicsMutationVariables,
  APITypes.DeleteMetadataImmunopeptidomicsMutation
>;
export const createMetadataNanoString = /* GraphQL */ `mutation CreateMetadataNanoString(
  $input: CreateMetadataNanoStringInput!
  $condition: ModelMetadataNanoStringConditionInput
) {
  createMetadataNanoString(input: $input, condition: $condition) {
    id
    sampleId
    sample {
      id
      timepointId
      timepoint {
        id
        patientId
        timepointName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        patientTimepointsId
        __typename
      }
      studyPhaseId
      StudyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      BatchId
      Batch {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      metadataImmunopeptidomicsList {
        nextToken
        __typename
      }
      MetadataNanoStringList {
        nextToken
        __typename
      }
      MetadataRnaSeqList {
        nextToken
        __typename
      }
      AuxiliaryAnalysislList {
        nextToken
        __typename
      }
      domainSampleId
      experimentSpecificVariables
      collectionDate
      processingDate
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseSamplesId
      timepointSamplesId
      batchSamplesId
      __typename
    }
    treatment {
      items {
        id
        metadataImmunopeptidomicsId
        metadataNanoStringId
        metadataRnaSeqId
        treatmentName
        dose
        doseUnit
        readGroups
        writeGroups
        adminGroups
        created
        updated
        metadataImmunopeptidomicsTreatmentId
        metadataNanoStringTreatmentId
        metadataRnaSeqTreatmentId
        __typename
      }
      nextToken
      __typename
    }
    metaDataTemplateS3Path
    subtypeOfAnalysis
    species
    sampleType
    qcStatus
    glacierBackup
    rawDataS3
    tumorVolume
    dv200Percentage
    concNanoGramPerMicroL
    rnaMassNanoGram
    rnaVolumeMicroL
    rin
    readGroups
    writeGroups
    adminGroups
    created
    updated
    isControl
    barcodesMappedToControl
    barcode
    s3Path
    sampleMetadataNanoStringListId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMetadataNanoStringMutationVariables,
  APITypes.CreateMetadataNanoStringMutation
>;
export const updateMetadataNanoString = /* GraphQL */ `mutation UpdateMetadataNanoString(
  $input: UpdateMetadataNanoStringInput!
  $condition: ModelMetadataNanoStringConditionInput
) {
  updateMetadataNanoString(input: $input, condition: $condition) {
    id
    sampleId
    sample {
      id
      timepointId
      timepoint {
        id
        patientId
        timepointName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        patientTimepointsId
        __typename
      }
      studyPhaseId
      StudyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      BatchId
      Batch {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      metadataImmunopeptidomicsList {
        nextToken
        __typename
      }
      MetadataNanoStringList {
        nextToken
        __typename
      }
      MetadataRnaSeqList {
        nextToken
        __typename
      }
      AuxiliaryAnalysislList {
        nextToken
        __typename
      }
      domainSampleId
      experimentSpecificVariables
      collectionDate
      processingDate
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseSamplesId
      timepointSamplesId
      batchSamplesId
      __typename
    }
    treatment {
      items {
        id
        metadataImmunopeptidomicsId
        metadataNanoStringId
        metadataRnaSeqId
        treatmentName
        dose
        doseUnit
        readGroups
        writeGroups
        adminGroups
        created
        updated
        metadataImmunopeptidomicsTreatmentId
        metadataNanoStringTreatmentId
        metadataRnaSeqTreatmentId
        __typename
      }
      nextToken
      __typename
    }
    metaDataTemplateS3Path
    subtypeOfAnalysis
    species
    sampleType
    qcStatus
    glacierBackup
    rawDataS3
    tumorVolume
    dv200Percentage
    concNanoGramPerMicroL
    rnaMassNanoGram
    rnaVolumeMicroL
    rin
    readGroups
    writeGroups
    adminGroups
    created
    updated
    isControl
    barcodesMappedToControl
    barcode
    s3Path
    sampleMetadataNanoStringListId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMetadataNanoStringMutationVariables,
  APITypes.UpdateMetadataNanoStringMutation
>;
export const deleteMetadataNanoString = /* GraphQL */ `mutation DeleteMetadataNanoString(
  $input: DeleteMetadataNanoStringInput!
  $condition: ModelMetadataNanoStringConditionInput
) {
  deleteMetadataNanoString(input: $input, condition: $condition) {
    id
    sampleId
    sample {
      id
      timepointId
      timepoint {
        id
        patientId
        timepointName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        patientTimepointsId
        __typename
      }
      studyPhaseId
      StudyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      BatchId
      Batch {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      metadataImmunopeptidomicsList {
        nextToken
        __typename
      }
      MetadataNanoStringList {
        nextToken
        __typename
      }
      MetadataRnaSeqList {
        nextToken
        __typename
      }
      AuxiliaryAnalysislList {
        nextToken
        __typename
      }
      domainSampleId
      experimentSpecificVariables
      collectionDate
      processingDate
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseSamplesId
      timepointSamplesId
      batchSamplesId
      __typename
    }
    treatment {
      items {
        id
        metadataImmunopeptidomicsId
        metadataNanoStringId
        metadataRnaSeqId
        treatmentName
        dose
        doseUnit
        readGroups
        writeGroups
        adminGroups
        created
        updated
        metadataImmunopeptidomicsTreatmentId
        metadataNanoStringTreatmentId
        metadataRnaSeqTreatmentId
        __typename
      }
      nextToken
      __typename
    }
    metaDataTemplateS3Path
    subtypeOfAnalysis
    species
    sampleType
    qcStatus
    glacierBackup
    rawDataS3
    tumorVolume
    dv200Percentage
    concNanoGramPerMicroL
    rnaMassNanoGram
    rnaVolumeMicroL
    rin
    readGroups
    writeGroups
    adminGroups
    created
    updated
    isControl
    barcodesMappedToControl
    barcode
    s3Path
    sampleMetadataNanoStringListId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMetadataNanoStringMutationVariables,
  APITypes.DeleteMetadataNanoStringMutation
>;
export const createMetadataRnaSeq = /* GraphQL */ `mutation CreateMetadataRnaSeq(
  $input: CreateMetadataRnaSeqInput!
  $condition: ModelMetadataRnaSeqConditionInput
) {
  createMetadataRnaSeq(input: $input, condition: $condition) {
    id
    sampleId
    sample {
      id
      timepointId
      timepoint {
        id
        patientId
        timepointName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        patientTimepointsId
        __typename
      }
      studyPhaseId
      StudyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      BatchId
      Batch {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      metadataImmunopeptidomicsList {
        nextToken
        __typename
      }
      MetadataNanoStringList {
        nextToken
        __typename
      }
      MetadataRnaSeqList {
        nextToken
        __typename
      }
      AuxiliaryAnalysislList {
        nextToken
        __typename
      }
      domainSampleId
      experimentSpecificVariables
      collectionDate
      processingDate
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseSamplesId
      timepointSamplesId
      batchSamplesId
      __typename
    }
    treatment {
      items {
        id
        metadataImmunopeptidomicsId
        metadataNanoStringId
        metadataRnaSeqId
        treatmentName
        dose
        doseUnit
        readGroups
        writeGroups
        adminGroups
        created
        updated
        metadataImmunopeptidomicsTreatmentId
        metadataNanoStringTreatmentId
        metadataRnaSeqTreatmentId
        __typename
      }
      nextToken
      __typename
    }
    metaDataTemplateS3Path
    subtypeOfAnalysis
    species
    sampleType
    qcStatus
    glacierBackup
    rawDataS3
    tumorVolume
    sequenceType
    strandSpecific
    UMI
    adaptorsRemoved
    adaptorsS3Path
    indexSequences
    concNanoGramPerMicroL
    rnaMassNanoGram
    rnaVolumeMicroL
    rin
    readGroups
    writeGroups
    adminGroups
    created
    updated
    isControl
    barcodesMappedToControl
    barcode
    s3Path
    sampleMetadataRnaSeqListId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMetadataRnaSeqMutationVariables,
  APITypes.CreateMetadataRnaSeqMutation
>;
export const updateMetadataRnaSeq = /* GraphQL */ `mutation UpdateMetadataRnaSeq(
  $input: UpdateMetadataRnaSeqInput!
  $condition: ModelMetadataRnaSeqConditionInput
) {
  updateMetadataRnaSeq(input: $input, condition: $condition) {
    id
    sampleId
    sample {
      id
      timepointId
      timepoint {
        id
        patientId
        timepointName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        patientTimepointsId
        __typename
      }
      studyPhaseId
      StudyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      BatchId
      Batch {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      metadataImmunopeptidomicsList {
        nextToken
        __typename
      }
      MetadataNanoStringList {
        nextToken
        __typename
      }
      MetadataRnaSeqList {
        nextToken
        __typename
      }
      AuxiliaryAnalysislList {
        nextToken
        __typename
      }
      domainSampleId
      experimentSpecificVariables
      collectionDate
      processingDate
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseSamplesId
      timepointSamplesId
      batchSamplesId
      __typename
    }
    treatment {
      items {
        id
        metadataImmunopeptidomicsId
        metadataNanoStringId
        metadataRnaSeqId
        treatmentName
        dose
        doseUnit
        readGroups
        writeGroups
        adminGroups
        created
        updated
        metadataImmunopeptidomicsTreatmentId
        metadataNanoStringTreatmentId
        metadataRnaSeqTreatmentId
        __typename
      }
      nextToken
      __typename
    }
    metaDataTemplateS3Path
    subtypeOfAnalysis
    species
    sampleType
    qcStatus
    glacierBackup
    rawDataS3
    tumorVolume
    sequenceType
    strandSpecific
    UMI
    adaptorsRemoved
    adaptorsS3Path
    indexSequences
    concNanoGramPerMicroL
    rnaMassNanoGram
    rnaVolumeMicroL
    rin
    readGroups
    writeGroups
    adminGroups
    created
    updated
    isControl
    barcodesMappedToControl
    barcode
    s3Path
    sampleMetadataRnaSeqListId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMetadataRnaSeqMutationVariables,
  APITypes.UpdateMetadataRnaSeqMutation
>;
export const deleteMetadataRnaSeq = /* GraphQL */ `mutation DeleteMetadataRnaSeq(
  $input: DeleteMetadataRnaSeqInput!
  $condition: ModelMetadataRnaSeqConditionInput
) {
  deleteMetadataRnaSeq(input: $input, condition: $condition) {
    id
    sampleId
    sample {
      id
      timepointId
      timepoint {
        id
        patientId
        timepointName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        patientTimepointsId
        __typename
      }
      studyPhaseId
      StudyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      BatchId
      Batch {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      metadataImmunopeptidomicsList {
        nextToken
        __typename
      }
      MetadataNanoStringList {
        nextToken
        __typename
      }
      MetadataRnaSeqList {
        nextToken
        __typename
      }
      AuxiliaryAnalysislList {
        nextToken
        __typename
      }
      domainSampleId
      experimentSpecificVariables
      collectionDate
      processingDate
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseSamplesId
      timepointSamplesId
      batchSamplesId
      __typename
    }
    treatment {
      items {
        id
        metadataImmunopeptidomicsId
        metadataNanoStringId
        metadataRnaSeqId
        treatmentName
        dose
        doseUnit
        readGroups
        writeGroups
        adminGroups
        created
        updated
        metadataImmunopeptidomicsTreatmentId
        metadataNanoStringTreatmentId
        metadataRnaSeqTreatmentId
        __typename
      }
      nextToken
      __typename
    }
    metaDataTemplateS3Path
    subtypeOfAnalysis
    species
    sampleType
    qcStatus
    glacierBackup
    rawDataS3
    tumorVolume
    sequenceType
    strandSpecific
    UMI
    adaptorsRemoved
    adaptorsS3Path
    indexSequences
    concNanoGramPerMicroL
    rnaMassNanoGram
    rnaVolumeMicroL
    rin
    readGroups
    writeGroups
    adminGroups
    created
    updated
    isControl
    barcodesMappedToControl
    barcode
    s3Path
    sampleMetadataRnaSeqListId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMetadataRnaSeqMutationVariables,
  APITypes.DeleteMetadataRnaSeqMutation
>;
export const createTreatment = /* GraphQL */ `mutation CreateTreatment(
  $input: CreateTreatmentInput!
  $condition: ModelTreatmentConditionInput
) {
  createTreatment(input: $input, condition: $condition) {
    id
    metadataImmunopeptidomicsId
    metadataImmunopeptidomics {
      id
      sampleId
      sample {
        id
        timepointId
        studyPhaseId
        BatchId
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
        __typename
      }
      treatment {
        nextToken
        __typename
      }
      metaDataTemplateS3Path
      species
      subtypeOfAnalysis
      sampleType
      qcStatus
      glacierBackup
      rawDataS3
      tumorVolume
      readGroups
      writeGroups
      adminGroups
      created
      updated
      isControl
      barcodesMappedToControl
      barcode
      s3Path
      sampleMetadataImmunopeptidomicsListId
      __typename
    }
    metadataNanoStringId
    metadataNanoString {
      id
      sampleId
      sample {
        id
        timepointId
        studyPhaseId
        BatchId
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
        __typename
      }
      treatment {
        nextToken
        __typename
      }
      metaDataTemplateS3Path
      subtypeOfAnalysis
      species
      sampleType
      qcStatus
      glacierBackup
      rawDataS3
      tumorVolume
      dv200Percentage
      concNanoGramPerMicroL
      rnaMassNanoGram
      rnaVolumeMicroL
      rin
      readGroups
      writeGroups
      adminGroups
      created
      updated
      isControl
      barcodesMappedToControl
      barcode
      s3Path
      sampleMetadataNanoStringListId
      __typename
    }
    metadataRnaSeqId
    metadataRnaSeq {
      id
      sampleId
      sample {
        id
        timepointId
        studyPhaseId
        BatchId
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
        __typename
      }
      treatment {
        nextToken
        __typename
      }
      metaDataTemplateS3Path
      subtypeOfAnalysis
      species
      sampleType
      qcStatus
      glacierBackup
      rawDataS3
      tumorVolume
      sequenceType
      strandSpecific
      UMI
      adaptorsRemoved
      adaptorsS3Path
      indexSequences
      concNanoGramPerMicroL
      rnaMassNanoGram
      rnaVolumeMicroL
      rin
      readGroups
      writeGroups
      adminGroups
      created
      updated
      isControl
      barcodesMappedToControl
      barcode
      s3Path
      sampleMetadataRnaSeqListId
      __typename
    }
    treatmentName
    dose
    doseUnit
    readGroups
    writeGroups
    adminGroups
    created
    updated
    metadataImmunopeptidomicsTreatmentId
    metadataNanoStringTreatmentId
    metadataRnaSeqTreatmentId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTreatmentMutationVariables,
  APITypes.CreateTreatmentMutation
>;
export const updateTreatment = /* GraphQL */ `mutation UpdateTreatment(
  $input: UpdateTreatmentInput!
  $condition: ModelTreatmentConditionInput
) {
  updateTreatment(input: $input, condition: $condition) {
    id
    metadataImmunopeptidomicsId
    metadataImmunopeptidomics {
      id
      sampleId
      sample {
        id
        timepointId
        studyPhaseId
        BatchId
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
        __typename
      }
      treatment {
        nextToken
        __typename
      }
      metaDataTemplateS3Path
      species
      subtypeOfAnalysis
      sampleType
      qcStatus
      glacierBackup
      rawDataS3
      tumorVolume
      readGroups
      writeGroups
      adminGroups
      created
      updated
      isControl
      barcodesMappedToControl
      barcode
      s3Path
      sampleMetadataImmunopeptidomicsListId
      __typename
    }
    metadataNanoStringId
    metadataNanoString {
      id
      sampleId
      sample {
        id
        timepointId
        studyPhaseId
        BatchId
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
        __typename
      }
      treatment {
        nextToken
        __typename
      }
      metaDataTemplateS3Path
      subtypeOfAnalysis
      species
      sampleType
      qcStatus
      glacierBackup
      rawDataS3
      tumorVolume
      dv200Percentage
      concNanoGramPerMicroL
      rnaMassNanoGram
      rnaVolumeMicroL
      rin
      readGroups
      writeGroups
      adminGroups
      created
      updated
      isControl
      barcodesMappedToControl
      barcode
      s3Path
      sampleMetadataNanoStringListId
      __typename
    }
    metadataRnaSeqId
    metadataRnaSeq {
      id
      sampleId
      sample {
        id
        timepointId
        studyPhaseId
        BatchId
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
        __typename
      }
      treatment {
        nextToken
        __typename
      }
      metaDataTemplateS3Path
      subtypeOfAnalysis
      species
      sampleType
      qcStatus
      glacierBackup
      rawDataS3
      tumorVolume
      sequenceType
      strandSpecific
      UMI
      adaptorsRemoved
      adaptorsS3Path
      indexSequences
      concNanoGramPerMicroL
      rnaMassNanoGram
      rnaVolumeMicroL
      rin
      readGroups
      writeGroups
      adminGroups
      created
      updated
      isControl
      barcodesMappedToControl
      barcode
      s3Path
      sampleMetadataRnaSeqListId
      __typename
    }
    treatmentName
    dose
    doseUnit
    readGroups
    writeGroups
    adminGroups
    created
    updated
    metadataImmunopeptidomicsTreatmentId
    metadataNanoStringTreatmentId
    metadataRnaSeqTreatmentId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTreatmentMutationVariables,
  APITypes.UpdateTreatmentMutation
>;
export const deleteTreatment = /* GraphQL */ `mutation DeleteTreatment(
  $input: DeleteTreatmentInput!
  $condition: ModelTreatmentConditionInput
) {
  deleteTreatment(input: $input, condition: $condition) {
    id
    metadataImmunopeptidomicsId
    metadataImmunopeptidomics {
      id
      sampleId
      sample {
        id
        timepointId
        studyPhaseId
        BatchId
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
        __typename
      }
      treatment {
        nextToken
        __typename
      }
      metaDataTemplateS3Path
      species
      subtypeOfAnalysis
      sampleType
      qcStatus
      glacierBackup
      rawDataS3
      tumorVolume
      readGroups
      writeGroups
      adminGroups
      created
      updated
      isControl
      barcodesMappedToControl
      barcode
      s3Path
      sampleMetadataImmunopeptidomicsListId
      __typename
    }
    metadataNanoStringId
    metadataNanoString {
      id
      sampleId
      sample {
        id
        timepointId
        studyPhaseId
        BatchId
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
        __typename
      }
      treatment {
        nextToken
        __typename
      }
      metaDataTemplateS3Path
      subtypeOfAnalysis
      species
      sampleType
      qcStatus
      glacierBackup
      rawDataS3
      tumorVolume
      dv200Percentage
      concNanoGramPerMicroL
      rnaMassNanoGram
      rnaVolumeMicroL
      rin
      readGroups
      writeGroups
      adminGroups
      created
      updated
      isControl
      barcodesMappedToControl
      barcode
      s3Path
      sampleMetadataNanoStringListId
      __typename
    }
    metadataRnaSeqId
    metadataRnaSeq {
      id
      sampleId
      sample {
        id
        timepointId
        studyPhaseId
        BatchId
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
        __typename
      }
      treatment {
        nextToken
        __typename
      }
      metaDataTemplateS3Path
      subtypeOfAnalysis
      species
      sampleType
      qcStatus
      glacierBackup
      rawDataS3
      tumorVolume
      sequenceType
      strandSpecific
      UMI
      adaptorsRemoved
      adaptorsS3Path
      indexSequences
      concNanoGramPerMicroL
      rnaMassNanoGram
      rnaVolumeMicroL
      rin
      readGroups
      writeGroups
      adminGroups
      created
      updated
      isControl
      barcodesMappedToControl
      barcode
      s3Path
      sampleMetadataRnaSeqListId
      __typename
    }
    treatmentName
    dose
    doseUnit
    readGroups
    writeGroups
    adminGroups
    created
    updated
    metadataImmunopeptidomicsTreatmentId
    metadataNanoStringTreatmentId
    metadataRnaSeqTreatmentId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTreatmentMutationVariables,
  APITypes.DeleteTreatmentMutation
>;
export const createLaunchablePipelines = /* GraphQL */ `mutation CreateLaunchablePipelines(
  $input: CreateLaunchablePipelinesInput!
  $condition: ModelLaunchablePipelinesConditionInput
) {
  createLaunchablePipelines(input: $input, condition: $condition) {
    id
    pipelineName
    organizations {
      items {
        id
        organizationId
        launchablePipelinesId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    pipelineVersion
    parameters
    description
    inputFileInfo
    supportedAnalyses
    supportedSubtypeOfAnalyses
    pipelines {
      items {
        id
        name
        status
        description
        s3Event
        s3ParamsFile
        s3Results
        visFileInfoS3path
        s3NfLog
        s3NfTasksLogs
        analysisType
        subtypeOfAnalysis
        parameters
        studyId
        studyPhaseId
        scheduledPipeline
        scheduledDateTime
        launchablePipelineId
        batchId
        runId
        userEmail
        pipelineStarted
        pipelineCompleted
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPipelinesId
        studyPhasePipelinesId
        batchPipelinesId
        launchablePipelinesPipelinesId
        __typename
      }
      nextToken
      __typename
    }
    gitRepo
    workflowId
    created
    updated
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLaunchablePipelinesMutationVariables,
  APITypes.CreateLaunchablePipelinesMutation
>;
export const updateLaunchablePipelines = /* GraphQL */ `mutation UpdateLaunchablePipelines(
  $input: UpdateLaunchablePipelinesInput!
  $condition: ModelLaunchablePipelinesConditionInput
) {
  updateLaunchablePipelines(input: $input, condition: $condition) {
    id
    pipelineName
    organizations {
      items {
        id
        organizationId
        launchablePipelinesId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    pipelineVersion
    parameters
    description
    inputFileInfo
    supportedAnalyses
    supportedSubtypeOfAnalyses
    pipelines {
      items {
        id
        name
        status
        description
        s3Event
        s3ParamsFile
        s3Results
        visFileInfoS3path
        s3NfLog
        s3NfTasksLogs
        analysisType
        subtypeOfAnalysis
        parameters
        studyId
        studyPhaseId
        scheduledPipeline
        scheduledDateTime
        launchablePipelineId
        batchId
        runId
        userEmail
        pipelineStarted
        pipelineCompleted
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPipelinesId
        studyPhasePipelinesId
        batchPipelinesId
        launchablePipelinesPipelinesId
        __typename
      }
      nextToken
      __typename
    }
    gitRepo
    workflowId
    created
    updated
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLaunchablePipelinesMutationVariables,
  APITypes.UpdateLaunchablePipelinesMutation
>;
export const deleteLaunchablePipelines = /* GraphQL */ `mutation DeleteLaunchablePipelines(
  $input: DeleteLaunchablePipelinesInput!
  $condition: ModelLaunchablePipelinesConditionInput
) {
  deleteLaunchablePipelines(input: $input, condition: $condition) {
    id
    pipelineName
    organizations {
      items {
        id
        organizationId
        launchablePipelinesId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    pipelineVersion
    parameters
    description
    inputFileInfo
    supportedAnalyses
    supportedSubtypeOfAnalyses
    pipelines {
      items {
        id
        name
        status
        description
        s3Event
        s3ParamsFile
        s3Results
        visFileInfoS3path
        s3NfLog
        s3NfTasksLogs
        analysisType
        subtypeOfAnalysis
        parameters
        studyId
        studyPhaseId
        scheduledPipeline
        scheduledDateTime
        launchablePipelineId
        batchId
        runId
        userEmail
        pipelineStarted
        pipelineCompleted
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPipelinesId
        studyPhasePipelinesId
        batchPipelinesId
        launchablePipelinesPipelinesId
        __typename
      }
      nextToken
      __typename
    }
    gitRepo
    workflowId
    created
    updated
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLaunchablePipelinesMutationVariables,
  APITypes.DeleteLaunchablePipelinesMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    email
    organization
    gitToken
    gitName
    gitURL
    readGroups
    created
    updated
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    email
    organization
    gitToken
    gitName
    gitURL
    readGroups
    created
    updated
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    email
    organization
    gitToken
    gitName
    gitURL
    readGroups
    created
    updated
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createUploadLog = /* GraphQL */ `mutation CreateUploadLog(
  $input: CreateUploadLogInput!
  $condition: ModelUploadLogConditionInput
) {
  createUploadLog(input: $input, condition: $condition) {
    id
    uploadS3Path
    status
    readGroups
    created
    updated
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUploadLogMutationVariables,
  APITypes.CreateUploadLogMutation
>;
export const updateUploadLog = /* GraphQL */ `mutation UpdateUploadLog(
  $input: UpdateUploadLogInput!
  $condition: ModelUploadLogConditionInput
) {
  updateUploadLog(input: $input, condition: $condition) {
    id
    uploadS3Path
    status
    readGroups
    created
    updated
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUploadLogMutationVariables,
  APITypes.UpdateUploadLogMutation
>;
export const deleteUploadLog = /* GraphQL */ `mutation DeleteUploadLog(
  $input: DeleteUploadLogInput!
  $condition: ModelUploadLogConditionInput
) {
  deleteUploadLog(input: $input, condition: $condition) {
    id
    uploadS3Path
    status
    readGroups
    created
    updated
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUploadLogMutationVariables,
  APITypes.DeleteUploadLogMutation
>;
export const createVisualizations = /* GraphQL */ `mutation CreateVisualizations(
  $input: CreateVisualizationsInput!
  $condition: ModelVisualizationsConditionInput
) {
  createVisualizations(input: $input, condition: $condition) {
    id
    visualizationName
    description
    version
    deploymentPath
    validationSchema
    allowedExtensions
    supportedAnalyses
    created
    updated
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateVisualizationsMutationVariables,
  APITypes.CreateVisualizationsMutation
>;
export const updateVisualizations = /* GraphQL */ `mutation UpdateVisualizations(
  $input: UpdateVisualizationsInput!
  $condition: ModelVisualizationsConditionInput
) {
  updateVisualizations(input: $input, condition: $condition) {
    id
    visualizationName
    description
    version
    deploymentPath
    validationSchema
    allowedExtensions
    supportedAnalyses
    created
    updated
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateVisualizationsMutationVariables,
  APITypes.UpdateVisualizationsMutation
>;
export const deleteVisualizations = /* GraphQL */ `mutation DeleteVisualizations(
  $input: DeleteVisualizationsInput!
  $condition: ModelVisualizationsConditionInput
) {
  deleteVisualizations(input: $input, condition: $condition) {
    id
    visualizationName
    description
    version
    deploymentPath
    validationSchema
    allowedExtensions
    supportedAnalyses
    created
    updated
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteVisualizationsMutationVariables,
  APITypes.DeleteVisualizationsMutation
>;
export const createVisualizationSession = /* GraphQL */ `mutation CreateVisualizationSession(
  $input: CreateVisualizationSessionInput!
  $condition: ModelVisualizationSessionConditionInput
) {
  createVisualizationSession(input: $input, condition: $condition) {
    id
    statuses
    data
    description
    created
    updated
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateVisualizationSessionMutationVariables,
  APITypes.CreateVisualizationSessionMutation
>;
export const updateVisualizationSession = /* GraphQL */ `mutation UpdateVisualizationSession(
  $input: UpdateVisualizationSessionInput!
  $condition: ModelVisualizationSessionConditionInput
) {
  updateVisualizationSession(input: $input, condition: $condition) {
    id
    statuses
    data
    description
    created
    updated
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateVisualizationSessionMutationVariables,
  APITypes.UpdateVisualizationSessionMutation
>;
export const deleteVisualizationSession = /* GraphQL */ `mutation DeleteVisualizationSession(
  $input: DeleteVisualizationSessionInput!
  $condition: ModelVisualizationSessionConditionInput
) {
  deleteVisualizationSession(input: $input, condition: $condition) {
    id
    statuses
    data
    description
    created
    updated
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteVisualizationSessionMutationVariables,
  APITypes.DeleteVisualizationSessionMutation
>;
export const createDataLabEC2Instances = /* GraphQL */ `mutation CreateDataLabEC2Instances(
  $input: CreateDataLabEC2InstancesInput!
  $condition: ModelDataLabEC2InstancesConditionInput
) {
  createDataLabEC2Instances(input: $input, condition: $condition) {
    id
    creationTimestamp
    stoppedTimestamp
    startTimestamp
    terminatedTimestamp
    stoppedTimePeriod
    runningTimePeriod
    userEmail
    status
    type
    dataLabInstanceSessions {
      items {
        id
        dataLabInstanceId
        s3Path
        instanceLaunchEvent
        status
        title
        description
        created
        updated
        dataLabEC2InstancesDataLabInstanceSessionsId
        owner
        __typename
      }
      nextToken
      __typename
    }
    dataLabInstanceStatusChanges {
      items {
        id
        dataLabInstanceId
        organizationId
        previousStatus
        newStatus
        initiatedBy
        action
        performedBy
        additionalNotes
        created
        updated
        dataLabEC2InstancesDataLabInstanceStatusChangesId
        owner
        __typename
      }
      nextToken
      __typename
    }
    organizationId
    organizationName
    instanceUrl
    instanceId
    infrastructureRelatedEntities
    instanceLaunchEvent
    adminGroups
    created
    updated
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDataLabEC2InstancesMutationVariables,
  APITypes.CreateDataLabEC2InstancesMutation
>;
export const updateDataLabEC2Instances = /* GraphQL */ `mutation UpdateDataLabEC2Instances(
  $input: UpdateDataLabEC2InstancesInput!
  $condition: ModelDataLabEC2InstancesConditionInput
) {
  updateDataLabEC2Instances(input: $input, condition: $condition) {
    id
    creationTimestamp
    stoppedTimestamp
    startTimestamp
    terminatedTimestamp
    stoppedTimePeriod
    runningTimePeriod
    userEmail
    status
    type
    dataLabInstanceSessions {
      items {
        id
        dataLabInstanceId
        s3Path
        instanceLaunchEvent
        status
        title
        description
        created
        updated
        dataLabEC2InstancesDataLabInstanceSessionsId
        owner
        __typename
      }
      nextToken
      __typename
    }
    dataLabInstanceStatusChanges {
      items {
        id
        dataLabInstanceId
        organizationId
        previousStatus
        newStatus
        initiatedBy
        action
        performedBy
        additionalNotes
        created
        updated
        dataLabEC2InstancesDataLabInstanceStatusChangesId
        owner
        __typename
      }
      nextToken
      __typename
    }
    organizationId
    organizationName
    instanceUrl
    instanceId
    infrastructureRelatedEntities
    instanceLaunchEvent
    adminGroups
    created
    updated
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDataLabEC2InstancesMutationVariables,
  APITypes.UpdateDataLabEC2InstancesMutation
>;
export const deleteDataLabEC2Instances = /* GraphQL */ `mutation DeleteDataLabEC2Instances(
  $input: DeleteDataLabEC2InstancesInput!
  $condition: ModelDataLabEC2InstancesConditionInput
) {
  deleteDataLabEC2Instances(input: $input, condition: $condition) {
    id
    creationTimestamp
    stoppedTimestamp
    startTimestamp
    terminatedTimestamp
    stoppedTimePeriod
    runningTimePeriod
    userEmail
    status
    type
    dataLabInstanceSessions {
      items {
        id
        dataLabInstanceId
        s3Path
        instanceLaunchEvent
        status
        title
        description
        created
        updated
        dataLabEC2InstancesDataLabInstanceSessionsId
        owner
        __typename
      }
      nextToken
      __typename
    }
    dataLabInstanceStatusChanges {
      items {
        id
        dataLabInstanceId
        organizationId
        previousStatus
        newStatus
        initiatedBy
        action
        performedBy
        additionalNotes
        created
        updated
        dataLabEC2InstancesDataLabInstanceStatusChangesId
        owner
        __typename
      }
      nextToken
      __typename
    }
    organizationId
    organizationName
    instanceUrl
    instanceId
    infrastructureRelatedEntities
    instanceLaunchEvent
    adminGroups
    created
    updated
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDataLabEC2InstancesMutationVariables,
  APITypes.DeleteDataLabEC2InstancesMutation
>;
export const createDataLabEC2InstanceSession = /* GraphQL */ `mutation CreateDataLabEC2InstanceSession(
  $input: CreateDataLabEC2InstanceSessionInput!
  $condition: ModelDataLabEC2InstanceSessionConditionInput
) {
  createDataLabEC2InstanceSession(input: $input, condition: $condition) {
    id
    dataLabInstanceId
    dataLabInstance {
      id
      creationTimestamp
      stoppedTimestamp
      startTimestamp
      terminatedTimestamp
      stoppedTimePeriod
      runningTimePeriod
      userEmail
      status
      type
      dataLabInstanceSessions {
        nextToken
        __typename
      }
      dataLabInstanceStatusChanges {
        nextToken
        __typename
      }
      organizationId
      organizationName
      instanceUrl
      instanceId
      infrastructureRelatedEntities
      instanceLaunchEvent
      adminGroups
      created
      updated
      owner
      __typename
    }
    s3Path
    instanceLaunchEvent
    status
    title
    description
    created
    updated
    dataLabEC2InstancesDataLabInstanceSessionsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDataLabEC2InstanceSessionMutationVariables,
  APITypes.CreateDataLabEC2InstanceSessionMutation
>;
export const updateDataLabEC2InstanceSession = /* GraphQL */ `mutation UpdateDataLabEC2InstanceSession(
  $input: UpdateDataLabEC2InstanceSessionInput!
  $condition: ModelDataLabEC2InstanceSessionConditionInput
) {
  updateDataLabEC2InstanceSession(input: $input, condition: $condition) {
    id
    dataLabInstanceId
    dataLabInstance {
      id
      creationTimestamp
      stoppedTimestamp
      startTimestamp
      terminatedTimestamp
      stoppedTimePeriod
      runningTimePeriod
      userEmail
      status
      type
      dataLabInstanceSessions {
        nextToken
        __typename
      }
      dataLabInstanceStatusChanges {
        nextToken
        __typename
      }
      organizationId
      organizationName
      instanceUrl
      instanceId
      infrastructureRelatedEntities
      instanceLaunchEvent
      adminGroups
      created
      updated
      owner
      __typename
    }
    s3Path
    instanceLaunchEvent
    status
    title
    description
    created
    updated
    dataLabEC2InstancesDataLabInstanceSessionsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDataLabEC2InstanceSessionMutationVariables,
  APITypes.UpdateDataLabEC2InstanceSessionMutation
>;
export const deleteDataLabEC2InstanceSession = /* GraphQL */ `mutation DeleteDataLabEC2InstanceSession(
  $input: DeleteDataLabEC2InstanceSessionInput!
  $condition: ModelDataLabEC2InstanceSessionConditionInput
) {
  deleteDataLabEC2InstanceSession(input: $input, condition: $condition) {
    id
    dataLabInstanceId
    dataLabInstance {
      id
      creationTimestamp
      stoppedTimestamp
      startTimestamp
      terminatedTimestamp
      stoppedTimePeriod
      runningTimePeriod
      userEmail
      status
      type
      dataLabInstanceSessions {
        nextToken
        __typename
      }
      dataLabInstanceStatusChanges {
        nextToken
        __typename
      }
      organizationId
      organizationName
      instanceUrl
      instanceId
      infrastructureRelatedEntities
      instanceLaunchEvent
      adminGroups
      created
      updated
      owner
      __typename
    }
    s3Path
    instanceLaunchEvent
    status
    title
    description
    created
    updated
    dataLabEC2InstancesDataLabInstanceSessionsId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDataLabEC2InstanceSessionMutationVariables,
  APITypes.DeleteDataLabEC2InstanceSessionMutation
>;
export const createDataLabEC2InstanceStatusChange = /* GraphQL */ `mutation CreateDataLabEC2InstanceStatusChange(
  $input: CreateDataLabEC2InstanceStatusChangeInput!
  $condition: ModelDataLabEC2InstanceStatusChangeConditionInput
) {
  createDataLabEC2InstanceStatusChange(input: $input, condition: $condition) {
    id
    dataLabInstanceId
    dataLabInstance {
      id
      creationTimestamp
      stoppedTimestamp
      startTimestamp
      terminatedTimestamp
      stoppedTimePeriod
      runningTimePeriod
      userEmail
      status
      type
      dataLabInstanceSessions {
        nextToken
        __typename
      }
      dataLabInstanceStatusChanges {
        nextToken
        __typename
      }
      organizationId
      organizationName
      instanceUrl
      instanceId
      infrastructureRelatedEntities
      instanceLaunchEvent
      adminGroups
      created
      updated
      owner
      __typename
    }
    organizationId
    previousStatus
    newStatus
    initiatedBy
    action
    performedBy
    additionalNotes
    created
    updated
    dataLabEC2InstancesDataLabInstanceStatusChangesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDataLabEC2InstanceStatusChangeMutationVariables,
  APITypes.CreateDataLabEC2InstanceStatusChangeMutation
>;
export const updateDataLabEC2InstanceStatusChange = /* GraphQL */ `mutation UpdateDataLabEC2InstanceStatusChange(
  $input: UpdateDataLabEC2InstanceStatusChangeInput!
  $condition: ModelDataLabEC2InstanceStatusChangeConditionInput
) {
  updateDataLabEC2InstanceStatusChange(input: $input, condition: $condition) {
    id
    dataLabInstanceId
    dataLabInstance {
      id
      creationTimestamp
      stoppedTimestamp
      startTimestamp
      terminatedTimestamp
      stoppedTimePeriod
      runningTimePeriod
      userEmail
      status
      type
      dataLabInstanceSessions {
        nextToken
        __typename
      }
      dataLabInstanceStatusChanges {
        nextToken
        __typename
      }
      organizationId
      organizationName
      instanceUrl
      instanceId
      infrastructureRelatedEntities
      instanceLaunchEvent
      adminGroups
      created
      updated
      owner
      __typename
    }
    organizationId
    previousStatus
    newStatus
    initiatedBy
    action
    performedBy
    additionalNotes
    created
    updated
    dataLabEC2InstancesDataLabInstanceStatusChangesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDataLabEC2InstanceStatusChangeMutationVariables,
  APITypes.UpdateDataLabEC2InstanceStatusChangeMutation
>;
export const deleteDataLabEC2InstanceStatusChange = /* GraphQL */ `mutation DeleteDataLabEC2InstanceStatusChange(
  $input: DeleteDataLabEC2InstanceStatusChangeInput!
  $condition: ModelDataLabEC2InstanceStatusChangeConditionInput
) {
  deleteDataLabEC2InstanceStatusChange(input: $input, condition: $condition) {
    id
    dataLabInstanceId
    dataLabInstance {
      id
      creationTimestamp
      stoppedTimestamp
      startTimestamp
      terminatedTimestamp
      stoppedTimePeriod
      runningTimePeriod
      userEmail
      status
      type
      dataLabInstanceSessions {
        nextToken
        __typename
      }
      dataLabInstanceStatusChanges {
        nextToken
        __typename
      }
      organizationId
      organizationName
      instanceUrl
      instanceId
      infrastructureRelatedEntities
      instanceLaunchEvent
      adminGroups
      created
      updated
      owner
      __typename
    }
    organizationId
    previousStatus
    newStatus
    initiatedBy
    action
    performedBy
    additionalNotes
    created
    updated
    dataLabEC2InstancesDataLabInstanceStatusChangesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDataLabEC2InstanceStatusChangeMutationVariables,
  APITypes.DeleteDataLabEC2InstanceStatusChangeMutation
>;
export const createUploadBundle = /* GraphQL */ `mutation CreateUploadBundle(
  $input: CreateUploadBundleInput!
  $condition: ModelUploadBundleConditionInput
) {
  createUploadBundle(input: $input, condition: $condition) {
    id
    studyPhaseId
    studyPhase {
      id
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      metaDataValidationSchemaTemplateId
      metaDataValidationSchemaTemplate {
        id
        version
        description
        templateFiles
        validationSchemaTemplate
        created
        updated
        __typename
      }
      groups {
        nextToken
        __typename
      }
      samples {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      uploadBundles {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      validationSchema
      description
      studyPhaseName
      studyPhaseAnswers
      experimentSpecificVariables
      sampleTypes
      specimens
      species
      s3Path
      groupNames
      labAssayTechnologies
      phase
      readGroups
      writeGroups
      adminGroups
      croGroups
      created
      updated
      studyStudyPhasesId
      metaDataValidationSchemaTemplateStudyPhasesId
      __typename
    }
    bundleLog
    validationErrors
    status
    userEmail
    uploadBundleAnalysisSession {
      id
      uploadBundleId
      uploadBundle {
        id
        studyPhaseId
        bundleLog
        validationErrors
        status
        userEmail
        adminGroups
        readGroups
        completionTime
        created
        updated
        studyPhaseUploadBundlesId
        uploadBundleUploadBundleAnalysisSessionId
        owner
        __typename
      }
      uploadSampleAnalysisSession {
        nextToken
        __typename
      }
      metadataFileS3Path
      step
      listOfRawFileNames
      analysisType
      subtypeOfAnalysis
      studyPhaseId
      metadataHelperLambdaResponseS3Path
      barcodeToFilesToUploadS3Path
      adminGroups
      readGroups
      created
      updated
      owner
      __typename
    }
    uploadSamples {
      items {
        id
        uploadBundleId
        sampleId
        barcode
        sampleLog
        writeMetadata
        status
        adminGroups
        readGroups
        created
        updated
        uploadBundleUploadSamplesId
        owner
        __typename
      }
      nextToken
      __typename
    }
    uploadFiles {
      items {
        id
        uploadSampleId
        uploadBundleId
        multipartUploadId
        status
        toSkipUpload
        fileName
        fileType
        s3Path
        size
        fileLog
        adminGroups
        readGroups
        created
        updated
        uploadBundleUploadFilesId
        uploadSampleUploadFilesId
        owner
        __typename
      }
      nextToken
      __typename
    }
    adminGroups
    readGroups
    completionTime
    created
    updated
    studyPhaseUploadBundlesId
    uploadBundleUploadBundleAnalysisSessionId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUploadBundleMutationVariables,
  APITypes.CreateUploadBundleMutation
>;
export const updateUploadBundle = /* GraphQL */ `mutation UpdateUploadBundle(
  $input: UpdateUploadBundleInput!
  $condition: ModelUploadBundleConditionInput
) {
  updateUploadBundle(input: $input, condition: $condition) {
    id
    studyPhaseId
    studyPhase {
      id
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      metaDataValidationSchemaTemplateId
      metaDataValidationSchemaTemplate {
        id
        version
        description
        templateFiles
        validationSchemaTemplate
        created
        updated
        __typename
      }
      groups {
        nextToken
        __typename
      }
      samples {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      uploadBundles {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      validationSchema
      description
      studyPhaseName
      studyPhaseAnswers
      experimentSpecificVariables
      sampleTypes
      specimens
      species
      s3Path
      groupNames
      labAssayTechnologies
      phase
      readGroups
      writeGroups
      adminGroups
      croGroups
      created
      updated
      studyStudyPhasesId
      metaDataValidationSchemaTemplateStudyPhasesId
      __typename
    }
    bundleLog
    validationErrors
    status
    userEmail
    uploadBundleAnalysisSession {
      id
      uploadBundleId
      uploadBundle {
        id
        studyPhaseId
        bundleLog
        validationErrors
        status
        userEmail
        adminGroups
        readGroups
        completionTime
        created
        updated
        studyPhaseUploadBundlesId
        uploadBundleUploadBundleAnalysisSessionId
        owner
        __typename
      }
      uploadSampleAnalysisSession {
        nextToken
        __typename
      }
      metadataFileS3Path
      step
      listOfRawFileNames
      analysisType
      subtypeOfAnalysis
      studyPhaseId
      metadataHelperLambdaResponseS3Path
      barcodeToFilesToUploadS3Path
      adminGroups
      readGroups
      created
      updated
      owner
      __typename
    }
    uploadSamples {
      items {
        id
        uploadBundleId
        sampleId
        barcode
        sampleLog
        writeMetadata
        status
        adminGroups
        readGroups
        created
        updated
        uploadBundleUploadSamplesId
        owner
        __typename
      }
      nextToken
      __typename
    }
    uploadFiles {
      items {
        id
        uploadSampleId
        uploadBundleId
        multipartUploadId
        status
        toSkipUpload
        fileName
        fileType
        s3Path
        size
        fileLog
        adminGroups
        readGroups
        created
        updated
        uploadBundleUploadFilesId
        uploadSampleUploadFilesId
        owner
        __typename
      }
      nextToken
      __typename
    }
    adminGroups
    readGroups
    completionTime
    created
    updated
    studyPhaseUploadBundlesId
    uploadBundleUploadBundleAnalysisSessionId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUploadBundleMutationVariables,
  APITypes.UpdateUploadBundleMutation
>;
export const deleteUploadBundle = /* GraphQL */ `mutation DeleteUploadBundle(
  $input: DeleteUploadBundleInput!
  $condition: ModelUploadBundleConditionInput
) {
  deleteUploadBundle(input: $input, condition: $condition) {
    id
    studyPhaseId
    studyPhase {
      id
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      metaDataValidationSchemaTemplateId
      metaDataValidationSchemaTemplate {
        id
        version
        description
        templateFiles
        validationSchemaTemplate
        created
        updated
        __typename
      }
      groups {
        nextToken
        __typename
      }
      samples {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      uploadBundles {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      validationSchema
      description
      studyPhaseName
      studyPhaseAnswers
      experimentSpecificVariables
      sampleTypes
      specimens
      species
      s3Path
      groupNames
      labAssayTechnologies
      phase
      readGroups
      writeGroups
      adminGroups
      croGroups
      created
      updated
      studyStudyPhasesId
      metaDataValidationSchemaTemplateStudyPhasesId
      __typename
    }
    bundleLog
    validationErrors
    status
    userEmail
    uploadBundleAnalysisSession {
      id
      uploadBundleId
      uploadBundle {
        id
        studyPhaseId
        bundleLog
        validationErrors
        status
        userEmail
        adminGroups
        readGroups
        completionTime
        created
        updated
        studyPhaseUploadBundlesId
        uploadBundleUploadBundleAnalysisSessionId
        owner
        __typename
      }
      uploadSampleAnalysisSession {
        nextToken
        __typename
      }
      metadataFileS3Path
      step
      listOfRawFileNames
      analysisType
      subtypeOfAnalysis
      studyPhaseId
      metadataHelperLambdaResponseS3Path
      barcodeToFilesToUploadS3Path
      adminGroups
      readGroups
      created
      updated
      owner
      __typename
    }
    uploadSamples {
      items {
        id
        uploadBundleId
        sampleId
        barcode
        sampleLog
        writeMetadata
        status
        adminGroups
        readGroups
        created
        updated
        uploadBundleUploadSamplesId
        owner
        __typename
      }
      nextToken
      __typename
    }
    uploadFiles {
      items {
        id
        uploadSampleId
        uploadBundleId
        multipartUploadId
        status
        toSkipUpload
        fileName
        fileType
        s3Path
        size
        fileLog
        adminGroups
        readGroups
        created
        updated
        uploadBundleUploadFilesId
        uploadSampleUploadFilesId
        owner
        __typename
      }
      nextToken
      __typename
    }
    adminGroups
    readGroups
    completionTime
    created
    updated
    studyPhaseUploadBundlesId
    uploadBundleUploadBundleAnalysisSessionId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUploadBundleMutationVariables,
  APITypes.DeleteUploadBundleMutation
>;
export const createUploadBundleAnalysisSession = /* GraphQL */ `mutation CreateUploadBundleAnalysisSession(
  $input: CreateUploadBundleAnalysisSessionInput!
  $condition: ModelUploadBundleAnalysisSessionConditionInput
) {
  createUploadBundleAnalysisSession(input: $input, condition: $condition) {
    id
    uploadBundleId
    uploadBundle {
      id
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      bundleLog
      validationErrors
      status
      userEmail
      uploadBundleAnalysisSession {
        id
        uploadBundleId
        metadataFileS3Path
        step
        listOfRawFileNames
        analysisType
        subtypeOfAnalysis
        studyPhaseId
        metadataHelperLambdaResponseS3Path
        barcodeToFilesToUploadS3Path
        adminGroups
        readGroups
        created
        updated
        owner
        __typename
      }
      uploadSamples {
        nextToken
        __typename
      }
      uploadFiles {
        nextToken
        __typename
      }
      adminGroups
      readGroups
      completionTime
      created
      updated
      studyPhaseUploadBundlesId
      uploadBundleUploadBundleAnalysisSessionId
      owner
      __typename
    }
    uploadSampleAnalysisSession {
      items {
        id
        uploadBundleAnalysisSessionId
        sampleId
        rowFromMetaDataFileMappedToSample
        adminGroups
        readGroups
        created
        updated
        uploadBundleAnalysisSessionUploadSampleAnalysisSessionId
        owner
        __typename
      }
      nextToken
      __typename
    }
    metadataFileS3Path
    step
    listOfRawFileNames
    analysisType
    subtypeOfAnalysis
    studyPhaseId
    metadataHelperLambdaResponseS3Path
    barcodeToFilesToUploadS3Path
    adminGroups
    readGroups
    created
    updated
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUploadBundleAnalysisSessionMutationVariables,
  APITypes.CreateUploadBundleAnalysisSessionMutation
>;
export const updateUploadBundleAnalysisSession = /* GraphQL */ `mutation UpdateUploadBundleAnalysisSession(
  $input: UpdateUploadBundleAnalysisSessionInput!
  $condition: ModelUploadBundleAnalysisSessionConditionInput
) {
  updateUploadBundleAnalysisSession(input: $input, condition: $condition) {
    id
    uploadBundleId
    uploadBundle {
      id
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      bundleLog
      validationErrors
      status
      userEmail
      uploadBundleAnalysisSession {
        id
        uploadBundleId
        metadataFileS3Path
        step
        listOfRawFileNames
        analysisType
        subtypeOfAnalysis
        studyPhaseId
        metadataHelperLambdaResponseS3Path
        barcodeToFilesToUploadS3Path
        adminGroups
        readGroups
        created
        updated
        owner
        __typename
      }
      uploadSamples {
        nextToken
        __typename
      }
      uploadFiles {
        nextToken
        __typename
      }
      adminGroups
      readGroups
      completionTime
      created
      updated
      studyPhaseUploadBundlesId
      uploadBundleUploadBundleAnalysisSessionId
      owner
      __typename
    }
    uploadSampleAnalysisSession {
      items {
        id
        uploadBundleAnalysisSessionId
        sampleId
        rowFromMetaDataFileMappedToSample
        adminGroups
        readGroups
        created
        updated
        uploadBundleAnalysisSessionUploadSampleAnalysisSessionId
        owner
        __typename
      }
      nextToken
      __typename
    }
    metadataFileS3Path
    step
    listOfRawFileNames
    analysisType
    subtypeOfAnalysis
    studyPhaseId
    metadataHelperLambdaResponseS3Path
    barcodeToFilesToUploadS3Path
    adminGroups
    readGroups
    created
    updated
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUploadBundleAnalysisSessionMutationVariables,
  APITypes.UpdateUploadBundleAnalysisSessionMutation
>;
export const deleteUploadBundleAnalysisSession = /* GraphQL */ `mutation DeleteUploadBundleAnalysisSession(
  $input: DeleteUploadBundleAnalysisSessionInput!
  $condition: ModelUploadBundleAnalysisSessionConditionInput
) {
  deleteUploadBundleAnalysisSession(input: $input, condition: $condition) {
    id
    uploadBundleId
    uploadBundle {
      id
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      bundleLog
      validationErrors
      status
      userEmail
      uploadBundleAnalysisSession {
        id
        uploadBundleId
        metadataFileS3Path
        step
        listOfRawFileNames
        analysisType
        subtypeOfAnalysis
        studyPhaseId
        metadataHelperLambdaResponseS3Path
        barcodeToFilesToUploadS3Path
        adminGroups
        readGroups
        created
        updated
        owner
        __typename
      }
      uploadSamples {
        nextToken
        __typename
      }
      uploadFiles {
        nextToken
        __typename
      }
      adminGroups
      readGroups
      completionTime
      created
      updated
      studyPhaseUploadBundlesId
      uploadBundleUploadBundleAnalysisSessionId
      owner
      __typename
    }
    uploadSampleAnalysisSession {
      items {
        id
        uploadBundleAnalysisSessionId
        sampleId
        rowFromMetaDataFileMappedToSample
        adminGroups
        readGroups
        created
        updated
        uploadBundleAnalysisSessionUploadSampleAnalysisSessionId
        owner
        __typename
      }
      nextToken
      __typename
    }
    metadataFileS3Path
    step
    listOfRawFileNames
    analysisType
    subtypeOfAnalysis
    studyPhaseId
    metadataHelperLambdaResponseS3Path
    barcodeToFilesToUploadS3Path
    adminGroups
    readGroups
    created
    updated
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUploadBundleAnalysisSessionMutationVariables,
  APITypes.DeleteUploadBundleAnalysisSessionMutation
>;
export const createUploadSample = /* GraphQL */ `mutation CreateUploadSample(
  $input: CreateUploadSampleInput!
  $condition: ModelUploadSampleConditionInput
) {
  createUploadSample(input: $input, condition: $condition) {
    id
    uploadBundleId
    uploadBundle {
      id
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      bundleLog
      validationErrors
      status
      userEmail
      uploadBundleAnalysisSession {
        id
        uploadBundleId
        metadataFileS3Path
        step
        listOfRawFileNames
        analysisType
        subtypeOfAnalysis
        studyPhaseId
        metadataHelperLambdaResponseS3Path
        barcodeToFilesToUploadS3Path
        adminGroups
        readGroups
        created
        updated
        owner
        __typename
      }
      uploadSamples {
        nextToken
        __typename
      }
      uploadFiles {
        nextToken
        __typename
      }
      adminGroups
      readGroups
      completionTime
      created
      updated
      studyPhaseUploadBundlesId
      uploadBundleUploadBundleAnalysisSessionId
      owner
      __typename
    }
    sampleId
    barcode
    sampleLog
    writeMetadata
    status
    uploadFiles {
      items {
        id
        uploadSampleId
        uploadBundleId
        multipartUploadId
        status
        toSkipUpload
        fileName
        fileType
        s3Path
        size
        fileLog
        adminGroups
        readGroups
        created
        updated
        uploadBundleUploadFilesId
        uploadSampleUploadFilesId
        owner
        __typename
      }
      nextToken
      __typename
    }
    adminGroups
    readGroups
    created
    updated
    uploadBundleUploadSamplesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUploadSampleMutationVariables,
  APITypes.CreateUploadSampleMutation
>;
export const updateUploadSample = /* GraphQL */ `mutation UpdateUploadSample(
  $input: UpdateUploadSampleInput!
  $condition: ModelUploadSampleConditionInput
) {
  updateUploadSample(input: $input, condition: $condition) {
    id
    uploadBundleId
    uploadBundle {
      id
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      bundleLog
      validationErrors
      status
      userEmail
      uploadBundleAnalysisSession {
        id
        uploadBundleId
        metadataFileS3Path
        step
        listOfRawFileNames
        analysisType
        subtypeOfAnalysis
        studyPhaseId
        metadataHelperLambdaResponseS3Path
        barcodeToFilesToUploadS3Path
        adminGroups
        readGroups
        created
        updated
        owner
        __typename
      }
      uploadSamples {
        nextToken
        __typename
      }
      uploadFiles {
        nextToken
        __typename
      }
      adminGroups
      readGroups
      completionTime
      created
      updated
      studyPhaseUploadBundlesId
      uploadBundleUploadBundleAnalysisSessionId
      owner
      __typename
    }
    sampleId
    barcode
    sampleLog
    writeMetadata
    status
    uploadFiles {
      items {
        id
        uploadSampleId
        uploadBundleId
        multipartUploadId
        status
        toSkipUpload
        fileName
        fileType
        s3Path
        size
        fileLog
        adminGroups
        readGroups
        created
        updated
        uploadBundleUploadFilesId
        uploadSampleUploadFilesId
        owner
        __typename
      }
      nextToken
      __typename
    }
    adminGroups
    readGroups
    created
    updated
    uploadBundleUploadSamplesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUploadSampleMutationVariables,
  APITypes.UpdateUploadSampleMutation
>;
export const deleteUploadSample = /* GraphQL */ `mutation DeleteUploadSample(
  $input: DeleteUploadSampleInput!
  $condition: ModelUploadSampleConditionInput
) {
  deleteUploadSample(input: $input, condition: $condition) {
    id
    uploadBundleId
    uploadBundle {
      id
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      bundleLog
      validationErrors
      status
      userEmail
      uploadBundleAnalysisSession {
        id
        uploadBundleId
        metadataFileS3Path
        step
        listOfRawFileNames
        analysisType
        subtypeOfAnalysis
        studyPhaseId
        metadataHelperLambdaResponseS3Path
        barcodeToFilesToUploadS3Path
        adminGroups
        readGroups
        created
        updated
        owner
        __typename
      }
      uploadSamples {
        nextToken
        __typename
      }
      uploadFiles {
        nextToken
        __typename
      }
      adminGroups
      readGroups
      completionTime
      created
      updated
      studyPhaseUploadBundlesId
      uploadBundleUploadBundleAnalysisSessionId
      owner
      __typename
    }
    sampleId
    barcode
    sampleLog
    writeMetadata
    status
    uploadFiles {
      items {
        id
        uploadSampleId
        uploadBundleId
        multipartUploadId
        status
        toSkipUpload
        fileName
        fileType
        s3Path
        size
        fileLog
        adminGroups
        readGroups
        created
        updated
        uploadBundleUploadFilesId
        uploadSampleUploadFilesId
        owner
        __typename
      }
      nextToken
      __typename
    }
    adminGroups
    readGroups
    created
    updated
    uploadBundleUploadSamplesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUploadSampleMutationVariables,
  APITypes.DeleteUploadSampleMutation
>;
export const createUploadSampleAnalysisSession = /* GraphQL */ `mutation CreateUploadSampleAnalysisSession(
  $input: CreateUploadSampleAnalysisSessionInput!
  $condition: ModelUploadSampleAnalysisSessionConditionInput
) {
  createUploadSampleAnalysisSession(input: $input, condition: $condition) {
    id
    uploadBundleAnalysisSessionId
    uploadBundleAnalysisSession {
      id
      uploadBundleId
      uploadBundle {
        id
        studyPhaseId
        bundleLog
        validationErrors
        status
        userEmail
        adminGroups
        readGroups
        completionTime
        created
        updated
        studyPhaseUploadBundlesId
        uploadBundleUploadBundleAnalysisSessionId
        owner
        __typename
      }
      uploadSampleAnalysisSession {
        nextToken
        __typename
      }
      metadataFileS3Path
      step
      listOfRawFileNames
      analysisType
      subtypeOfAnalysis
      studyPhaseId
      metadataHelperLambdaResponseS3Path
      barcodeToFilesToUploadS3Path
      adminGroups
      readGroups
      created
      updated
      owner
      __typename
    }
    sampleId
    rowFromMetaDataFileMappedToSample
    adminGroups
    readGroups
    created
    updated
    uploadBundleAnalysisSessionUploadSampleAnalysisSessionId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUploadSampleAnalysisSessionMutationVariables,
  APITypes.CreateUploadSampleAnalysisSessionMutation
>;
export const updateUploadSampleAnalysisSession = /* GraphQL */ `mutation UpdateUploadSampleAnalysisSession(
  $input: UpdateUploadSampleAnalysisSessionInput!
  $condition: ModelUploadSampleAnalysisSessionConditionInput
) {
  updateUploadSampleAnalysisSession(input: $input, condition: $condition) {
    id
    uploadBundleAnalysisSessionId
    uploadBundleAnalysisSession {
      id
      uploadBundleId
      uploadBundle {
        id
        studyPhaseId
        bundleLog
        validationErrors
        status
        userEmail
        adminGroups
        readGroups
        completionTime
        created
        updated
        studyPhaseUploadBundlesId
        uploadBundleUploadBundleAnalysisSessionId
        owner
        __typename
      }
      uploadSampleAnalysisSession {
        nextToken
        __typename
      }
      metadataFileS3Path
      step
      listOfRawFileNames
      analysisType
      subtypeOfAnalysis
      studyPhaseId
      metadataHelperLambdaResponseS3Path
      barcodeToFilesToUploadS3Path
      adminGroups
      readGroups
      created
      updated
      owner
      __typename
    }
    sampleId
    rowFromMetaDataFileMappedToSample
    adminGroups
    readGroups
    created
    updated
    uploadBundleAnalysisSessionUploadSampleAnalysisSessionId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUploadSampleAnalysisSessionMutationVariables,
  APITypes.UpdateUploadSampleAnalysisSessionMutation
>;
export const deleteUploadSampleAnalysisSession = /* GraphQL */ `mutation DeleteUploadSampleAnalysisSession(
  $input: DeleteUploadSampleAnalysisSessionInput!
  $condition: ModelUploadSampleAnalysisSessionConditionInput
) {
  deleteUploadSampleAnalysisSession(input: $input, condition: $condition) {
    id
    uploadBundleAnalysisSessionId
    uploadBundleAnalysisSession {
      id
      uploadBundleId
      uploadBundle {
        id
        studyPhaseId
        bundleLog
        validationErrors
        status
        userEmail
        adminGroups
        readGroups
        completionTime
        created
        updated
        studyPhaseUploadBundlesId
        uploadBundleUploadBundleAnalysisSessionId
        owner
        __typename
      }
      uploadSampleAnalysisSession {
        nextToken
        __typename
      }
      metadataFileS3Path
      step
      listOfRawFileNames
      analysisType
      subtypeOfAnalysis
      studyPhaseId
      metadataHelperLambdaResponseS3Path
      barcodeToFilesToUploadS3Path
      adminGroups
      readGroups
      created
      updated
      owner
      __typename
    }
    sampleId
    rowFromMetaDataFileMappedToSample
    adminGroups
    readGroups
    created
    updated
    uploadBundleAnalysisSessionUploadSampleAnalysisSessionId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUploadSampleAnalysisSessionMutationVariables,
  APITypes.DeleteUploadSampleAnalysisSessionMutation
>;
export const createUploadFile = /* GraphQL */ `mutation CreateUploadFile(
  $input: CreateUploadFileInput!
  $condition: ModelUploadFileConditionInput
) {
  createUploadFile(input: $input, condition: $condition) {
    id
    uploadSampleId
    uploadSample {
      id
      uploadBundleId
      uploadBundle {
        id
        studyPhaseId
        bundleLog
        validationErrors
        status
        userEmail
        adminGroups
        readGroups
        completionTime
        created
        updated
        studyPhaseUploadBundlesId
        uploadBundleUploadBundleAnalysisSessionId
        owner
        __typename
      }
      sampleId
      barcode
      sampleLog
      writeMetadata
      status
      uploadFiles {
        nextToken
        __typename
      }
      adminGroups
      readGroups
      created
      updated
      uploadBundleUploadSamplesId
      owner
      __typename
    }
    uploadBundleId
    uploadBundle {
      id
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      bundleLog
      validationErrors
      status
      userEmail
      uploadBundleAnalysisSession {
        id
        uploadBundleId
        metadataFileS3Path
        step
        listOfRawFileNames
        analysisType
        subtypeOfAnalysis
        studyPhaseId
        metadataHelperLambdaResponseS3Path
        barcodeToFilesToUploadS3Path
        adminGroups
        readGroups
        created
        updated
        owner
        __typename
      }
      uploadSamples {
        nextToken
        __typename
      }
      uploadFiles {
        nextToken
        __typename
      }
      adminGroups
      readGroups
      completionTime
      created
      updated
      studyPhaseUploadBundlesId
      uploadBundleUploadBundleAnalysisSessionId
      owner
      __typename
    }
    multipartUploadId
    status
    toSkipUpload
    fileName
    fileType
    s3Path
    size
    fileLog
    adminGroups
    readGroups
    created
    updated
    uploadBundleUploadFilesId
    uploadSampleUploadFilesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUploadFileMutationVariables,
  APITypes.CreateUploadFileMutation
>;
export const updateUploadFile = /* GraphQL */ `mutation UpdateUploadFile(
  $input: UpdateUploadFileInput!
  $condition: ModelUploadFileConditionInput
) {
  updateUploadFile(input: $input, condition: $condition) {
    id
    uploadSampleId
    uploadSample {
      id
      uploadBundleId
      uploadBundle {
        id
        studyPhaseId
        bundleLog
        validationErrors
        status
        userEmail
        adminGroups
        readGroups
        completionTime
        created
        updated
        studyPhaseUploadBundlesId
        uploadBundleUploadBundleAnalysisSessionId
        owner
        __typename
      }
      sampleId
      barcode
      sampleLog
      writeMetadata
      status
      uploadFiles {
        nextToken
        __typename
      }
      adminGroups
      readGroups
      created
      updated
      uploadBundleUploadSamplesId
      owner
      __typename
    }
    uploadBundleId
    uploadBundle {
      id
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      bundleLog
      validationErrors
      status
      userEmail
      uploadBundleAnalysisSession {
        id
        uploadBundleId
        metadataFileS3Path
        step
        listOfRawFileNames
        analysisType
        subtypeOfAnalysis
        studyPhaseId
        metadataHelperLambdaResponseS3Path
        barcodeToFilesToUploadS3Path
        adminGroups
        readGroups
        created
        updated
        owner
        __typename
      }
      uploadSamples {
        nextToken
        __typename
      }
      uploadFiles {
        nextToken
        __typename
      }
      adminGroups
      readGroups
      completionTime
      created
      updated
      studyPhaseUploadBundlesId
      uploadBundleUploadBundleAnalysisSessionId
      owner
      __typename
    }
    multipartUploadId
    status
    toSkipUpload
    fileName
    fileType
    s3Path
    size
    fileLog
    adminGroups
    readGroups
    created
    updated
    uploadBundleUploadFilesId
    uploadSampleUploadFilesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUploadFileMutationVariables,
  APITypes.UpdateUploadFileMutation
>;
export const deleteUploadFile = /* GraphQL */ `mutation DeleteUploadFile(
  $input: DeleteUploadFileInput!
  $condition: ModelUploadFileConditionInput
) {
  deleteUploadFile(input: $input, condition: $condition) {
    id
    uploadSampleId
    uploadSample {
      id
      uploadBundleId
      uploadBundle {
        id
        studyPhaseId
        bundleLog
        validationErrors
        status
        userEmail
        adminGroups
        readGroups
        completionTime
        created
        updated
        studyPhaseUploadBundlesId
        uploadBundleUploadBundleAnalysisSessionId
        owner
        __typename
      }
      sampleId
      barcode
      sampleLog
      writeMetadata
      status
      uploadFiles {
        nextToken
        __typename
      }
      adminGroups
      readGroups
      created
      updated
      uploadBundleUploadSamplesId
      owner
      __typename
    }
    uploadBundleId
    uploadBundle {
      id
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      bundleLog
      validationErrors
      status
      userEmail
      uploadBundleAnalysisSession {
        id
        uploadBundleId
        metadataFileS3Path
        step
        listOfRawFileNames
        analysisType
        subtypeOfAnalysis
        studyPhaseId
        metadataHelperLambdaResponseS3Path
        barcodeToFilesToUploadS3Path
        adminGroups
        readGroups
        created
        updated
        owner
        __typename
      }
      uploadSamples {
        nextToken
        __typename
      }
      uploadFiles {
        nextToken
        __typename
      }
      adminGroups
      readGroups
      completionTime
      created
      updated
      studyPhaseUploadBundlesId
      uploadBundleUploadBundleAnalysisSessionId
      owner
      __typename
    }
    multipartUploadId
    status
    toSkipUpload
    fileName
    fileType
    s3Path
    size
    fileLog
    adminGroups
    readGroups
    created
    updated
    uploadBundleUploadFilesId
    uploadSampleUploadFilesId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUploadFileMutationVariables,
  APITypes.DeleteUploadFileMutation
>;
export const createOrganizationLaunchablePipelines = /* GraphQL */ `mutation CreateOrganizationLaunchablePipelines(
  $input: CreateOrganizationLaunchablePipelinesInput!
  $condition: ModelOrganizationLaunchablePipelinesConditionInput
) {
  createOrganizationLaunchablePipelines(input: $input, condition: $condition) {
    id
    organizationId
    launchablePipelinesId
    organization {
      id
      organizationName
      organizationCode
      active
      studies {
        nextToken
        __typename
      }
      launchablePipelines {
        nextToken
        __typename
      }
      notificationEmail
      description
      organizationAnswers
      url
      gitUrl
      s3Path
      readGroups
      writeGroups
      created
      updated
      __typename
    }
    launchablePipelines {
      id
      pipelineName
      organizations {
        nextToken
        __typename
      }
      pipelineVersion
      parameters
      description
      inputFileInfo
      supportedAnalyses
      supportedSubtypeOfAnalyses
      pipelines {
        nextToken
        __typename
      }
      gitRepo
      workflowId
      created
      updated
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrganizationLaunchablePipelinesMutationVariables,
  APITypes.CreateOrganizationLaunchablePipelinesMutation
>;
export const updateOrganizationLaunchablePipelines = /* GraphQL */ `mutation UpdateOrganizationLaunchablePipelines(
  $input: UpdateOrganizationLaunchablePipelinesInput!
  $condition: ModelOrganizationLaunchablePipelinesConditionInput
) {
  updateOrganizationLaunchablePipelines(input: $input, condition: $condition) {
    id
    organizationId
    launchablePipelinesId
    organization {
      id
      organizationName
      organizationCode
      active
      studies {
        nextToken
        __typename
      }
      launchablePipelines {
        nextToken
        __typename
      }
      notificationEmail
      description
      organizationAnswers
      url
      gitUrl
      s3Path
      readGroups
      writeGroups
      created
      updated
      __typename
    }
    launchablePipelines {
      id
      pipelineName
      organizations {
        nextToken
        __typename
      }
      pipelineVersion
      parameters
      description
      inputFileInfo
      supportedAnalyses
      supportedSubtypeOfAnalyses
      pipelines {
        nextToken
        __typename
      }
      gitRepo
      workflowId
      created
      updated
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrganizationLaunchablePipelinesMutationVariables,
  APITypes.UpdateOrganizationLaunchablePipelinesMutation
>;
export const deleteOrganizationLaunchablePipelines = /* GraphQL */ `mutation DeleteOrganizationLaunchablePipelines(
  $input: DeleteOrganizationLaunchablePipelinesInput!
  $condition: ModelOrganizationLaunchablePipelinesConditionInput
) {
  deleteOrganizationLaunchablePipelines(input: $input, condition: $condition) {
    id
    organizationId
    launchablePipelinesId
    organization {
      id
      organizationName
      organizationCode
      active
      studies {
        nextToken
        __typename
      }
      launchablePipelines {
        nextToken
        __typename
      }
      notificationEmail
      description
      organizationAnswers
      url
      gitUrl
      s3Path
      readGroups
      writeGroups
      created
      updated
      __typename
    }
    launchablePipelines {
      id
      pipelineName
      organizations {
        nextToken
        __typename
      }
      pipelineVersion
      parameters
      description
      inputFileInfo
      supportedAnalyses
      supportedSubtypeOfAnalyses
      pipelines {
        nextToken
        __typename
      }
      gitRepo
      workflowId
      created
      updated
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrganizationLaunchablePipelinesMutationVariables,
  APITypes.DeleteOrganizationLaunchablePipelinesMutation
>;
