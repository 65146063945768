import { createApp } from 'vue';

import Amplify, { Auth } from 'aws-amplify';
import AmplifyVue from '@aws-amplify/ui-vue';

import App from './App.vue';
import router from './router';
import store from './store';

import '@aws-amplify/ui-vue/styles.css';
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import '@/assets/styles/layout.scss';

import PrimeVue from 'primevue/config';
import Button from 'primevue/button';
import Card from 'primevue/card';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Menu from 'primevue/menu';
import TabMenu from 'primevue/tabmenu';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Dialog from 'primevue/dialog';
import Password from 'primevue/password';
import Divider from 'primevue/divider';
import InlineMessage from 'primevue/inlinemessage';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import Dropdown from 'primevue/dropdown';
import TreeSelect from 'primevue/treeselect';
import Tree from 'primevue/tree';
import ToggleButton from 'primevue/togglebutton';
import SelectButton from 'primevue/selectbutton';
import Checkbox from 'primevue/checkbox';
import Listbox from 'primevue/listbox';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmationService from 'primevue/confirmationservice';
import Textarea from 'primevue/textarea';
import InputSwitch from 'primevue/inputswitch';
import Tooltip from 'primevue/tooltip';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
import OverlayPanel from 'primevue/overlaypanel';
import Tag from 'primevue/tag';
import FileUpload from 'primevue/fileupload';
import Knob from 'primevue/knob';
import ProgressBar from 'primevue/progressbar';



const Markdown = require('vue3-markdown-it');

const awsconfig = require('./aws-exports.js').default;


const redirect_url = `${window.location.protocol}//${window.location.hostname}/`;
const updatedAwsConfig = {
  ...awsconfig,
  oauth: {
    ...awsconfig.oauth,
    redirectSignIn: awsconfig?.oauth?.redirectSignIn?.split(',').includes(redirect_url) ? redirect_url : 'https://dev.d2d8q2a937e8rv.amplifyapp.com/',
    redirectSignOut: awsconfig?.oauth?.redirectSignIn?.split(',').includes(redirect_url) ? redirect_url : 'https://dev.d2d8q2a937e8rv.amplifyapp.com/',
  },
};
Amplify.configure(updatedAwsConfig);
Auth.configure(updatedAwsConfig);

const app = createApp(App);

app.component('Button', Button);

app.use(PrimeVue, { ripple: true, inputStyle: 'outlined' });
app.use(store);
app.use(router);
app.use(AmplifyVue);
app.use(ToastService);
app.use(ConfirmationService);
app.use(Markdown);

app.directive('tooltip', Tooltip);

app.component('Card', Card);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('InputText', InputText);
app.component('InputNumber', InputNumber);
app.component('Menu', Menu);
app.component('TabMenu', TabMenu);
app.component('Toast', Toast);
app.component('Dialog', Dialog)
app.component('Password', Password)
app.component('Divider', Divider)
app.component('InlineMessage', InlineMessage)
app.component('Message', Message)
app.component('Dropdown', Dropdown)
app.component('MultiSelect', MultiSelect)
app.component('TreeSelect', TreeSelect)
app.component('Tree', Tree)
app.component('SelectButton', SelectButton)
app.component('Checkbox', Checkbox)
app.component('Listbox', Listbox)
app.component('ConfirmDialog', ConfirmDialog)
app.component('Textarea', Textarea)
app.component('ToggleButton', ToggleButton)
app.component('InputSwitch', InputSwitch)
app.component('Breadcrumb', Breadcrumb)
app.component('Calendar', Calendar)
app.component('OverlayPanel', OverlayPanel)
app.component('Tag', Tag)
app.component('FileUpload', FileUpload)
app.component('Knob', Knob)
app.component('ProgressBar', ProgressBar)

app.mount('#app');

// createApp(App).use(store).use(router).mount('#app');
