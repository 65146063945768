/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const queryS3UploadPathsAndValidationSchema = /* GraphQL */ `query QueryS3UploadPathsAndValidationSchema($event: AWSJSON!) {
  queryS3UploadPathsAndValidationSchema(event: $event)
}
` as GeneratedQuery<
  APITypes.QueryS3UploadPathsAndValidationSchemaQueryVariables,
  APITypes.QueryS3UploadPathsAndValidationSchemaQuery
>;
export const queryTriggerMetaDataUpload = /* GraphQL */ `query QueryTriggerMetaDataUpload($event: AWSJSON!) {
  queryTriggerMetaDataUpload(event: $event)
}
` as GeneratedQuery<
  APITypes.QueryTriggerMetaDataUploadQueryVariables,
  APITypes.QueryTriggerMetaDataUploadQuery
>;
export const generatePlotlyJWT = /* GraphQL */ `query GeneratePlotlyJWT($event: AWSJSON!) {
  generatePlotlyJWT(event: $event)
}
` as GeneratedQuery<
  APITypes.GeneratePlotlyJWTQueryVariables,
  APITypes.GeneratePlotlyJWTQuery
>;
export const getOrganization = /* GraphQL */ `query GetOrganization($id: ID!) {
  getOrganization(id: $id) {
    id
    organizationName
    organizationCode
    active
    studies {
      items {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      nextToken
      __typename
    }
    launchablePipelines {
      items {
        id
        organizationId
        launchablePipelinesId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    notificationEmail
    description
    organizationAnswers
    url
    gitUrl
    s3Path
    readGroups
    writeGroups
    created
    updated
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationQueryVariables,
  APITypes.GetOrganizationQuery
>;
export const listOrganizations = /* GraphQL */ `query ListOrganizations(
  $id: ID
  $filter: ModelOrganizationFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listOrganizations(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      organizationName
      organizationCode
      active
      studies {
        nextToken
        __typename
      }
      launchablePipelines {
        nextToken
        __typename
      }
      notificationEmail
      description
      organizationAnswers
      url
      gitUrl
      s3Path
      readGroups
      writeGroups
      created
      updated
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrganizationsQueryVariables,
  APITypes.ListOrganizationsQuery
>;
export const getStudy = /* GraphQL */ `query GetStudy($id: ID!) {
  getStudy(id: $id) {
    id
    studyName
    organizationId
    organization {
      id
      organizationName
      organizationCode
      active
      studies {
        nextToken
        __typename
      }
      launchablePipelines {
        nextToken
        __typename
      }
      notificationEmail
      description
      organizationAnswers
      url
      gitUrl
      s3Path
      readGroups
      writeGroups
      created
      updated
      __typename
    }
    studyPhases {
      items {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      nextToken
      __typename
    }
    batches {
      items {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      nextToken
      __typename
    }
    pipelines {
      items {
        id
        name
        status
        description
        s3Event
        s3ParamsFile
        s3Results
        visFileInfoS3path
        s3NfLog
        s3NfTasksLogs
        analysisType
        subtypeOfAnalysis
        parameters
        studyId
        studyPhaseId
        scheduledPipeline
        scheduledDateTime
        launchablePipelineId
        batchId
        runId
        userEmail
        pipelineStarted
        pipelineCompleted
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPipelinesId
        studyPhasePipelinesId
        batchPipelinesId
        launchablePipelinesPipelinesId
        __typename
      }
      nextToken
      __typename
    }
    description
    studyCode
    studyAnswers
    s3Path
    croGroups
    readGroups
    writeGroups
    adminGroups
    created
    updated
    organizationStudiesId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetStudyQueryVariables, APITypes.GetStudyQuery>;
export const listStudies = /* GraphQL */ `query ListStudies(
  $id: ID
  $filter: ModelStudyFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listStudies(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      studyName
      organizationId
      organization {
        id
        organizationName
        organizationCode
        active
        notificationEmail
        description
        organizationAnswers
        url
        gitUrl
        s3Path
        readGroups
        writeGroups
        created
        updated
        __typename
      }
      studyPhases {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      description
      studyCode
      studyAnswers
      s3Path
      croGroups
      readGroups
      writeGroups
      adminGroups
      created
      updated
      organizationStudiesId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStudiesQueryVariables,
  APITypes.ListStudiesQuery
>;
export const getStudyPhase = /* GraphQL */ `query GetStudyPhase($id: ID!) {
  getStudyPhase(id: $id) {
    id
    studyId
    study {
      id
      studyName
      organizationId
      organization {
        id
        organizationName
        organizationCode
        active
        notificationEmail
        description
        organizationAnswers
        url
        gitUrl
        s3Path
        readGroups
        writeGroups
        created
        updated
        __typename
      }
      studyPhases {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      description
      studyCode
      studyAnswers
      s3Path
      croGroups
      readGroups
      writeGroups
      adminGroups
      created
      updated
      organizationStudiesId
      __typename
    }
    metaDataValidationSchemaTemplateId
    metaDataValidationSchemaTemplate {
      id
      studyPhases {
        nextToken
        __typename
      }
      version
      description
      templateFiles
      validationSchemaTemplate
      created
      updated
      __typename
    }
    groups {
      items {
        id
        studyPhaseId
        type
        groupName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseGroupsId
        __typename
      }
      nextToken
      __typename
    }
    samples {
      items {
        id
        timepointId
        studyPhaseId
        BatchId
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
        __typename
      }
      nextToken
      __typename
    }
    batches {
      items {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      nextToken
      __typename
    }
    uploadBundles {
      items {
        id
        studyPhaseId
        bundleLog
        validationErrors
        status
        userEmail
        adminGroups
        readGroups
        completionTime
        created
        updated
        studyPhaseUploadBundlesId
        uploadBundleUploadBundleAnalysisSessionId
        owner
        __typename
      }
      nextToken
      __typename
    }
    pipelines {
      items {
        id
        name
        status
        description
        s3Event
        s3ParamsFile
        s3Results
        visFileInfoS3path
        s3NfLog
        s3NfTasksLogs
        analysisType
        subtypeOfAnalysis
        parameters
        studyId
        studyPhaseId
        scheduledPipeline
        scheduledDateTime
        launchablePipelineId
        batchId
        runId
        userEmail
        pipelineStarted
        pipelineCompleted
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPipelinesId
        studyPhasePipelinesId
        batchPipelinesId
        launchablePipelinesPipelinesId
        __typename
      }
      nextToken
      __typename
    }
    validationSchema
    description
    studyPhaseName
    studyPhaseAnswers
    experimentSpecificVariables
    sampleTypes
    specimens
    species
    s3Path
    groupNames
    labAssayTechnologies
    phase
    readGroups
    writeGroups
    adminGroups
    croGroups
    created
    updated
    studyStudyPhasesId
    metaDataValidationSchemaTemplateStudyPhasesId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStudyPhaseQueryVariables,
  APITypes.GetStudyPhaseQuery
>;
export const listStudyPhases = /* GraphQL */ `query ListStudyPhases(
  $id: ID
  $filter: ModelStudyPhaseFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listStudyPhases(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      metaDataValidationSchemaTemplateId
      metaDataValidationSchemaTemplate {
        id
        version
        description
        templateFiles
        validationSchemaTemplate
        created
        updated
        __typename
      }
      groups {
        nextToken
        __typename
      }
      samples {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      uploadBundles {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      validationSchema
      description
      studyPhaseName
      studyPhaseAnswers
      experimentSpecificVariables
      sampleTypes
      specimens
      species
      s3Path
      groupNames
      labAssayTechnologies
      phase
      readGroups
      writeGroups
      adminGroups
      croGroups
      created
      updated
      studyStudyPhasesId
      metaDataValidationSchemaTemplateStudyPhasesId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStudyPhasesQueryVariables,
  APITypes.ListStudyPhasesQuery
>;
export const getEntityQuestions = /* GraphQL */ `query GetEntityQuestions($id: ID!) {
  getEntityQuestions(id: $id) {
    id
    entityType
    questions
    created
    updated
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEntityQuestionsQueryVariables,
  APITypes.GetEntityQuestionsQuery
>;
export const listEntityQuestions = /* GraphQL */ `query ListEntityQuestions(
  $id: ID
  $filter: ModelEntityQuestionsFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listEntityQuestions(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      entityType
      questions
      created
      updated
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEntityQuestionsQueryVariables,
  APITypes.ListEntityQuestionsQuery
>;
export const getMetaDataValidationSchemaTemplate = /* GraphQL */ `query GetMetaDataValidationSchemaTemplate($id: ID!) {
  getMetaDataValidationSchemaTemplate(id: $id) {
    id
    studyPhases {
      items {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      nextToken
      __typename
    }
    version
    description
    templateFiles
    validationSchemaTemplate
    created
    updated
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMetaDataValidationSchemaTemplateQueryVariables,
  APITypes.GetMetaDataValidationSchemaTemplateQuery
>;
export const listMetaDataValidationSchemaTemplates = /* GraphQL */ `query ListMetaDataValidationSchemaTemplates(
  $id: ID
  $filter: ModelMetaDataValidationSchemaTemplateFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listMetaDataValidationSchemaTemplates(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      studyPhases {
        nextToken
        __typename
      }
      version
      description
      templateFiles
      validationSchemaTemplate
      created
      updated
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMetaDataValidationSchemaTemplatesQueryVariables,
  APITypes.ListMetaDataValidationSchemaTemplatesQuery
>;
export const getGroup = /* GraphQL */ `query GetGroup($id: ID!) {
  getGroup(id: $id) {
    id
    studyPhaseId
    studyPhase {
      id
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      metaDataValidationSchemaTemplateId
      metaDataValidationSchemaTemplate {
        id
        version
        description
        templateFiles
        validationSchemaTemplate
        created
        updated
        __typename
      }
      groups {
        nextToken
        __typename
      }
      samples {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      uploadBundles {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      validationSchema
      description
      studyPhaseName
      studyPhaseAnswers
      experimentSpecificVariables
      sampleTypes
      specimens
      species
      s3Path
      groupNames
      labAssayTechnologies
      phase
      readGroups
      writeGroups
      adminGroups
      croGroups
      created
      updated
      studyStudyPhasesId
      metaDataValidationSchemaTemplateStudyPhasesId
      __typename
    }
    type
    groupName
    s3Path
    patients {
      items {
        id
        groupId
        patientStudyPhaseId
        patientStudyId
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        groupPatientsId
        __typename
      }
      nextToken
      __typename
    }
    readGroups
    writeGroups
    adminGroups
    created
    updated
    studyPhaseGroupsId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetGroupQueryVariables, APITypes.GetGroupQuery>;
export const listGroups = /* GraphQL */ `query ListGroups(
  $id: ID
  $filter: ModelGroupFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listGroups(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      type
      groupName
      s3Path
      patients {
        nextToken
        __typename
      }
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseGroupsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGroupsQueryVariables,
  APITypes.ListGroupsQuery
>;
export const getPatient = /* GraphQL */ `query GetPatient($id: ID!) {
  getPatient(id: $id) {
    id
    groupId
    group {
      id
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      type
      groupName
      s3Path
      patients {
        nextToken
        __typename
      }
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseGroupsId
      __typename
    }
    timepoints {
      items {
        id
        patientId
        timepointName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        patientTimepointsId
        __typename
      }
      nextToken
      __typename
    }
    patientStudyPhaseId
    patientStudyId
    s3Path
    readGroups
    writeGroups
    adminGroups
    created
    updated
    groupPatientsId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPatientQueryVariables,
  APITypes.GetPatientQuery
>;
export const listPatients = /* GraphQL */ `query ListPatients(
  $id: ID
  $filter: ModelPatientFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listPatients(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      groupId
      group {
        id
        studyPhaseId
        type
        groupName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseGroupsId
        __typename
      }
      timepoints {
        nextToken
        __typename
      }
      patientStudyPhaseId
      patientStudyId
      s3Path
      readGroups
      writeGroups
      adminGroups
      created
      updated
      groupPatientsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPatientsQueryVariables,
  APITypes.ListPatientsQuery
>;
export const getTimepoint = /* GraphQL */ `query GetTimepoint($id: ID!) {
  getTimepoint(id: $id) {
    id
    patientId
    patient {
      id
      groupId
      group {
        id
        studyPhaseId
        type
        groupName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseGroupsId
        __typename
      }
      timepoints {
        nextToken
        __typename
      }
      patientStudyPhaseId
      patientStudyId
      s3Path
      readGroups
      writeGroups
      adminGroups
      created
      updated
      groupPatientsId
      __typename
    }
    samples {
      items {
        id
        timepointId
        studyPhaseId
        BatchId
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
        __typename
      }
      nextToken
      __typename
    }
    timepointName
    s3Path
    readGroups
    writeGroups
    adminGroups
    created
    updated
    patientTimepointsId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTimepointQueryVariables,
  APITypes.GetTimepointQuery
>;
export const listTimepoints = /* GraphQL */ `query ListTimepoints(
  $id: ID
  $filter: ModelTimepointFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listTimepoints(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      patientId
      patient {
        id
        groupId
        patientStudyPhaseId
        patientStudyId
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        groupPatientsId
        __typename
      }
      samples {
        nextToken
        __typename
      }
      timepointName
      s3Path
      readGroups
      writeGroups
      adminGroups
      created
      updated
      patientTimepointsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTimepointsQueryVariables,
  APITypes.ListTimepointsQuery
>;
export const getSample = /* GraphQL */ `query GetSample($id: ID!) {
  getSample(id: $id) {
    id
    timepointId
    timepoint {
      id
      patientId
      patient {
        id
        groupId
        patientStudyPhaseId
        patientStudyId
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        groupPatientsId
        __typename
      }
      samples {
        nextToken
        __typename
      }
      timepointName
      s3Path
      readGroups
      writeGroups
      adminGroups
      created
      updated
      patientTimepointsId
      __typename
    }
    studyPhaseId
    StudyPhase {
      id
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      metaDataValidationSchemaTemplateId
      metaDataValidationSchemaTemplate {
        id
        version
        description
        templateFiles
        validationSchemaTemplate
        created
        updated
        __typename
      }
      groups {
        nextToken
        __typename
      }
      samples {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      uploadBundles {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      validationSchema
      description
      studyPhaseName
      studyPhaseAnswers
      experimentSpecificVariables
      sampleTypes
      specimens
      species
      s3Path
      groupNames
      labAssayTechnologies
      phase
      readGroups
      writeGroups
      adminGroups
      croGroups
      created
      updated
      studyStudyPhasesId
      metaDataValidationSchemaTemplateStudyPhasesId
      __typename
    }
    BatchId
    Batch {
      id
      batchName
      batchAnnotations
      batchGroups
      annotations
      controlSamples
      filters
      description
      samples {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyBatchesId
      studyPhaseBatchesId
      __typename
    }
    metadataImmunopeptidomicsList {
      items {
        id
        sampleId
        metaDataTemplateS3Path
        species
        subtypeOfAnalysis
        sampleType
        qcStatus
        glacierBackup
        rawDataS3
        tumorVolume
        readGroups
        writeGroups
        adminGroups
        created
        updated
        isControl
        barcodesMappedToControl
        barcode
        s3Path
        sampleMetadataImmunopeptidomicsListId
        __typename
      }
      nextToken
      __typename
    }
    MetadataNanoStringList {
      items {
        id
        sampleId
        metaDataTemplateS3Path
        subtypeOfAnalysis
        species
        sampleType
        qcStatus
        glacierBackup
        rawDataS3
        tumorVolume
        dv200Percentage
        concNanoGramPerMicroL
        rnaMassNanoGram
        rnaVolumeMicroL
        rin
        readGroups
        writeGroups
        adminGroups
        created
        updated
        isControl
        barcodesMappedToControl
        barcode
        s3Path
        sampleMetadataNanoStringListId
        __typename
      }
      nextToken
      __typename
    }
    MetadataRnaSeqList {
      items {
        id
        sampleId
        metaDataTemplateS3Path
        subtypeOfAnalysis
        species
        sampleType
        qcStatus
        glacierBackup
        rawDataS3
        tumorVolume
        sequenceType
        strandSpecific
        UMI
        adaptorsRemoved
        adaptorsS3Path
        indexSequences
        concNanoGramPerMicroL
        rnaMassNanoGram
        rnaVolumeMicroL
        rin
        readGroups
        writeGroups
        adminGroups
        created
        updated
        isControl
        barcodesMappedToControl
        barcode
        s3Path
        sampleMetadataRnaSeqListId
        __typename
      }
      nextToken
      __typename
    }
    AuxiliaryAnalysislList {
      items {
        id
        sampleId
        auxiliaryFileS3Path
        vendorId
        specimenType
        parameters
        collectionDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        sampleAuxiliaryAnalysislListId
        __typename
      }
      nextToken
      __typename
    }
    domainSampleId
    experimentSpecificVariables
    collectionDate
    processingDate
    readGroups
    writeGroups
    adminGroups
    created
    updated
    studyPhaseSamplesId
    timepointSamplesId
    batchSamplesId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetSampleQueryVariables, APITypes.GetSampleQuery>;
export const listSamples = /* GraphQL */ `query ListSamples(
  $id: ID
  $filter: ModelSampleFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listSamples(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      timepointId
      timepoint {
        id
        patientId
        timepointName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        patientTimepointsId
        __typename
      }
      studyPhaseId
      StudyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      BatchId
      Batch {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      metadataImmunopeptidomicsList {
        nextToken
        __typename
      }
      MetadataNanoStringList {
        nextToken
        __typename
      }
      MetadataRnaSeqList {
        nextToken
        __typename
      }
      AuxiliaryAnalysislList {
        nextToken
        __typename
      }
      domainSampleId
      experimentSpecificVariables
      collectionDate
      processingDate
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseSamplesId
      timepointSamplesId
      batchSamplesId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSamplesQueryVariables,
  APITypes.ListSamplesQuery
>;
export const getBatch = /* GraphQL */ `query GetBatch($id: ID!) {
  getBatch(id: $id) {
    id
    batchName
    batchAnnotations
    batchGroups
    annotations
    controlSamples
    filters
    description
    samples {
      items {
        id
        timepointId
        studyPhaseId
        BatchId
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
        __typename
      }
      nextToken
      __typename
    }
    pipelines {
      items {
        id
        name
        status
        description
        s3Event
        s3ParamsFile
        s3Results
        visFileInfoS3path
        s3NfLog
        s3NfTasksLogs
        analysisType
        subtypeOfAnalysis
        parameters
        studyId
        studyPhaseId
        scheduledPipeline
        scheduledDateTime
        launchablePipelineId
        batchId
        runId
        userEmail
        pipelineStarted
        pipelineCompleted
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPipelinesId
        studyPhasePipelinesId
        batchPipelinesId
        launchablePipelinesPipelinesId
        __typename
      }
      nextToken
      __typename
    }
    studyId
    study {
      id
      studyName
      organizationId
      organization {
        id
        organizationName
        organizationCode
        active
        notificationEmail
        description
        organizationAnswers
        url
        gitUrl
        s3Path
        readGroups
        writeGroups
        created
        updated
        __typename
      }
      studyPhases {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      description
      studyCode
      studyAnswers
      s3Path
      croGroups
      readGroups
      writeGroups
      adminGroups
      created
      updated
      organizationStudiesId
      __typename
    }
    studyPhaseId
    studyPhase {
      id
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      metaDataValidationSchemaTemplateId
      metaDataValidationSchemaTemplate {
        id
        version
        description
        templateFiles
        validationSchemaTemplate
        created
        updated
        __typename
      }
      groups {
        nextToken
        __typename
      }
      samples {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      uploadBundles {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      validationSchema
      description
      studyPhaseName
      studyPhaseAnswers
      experimentSpecificVariables
      sampleTypes
      specimens
      species
      s3Path
      groupNames
      labAssayTechnologies
      phase
      readGroups
      writeGroups
      adminGroups
      croGroups
      created
      updated
      studyStudyPhasesId
      metaDataValidationSchemaTemplateStudyPhasesId
      __typename
    }
    readGroups
    writeGroups
    adminGroups
    created
    updated
    studyBatchesId
    studyPhaseBatchesId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetBatchQueryVariables, APITypes.GetBatchQuery>;
export const listBatches = /* GraphQL */ `query ListBatches(
  $id: ID
  $filter: ModelBatchFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listBatches(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      batchName
      batchAnnotations
      batchGroups
      annotations
      controlSamples
      filters
      description
      samples {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyBatchesId
      studyPhaseBatchesId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBatchesQueryVariables,
  APITypes.ListBatchesQuery
>;
export const getPipeline = /* GraphQL */ `query GetPipeline($id: ID!) {
  getPipeline(id: $id) {
    id
    name
    status
    description
    s3Event
    s3ParamsFile
    s3Results
    visFileInfoS3path
    s3NfLog
    s3NfTasksLogs
    analysisType
    subtypeOfAnalysis
    parameters
    studyId
    study {
      id
      studyName
      organizationId
      organization {
        id
        organizationName
        organizationCode
        active
        notificationEmail
        description
        organizationAnswers
        url
        gitUrl
        s3Path
        readGroups
        writeGroups
        created
        updated
        __typename
      }
      studyPhases {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      description
      studyCode
      studyAnswers
      s3Path
      croGroups
      readGroups
      writeGroups
      adminGroups
      created
      updated
      organizationStudiesId
      __typename
    }
    studyPhaseId
    studyPhase {
      id
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      metaDataValidationSchemaTemplateId
      metaDataValidationSchemaTemplate {
        id
        version
        description
        templateFiles
        validationSchemaTemplate
        created
        updated
        __typename
      }
      groups {
        nextToken
        __typename
      }
      samples {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      uploadBundles {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      validationSchema
      description
      studyPhaseName
      studyPhaseAnswers
      experimentSpecificVariables
      sampleTypes
      specimens
      species
      s3Path
      groupNames
      labAssayTechnologies
      phase
      readGroups
      writeGroups
      adminGroups
      croGroups
      created
      updated
      studyStudyPhasesId
      metaDataValidationSchemaTemplateStudyPhasesId
      __typename
    }
    scheduledPipeline
    scheduledDateTime
    launchablePipelineId
    launchablePipeline {
      id
      pipelineName
      organizations {
        nextToken
        __typename
      }
      pipelineVersion
      parameters
      description
      inputFileInfo
      supportedAnalyses
      supportedSubtypeOfAnalyses
      pipelines {
        nextToken
        __typename
      }
      gitRepo
      workflowId
      created
      updated
      __typename
    }
    batchId
    batch {
      id
      batchName
      batchAnnotations
      batchGroups
      annotations
      controlSamples
      filters
      description
      samples {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyBatchesId
      studyPhaseBatchesId
      __typename
    }
    runId
    userEmail
    pipelineStarted
    pipelineCompleted
    readGroups
    writeGroups
    adminGroups
    created
    updated
    studyPipelinesId
    studyPhasePipelinesId
    batchPipelinesId
    launchablePipelinesPipelinesId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPipelineQueryVariables,
  APITypes.GetPipelineQuery
>;
export const listPipelines = /* GraphQL */ `query ListPipelines(
  $id: ID
  $filter: ModelPipelineFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listPipelines(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      name
      status
      description
      s3Event
      s3ParamsFile
      s3Results
      visFileInfoS3path
      s3NfLog
      s3NfTasksLogs
      analysisType
      subtypeOfAnalysis
      parameters
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      scheduledPipeline
      scheduledDateTime
      launchablePipelineId
      launchablePipeline {
        id
        pipelineName
        pipelineVersion
        parameters
        description
        inputFileInfo
        supportedAnalyses
        supportedSubtypeOfAnalyses
        gitRepo
        workflowId
        created
        updated
        __typename
      }
      batchId
      batch {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      runId
      userEmail
      pipelineStarted
      pipelineCompleted
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPipelinesId
      studyPhasePipelinesId
      batchPipelinesId
      launchablePipelinesPipelinesId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPipelinesQueryVariables,
  APITypes.ListPipelinesQuery
>;
export const getAuxiliaryAnalysis = /* GraphQL */ `query GetAuxiliaryAnalysis($id: ID!) {
  getAuxiliaryAnalysis(id: $id) {
    id
    sampleId
    sample {
      id
      timepointId
      timepoint {
        id
        patientId
        timepointName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        patientTimepointsId
        __typename
      }
      studyPhaseId
      StudyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      BatchId
      Batch {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      metadataImmunopeptidomicsList {
        nextToken
        __typename
      }
      MetadataNanoStringList {
        nextToken
        __typename
      }
      MetadataRnaSeqList {
        nextToken
        __typename
      }
      AuxiliaryAnalysislList {
        nextToken
        __typename
      }
      domainSampleId
      experimentSpecificVariables
      collectionDate
      processingDate
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseSamplesId
      timepointSamplesId
      batchSamplesId
      __typename
    }
    auxiliaryFileS3Path
    vendorId
    specimenType
    parameters
    collectionDate
    readGroups
    writeGroups
    adminGroups
    created
    updated
    sampleAuxiliaryAnalysislListId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAuxiliaryAnalysisQueryVariables,
  APITypes.GetAuxiliaryAnalysisQuery
>;
export const listAuxiliaryAnalyses = /* GraphQL */ `query ListAuxiliaryAnalyses(
  $id: ID
  $filter: ModelAuxiliaryAnalysisFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listAuxiliaryAnalyses(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      sampleId
      sample {
        id
        timepointId
        studyPhaseId
        BatchId
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
        __typename
      }
      auxiliaryFileS3Path
      vendorId
      specimenType
      parameters
      collectionDate
      readGroups
      writeGroups
      adminGroups
      created
      updated
      sampleAuxiliaryAnalysislListId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAuxiliaryAnalysesQueryVariables,
  APITypes.ListAuxiliaryAnalysesQuery
>;
export const getMetadataImmunopeptidomics = /* GraphQL */ `query GetMetadataImmunopeptidomics($id: ID!) {
  getMetadataImmunopeptidomics(id: $id) {
    id
    sampleId
    sample {
      id
      timepointId
      timepoint {
        id
        patientId
        timepointName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        patientTimepointsId
        __typename
      }
      studyPhaseId
      StudyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      BatchId
      Batch {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      metadataImmunopeptidomicsList {
        nextToken
        __typename
      }
      MetadataNanoStringList {
        nextToken
        __typename
      }
      MetadataRnaSeqList {
        nextToken
        __typename
      }
      AuxiliaryAnalysislList {
        nextToken
        __typename
      }
      domainSampleId
      experimentSpecificVariables
      collectionDate
      processingDate
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseSamplesId
      timepointSamplesId
      batchSamplesId
      __typename
    }
    treatment {
      items {
        id
        metadataImmunopeptidomicsId
        metadataNanoStringId
        metadataRnaSeqId
        treatmentName
        dose
        doseUnit
        readGroups
        writeGroups
        adminGroups
        created
        updated
        metadataImmunopeptidomicsTreatmentId
        metadataNanoStringTreatmentId
        metadataRnaSeqTreatmentId
        __typename
      }
      nextToken
      __typename
    }
    metaDataTemplateS3Path
    species
    subtypeOfAnalysis
    sampleType
    qcStatus
    glacierBackup
    rawDataS3
    tumorVolume
    readGroups
    writeGroups
    adminGroups
    created
    updated
    isControl
    barcodesMappedToControl
    barcode
    s3Path
    sampleMetadataImmunopeptidomicsListId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMetadataImmunopeptidomicsQueryVariables,
  APITypes.GetMetadataImmunopeptidomicsQuery
>;
export const listMetadataImmunopeptidomics = /* GraphQL */ `query ListMetadataImmunopeptidomics(
  $id: ID
  $filter: ModelMetadataImmunopeptidomicsFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listMetadataImmunopeptidomics(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      sampleId
      sample {
        id
        timepointId
        studyPhaseId
        BatchId
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
        __typename
      }
      treatment {
        nextToken
        __typename
      }
      metaDataTemplateS3Path
      species
      subtypeOfAnalysis
      sampleType
      qcStatus
      glacierBackup
      rawDataS3
      tumorVolume
      readGroups
      writeGroups
      adminGroups
      created
      updated
      isControl
      barcodesMappedToControl
      barcode
      s3Path
      sampleMetadataImmunopeptidomicsListId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMetadataImmunopeptidomicsQueryVariables,
  APITypes.ListMetadataImmunopeptidomicsQuery
>;
export const getMetadataNanoString = /* GraphQL */ `query GetMetadataNanoString($id: ID!) {
  getMetadataNanoString(id: $id) {
    id
    sampleId
    sample {
      id
      timepointId
      timepoint {
        id
        patientId
        timepointName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        patientTimepointsId
        __typename
      }
      studyPhaseId
      StudyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      BatchId
      Batch {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      metadataImmunopeptidomicsList {
        nextToken
        __typename
      }
      MetadataNanoStringList {
        nextToken
        __typename
      }
      MetadataRnaSeqList {
        nextToken
        __typename
      }
      AuxiliaryAnalysislList {
        nextToken
        __typename
      }
      domainSampleId
      experimentSpecificVariables
      collectionDate
      processingDate
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseSamplesId
      timepointSamplesId
      batchSamplesId
      __typename
    }
    treatment {
      items {
        id
        metadataImmunopeptidomicsId
        metadataNanoStringId
        metadataRnaSeqId
        treatmentName
        dose
        doseUnit
        readGroups
        writeGroups
        adminGroups
        created
        updated
        metadataImmunopeptidomicsTreatmentId
        metadataNanoStringTreatmentId
        metadataRnaSeqTreatmentId
        __typename
      }
      nextToken
      __typename
    }
    metaDataTemplateS3Path
    subtypeOfAnalysis
    species
    sampleType
    qcStatus
    glacierBackup
    rawDataS3
    tumorVolume
    dv200Percentage
    concNanoGramPerMicroL
    rnaMassNanoGram
    rnaVolumeMicroL
    rin
    readGroups
    writeGroups
    adminGroups
    created
    updated
    isControl
    barcodesMappedToControl
    barcode
    s3Path
    sampleMetadataNanoStringListId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMetadataNanoStringQueryVariables,
  APITypes.GetMetadataNanoStringQuery
>;
export const listMetadataNanoStrings = /* GraphQL */ `query ListMetadataNanoStrings(
  $id: ID
  $filter: ModelMetadataNanoStringFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listMetadataNanoStrings(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      sampleId
      sample {
        id
        timepointId
        studyPhaseId
        BatchId
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
        __typename
      }
      treatment {
        nextToken
        __typename
      }
      metaDataTemplateS3Path
      subtypeOfAnalysis
      species
      sampleType
      qcStatus
      glacierBackup
      rawDataS3
      tumorVolume
      dv200Percentage
      concNanoGramPerMicroL
      rnaMassNanoGram
      rnaVolumeMicroL
      rin
      readGroups
      writeGroups
      adminGroups
      created
      updated
      isControl
      barcodesMappedToControl
      barcode
      s3Path
      sampleMetadataNanoStringListId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMetadataNanoStringsQueryVariables,
  APITypes.ListMetadataNanoStringsQuery
>;
export const getMetadataRnaSeq = /* GraphQL */ `query GetMetadataRnaSeq($id: ID!) {
  getMetadataRnaSeq(id: $id) {
    id
    sampleId
    sample {
      id
      timepointId
      timepoint {
        id
        patientId
        timepointName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        patientTimepointsId
        __typename
      }
      studyPhaseId
      StudyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      BatchId
      Batch {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      metadataImmunopeptidomicsList {
        nextToken
        __typename
      }
      MetadataNanoStringList {
        nextToken
        __typename
      }
      MetadataRnaSeqList {
        nextToken
        __typename
      }
      AuxiliaryAnalysislList {
        nextToken
        __typename
      }
      domainSampleId
      experimentSpecificVariables
      collectionDate
      processingDate
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseSamplesId
      timepointSamplesId
      batchSamplesId
      __typename
    }
    treatment {
      items {
        id
        metadataImmunopeptidomicsId
        metadataNanoStringId
        metadataRnaSeqId
        treatmentName
        dose
        doseUnit
        readGroups
        writeGroups
        adminGroups
        created
        updated
        metadataImmunopeptidomicsTreatmentId
        metadataNanoStringTreatmentId
        metadataRnaSeqTreatmentId
        __typename
      }
      nextToken
      __typename
    }
    metaDataTemplateS3Path
    subtypeOfAnalysis
    species
    sampleType
    qcStatus
    glacierBackup
    rawDataS3
    tumorVolume
    sequenceType
    strandSpecific
    UMI
    adaptorsRemoved
    adaptorsS3Path
    indexSequences
    concNanoGramPerMicroL
    rnaMassNanoGram
    rnaVolumeMicroL
    rin
    readGroups
    writeGroups
    adminGroups
    created
    updated
    isControl
    barcodesMappedToControl
    barcode
    s3Path
    sampleMetadataRnaSeqListId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMetadataRnaSeqQueryVariables,
  APITypes.GetMetadataRnaSeqQuery
>;
export const listMetadataRnaSeqs = /* GraphQL */ `query ListMetadataRnaSeqs(
  $id: ID
  $filter: ModelMetadataRnaSeqFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listMetadataRnaSeqs(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      sampleId
      sample {
        id
        timepointId
        studyPhaseId
        BatchId
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
        __typename
      }
      treatment {
        nextToken
        __typename
      }
      metaDataTemplateS3Path
      subtypeOfAnalysis
      species
      sampleType
      qcStatus
      glacierBackup
      rawDataS3
      tumorVolume
      sequenceType
      strandSpecific
      UMI
      adaptorsRemoved
      adaptorsS3Path
      indexSequences
      concNanoGramPerMicroL
      rnaMassNanoGram
      rnaVolumeMicroL
      rin
      readGroups
      writeGroups
      adminGroups
      created
      updated
      isControl
      barcodesMappedToControl
      barcode
      s3Path
      sampleMetadataRnaSeqListId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMetadataRnaSeqsQueryVariables,
  APITypes.ListMetadataRnaSeqsQuery
>;
export const getTreatment = /* GraphQL */ `query GetTreatment($id: ID!) {
  getTreatment(id: $id) {
    id
    metadataImmunopeptidomicsId
    metadataImmunopeptidomics {
      id
      sampleId
      sample {
        id
        timepointId
        studyPhaseId
        BatchId
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
        __typename
      }
      treatment {
        nextToken
        __typename
      }
      metaDataTemplateS3Path
      species
      subtypeOfAnalysis
      sampleType
      qcStatus
      glacierBackup
      rawDataS3
      tumorVolume
      readGroups
      writeGroups
      adminGroups
      created
      updated
      isControl
      barcodesMappedToControl
      barcode
      s3Path
      sampleMetadataImmunopeptidomicsListId
      __typename
    }
    metadataNanoStringId
    metadataNanoString {
      id
      sampleId
      sample {
        id
        timepointId
        studyPhaseId
        BatchId
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
        __typename
      }
      treatment {
        nextToken
        __typename
      }
      metaDataTemplateS3Path
      subtypeOfAnalysis
      species
      sampleType
      qcStatus
      glacierBackup
      rawDataS3
      tumorVolume
      dv200Percentage
      concNanoGramPerMicroL
      rnaMassNanoGram
      rnaVolumeMicroL
      rin
      readGroups
      writeGroups
      adminGroups
      created
      updated
      isControl
      barcodesMappedToControl
      barcode
      s3Path
      sampleMetadataNanoStringListId
      __typename
    }
    metadataRnaSeqId
    metadataRnaSeq {
      id
      sampleId
      sample {
        id
        timepointId
        studyPhaseId
        BatchId
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
        __typename
      }
      treatment {
        nextToken
        __typename
      }
      metaDataTemplateS3Path
      subtypeOfAnalysis
      species
      sampleType
      qcStatus
      glacierBackup
      rawDataS3
      tumorVolume
      sequenceType
      strandSpecific
      UMI
      adaptorsRemoved
      adaptorsS3Path
      indexSequences
      concNanoGramPerMicroL
      rnaMassNanoGram
      rnaVolumeMicroL
      rin
      readGroups
      writeGroups
      adminGroups
      created
      updated
      isControl
      barcodesMappedToControl
      barcode
      s3Path
      sampleMetadataRnaSeqListId
      __typename
    }
    treatmentName
    dose
    doseUnit
    readGroups
    writeGroups
    adminGroups
    created
    updated
    metadataImmunopeptidomicsTreatmentId
    metadataNanoStringTreatmentId
    metadataRnaSeqTreatmentId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTreatmentQueryVariables,
  APITypes.GetTreatmentQuery
>;
export const listTreatments = /* GraphQL */ `query ListTreatments(
  $id: ID
  $filter: ModelTreatmentFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listTreatments(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      metadataImmunopeptidomicsId
      metadataImmunopeptidomics {
        id
        sampleId
        metaDataTemplateS3Path
        species
        subtypeOfAnalysis
        sampleType
        qcStatus
        glacierBackup
        rawDataS3
        tumorVolume
        readGroups
        writeGroups
        adminGroups
        created
        updated
        isControl
        barcodesMappedToControl
        barcode
        s3Path
        sampleMetadataImmunopeptidomicsListId
        __typename
      }
      metadataNanoStringId
      metadataNanoString {
        id
        sampleId
        metaDataTemplateS3Path
        subtypeOfAnalysis
        species
        sampleType
        qcStatus
        glacierBackup
        rawDataS3
        tumorVolume
        dv200Percentage
        concNanoGramPerMicroL
        rnaMassNanoGram
        rnaVolumeMicroL
        rin
        readGroups
        writeGroups
        adminGroups
        created
        updated
        isControl
        barcodesMappedToControl
        barcode
        s3Path
        sampleMetadataNanoStringListId
        __typename
      }
      metadataRnaSeqId
      metadataRnaSeq {
        id
        sampleId
        metaDataTemplateS3Path
        subtypeOfAnalysis
        species
        sampleType
        qcStatus
        glacierBackup
        rawDataS3
        tumorVolume
        sequenceType
        strandSpecific
        UMI
        adaptorsRemoved
        adaptorsS3Path
        indexSequences
        concNanoGramPerMicroL
        rnaMassNanoGram
        rnaVolumeMicroL
        rin
        readGroups
        writeGroups
        adminGroups
        created
        updated
        isControl
        barcodesMappedToControl
        barcode
        s3Path
        sampleMetadataRnaSeqListId
        __typename
      }
      treatmentName
      dose
      doseUnit
      readGroups
      writeGroups
      adminGroups
      created
      updated
      metadataImmunopeptidomicsTreatmentId
      metadataNanoStringTreatmentId
      metadataRnaSeqTreatmentId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTreatmentsQueryVariables,
  APITypes.ListTreatmentsQuery
>;
export const getLaunchablePipelines = /* GraphQL */ `query GetLaunchablePipelines($id: ID!) {
  getLaunchablePipelines(id: $id) {
    id
    pipelineName
    organizations {
      items {
        id
        organizationId
        launchablePipelinesId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    pipelineVersion
    parameters
    description
    inputFileInfo
    supportedAnalyses
    supportedSubtypeOfAnalyses
    pipelines {
      items {
        id
        name
        status
        description
        s3Event
        s3ParamsFile
        s3Results
        visFileInfoS3path
        s3NfLog
        s3NfTasksLogs
        analysisType
        subtypeOfAnalysis
        parameters
        studyId
        studyPhaseId
        scheduledPipeline
        scheduledDateTime
        launchablePipelineId
        batchId
        runId
        userEmail
        pipelineStarted
        pipelineCompleted
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPipelinesId
        studyPhasePipelinesId
        batchPipelinesId
        launchablePipelinesPipelinesId
        __typename
      }
      nextToken
      __typename
    }
    gitRepo
    workflowId
    created
    updated
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLaunchablePipelinesQueryVariables,
  APITypes.GetLaunchablePipelinesQuery
>;
export const listLaunchablePipelines = /* GraphQL */ `query ListLaunchablePipelines(
  $id: ID
  $filter: ModelLaunchablePipelinesFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listLaunchablePipelines(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      pipelineName
      organizations {
        nextToken
        __typename
      }
      pipelineVersion
      parameters
      description
      inputFileInfo
      supportedAnalyses
      supportedSubtypeOfAnalyses
      pipelines {
        nextToken
        __typename
      }
      gitRepo
      workflowId
      created
      updated
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLaunchablePipelinesQueryVariables,
  APITypes.ListLaunchablePipelinesQuery
>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    email
    organization
    gitToken
    gitName
    gitURL
    readGroups
    created
    updated
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      email
      organization
      gitToken
      gitName
      gitURL
      readGroups
      created
      updated
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const getUploadLog = /* GraphQL */ `query GetUploadLog($id: ID!) {
  getUploadLog(id: $id) {
    id
    uploadS3Path
    status
    readGroups
    created
    updated
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUploadLogQueryVariables,
  APITypes.GetUploadLogQuery
>;
export const listUploadLogs = /* GraphQL */ `query ListUploadLogs(
  $id: ID
  $filter: ModelUploadLogFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUploadLogs(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      uploadS3Path
      status
      readGroups
      created
      updated
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUploadLogsQueryVariables,
  APITypes.ListUploadLogsQuery
>;
export const getVisualizations = /* GraphQL */ `query GetVisualizations($id: ID!) {
  getVisualizations(id: $id) {
    id
    visualizationName
    description
    version
    deploymentPath
    validationSchema
    allowedExtensions
    supportedAnalyses
    created
    updated
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetVisualizationsQueryVariables,
  APITypes.GetVisualizationsQuery
>;
export const listVisualizations = /* GraphQL */ `query ListVisualizations(
  $id: ID
  $filter: ModelVisualizationsFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listVisualizations(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      visualizationName
      description
      version
      deploymentPath
      validationSchema
      allowedExtensions
      supportedAnalyses
      created
      updated
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVisualizationsQueryVariables,
  APITypes.ListVisualizationsQuery
>;
export const getVisualizationSession = /* GraphQL */ `query GetVisualizationSession($id: ID!) {
  getVisualizationSession(id: $id) {
    id
    statuses
    data
    description
    created
    updated
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetVisualizationSessionQueryVariables,
  APITypes.GetVisualizationSessionQuery
>;
export const listVisualizationSessions = /* GraphQL */ `query ListVisualizationSessions(
  $filter: ModelVisualizationSessionFilterInput
  $limit: Int
  $nextToken: String
) {
  listVisualizationSessions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      statuses
      data
      description
      created
      updated
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVisualizationSessionsQueryVariables,
  APITypes.ListVisualizationSessionsQuery
>;
export const getDataLabEC2Instances = /* GraphQL */ `query GetDataLabEC2Instances($id: ID!) {
  getDataLabEC2Instances(id: $id) {
    id
    creationTimestamp
    stoppedTimestamp
    startTimestamp
    terminatedTimestamp
    stoppedTimePeriod
    runningTimePeriod
    userEmail
    status
    type
    dataLabInstanceSessions {
      items {
        id
        dataLabInstanceId
        s3Path
        instanceLaunchEvent
        status
        title
        description
        created
        updated
        dataLabEC2InstancesDataLabInstanceSessionsId
        owner
        __typename
      }
      nextToken
      __typename
    }
    dataLabInstanceStatusChanges {
      items {
        id
        dataLabInstanceId
        organizationId
        previousStatus
        newStatus
        initiatedBy
        action
        performedBy
        additionalNotes
        created
        updated
        dataLabEC2InstancesDataLabInstanceStatusChangesId
        owner
        __typename
      }
      nextToken
      __typename
    }
    organizationId
    organizationName
    instanceUrl
    instanceId
    infrastructureRelatedEntities
    instanceLaunchEvent
    adminGroups
    created
    updated
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDataLabEC2InstancesQueryVariables,
  APITypes.GetDataLabEC2InstancesQuery
>;
export const listDataLabEC2Instances = /* GraphQL */ `query ListDataLabEC2Instances(
  $filter: ModelDataLabEC2InstancesFilterInput
  $limit: Int
  $nextToken: String
) {
  listDataLabEC2Instances(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      creationTimestamp
      stoppedTimestamp
      startTimestamp
      terminatedTimestamp
      stoppedTimePeriod
      runningTimePeriod
      userEmail
      status
      type
      dataLabInstanceSessions {
        nextToken
        __typename
      }
      dataLabInstanceStatusChanges {
        nextToken
        __typename
      }
      organizationId
      organizationName
      instanceUrl
      instanceId
      infrastructureRelatedEntities
      instanceLaunchEvent
      adminGroups
      created
      updated
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDataLabEC2InstancesQueryVariables,
  APITypes.ListDataLabEC2InstancesQuery
>;
export const getDataLabEC2InstanceSession = /* GraphQL */ `query GetDataLabEC2InstanceSession($id: ID!) {
  getDataLabEC2InstanceSession(id: $id) {
    id
    dataLabInstanceId
    dataLabInstance {
      id
      creationTimestamp
      stoppedTimestamp
      startTimestamp
      terminatedTimestamp
      stoppedTimePeriod
      runningTimePeriod
      userEmail
      status
      type
      dataLabInstanceSessions {
        nextToken
        __typename
      }
      dataLabInstanceStatusChanges {
        nextToken
        __typename
      }
      organizationId
      organizationName
      instanceUrl
      instanceId
      infrastructureRelatedEntities
      instanceLaunchEvent
      adminGroups
      created
      updated
      owner
      __typename
    }
    s3Path
    instanceLaunchEvent
    status
    title
    description
    created
    updated
    dataLabEC2InstancesDataLabInstanceSessionsId
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDataLabEC2InstanceSessionQueryVariables,
  APITypes.GetDataLabEC2InstanceSessionQuery
>;
export const listDataLabEC2InstanceSessions = /* GraphQL */ `query ListDataLabEC2InstanceSessions(
  $filter: ModelDataLabEC2InstanceSessionFilterInput
  $limit: Int
  $nextToken: String
) {
  listDataLabEC2InstanceSessions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      dataLabInstanceId
      dataLabInstance {
        id
        creationTimestamp
        stoppedTimestamp
        startTimestamp
        terminatedTimestamp
        stoppedTimePeriod
        runningTimePeriod
        userEmail
        status
        type
        organizationId
        organizationName
        instanceUrl
        instanceId
        infrastructureRelatedEntities
        instanceLaunchEvent
        adminGroups
        created
        updated
        owner
        __typename
      }
      s3Path
      instanceLaunchEvent
      status
      title
      description
      created
      updated
      dataLabEC2InstancesDataLabInstanceSessionsId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDataLabEC2InstanceSessionsQueryVariables,
  APITypes.ListDataLabEC2InstanceSessionsQuery
>;
export const getDataLabEC2InstanceStatusChange = /* GraphQL */ `query GetDataLabEC2InstanceStatusChange($id: ID!) {
  getDataLabEC2InstanceStatusChange(id: $id) {
    id
    dataLabInstanceId
    dataLabInstance {
      id
      creationTimestamp
      stoppedTimestamp
      startTimestamp
      terminatedTimestamp
      stoppedTimePeriod
      runningTimePeriod
      userEmail
      status
      type
      dataLabInstanceSessions {
        nextToken
        __typename
      }
      dataLabInstanceStatusChanges {
        nextToken
        __typename
      }
      organizationId
      organizationName
      instanceUrl
      instanceId
      infrastructureRelatedEntities
      instanceLaunchEvent
      adminGroups
      created
      updated
      owner
      __typename
    }
    organizationId
    previousStatus
    newStatus
    initiatedBy
    action
    performedBy
    additionalNotes
    created
    updated
    dataLabEC2InstancesDataLabInstanceStatusChangesId
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDataLabEC2InstanceStatusChangeQueryVariables,
  APITypes.GetDataLabEC2InstanceStatusChangeQuery
>;
export const listDataLabEC2InstanceStatusChanges = /* GraphQL */ `query ListDataLabEC2InstanceStatusChanges(
  $filter: ModelDataLabEC2InstanceStatusChangeFilterInput
  $limit: Int
  $nextToken: String
) {
  listDataLabEC2InstanceStatusChanges(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      dataLabInstanceId
      dataLabInstance {
        id
        creationTimestamp
        stoppedTimestamp
        startTimestamp
        terminatedTimestamp
        stoppedTimePeriod
        runningTimePeriod
        userEmail
        status
        type
        organizationId
        organizationName
        instanceUrl
        instanceId
        infrastructureRelatedEntities
        instanceLaunchEvent
        adminGroups
        created
        updated
        owner
        __typename
      }
      organizationId
      previousStatus
      newStatus
      initiatedBy
      action
      performedBy
      additionalNotes
      created
      updated
      dataLabEC2InstancesDataLabInstanceStatusChangesId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDataLabEC2InstanceStatusChangesQueryVariables,
  APITypes.ListDataLabEC2InstanceStatusChangesQuery
>;
export const getUploadBundle = /* GraphQL */ `query GetUploadBundle($id: ID!) {
  getUploadBundle(id: $id) {
    id
    studyPhaseId
    studyPhase {
      id
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      metaDataValidationSchemaTemplateId
      metaDataValidationSchemaTemplate {
        id
        version
        description
        templateFiles
        validationSchemaTemplate
        created
        updated
        __typename
      }
      groups {
        nextToken
        __typename
      }
      samples {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      uploadBundles {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      validationSchema
      description
      studyPhaseName
      studyPhaseAnswers
      experimentSpecificVariables
      sampleTypes
      specimens
      species
      s3Path
      groupNames
      labAssayTechnologies
      phase
      readGroups
      writeGroups
      adminGroups
      croGroups
      created
      updated
      studyStudyPhasesId
      metaDataValidationSchemaTemplateStudyPhasesId
      __typename
    }
    bundleLog
    validationErrors
    status
    userEmail
    uploadBundleAnalysisSession {
      id
      uploadBundleId
      uploadBundle {
        id
        studyPhaseId
        bundleLog
        validationErrors
        status
        userEmail
        adminGroups
        readGroups
        completionTime
        created
        updated
        studyPhaseUploadBundlesId
        uploadBundleUploadBundleAnalysisSessionId
        owner
        __typename
      }
      uploadSampleAnalysisSession {
        nextToken
        __typename
      }
      metadataFileS3Path
      step
      listOfRawFileNames
      analysisType
      subtypeOfAnalysis
      studyPhaseId
      metadataHelperLambdaResponseS3Path
      barcodeToFilesToUploadS3Path
      adminGroups
      readGroups
      created
      updated
      owner
      __typename
    }
    uploadSamples {
      items {
        id
        uploadBundleId
        sampleId
        barcode
        sampleLog
        writeMetadata
        status
        adminGroups
        readGroups
        created
        updated
        uploadBundleUploadSamplesId
        owner
        __typename
      }
      nextToken
      __typename
    }
    uploadFiles {
      items {
        id
        uploadSampleId
        uploadBundleId
        multipartUploadId
        status
        toSkipUpload
        fileName
        fileType
        s3Path
        size
        fileLog
        adminGroups
        readGroups
        created
        updated
        uploadBundleUploadFilesId
        uploadSampleUploadFilesId
        owner
        __typename
      }
      nextToken
      __typename
    }
    adminGroups
    readGroups
    completionTime
    created
    updated
    studyPhaseUploadBundlesId
    uploadBundleUploadBundleAnalysisSessionId
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUploadBundleQueryVariables,
  APITypes.GetUploadBundleQuery
>;
export const listUploadBundles = /* GraphQL */ `query ListUploadBundles(
  $filter: ModelUploadBundleFilterInput
  $limit: Int
  $nextToken: String
) {
  listUploadBundles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      bundleLog
      validationErrors
      status
      userEmail
      uploadBundleAnalysisSession {
        id
        uploadBundleId
        metadataFileS3Path
        step
        listOfRawFileNames
        analysisType
        subtypeOfAnalysis
        studyPhaseId
        metadataHelperLambdaResponseS3Path
        barcodeToFilesToUploadS3Path
        adminGroups
        readGroups
        created
        updated
        owner
        __typename
      }
      uploadSamples {
        nextToken
        __typename
      }
      uploadFiles {
        nextToken
        __typename
      }
      adminGroups
      readGroups
      completionTime
      created
      updated
      studyPhaseUploadBundlesId
      uploadBundleUploadBundleAnalysisSessionId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUploadBundlesQueryVariables,
  APITypes.ListUploadBundlesQuery
>;
export const getUploadBundleAnalysisSession = /* GraphQL */ `query GetUploadBundleAnalysisSession($id: ID!) {
  getUploadBundleAnalysisSession(id: $id) {
    id
    uploadBundleId
    uploadBundle {
      id
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      bundleLog
      validationErrors
      status
      userEmail
      uploadBundleAnalysisSession {
        id
        uploadBundleId
        metadataFileS3Path
        step
        listOfRawFileNames
        analysisType
        subtypeOfAnalysis
        studyPhaseId
        metadataHelperLambdaResponseS3Path
        barcodeToFilesToUploadS3Path
        adminGroups
        readGroups
        created
        updated
        owner
        __typename
      }
      uploadSamples {
        nextToken
        __typename
      }
      uploadFiles {
        nextToken
        __typename
      }
      adminGroups
      readGroups
      completionTime
      created
      updated
      studyPhaseUploadBundlesId
      uploadBundleUploadBundleAnalysisSessionId
      owner
      __typename
    }
    uploadSampleAnalysisSession {
      items {
        id
        uploadBundleAnalysisSessionId
        sampleId
        rowFromMetaDataFileMappedToSample
        adminGroups
        readGroups
        created
        updated
        uploadBundleAnalysisSessionUploadSampleAnalysisSessionId
        owner
        __typename
      }
      nextToken
      __typename
    }
    metadataFileS3Path
    step
    listOfRawFileNames
    analysisType
    subtypeOfAnalysis
    studyPhaseId
    metadataHelperLambdaResponseS3Path
    barcodeToFilesToUploadS3Path
    adminGroups
    readGroups
    created
    updated
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUploadBundleAnalysisSessionQueryVariables,
  APITypes.GetUploadBundleAnalysisSessionQuery
>;
export const listUploadBundleAnalysisSessions = /* GraphQL */ `query ListUploadBundleAnalysisSessions(
  $filter: ModelUploadBundleAnalysisSessionFilterInput
  $limit: Int
  $nextToken: String
) {
  listUploadBundleAnalysisSessions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      uploadBundleId
      uploadBundle {
        id
        studyPhaseId
        bundleLog
        validationErrors
        status
        userEmail
        adminGroups
        readGroups
        completionTime
        created
        updated
        studyPhaseUploadBundlesId
        uploadBundleUploadBundleAnalysisSessionId
        owner
        __typename
      }
      uploadSampleAnalysisSession {
        nextToken
        __typename
      }
      metadataFileS3Path
      step
      listOfRawFileNames
      analysisType
      subtypeOfAnalysis
      studyPhaseId
      metadataHelperLambdaResponseS3Path
      barcodeToFilesToUploadS3Path
      adminGroups
      readGroups
      created
      updated
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUploadBundleAnalysisSessionsQueryVariables,
  APITypes.ListUploadBundleAnalysisSessionsQuery
>;
export const getUploadSample = /* GraphQL */ `query GetUploadSample($id: ID!) {
  getUploadSample(id: $id) {
    id
    uploadBundleId
    uploadBundle {
      id
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      bundleLog
      validationErrors
      status
      userEmail
      uploadBundleAnalysisSession {
        id
        uploadBundleId
        metadataFileS3Path
        step
        listOfRawFileNames
        analysisType
        subtypeOfAnalysis
        studyPhaseId
        metadataHelperLambdaResponseS3Path
        barcodeToFilesToUploadS3Path
        adminGroups
        readGroups
        created
        updated
        owner
        __typename
      }
      uploadSamples {
        nextToken
        __typename
      }
      uploadFiles {
        nextToken
        __typename
      }
      adminGroups
      readGroups
      completionTime
      created
      updated
      studyPhaseUploadBundlesId
      uploadBundleUploadBundleAnalysisSessionId
      owner
      __typename
    }
    sampleId
    barcode
    sampleLog
    writeMetadata
    status
    uploadFiles {
      items {
        id
        uploadSampleId
        uploadBundleId
        multipartUploadId
        status
        toSkipUpload
        fileName
        fileType
        s3Path
        size
        fileLog
        adminGroups
        readGroups
        created
        updated
        uploadBundleUploadFilesId
        uploadSampleUploadFilesId
        owner
        __typename
      }
      nextToken
      __typename
    }
    adminGroups
    readGroups
    created
    updated
    uploadBundleUploadSamplesId
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUploadSampleQueryVariables,
  APITypes.GetUploadSampleQuery
>;
export const listUploadSamples = /* GraphQL */ `query ListUploadSamples(
  $filter: ModelUploadSampleFilterInput
  $limit: Int
  $nextToken: String
) {
  listUploadSamples(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      uploadBundleId
      uploadBundle {
        id
        studyPhaseId
        bundleLog
        validationErrors
        status
        userEmail
        adminGroups
        readGroups
        completionTime
        created
        updated
        studyPhaseUploadBundlesId
        uploadBundleUploadBundleAnalysisSessionId
        owner
        __typename
      }
      sampleId
      barcode
      sampleLog
      writeMetadata
      status
      uploadFiles {
        nextToken
        __typename
      }
      adminGroups
      readGroups
      created
      updated
      uploadBundleUploadSamplesId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUploadSamplesQueryVariables,
  APITypes.ListUploadSamplesQuery
>;
export const getUploadSampleAnalysisSession = /* GraphQL */ `query GetUploadSampleAnalysisSession($id: ID!) {
  getUploadSampleAnalysisSession(id: $id) {
    id
    uploadBundleAnalysisSessionId
    uploadBundleAnalysisSession {
      id
      uploadBundleId
      uploadBundle {
        id
        studyPhaseId
        bundleLog
        validationErrors
        status
        userEmail
        adminGroups
        readGroups
        completionTime
        created
        updated
        studyPhaseUploadBundlesId
        uploadBundleUploadBundleAnalysisSessionId
        owner
        __typename
      }
      uploadSampleAnalysisSession {
        nextToken
        __typename
      }
      metadataFileS3Path
      step
      listOfRawFileNames
      analysisType
      subtypeOfAnalysis
      studyPhaseId
      metadataHelperLambdaResponseS3Path
      barcodeToFilesToUploadS3Path
      adminGroups
      readGroups
      created
      updated
      owner
      __typename
    }
    sampleId
    rowFromMetaDataFileMappedToSample
    adminGroups
    readGroups
    created
    updated
    uploadBundleAnalysisSessionUploadSampleAnalysisSessionId
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUploadSampleAnalysisSessionQueryVariables,
  APITypes.GetUploadSampleAnalysisSessionQuery
>;
export const listUploadSampleAnalysisSessions = /* GraphQL */ `query ListUploadSampleAnalysisSessions(
  $filter: ModelUploadSampleAnalysisSessionFilterInput
  $limit: Int
  $nextToken: String
) {
  listUploadSampleAnalysisSessions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      uploadBundleAnalysisSessionId
      uploadBundleAnalysisSession {
        id
        uploadBundleId
        metadataFileS3Path
        step
        listOfRawFileNames
        analysisType
        subtypeOfAnalysis
        studyPhaseId
        metadataHelperLambdaResponseS3Path
        barcodeToFilesToUploadS3Path
        adminGroups
        readGroups
        created
        updated
        owner
        __typename
      }
      sampleId
      rowFromMetaDataFileMappedToSample
      adminGroups
      readGroups
      created
      updated
      uploadBundleAnalysisSessionUploadSampleAnalysisSessionId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUploadSampleAnalysisSessionsQueryVariables,
  APITypes.ListUploadSampleAnalysisSessionsQuery
>;
export const getUploadFile = /* GraphQL */ `query GetUploadFile($id: ID!) {
  getUploadFile(id: $id) {
    id
    uploadSampleId
    uploadSample {
      id
      uploadBundleId
      uploadBundle {
        id
        studyPhaseId
        bundleLog
        validationErrors
        status
        userEmail
        adminGroups
        readGroups
        completionTime
        created
        updated
        studyPhaseUploadBundlesId
        uploadBundleUploadBundleAnalysisSessionId
        owner
        __typename
      }
      sampleId
      barcode
      sampleLog
      writeMetadata
      status
      uploadFiles {
        nextToken
        __typename
      }
      adminGroups
      readGroups
      created
      updated
      uploadBundleUploadSamplesId
      owner
      __typename
    }
    uploadBundleId
    uploadBundle {
      id
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      bundleLog
      validationErrors
      status
      userEmail
      uploadBundleAnalysisSession {
        id
        uploadBundleId
        metadataFileS3Path
        step
        listOfRawFileNames
        analysisType
        subtypeOfAnalysis
        studyPhaseId
        metadataHelperLambdaResponseS3Path
        barcodeToFilesToUploadS3Path
        adminGroups
        readGroups
        created
        updated
        owner
        __typename
      }
      uploadSamples {
        nextToken
        __typename
      }
      uploadFiles {
        nextToken
        __typename
      }
      adminGroups
      readGroups
      completionTime
      created
      updated
      studyPhaseUploadBundlesId
      uploadBundleUploadBundleAnalysisSessionId
      owner
      __typename
    }
    multipartUploadId
    status
    toSkipUpload
    fileName
    fileType
    s3Path
    size
    fileLog
    adminGroups
    readGroups
    created
    updated
    uploadBundleUploadFilesId
    uploadSampleUploadFilesId
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUploadFileQueryVariables,
  APITypes.GetUploadFileQuery
>;
export const listUploadFiles = /* GraphQL */ `query ListUploadFiles(
  $filter: ModelUploadFileFilterInput
  $limit: Int
  $nextToken: String
) {
  listUploadFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      uploadSampleId
      uploadSample {
        id
        uploadBundleId
        sampleId
        barcode
        sampleLog
        writeMetadata
        status
        adminGroups
        readGroups
        created
        updated
        uploadBundleUploadSamplesId
        owner
        __typename
      }
      uploadBundleId
      uploadBundle {
        id
        studyPhaseId
        bundleLog
        validationErrors
        status
        userEmail
        adminGroups
        readGroups
        completionTime
        created
        updated
        studyPhaseUploadBundlesId
        uploadBundleUploadBundleAnalysisSessionId
        owner
        __typename
      }
      multipartUploadId
      status
      toSkipUpload
      fileName
      fileType
      s3Path
      size
      fileLog
      adminGroups
      readGroups
      created
      updated
      uploadBundleUploadFilesId
      uploadSampleUploadFilesId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUploadFilesQueryVariables,
  APITypes.ListUploadFilesQuery
>;
export const getOrganizationLaunchablePipelines = /* GraphQL */ `query GetOrganizationLaunchablePipelines($id: ID!) {
  getOrganizationLaunchablePipelines(id: $id) {
    id
    organizationId
    launchablePipelinesId
    organization {
      id
      organizationName
      organizationCode
      active
      studies {
        nextToken
        __typename
      }
      launchablePipelines {
        nextToken
        __typename
      }
      notificationEmail
      description
      organizationAnswers
      url
      gitUrl
      s3Path
      readGroups
      writeGroups
      created
      updated
      __typename
    }
    launchablePipelines {
      id
      pipelineName
      organizations {
        nextToken
        __typename
      }
      pipelineVersion
      parameters
      description
      inputFileInfo
      supportedAnalyses
      supportedSubtypeOfAnalyses
      pipelines {
        nextToken
        __typename
      }
      gitRepo
      workflowId
      created
      updated
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationLaunchablePipelinesQueryVariables,
  APITypes.GetOrganizationLaunchablePipelinesQuery
>;
export const listOrganizationLaunchablePipelines = /* GraphQL */ `query ListOrganizationLaunchablePipelines(
  $filter: ModelOrganizationLaunchablePipelinesFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrganizationLaunchablePipelines(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      organizationId
      launchablePipelinesId
      organization {
        id
        organizationName
        organizationCode
        active
        notificationEmail
        description
        organizationAnswers
        url
        gitUrl
        s3Path
        readGroups
        writeGroups
        created
        updated
        __typename
      }
      launchablePipelines {
        id
        pipelineName
        pipelineVersion
        parameters
        description
        inputFileInfo
        supportedAnalyses
        supportedSubtypeOfAnalyses
        gitRepo
        workflowId
        created
        updated
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrganizationLaunchablePipelinesQueryVariables,
  APITypes.ListOrganizationLaunchablePipelinesQuery
>;
export const studiesByOrganization = /* GraphQL */ `query StudiesByOrganization(
  $organizationId: ID!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelStudyFilterInput
  $limit: Int
  $nextToken: String
) {
  studiesByOrganization(
    organizationId: $organizationId
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      studyName
      organizationId
      organization {
        id
        organizationName
        organizationCode
        active
        notificationEmail
        description
        organizationAnswers
        url
        gitUrl
        s3Path
        readGroups
        writeGroups
        created
        updated
        __typename
      }
      studyPhases {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      description
      studyCode
      studyAnswers
      s3Path
      croGroups
      readGroups
      writeGroups
      adminGroups
      created
      updated
      organizationStudiesId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.StudiesByOrganizationQueryVariables,
  APITypes.StudiesByOrganizationQuery
>;
export const studyPhasesByStudy = /* GraphQL */ `query StudyPhasesByStudy(
  $studyId: ID!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelStudyPhaseFilterInput
  $limit: Int
  $nextToken: String
) {
  studyPhasesByStudy(
    studyId: $studyId
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      metaDataValidationSchemaTemplateId
      metaDataValidationSchemaTemplate {
        id
        version
        description
        templateFiles
        validationSchemaTemplate
        created
        updated
        __typename
      }
      groups {
        nextToken
        __typename
      }
      samples {
        nextToken
        __typename
      }
      batches {
        nextToken
        __typename
      }
      uploadBundles {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      validationSchema
      description
      studyPhaseName
      studyPhaseAnswers
      experimentSpecificVariables
      sampleTypes
      specimens
      species
      s3Path
      groupNames
      labAssayTechnologies
      phase
      readGroups
      writeGroups
      adminGroups
      croGroups
      created
      updated
      studyStudyPhasesId
      metaDataValidationSchemaTemplateStudyPhasesId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.StudyPhasesByStudyQueryVariables,
  APITypes.StudyPhasesByStudyQuery
>;
export const questionsByEntityType = /* GraphQL */ `query QuestionsByEntityType(
  $entityType: EntityType!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEntityQuestionsFilterInput
  $limit: Int
  $nextToken: String
) {
  questionsByEntityType(
    entityType: $entityType
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      entityType
      questions
      created
      updated
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.QuestionsByEntityTypeQueryVariables,
  APITypes.QuestionsByEntityTypeQuery
>;
export const groupsByStudyPhase = /* GraphQL */ `query GroupsByStudyPhase(
  $studyPhaseId: ID!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  groupsByStudyPhase(
    studyPhaseId: $studyPhaseId
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      type
      groupName
      s3Path
      patients {
        nextToken
        __typename
      }
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseGroupsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GroupsByStudyPhaseQueryVariables,
  APITypes.GroupsByStudyPhaseQuery
>;
export const patientsByGroup = /* GraphQL */ `query PatientsByGroup(
  $groupId: ID!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPatientFilterInput
  $limit: Int
  $nextToken: String
) {
  patientsByGroup(
    groupId: $groupId
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      groupId
      group {
        id
        studyPhaseId
        type
        groupName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseGroupsId
        __typename
      }
      timepoints {
        nextToken
        __typename
      }
      patientStudyPhaseId
      patientStudyId
      s3Path
      readGroups
      writeGroups
      adminGroups
      created
      updated
      groupPatientsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PatientsByGroupQueryVariables,
  APITypes.PatientsByGroupQuery
>;
export const timepointsByPatient = /* GraphQL */ `query TimepointsByPatient(
  $patientId: ID!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTimepointFilterInput
  $limit: Int
  $nextToken: String
) {
  timepointsByPatient(
    patientId: $patientId
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      patientId
      patient {
        id
        groupId
        patientStudyPhaseId
        patientStudyId
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        groupPatientsId
        __typename
      }
      samples {
        nextToken
        __typename
      }
      timepointName
      s3Path
      readGroups
      writeGroups
      adminGroups
      created
      updated
      patientTimepointsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TimepointsByPatientQueryVariables,
  APITypes.TimepointsByPatientQuery
>;
export const samplesByTimepoint = /* GraphQL */ `query SamplesByTimepoint(
  $timepointId: ID!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSampleFilterInput
  $limit: Int
  $nextToken: String
) {
  samplesByTimepoint(
    timepointId: $timepointId
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      timepointId
      timepoint {
        id
        patientId
        timepointName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        patientTimepointsId
        __typename
      }
      studyPhaseId
      StudyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      BatchId
      Batch {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      metadataImmunopeptidomicsList {
        nextToken
        __typename
      }
      MetadataNanoStringList {
        nextToken
        __typename
      }
      MetadataRnaSeqList {
        nextToken
        __typename
      }
      AuxiliaryAnalysislList {
        nextToken
        __typename
      }
      domainSampleId
      experimentSpecificVariables
      collectionDate
      processingDate
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseSamplesId
      timepointSamplesId
      batchSamplesId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SamplesByTimepointQueryVariables,
  APITypes.SamplesByTimepointQuery
>;
export const samplesByStudyPhase = /* GraphQL */ `query SamplesByStudyPhase(
  $studyPhaseId: ID!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSampleFilterInput
  $limit: Int
  $nextToken: String
) {
  samplesByStudyPhase(
    studyPhaseId: $studyPhaseId
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      timepointId
      timepoint {
        id
        patientId
        timepointName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        patientTimepointsId
        __typename
      }
      studyPhaseId
      StudyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      BatchId
      Batch {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      metadataImmunopeptidomicsList {
        nextToken
        __typename
      }
      MetadataNanoStringList {
        nextToken
        __typename
      }
      MetadataRnaSeqList {
        nextToken
        __typename
      }
      AuxiliaryAnalysislList {
        nextToken
        __typename
      }
      domainSampleId
      experimentSpecificVariables
      collectionDate
      processingDate
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseSamplesId
      timepointSamplesId
      batchSamplesId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SamplesByStudyPhaseQueryVariables,
  APITypes.SamplesByStudyPhaseQuery
>;
export const samplesByBatch = /* GraphQL */ `query SamplesByBatch(
  $BatchId: ID!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSampleFilterInput
  $limit: Int
  $nextToken: String
) {
  samplesByBatch(
    BatchId: $BatchId
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      timepointId
      timepoint {
        id
        patientId
        timepointName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        patientTimepointsId
        __typename
      }
      studyPhaseId
      StudyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      BatchId
      Batch {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      metadataImmunopeptidomicsList {
        nextToken
        __typename
      }
      MetadataNanoStringList {
        nextToken
        __typename
      }
      MetadataRnaSeqList {
        nextToken
        __typename
      }
      AuxiliaryAnalysislList {
        nextToken
        __typename
      }
      domainSampleId
      experimentSpecificVariables
      collectionDate
      processingDate
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseSamplesId
      timepointSamplesId
      batchSamplesId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SamplesByBatchQueryVariables,
  APITypes.SamplesByBatchQuery
>;
export const sampleByDomainSampleId = /* GraphQL */ `query SampleByDomainSampleId(
  $domainSampleId: String!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSampleFilterInput
  $limit: Int
  $nextToken: String
) {
  sampleByDomainSampleId(
    domainSampleId: $domainSampleId
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      timepointId
      timepoint {
        id
        patientId
        timepointName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        patientTimepointsId
        __typename
      }
      studyPhaseId
      StudyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      BatchId
      Batch {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      metadataImmunopeptidomicsList {
        nextToken
        __typename
      }
      MetadataNanoStringList {
        nextToken
        __typename
      }
      MetadataRnaSeqList {
        nextToken
        __typename
      }
      AuxiliaryAnalysislList {
        nextToken
        __typename
      }
      domainSampleId
      experimentSpecificVariables
      collectionDate
      processingDate
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseSamplesId
      timepointSamplesId
      batchSamplesId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SampleByDomainSampleIdQueryVariables,
  APITypes.SampleByDomainSampleIdQuery
>;
export const batchesByStudy = /* GraphQL */ `query BatchesByStudy(
  $studyId: ID!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBatchFilterInput
  $limit: Int
  $nextToken: String
) {
  batchesByStudy(
    studyId: $studyId
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      batchName
      batchAnnotations
      batchGroups
      annotations
      controlSamples
      filters
      description
      samples {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyBatchesId
      studyPhaseBatchesId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BatchesByStudyQueryVariables,
  APITypes.BatchesByStudyQuery
>;
export const batchesByStudyPhase = /* GraphQL */ `query BatchesByStudyPhase(
  $studyPhaseId: ID!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBatchFilterInput
  $limit: Int
  $nextToken: String
) {
  batchesByStudyPhase(
    studyPhaseId: $studyPhaseId
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      batchName
      batchAnnotations
      batchGroups
      annotations
      controlSamples
      filters
      description
      samples {
        nextToken
        __typename
      }
      pipelines {
        nextToken
        __typename
      }
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyBatchesId
      studyPhaseBatchesId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BatchesByStudyPhaseQueryVariables,
  APITypes.BatchesByStudyPhaseQuery
>;
export const pipelinesByStudy = /* GraphQL */ `query PipelinesByStudy(
  $studyId: ID!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPipelineFilterInput
  $limit: Int
  $nextToken: String
) {
  pipelinesByStudy(
    studyId: $studyId
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      status
      description
      s3Event
      s3ParamsFile
      s3Results
      visFileInfoS3path
      s3NfLog
      s3NfTasksLogs
      analysisType
      subtypeOfAnalysis
      parameters
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      scheduledPipeline
      scheduledDateTime
      launchablePipelineId
      launchablePipeline {
        id
        pipelineName
        pipelineVersion
        parameters
        description
        inputFileInfo
        supportedAnalyses
        supportedSubtypeOfAnalyses
        gitRepo
        workflowId
        created
        updated
        __typename
      }
      batchId
      batch {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      runId
      userEmail
      pipelineStarted
      pipelineCompleted
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPipelinesId
      studyPhasePipelinesId
      batchPipelinesId
      launchablePipelinesPipelinesId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PipelinesByStudyQueryVariables,
  APITypes.PipelinesByStudyQuery
>;
export const pipelinesByStudyPhase = /* GraphQL */ `query PipelinesByStudyPhase(
  $studyPhaseId: ID!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPipelineFilterInput
  $limit: Int
  $nextToken: String
) {
  pipelinesByStudyPhase(
    studyPhaseId: $studyPhaseId
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      status
      description
      s3Event
      s3ParamsFile
      s3Results
      visFileInfoS3path
      s3NfLog
      s3NfTasksLogs
      analysisType
      subtypeOfAnalysis
      parameters
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      scheduledPipeline
      scheduledDateTime
      launchablePipelineId
      launchablePipeline {
        id
        pipelineName
        pipelineVersion
        parameters
        description
        inputFileInfo
        supportedAnalyses
        supportedSubtypeOfAnalyses
        gitRepo
        workflowId
        created
        updated
        __typename
      }
      batchId
      batch {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      runId
      userEmail
      pipelineStarted
      pipelineCompleted
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPipelinesId
      studyPhasePipelinesId
      batchPipelinesId
      launchablePipelinesPipelinesId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PipelinesByStudyPhaseQueryVariables,
  APITypes.PipelinesByStudyPhaseQuery
>;
export const pipelinesByBLaunchablePipelines = /* GraphQL */ `query PipelinesByBLaunchablePipelines(
  $launchablePipelineId: ID!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPipelineFilterInput
  $limit: Int
  $nextToken: String
) {
  pipelinesByBLaunchablePipelines(
    launchablePipelineId: $launchablePipelineId
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      status
      description
      s3Event
      s3ParamsFile
      s3Results
      visFileInfoS3path
      s3NfLog
      s3NfTasksLogs
      analysisType
      subtypeOfAnalysis
      parameters
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      scheduledPipeline
      scheduledDateTime
      launchablePipelineId
      launchablePipeline {
        id
        pipelineName
        pipelineVersion
        parameters
        description
        inputFileInfo
        supportedAnalyses
        supportedSubtypeOfAnalyses
        gitRepo
        workflowId
        created
        updated
        __typename
      }
      batchId
      batch {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      runId
      userEmail
      pipelineStarted
      pipelineCompleted
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPipelinesId
      studyPhasePipelinesId
      batchPipelinesId
      launchablePipelinesPipelinesId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PipelinesByBLaunchablePipelinesQueryVariables,
  APITypes.PipelinesByBLaunchablePipelinesQuery
>;
export const pipelinesByBatch = /* GraphQL */ `query PipelinesByBatch(
  $batchId: ID!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPipelineFilterInput
  $limit: Int
  $nextToken: String
) {
  pipelinesByBatch(
    batchId: $batchId
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      status
      description
      s3Event
      s3ParamsFile
      s3Results
      visFileInfoS3path
      s3NfLog
      s3NfTasksLogs
      analysisType
      subtypeOfAnalysis
      parameters
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      scheduledPipeline
      scheduledDateTime
      launchablePipelineId
      launchablePipeline {
        id
        pipelineName
        pipelineVersion
        parameters
        description
        inputFileInfo
        supportedAnalyses
        supportedSubtypeOfAnalyses
        gitRepo
        workflowId
        created
        updated
        __typename
      }
      batchId
      batch {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      runId
      userEmail
      pipelineStarted
      pipelineCompleted
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPipelinesId
      studyPhasePipelinesId
      batchPipelinesId
      launchablePipelinesPipelinesId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PipelinesByBatchQueryVariables,
  APITypes.PipelinesByBatchQuery
>;
export const pipelineByRunId = /* GraphQL */ `query PipelineByRunId(
  $runId: String!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPipelineFilterInput
  $limit: Int
  $nextToken: String
) {
  pipelineByRunId(
    runId: $runId
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      status
      description
      s3Event
      s3ParamsFile
      s3Results
      visFileInfoS3path
      s3NfLog
      s3NfTasksLogs
      analysisType
      subtypeOfAnalysis
      parameters
      studyId
      study {
        id
        studyName
        organizationId
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
        __typename
      }
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      scheduledPipeline
      scheduledDateTime
      launchablePipelineId
      launchablePipeline {
        id
        pipelineName
        pipelineVersion
        parameters
        description
        inputFileInfo
        supportedAnalyses
        supportedSubtypeOfAnalyses
        gitRepo
        workflowId
        created
        updated
        __typename
      }
      batchId
      batch {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        studyId
        studyPhaseId
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
        __typename
      }
      runId
      userEmail
      pipelineStarted
      pipelineCompleted
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPipelinesId
      studyPhasePipelinesId
      batchPipelinesId
      launchablePipelinesPipelinesId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PipelineByRunIdQueryVariables,
  APITypes.PipelineByRunIdQuery
>;
export const auxiliaryAnalysisBySample = /* GraphQL */ `query AuxiliaryAnalysisBySample(
  $sampleId: ID!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelAuxiliaryAnalysisFilterInput
  $limit: Int
  $nextToken: String
) {
  auxiliaryAnalysisBySample(
    sampleId: $sampleId
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sampleId
      sample {
        id
        timepointId
        studyPhaseId
        BatchId
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
        __typename
      }
      auxiliaryFileS3Path
      vendorId
      specimenType
      parameters
      collectionDate
      readGroups
      writeGroups
      adminGroups
      created
      updated
      sampleAuxiliaryAnalysislListId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AuxiliaryAnalysisBySampleQueryVariables,
  APITypes.AuxiliaryAnalysisBySampleQuery
>;
export const MetadataImmunopeptidomicsBySample = /* GraphQL */ `query MetadataImmunopeptidomicsBySample(
  $sampleId: ID!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMetadataImmunopeptidomicsFilterInput
  $limit: Int
  $nextToken: String
) {
  MetadataImmunopeptidomicsBySample(
    sampleId: $sampleId
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sampleId
      sample {
        id
        timepointId
        studyPhaseId
        BatchId
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
        __typename
      }
      treatment {
        nextToken
        __typename
      }
      metaDataTemplateS3Path
      species
      subtypeOfAnalysis
      sampleType
      qcStatus
      glacierBackup
      rawDataS3
      tumorVolume
      readGroups
      writeGroups
      adminGroups
      created
      updated
      isControl
      barcodesMappedToControl
      barcode
      s3Path
      sampleMetadataImmunopeptidomicsListId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MetadataImmunopeptidomicsBySampleQueryVariables,
  APITypes.MetadataImmunopeptidomicsBySampleQuery
>;
export const MetadataNanoStringBySample = /* GraphQL */ `query MetadataNanoStringBySample(
  $sampleId: ID!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMetadataNanoStringFilterInput
  $limit: Int
  $nextToken: String
) {
  MetadataNanoStringBySample(
    sampleId: $sampleId
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sampleId
      sample {
        id
        timepointId
        studyPhaseId
        BatchId
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
        __typename
      }
      treatment {
        nextToken
        __typename
      }
      metaDataTemplateS3Path
      subtypeOfAnalysis
      species
      sampleType
      qcStatus
      glacierBackup
      rawDataS3
      tumorVolume
      dv200Percentage
      concNanoGramPerMicroL
      rnaMassNanoGram
      rnaVolumeMicroL
      rin
      readGroups
      writeGroups
      adminGroups
      created
      updated
      isControl
      barcodesMappedToControl
      barcode
      s3Path
      sampleMetadataNanoStringListId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MetadataNanoStringBySampleQueryVariables,
  APITypes.MetadataNanoStringBySampleQuery
>;
export const MetadataRnaSeqBySample = /* GraphQL */ `query MetadataRnaSeqBySample(
  $sampleId: ID!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMetadataRnaSeqFilterInput
  $limit: Int
  $nextToken: String
) {
  MetadataRnaSeqBySample(
    sampleId: $sampleId
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sampleId
      sample {
        id
        timepointId
        studyPhaseId
        BatchId
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
        __typename
      }
      treatment {
        nextToken
        __typename
      }
      metaDataTemplateS3Path
      subtypeOfAnalysis
      species
      sampleType
      qcStatus
      glacierBackup
      rawDataS3
      tumorVolume
      sequenceType
      strandSpecific
      UMI
      adaptorsRemoved
      adaptorsS3Path
      indexSequences
      concNanoGramPerMicroL
      rnaMassNanoGram
      rnaVolumeMicroL
      rin
      readGroups
      writeGroups
      adminGroups
      created
      updated
      isControl
      barcodesMappedToControl
      barcode
      s3Path
      sampleMetadataRnaSeqListId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MetadataRnaSeqBySampleQueryVariables,
  APITypes.MetadataRnaSeqBySampleQuery
>;
export const treatmentsByMetadataImmunopeptidomics = /* GraphQL */ `query TreatmentsByMetadataImmunopeptidomics(
  $metadataImmunopeptidomicsId: ID!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTreatmentFilterInput
  $limit: Int
  $nextToken: String
) {
  treatmentsByMetadataImmunopeptidomics(
    metadataImmunopeptidomicsId: $metadataImmunopeptidomicsId
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      metadataImmunopeptidomicsId
      metadataImmunopeptidomics {
        id
        sampleId
        metaDataTemplateS3Path
        species
        subtypeOfAnalysis
        sampleType
        qcStatus
        glacierBackup
        rawDataS3
        tumorVolume
        readGroups
        writeGroups
        adminGroups
        created
        updated
        isControl
        barcodesMappedToControl
        barcode
        s3Path
        sampleMetadataImmunopeptidomicsListId
        __typename
      }
      metadataNanoStringId
      metadataNanoString {
        id
        sampleId
        metaDataTemplateS3Path
        subtypeOfAnalysis
        species
        sampleType
        qcStatus
        glacierBackup
        rawDataS3
        tumorVolume
        dv200Percentage
        concNanoGramPerMicroL
        rnaMassNanoGram
        rnaVolumeMicroL
        rin
        readGroups
        writeGroups
        adminGroups
        created
        updated
        isControl
        barcodesMappedToControl
        barcode
        s3Path
        sampleMetadataNanoStringListId
        __typename
      }
      metadataRnaSeqId
      metadataRnaSeq {
        id
        sampleId
        metaDataTemplateS3Path
        subtypeOfAnalysis
        species
        sampleType
        qcStatus
        glacierBackup
        rawDataS3
        tumorVolume
        sequenceType
        strandSpecific
        UMI
        adaptorsRemoved
        adaptorsS3Path
        indexSequences
        concNanoGramPerMicroL
        rnaMassNanoGram
        rnaVolumeMicroL
        rin
        readGroups
        writeGroups
        adminGroups
        created
        updated
        isControl
        barcodesMappedToControl
        barcode
        s3Path
        sampleMetadataRnaSeqListId
        __typename
      }
      treatmentName
      dose
      doseUnit
      readGroups
      writeGroups
      adminGroups
      created
      updated
      metadataImmunopeptidomicsTreatmentId
      metadataNanoStringTreatmentId
      metadataRnaSeqTreatmentId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TreatmentsByMetadataImmunopeptidomicsQueryVariables,
  APITypes.TreatmentsByMetadataImmunopeptidomicsQuery
>;
export const treatmentsByMetadataNanoString = /* GraphQL */ `query TreatmentsByMetadataNanoString(
  $metadataNanoStringId: ID!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTreatmentFilterInput
  $limit: Int
  $nextToken: String
) {
  treatmentsByMetadataNanoString(
    metadataNanoStringId: $metadataNanoStringId
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      metadataImmunopeptidomicsId
      metadataImmunopeptidomics {
        id
        sampleId
        metaDataTemplateS3Path
        species
        subtypeOfAnalysis
        sampleType
        qcStatus
        glacierBackup
        rawDataS3
        tumorVolume
        readGroups
        writeGroups
        adminGroups
        created
        updated
        isControl
        barcodesMappedToControl
        barcode
        s3Path
        sampleMetadataImmunopeptidomicsListId
        __typename
      }
      metadataNanoStringId
      metadataNanoString {
        id
        sampleId
        metaDataTemplateS3Path
        subtypeOfAnalysis
        species
        sampleType
        qcStatus
        glacierBackup
        rawDataS3
        tumorVolume
        dv200Percentage
        concNanoGramPerMicroL
        rnaMassNanoGram
        rnaVolumeMicroL
        rin
        readGroups
        writeGroups
        adminGroups
        created
        updated
        isControl
        barcodesMappedToControl
        barcode
        s3Path
        sampleMetadataNanoStringListId
        __typename
      }
      metadataRnaSeqId
      metadataRnaSeq {
        id
        sampleId
        metaDataTemplateS3Path
        subtypeOfAnalysis
        species
        sampleType
        qcStatus
        glacierBackup
        rawDataS3
        tumorVolume
        sequenceType
        strandSpecific
        UMI
        adaptorsRemoved
        adaptorsS3Path
        indexSequences
        concNanoGramPerMicroL
        rnaMassNanoGram
        rnaVolumeMicroL
        rin
        readGroups
        writeGroups
        adminGroups
        created
        updated
        isControl
        barcodesMappedToControl
        barcode
        s3Path
        sampleMetadataRnaSeqListId
        __typename
      }
      treatmentName
      dose
      doseUnit
      readGroups
      writeGroups
      adminGroups
      created
      updated
      metadataImmunopeptidomicsTreatmentId
      metadataNanoStringTreatmentId
      metadataRnaSeqTreatmentId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TreatmentsByMetadataNanoStringQueryVariables,
  APITypes.TreatmentsByMetadataNanoStringQuery
>;
export const treatmentsByMetadataRnaSeq = /* GraphQL */ `query TreatmentsByMetadataRnaSeq(
  $metadataRnaSeqId: ID!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTreatmentFilterInput
  $limit: Int
  $nextToken: String
) {
  treatmentsByMetadataRnaSeq(
    metadataRnaSeqId: $metadataRnaSeqId
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      metadataImmunopeptidomicsId
      metadataImmunopeptidomics {
        id
        sampleId
        metaDataTemplateS3Path
        species
        subtypeOfAnalysis
        sampleType
        qcStatus
        glacierBackup
        rawDataS3
        tumorVolume
        readGroups
        writeGroups
        adminGroups
        created
        updated
        isControl
        barcodesMappedToControl
        barcode
        s3Path
        sampleMetadataImmunopeptidomicsListId
        __typename
      }
      metadataNanoStringId
      metadataNanoString {
        id
        sampleId
        metaDataTemplateS3Path
        subtypeOfAnalysis
        species
        sampleType
        qcStatus
        glacierBackup
        rawDataS3
        tumorVolume
        dv200Percentage
        concNanoGramPerMicroL
        rnaMassNanoGram
        rnaVolumeMicroL
        rin
        readGroups
        writeGroups
        adminGroups
        created
        updated
        isControl
        barcodesMappedToControl
        barcode
        s3Path
        sampleMetadataNanoStringListId
        __typename
      }
      metadataRnaSeqId
      metadataRnaSeq {
        id
        sampleId
        metaDataTemplateS3Path
        subtypeOfAnalysis
        species
        sampleType
        qcStatus
        glacierBackup
        rawDataS3
        tumorVolume
        sequenceType
        strandSpecific
        UMI
        adaptorsRemoved
        adaptorsS3Path
        indexSequences
        concNanoGramPerMicroL
        rnaMassNanoGram
        rnaVolumeMicroL
        rin
        readGroups
        writeGroups
        adminGroups
        created
        updated
        isControl
        barcodesMappedToControl
        barcode
        s3Path
        sampleMetadataRnaSeqListId
        __typename
      }
      treatmentName
      dose
      doseUnit
      readGroups
      writeGroups
      adminGroups
      created
      updated
      metadataImmunopeptidomicsTreatmentId
      metadataNanoStringTreatmentId
      metadataRnaSeqTreatmentId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TreatmentsByMetadataRnaSeqQueryVariables,
  APITypes.TreatmentsByMetadataRnaSeqQuery
>;
export const launchablePipelinesByPipelineNameAndVersion = /* GraphQL */ `query LaunchablePipelinesByPipelineNameAndVersion(
  $pipelineName: String!
  $pipelineVersion: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelLaunchablePipelinesFilterInput
  $limit: Int
  $nextToken: String
) {
  launchablePipelinesByPipelineNameAndVersion(
    pipelineName: $pipelineName
    pipelineVersion: $pipelineVersion
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      pipelineName
      organizations {
        nextToken
        __typename
      }
      pipelineVersion
      parameters
      description
      inputFileInfo
      supportedAnalyses
      supportedSubtypeOfAnalyses
      pipelines {
        nextToken
        __typename
      }
      gitRepo
      workflowId
      created
      updated
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LaunchablePipelinesByPipelineNameAndVersionQueryVariables,
  APITypes.LaunchablePipelinesByPipelineNameAndVersionQuery
>;
export const usersByOrganization = /* GraphQL */ `query UsersByOrganization(
  $organization: String!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  usersByOrganization(
    organization: $organization
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      email
      organization
      gitToken
      gitName
      gitURL
      readGroups
      created
      updated
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UsersByOrganizationQueryVariables,
  APITypes.UsersByOrganizationQuery
>;
export const dataLabEC2InstancesByType = /* GraphQL */ `query DataLabEC2InstancesByType(
  $type: DataLabEC2InstancesType!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelDataLabEC2InstancesFilterInput
  $limit: Int
  $nextToken: String
) {
  dataLabEC2InstancesByType(
    type: $type
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      creationTimestamp
      stoppedTimestamp
      startTimestamp
      terminatedTimestamp
      stoppedTimePeriod
      runningTimePeriod
      userEmail
      status
      type
      dataLabInstanceSessions {
        nextToken
        __typename
      }
      dataLabInstanceStatusChanges {
        nextToken
        __typename
      }
      organizationId
      organizationName
      instanceUrl
      instanceId
      infrastructureRelatedEntities
      instanceLaunchEvent
      adminGroups
      created
      updated
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DataLabEC2InstancesByTypeQueryVariables,
  APITypes.DataLabEC2InstancesByTypeQuery
>;
export const dataLabInstanceSessionsByDataLabInstance = /* GraphQL */ `query DataLabInstanceSessionsByDataLabInstance(
  $dataLabInstanceId: ID!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelDataLabEC2InstanceSessionFilterInput
  $limit: Int
  $nextToken: String
) {
  dataLabInstanceSessionsByDataLabInstance(
    dataLabInstanceId: $dataLabInstanceId
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      dataLabInstanceId
      dataLabInstance {
        id
        creationTimestamp
        stoppedTimestamp
        startTimestamp
        terminatedTimestamp
        stoppedTimePeriod
        runningTimePeriod
        userEmail
        status
        type
        organizationId
        organizationName
        instanceUrl
        instanceId
        infrastructureRelatedEntities
        instanceLaunchEvent
        adminGroups
        created
        updated
        owner
        __typename
      }
      s3Path
      instanceLaunchEvent
      status
      title
      description
      created
      updated
      dataLabEC2InstancesDataLabInstanceSessionsId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DataLabInstanceSessionsByDataLabInstanceQueryVariables,
  APITypes.DataLabInstanceSessionsByDataLabInstanceQuery
>;
export const dataLabInstanceStatusChangesByDataLabInstance = /* GraphQL */ `query DataLabInstanceStatusChangesByDataLabInstance(
  $dataLabInstanceId: ID!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelDataLabEC2InstanceStatusChangeFilterInput
  $limit: Int
  $nextToken: String
) {
  dataLabInstanceStatusChangesByDataLabInstance(
    dataLabInstanceId: $dataLabInstanceId
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      dataLabInstanceId
      dataLabInstance {
        id
        creationTimestamp
        stoppedTimestamp
        startTimestamp
        terminatedTimestamp
        stoppedTimePeriod
        runningTimePeriod
        userEmail
        status
        type
        organizationId
        organizationName
        instanceUrl
        instanceId
        infrastructureRelatedEntities
        instanceLaunchEvent
        adminGroups
        created
        updated
        owner
        __typename
      }
      organizationId
      previousStatus
      newStatus
      initiatedBy
      action
      performedBy
      additionalNotes
      created
      updated
      dataLabEC2InstancesDataLabInstanceStatusChangesId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DataLabInstanceStatusChangesByDataLabInstanceQueryVariables,
  APITypes.DataLabInstanceStatusChangesByDataLabInstanceQuery
>;
export const uploadBundlesByStudyPhase = /* GraphQL */ `query UploadBundlesByStudyPhase(
  $studyPhaseId: ID!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUploadBundleFilterInput
  $limit: Int
  $nextToken: String
) {
  uploadBundlesByStudyPhase(
    studyPhaseId: $studyPhaseId
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      studyPhaseId
      studyPhase {
        id
        studyId
        metaDataValidationSchemaTemplateId
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
        __typename
      }
      bundleLog
      validationErrors
      status
      userEmail
      uploadBundleAnalysisSession {
        id
        uploadBundleId
        metadataFileS3Path
        step
        listOfRawFileNames
        analysisType
        subtypeOfAnalysis
        studyPhaseId
        metadataHelperLambdaResponseS3Path
        barcodeToFilesToUploadS3Path
        adminGroups
        readGroups
        created
        updated
        owner
        __typename
      }
      uploadSamples {
        nextToken
        __typename
      }
      uploadFiles {
        nextToken
        __typename
      }
      adminGroups
      readGroups
      completionTime
      created
      updated
      studyPhaseUploadBundlesId
      uploadBundleUploadBundleAnalysisSessionId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UploadBundlesByStudyPhaseQueryVariables,
  APITypes.UploadBundlesByStudyPhaseQuery
>;
export const uploadSamplesByUploadBundle = /* GraphQL */ `query UploadSamplesByUploadBundle(
  $uploadBundleId: ID!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUploadSampleFilterInput
  $limit: Int
  $nextToken: String
) {
  uploadSamplesByUploadBundle(
    uploadBundleId: $uploadBundleId
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      uploadBundleId
      uploadBundle {
        id
        studyPhaseId
        bundleLog
        validationErrors
        status
        userEmail
        adminGroups
        readGroups
        completionTime
        created
        updated
        studyPhaseUploadBundlesId
        uploadBundleUploadBundleAnalysisSessionId
        owner
        __typename
      }
      sampleId
      barcode
      sampleLog
      writeMetadata
      status
      uploadFiles {
        nextToken
        __typename
      }
      adminGroups
      readGroups
      created
      updated
      uploadBundleUploadSamplesId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UploadSamplesByUploadBundleQueryVariables,
  APITypes.UploadSamplesByUploadBundleQuery
>;
export const uploadSampleAnalysisSessionByUploadBundleAnalysisSession = /* GraphQL */ `query UploadSampleAnalysisSessionByUploadBundleAnalysisSession(
  $uploadBundleAnalysisSessionId: ID!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUploadSampleAnalysisSessionFilterInput
  $limit: Int
  $nextToken: String
) {
  uploadSampleAnalysisSessionByUploadBundleAnalysisSession(
    uploadBundleAnalysisSessionId: $uploadBundleAnalysisSessionId
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      uploadBundleAnalysisSessionId
      uploadBundleAnalysisSession {
        id
        uploadBundleId
        metadataFileS3Path
        step
        listOfRawFileNames
        analysisType
        subtypeOfAnalysis
        studyPhaseId
        metadataHelperLambdaResponseS3Path
        barcodeToFilesToUploadS3Path
        adminGroups
        readGroups
        created
        updated
        owner
        __typename
      }
      sampleId
      rowFromMetaDataFileMappedToSample
      adminGroups
      readGroups
      created
      updated
      uploadBundleAnalysisSessionUploadSampleAnalysisSessionId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UploadSampleAnalysisSessionByUploadBundleAnalysisSessionQueryVariables,
  APITypes.UploadSampleAnalysisSessionByUploadBundleAnalysisSessionQuery
>;
export const uploadFilesByUploadSample = /* GraphQL */ `query UploadFilesByUploadSample(
  $uploadSampleId: ID!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUploadFileFilterInput
  $limit: Int
  $nextToken: String
) {
  uploadFilesByUploadSample(
    uploadSampleId: $uploadSampleId
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      uploadSampleId
      uploadSample {
        id
        uploadBundleId
        sampleId
        barcode
        sampleLog
        writeMetadata
        status
        adminGroups
        readGroups
        created
        updated
        uploadBundleUploadSamplesId
        owner
        __typename
      }
      uploadBundleId
      uploadBundle {
        id
        studyPhaseId
        bundleLog
        validationErrors
        status
        userEmail
        adminGroups
        readGroups
        completionTime
        created
        updated
        studyPhaseUploadBundlesId
        uploadBundleUploadBundleAnalysisSessionId
        owner
        __typename
      }
      multipartUploadId
      status
      toSkipUpload
      fileName
      fileType
      s3Path
      size
      fileLog
      adminGroups
      readGroups
      created
      updated
      uploadBundleUploadFilesId
      uploadSampleUploadFilesId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UploadFilesByUploadSampleQueryVariables,
  APITypes.UploadFilesByUploadSampleQuery
>;
export const uploadFilesByUploadBundle = /* GraphQL */ `query UploadFilesByUploadBundle(
  $uploadBundleId: ID!
  $created: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUploadFileFilterInput
  $limit: Int
  $nextToken: String
) {
  uploadFilesByUploadBundle(
    uploadBundleId: $uploadBundleId
    created: $created
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      uploadSampleId
      uploadSample {
        id
        uploadBundleId
        sampleId
        barcode
        sampleLog
        writeMetadata
        status
        adminGroups
        readGroups
        created
        updated
        uploadBundleUploadSamplesId
        owner
        __typename
      }
      uploadBundleId
      uploadBundle {
        id
        studyPhaseId
        bundleLog
        validationErrors
        status
        userEmail
        adminGroups
        readGroups
        completionTime
        created
        updated
        studyPhaseUploadBundlesId
        uploadBundleUploadBundleAnalysisSessionId
        owner
        __typename
      }
      multipartUploadId
      status
      toSkipUpload
      fileName
      fileType
      s3Path
      size
      fileLog
      adminGroups
      readGroups
      created
      updated
      uploadBundleUploadFilesId
      uploadSampleUploadFilesId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UploadFilesByUploadBundleQueryVariables,
  APITypes.UploadFilesByUploadBundleQuery
>;
export const organizationLaunchablePipelinesByOrganizationId = /* GraphQL */ `query OrganizationLaunchablePipelinesByOrganizationId(
  $organizationId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelOrganizationLaunchablePipelinesFilterInput
  $limit: Int
  $nextToken: String
) {
  organizationLaunchablePipelinesByOrganizationId(
    organizationId: $organizationId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      organizationId
      launchablePipelinesId
      organization {
        id
        organizationName
        organizationCode
        active
        notificationEmail
        description
        organizationAnswers
        url
        gitUrl
        s3Path
        readGroups
        writeGroups
        created
        updated
        __typename
      }
      launchablePipelines {
        id
        pipelineName
        pipelineVersion
        parameters
        description
        inputFileInfo
        supportedAnalyses
        supportedSubtypeOfAnalyses
        gitRepo
        workflowId
        created
        updated
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.OrganizationLaunchablePipelinesByOrganizationIdQueryVariables,
  APITypes.OrganizationLaunchablePipelinesByOrganizationIdQuery
>;
export const organizationLaunchablePipelinesByLaunchablePipelinesId = /* GraphQL */ `query OrganizationLaunchablePipelinesByLaunchablePipelinesId(
  $launchablePipelinesId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelOrganizationLaunchablePipelinesFilterInput
  $limit: Int
  $nextToken: String
) {
  organizationLaunchablePipelinesByLaunchablePipelinesId(
    launchablePipelinesId: $launchablePipelinesId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      organizationId
      launchablePipelinesId
      organization {
        id
        organizationName
        organizationCode
        active
        notificationEmail
        description
        organizationAnswers
        url
        gitUrl
        s3Path
        readGroups
        writeGroups
        created
        updated
        __typename
      }
      launchablePipelines {
        id
        pipelineName
        pipelineVersion
        parameters
        description
        inputFileInfo
        supportedAnalyses
        supportedSubtypeOfAnalyses
        gitRepo
        workflowId
        created
        updated
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.OrganizationLaunchablePipelinesByLaunchablePipelinesIdQueryVariables,
  APITypes.OrganizationLaunchablePipelinesByLaunchablePipelinesIdQuery
>;
