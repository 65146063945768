
import { defineComponent } from 'vue';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { MenuItem } from '@/models/customModels';
import AWS from 'aws-sdk';
import {
  IAMClient, ListRolesCommand, DeleteRoleCommand, ListPoliciesCommand, DeletePolicyCommand, ListPolicyVersionsCommand, DeletePolicyVersionCommand, DetachRolePolicyCommand,
} from '@aws-sdk/client-iam';
import { listItems, updateDynamoDBDirectly } from '@/utils';
import { Organization, Study, StudyPhase } from '@/models';
import * as customQueries from '@/graphql/customQueries';
import * as queries from '@/graphql/queries';
import * as mutations from '@/graphql/mutations';

// Comment to force build 2
export default defineComponent({
  name: 'AdminView',
  data() {
    return {
      itemOptions: [{
        label: 'Delete groups',
        command: async () => {
          console.log('Delete groups');
          let userPoolId = '';
          console.log('process.env.STAGE :>> ', process.env.STAGE);
          if (process.env.VUE_APP_MASTER_BUCKET?.includes('dev')) userPoolId = 'eu-west-2_BBthzaLHV';
          if (process.env.VUE_APP_MASTER_BUCKET?.includes('uat')) userPoolId = 'eu-west-2_DrxRDlQG7';
          const apiName = 'AdminQueries';
          const path = '/listGroups';
          const myInit = {
            queryStringParameters: {
              limit: 60,
            },
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
            },
          };
          const { ...groups } = await API.get(apiName, path, myInit);
          console.log('groups.Groups :>> ', groups.Groups);
          const filteredGroups = groups.Groups.filter((grp: { GroupName: string }) => grp.GroupName.startsWith('ORG') || grp.GroupName.startsWith('S') || grp.GroupName.startsWith('SP') || grp.GroupName.startsWith('CRO'));
          console.log('filteredGroups :>> ', filteredGroups);
          for (const group of filteredGroups) {
            const authCredentials = await Auth.currentCredentials();
            const credentials = new AWS.Credentials({
              accessKeyId: authCredentials.accessKeyId, secretAccessKey: authCredentials.secretAccessKey, sessionToken: authCredentials.sessionToken,
            });
            const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({ region: 'eu-west-2', credentials });
            const params = {
              GroupName: group.GroupName,
              UserPoolId: userPoolId,
            };
            cognitoidentityserviceprovider.deleteGroup(params, (err, data) => {
              if (err) console.log(err, err.stack);
              else console.log(data);
            });
          }
        },
      }, {
        label: 'Delete Roles',
        // eslint-disable-next-line consistent-return
        command: async () => {
          const authCredentials = await Auth.currentCredentials();
          const credentials = new AWS.Credentials({
            accessKeyId: authCredentials.accessKeyId, secretAccessKey: authCredentials.secretAccessKey, sessionToken: authCredentials.sessionToken,
          });
          const iamClient = new IAMClient({ region: 'eu-west-2', credentials });
          const params = {
            MaxItems: 200,
          };
          const results = await iamClient.send(new ListRolesCommand(params));
          console.log('Success', results);
          if (results && results.Roles) {
            const filteredRoles = results!.Roles.filter((role: any) => role.RoleName.startsWith('Role_'));
            console.log('filteredRoles :>> ', filteredRoles);
            for (let i = 0; i < filteredRoles.length; i += 1) {
              const roleObj = filteredRoles[i];
              this.deleteRole(iamClient, roleObj.RoleName);
            }
          }
        },
      }, {
        label: 'Delete Policies',
        command: async () => {
          const authCredentials = await Auth.currentCredentials();
          const credentials = new AWS.Credentials({
            accessKeyId: authCredentials.accessKeyId, secretAccessKey: authCredentials.secretAccessKey, sessionToken: authCredentials.sessionToken,
          });
          const iamClient = new IAMClient({ region: 'eu-west-2', credentials });
          const params = {
            MaxItems: 600,
          };
          const results = await iamClient.send(new ListPoliciesCommand(params));
          const filtered = results.Policies?.filter((policyObj: any) => policyObj.PolicyName.startsWith('Policy_'));
          console.log('results :>> ', results);
          console.log('filtered :>> ', filtered);
          if (filtered) {
            for (let i = 0; i < filtered.length; i += 1) {
              const policyObj = filtered[i];
              try {
                await this.detachPolicy(iamClient, policyObj.Arn, policyObj.PolicyName?.replace('Policy', 'Role'));
              } catch (error) {
                console.error(error);
              }
              try {
                await this.listPolicyVersions(iamClient, policyObj.Arn);
              } catch (error) {
                console.error(error);
              }
              try {
                await this.deletePolicy(iamClient, policyObj.Arn);
              } catch (error) {
                console.error(error);
              }
            }
          }
          console.log('Done deleting policies');
        },
      }, {
        label: 'Delete CRO and SP Policies',
        command: async () => {
          const authCredentials = await Auth.currentCredentials();
          const credentials = new AWS.Credentials({
            accessKeyId: authCredentials.accessKeyId, secretAccessKey: authCredentials.secretAccessKey, sessionToken: authCredentials.sessionToken,
          });
          const iamClient = new IAMClient({ region: 'eu-west-2', credentials });
          const params = {
            MaxItems: 600,
          };
          const results = await iamClient.send(new ListPoliciesCommand(params));
          const filtered = results.Policies?.filter((policyObj: any) => policyObj.PolicyName.startsWith('Policy_CRO') || policyObj.PolicyName.startsWith('Policy_SP'));
          console.log('results :>> ', results);
          console.log('filtered :>> ', filtered);
          if (filtered) {
            for (let i = 0; i < filtered.length; i += 1) {
              const policyObj = filtered[i];
              try {
                await this.detachPolicy(iamClient, policyObj.Arn, policyObj.PolicyName?.replace('Policy', 'Role'));
              } catch (error) {
                console.error(error);
              }
              try {
                await this.listPolicyVersions(iamClient, policyObj.Arn);
              } catch (error) {
                console.error(error);
              }
              try {
                await this.deletePolicy(iamClient, policyObj.Arn);
              } catch (error) {
                console.error(error);
              }
            }
          }
          console.log('Done deleting policies');
        },
      },
      {
        label: 'Update all studyPhases',
        command: async () => {
          const allStudyPhases: StudyPhase[] = await listItems(customQueries.listStudyPhasesForFixGroups, {});
          // eslint-disable-next-line no-return-await
          allStudyPhases.forEach(async (sp: StudyPhase) => await API.graphql(graphqlOperation(mutations.updateStudyPhase, { input: { id: sp.id, description: `${sp.description}\n` } })));
        },
      },
      {
        label: 'List all',
        command: async () => {
          this.listAll();
        },
      },
      {
        label: 'Reset all org groups to start',
        command: async () => {
          this.resetAllOrgGroupsToStart();
        },
      },
      {
        label: 'Reset Study Groups',
        command: async () => {
          this.resetStudyGroups();
        },
      },
      {
        label: 'Reset Study Phase Groups',
        command: async () => {
          this.resetStudyPhaseGroups();
        },
      },
      {
        label: 'Change study phase composite fields to lists',
        command: async () => {
          this.changeStudyPhaseDataFieldsToLists();
        },
      }] as MenuItem[],
      orgName: '' as String,
      profileMenuItems: [] as MenuItem[],
      logo: '',
    };
  },
  methods: {
    async updateAllStudyPhaseValidationSchemas(): Promise<void> {
      try {
        this.$toast.add({
          severity: 'warn', summary: 'Updating', detail: 'Started updating study phases...', life: 5000,
        });
        const allStudyPhases = await this.getAllStudyPhases();
        console.log('allStudyPhases :>> ', allStudyPhases);
        await this.updateStudyPhases(allStudyPhases);
        this.$toast.add({
          severity: 'success', summary: 'Success', detail: 'Study phase update finish.', life: 3000,
        });
      } catch (error) {
        console.error(error);
        this.$toast.add({
          severity: 'error', summary: 'Error', detail: 'Failed to update study phases!', life: 3000,
        });
      }
    },
    async getAllStudyPhases(): Promise<StudyPhase[]> {
      return listItems(customQueries.listStudyPhasesForUpdateValidationSchema, {});
    },
    async updateStudyPhases(studyPhases: StudyPhase[]): Promise<void> {
      studyPhases.forEach((studyPhase: StudyPhase) => {
        API.graphql(graphqlOperation(mutations.updateStudyPhase, {
          input: {
            id: studyPhase.id,
            validationSchema: studyPhase?.metaDataValidationSchemaTemplate?.validationSchemaTemplate,
          },
        }));
      });
    },
    async listPolicyVersions(iamClient: IAMClient, PolicyArn: string) {
      const input = { // ListPolicyVersionsRequest
        PolicyArn, // required
        MaxItems: 300,
      };
      const command = new ListPolicyVersionsCommand(input);
      const response = await iamClient.send(command);
      console.log('response policy version :>> ', response);
      if (response && response.Versions) {
        for (let i = 0; i < response.Versions.length; i += 1) {
          const policyVersion = response.Versions[i];
          this.deletePolicyVersion(iamClient, PolicyArn, policyVersion.VersionId!);
        }
      }
    },
    async detachPolicy(iamClient: IAMClient, PolicyArn: string, RoleName: string) {
      const input = { // ListPolicyVersionsRequest
        PolicyArn, // required
        RoleName,
      };
      const command = new DetachRolePolicyCommand(input);
      const response = await iamClient.send(command);
      console.log('response policy detach :>> ', response);
    },
    async deletePolicyVersion(iamClient: IAMClient, PolicyArn: string, VersionId: string) {
      const input = { // ListPolicyVersionsRequest
        PolicyArn, // required
        VersionId,
      };
      const command = new DeletePolicyVersionCommand(input);
      const response = await iamClient.send(command);
      console.log('response policy deletion :>> ', response);
    },
    async deletePolicy(iamClient: IAMClient, PolicyArn: string) {
      const input = {
        PolicyArn,
      };
      const command = new DeletePolicyCommand(input);
      const response = await iamClient.send(command);
      console.log('delete policy response :>> ', response);
    },
    async deleteRole(iamClient: IAMClient, RoleName: string) {
      const input = {
        RoleName,
      };
      const command = new DeleteRoleCommand(input);
      const response = await iamClient.send(command);
      console.log('delete role response :>> ', response);
    },
    async resetAllOrgGroupsToStart(): Promise<void> {
      try {
        const allOrganizations: Organization[] = await listItems(queries.listOrganizations, {});
        console.log('allOrganizations :>> ', allOrganizations);
        await Promise.all([allOrganizations.map((org) => API.graphql(graphqlOperation(mutations.updateOrganization, {
          input: {
            // eslint-disable-next-line no-underscore-dangle
            id: org.id, writeGroups: [`ORG/${org.id}/Admin`], readGroups: [],
          },
        })))]);
        // await Promise.all([allOrganizations.map((org) => this.updateDynamoDBDirectly(org.id, 'Organization-yputep3b2jfjpng6pg25ygf62e-dev', 'writeGroups', ['MojaBaba1', 'MojaBaba2']))]);
        console.log('Donee resetting org groups');
      } catch (error) {
        console.error(error);
      }
    },
    async resetStudyGroups(): Promise<void> {
      try {
        const allStudies: Study[] = await listItems(queries.listStudies, {});
        console.log('allStudies :>> ', allStudies);
        await Promise.all([allStudies.map((study) => API.graphql(graphqlOperation(mutations.updateStudy, {
          input: {
            id: study.id,
            adminGroups: [`ORG/${study.organization!.id}/Admin`],
            writeGroups: [`S/${study.id}/Admin`], // Change if we want to allow study admins to delete studies in the future
            readGroups: [],
            croGroups: [],
          },
        })))]);
        await Promise.all([allStudies.map((study) => updateDynamoDBDirectly(study.organization!.id, 'Organization', [{ field: 'readGroups', values: [`S/${study.id}/Admin`] }]))]);
      } catch (error) {
        console.error(error);
      }
    },
    async resetStudyPhaseGroups(): Promise<void> {
      try {
        const allStudyPhases: StudyPhase[] = await listItems(customQueries.listStudyPhasesForFixGroups, {});
        console.log('allStudyPhases :>> ', allStudyPhases);
        for (let i = 0; i < allStudyPhases.length; i += 1) {
          const studyPhase = allStudyPhases[i];
          await API.graphql(graphqlOperation(mutations.updateStudyPhase, {
            input: {
              id: studyPhase.id,
              // eslint-disable-next-line no-underscore-dangle
              adminGroups: [`ORG/${studyPhase.study!.organization!.id}/Admin`, `S/${studyPhase.study!.id}/Admin`],
              writeGroups: [`SP/${studyPhase.id}/Admin`],
              readGroups: [`SP/${studyPhase.id}/User`],
              croGroups: [`CRO/${studyPhase.id}`],
            },
          }));
        }
        console.log('Done updating study phases');
        let updateBatch: any[] = [];
        for (let i = 0; i < allStudyPhases.length; i += 1) {
          const studyPhase = allStudyPhases[i];
          await Promise.all([updateDynamoDBDirectly(studyPhase.study!.organization!.id, 'Organization', [{ field: 'readGroups', values: [`SP/${studyPhase.id}/Admin`, `SP/${studyPhase.id}/User`, `CRO/${studyPhase.id}`] }])]);
          await Promise.all([updateDynamoDBDirectly(studyPhase.study!.id, 'Study', [{ field: 'readGroups', values: [`SP/${studyPhase.id}/Admin`, `SP/${studyPhase.id}/User`] }, { field: 'croGroups', values: [`CRO/${studyPhase.id}`] }])]);
          if (i % 5 === 0) {
            await Promise.all(updateBatch);
            updateBatch = [];
            console.log('Done with batch');
          }
        }
        console.log('Done with study phases');
      } catch (error) {
        console.error(error);
      }
    },
    async listAll(): Promise<void> {
      console.log('process.env :>> ', process.env);
      console.log(`Organization-${process.env.VUE_APP_API_ID}-${process.env.VUE_APP_DATA_BUCKET?.split('-')[2]}`);
      const allOrganizations: Organization[] = await listItems(queries.listOrganizations, {});
      console.log('allOrganizations :>> ', allOrganizations);
      const allStudies: Study[] = await listItems(queries.listStudies, {});
      console.log('allStudies :>> ', allStudies);
      const allStudyPhases: StudyPhase[] = await listItems(customQueries.listStudyPhasesForFixGroups, {});
      console.log('allStudyPhases :>> ', allStudyPhases);
    },
    async changeStudyPhaseDataFieldsToLists() {
      const allStudyPhases: StudyPhase[] = await listItems(customQueries.listStudyPhasesForFixCompositeFields, {});
      console.log('allStudyPhases :>> ', allStudyPhases);
      const promises: any = [];
      for (let i = 0; i < allStudyPhases.length; i += 1) {
        const studyPhase = allStudyPhases[i];
        const updateObj: any = {
          id: studyPhase.id,
        };
        if (studyPhase.groupNames === null) updateObj.groupNames = [];
        if (studyPhase.labAssayTechnologies === null) updateObj.groupNames = [];
        if (studyPhase.sampleTypes === null) updateObj.sampleTypes = [];
        if (studyPhase.species === null) updateObj.species = [];
        if (studyPhase.specimens === null) updateObj.specimens = [];
      }
      await Promise.all(promises);
      console.log('Done');
    },
  },
});
