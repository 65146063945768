
import * as AWS from 'aws-sdk';
import { defineComponent } from 'vue';
import * as customQueries from '@/graphql/customQueries';
// eslint-disable-next-line import/no-extraneous-dependencies
import { graphqlOperation, GraphQLResult } from '@aws-amplify/api-graphql';
import {
  listItems, setCredentials, uniqueObjectsByKey, wait, 
} from '@/utils';
import {
  // eslint-disable-next-line no-unused-vars
  ComplexTemplateValidationItem, FEMetadataCROTemplate, MetaDataAllowedValuesCustomTemplate, MetadataCROTemplate, DownloadebleTemplates as DownloadableTemplates, AWSConfigCredentialsObj,
} from '@/models/customModels';
import { Auth, API } from 'aws-amplify';
import axios from 'axios';
import {
  Study, StudyPhase,
} from '@/models';
import Upload from '@/components/Upload/Upload.vue';

export default defineComponent({
  name: 'CRO',
  components: {
    Upload,
  },
  data() {
    return {
      loading: false as boolean,
      downloaded: false as boolean,
      selectedStudyPhase: null as unknown as StudyPhase,
      availableStudyPhases: [] as unknown as StudyPhase[],
      selectedMetadataTemplate: null as unknown as MetaDataAllowedValuesCustomTemplate | null,
      downloadableTemplates: [] as DownloadableTemplates[],
      selectedDownloadableTemplates: [] as DownloadableTemplates[],
      data: {} as {[key: string]: any},
      groupMap: {} as { [key: string] : Array<ComplexTemplateValidationItem> },
      mergeMap: {} as {[key: string]: any},
      templateArray: [] as Array<Array<FEMetadataCROTemplate[]>>,
      template: [] as Array<FEMetadataCROTemplate[]>,
      group: null as unknown as string,
      uploadToken: null as unknown as string,
      credentials: null as unknown as AWSConfigCredentialsObj,
      uploading: false,
      uploadFinishedBool: false,
      downloadTemplateCheck: false,
      selectedAnalysisType: null as unknown as { type: string, subtype: string, templateFileName: string },
      // ['Imunopeptidomics', 'RNAseq', 'scRNAseqSMART-Seq', 'scRNAseqCellRanger', 'TCRseq', 'Nanostring']
      availableAnalysisTypes: [{
        type: 'RNAseq',
        subtype: 'RNAseq',
        templateFileName: 'rnaseq-meta-data-template.csv',
      },
      {
        type: 'RNAseq',
        subtype: 'scRNAseqSMART-Seq',
        templateFileName: 'scrnaseq-meta-data-template.csv',
      },
      {
        type: 'RNAseq',
        subtype: 'scRNAseqCellRanger',
        templateFileName: 'scrnaseq-meta-data-template.csv',
      },
      {
        type: 'RNAseq',
        subtype: 'TCRseq',
        templateFileName: 'tcrseq-meta-data-template.csv',
      },
      {
        type: 'Immunopeptidomics',
        subtype: 'Immunopeptidomics',
        templateFileName: 'immunopeptidomics-meta-data-template.csv',
      },
      {
        type: 'Nanostring',
        subtype: 'Nanostring',
        templateFileName: 'nanostring-meta-data-template.csv',
      }],
    };
  },
  mounted() {
    this.main();
  },
  methods: {
    async main() {
      try {
        this.loading = true;
        let groups: string[] = (await Auth.currentAuthenticatedUser()).signInUserSession.accessToken.payload['cognito:groups'];
        groups = groups.filter((grp) => grp.startsWith('M2MAdmin') || grp.startsWith('ORG/') || grp.startsWith('S/') || grp.startsWith('SP/') || grp.startsWith('CRO/'));
        const groupsRemapped: StudyPhase[] = await this.getGroupNames(groups);
        this.availableStudyPhases = groupsRemapped;
        if (this.availableStudyPhases && this.availableStudyPhases.length === 1) {
          this.selectedStudyPhase = this.availableStudyPhases[0];
        }
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    async getGroupNames(groups: string[]): Promise<StudyPhase[] | []> {
      try {
        const studyPhaseGroups: string[] = [];
        const orgStudyPhaseGroupGroups: string[] = [];
        for (let i = 0; i < groups.length; i += 1) {
          const grp = groups[i];
          if (grp === 'M2MAdmin') return this.getAllStudyPhases();
          if (grp.startsWith('CRO/')) studyPhaseGroups.push(grp);
          if (grp.startsWith('ORG/')) orgStudyPhaseGroupGroups.push(grp);
        }
        const orgGroups = await this.getStudyPhaseGroupsFromOrgNames(orgStudyPhaseGroupGroups);
        const studyPhasesFromCRO = await this.getStudyPhaseGroupsNames(studyPhaseGroups);
        const studyPhases = uniqueObjectsByKey([...orgGroups, ...studyPhasesFromCRO], 'id');
        return studyPhases;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async getAllStudyPhases(): Promise<StudyPhase[] | []> {
      try {
        const allStudyPhases = await listItems(customQueries.listStudyPhasesForCroM2MAdmin, {});
        if (allStudyPhases) return allStudyPhases as unknown as StudyPhase[];
        return [];
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async getStudyPhaseGroupsFromOrgNames(groups: string[]): Promise<StudyPhase[] | []> {
      try {
        const studyPromises: any[] = [];
        for (let i = 0; i < groups.length; i += 1) {
          const group = groups[i];
          const organizationId = group.split('/')[1];
          studyPromises.push(listItems(customQueries.studiesByOrganizationForUpload, { organizationId }));
        }
        const studies: Study[] = ((await Promise.all(studyPromises)).flat()) as Study[];
        const studyPhasePromises: any[] = [];
        studies.forEach((study) => {
          studyPhasePromises.push(listItems(customQueries.studyPhasesByStudyForUpload, { studyId: study.id })) as unknown as StudyPhase[];
        });
        const groupNames = ((await Promise.all(studyPhasePromises)).flat()) as StudyPhase[];
        if (groupNames) return groupNames as unknown as StudyPhase[];
        return [];
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async getStudyPhaseGroupsNames(groups: string[]): Promise<StudyPhase[] | []> {
      try {
        const namePromises: any[] = [];
        for (let i = 0; i < groups.length; i += 1) {
          const group = groups[i];
          const studyPhaseId = group.split('/')[1];
          namePromises.push(API.graphql(graphqlOperation(customQueries.getStudyPhaseIdNameSchemaMetadataTemplate, { id: studyPhaseId })));
        }
        const groupNames = await Promise.all(namePromises);
        const groupNamesRemapped = groupNames.map((res) => (res as GraphQLResult<any>).data.getStudyPhase);
        if (groupNames) return groupNamesRemapped as unknown as StudyPhase[];
        return [];
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    downloadTemplate() {
      if (this.selectedDownloadableTemplates.length > 0) this.downloadTemplates(this.selectedDownloadableTemplates);
    },
    async downloadTemplates(templates: DownloadableTemplates[]): Promise<void> {
      try {
        console.log('templates :>> ', templates);
        const s3Paths = templates.map((template) => template.s3Path);
        console.log('s3Paths :>> ', s3Paths);
        const credentials: AWSConfigCredentialsObj | null = (this.credentials) ? this.credentials : await this.getCredentials();
        if (!credentials) throw new Error('Credentials are null');
        AWS.config.update(credentials);
        const s3 = new AWS.S3();
        if (!process.env.VUE_APP_MASTER_BUCKET) throw new Error('Bucket unavailable');
        const bucket: string = process.env.VUE_APP_MASTER_BUCKET;
        for (let i = 0; i < s3Paths.length; i += 1) {
          const path = s3Paths[i];
          const params = {
            Bucket: bucket,
            Key: path.split(bucket)[1].substring(1, path.split(bucket)[1].length),
          };
          const url = s3.getSignedUrl('getObject', params);
          axios({
            url, // File URL Goes Here
            method: 'GET',
            responseType: 'blob',
          }).then((res: any) => {
            const FILE = window.URL.createObjectURL(new Blob([res.data]));
            const docUrl = document.createElement('a');
            docUrl.href = FILE;
            docUrl.setAttribute('download', this.getFileName(path));
            document.body.appendChild(docUrl);
            docUrl.click();
          });
        }
        this.$toast.add({
          severity: 'success', summary: 'Success', detail: 'Template download initiated!', life: 5000,
        });
      } catch (error) {
        console.error(error);
        this.$toast.add({
          severity: 'error', summary: 'Error', detail: 'Failed to download templates!', life: 5000,
        });
      }
    },
    async getCredentials(): Promise<AWSConfigCredentialsObj | null> {
      try {
        const groups: string[] = (await Auth.currentAuthenticatedUser()).signInUserSession.accessToken.payload['cognito:groups'];
        console.log('groups :>> ', groups);
        if (this.$store.state.precedenceLevel === 1) {
          const credentials = await setCredentials('M2MAdmin');
          if (credentials) {
            this.group = 'M2MAdmin';
            this.credentials = credentials;
            return credentials;
          } throw new Error('Credentials null');
        }
        if (this.selectedStudyPhase && groups.some((grp) => grp === `CRO/${this.selectedStudyPhase.id}`)) {
          const credentials = await setCredentials(`CRO/${this.selectedStudyPhase.id}`);
          if (credentials) {
            this.group = `CRO/${this.selectedStudyPhase.id}`;
            this.credentials = credentials;
            return credentials;
          } throw new Error('Credentials null');
        } else if (!this.selectedStudyPhase && groups.every((grp) => grp.startsWith('CRO/'))) {
          const credentials = await setCredentials(groups[0]);
          if (credentials) {
            this.group = groups[0];
            this.credentials = credentials;
            return credentials;
          } throw new Error('Credentials null');
        }
        const orgId = this.$route.params.organizationId;
        if (orgId) {
          const credentials = await setCredentials(`ORG/${orgId}/Admin`);
          if (credentials) {
            this.group = `ORG/${orgId}/Admin`;
            this.credentials = credentials;
            return credentials;
          } throw new Error('Credentials null');
        }
        return null;
      } catch (error) {
        console.error(error);
        return null;
      }
    },

    getFileName(path: string): string {
      try {
        const split = path.split('/');
        if (split && split.at(-1) && split.at(-1) !== undefined) return split.at(-1) as string;
        return path;
      } catch (error) {
        console.error(error);
        return path;
      }
    },
    base64url(source: string) {
      // eslint-disable-next-line global-require, import/no-extraneous-dependencies
      const CryptoJS = require('crypto-js');
      let encodedSource = '';

      // Encode in classical base64
      encodedSource = CryptoJS.enc.Base64.stringify(source);

      // Remove padding equal characters
      encodedSource = encodedSource.replace(/=+$/, '');

      // Replace characters according to base64url specifications
      encodedSource = encodedSource.replace(/\+/g, '-');
      encodedSource = encodedSource.replace(/\//g, '_');

      return encodedSource;
    },
    startingUpload(uploading: boolean): void {
      this.uploading = uploading;
    },
    uploadFinished(uploadDone: boolean) {
      this.uploadFinishedBool = uploadDone;
    },
    async reset() {
      const tmpSP = this.selectedStudyPhase;
      this.selectedStudyPhase = null as unknown as StudyPhase;
      await wait(1);
      this.selectedStudyPhase = tmpSP;
    },
  },
  watch: {
    selectedStudyPhase: {
      async handler() {
        if (!this.selectedStudyPhase) {
          this.loading = false;
          console.log('No study phase selected in handler');
          return;
        }
        this.selectedMetadataTemplate = this.selectedStudyPhase.metaDataValidationSchemaTemplate as any;
        if (this.selectedMetadataTemplate!.templateFiles) {
          const templateFilesJson = JSON.parse(this.selectedMetadataTemplate!.templateFiles);
          this.downloadableTemplates = templateFilesJson.templates;
        }
        await this.getCredentials();
        // if (this.selectedStudyPhase.validationSchema) {
        //   console.log('Has schema');
        //   this.selectedMetadataTemplate = null;
        //   this.template = [];
        //   this.data = {};
        //   this.loading = false;
        //   return;
        // }
        // if (this.selectedMetadataTemplate.validationSchemaTemplate) {
        //   const fullTemplate: MetadataCROTemplate = JSON.parse(this.selectedMetadataTemplate.validationSchemaTemplate);
        //   // Give each element an id. Each schema will have and id in the format of name-i-j where i and j behave like a matrix. i is the row index and j is the element in each row
        //   if (fullTemplate.front_end) {
        //     for (let i = 0; i < fullTemplate.front_end.length; i += 1) {
        //       const templateGroup = fullTemplate.front_end[i];
        //       for (let j = 0; j < templateGroup.length; j += 1) {
        //         const schema = templateGroup[j];
        //         schema.id = `${schema.name}-${i}-${j}`;
        //         this.data[schema.id as keyof Object] = schema;
        //       }
        //     }
        //     this.template = fullTemplate.front_end;
        //   }
        // }
        this.loading = false;
      },
    },
  },
});
