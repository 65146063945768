export const createPipeline = /* GraphQL */ `
  mutation CreatePipeline(
    $input: CreatePipelineInput!
    $condition: ModelPipelineConditionInput
  ) {
    createPipeline(input: $input, condition: $condition) {
      id
      name
      status
      description
      s3Event
      s3ParamsFile
      s3Results
      visFileInfoS3path
      s3NfLog
      s3NfTasksLogs
      analysisType
      parameters
      studyId
      runId
      userEmail
      pipelineStarted
      pipelineCompleted
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPipelinesId
      studyPhasePipelinesId
      batchPipelinesId
      launchablePipelinesPipelinesId
    }
  }
`;

export const updatePipeline = /* GraphQL */ `
  mutation UpdatePipeline(
    $input: UpdatePipelineInput!
    $condition: ModelPipelineConditionInput
  ) {
    updatePipeline(input: $input, condition: $condition) {
      id
      name
      status
      description
      s3Event
      s3ParamsFile
      s3Results
      visFileInfoS3path
      s3NfLog
      s3NfTasksLogs
      analysisType
      parameters
      studyId
      studyPhaseId
      scheduledPipeline
      scheduledDateTime
      launchablePipelineId
      batchId
      runId
      userEmail
      pipelineStarted
      pipelineCompleted
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPipelinesId
      studyPhasePipelinesId
      batchPipelinesId
      launchablePipelinesPipelinesId
    }
  }
`;

export const createDataLabEC2InstanceSession = /* GraphQL */ `
  mutation CreateDataLabEC2InstanceSession(
    $input: CreateDataLabEC2InstanceSessionInput!
    $condition: ModelDataLabEC2InstanceSessionConditionInput
  ) {
    createDataLabEC2InstanceSession(input: $input, condition: $condition) {
      id
      dataLabInstanceId
      s3Path
      instanceLaunchEvent
      status
      title
      description
      created
      updated
      dataLabEC2InstancesDataLabInstanceSessionsId
      owner
    }
  }
`;

export const deleteDataLabEC2InstanceSession = /* GraphQL */ `
  mutation DeleteDataLabEC2InstanceSession(
    $input: DeleteDataLabEC2InstanceSessionInput!
    $condition: ModelDataLabEC2InstanceSessionConditionInput
  ) {
    deleteDataLabEC2InstanceSession(input: $input, condition: $condition) {
      id
      dataLabInstanceId
      s3Path
      instanceLaunchEvent
      status
      title
      description
      created
      updated
      dataLabEC2InstancesDataLabInstanceSessionsId
      owner
    }
  }
`;

export const createDataLabEC2InstanceStatusChange = /* GraphQL */ `
  mutation CreateDataLabEC2InstanceStatusChange(
    $input: CreateDataLabEC2InstanceStatusChangeInput!
    $condition: ModelDataLabEC2InstanceStatusChangeConditionInput
  ) {
    createDataLabEC2InstanceStatusChange(input: $input, condition: $condition) {
      id
      dataLabInstanceId
      organizationId
      previousStatus
      newStatus
      initiatedBy
      action
      performedBy
      additionalNotes
      created
      updated
      dataLabEC2InstancesDataLabInstanceStatusChangesId
      owner
    }
  }
`;

export const createUploadFileCustom = /* GraphQL */ `
  mutation CreateUploadFile(
    $input: CreateUploadFileInput!
    $condition: ModelUploadFileConditionInput
  ) {
    createUploadFile(input: $input, condition: $condition) {
      id
      uploadSampleId
      multipartUploadId
      status
      fileName
      s3Path
      fileLog
      adminGroups
      created
      updated
      uploadSampleUploadFilesId
    }
  }
`;

export const createUploadBundleCustom = /* GraphQL */ `
  mutation CreateUploadBundle(
    $input: CreateUploadBundleInput!
    $condition: ModelUploadBundleConditionInput
  ) {
    createUploadBundle(input: $input, condition: $condition) {
      id
      bundleLog
      validationErrors
      status
      adminGroups
      created
      updated
    }
  }
`;

export const updateUploadFile = /* GraphQL */ `
  mutation UpdateUploadFile(
    $input: UpdateUploadFileInput!
    $condition: ModelUploadFileConditionInput
  ) {
    updateUploadFile(input: $input, condition: $condition) {
      id
      uploadSampleId
      uploadBundleId
      multipartUploadId
      status
      fileName
      s3Path
      fileLog
      adminGroups
      created
      updated
      uploadBundleUploadFilesId
      uploadSampleUploadFilesId
    }
  }
`;

export const updateUploadBundle = /* GraphQL */ `
  mutation UpdateUploadBundle(
    $input: UpdateUploadBundleInput!
    $condition: ModelUploadBundleConditionInput
  ) {
    updateUploadBundle(input: $input, condition: $condition) {
      id
      studyPhaseId
      bundleLog
      validationErrors
      status
      userEmail
      adminGroups
      created
      updated
      studyPhaseUploadBundlesId
      uploadBundleUploadBundleAnalysisSessionId
    }
  }
`;

export const createStudy = /* GraphQL */ `
  mutation CreateStudy(
    $input: CreateStudyInput!
    $condition: ModelStudyConditionInput
  ) {
    createStudy(input: $input, condition: $condition) {
      id
      studyName
      organizationId
      description
      studyCode
      studyAnswers
      s3Path
      croGroups
      readGroups
      writeGroups
      adminGroups
      created
      updated
      organizationStudiesId
    }
  }
`;

export const updateStudy = /* GraphQL */ `
  mutation UpdateStudy(
    $input: UpdateStudyInput!
    $condition: ModelStudyConditionInput
  ) {
    updateStudy(input: $input, condition: $condition) {
      id
      studyName
      organizationId
      description
      studyCode
      studyAnswers
      s3Path
      croGroups
      readGroups
      writeGroups
      adminGroups
      created
      updated
      organizationStudiesId
    }
  }
`;

export const createStudyPhase = /* GraphQL */ `
  mutation CreateStudyPhase(
    $input: CreateStudyPhaseInput!
    $condition: ModelStudyPhaseConditionInput
  ) {
    createStudyPhase(input: $input, condition: $condition) {
      id
      studyId
      metaDataValidationSchemaTemplateId
      validationSchema
      description
      studyPhaseName
      studyPhaseAnswers
      experimentSpecificVariables
      sampleTypes
      specimens
      species
      s3Path
      groupNames
      labAssayTechnologies
      phase
      readGroups
      writeGroups
      adminGroups
      croGroups
      created
      updated
      studyStudyPhasesId
      metaDataValidationSchemaTemplateStudyPhasesId
    }
  }
`;

export const updateStudyPhase = /* GraphQL */ `
  mutation UpdateStudyPhase(
    $input: UpdateStudyPhaseInput!
    $condition: ModelStudyPhaseConditionInput
  ) {
    updateStudyPhase(input: $input, condition: $condition) {
      id
      studyId
      metaDataValidationSchemaTemplateId
      validationSchema
      description
      studyPhaseName
      studyPhaseAnswers
      experimentSpecificVariables
      sampleTypes
      specimens
      species
      s3Path
      groupNames
      labAssayTechnologies
      phase
      readGroups
      writeGroups
      adminGroups
      croGroups
      created
      updated
      studyStudyPhasesId
      metaDataValidationSchemaTemplateStudyPhasesId
    }
  }
`;

export const createBatch = /* GraphQL */ `mutation CreateBatch(
  $input: CreateBatchInput!
  $condition: ModelBatchConditionInput
) {
  createBatch(input: $input, condition: $condition) {
    id
    batchName
    batchAnnotations
    batchGroups
    annotations
    controlSamples
    filters
    description
    studyId
    studyPhaseId
    readGroups
    writeGroups
    adminGroups
    created
    updated
    studyBatchesId
    studyPhaseBatchesId
  }
}
`;

export const updateMetadataRnaSeq = /* GraphQL */ `mutation UpdateMetadataRnaSeq(
  $input: UpdateMetadataRnaSeqInput!
  $condition: ModelMetadataRnaSeqConditionInput
) {
  updateMetadataRnaSeq(input: $input, condition: $condition) {
    id
    sampleId
    metaDataTemplateS3Path
    subtypeOfAnalysis
    species
    sampleType
    qcStatus
    glacierBackup
    rawDataS3
    tumorVolume
    sequenceType
    strandSpecific
    UMI
    adaptorsRemoved
    adaptorsS3Path
    indexSequences
    concNanoGramPerMicroL
    rnaMassNanoGram
    rnaVolumeMicroL
    rin
    readGroups
    writeGroups
    adminGroups
    created
    updated
    isControl
    barcodesMappedToControl
    barcode
    s3Path
    sampleMetadataRnaSeqListId
  }
}
`;

export const updateMetadataNanoString = /* GraphQL */ `mutation UpdateMetadataNanoString(
  $input: UpdateMetadataNanoStringInput!
  $condition: ModelMetadataNanoStringConditionInput
) {
  updateMetadataNanoString(input: $input, condition: $condition) {
    id
    sampleId
    metaDataTemplateS3Path
    subtypeOfAnalysis
    species
    sampleType
    qcStatus
    glacierBackup
    rawDataS3
    tumorVolume
    dv200Percentage
    concNanoGramPerMicroL
    rnaMassNanoGram
    rnaVolumeMicroL
    rin
    readGroups
    writeGroups
    adminGroups
    created
    updated
    isControl
    barcodesMappedToControl
    barcode
    s3Path
    sampleMetadataNanoStringListId
    __typename
  }
}
`;

export const updateMetadataImmunopeptidomics = /* GraphQL */ `mutation UpdateMetadataImmunopeptidomics(
  $input: UpdateMetadataImmunopeptidomicsInput!
  $condition: ModelMetadataImmunopeptidomicsConditionInput
) {
  updateMetadataImmunopeptidomics(input: $input, condition: $condition) {
    id
    sampleId
    metaDataTemplateS3Path
    species
    subtypeOfAnalysis
    sampleType
    qcStatus
    glacierBackup
    rawDataS3
    tumorVolume
    readGroups
    writeGroups
    adminGroups
    created
    updated
    isControl
    barcodesMappedToControl
    barcode
    s3Path
    sampleMetadataImmunopeptidomicsListId
    __typename
  }
}
`;

export const updateSample = /* GraphQL */ `mutation UpdateSample(
  $input: UpdateSampleInput!
  $condition: ModelSampleConditionInput
) {
  updateSample(input: $input, condition: $condition) {
    id
    timepointId
    studyPhaseId
    BatchId
    domainSampleId
    experimentSpecificVariables
    collectionDate
    processingDate
    readGroups
    writeGroups
    adminGroups
    created
    updated
    studyPhaseSamplesId
    timepointSamplesId
    batchSamplesId
    __typename
  }
}
`;
