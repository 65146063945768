<template>
  <Dialog v-model:visible="showing" header="Profile" :modal="true" class="p-fluid profile-dialog" :closable="false" :style="{width: '400px'}" :class="orgName">
    <div class="field">
      <label for="email">Email</label>
      <InputText id="email" v-model="email" required="true" :disabled="true" />
    </div>
    <div class="field">
      <Button class="p-button" label="Reset Password" @click="toggleChangePassword()" />
    </div>
    <div class="field">
      <label for="git-url">Git URL</label>
      <InputText id="git-url" v-model="gitUrl" :class="validateGitURL() ? '' : 'p-invalid'"/>
    </div>
    <InlineMessage :style="(!validateGitURL()) ? '' : 'display: none;'">Invalid Git Repo!</InlineMessage>
    <div class="field">
      <label for="git-token">Git Token</label>
      <InputText id="git-token" v-model="gitToken" />
    </div>
    <div>
      <Button @click="saveChanges()" :disabled="saving" label="Save Git Information" class="p-button" />
    </div>
    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-secondary" @click="hide"/>
    </template>
  </Dialog>
</template>

<script>
import { API, Auth, graphqlOperation } from 'aws-amplify';
import * as queries from '@/graphql/queries';
import * as mutations from '@/graphql/mutations';
import { validateURL } from '@/utils';

export default {
  data() {
    return {
      showing: false,
      email: '',
      isHidden: true,
      orgName: '',
      gitUrl: '',
      gitToken: '',
      userId: '',
      saving: false,
    };
  },
  methods: {
    hide() {
      if (this.isHidden === false) {
        this.isHidden = true;
      } else {
        this.$store.dispatch('showProfile');
      }
    },
    toggleChangePassword() {
      this.$store.dispatch('showProfile');
      this.$store.dispatch('showChangePassword');
    },
    async main() {
      await this.getUserInfo();
      await this.getDBUserInfo(this.userId);
    },
    async getUserInfo() {
      const userInfo = await Auth.currentUserInfo();
      this.email = userInfo.attributes.email;
      this.fullName = userInfo.attributes.name;
      this.userId = userInfo.username;
      this.orgName = (await this.$store.state.activeOrganization).organizationName;
    },
    async getDBUserInfo(userId) {
      let dbUser = await API.graphql(graphqlOperation(queries.getUser, { id: userId }));
      if (!dbUser.data.getUser) {
        dbUser = await this.createUser();
        if (!dbUser) {
          console.error('Failed to create user');
        }
        return;
      }
      this.gitUrl = dbUser.data.getUser.gitURL;
      this.gitToken = dbUser.data.getUser.gitToken;
    },
    async createUser() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const createUserResponse = await API.graphql(graphqlOperation(mutations.createUser, {
          input: {
            id: user.attributes.sub,
            organization: this.$route.params.organizationName,
          },
        }));
        return createUserResponse;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async saveChanges() {
      try {
        if (!this.validateGitURL()) {
          this.$toast.add({
            severity: 'error', summary: 'Error', detail: 'Invalid Git URL', life: 3000,
          });
          return;
        }
        this.saving = true;
        await API.graphql(graphqlOperation(mutations.updateUser, {
          input: {
            id: this.userId,
            gitURL: this.gitUrl,
            gitToken: this.gitToken,
          },
        }));
        this.saving = false;
        this.$toast.add({
          severity: 'success', summary: 'Success', detail: 'Saved Git Information!', life: 3000,
        });
      } catch (error) {
        console.error(error);
        this.saving = false;
      }
    },
    validateGitURL() {
      return validateURL(this.gitUrl);
    },
  },
  async created() {
    this.main();
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingProfile': async function () {
      this.showing = this.$store.state.showingProfile;
    },
  },
};
</script>
