/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateUserInput = {
  id?: string | null,
  email?: string | null,
  organization?: string | null,
  readGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
};

export type LaunchablePipelines = {
  __typename: "LaunchablePipelines",
  id: string,
  pipelineName?: string | null,
  organizations?: ModelOrganizationLaunchablePipelinesConnection | null,
  pipelineVersion?: string | null,
  parameters?: string | null,
  description?: string | null,
  inputFileInfo?: string | null,
  supportedAnalyses?: Array< AnalysisType | null > | null,
  supportedSubtypeOfAnalyses?: Array< string | null > | null,
  pipelines?: ModelPipelineConnection | null,
  gitRepo?: string | null,
  workflowId?: string | null,
  created: string,
  updated: string,
};

export type ModelOrganizationLaunchablePipelinesConnection = {
  __typename: "ModelOrganizationLaunchablePipelinesConnection",
  items:  Array<OrganizationLaunchablePipelines | null >,
  nextToken?: string | null,
};

export type OrganizationLaunchablePipelines = {
  __typename: "OrganizationLaunchablePipelines",
  id: string,
  organizationId: string,
  launchablePipelinesId: string,
  organization: Organization,
  launchablePipelines: LaunchablePipelines,
  createdAt: string,
  updatedAt: string,
};

export type Organization = {
  __typename: "Organization",
  id: string,
  organizationName: string,
  organizationCode?: string | null,
  active: boolean,
  studies?: ModelStudyConnection | null,
  launchablePipelines?: ModelOrganizationLaunchablePipelinesConnection | null,
  notificationEmail: string,
  description: string,
  organizationAnswers?: string | null,
  url: string,
  s3Path?: string | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  created: string,
  updated: string,
};

export type ModelStudyConnection = {
  __typename: "ModelStudyConnection",
  items:  Array<Study | null >,
  nextToken?: string | null,
};

export type Study = {
  __typename: "Study",
  id: string,
  studyName: string,
  organizationId: string,
  organization?: Organization | null,
  studyPhases?: ModelStudyPhaseConnection | null,
  batches?: ModelBatchConnection | null,
  pipelines?: ModelPipelineConnection | null,
  description: string,
  studyCode: string,
  studyAnswers?: string | null,
  s3Path?: string | null,
  croGroups?: Array< string | null > | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created: string,
  updated: string,
  organizationStudiesId?: string | null,
};

export type ModelStudyPhaseConnection = {
  __typename: "ModelStudyPhaseConnection",
  items:  Array<StudyPhase | null >,
  nextToken?: string | null,
};

export type StudyPhase = {
  __typename: "StudyPhase",
  id: string,
  studyId: string,
  study?: Study | null,
  metaDataValidationSchemaTemplateId: string,
  metaDataValidationSchemaTemplate?: MetaDataValidationSchemaTemplate | null,
  groups?: ModelGroupConnection | null,
  samples?: ModelSampleConnection | null,
  batches?: ModelBatchConnection | null,
  uploadBundles?: ModelUploadBundleConnection | null,
  pipelines?: ModelPipelineConnection | null,
  validationSchema?: string | null,
  description: string,
  studyPhaseName: string,
  studyPhaseAnswers?: string | null,
  experimentSpecificVariables?: string | null,
  sampleTypes?: Array< string | null > | null,
  specimens?: Array< string | null > | null,
  species?: Array< string | null > | null,
  s3Path?: string | null,
  groupNames?: Array< string | null > | null,
  labAssayTechnologies?: Array< string | null > | null,
  phase: Phase,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  croGroups?: Array< string | null > | null,
  created: string,
  updated: string,
  studyStudyPhasesId?: string | null,
  metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
};

export type MetaDataValidationSchemaTemplate = {
  __typename: "MetaDataValidationSchemaTemplate",
  id: string,
  studyPhases?: ModelStudyPhaseConnection | null,
  version: string,
  description?: string | null,
  templateFiles: string,
  validationSchemaTemplate: string,
  created: string,
  updated: string,
};

export type ModelGroupConnection = {
  __typename: "ModelGroupConnection",
  items:  Array<Group | null >,
  nextToken?: string | null,
};

export type Group = {
  __typename: "Group",
  id: string,
  studyPhaseId: string,
  studyPhase?: StudyPhase | null,
  type?: string | null,
  groupName: string,
  s3Path?: string | null,
  patients?: ModelPatientConnection | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created: string,
  updated: string,
  studyPhaseGroupsId?: string | null,
};

export type ModelPatientConnection = {
  __typename: "ModelPatientConnection",
  items:  Array<Patient | null >,
  nextToken?: string | null,
};

export type Patient = {
  __typename: "Patient",
  id: string,
  groupId: string,
  group?: Group | null,
  timepoints?: ModelTimepointConnection | null,
  patientStudyPhaseId: string,
  patientStudyId: string,
  s3Path?: string | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created: string,
  updated: string,
  groupPatientsId?: string | null,
};

export type ModelTimepointConnection = {
  __typename: "ModelTimepointConnection",
  items:  Array<Timepoint | null >,
  nextToken?: string | null,
};

export type Timepoint = {
  __typename: "Timepoint",
  id: string,
  patientId: string,
  patient?: Patient | null,
  samples?: ModelSampleConnection | null,
  timepointName: string,
  s3Path?: string | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created: string,
  updated: string,
  patientTimepointsId?: string | null,
};

export type ModelSampleConnection = {
  __typename: "ModelSampleConnection",
  items:  Array<Sample | null >,
  nextToken?: string | null,
};

export type Sample = {
  __typename: "Sample",
  id: string,
  timepointId?: string | null,
  timepoint?: Timepoint | null,
  studyPhaseId?: string | null,
  StudyPhase?: StudyPhase | null,
  BatchId?: string | null,
  Batch?: Batch | null,
  metadataImmunopeptidomicsList?: ModelMetadataImmunopeptidomicsConnection | null,
  MetadataNanoStringList?: ModelMetadataNanoStringConnection | null,
  MetadataRnaSeqList?: ModelMetadataRnaSeqConnection | null,
  AuxiliaryAnalysislList?: ModelAuxiliaryAnalysisConnection | null,
  domainSampleId: string,
  experimentSpecificVariables?: string | null,
  collectionDate?: string | null,
  processingDate?: string | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created: string,
  updated: string,
  studyPhaseSamplesId?: string | null,
  timepointSamplesId?: string | null,
  batchSamplesId?: string | null,
};

export type Batch = {
  __typename: "Batch",
  id: string,
  batchName: string,
  batchAnnotations?: string | null,
  batchGroups?: string | null,
  annotations: string,
  controlSamples: string,
  filters: string,
  description?: string | null,
  samples?: ModelSampleConnection | null,
  pipelines?: ModelPipelineConnection | null,
  studyId: string,
  study?: Study | null,
  studyPhaseId?: string | null,
  studyPhase?: StudyPhase | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created: string,
  updated: string,
  studyBatchesId?: string | null,
  studyPhaseBatchesId?: string | null,
};

export type ModelPipelineConnection = {
  __typename: "ModelPipelineConnection",
  items:  Array<Pipeline | null >,
  nextToken?: string | null,
};

export type Pipeline = {
  __typename: "Pipeline",
  id: string,
  name: string,
  status?: pipelineStatus | null,
  description?: string | null,
  s3Event?: string | null,
  s3ParamsFile?: string | null,
  s3Results?: string | null,
  visFileInfoS3path?: string | null,
  s3NfLog?: string | null,
  s3NfTasksLogs?: Array< string | null > | null,
  analysisType: AnalysisType,
  subtypeOfAnalysis?: string | null,
  parameters: string,
  studyId?: string | null,
  study?: Study | null,
  studyPhaseId?: string | null,
  studyPhase?: StudyPhase | null,
  scheduledPipeline: boolean,
  scheduledDateTime?: string | null,
  launchablePipelineId: string,
  launchablePipeline?: LaunchablePipelines | null,
  batchId: string,
  batch?: Batch | null,
  runId?: string | null,
  userEmail: string,
  pipelineStarted?: string | null,
  pipelineCompleted?: string | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created: string,
  updated: string,
  studyPipelinesId?: string | null,
  studyPhasePipelinesId?: string | null,
  batchPipelinesId?: string | null,
  launchablePipelinesPipelinesId?: string | null,
};

export enum pipelineStatus {
  INITIATED = "INITIATED",
  SCHEDULED = "SCHEDULED",
  SCHEDULED_FAILED = "SCHEDULED_FAILED",
  RUNNING = "RUNNING",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
  CANCELLED_FAILED = "CANCELLED_FAILED",
  ABORTED = "ABORTED",
  ABORTED_FAILED = "ABORTED_FAILED",
  FINISHED = "FINISHED",
  FAILED = "FAILED",
  ERROR = "ERROR",
}


export enum AnalysisType {
  RNASEQ = "RNASEQ",
  IMMUNOPEPTIDOMICS = "IMMUNOPEPTIDOMICS",
  NANOSTRING = "NANOSTRING",
}


export type ModelMetadataImmunopeptidomicsConnection = {
  __typename: "ModelMetadataImmunopeptidomicsConnection",
  items:  Array<MetadataImmunopeptidomics | null >,
  nextToken?: string | null,
};

export type MetadataImmunopeptidomics = {
  __typename: "MetadataImmunopeptidomics",
  id: string,
  sampleId: string,
  sample?: Sample | null,
  treatment?: ModelTreatmentConnection | null,
  metaDataTemplateS3Path: string,
  species: string,
  subtypeOfAnalysis?: string | null,
  sampleType: string,
  qcStatus: QcStatus,
  glacierBackup: boolean,
  rawDataS3: boolean,
  tumorVolume?: number | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created: string,
  updated: string,
  isControl: boolean,
  barcodesMappedToControl?: Array< string | null > | null,
  barcode: string,
  s3Path: Array< string | null >,
  sampleMetadataImmunopeptidomicsListId?: string | null,
};

export type ModelTreatmentConnection = {
  __typename: "ModelTreatmentConnection",
  items:  Array<Treatment | null >,
  nextToken?: string | null,
};

export type Treatment = {
  __typename: "Treatment",
  id: string,
  metadataImmunopeptidomicsId?: string | null,
  metadataImmunopeptidomics?: MetadataImmunopeptidomics | null,
  metadataNanoStringId?: string | null,
  metadataNanoString?: MetadataNanoString | null,
  metadataRnaSeqId?: string | null,
  metadataRnaSeq?: MetadataRnaSeq | null,
  treatmentName: string,
  dose: number,
  doseUnit: string,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created: string,
  updated: string,
  metadataImmunopeptidomicsTreatmentId?: string | null,
  metadataNanoStringTreatmentId?: string | null,
  metadataRnaSeqTreatmentId?: string | null,
};

export type MetadataNanoString = {
  __typename: "MetadataNanoString",
  id: string,
  sampleId: string,
  sample?: Sample | null,
  treatment?: ModelTreatmentConnection | null,
  metaDataTemplateS3Path: string,
  subtypeOfAnalysis?: string | null,
  species: string,
  sampleType: string,
  qcStatus: QcStatus,
  glacierBackup: boolean,
  rawDataS3: boolean,
  tumorVolume?: number | null,
  dv200Percentage?: number | null,
  concNanoGramPerMicroL?: number | null,
  rnaMassNanoGram?: number | null,
  rnaVolumeMicroL?: number | null,
  rin?: number | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created: string,
  updated: string,
  isControl: boolean,
  barcodesMappedToControl?: Array< string | null > | null,
  barcode: string,
  s3Path: Array< string | null >,
  sampleMetadataNanoStringListId?: string | null,
};

export enum QcStatus {
  AWAITING = "AWAITING",
  PASSED = "PASSED",
  FAILED = "FAILED",
}


export type MetadataRnaSeq = {
  __typename: "MetadataRnaSeq",
  id: string,
  sampleId: string,
  sample?: Sample | null,
  treatment?: ModelTreatmentConnection | null,
  metaDataTemplateS3Path: string,
  subtypeOfAnalysis?: string | null,
  species: string,
  sampleType: string,
  qcStatus: QcStatus,
  glacierBackup: boolean,
  rawDataS3: boolean,
  tumorVolume?: number | null,
  sequenceType: SequenceType,
  strandSpecific: string,
  UMI: boolean,
  adaptorsRemoved: boolean,
  adaptorsS3Path?: string | null,
  indexSequences?: Array< string | null > | null,
  concNanoGramPerMicroL?: number | null,
  rnaMassNanoGram?: number | null,
  rnaVolumeMicroL?: number | null,
  rin?: number | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created: string,
  updated: string,
  isControl: boolean,
  barcodesMappedToControl?: Array< string | null > | null,
  barcode: string,
  s3Path: Array< string | null >,
  sampleMetadataRnaSeqListId?: string | null,
};

export enum SequenceType {
  PAIRED_END = "PAIRED_END",
  SINGLE_END = "SINGLE_END",
}


export type ModelMetadataNanoStringConnection = {
  __typename: "ModelMetadataNanoStringConnection",
  items:  Array<MetadataNanoString | null >,
  nextToken?: string | null,
};

export type ModelMetadataRnaSeqConnection = {
  __typename: "ModelMetadataRnaSeqConnection",
  items:  Array<MetadataRnaSeq | null >,
  nextToken?: string | null,
};

export type ModelAuxiliaryAnalysisConnection = {
  __typename: "ModelAuxiliaryAnalysisConnection",
  items:  Array<AuxiliaryAnalysis | null >,
  nextToken?: string | null,
};

export type AuxiliaryAnalysis = {
  __typename: "AuxiliaryAnalysis",
  id: string,
  sampleId: string,
  sample?: Sample | null,
  auxiliaryFileS3Path: string,
  vendorId: string,
  specimenType: string,
  parameters: string,
  collectionDate?: string | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created: string,
  updated: string,
  sampleAuxiliaryAnalysislListId?: string | null,
};

export type ModelBatchConnection = {
  __typename: "ModelBatchConnection",
  items:  Array<Batch | null >,
  nextToken?: string | null,
};

export type ModelUploadBundleConnection = {
  __typename: "ModelUploadBundleConnection",
  items:  Array<UploadBundle | null >,
  nextToken?: string | null,
};

export type UploadBundle = {
  __typename: "UploadBundle",
  id: string,
  studyPhaseId?: string | null,
  studyPhase?: StudyPhase | null,
  bundleLog?: string | null,
  validationErrors?: string | null,
  status?: string | null,
  userEmail?: string | null,
  uploadBundleAnalysisSession?: UploadBundleAnalysisSession | null,
  uploadSamples?: ModelUploadSampleConnection | null,
  uploadFiles?: ModelUploadFileConnection | null,
  adminGroups?: Array< string | null > | null,
  completionTime?: string | null,
  created: string,
  updated: string,
  studyPhaseUploadBundlesId?: string | null,
  uploadBundleUploadBundleAnalysisSessionId?: string | null,
  owner?: string | null,
};

export type UploadBundleAnalysisSession = {
  __typename: "UploadBundleAnalysisSession",
  id: string,
  uploadSampleAnalysisSession?: ModelUploadSampleAnalysisSessionConnection | null,
  metadataFileS3Path?: string | null,
  step?: string | null,
  listOfRawFileNames?: Array< string | null > | null,
  analysisType: AnalysisType,
  subtypeOfAnalysis: string,
  studyPhaseId: string,
  metadataHelperLambdaResponseS3Path?: string | null,
  barcodeToFilesToUploadS3Path?: string | null,
  adminGroups?: Array< string | null > | null,
  created: string,
  updated: string,
  owner?: string | null,
};

export type ModelUploadSampleAnalysisSessionConnection = {
  __typename: "ModelUploadSampleAnalysisSessionConnection",
  items:  Array<UploadSampleAnalysisSession | null >,
  nextToken?: string | null,
};

export type UploadSampleAnalysisSession = {
  __typename: "UploadSampleAnalysisSession",
  id: string,
  uploadBundleAnalysisSessionId: string,
  uploadBundleAnalysisSession?: UploadBundleAnalysisSession | null,
  sampleId: string,
  rowFromMetaDataFileMappedToSample: string,
  adminGroups?: Array< string | null > | null,
  created: string,
  updated: string,
  uploadBundleAnalysisSessionUploadSampleAnalysisSessionId?: string | null,
  owner?: string | null,
};

export type ModelUploadSampleConnection = {
  __typename: "ModelUploadSampleConnection",
  items:  Array<UploadSample | null >,
  nextToken?: string | null,
};

export type UploadSample = {
  __typename: "UploadSample",
  id: string,
  uploadBundleId: string,
  uploadBundle?: UploadBundle | null,
  sampleId: string,
  barcode: string,
  sampleLog?: string | null,
  writeMetadata: boolean,
  status?: string | null,
  uploadFiles?: ModelUploadFileConnection | null,
  adminGroups?: Array< string | null > | null,
  created: string,
  updated: string,
  uploadBundleUploadSamplesId?: string | null,
  owner?: string | null,
};

export type ModelUploadFileConnection = {
  __typename: "ModelUploadFileConnection",
  items:  Array<UploadFile | null >,
  nextToken?: string | null,
};

export type UploadFile = {
  __typename: "UploadFile",
  id: string,
  uploadSampleId?: string | null,
  uploadSample?: UploadSample | null,
  uploadBundleId?: string | null,
  uploadBundle?: UploadBundle | null,
  multipartUploadId?: string | null,
  status?: UploadFileStatus | null,
  toSkipUpload: boolean,
  fileName: string,
  fileType: string,
  s3Path: string,
  size?: number | null,
  fileLog?: string | null,
  adminGroups?: Array< string | null > | null,
  created: string,
  updated: string,
  uploadBundleUploadFilesId?: string | null,
  uploadSampleUploadFilesId?: string | null,
  owner?: string | null,
};

export enum UploadFileStatus {
  PENDING = "PENDING",
  UPLOADING = "UPLOADING",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  MISSING = "MISSING",
}


export enum Phase {
  PRE_CLINICAL = "PRE_CLINICAL",
  PHASE_1 = "PHASE_1",
  PHASE_2 = "PHASE_2",
  PHASE_3 = "PHASE_3",
}


export type CreateOrganizationInput = {
  id?: string | null,
  organizationName: string,
  organizationCode?: string | null,
  active: boolean,
  notificationEmail: string,
  description: string,
  organizationAnswers?: string | null,
  url: string,
  s3Path?: string | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
};

export type ModelOrganizationConditionInput = {
  organizationName?: ModelStringInput | null,
  organizationCode?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  notificationEmail?: ModelStringInput | null,
  description?: ModelStringInput | null,
  organizationAnswers?: ModelStringInput | null,
  url?: ModelStringInput | null,
  s3Path?: ModelStringInput | null,
  readGroups?: ModelStringInput | null,
  writeGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelOrganizationConditionInput | null > | null,
  or?: Array< ModelOrganizationConditionInput | null > | null,
  not?: ModelOrganizationConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateOrganizationInput = {
  id: string,
  organizationName?: string | null,
  organizationCode?: string | null,
  active?: boolean | null,
  notificationEmail?: string | null,
  description?: string | null,
  organizationAnswers?: string | null,
  url?: string | null,
  s3Path?: string | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
};

export type DeleteOrganizationInput = {
  id: string,
};

export type CreateStudyInput = {
  id?: string | null,
  studyName: string,
  organizationId: string,
  description: string,
  studyCode: string,
  studyAnswers?: string | null,
  s3Path?: string | null,
  croGroups?: Array< string | null > | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
  organizationStudiesId?: string | null,
};

export type ModelStudyConditionInput = {
  studyName?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  description?: ModelStringInput | null,
  studyCode?: ModelStringInput | null,
  studyAnswers?: ModelStringInput | null,
  s3Path?: ModelStringInput | null,
  croGroups?: ModelStringInput | null,
  readGroups?: ModelStringInput | null,
  writeGroups?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelStudyConditionInput | null > | null,
  or?: Array< ModelStudyConditionInput | null > | null,
  not?: ModelStudyConditionInput | null,
  organizationStudiesId?: ModelIDInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type UpdateStudyInput = {
  id: string,
  studyName?: string | null,
  organizationId?: string | null,
  description?: string | null,
  studyCode?: string | null,
  studyAnswers?: string | null,
  s3Path?: string | null,
  croGroups?: Array< string | null > | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
  organizationStudiesId?: string | null,
};

export type DeleteStudyInput = {
  id: string,
};

export type CreateStudyPhaseInput = {
  id?: string | null,
  studyId: string,
  metaDataValidationSchemaTemplateId: string,
  validationSchema?: string | null,
  description: string,
  studyPhaseName: string,
  studyPhaseAnswers?: string | null,
  experimentSpecificVariables?: string | null,
  sampleTypes?: Array< string | null > | null,
  specimens?: Array< string | null > | null,
  species?: Array< string | null > | null,
  s3Path?: string | null,
  groupNames?: Array< string | null > | null,
  labAssayTechnologies?: Array< string | null > | null,
  phase: Phase,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  croGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
  studyStudyPhasesId?: string | null,
  metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
};

export type ModelStudyPhaseConditionInput = {
  studyId?: ModelIDInput | null,
  metaDataValidationSchemaTemplateId?: ModelIDInput | null,
  validationSchema?: ModelStringInput | null,
  description?: ModelStringInput | null,
  studyPhaseName?: ModelStringInput | null,
  studyPhaseAnswers?: ModelStringInput | null,
  experimentSpecificVariables?: ModelStringInput | null,
  sampleTypes?: ModelStringInput | null,
  specimens?: ModelStringInput | null,
  species?: ModelStringInput | null,
  s3Path?: ModelStringInput | null,
  groupNames?: ModelStringInput | null,
  labAssayTechnologies?: ModelStringInput | null,
  phase?: ModelPhaseInput | null,
  readGroups?: ModelStringInput | null,
  writeGroups?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  croGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelStudyPhaseConditionInput | null > | null,
  or?: Array< ModelStudyPhaseConditionInput | null > | null,
  not?: ModelStudyPhaseConditionInput | null,
  studyStudyPhasesId?: ModelIDInput | null,
  metaDataValidationSchemaTemplateStudyPhasesId?: ModelIDInput | null,
};

export type ModelPhaseInput = {
  eq?: Phase | null,
  ne?: Phase | null,
};

export type UpdateStudyPhaseInput = {
  id: string,
  studyId?: string | null,
  metaDataValidationSchemaTemplateId?: string | null,
  validationSchema?: string | null,
  description?: string | null,
  studyPhaseName?: string | null,
  studyPhaseAnswers?: string | null,
  experimentSpecificVariables?: string | null,
  sampleTypes?: Array< string | null > | null,
  specimens?: Array< string | null > | null,
  species?: Array< string | null > | null,
  s3Path?: string | null,
  groupNames?: Array< string | null > | null,
  labAssayTechnologies?: Array< string | null > | null,
  phase?: Phase | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  croGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
  studyStudyPhasesId?: string | null,
  metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
};

export type DeleteStudyPhaseInput = {
  id: string,
};

export type CreateEntityQuestionsInput = {
  id?: string | null,
  entityType?: EntityType | null,
  questions?: string | null,
  created?: string | null,
  updated?: string | null,
};

export enum EntityType {
  ORGANIZATION = "ORGANIZATION",
  STUDY = "STUDY",
  STUDY_PHASE = "STUDY_PHASE",
}


export type ModelEntityQuestionsConditionInput = {
  entityType?: ModelEntityTypeInput | null,
  questions?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelEntityQuestionsConditionInput | null > | null,
  or?: Array< ModelEntityQuestionsConditionInput | null > | null,
  not?: ModelEntityQuestionsConditionInput | null,
};

export type ModelEntityTypeInput = {
  eq?: EntityType | null,
  ne?: EntityType | null,
};

export type EntityQuestions = {
  __typename: "EntityQuestions",
  id: string,
  entityType?: EntityType | null,
  questions?: string | null,
  created: string,
  updated: string,
};

export type UpdateEntityQuestionsInput = {
  id: string,
  entityType?: EntityType | null,
  questions?: string | null,
  created?: string | null,
  updated?: string | null,
};

export type DeleteEntityQuestionsInput = {
  id: string,
};

export type CreateMetaDataValidationSchemaTemplateInput = {
  id?: string | null,
  version: string,
  description?: string | null,
  templateFiles: string,
  validationSchemaTemplate: string,
  created?: string | null,
  updated?: string | null,
};

export type ModelMetaDataValidationSchemaTemplateConditionInput = {
  version?: ModelStringInput | null,
  description?: ModelStringInput | null,
  templateFiles?: ModelStringInput | null,
  validationSchemaTemplate?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelMetaDataValidationSchemaTemplateConditionInput | null > | null,
  or?: Array< ModelMetaDataValidationSchemaTemplateConditionInput | null > | null,
  not?: ModelMetaDataValidationSchemaTemplateConditionInput | null,
};

export type UpdateMetaDataValidationSchemaTemplateInput = {
  id: string,
  version?: string | null,
  description?: string | null,
  templateFiles?: string | null,
  validationSchemaTemplate?: string | null,
  created?: string | null,
  updated?: string | null,
};

export type DeleteMetaDataValidationSchemaTemplateInput = {
  id: string,
};

export type CreateGroupInput = {
  id?: string | null,
  studyPhaseId: string,
  type?: string | null,
  groupName: string,
  s3Path?: string | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
  studyPhaseGroupsId?: string | null,
};

export type ModelGroupConditionInput = {
  studyPhaseId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  groupName?: ModelStringInput | null,
  s3Path?: ModelStringInput | null,
  readGroups?: ModelStringInput | null,
  writeGroups?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelGroupConditionInput | null > | null,
  or?: Array< ModelGroupConditionInput | null > | null,
  not?: ModelGroupConditionInput | null,
  studyPhaseGroupsId?: ModelIDInput | null,
};

export type UpdateGroupInput = {
  id: string,
  studyPhaseId?: string | null,
  type?: string | null,
  groupName?: string | null,
  s3Path?: string | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
  studyPhaseGroupsId?: string | null,
};

export type DeleteGroupInput = {
  id: string,
};

export type CreatePatientInput = {
  id?: string | null,
  groupId: string,
  patientStudyPhaseId: string,
  patientStudyId: string,
  s3Path?: string | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
  groupPatientsId?: string | null,
};

export type ModelPatientConditionInput = {
  groupId?: ModelIDInput | null,
  patientStudyPhaseId?: ModelStringInput | null,
  patientStudyId?: ModelStringInput | null,
  s3Path?: ModelStringInput | null,
  readGroups?: ModelStringInput | null,
  writeGroups?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelPatientConditionInput | null > | null,
  or?: Array< ModelPatientConditionInput | null > | null,
  not?: ModelPatientConditionInput | null,
  groupPatientsId?: ModelIDInput | null,
};

export type UpdatePatientInput = {
  id: string,
  groupId?: string | null,
  patientStudyPhaseId?: string | null,
  patientStudyId?: string | null,
  s3Path?: string | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
  groupPatientsId?: string | null,
};

export type DeletePatientInput = {
  id: string,
};

export type CreateTimepointInput = {
  id?: string | null,
  patientId: string,
  timepointName: string,
  s3Path?: string | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
  patientTimepointsId?: string | null,
};

export type ModelTimepointConditionInput = {
  patientId?: ModelIDInput | null,
  timepointName?: ModelStringInput | null,
  s3Path?: ModelStringInput | null,
  readGroups?: ModelStringInput | null,
  writeGroups?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelTimepointConditionInput | null > | null,
  or?: Array< ModelTimepointConditionInput | null > | null,
  not?: ModelTimepointConditionInput | null,
  patientTimepointsId?: ModelIDInput | null,
};

export type UpdateTimepointInput = {
  id: string,
  patientId?: string | null,
  timepointName?: string | null,
  s3Path?: string | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
  patientTimepointsId?: string | null,
};

export type DeleteTimepointInput = {
  id: string,
};

export type CreateSampleInput = {
  id?: string | null,
  timepointId?: string | null,
  studyPhaseId?: string | null,
  BatchId?: string | null,
  domainSampleId: string,
  experimentSpecificVariables?: string | null,
  collectionDate?: string | null,
  processingDate?: string | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
  studyPhaseSamplesId?: string | null,
  timepointSamplesId?: string | null,
  batchSamplesId?: string | null,
};

export type ModelSampleConditionInput = {
  timepointId?: ModelIDInput | null,
  studyPhaseId?: ModelIDInput | null,
  BatchId?: ModelIDInput | null,
  domainSampleId?: ModelStringInput | null,
  experimentSpecificVariables?: ModelStringInput | null,
  collectionDate?: ModelStringInput | null,
  processingDate?: ModelStringInput | null,
  readGroups?: ModelStringInput | null,
  writeGroups?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelSampleConditionInput | null > | null,
  or?: Array< ModelSampleConditionInput | null > | null,
  not?: ModelSampleConditionInput | null,
  studyPhaseSamplesId?: ModelIDInput | null,
  timepointSamplesId?: ModelIDInput | null,
  batchSamplesId?: ModelIDInput | null,
};

export type UpdateSampleInput = {
  id: string,
  timepointId?: string | null,
  studyPhaseId?: string | null,
  BatchId?: string | null,
  domainSampleId?: string | null,
  experimentSpecificVariables?: string | null,
  collectionDate?: string | null,
  processingDate?: string | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
  studyPhaseSamplesId?: string | null,
  timepointSamplesId?: string | null,
  batchSamplesId?: string | null,
};

export type DeleteSampleInput = {
  id: string,
};

export type CreateBatchInput = {
  id?: string | null,
  batchName: string,
  batchAnnotations?: string | null,
  batchGroups?: string | null,
  annotations: string,
  controlSamples: string,
  filters: string,
  description?: string | null,
  studyId: string,
  studyPhaseId?: string | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
  studyBatchesId?: string | null,
  studyPhaseBatchesId?: string | null,
};

export type ModelBatchConditionInput = {
  batchName?: ModelStringInput | null,
  batchAnnotations?: ModelStringInput | null,
  batchGroups?: ModelStringInput | null,
  annotations?: ModelStringInput | null,
  controlSamples?: ModelStringInput | null,
  filters?: ModelStringInput | null,
  description?: ModelStringInput | null,
  studyId?: ModelIDInput | null,
  studyPhaseId?: ModelIDInput | null,
  readGroups?: ModelStringInput | null,
  writeGroups?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelBatchConditionInput | null > | null,
  or?: Array< ModelBatchConditionInput | null > | null,
  not?: ModelBatchConditionInput | null,
  studyBatchesId?: ModelIDInput | null,
  studyPhaseBatchesId?: ModelIDInput | null,
};

export type UpdateBatchInput = {
  id: string,
  batchName?: string | null,
  batchAnnotations?: string | null,
  batchGroups?: string | null,
  annotations?: string | null,
  controlSamples?: string | null,
  filters?: string | null,
  description?: string | null,
  studyId?: string | null,
  studyPhaseId?: string | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
  studyBatchesId?: string | null,
  studyPhaseBatchesId?: string | null,
};

export type DeleteBatchInput = {
  id: string,
};

export type CreatePipelineInput = {
  id?: string | null,
  name: string,
  status?: pipelineStatus | null,
  description?: string | null,
  s3Event?: string | null,
  s3ParamsFile?: string | null,
  s3Results?: string | null,
  visFileInfoS3path?: string | null,
  s3NfLog?: string | null,
  s3NfTasksLogs?: Array< string | null > | null,
  analysisType: AnalysisType,
  subtypeOfAnalysis?: string | null,
  parameters: string,
  studyId?: string | null,
  studyPhaseId?: string | null,
  scheduledPipeline: boolean,
  scheduledDateTime?: string | null,
  launchablePipelineId: string,
  batchId: string,
  runId?: string | null,
  userEmail: string,
  pipelineStarted?: string | null,
  pipelineCompleted?: string | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
  studyPipelinesId?: string | null,
  studyPhasePipelinesId?: string | null,
  batchPipelinesId?: string | null,
  launchablePipelinesPipelinesId?: string | null,
};

export type ModelPipelineConditionInput = {
  name?: ModelStringInput | null,
  status?: ModelpipelineStatusInput | null,
  description?: ModelStringInput | null,
  s3Event?: ModelStringInput | null,
  s3ParamsFile?: ModelStringInput | null,
  s3Results?: ModelStringInput | null,
  visFileInfoS3path?: ModelStringInput | null,
  s3NfLog?: ModelStringInput | null,
  s3NfTasksLogs?: ModelStringInput | null,
  analysisType?: ModelAnalysisTypeInput | null,
  subtypeOfAnalysis?: ModelStringInput | null,
  parameters?: ModelStringInput | null,
  studyId?: ModelIDInput | null,
  studyPhaseId?: ModelIDInput | null,
  scheduledPipeline?: ModelBooleanInput | null,
  scheduledDateTime?: ModelStringInput | null,
  launchablePipelineId?: ModelIDInput | null,
  batchId?: ModelIDInput | null,
  runId?: ModelStringInput | null,
  userEmail?: ModelStringInput | null,
  pipelineStarted?: ModelStringInput | null,
  pipelineCompleted?: ModelStringInput | null,
  readGroups?: ModelStringInput | null,
  writeGroups?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelPipelineConditionInput | null > | null,
  or?: Array< ModelPipelineConditionInput | null > | null,
  not?: ModelPipelineConditionInput | null,
  studyPipelinesId?: ModelIDInput | null,
  studyPhasePipelinesId?: ModelIDInput | null,
  batchPipelinesId?: ModelIDInput | null,
  launchablePipelinesPipelinesId?: ModelIDInput | null,
};

export type ModelpipelineStatusInput = {
  eq?: pipelineStatus | null,
  ne?: pipelineStatus | null,
};

export type ModelAnalysisTypeInput = {
  eq?: AnalysisType | null,
  ne?: AnalysisType | null,
};

export type UpdatePipelineInput = {
  id: string,
  name?: string | null,
  status?: pipelineStatus | null,
  description?: string | null,
  s3Event?: string | null,
  s3ParamsFile?: string | null,
  s3Results?: string | null,
  visFileInfoS3path?: string | null,
  s3NfLog?: string | null,
  s3NfTasksLogs?: Array< string | null > | null,
  analysisType?: AnalysisType | null,
  subtypeOfAnalysis?: string | null,
  parameters?: string | null,
  studyId?: string | null,
  studyPhaseId?: string | null,
  scheduledPipeline?: boolean | null,
  scheduledDateTime?: string | null,
  launchablePipelineId?: string | null,
  batchId?: string | null,
  runId?: string | null,
  userEmail?: string | null,
  pipelineStarted?: string | null,
  pipelineCompleted?: string | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
  studyPipelinesId?: string | null,
  studyPhasePipelinesId?: string | null,
  batchPipelinesId?: string | null,
  launchablePipelinesPipelinesId?: string | null,
};

export type DeletePipelineInput = {
  id: string,
};

export type CreateAuxiliaryAnalysisInput = {
  id?: string | null,
  sampleId: string,
  auxiliaryFileS3Path: string,
  vendorId: string,
  specimenType: string,
  parameters: string,
  collectionDate?: string | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
  sampleAuxiliaryAnalysislListId?: string | null,
};

export type ModelAuxiliaryAnalysisConditionInput = {
  sampleId?: ModelIDInput | null,
  auxiliaryFileS3Path?: ModelStringInput | null,
  vendorId?: ModelStringInput | null,
  specimenType?: ModelStringInput | null,
  parameters?: ModelStringInput | null,
  collectionDate?: ModelStringInput | null,
  readGroups?: ModelStringInput | null,
  writeGroups?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelAuxiliaryAnalysisConditionInput | null > | null,
  or?: Array< ModelAuxiliaryAnalysisConditionInput | null > | null,
  not?: ModelAuxiliaryAnalysisConditionInput | null,
  sampleAuxiliaryAnalysislListId?: ModelIDInput | null,
};

export type UpdateAuxiliaryAnalysisInput = {
  id: string,
  sampleId?: string | null,
  auxiliaryFileS3Path?: string | null,
  vendorId?: string | null,
  specimenType?: string | null,
  parameters?: string | null,
  collectionDate?: string | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
  sampleAuxiliaryAnalysislListId?: string | null,
};

export type DeleteAuxiliaryAnalysisInput = {
  id: string,
};

export type CreateMetadataImmunopeptidomicsInput = {
  id?: string | null,
  sampleId: string,
  metaDataTemplateS3Path: string,
  species: string,
  subtypeOfAnalysis?: string | null,
  sampleType: string,
  qcStatus: QcStatus,
  glacierBackup: boolean,
  rawDataS3: boolean,
  tumorVolume?: number | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
  isControl: boolean,
  barcodesMappedToControl?: Array< string | null > | null,
  barcode: string,
  s3Path: Array< string | null >,
  sampleMetadataImmunopeptidomicsListId?: string | null,
};

export type ModelMetadataImmunopeptidomicsConditionInput = {
  sampleId?: ModelIDInput | null,
  metaDataTemplateS3Path?: ModelStringInput | null,
  species?: ModelStringInput | null,
  subtypeOfAnalysis?: ModelStringInput | null,
  sampleType?: ModelStringInput | null,
  qcStatus?: ModelQcStatusInput | null,
  glacierBackup?: ModelBooleanInput | null,
  rawDataS3?: ModelBooleanInput | null,
  tumorVolume?: ModelFloatInput | null,
  readGroups?: ModelStringInput | null,
  writeGroups?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  isControl?: ModelBooleanInput | null,
  barcodesMappedToControl?: ModelStringInput | null,
  barcode?: ModelStringInput | null,
  s3Path?: ModelStringInput | null,
  and?: Array< ModelMetadataImmunopeptidomicsConditionInput | null > | null,
  or?: Array< ModelMetadataImmunopeptidomicsConditionInput | null > | null,
  not?: ModelMetadataImmunopeptidomicsConditionInput | null,
  sampleMetadataImmunopeptidomicsListId?: ModelIDInput | null,
};

export type ModelQcStatusInput = {
  eq?: QcStatus | null,
  ne?: QcStatus | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateMetadataImmunopeptidomicsInput = {
  id: string,
  sampleId?: string | null,
  metaDataTemplateS3Path?: string | null,
  species?: string | null,
  subtypeOfAnalysis?: string | null,
  sampleType?: string | null,
  qcStatus?: QcStatus | null,
  glacierBackup?: boolean | null,
  rawDataS3?: boolean | null,
  tumorVolume?: number | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
  isControl?: boolean | null,
  barcodesMappedToControl?: Array< string | null > | null,
  barcode?: string | null,
  s3Path?: Array< string | null > | null,
  sampleMetadataImmunopeptidomicsListId?: string | null,
};

export type DeleteMetadataImmunopeptidomicsInput = {
  id: string,
};

export type CreateMetadataNanoStringInput = {
  id?: string | null,
  sampleId: string,
  metaDataTemplateS3Path: string,
  subtypeOfAnalysis?: string | null,
  species: string,
  sampleType: string,
  qcStatus: QcStatus,
  glacierBackup: boolean,
  rawDataS3: boolean,
  tumorVolume?: number | null,
  dv200Percentage?: number | null,
  concNanoGramPerMicroL?: number | null,
  rnaMassNanoGram?: number | null,
  rnaVolumeMicroL?: number | null,
  rin?: number | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
  isControl: boolean,
  barcodesMappedToControl?: Array< string | null > | null,
  barcode: string,
  s3Path: Array< string | null >,
  sampleMetadataNanoStringListId?: string | null,
};

export type ModelMetadataNanoStringConditionInput = {
  sampleId?: ModelIDInput | null,
  metaDataTemplateS3Path?: ModelStringInput | null,
  subtypeOfAnalysis?: ModelStringInput | null,
  species?: ModelStringInput | null,
  sampleType?: ModelStringInput | null,
  qcStatus?: ModelQcStatusInput | null,
  glacierBackup?: ModelBooleanInput | null,
  rawDataS3?: ModelBooleanInput | null,
  tumorVolume?: ModelFloatInput | null,
  dv200Percentage?: ModelFloatInput | null,
  concNanoGramPerMicroL?: ModelFloatInput | null,
  rnaMassNanoGram?: ModelFloatInput | null,
  rnaVolumeMicroL?: ModelFloatInput | null,
  rin?: ModelFloatInput | null,
  readGroups?: ModelStringInput | null,
  writeGroups?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  isControl?: ModelBooleanInput | null,
  barcodesMappedToControl?: ModelStringInput | null,
  barcode?: ModelStringInput | null,
  s3Path?: ModelStringInput | null,
  and?: Array< ModelMetadataNanoStringConditionInput | null > | null,
  or?: Array< ModelMetadataNanoStringConditionInput | null > | null,
  not?: ModelMetadataNanoStringConditionInput | null,
  sampleMetadataNanoStringListId?: ModelIDInput | null,
};

export type UpdateMetadataNanoStringInput = {
  id: string,
  sampleId?: string | null,
  metaDataTemplateS3Path?: string | null,
  subtypeOfAnalysis?: string | null,
  species?: string | null,
  sampleType?: string | null,
  qcStatus?: QcStatus | null,
  glacierBackup?: boolean | null,
  rawDataS3?: boolean | null,
  tumorVolume?: number | null,
  dv200Percentage?: number | null,
  concNanoGramPerMicroL?: number | null,
  rnaMassNanoGram?: number | null,
  rnaVolumeMicroL?: number | null,
  rin?: number | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
  isControl?: boolean | null,
  barcodesMappedToControl?: Array< string | null > | null,
  barcode?: string | null,
  s3Path?: Array< string | null > | null,
  sampleMetadataNanoStringListId?: string | null,
};

export type DeleteMetadataNanoStringInput = {
  id: string,
};

export type CreateMetadataRnaSeqInput = {
  id?: string | null,
  sampleId: string,
  metaDataTemplateS3Path: string,
  subtypeOfAnalysis?: string | null,
  species: string,
  sampleType: string,
  qcStatus: QcStatus,
  glacierBackup: boolean,
  rawDataS3: boolean,
  tumorVolume?: number | null,
  sequenceType: SequenceType,
  strandSpecific: string,
  UMI: boolean,
  adaptorsRemoved: boolean,
  adaptorsS3Path?: string | null,
  indexSequences?: Array< string | null > | null,
  concNanoGramPerMicroL?: number | null,
  rnaMassNanoGram?: number | null,
  rnaVolumeMicroL?: number | null,
  rin?: number | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
  isControl: boolean,
  barcodesMappedToControl?: Array< string | null > | null,
  barcode: string,
  s3Path: Array< string | null >,
  sampleMetadataRnaSeqListId?: string | null,
};

export type ModelMetadataRnaSeqConditionInput = {
  sampleId?: ModelIDInput | null,
  metaDataTemplateS3Path?: ModelStringInput | null,
  subtypeOfAnalysis?: ModelStringInput | null,
  species?: ModelStringInput | null,
  sampleType?: ModelStringInput | null,
  qcStatus?: ModelQcStatusInput | null,
  glacierBackup?: ModelBooleanInput | null,
  rawDataS3?: ModelBooleanInput | null,
  tumorVolume?: ModelFloatInput | null,
  sequenceType?: ModelSequenceTypeInput | null,
  strandSpecific?: ModelStringInput | null,
  UMI?: ModelBooleanInput | null,
  adaptorsRemoved?: ModelBooleanInput | null,
  adaptorsS3Path?: ModelStringInput | null,
  indexSequences?: ModelStringInput | null,
  concNanoGramPerMicroL?: ModelFloatInput | null,
  rnaMassNanoGram?: ModelFloatInput | null,
  rnaVolumeMicroL?: ModelFloatInput | null,
  rin?: ModelFloatInput | null,
  readGroups?: ModelStringInput | null,
  writeGroups?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  isControl?: ModelBooleanInput | null,
  barcodesMappedToControl?: ModelStringInput | null,
  barcode?: ModelStringInput | null,
  s3Path?: ModelStringInput | null,
  and?: Array< ModelMetadataRnaSeqConditionInput | null > | null,
  or?: Array< ModelMetadataRnaSeqConditionInput | null > | null,
  not?: ModelMetadataRnaSeqConditionInput | null,
  sampleMetadataRnaSeqListId?: ModelIDInput | null,
};

export type ModelSequenceTypeInput = {
  eq?: SequenceType | null,
  ne?: SequenceType | null,
};

export type UpdateMetadataRnaSeqInput = {
  id: string,
  sampleId?: string | null,
  metaDataTemplateS3Path?: string | null,
  subtypeOfAnalysis?: string | null,
  species?: string | null,
  sampleType?: string | null,
  qcStatus?: QcStatus | null,
  glacierBackup?: boolean | null,
  rawDataS3?: boolean | null,
  tumorVolume?: number | null,
  sequenceType?: SequenceType | null,
  strandSpecific?: string | null,
  UMI?: boolean | null,
  adaptorsRemoved?: boolean | null,
  adaptorsS3Path?: string | null,
  indexSequences?: Array< string | null > | null,
  concNanoGramPerMicroL?: number | null,
  rnaMassNanoGram?: number | null,
  rnaVolumeMicroL?: number | null,
  rin?: number | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
  isControl?: boolean | null,
  barcodesMappedToControl?: Array< string | null > | null,
  barcode?: string | null,
  s3Path?: Array< string | null > | null,
  sampleMetadataRnaSeqListId?: string | null,
};

export type DeleteMetadataRnaSeqInput = {
  id: string,
};

export type CreateTreatmentInput = {
  id?: string | null,
  metadataImmunopeptidomicsId?: string | null,
  metadataNanoStringId?: string | null,
  metadataRnaSeqId?: string | null,
  treatmentName: string,
  dose: number,
  doseUnit: string,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
  metadataImmunopeptidomicsTreatmentId?: string | null,
  metadataNanoStringTreatmentId?: string | null,
  metadataRnaSeqTreatmentId?: string | null,
};

export type ModelTreatmentConditionInput = {
  metadataImmunopeptidomicsId?: ModelIDInput | null,
  metadataNanoStringId?: ModelIDInput | null,
  metadataRnaSeqId?: ModelIDInput | null,
  treatmentName?: ModelStringInput | null,
  dose?: ModelFloatInput | null,
  doseUnit?: ModelStringInput | null,
  readGroups?: ModelStringInput | null,
  writeGroups?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelTreatmentConditionInput | null > | null,
  or?: Array< ModelTreatmentConditionInput | null > | null,
  not?: ModelTreatmentConditionInput | null,
  metadataImmunopeptidomicsTreatmentId?: ModelIDInput | null,
  metadataNanoStringTreatmentId?: ModelIDInput | null,
  metadataRnaSeqTreatmentId?: ModelIDInput | null,
};

export type UpdateTreatmentInput = {
  id: string,
  metadataImmunopeptidomicsId?: string | null,
  metadataNanoStringId?: string | null,
  metadataRnaSeqId?: string | null,
  treatmentName?: string | null,
  dose?: number | null,
  doseUnit?: string | null,
  readGroups?: Array< string | null > | null,
  writeGroups?: Array< string | null > | null,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
  metadataImmunopeptidomicsTreatmentId?: string | null,
  metadataNanoStringTreatmentId?: string | null,
  metadataRnaSeqTreatmentId?: string | null,
};

export type DeleteTreatmentInput = {
  id: string,
};

export type CreateLaunchablePipelinesInput = {
  id?: string | null,
  pipelineName?: string | null,
  pipelineVersion?: string | null,
  parameters?: string | null,
  description?: string | null,
  inputFileInfo?: string | null,
  supportedAnalyses?: Array< AnalysisType | null > | null,
  supportedSubtypeOfAnalyses?: Array< string | null > | null,
  gitRepo?: string | null,
  workflowId?: string | null,
  created?: string | null,
  updated?: string | null,
};

export type ModelLaunchablePipelinesConditionInput = {
  pipelineName?: ModelStringInput | null,
  pipelineVersion?: ModelStringInput | null,
  parameters?: ModelStringInput | null,
  description?: ModelStringInput | null,
  inputFileInfo?: ModelStringInput | null,
  supportedAnalyses?: ModelAnalysisTypeListInput | null,
  supportedSubtypeOfAnalyses?: ModelStringInput | null,
  gitRepo?: ModelStringInput | null,
  workflowId?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelLaunchablePipelinesConditionInput | null > | null,
  or?: Array< ModelLaunchablePipelinesConditionInput | null > | null,
  not?: ModelLaunchablePipelinesConditionInput | null,
};

export type ModelAnalysisTypeListInput = {
  eq?: Array< AnalysisType | null > | null,
  ne?: Array< AnalysisType | null > | null,
  contains?: AnalysisType | null,
  notContains?: AnalysisType | null,
};

export type UpdateLaunchablePipelinesInput = {
  id: string,
  pipelineName?: string | null,
  pipelineVersion?: string | null,
  parameters?: string | null,
  description?: string | null,
  inputFileInfo?: string | null,
  supportedAnalyses?: Array< AnalysisType | null > | null,
  supportedSubtypeOfAnalyses?: Array< string | null > | null,
  gitRepo?: string | null,
  workflowId?: string | null,
  created?: string | null,
  updated?: string | null,
};

export type DeleteLaunchablePipelinesInput = {
  id: string,
};

export type ModelUserConditionInput = {
  email?: ModelStringInput | null,
  organization?: ModelStringInput | null,
  gitToken?: ModelStringInput | null,
  gitName?: ModelStringInput | null,
  gitURL?: ModelStringInput | null,
  readGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
  owner?: ModelStringInput | null,
};

export type User = {
  __typename: "User",
  id: string,
  email?: string | null,
  organization?: string | null,
  gitToken?: string | null,
  gitName?: string | null,
  gitURL?: string | null,
  readGroups?: Array< string | null > | null,
  created: string,
  updated: string,
  owner?: string | null,
};

export type UpdateUserInput = {
  id: string,
  email?: string | null,
  organization?: string | null,
  gitToken?: string | null,
  gitName?: string | null,
  gitURL?: string | null,
  readGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreateUploadLogInput = {
  id?: string | null,
  uploadS3Path?: string | null,
  status?: string | null,
  readGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
};

export type ModelUploadLogConditionInput = {
  uploadS3Path?: ModelStringInput | null,
  status?: ModelStringInput | null,
  readGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelUploadLogConditionInput | null > | null,
  or?: Array< ModelUploadLogConditionInput | null > | null,
  not?: ModelUploadLogConditionInput | null,
};

export type UploadLog = {
  __typename: "UploadLog",
  id: string,
  uploadS3Path?: string | null,
  status?: string | null,
  readGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
};

export type UpdateUploadLogInput = {
  id: string,
  uploadS3Path?: string | null,
  status?: string | null,
  readGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
};

export type DeleteUploadLogInput = {
  id: string,
};

export type CreateVisualizationsInput = {
  id?: string | null,
  visualizationName?: string | null,
  description?: string | null,
  version?: string | null,
  deploymentPath?: string | null,
  validationSchema?: string | null,
  allowedExtensions?: Array< string | null > | null,
  supportedAnalyses?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
};

export type ModelVisualizationsConditionInput = {
  visualizationName?: ModelStringInput | null,
  description?: ModelStringInput | null,
  version?: ModelStringInput | null,
  deploymentPath?: ModelStringInput | null,
  validationSchema?: ModelStringInput | null,
  allowedExtensions?: ModelStringInput | null,
  supportedAnalyses?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelVisualizationsConditionInput | null > | null,
  or?: Array< ModelVisualizationsConditionInput | null > | null,
  not?: ModelVisualizationsConditionInput | null,
};

export type Visualizations = {
  __typename: "Visualizations",
  id: string,
  visualizationName?: string | null,
  description?: string | null,
  version?: string | null,
  deploymentPath?: string | null,
  validationSchema?: string | null,
  allowedExtensions?: Array< string | null > | null,
  supportedAnalyses?: Array< string | null > | null,
  created: string,
  updated: string,
};

export type UpdateVisualizationsInput = {
  id: string,
  visualizationName?: string | null,
  description?: string | null,
  version?: string | null,
  deploymentPath?: string | null,
  validationSchema?: string | null,
  allowedExtensions?: Array< string | null > | null,
  supportedAnalyses?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
};

export type DeleteVisualizationsInput = {
  id: string,
};

export type CreateVisualizationSessionInput = {
  id?: string | null,
  statuses?: string | null,
  data?: string | null,
  description?: string | null,
  created?: string | null,
  updated?: string | null,
};

export type ModelVisualizationSessionConditionInput = {
  statuses?: ModelStringInput | null,
  data?: ModelStringInput | null,
  description?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelVisualizationSessionConditionInput | null > | null,
  or?: Array< ModelVisualizationSessionConditionInput | null > | null,
  not?: ModelVisualizationSessionConditionInput | null,
  owner?: ModelStringInput | null,
};

export type VisualizationSession = {
  __typename: "VisualizationSession",
  id: string,
  statuses?: string | null,
  data?: string | null,
  description?: string | null,
  created: string,
  updated: string,
  owner?: string | null,
};

export type UpdateVisualizationSessionInput = {
  id: string,
  statuses?: string | null,
  data?: string | null,
  description?: string | null,
  created?: string | null,
  updated?: string | null,
};

export type DeleteVisualizationSessionInput = {
  id: string,
};

export type CreateDataLabEC2InstancesInput = {
  id?: string | null,
  creationTimestamp?: string | null,
  stoppedTimestamp?: string | null,
  startTimestamp?: string | null,
  terminatedTimestamp?: string | null,
  stoppedTimePeriod?: number | null,
  runningTimePeriod?: number | null,
  userEmail: string,
  status?: DataLabEC2InstancesStatus | null,
  type?: DataLabEC2InstancesType | null,
  organizationId?: string | null,
  organizationName?: string | null,
  instanceUrl?: string | null,
  instanceId?: string | null,
  infrastructureRelatedEntities?: string | null,
  instanceLaunchEvent?: string | null,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
};

export enum DataLabEC2InstancesStatus {
  INITIATED = "INITIATED",
  STOPPED = "STOPPED",
  RUNNING = "RUNNING",
  TERMINATED = "TERMINATED",
  SETTING_UP = "SETTING_UP",
  FAILED = "FAILED",
}


export enum DataLabEC2InstancesType {
  RSTUDIO = "RSTUDIO",
  JUPYTER = "JUPYTER",
}


export type ModelDataLabEC2InstancesConditionInput = {
  creationTimestamp?: ModelStringInput | null,
  stoppedTimestamp?: ModelStringInput | null,
  startTimestamp?: ModelStringInput | null,
  terminatedTimestamp?: ModelStringInput | null,
  stoppedTimePeriod?: ModelIntInput | null,
  runningTimePeriod?: ModelIntInput | null,
  userEmail?: ModelStringInput | null,
  status?: ModelDataLabEC2InstancesStatusInput | null,
  type?: ModelDataLabEC2InstancesTypeInput | null,
  organizationId?: ModelIDInput | null,
  organizationName?: ModelStringInput | null,
  instanceUrl?: ModelStringInput | null,
  instanceId?: ModelStringInput | null,
  infrastructureRelatedEntities?: ModelStringInput | null,
  instanceLaunchEvent?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelDataLabEC2InstancesConditionInput | null > | null,
  or?: Array< ModelDataLabEC2InstancesConditionInput | null > | null,
  not?: ModelDataLabEC2InstancesConditionInput | null,
  owner?: ModelStringInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelDataLabEC2InstancesStatusInput = {
  eq?: DataLabEC2InstancesStatus | null,
  ne?: DataLabEC2InstancesStatus | null,
};

export type ModelDataLabEC2InstancesTypeInput = {
  eq?: DataLabEC2InstancesType | null,
  ne?: DataLabEC2InstancesType | null,
};

export type DataLabEC2Instances = {
  __typename: "DataLabEC2Instances",
  id: string,
  creationTimestamp?: string | null,
  stoppedTimestamp?: string | null,
  startTimestamp?: string | null,
  terminatedTimestamp?: string | null,
  stoppedTimePeriod?: number | null,
  runningTimePeriod?: number | null,
  userEmail: string,
  status?: DataLabEC2InstancesStatus | null,
  type?: DataLabEC2InstancesType | null,
  dataLabInstanceSessions?: ModelDataLabEC2InstanceSessionConnection | null,
  dataLabInstanceStatusChanges?: ModelDataLabEC2InstanceStatusChangeConnection | null,
  organizationId?: string | null,
  organizationName?: string | null,
  instanceUrl?: string | null,
  instanceId?: string | null,
  infrastructureRelatedEntities?: string | null,
  instanceLaunchEvent?: string | null,
  adminGroups?: Array< string | null > | null,
  created: string,
  updated: string,
  owner?: string | null,
};

export type ModelDataLabEC2InstanceSessionConnection = {
  __typename: "ModelDataLabEC2InstanceSessionConnection",
  items:  Array<DataLabEC2InstanceSession | null >,
  nextToken?: string | null,
};

export type DataLabEC2InstanceSession = {
  __typename: "DataLabEC2InstanceSession",
  id: string,
  dataLabInstanceId: string,
  dataLabInstance?: DataLabEC2Instances | null,
  s3Path?: string | null,
  instanceLaunchEvent?: string | null,
  status?: DataLabEC2InstanceSessionStatus | null,
  title?: string | null,
  description?: string | null,
  created: string,
  updated: string,
  dataLabEC2InstancesDataLabInstanceSessionsId?: string | null,
  owner?: string | null,
};

export enum DataLabEC2InstanceSessionStatus {
  LOADING = "LOADING",
  LOADING_FAILED = "LOADING_FAILED",
  LOADED = "LOADED",
  STAND_BY = "STAND_BY",
  SAVING = "SAVING",
  SAVING_FAILED = "SAVING_FAILED",
  SAVED = "SAVED",
}


export type ModelDataLabEC2InstanceStatusChangeConnection = {
  __typename: "ModelDataLabEC2InstanceStatusChangeConnection",
  items:  Array<DataLabEC2InstanceStatusChange | null >,
  nextToken?: string | null,
};

export type DataLabEC2InstanceStatusChange = {
  __typename: "DataLabEC2InstanceStatusChange",
  id: string,
  dataLabInstanceId: string,
  dataLabInstance?: DataLabEC2Instances | null,
  organizationId?: string | null,
  previousStatus?: DataLabEC2InstancesStatus | null,
  newStatus?: DataLabEC2InstancesStatus | null,
  initiatedBy?: string | null,
  action?: string | null,
  performedBy?: string | null,
  additionalNotes?: string | null,
  created: string,
  updated: string,
  dataLabEC2InstancesDataLabInstanceStatusChangesId?: string | null,
  owner?: string | null,
};

export type UpdateDataLabEC2InstancesInput = {
  id: string,
  creationTimestamp?: string | null,
  stoppedTimestamp?: string | null,
  startTimestamp?: string | null,
  terminatedTimestamp?: string | null,
  stoppedTimePeriod?: number | null,
  runningTimePeriod?: number | null,
  userEmail?: string | null,
  status?: DataLabEC2InstancesStatus | null,
  type?: DataLabEC2InstancesType | null,
  organizationId?: string | null,
  organizationName?: string | null,
  instanceUrl?: string | null,
  instanceId?: string | null,
  infrastructureRelatedEntities?: string | null,
  instanceLaunchEvent?: string | null,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
};

export type DeleteDataLabEC2InstancesInput = {
  id: string,
};

export type CreateDataLabEC2InstanceSessionInput = {
  id?: string | null,
  dataLabInstanceId: string,
  s3Path?: string | null,
  instanceLaunchEvent?: string | null,
  status?: DataLabEC2InstanceSessionStatus | null,
  title?: string | null,
  description?: string | null,
  created?: string | null,
  updated?: string | null,
  dataLabEC2InstancesDataLabInstanceSessionsId?: string | null,
};

export type ModelDataLabEC2InstanceSessionConditionInput = {
  dataLabInstanceId?: ModelIDInput | null,
  s3Path?: ModelStringInput | null,
  instanceLaunchEvent?: ModelStringInput | null,
  status?: ModelDataLabEC2InstanceSessionStatusInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelDataLabEC2InstanceSessionConditionInput | null > | null,
  or?: Array< ModelDataLabEC2InstanceSessionConditionInput | null > | null,
  not?: ModelDataLabEC2InstanceSessionConditionInput | null,
  dataLabEC2InstancesDataLabInstanceSessionsId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
};

export type ModelDataLabEC2InstanceSessionStatusInput = {
  eq?: DataLabEC2InstanceSessionStatus | null,
  ne?: DataLabEC2InstanceSessionStatus | null,
};

export type UpdateDataLabEC2InstanceSessionInput = {
  id: string,
  dataLabInstanceId?: string | null,
  s3Path?: string | null,
  instanceLaunchEvent?: string | null,
  status?: DataLabEC2InstanceSessionStatus | null,
  title?: string | null,
  description?: string | null,
  created?: string | null,
  updated?: string | null,
  dataLabEC2InstancesDataLabInstanceSessionsId?: string | null,
};

export type DeleteDataLabEC2InstanceSessionInput = {
  id: string,
};

export type CreateDataLabEC2InstanceStatusChangeInput = {
  id?: string | null,
  dataLabInstanceId: string,
  organizationId?: string | null,
  previousStatus?: DataLabEC2InstancesStatus | null,
  newStatus?: DataLabEC2InstancesStatus | null,
  initiatedBy?: string | null,
  action?: string | null,
  performedBy?: string | null,
  additionalNotes?: string | null,
  created?: string | null,
  updated?: string | null,
  dataLabEC2InstancesDataLabInstanceStatusChangesId?: string | null,
};

export type ModelDataLabEC2InstanceStatusChangeConditionInput = {
  dataLabInstanceId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  previousStatus?: ModelDataLabEC2InstancesStatusInput | null,
  newStatus?: ModelDataLabEC2InstancesStatusInput | null,
  initiatedBy?: ModelStringInput | null,
  action?: ModelStringInput | null,
  performedBy?: ModelStringInput | null,
  additionalNotes?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelDataLabEC2InstanceStatusChangeConditionInput | null > | null,
  or?: Array< ModelDataLabEC2InstanceStatusChangeConditionInput | null > | null,
  not?: ModelDataLabEC2InstanceStatusChangeConditionInput | null,
  dataLabEC2InstancesDataLabInstanceStatusChangesId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
};

export type UpdateDataLabEC2InstanceStatusChangeInput = {
  id: string,
  dataLabInstanceId?: string | null,
  organizationId?: string | null,
  previousStatus?: DataLabEC2InstancesStatus | null,
  newStatus?: DataLabEC2InstancesStatus | null,
  initiatedBy?: string | null,
  action?: string | null,
  performedBy?: string | null,
  additionalNotes?: string | null,
  created?: string | null,
  updated?: string | null,
  dataLabEC2InstancesDataLabInstanceStatusChangesId?: string | null,
};

export type DeleteDataLabEC2InstanceStatusChangeInput = {
  id: string,
};

export type CreateUploadBundleInput = {
  id?: string | null,
  studyPhaseId?: string | null,
  bundleLog?: string | null,
  validationErrors?: string | null,
  status?: string | null,
  userEmail?: string | null,
  adminGroups?: Array< string | null > | null,
  completionTime?: string | null,
  created?: string | null,
  updated?: string | null,
  studyPhaseUploadBundlesId?: string | null,
  uploadBundleUploadBundleAnalysisSessionId?: string | null,
};

export type ModelUploadBundleConditionInput = {
  studyPhaseId?: ModelIDInput | null,
  bundleLog?: ModelStringInput | null,
  validationErrors?: ModelStringInput | null,
  status?: ModelStringInput | null,
  userEmail?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  completionTime?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelUploadBundleConditionInput | null > | null,
  or?: Array< ModelUploadBundleConditionInput | null > | null,
  not?: ModelUploadBundleConditionInput | null,
  studyPhaseUploadBundlesId?: ModelIDInput | null,
  uploadBundleUploadBundleAnalysisSessionId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
};

export type UpdateUploadBundleInput = {
  id: string,
  studyPhaseId?: string | null,
  bundleLog?: string | null,
  validationErrors?: string | null,
  status?: string | null,
  userEmail?: string | null,
  adminGroups?: Array< string | null > | null,
  completionTime?: string | null,
  created?: string | null,
  updated?: string | null,
  studyPhaseUploadBundlesId?: string | null,
  uploadBundleUploadBundleAnalysisSessionId?: string | null,
};

export type DeleteUploadBundleInput = {
  id: string,
};

export type CreateUploadBundleAnalysisSessionInput = {
  id?: string | null,
  metadataFileS3Path?: string | null,
  step?: string | null,
  listOfRawFileNames?: Array< string | null > | null,
  analysisType: AnalysisType,
  subtypeOfAnalysis: string,
  studyPhaseId: string,
  metadataHelperLambdaResponseS3Path?: string | null,
  barcodeToFilesToUploadS3Path?: string | null,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
};

export type ModelUploadBundleAnalysisSessionConditionInput = {
  metadataFileS3Path?: ModelStringInput | null,
  step?: ModelStringInput | null,
  listOfRawFileNames?: ModelStringInput | null,
  analysisType?: ModelAnalysisTypeInput | null,
  subtypeOfAnalysis?: ModelStringInput | null,
  studyPhaseId?: ModelStringInput | null,
  metadataHelperLambdaResponseS3Path?: ModelStringInput | null,
  barcodeToFilesToUploadS3Path?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelUploadBundleAnalysisSessionConditionInput | null > | null,
  or?: Array< ModelUploadBundleAnalysisSessionConditionInput | null > | null,
  not?: ModelUploadBundleAnalysisSessionConditionInput | null,
  owner?: ModelStringInput | null,
};

export type UpdateUploadBundleAnalysisSessionInput = {
  id: string,
  metadataFileS3Path?: string | null,
  step?: string | null,
  listOfRawFileNames?: Array< string | null > | null,
  analysisType?: AnalysisType | null,
  subtypeOfAnalysis?: string | null,
  studyPhaseId?: string | null,
  metadataHelperLambdaResponseS3Path?: string | null,
  barcodeToFilesToUploadS3Path?: string | null,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
};

export type DeleteUploadBundleAnalysisSessionInput = {
  id: string,
};

export type CreateUploadSampleInput = {
  id?: string | null,
  uploadBundleId: string,
  sampleId: string,
  barcode: string,
  sampleLog?: string | null,
  writeMetadata: boolean,
  status?: string | null,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
  uploadBundleUploadSamplesId?: string | null,
};

export type ModelUploadSampleConditionInput = {
  uploadBundleId?: ModelIDInput | null,
  sampleId?: ModelIDInput | null,
  barcode?: ModelStringInput | null,
  sampleLog?: ModelStringInput | null,
  writeMetadata?: ModelBooleanInput | null,
  status?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelUploadSampleConditionInput | null > | null,
  or?: Array< ModelUploadSampleConditionInput | null > | null,
  not?: ModelUploadSampleConditionInput | null,
  uploadBundleUploadSamplesId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
};

export type UpdateUploadSampleInput = {
  id: string,
  uploadBundleId?: string | null,
  sampleId?: string | null,
  barcode?: string | null,
  sampleLog?: string | null,
  writeMetadata?: boolean | null,
  status?: string | null,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
  uploadBundleUploadSamplesId?: string | null,
};

export type DeleteUploadSampleInput = {
  id: string,
};

export type CreateUploadSampleAnalysisSessionInput = {
  id?: string | null,
  uploadBundleAnalysisSessionId: string,
  sampleId: string,
  rowFromMetaDataFileMappedToSample: string,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
  uploadBundleAnalysisSessionUploadSampleAnalysisSessionId?: string | null,
};

export type ModelUploadSampleAnalysisSessionConditionInput = {
  uploadBundleAnalysisSessionId?: ModelIDInput | null,
  sampleId?: ModelIDInput | null,
  rowFromMetaDataFileMappedToSample?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelUploadSampleAnalysisSessionConditionInput | null > | null,
  or?: Array< ModelUploadSampleAnalysisSessionConditionInput | null > | null,
  not?: ModelUploadSampleAnalysisSessionConditionInput | null,
  uploadBundleAnalysisSessionUploadSampleAnalysisSessionId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
};

export type UpdateUploadSampleAnalysisSessionInput = {
  id: string,
  uploadBundleAnalysisSessionId?: string | null,
  sampleId?: string | null,
  rowFromMetaDataFileMappedToSample?: string | null,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
  uploadBundleAnalysisSessionUploadSampleAnalysisSessionId?: string | null,
};

export type DeleteUploadSampleAnalysisSessionInput = {
  id: string,
};

export type CreateUploadFileInput = {
  id?: string | null,
  uploadSampleId?: string | null,
  uploadBundleId?: string | null,
  multipartUploadId?: string | null,
  status?: UploadFileStatus | null,
  toSkipUpload: boolean,
  fileName: string,
  fileType: string,
  s3Path: string,
  size?: number | null,
  fileLog?: string | null,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
  uploadBundleUploadFilesId?: string | null,
  uploadSampleUploadFilesId?: string | null,
};

export type ModelUploadFileConditionInput = {
  uploadSampleId?: ModelIDInput | null,
  uploadBundleId?: ModelIDInput | null,
  multipartUploadId?: ModelStringInput | null,
  status?: ModelUploadFileStatusInput | null,
  toSkipUpload?: ModelBooleanInput | null,
  fileName?: ModelStringInput | null,
  fileType?: ModelStringInput | null,
  s3Path?: ModelStringInput | null,
  size?: ModelIntInput | null,
  fileLog?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelUploadFileConditionInput | null > | null,
  or?: Array< ModelUploadFileConditionInput | null > | null,
  not?: ModelUploadFileConditionInput | null,
  uploadBundleUploadFilesId?: ModelIDInput | null,
  uploadSampleUploadFilesId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
};

export type ModelUploadFileStatusInput = {
  eq?: UploadFileStatus | null,
  ne?: UploadFileStatus | null,
};

export type UpdateUploadFileInput = {
  id: string,
  uploadSampleId?: string | null,
  uploadBundleId?: string | null,
  multipartUploadId?: string | null,
  status?: UploadFileStatus | null,
  toSkipUpload?: boolean | null,
  fileName?: string | null,
  fileType?: string | null,
  s3Path?: string | null,
  size?: number | null,
  fileLog?: string | null,
  adminGroups?: Array< string | null > | null,
  created?: string | null,
  updated?: string | null,
  uploadBundleUploadFilesId?: string | null,
  uploadSampleUploadFilesId?: string | null,
};

export type DeleteUploadFileInput = {
  id: string,
};

export type CreateOrganizationLaunchablePipelinesInput = {
  id?: string | null,
  organizationId: string,
  launchablePipelinesId: string,
};

export type ModelOrganizationLaunchablePipelinesConditionInput = {
  organizationId?: ModelIDInput | null,
  launchablePipelinesId?: ModelIDInput | null,
  and?: Array< ModelOrganizationLaunchablePipelinesConditionInput | null > | null,
  or?: Array< ModelOrganizationLaunchablePipelinesConditionInput | null > | null,
  not?: ModelOrganizationLaunchablePipelinesConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateOrganizationLaunchablePipelinesInput = {
  id: string,
  organizationId?: string | null,
  launchablePipelinesId?: string | null,
};

export type DeleteOrganizationLaunchablePipelinesInput = {
  id: string,
};

export type ModelOrganizationFilterInput = {
  id?: ModelIDInput | null,
  organizationName?: ModelStringInput | null,
  organizationCode?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  notificationEmail?: ModelStringInput | null,
  description?: ModelStringInput | null,
  organizationAnswers?: ModelStringInput | null,
  url?: ModelStringInput | null,
  s3Path?: ModelStringInput | null,
  readGroups?: ModelStringInput | null,
  writeGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelOrganizationFilterInput | null > | null,
  or?: Array< ModelOrganizationFilterInput | null > | null,
  not?: ModelOrganizationFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelOrganizationConnection = {
  __typename: "ModelOrganizationConnection",
  items:  Array<Organization | null >,
  nextToken?: string | null,
};

export type ModelStudyFilterInput = {
  id?: ModelIDInput | null,
  studyName?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  description?: ModelStringInput | null,
  studyCode?: ModelStringInput | null,
  studyAnswers?: ModelStringInput | null,
  s3Path?: ModelStringInput | null,
  croGroups?: ModelStringInput | null,
  readGroups?: ModelStringInput | null,
  writeGroups?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelStudyFilterInput | null > | null,
  or?: Array< ModelStudyFilterInput | null > | null,
  not?: ModelStudyFilterInput | null,
  organizationStudiesId?: ModelIDInput | null,
};

export type ModelStudyPhaseFilterInput = {
  id?: ModelIDInput | null,
  studyId?: ModelIDInput | null,
  metaDataValidationSchemaTemplateId?: ModelIDInput | null,
  validationSchema?: ModelStringInput | null,
  description?: ModelStringInput | null,
  studyPhaseName?: ModelStringInput | null,
  studyPhaseAnswers?: ModelStringInput | null,
  experimentSpecificVariables?: ModelStringInput | null,
  sampleTypes?: ModelStringInput | null,
  specimens?: ModelStringInput | null,
  species?: ModelStringInput | null,
  s3Path?: ModelStringInput | null,
  groupNames?: ModelStringInput | null,
  labAssayTechnologies?: ModelStringInput | null,
  phase?: ModelPhaseInput | null,
  readGroups?: ModelStringInput | null,
  writeGroups?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  croGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelStudyPhaseFilterInput | null > | null,
  or?: Array< ModelStudyPhaseFilterInput | null > | null,
  not?: ModelStudyPhaseFilterInput | null,
  studyStudyPhasesId?: ModelIDInput | null,
  metaDataValidationSchemaTemplateStudyPhasesId?: ModelIDInput | null,
};

export type ModelEntityQuestionsFilterInput = {
  id?: ModelIDInput | null,
  entityType?: ModelEntityTypeInput | null,
  questions?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelEntityQuestionsFilterInput | null > | null,
  or?: Array< ModelEntityQuestionsFilterInput | null > | null,
  not?: ModelEntityQuestionsFilterInput | null,
};

export type ModelEntityQuestionsConnection = {
  __typename: "ModelEntityQuestionsConnection",
  items:  Array<EntityQuestions | null >,
  nextToken?: string | null,
};

export type ModelMetaDataValidationSchemaTemplateFilterInput = {
  id?: ModelIDInput | null,
  version?: ModelStringInput | null,
  description?: ModelStringInput | null,
  templateFiles?: ModelStringInput | null,
  validationSchemaTemplate?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelMetaDataValidationSchemaTemplateFilterInput | null > | null,
  or?: Array< ModelMetaDataValidationSchemaTemplateFilterInput | null > | null,
  not?: ModelMetaDataValidationSchemaTemplateFilterInput | null,
};

export type ModelMetaDataValidationSchemaTemplateConnection = {
  __typename: "ModelMetaDataValidationSchemaTemplateConnection",
  items:  Array<MetaDataValidationSchemaTemplate | null >,
  nextToken?: string | null,
};

export type ModelGroupFilterInput = {
  id?: ModelIDInput | null,
  studyPhaseId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  groupName?: ModelStringInput | null,
  s3Path?: ModelStringInput | null,
  readGroups?: ModelStringInput | null,
  writeGroups?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelGroupFilterInput | null > | null,
  or?: Array< ModelGroupFilterInput | null > | null,
  not?: ModelGroupFilterInput | null,
  studyPhaseGroupsId?: ModelIDInput | null,
};

export type ModelPatientFilterInput = {
  id?: ModelIDInput | null,
  groupId?: ModelIDInput | null,
  patientStudyPhaseId?: ModelStringInput | null,
  patientStudyId?: ModelStringInput | null,
  s3Path?: ModelStringInput | null,
  readGroups?: ModelStringInput | null,
  writeGroups?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelPatientFilterInput | null > | null,
  or?: Array< ModelPatientFilterInput | null > | null,
  not?: ModelPatientFilterInput | null,
  groupPatientsId?: ModelIDInput | null,
};

export type ModelTimepointFilterInput = {
  id?: ModelIDInput | null,
  patientId?: ModelIDInput | null,
  timepointName?: ModelStringInput | null,
  s3Path?: ModelStringInput | null,
  readGroups?: ModelStringInput | null,
  writeGroups?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelTimepointFilterInput | null > | null,
  or?: Array< ModelTimepointFilterInput | null > | null,
  not?: ModelTimepointFilterInput | null,
  patientTimepointsId?: ModelIDInput | null,
};

export type ModelSampleFilterInput = {
  id?: ModelIDInput | null,
  timepointId?: ModelIDInput | null,
  studyPhaseId?: ModelIDInput | null,
  BatchId?: ModelIDInput | null,
  domainSampleId?: ModelStringInput | null,
  experimentSpecificVariables?: ModelStringInput | null,
  collectionDate?: ModelStringInput | null,
  processingDate?: ModelStringInput | null,
  readGroups?: ModelStringInput | null,
  writeGroups?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelSampleFilterInput | null > | null,
  or?: Array< ModelSampleFilterInput | null > | null,
  not?: ModelSampleFilterInput | null,
  studyPhaseSamplesId?: ModelIDInput | null,
  timepointSamplesId?: ModelIDInput | null,
  batchSamplesId?: ModelIDInput | null,
};

export type ModelBatchFilterInput = {
  id?: ModelIDInput | null,
  batchName?: ModelStringInput | null,
  batchAnnotations?: ModelStringInput | null,
  batchGroups?: ModelStringInput | null,
  annotations?: ModelStringInput | null,
  controlSamples?: ModelStringInput | null,
  filters?: ModelStringInput | null,
  description?: ModelStringInput | null,
  studyId?: ModelIDInput | null,
  studyPhaseId?: ModelIDInput | null,
  readGroups?: ModelStringInput | null,
  writeGroups?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelBatchFilterInput | null > | null,
  or?: Array< ModelBatchFilterInput | null > | null,
  not?: ModelBatchFilterInput | null,
  studyBatchesId?: ModelIDInput | null,
  studyPhaseBatchesId?: ModelIDInput | null,
};

export type ModelPipelineFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  status?: ModelpipelineStatusInput | null,
  description?: ModelStringInput | null,
  s3Event?: ModelStringInput | null,
  s3ParamsFile?: ModelStringInput | null,
  s3Results?: ModelStringInput | null,
  visFileInfoS3path?: ModelStringInput | null,
  s3NfLog?: ModelStringInput | null,
  s3NfTasksLogs?: ModelStringInput | null,
  analysisType?: ModelAnalysisTypeInput | null,
  subtypeOfAnalysis?: ModelStringInput | null,
  parameters?: ModelStringInput | null,
  studyId?: ModelIDInput | null,
  studyPhaseId?: ModelIDInput | null,
  scheduledPipeline?: ModelBooleanInput | null,
  scheduledDateTime?: ModelStringInput | null,
  launchablePipelineId?: ModelIDInput | null,
  batchId?: ModelIDInput | null,
  runId?: ModelStringInput | null,
  userEmail?: ModelStringInput | null,
  pipelineStarted?: ModelStringInput | null,
  pipelineCompleted?: ModelStringInput | null,
  readGroups?: ModelStringInput | null,
  writeGroups?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelPipelineFilterInput | null > | null,
  or?: Array< ModelPipelineFilterInput | null > | null,
  not?: ModelPipelineFilterInput | null,
  studyPipelinesId?: ModelIDInput | null,
  studyPhasePipelinesId?: ModelIDInput | null,
  batchPipelinesId?: ModelIDInput | null,
  launchablePipelinesPipelinesId?: ModelIDInput | null,
};

export type ModelAuxiliaryAnalysisFilterInput = {
  id?: ModelIDInput | null,
  sampleId?: ModelIDInput | null,
  auxiliaryFileS3Path?: ModelStringInput | null,
  vendorId?: ModelStringInput | null,
  specimenType?: ModelStringInput | null,
  parameters?: ModelStringInput | null,
  collectionDate?: ModelStringInput | null,
  readGroups?: ModelStringInput | null,
  writeGroups?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelAuxiliaryAnalysisFilterInput | null > | null,
  or?: Array< ModelAuxiliaryAnalysisFilterInput | null > | null,
  not?: ModelAuxiliaryAnalysisFilterInput | null,
  sampleAuxiliaryAnalysislListId?: ModelIDInput | null,
};

export type ModelMetadataImmunopeptidomicsFilterInput = {
  id?: ModelIDInput | null,
  sampleId?: ModelIDInput | null,
  metaDataTemplateS3Path?: ModelStringInput | null,
  species?: ModelStringInput | null,
  subtypeOfAnalysis?: ModelStringInput | null,
  sampleType?: ModelStringInput | null,
  qcStatus?: ModelQcStatusInput | null,
  glacierBackup?: ModelBooleanInput | null,
  rawDataS3?: ModelBooleanInput | null,
  tumorVolume?: ModelFloatInput | null,
  readGroups?: ModelStringInput | null,
  writeGroups?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  isControl?: ModelBooleanInput | null,
  barcodesMappedToControl?: ModelStringInput | null,
  barcode?: ModelStringInput | null,
  s3Path?: ModelStringInput | null,
  and?: Array< ModelMetadataImmunopeptidomicsFilterInput | null > | null,
  or?: Array< ModelMetadataImmunopeptidomicsFilterInput | null > | null,
  not?: ModelMetadataImmunopeptidomicsFilterInput | null,
  sampleMetadataImmunopeptidomicsListId?: ModelIDInput | null,
};

export type ModelMetadataNanoStringFilterInput = {
  id?: ModelIDInput | null,
  sampleId?: ModelIDInput | null,
  metaDataTemplateS3Path?: ModelStringInput | null,
  subtypeOfAnalysis?: ModelStringInput | null,
  species?: ModelStringInput | null,
  sampleType?: ModelStringInput | null,
  qcStatus?: ModelQcStatusInput | null,
  glacierBackup?: ModelBooleanInput | null,
  rawDataS3?: ModelBooleanInput | null,
  tumorVolume?: ModelFloatInput | null,
  dv200Percentage?: ModelFloatInput | null,
  concNanoGramPerMicroL?: ModelFloatInput | null,
  rnaMassNanoGram?: ModelFloatInput | null,
  rnaVolumeMicroL?: ModelFloatInput | null,
  rin?: ModelFloatInput | null,
  readGroups?: ModelStringInput | null,
  writeGroups?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  isControl?: ModelBooleanInput | null,
  barcodesMappedToControl?: ModelStringInput | null,
  barcode?: ModelStringInput | null,
  s3Path?: ModelStringInput | null,
  and?: Array< ModelMetadataNanoStringFilterInput | null > | null,
  or?: Array< ModelMetadataNanoStringFilterInput | null > | null,
  not?: ModelMetadataNanoStringFilterInput | null,
  sampleMetadataNanoStringListId?: ModelIDInput | null,
};

export type ModelMetadataRnaSeqFilterInput = {
  id?: ModelIDInput | null,
  sampleId?: ModelIDInput | null,
  metaDataTemplateS3Path?: ModelStringInput | null,
  subtypeOfAnalysis?: ModelStringInput | null,
  species?: ModelStringInput | null,
  sampleType?: ModelStringInput | null,
  qcStatus?: ModelQcStatusInput | null,
  glacierBackup?: ModelBooleanInput | null,
  rawDataS3?: ModelBooleanInput | null,
  tumorVolume?: ModelFloatInput | null,
  sequenceType?: ModelSequenceTypeInput | null,
  strandSpecific?: ModelStringInput | null,
  UMI?: ModelBooleanInput | null,
  adaptorsRemoved?: ModelBooleanInput | null,
  adaptorsS3Path?: ModelStringInput | null,
  indexSequences?: ModelStringInput | null,
  concNanoGramPerMicroL?: ModelFloatInput | null,
  rnaMassNanoGram?: ModelFloatInput | null,
  rnaVolumeMicroL?: ModelFloatInput | null,
  rin?: ModelFloatInput | null,
  readGroups?: ModelStringInput | null,
  writeGroups?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  isControl?: ModelBooleanInput | null,
  barcodesMappedToControl?: ModelStringInput | null,
  barcode?: ModelStringInput | null,
  s3Path?: ModelStringInput | null,
  and?: Array< ModelMetadataRnaSeqFilterInput | null > | null,
  or?: Array< ModelMetadataRnaSeqFilterInput | null > | null,
  not?: ModelMetadataRnaSeqFilterInput | null,
  sampleMetadataRnaSeqListId?: ModelIDInput | null,
};

export type ModelTreatmentFilterInput = {
  id?: ModelIDInput | null,
  metadataImmunopeptidomicsId?: ModelIDInput | null,
  metadataNanoStringId?: ModelIDInput | null,
  metadataRnaSeqId?: ModelIDInput | null,
  treatmentName?: ModelStringInput | null,
  dose?: ModelFloatInput | null,
  doseUnit?: ModelStringInput | null,
  readGroups?: ModelStringInput | null,
  writeGroups?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelTreatmentFilterInput | null > | null,
  or?: Array< ModelTreatmentFilterInput | null > | null,
  not?: ModelTreatmentFilterInput | null,
  metadataImmunopeptidomicsTreatmentId?: ModelIDInput | null,
  metadataNanoStringTreatmentId?: ModelIDInput | null,
  metadataRnaSeqTreatmentId?: ModelIDInput | null,
};

export type ModelLaunchablePipelinesFilterInput = {
  id?: ModelIDInput | null,
  pipelineName?: ModelStringInput | null,
  pipelineVersion?: ModelStringInput | null,
  parameters?: ModelStringInput | null,
  description?: ModelStringInput | null,
  inputFileInfo?: ModelStringInput | null,
  supportedAnalyses?: ModelAnalysisTypeListInput | null,
  supportedSubtypeOfAnalyses?: ModelStringInput | null,
  gitRepo?: ModelStringInput | null,
  workflowId?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelLaunchablePipelinesFilterInput | null > | null,
  or?: Array< ModelLaunchablePipelinesFilterInput | null > | null,
  not?: ModelLaunchablePipelinesFilterInput | null,
};

export type ModelLaunchablePipelinesConnection = {
  __typename: "ModelLaunchablePipelinesConnection",
  items:  Array<LaunchablePipelines | null >,
  nextToken?: string | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  organization?: ModelStringInput | null,
  gitToken?: ModelStringInput | null,
  gitName?: ModelStringInput | null,
  gitURL?: ModelStringInput | null,
  readGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelUploadLogFilterInput = {
  id?: ModelIDInput | null,
  uploadS3Path?: ModelStringInput | null,
  status?: ModelStringInput | null,
  readGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelUploadLogFilterInput | null > | null,
  or?: Array< ModelUploadLogFilterInput | null > | null,
  not?: ModelUploadLogFilterInput | null,
};

export type ModelUploadLogConnection = {
  __typename: "ModelUploadLogConnection",
  items:  Array<UploadLog | null >,
  nextToken?: string | null,
};

export type ModelVisualizationsFilterInput = {
  id?: ModelIDInput | null,
  visualizationName?: ModelStringInput | null,
  description?: ModelStringInput | null,
  version?: ModelStringInput | null,
  deploymentPath?: ModelStringInput | null,
  validationSchema?: ModelStringInput | null,
  allowedExtensions?: ModelStringInput | null,
  supportedAnalyses?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelVisualizationsFilterInput | null > | null,
  or?: Array< ModelVisualizationsFilterInput | null > | null,
  not?: ModelVisualizationsFilterInput | null,
};

export type ModelVisualizationsConnection = {
  __typename: "ModelVisualizationsConnection",
  items:  Array<Visualizations | null >,
  nextToken?: string | null,
};

export type ModelVisualizationSessionFilterInput = {
  id?: ModelIDInput | null,
  statuses?: ModelStringInput | null,
  data?: ModelStringInput | null,
  description?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelVisualizationSessionFilterInput | null > | null,
  or?: Array< ModelVisualizationSessionFilterInput | null > | null,
  not?: ModelVisualizationSessionFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelVisualizationSessionConnection = {
  __typename: "ModelVisualizationSessionConnection",
  items:  Array<VisualizationSession | null >,
  nextToken?: string | null,
};

export type ModelDataLabEC2InstancesFilterInput = {
  id?: ModelIDInput | null,
  creationTimestamp?: ModelStringInput | null,
  stoppedTimestamp?: ModelStringInput | null,
  startTimestamp?: ModelStringInput | null,
  terminatedTimestamp?: ModelStringInput | null,
  stoppedTimePeriod?: ModelIntInput | null,
  runningTimePeriod?: ModelIntInput | null,
  userEmail?: ModelStringInput | null,
  status?: ModelDataLabEC2InstancesStatusInput | null,
  type?: ModelDataLabEC2InstancesTypeInput | null,
  organizationId?: ModelIDInput | null,
  organizationName?: ModelStringInput | null,
  instanceUrl?: ModelStringInput | null,
  instanceId?: ModelStringInput | null,
  infrastructureRelatedEntities?: ModelStringInput | null,
  instanceLaunchEvent?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelDataLabEC2InstancesFilterInput | null > | null,
  or?: Array< ModelDataLabEC2InstancesFilterInput | null > | null,
  not?: ModelDataLabEC2InstancesFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelDataLabEC2InstancesConnection = {
  __typename: "ModelDataLabEC2InstancesConnection",
  items:  Array<DataLabEC2Instances | null >,
  nextToken?: string | null,
};

export type ModelDataLabEC2InstanceSessionFilterInput = {
  id?: ModelIDInput | null,
  dataLabInstanceId?: ModelIDInput | null,
  s3Path?: ModelStringInput | null,
  instanceLaunchEvent?: ModelStringInput | null,
  status?: ModelDataLabEC2InstanceSessionStatusInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelDataLabEC2InstanceSessionFilterInput | null > | null,
  or?: Array< ModelDataLabEC2InstanceSessionFilterInput | null > | null,
  not?: ModelDataLabEC2InstanceSessionFilterInput | null,
  dataLabEC2InstancesDataLabInstanceSessionsId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
};

export type ModelDataLabEC2InstanceStatusChangeFilterInput = {
  id?: ModelIDInput | null,
  dataLabInstanceId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  previousStatus?: ModelDataLabEC2InstancesStatusInput | null,
  newStatus?: ModelDataLabEC2InstancesStatusInput | null,
  initiatedBy?: ModelStringInput | null,
  action?: ModelStringInput | null,
  performedBy?: ModelStringInput | null,
  additionalNotes?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelDataLabEC2InstanceStatusChangeFilterInput | null > | null,
  or?: Array< ModelDataLabEC2InstanceStatusChangeFilterInput | null > | null,
  not?: ModelDataLabEC2InstanceStatusChangeFilterInput | null,
  dataLabEC2InstancesDataLabInstanceStatusChangesId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
};

export type ModelUploadBundleFilterInput = {
  id?: ModelIDInput | null,
  studyPhaseId?: ModelIDInput | null,
  bundleLog?: ModelStringInput | null,
  validationErrors?: ModelStringInput | null,
  status?: ModelStringInput | null,
  userEmail?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  completionTime?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelUploadBundleFilterInput | null > | null,
  or?: Array< ModelUploadBundleFilterInput | null > | null,
  not?: ModelUploadBundleFilterInput | null,
  studyPhaseUploadBundlesId?: ModelIDInput | null,
  uploadBundleUploadBundleAnalysisSessionId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
};

export type ModelUploadBundleAnalysisSessionFilterInput = {
  id?: ModelIDInput | null,
  metadataFileS3Path?: ModelStringInput | null,
  step?: ModelStringInput | null,
  listOfRawFileNames?: ModelStringInput | null,
  analysisType?: ModelAnalysisTypeInput | null,
  subtypeOfAnalysis?: ModelStringInput | null,
  studyPhaseId?: ModelStringInput | null,
  metadataHelperLambdaResponseS3Path?: ModelStringInput | null,
  barcodeToFilesToUploadS3Path?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelUploadBundleAnalysisSessionFilterInput | null > | null,
  or?: Array< ModelUploadBundleAnalysisSessionFilterInput | null > | null,
  not?: ModelUploadBundleAnalysisSessionFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelUploadBundleAnalysisSessionConnection = {
  __typename: "ModelUploadBundleAnalysisSessionConnection",
  items:  Array<UploadBundleAnalysisSession | null >,
  nextToken?: string | null,
};

export type ModelUploadSampleFilterInput = {
  id?: ModelIDInput | null,
  uploadBundleId?: ModelIDInput | null,
  sampleId?: ModelIDInput | null,
  barcode?: ModelStringInput | null,
  sampleLog?: ModelStringInput | null,
  writeMetadata?: ModelBooleanInput | null,
  status?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelUploadSampleFilterInput | null > | null,
  or?: Array< ModelUploadSampleFilterInput | null > | null,
  not?: ModelUploadSampleFilterInput | null,
  uploadBundleUploadSamplesId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
};

export type ModelUploadSampleAnalysisSessionFilterInput = {
  id?: ModelIDInput | null,
  uploadBundleAnalysisSessionId?: ModelIDInput | null,
  sampleId?: ModelIDInput | null,
  rowFromMetaDataFileMappedToSample?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelUploadSampleAnalysisSessionFilterInput | null > | null,
  or?: Array< ModelUploadSampleAnalysisSessionFilterInput | null > | null,
  not?: ModelUploadSampleAnalysisSessionFilterInput | null,
  uploadBundleAnalysisSessionUploadSampleAnalysisSessionId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
};

export type ModelUploadFileFilterInput = {
  id?: ModelIDInput | null,
  uploadSampleId?: ModelIDInput | null,
  uploadBundleId?: ModelIDInput | null,
  multipartUploadId?: ModelStringInput | null,
  status?: ModelUploadFileStatusInput | null,
  toSkipUpload?: ModelBooleanInput | null,
  fileName?: ModelStringInput | null,
  fileType?: ModelStringInput | null,
  s3Path?: ModelStringInput | null,
  size?: ModelIntInput | null,
  fileLog?: ModelStringInput | null,
  adminGroups?: ModelStringInput | null,
  created?: ModelStringInput | null,
  updated?: ModelStringInput | null,
  and?: Array< ModelUploadFileFilterInput | null > | null,
  or?: Array< ModelUploadFileFilterInput | null > | null,
  not?: ModelUploadFileFilterInput | null,
  uploadBundleUploadFilesId?: ModelIDInput | null,
  uploadSampleUploadFilesId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
};

export type ModelOrganizationLaunchablePipelinesFilterInput = {
  id?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  launchablePipelinesId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOrganizationLaunchablePipelinesFilterInput | null > | null,
  or?: Array< ModelOrganizationLaunchablePipelinesFilterInput | null > | null,
  not?: ModelOrganizationLaunchablePipelinesFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelSubscriptionOrganizationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  organizationName?: ModelSubscriptionStringInput | null,
  organizationCode?: ModelSubscriptionStringInput | null,
  active?: ModelSubscriptionBooleanInput | null,
  notificationEmail?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  organizationAnswers?: ModelSubscriptionStringInput | null,
  url?: ModelSubscriptionStringInput | null,
  s3Path?: ModelSubscriptionStringInput | null,
  created?: ModelSubscriptionStringInput | null,
  updated?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOrganizationFilterInput | null > | null,
  or?: Array< ModelSubscriptionOrganizationFilterInput | null > | null,
  organizationStudiesId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionStudyFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  studyName?: ModelSubscriptionStringInput | null,
  organizationId?: ModelSubscriptionIDInput | null,
  description?: ModelSubscriptionStringInput | null,
  studyCode?: ModelSubscriptionStringInput | null,
  studyAnswers?: ModelSubscriptionStringInput | null,
  s3Path?: ModelSubscriptionStringInput | null,
  created?: ModelSubscriptionStringInput | null,
  updated?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionStudyFilterInput | null > | null,
  or?: Array< ModelSubscriptionStudyFilterInput | null > | null,
  studyStudyPhasesId?: ModelSubscriptionIDInput | null,
  studyBatchesId?: ModelSubscriptionIDInput | null,
  studyPipelinesId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionStudyPhaseFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  studyId?: ModelSubscriptionIDInput | null,
  metaDataValidationSchemaTemplateId?: ModelSubscriptionIDInput | null,
  validationSchema?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  studyPhaseName?: ModelSubscriptionStringInput | null,
  studyPhaseAnswers?: ModelSubscriptionStringInput | null,
  experimentSpecificVariables?: ModelSubscriptionStringInput | null,
  sampleTypes?: ModelSubscriptionStringInput | null,
  specimens?: ModelSubscriptionStringInput | null,
  species?: ModelSubscriptionStringInput | null,
  s3Path?: ModelSubscriptionStringInput | null,
  groupNames?: ModelSubscriptionStringInput | null,
  labAssayTechnologies?: ModelSubscriptionStringInput | null,
  phase?: ModelSubscriptionStringInput | null,
  created?: ModelSubscriptionStringInput | null,
  updated?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionStudyPhaseFilterInput | null > | null,
  or?: Array< ModelSubscriptionStudyPhaseFilterInput | null > | null,
  studyPhaseGroupsId?: ModelSubscriptionIDInput | null,
  studyPhaseSamplesId?: ModelSubscriptionIDInput | null,
  studyPhaseBatchesId?: ModelSubscriptionIDInput | null,
  studyPhaseUploadBundlesId?: ModelSubscriptionIDInput | null,
  studyPhasePipelinesId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionEntityQuestionsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  entityType?: ModelSubscriptionStringInput | null,
  questions?: ModelSubscriptionStringInput | null,
  created?: ModelSubscriptionStringInput | null,
  updated?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionEntityQuestionsFilterInput | null > | null,
  or?: Array< ModelSubscriptionEntityQuestionsFilterInput | null > | null,
};

export type ModelSubscriptionMetaDataValidationSchemaTemplateFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  version?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  templateFiles?: ModelSubscriptionStringInput | null,
  validationSchemaTemplate?: ModelSubscriptionStringInput | null,
  created?: ModelSubscriptionStringInput | null,
  updated?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMetaDataValidationSchemaTemplateFilterInput | null > | null,
  or?: Array< ModelSubscriptionMetaDataValidationSchemaTemplateFilterInput | null > | null,
  metaDataValidationSchemaTemplateStudyPhasesId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionGroupFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  studyPhaseId?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  groupName?: ModelSubscriptionStringInput | null,
  s3Path?: ModelSubscriptionStringInput | null,
  created?: ModelSubscriptionStringInput | null,
  updated?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionGroupFilterInput | null > | null,
  or?: Array< ModelSubscriptionGroupFilterInput | null > | null,
  groupPatientsId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionPatientFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  groupId?: ModelSubscriptionIDInput | null,
  patientStudyPhaseId?: ModelSubscriptionStringInput | null,
  patientStudyId?: ModelSubscriptionStringInput | null,
  s3Path?: ModelSubscriptionStringInput | null,
  created?: ModelSubscriptionStringInput | null,
  updated?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPatientFilterInput | null > | null,
  or?: Array< ModelSubscriptionPatientFilterInput | null > | null,
  patientTimepointsId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionTimepointFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  patientId?: ModelSubscriptionIDInput | null,
  timepointName?: ModelSubscriptionStringInput | null,
  s3Path?: ModelSubscriptionStringInput | null,
  created?: ModelSubscriptionStringInput | null,
  updated?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTimepointFilterInput | null > | null,
  or?: Array< ModelSubscriptionTimepointFilterInput | null > | null,
  timepointSamplesId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionSampleFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  timepointId?: ModelSubscriptionIDInput | null,
  studyPhaseId?: ModelSubscriptionIDInput | null,
  BatchId?: ModelSubscriptionIDInput | null,
  domainSampleId?: ModelSubscriptionStringInput | null,
  experimentSpecificVariables?: ModelSubscriptionStringInput | null,
  collectionDate?: ModelSubscriptionStringInput | null,
  processingDate?: ModelSubscriptionStringInput | null,
  created?: ModelSubscriptionStringInput | null,
  updated?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSampleFilterInput | null > | null,
  or?: Array< ModelSubscriptionSampleFilterInput | null > | null,
  sampleMetadataImmunopeptidomicsListId?: ModelSubscriptionIDInput | null,
  sampleMetadataNanoStringListId?: ModelSubscriptionIDInput | null,
  sampleMetadataRnaSeqListId?: ModelSubscriptionIDInput | null,
  sampleAuxiliaryAnalysislListId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionBatchFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  batchName?: ModelSubscriptionStringInput | null,
  batchAnnotations?: ModelSubscriptionStringInput | null,
  batchGroups?: ModelSubscriptionStringInput | null,
  annotations?: ModelSubscriptionStringInput | null,
  controlSamples?: ModelSubscriptionStringInput | null,
  filters?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  studyId?: ModelSubscriptionIDInput | null,
  studyPhaseId?: ModelSubscriptionIDInput | null,
  created?: ModelSubscriptionStringInput | null,
  updated?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBatchFilterInput | null > | null,
  or?: Array< ModelSubscriptionBatchFilterInput | null > | null,
  batchSamplesId?: ModelSubscriptionIDInput | null,
  batchPipelinesId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionPipelineFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  s3Event?: ModelSubscriptionStringInput | null,
  s3ParamsFile?: ModelSubscriptionStringInput | null,
  s3Results?: ModelSubscriptionStringInput | null,
  visFileInfoS3path?: ModelSubscriptionStringInput | null,
  s3NfLog?: ModelSubscriptionStringInput | null,
  s3NfTasksLogs?: ModelSubscriptionStringInput | null,
  analysisType?: ModelSubscriptionStringInput | null,
  subtypeOfAnalysis?: ModelSubscriptionStringInput | null,
  parameters?: ModelSubscriptionStringInput | null,
  studyId?: ModelSubscriptionIDInput | null,
  studyPhaseId?: ModelSubscriptionIDInput | null,
  scheduledPipeline?: ModelSubscriptionBooleanInput | null,
  scheduledDateTime?: ModelSubscriptionStringInput | null,
  launchablePipelineId?: ModelSubscriptionIDInput | null,
  batchId?: ModelSubscriptionIDInput | null,
  runId?: ModelSubscriptionStringInput | null,
  userEmail?: ModelSubscriptionStringInput | null,
  pipelineStarted?: ModelSubscriptionStringInput | null,
  pipelineCompleted?: ModelSubscriptionStringInput | null,
  created?: ModelSubscriptionStringInput | null,
  updated?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPipelineFilterInput | null > | null,
  or?: Array< ModelSubscriptionPipelineFilterInput | null > | null,
};

export type ModelSubscriptionAuxiliaryAnalysisFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  sampleId?: ModelSubscriptionIDInput | null,
  auxiliaryFileS3Path?: ModelSubscriptionStringInput | null,
  vendorId?: ModelSubscriptionStringInput | null,
  specimenType?: ModelSubscriptionStringInput | null,
  parameters?: ModelSubscriptionStringInput | null,
  collectionDate?: ModelSubscriptionStringInput | null,
  created?: ModelSubscriptionStringInput | null,
  updated?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAuxiliaryAnalysisFilterInput | null > | null,
  or?: Array< ModelSubscriptionAuxiliaryAnalysisFilterInput | null > | null,
};

export type ModelSubscriptionMetadataImmunopeptidomicsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  sampleId?: ModelSubscriptionIDInput | null,
  metaDataTemplateS3Path?: ModelSubscriptionStringInput | null,
  species?: ModelSubscriptionStringInput | null,
  subtypeOfAnalysis?: ModelSubscriptionStringInput | null,
  sampleType?: ModelSubscriptionStringInput | null,
  qcStatus?: ModelSubscriptionStringInput | null,
  glacierBackup?: ModelSubscriptionBooleanInput | null,
  rawDataS3?: ModelSubscriptionBooleanInput | null,
  tumorVolume?: ModelSubscriptionFloatInput | null,
  created?: ModelSubscriptionStringInput | null,
  updated?: ModelSubscriptionStringInput | null,
  isControl?: ModelSubscriptionBooleanInput | null,
  barcodesMappedToControl?: ModelSubscriptionStringInput | null,
  barcode?: ModelSubscriptionStringInput | null,
  s3Path?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMetadataImmunopeptidomicsFilterInput | null > | null,
  or?: Array< ModelSubscriptionMetadataImmunopeptidomicsFilterInput | null > | null,
  metadataImmunopeptidomicsTreatmentId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionMetadataNanoStringFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  sampleId?: ModelSubscriptionIDInput | null,
  metaDataTemplateS3Path?: ModelSubscriptionStringInput | null,
  subtypeOfAnalysis?: ModelSubscriptionStringInput | null,
  species?: ModelSubscriptionStringInput | null,
  sampleType?: ModelSubscriptionStringInput | null,
  qcStatus?: ModelSubscriptionStringInput | null,
  glacierBackup?: ModelSubscriptionBooleanInput | null,
  rawDataS3?: ModelSubscriptionBooleanInput | null,
  tumorVolume?: ModelSubscriptionFloatInput | null,
  dv200Percentage?: ModelSubscriptionFloatInput | null,
  concNanoGramPerMicroL?: ModelSubscriptionFloatInput | null,
  rnaMassNanoGram?: ModelSubscriptionFloatInput | null,
  rnaVolumeMicroL?: ModelSubscriptionFloatInput | null,
  rin?: ModelSubscriptionFloatInput | null,
  created?: ModelSubscriptionStringInput | null,
  updated?: ModelSubscriptionStringInput | null,
  isControl?: ModelSubscriptionBooleanInput | null,
  barcodesMappedToControl?: ModelSubscriptionStringInput | null,
  barcode?: ModelSubscriptionStringInput | null,
  s3Path?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMetadataNanoStringFilterInput | null > | null,
  or?: Array< ModelSubscriptionMetadataNanoStringFilterInput | null > | null,
  metadataNanoStringTreatmentId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionMetadataRnaSeqFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  sampleId?: ModelSubscriptionIDInput | null,
  metaDataTemplateS3Path?: ModelSubscriptionStringInput | null,
  subtypeOfAnalysis?: ModelSubscriptionStringInput | null,
  species?: ModelSubscriptionStringInput | null,
  sampleType?: ModelSubscriptionStringInput | null,
  qcStatus?: ModelSubscriptionStringInput | null,
  glacierBackup?: ModelSubscriptionBooleanInput | null,
  rawDataS3?: ModelSubscriptionBooleanInput | null,
  tumorVolume?: ModelSubscriptionFloatInput | null,
  sequenceType?: ModelSubscriptionStringInput | null,
  strandSpecific?: ModelSubscriptionStringInput | null,
  UMI?: ModelSubscriptionBooleanInput | null,
  adaptorsRemoved?: ModelSubscriptionBooleanInput | null,
  adaptorsS3Path?: ModelSubscriptionStringInput | null,
  indexSequences?: ModelSubscriptionStringInput | null,
  concNanoGramPerMicroL?: ModelSubscriptionFloatInput | null,
  rnaMassNanoGram?: ModelSubscriptionFloatInput | null,
  rnaVolumeMicroL?: ModelSubscriptionFloatInput | null,
  rin?: ModelSubscriptionFloatInput | null,
  created?: ModelSubscriptionStringInput | null,
  updated?: ModelSubscriptionStringInput | null,
  isControl?: ModelSubscriptionBooleanInput | null,
  barcodesMappedToControl?: ModelSubscriptionStringInput | null,
  barcode?: ModelSubscriptionStringInput | null,
  s3Path?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMetadataRnaSeqFilterInput | null > | null,
  or?: Array< ModelSubscriptionMetadataRnaSeqFilterInput | null > | null,
  metadataRnaSeqTreatmentId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionTreatmentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  metadataImmunopeptidomicsId?: ModelSubscriptionIDInput | null,
  metadataNanoStringId?: ModelSubscriptionIDInput | null,
  metadataRnaSeqId?: ModelSubscriptionIDInput | null,
  treatmentName?: ModelSubscriptionStringInput | null,
  dose?: ModelSubscriptionFloatInput | null,
  doseUnit?: ModelSubscriptionStringInput | null,
  created?: ModelSubscriptionStringInput | null,
  updated?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTreatmentFilterInput | null > | null,
  or?: Array< ModelSubscriptionTreatmentFilterInput | null > | null,
};

export type ModelSubscriptionLaunchablePipelinesFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  pipelineName?: ModelSubscriptionStringInput | null,
  pipelineVersion?: ModelSubscriptionStringInput | null,
  parameters?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  inputFileInfo?: ModelSubscriptionStringInput | null,
  supportedAnalyses?: ModelSubscriptionStringInput | null,
  supportedSubtypeOfAnalyses?: ModelSubscriptionStringInput | null,
  gitRepo?: ModelSubscriptionStringInput | null,
  workflowId?: ModelSubscriptionStringInput | null,
  created?: ModelSubscriptionStringInput | null,
  updated?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionLaunchablePipelinesFilterInput | null > | null,
  or?: Array< ModelSubscriptionLaunchablePipelinesFilterInput | null > | null,
  launchablePipelinesPipelinesId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  email?: ModelSubscriptionStringInput | null,
  organization?: ModelSubscriptionStringInput | null,
  gitToken?: ModelSubscriptionStringInput | null,
  gitName?: ModelSubscriptionStringInput | null,
  gitURL?: ModelSubscriptionStringInput | null,
  created?: ModelSubscriptionStringInput | null,
  updated?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionUploadLogFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  uploadS3Path?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  created?: ModelSubscriptionStringInput | null,
  updated?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUploadLogFilterInput | null > | null,
  or?: Array< ModelSubscriptionUploadLogFilterInput | null > | null,
};

export type ModelSubscriptionVisualizationsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  visualizationName?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  version?: ModelSubscriptionStringInput | null,
  deploymentPath?: ModelSubscriptionStringInput | null,
  validationSchema?: ModelSubscriptionStringInput | null,
  allowedExtensions?: ModelSubscriptionStringInput | null,
  supportedAnalyses?: ModelSubscriptionStringInput | null,
  created?: ModelSubscriptionStringInput | null,
  updated?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionVisualizationsFilterInput | null > | null,
  or?: Array< ModelSubscriptionVisualizationsFilterInput | null > | null,
};

export type ModelSubscriptionVisualizationSessionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  statuses?: ModelSubscriptionStringInput | null,
  data?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  created?: ModelSubscriptionStringInput | null,
  updated?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionVisualizationSessionFilterInput | null > | null,
  or?: Array< ModelSubscriptionVisualizationSessionFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionDataLabEC2InstancesFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  creationTimestamp?: ModelSubscriptionStringInput | null,
  stoppedTimestamp?: ModelSubscriptionStringInput | null,
  startTimestamp?: ModelSubscriptionStringInput | null,
  terminatedTimestamp?: ModelSubscriptionStringInput | null,
  stoppedTimePeriod?: ModelSubscriptionIntInput | null,
  runningTimePeriod?: ModelSubscriptionIntInput | null,
  userEmail?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  organizationId?: ModelSubscriptionIDInput | null,
  organizationName?: ModelSubscriptionStringInput | null,
  instanceUrl?: ModelSubscriptionStringInput | null,
  instanceId?: ModelSubscriptionStringInput | null,
  infrastructureRelatedEntities?: ModelSubscriptionStringInput | null,
  instanceLaunchEvent?: ModelSubscriptionStringInput | null,
  created?: ModelSubscriptionStringInput | null,
  updated?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDataLabEC2InstancesFilterInput | null > | null,
  or?: Array< ModelSubscriptionDataLabEC2InstancesFilterInput | null > | null,
  dataLabEC2InstancesDataLabInstanceSessionsId?: ModelSubscriptionIDInput | null,
  dataLabEC2InstancesDataLabInstanceStatusChangesId?: ModelSubscriptionIDInput | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionDataLabEC2InstanceSessionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  dataLabInstanceId?: ModelSubscriptionIDInput | null,
  s3Path?: ModelSubscriptionStringInput | null,
  instanceLaunchEvent?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  created?: ModelSubscriptionStringInput | null,
  updated?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDataLabEC2InstanceSessionFilterInput | null > | null,
  or?: Array< ModelSubscriptionDataLabEC2InstanceSessionFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionDataLabEC2InstanceStatusChangeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  dataLabInstanceId?: ModelSubscriptionIDInput | null,
  organizationId?: ModelSubscriptionIDInput | null,
  previousStatus?: ModelSubscriptionStringInput | null,
  newStatus?: ModelSubscriptionStringInput | null,
  initiatedBy?: ModelSubscriptionStringInput | null,
  action?: ModelSubscriptionStringInput | null,
  performedBy?: ModelSubscriptionStringInput | null,
  additionalNotes?: ModelSubscriptionStringInput | null,
  created?: ModelSubscriptionStringInput | null,
  updated?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDataLabEC2InstanceStatusChangeFilterInput | null > | null,
  or?: Array< ModelSubscriptionDataLabEC2InstanceStatusChangeFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionUploadBundleFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  studyPhaseId?: ModelSubscriptionIDInput | null,
  bundleLog?: ModelSubscriptionStringInput | null,
  validationErrors?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  userEmail?: ModelSubscriptionStringInput | null,
  completionTime?: ModelSubscriptionStringInput | null,
  created?: ModelSubscriptionStringInput | null,
  updated?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUploadBundleFilterInput | null > | null,
  or?: Array< ModelSubscriptionUploadBundleFilterInput | null > | null,
  uploadBundleUploadSamplesId?: ModelSubscriptionIDInput | null,
  uploadBundleUploadFilesId?: ModelSubscriptionIDInput | null,
  uploadBundleUploadBundleAnalysisSessionId?: ModelSubscriptionIDInput | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionUploadBundleAnalysisSessionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  metadataFileS3Path?: ModelSubscriptionStringInput | null,
  step?: ModelSubscriptionStringInput | null,
  listOfRawFileNames?: ModelSubscriptionStringInput | null,
  analysisType?: ModelSubscriptionStringInput | null,
  subtypeOfAnalysis?: ModelSubscriptionStringInput | null,
  studyPhaseId?: ModelSubscriptionStringInput | null,
  metadataHelperLambdaResponseS3Path?: ModelSubscriptionStringInput | null,
  barcodeToFilesToUploadS3Path?: ModelSubscriptionStringInput | null,
  created?: ModelSubscriptionStringInput | null,
  updated?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUploadBundleAnalysisSessionFilterInput | null > | null,
  or?: Array< ModelSubscriptionUploadBundleAnalysisSessionFilterInput | null > | null,
  uploadBundleAnalysisSessionUploadSampleAnalysisSessionId?: ModelSubscriptionIDInput | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionUploadSampleFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  uploadBundleId?: ModelSubscriptionIDInput | null,
  sampleId?: ModelSubscriptionIDInput | null,
  barcode?: ModelSubscriptionStringInput | null,
  sampleLog?: ModelSubscriptionStringInput | null,
  writeMetadata?: ModelSubscriptionBooleanInput | null,
  status?: ModelSubscriptionStringInput | null,
  created?: ModelSubscriptionStringInput | null,
  updated?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUploadSampleFilterInput | null > | null,
  or?: Array< ModelSubscriptionUploadSampleFilterInput | null > | null,
  uploadSampleUploadFilesId?: ModelSubscriptionIDInput | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionUploadSampleAnalysisSessionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  uploadBundleAnalysisSessionId?: ModelSubscriptionIDInput | null,
  sampleId?: ModelSubscriptionIDInput | null,
  rowFromMetaDataFileMappedToSample?: ModelSubscriptionStringInput | null,
  created?: ModelSubscriptionStringInput | null,
  updated?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUploadSampleAnalysisSessionFilterInput | null > | null,
  or?: Array< ModelSubscriptionUploadSampleAnalysisSessionFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionUploadFileFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  uploadSampleId?: ModelSubscriptionIDInput | null,
  uploadBundleId?: ModelSubscriptionIDInput | null,
  multipartUploadId?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  toSkipUpload?: ModelSubscriptionBooleanInput | null,
  fileName?: ModelSubscriptionStringInput | null,
  fileType?: ModelSubscriptionStringInput | null,
  s3Path?: ModelSubscriptionStringInput | null,
  size?: ModelSubscriptionIntInput | null,
  fileLog?: ModelSubscriptionStringInput | null,
  created?: ModelSubscriptionStringInput | null,
  updated?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUploadFileFilterInput | null > | null,
  or?: Array< ModelSubscriptionUploadFileFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionOrganizationLaunchablePipelinesFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  organizationId?: ModelSubscriptionIDInput | null,
  launchablePipelinesId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOrganizationLaunchablePipelinesFilterInput | null > | null,
  or?: Array< ModelSubscriptionOrganizationLaunchablePipelinesFilterInput | null > | null,
};

export type DoNotTouchItDoesNothingMutationVariables = {
  input: CreateUserInput,
};

export type DoNotTouchItDoesNothingMutation = {
  doNotTouchItDoesNothing?:  {
    __typename: "LaunchablePipelines",
    id: string,
    pipelineName?: string | null,
    organizations?:  {
      __typename: "ModelOrganizationLaunchablePipelinesConnection",
      items:  Array< {
        __typename: "OrganizationLaunchablePipelines",
        id: string,
        organizationId: string,
        launchablePipelinesId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pipelineVersion?: string | null,
    parameters?: string | null,
    description?: string | null,
    inputFileInfo?: string | null,
    supportedAnalyses?: Array< AnalysisType | null > | null,
    supportedSubtypeOfAnalyses?: Array< string | null > | null,
    pipelines?:  {
      __typename: "ModelPipelineConnection",
      items:  Array< {
        __typename: "Pipeline",
        id: string,
        name: string,
        status?: pipelineStatus | null,
        description?: string | null,
        s3Event?: string | null,
        s3ParamsFile?: string | null,
        s3Results?: string | null,
        visFileInfoS3path?: string | null,
        s3NfLog?: string | null,
        s3NfTasksLogs?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis?: string | null,
        parameters: string,
        studyId?: string | null,
        studyPhaseId?: string | null,
        scheduledPipeline: boolean,
        scheduledDateTime?: string | null,
        launchablePipelineId: string,
        batchId: string,
        runId?: string | null,
        userEmail: string,
        pipelineStarted?: string | null,
        pipelineCompleted?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPipelinesId?: string | null,
        studyPhasePipelinesId?: string | null,
        batchPipelinesId?: string | null,
        launchablePipelinesPipelinesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    gitRepo?: string | null,
    workflowId?: string | null,
    created: string,
    updated: string,
  } | null,
};

export type CreateOrganizationMutationVariables = {
  input: CreateOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type CreateOrganizationMutation = {
  createOrganization?:  {
    __typename: "Organization",
    id: string,
    organizationName: string,
    organizationCode?: string | null,
    active: boolean,
    studies?:  {
      __typename: "ModelStudyConnection",
      items:  Array< {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    launchablePipelines?:  {
      __typename: "ModelOrganizationLaunchablePipelinesConnection",
      items:  Array< {
        __typename: "OrganizationLaunchablePipelines",
        id: string,
        organizationId: string,
        launchablePipelinesId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    notificationEmail: string,
    description: string,
    organizationAnswers?: string | null,
    url: string,
    s3Path?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    created: string,
    updated: string,
  } | null,
};

export type UpdateOrganizationMutationVariables = {
  input: UpdateOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type UpdateOrganizationMutation = {
  updateOrganization?:  {
    __typename: "Organization",
    id: string,
    organizationName: string,
    organizationCode?: string | null,
    active: boolean,
    studies?:  {
      __typename: "ModelStudyConnection",
      items:  Array< {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    launchablePipelines?:  {
      __typename: "ModelOrganizationLaunchablePipelinesConnection",
      items:  Array< {
        __typename: "OrganizationLaunchablePipelines",
        id: string,
        organizationId: string,
        launchablePipelinesId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    notificationEmail: string,
    description: string,
    organizationAnswers?: string | null,
    url: string,
    s3Path?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    created: string,
    updated: string,
  } | null,
};

export type DeleteOrganizationMutationVariables = {
  input: DeleteOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type DeleteOrganizationMutation = {
  deleteOrganization?:  {
    __typename: "Organization",
    id: string,
    organizationName: string,
    organizationCode?: string | null,
    active: boolean,
    studies?:  {
      __typename: "ModelStudyConnection",
      items:  Array< {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    launchablePipelines?:  {
      __typename: "ModelOrganizationLaunchablePipelinesConnection",
      items:  Array< {
        __typename: "OrganizationLaunchablePipelines",
        id: string,
        organizationId: string,
        launchablePipelinesId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    notificationEmail: string,
    description: string,
    organizationAnswers?: string | null,
    url: string,
    s3Path?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    created: string,
    updated: string,
  } | null,
};

export type CreateStudyMutationVariables = {
  input: CreateStudyInput,
  condition?: ModelStudyConditionInput | null,
};

export type CreateStudyMutation = {
  createStudy?:  {
    __typename: "Study",
    id: string,
    studyName: string,
    organizationId: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      organizationName: string,
      organizationCode?: string | null,
      active: boolean,
      studies?:  {
        __typename: "ModelStudyConnection",
        nextToken?: string | null,
      } | null,
      launchablePipelines?:  {
        __typename: "ModelOrganizationLaunchablePipelinesConnection",
        nextToken?: string | null,
      } | null,
      notificationEmail: string,
      description: string,
      organizationAnswers?: string | null,
      url: string,
      s3Path?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      created: string,
      updated: string,
    } | null,
    studyPhases?:  {
      __typename: "ModelStudyPhaseConnection",
      items:  Array< {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    batches?:  {
      __typename: "ModelBatchConnection",
      items:  Array< {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pipelines?:  {
      __typename: "ModelPipelineConnection",
      items:  Array< {
        __typename: "Pipeline",
        id: string,
        name: string,
        status?: pipelineStatus | null,
        description?: string | null,
        s3Event?: string | null,
        s3ParamsFile?: string | null,
        s3Results?: string | null,
        visFileInfoS3path?: string | null,
        s3NfLog?: string | null,
        s3NfTasksLogs?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis?: string | null,
        parameters: string,
        studyId?: string | null,
        studyPhaseId?: string | null,
        scheduledPipeline: boolean,
        scheduledDateTime?: string | null,
        launchablePipelineId: string,
        batchId: string,
        runId?: string | null,
        userEmail: string,
        pipelineStarted?: string | null,
        pipelineCompleted?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPipelinesId?: string | null,
        studyPhasePipelinesId?: string | null,
        batchPipelinesId?: string | null,
        launchablePipelinesPipelinesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    description: string,
    studyCode: string,
    studyAnswers?: string | null,
    s3Path?: string | null,
    croGroups?: Array< string | null > | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    organizationStudiesId?: string | null,
  } | null,
};

export type UpdateStudyMutationVariables = {
  input: UpdateStudyInput,
  condition?: ModelStudyConditionInput | null,
};

export type UpdateStudyMutation = {
  updateStudy?:  {
    __typename: "Study",
    id: string,
    studyName: string,
    organizationId: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      organizationName: string,
      organizationCode?: string | null,
      active: boolean,
      studies?:  {
        __typename: "ModelStudyConnection",
        nextToken?: string | null,
      } | null,
      launchablePipelines?:  {
        __typename: "ModelOrganizationLaunchablePipelinesConnection",
        nextToken?: string | null,
      } | null,
      notificationEmail: string,
      description: string,
      organizationAnswers?: string | null,
      url: string,
      s3Path?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      created: string,
      updated: string,
    } | null,
    studyPhases?:  {
      __typename: "ModelStudyPhaseConnection",
      items:  Array< {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    batches?:  {
      __typename: "ModelBatchConnection",
      items:  Array< {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pipelines?:  {
      __typename: "ModelPipelineConnection",
      items:  Array< {
        __typename: "Pipeline",
        id: string,
        name: string,
        status?: pipelineStatus | null,
        description?: string | null,
        s3Event?: string | null,
        s3ParamsFile?: string | null,
        s3Results?: string | null,
        visFileInfoS3path?: string | null,
        s3NfLog?: string | null,
        s3NfTasksLogs?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis?: string | null,
        parameters: string,
        studyId?: string | null,
        studyPhaseId?: string | null,
        scheduledPipeline: boolean,
        scheduledDateTime?: string | null,
        launchablePipelineId: string,
        batchId: string,
        runId?: string | null,
        userEmail: string,
        pipelineStarted?: string | null,
        pipelineCompleted?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPipelinesId?: string | null,
        studyPhasePipelinesId?: string | null,
        batchPipelinesId?: string | null,
        launchablePipelinesPipelinesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    description: string,
    studyCode: string,
    studyAnswers?: string | null,
    s3Path?: string | null,
    croGroups?: Array< string | null > | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    organizationStudiesId?: string | null,
  } | null,
};

export type DeleteStudyMutationVariables = {
  input: DeleteStudyInput,
  condition?: ModelStudyConditionInput | null,
};

export type DeleteStudyMutation = {
  deleteStudy?:  {
    __typename: "Study",
    id: string,
    studyName: string,
    organizationId: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      organizationName: string,
      organizationCode?: string | null,
      active: boolean,
      studies?:  {
        __typename: "ModelStudyConnection",
        nextToken?: string | null,
      } | null,
      launchablePipelines?:  {
        __typename: "ModelOrganizationLaunchablePipelinesConnection",
        nextToken?: string | null,
      } | null,
      notificationEmail: string,
      description: string,
      organizationAnswers?: string | null,
      url: string,
      s3Path?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      created: string,
      updated: string,
    } | null,
    studyPhases?:  {
      __typename: "ModelStudyPhaseConnection",
      items:  Array< {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    batches?:  {
      __typename: "ModelBatchConnection",
      items:  Array< {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pipelines?:  {
      __typename: "ModelPipelineConnection",
      items:  Array< {
        __typename: "Pipeline",
        id: string,
        name: string,
        status?: pipelineStatus | null,
        description?: string | null,
        s3Event?: string | null,
        s3ParamsFile?: string | null,
        s3Results?: string | null,
        visFileInfoS3path?: string | null,
        s3NfLog?: string | null,
        s3NfTasksLogs?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis?: string | null,
        parameters: string,
        studyId?: string | null,
        studyPhaseId?: string | null,
        scheduledPipeline: boolean,
        scheduledDateTime?: string | null,
        launchablePipelineId: string,
        batchId: string,
        runId?: string | null,
        userEmail: string,
        pipelineStarted?: string | null,
        pipelineCompleted?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPipelinesId?: string | null,
        studyPhasePipelinesId?: string | null,
        batchPipelinesId?: string | null,
        launchablePipelinesPipelinesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    description: string,
    studyCode: string,
    studyAnswers?: string | null,
    s3Path?: string | null,
    croGroups?: Array< string | null > | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    organizationStudiesId?: string | null,
  } | null,
};

export type CreateStudyPhaseMutationVariables = {
  input: CreateStudyPhaseInput,
  condition?: ModelStudyPhaseConditionInput | null,
};

export type CreateStudyPhaseMutation = {
  createStudyPhase?:  {
    __typename: "StudyPhase",
    id: string,
    studyId: string,
    study?:  {
      __typename: "Study",
      id: string,
      studyName: string,
      organizationId: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        organizationName: string,
        organizationCode?: string | null,
        active: boolean,
        notificationEmail: string,
        description: string,
        organizationAnswers?: string | null,
        url: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        created: string,
        updated: string,
      } | null,
      studyPhases?:  {
        __typename: "ModelStudyPhaseConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      description: string,
      studyCode: string,
      studyAnswers?: string | null,
      s3Path?: string | null,
      croGroups?: Array< string | null > | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      organizationStudiesId?: string | null,
    } | null,
    metaDataValidationSchemaTemplateId: string,
    metaDataValidationSchemaTemplate?:  {
      __typename: "MetaDataValidationSchemaTemplate",
      id: string,
      studyPhases?:  {
        __typename: "ModelStudyPhaseConnection",
        nextToken?: string | null,
      } | null,
      version: string,
      description?: string | null,
      templateFiles: string,
      validationSchemaTemplate: string,
      created: string,
      updated: string,
    } | null,
    groups?:  {
      __typename: "ModelGroupConnection",
      items:  Array< {
        __typename: "Group",
        id: string,
        studyPhaseId: string,
        type?: string | null,
        groupName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseGroupsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    samples?:  {
      __typename: "ModelSampleConnection",
      items:  Array< {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    batches?:  {
      __typename: "ModelBatchConnection",
      items:  Array< {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    uploadBundles?:  {
      __typename: "ModelUploadBundleConnection",
      items:  Array< {
        __typename: "UploadBundle",
        id: string,
        studyPhaseId?: string | null,
        bundleLog?: string | null,
        validationErrors?: string | null,
        status?: string | null,
        userEmail?: string | null,
        adminGroups?: Array< string | null > | null,
        completionTime?: string | null,
        created: string,
        updated: string,
        studyPhaseUploadBundlesId?: string | null,
        uploadBundleUploadBundleAnalysisSessionId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pipelines?:  {
      __typename: "ModelPipelineConnection",
      items:  Array< {
        __typename: "Pipeline",
        id: string,
        name: string,
        status?: pipelineStatus | null,
        description?: string | null,
        s3Event?: string | null,
        s3ParamsFile?: string | null,
        s3Results?: string | null,
        visFileInfoS3path?: string | null,
        s3NfLog?: string | null,
        s3NfTasksLogs?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis?: string | null,
        parameters: string,
        studyId?: string | null,
        studyPhaseId?: string | null,
        scheduledPipeline: boolean,
        scheduledDateTime?: string | null,
        launchablePipelineId: string,
        batchId: string,
        runId?: string | null,
        userEmail: string,
        pipelineStarted?: string | null,
        pipelineCompleted?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPipelinesId?: string | null,
        studyPhasePipelinesId?: string | null,
        batchPipelinesId?: string | null,
        launchablePipelinesPipelinesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    validationSchema?: string | null,
    description: string,
    studyPhaseName: string,
    studyPhaseAnswers?: string | null,
    experimentSpecificVariables?: string | null,
    sampleTypes?: Array< string | null > | null,
    specimens?: Array< string | null > | null,
    species?: Array< string | null > | null,
    s3Path?: string | null,
    groupNames?: Array< string | null > | null,
    labAssayTechnologies?: Array< string | null > | null,
    phase: Phase,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    croGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyStudyPhasesId?: string | null,
    metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
  } | null,
};

export type UpdateStudyPhaseMutationVariables = {
  input: UpdateStudyPhaseInput,
  condition?: ModelStudyPhaseConditionInput | null,
};

export type UpdateStudyPhaseMutation = {
  updateStudyPhase?:  {
    __typename: "StudyPhase",
    id: string,
    studyId: string,
    study?:  {
      __typename: "Study",
      id: string,
      studyName: string,
      organizationId: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        organizationName: string,
        organizationCode?: string | null,
        active: boolean,
        notificationEmail: string,
        description: string,
        organizationAnswers?: string | null,
        url: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        created: string,
        updated: string,
      } | null,
      studyPhases?:  {
        __typename: "ModelStudyPhaseConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      description: string,
      studyCode: string,
      studyAnswers?: string | null,
      s3Path?: string | null,
      croGroups?: Array< string | null > | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      organizationStudiesId?: string | null,
    } | null,
    metaDataValidationSchemaTemplateId: string,
    metaDataValidationSchemaTemplate?:  {
      __typename: "MetaDataValidationSchemaTemplate",
      id: string,
      studyPhases?:  {
        __typename: "ModelStudyPhaseConnection",
        nextToken?: string | null,
      } | null,
      version: string,
      description?: string | null,
      templateFiles: string,
      validationSchemaTemplate: string,
      created: string,
      updated: string,
    } | null,
    groups?:  {
      __typename: "ModelGroupConnection",
      items:  Array< {
        __typename: "Group",
        id: string,
        studyPhaseId: string,
        type?: string | null,
        groupName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseGroupsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    samples?:  {
      __typename: "ModelSampleConnection",
      items:  Array< {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    batches?:  {
      __typename: "ModelBatchConnection",
      items:  Array< {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    uploadBundles?:  {
      __typename: "ModelUploadBundleConnection",
      items:  Array< {
        __typename: "UploadBundle",
        id: string,
        studyPhaseId?: string | null,
        bundleLog?: string | null,
        validationErrors?: string | null,
        status?: string | null,
        userEmail?: string | null,
        adminGroups?: Array< string | null > | null,
        completionTime?: string | null,
        created: string,
        updated: string,
        studyPhaseUploadBundlesId?: string | null,
        uploadBundleUploadBundleAnalysisSessionId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pipelines?:  {
      __typename: "ModelPipelineConnection",
      items:  Array< {
        __typename: "Pipeline",
        id: string,
        name: string,
        status?: pipelineStatus | null,
        description?: string | null,
        s3Event?: string | null,
        s3ParamsFile?: string | null,
        s3Results?: string | null,
        visFileInfoS3path?: string | null,
        s3NfLog?: string | null,
        s3NfTasksLogs?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis?: string | null,
        parameters: string,
        studyId?: string | null,
        studyPhaseId?: string | null,
        scheduledPipeline: boolean,
        scheduledDateTime?: string | null,
        launchablePipelineId: string,
        batchId: string,
        runId?: string | null,
        userEmail: string,
        pipelineStarted?: string | null,
        pipelineCompleted?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPipelinesId?: string | null,
        studyPhasePipelinesId?: string | null,
        batchPipelinesId?: string | null,
        launchablePipelinesPipelinesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    validationSchema?: string | null,
    description: string,
    studyPhaseName: string,
    studyPhaseAnswers?: string | null,
    experimentSpecificVariables?: string | null,
    sampleTypes?: Array< string | null > | null,
    specimens?: Array< string | null > | null,
    species?: Array< string | null > | null,
    s3Path?: string | null,
    groupNames?: Array< string | null > | null,
    labAssayTechnologies?: Array< string | null > | null,
    phase: Phase,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    croGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyStudyPhasesId?: string | null,
    metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
  } | null,
};

export type DeleteStudyPhaseMutationVariables = {
  input: DeleteStudyPhaseInput,
  condition?: ModelStudyPhaseConditionInput | null,
};

export type DeleteStudyPhaseMutation = {
  deleteStudyPhase?:  {
    __typename: "StudyPhase",
    id: string,
    studyId: string,
    study?:  {
      __typename: "Study",
      id: string,
      studyName: string,
      organizationId: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        organizationName: string,
        organizationCode?: string | null,
        active: boolean,
        notificationEmail: string,
        description: string,
        organizationAnswers?: string | null,
        url: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        created: string,
        updated: string,
      } | null,
      studyPhases?:  {
        __typename: "ModelStudyPhaseConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      description: string,
      studyCode: string,
      studyAnswers?: string | null,
      s3Path?: string | null,
      croGroups?: Array< string | null > | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      organizationStudiesId?: string | null,
    } | null,
    metaDataValidationSchemaTemplateId: string,
    metaDataValidationSchemaTemplate?:  {
      __typename: "MetaDataValidationSchemaTemplate",
      id: string,
      studyPhases?:  {
        __typename: "ModelStudyPhaseConnection",
        nextToken?: string | null,
      } | null,
      version: string,
      description?: string | null,
      templateFiles: string,
      validationSchemaTemplate: string,
      created: string,
      updated: string,
    } | null,
    groups?:  {
      __typename: "ModelGroupConnection",
      items:  Array< {
        __typename: "Group",
        id: string,
        studyPhaseId: string,
        type?: string | null,
        groupName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseGroupsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    samples?:  {
      __typename: "ModelSampleConnection",
      items:  Array< {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    batches?:  {
      __typename: "ModelBatchConnection",
      items:  Array< {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    uploadBundles?:  {
      __typename: "ModelUploadBundleConnection",
      items:  Array< {
        __typename: "UploadBundle",
        id: string,
        studyPhaseId?: string | null,
        bundleLog?: string | null,
        validationErrors?: string | null,
        status?: string | null,
        userEmail?: string | null,
        adminGroups?: Array< string | null > | null,
        completionTime?: string | null,
        created: string,
        updated: string,
        studyPhaseUploadBundlesId?: string | null,
        uploadBundleUploadBundleAnalysisSessionId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pipelines?:  {
      __typename: "ModelPipelineConnection",
      items:  Array< {
        __typename: "Pipeline",
        id: string,
        name: string,
        status?: pipelineStatus | null,
        description?: string | null,
        s3Event?: string | null,
        s3ParamsFile?: string | null,
        s3Results?: string | null,
        visFileInfoS3path?: string | null,
        s3NfLog?: string | null,
        s3NfTasksLogs?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis?: string | null,
        parameters: string,
        studyId?: string | null,
        studyPhaseId?: string | null,
        scheduledPipeline: boolean,
        scheduledDateTime?: string | null,
        launchablePipelineId: string,
        batchId: string,
        runId?: string | null,
        userEmail: string,
        pipelineStarted?: string | null,
        pipelineCompleted?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPipelinesId?: string | null,
        studyPhasePipelinesId?: string | null,
        batchPipelinesId?: string | null,
        launchablePipelinesPipelinesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    validationSchema?: string | null,
    description: string,
    studyPhaseName: string,
    studyPhaseAnswers?: string | null,
    experimentSpecificVariables?: string | null,
    sampleTypes?: Array< string | null > | null,
    specimens?: Array< string | null > | null,
    species?: Array< string | null > | null,
    s3Path?: string | null,
    groupNames?: Array< string | null > | null,
    labAssayTechnologies?: Array< string | null > | null,
    phase: Phase,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    croGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyStudyPhasesId?: string | null,
    metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
  } | null,
};

export type CreateEntityQuestionsMutationVariables = {
  input: CreateEntityQuestionsInput,
  condition?: ModelEntityQuestionsConditionInput | null,
};

export type CreateEntityQuestionsMutation = {
  createEntityQuestions?:  {
    __typename: "EntityQuestions",
    id: string,
    entityType?: EntityType | null,
    questions?: string | null,
    created: string,
    updated: string,
  } | null,
};

export type UpdateEntityQuestionsMutationVariables = {
  input: UpdateEntityQuestionsInput,
  condition?: ModelEntityQuestionsConditionInput | null,
};

export type UpdateEntityQuestionsMutation = {
  updateEntityQuestions?:  {
    __typename: "EntityQuestions",
    id: string,
    entityType?: EntityType | null,
    questions?: string | null,
    created: string,
    updated: string,
  } | null,
};

export type DeleteEntityQuestionsMutationVariables = {
  input: DeleteEntityQuestionsInput,
  condition?: ModelEntityQuestionsConditionInput | null,
};

export type DeleteEntityQuestionsMutation = {
  deleteEntityQuestions?:  {
    __typename: "EntityQuestions",
    id: string,
    entityType?: EntityType | null,
    questions?: string | null,
    created: string,
    updated: string,
  } | null,
};

export type CreateMetaDataValidationSchemaTemplateMutationVariables = {
  input: CreateMetaDataValidationSchemaTemplateInput,
  condition?: ModelMetaDataValidationSchemaTemplateConditionInput | null,
};

export type CreateMetaDataValidationSchemaTemplateMutation = {
  createMetaDataValidationSchemaTemplate?:  {
    __typename: "MetaDataValidationSchemaTemplate",
    id: string,
    studyPhases?:  {
      __typename: "ModelStudyPhaseConnection",
      items:  Array< {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    version: string,
    description?: string | null,
    templateFiles: string,
    validationSchemaTemplate: string,
    created: string,
    updated: string,
  } | null,
};

export type UpdateMetaDataValidationSchemaTemplateMutationVariables = {
  input: UpdateMetaDataValidationSchemaTemplateInput,
  condition?: ModelMetaDataValidationSchemaTemplateConditionInput | null,
};

export type UpdateMetaDataValidationSchemaTemplateMutation = {
  updateMetaDataValidationSchemaTemplate?:  {
    __typename: "MetaDataValidationSchemaTemplate",
    id: string,
    studyPhases?:  {
      __typename: "ModelStudyPhaseConnection",
      items:  Array< {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    version: string,
    description?: string | null,
    templateFiles: string,
    validationSchemaTemplate: string,
    created: string,
    updated: string,
  } | null,
};

export type DeleteMetaDataValidationSchemaTemplateMutationVariables = {
  input: DeleteMetaDataValidationSchemaTemplateInput,
  condition?: ModelMetaDataValidationSchemaTemplateConditionInput | null,
};

export type DeleteMetaDataValidationSchemaTemplateMutation = {
  deleteMetaDataValidationSchemaTemplate?:  {
    __typename: "MetaDataValidationSchemaTemplate",
    id: string,
    studyPhases?:  {
      __typename: "ModelStudyPhaseConnection",
      items:  Array< {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    version: string,
    description?: string | null,
    templateFiles: string,
    validationSchemaTemplate: string,
    created: string,
    updated: string,
  } | null,
};

export type CreateGroupMutationVariables = {
  input: CreateGroupInput,
  condition?: ModelGroupConditionInput | null,
};

export type CreateGroupMutation = {
  createGroup?:  {
    __typename: "Group",
    id: string,
    studyPhaseId: string,
    studyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    type?: string | null,
    groupName: string,
    s3Path?: string | null,
    patients?:  {
      __typename: "ModelPatientConnection",
      items:  Array< {
        __typename: "Patient",
        id: string,
        groupId: string,
        patientStudyPhaseId: string,
        patientStudyId: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        groupPatientsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyPhaseGroupsId?: string | null,
  } | null,
};

export type UpdateGroupMutationVariables = {
  input: UpdateGroupInput,
  condition?: ModelGroupConditionInput | null,
};

export type UpdateGroupMutation = {
  updateGroup?:  {
    __typename: "Group",
    id: string,
    studyPhaseId: string,
    studyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    type?: string | null,
    groupName: string,
    s3Path?: string | null,
    patients?:  {
      __typename: "ModelPatientConnection",
      items:  Array< {
        __typename: "Patient",
        id: string,
        groupId: string,
        patientStudyPhaseId: string,
        patientStudyId: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        groupPatientsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyPhaseGroupsId?: string | null,
  } | null,
};

export type DeleteGroupMutationVariables = {
  input: DeleteGroupInput,
  condition?: ModelGroupConditionInput | null,
};

export type DeleteGroupMutation = {
  deleteGroup?:  {
    __typename: "Group",
    id: string,
    studyPhaseId: string,
    studyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    type?: string | null,
    groupName: string,
    s3Path?: string | null,
    patients?:  {
      __typename: "ModelPatientConnection",
      items:  Array< {
        __typename: "Patient",
        id: string,
        groupId: string,
        patientStudyPhaseId: string,
        patientStudyId: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        groupPatientsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyPhaseGroupsId?: string | null,
  } | null,
};

export type CreatePatientMutationVariables = {
  input: CreatePatientInput,
  condition?: ModelPatientConditionInput | null,
};

export type CreatePatientMutation = {
  createPatient?:  {
    __typename: "Patient",
    id: string,
    groupId: string,
    group?:  {
      __typename: "Group",
      id: string,
      studyPhaseId: string,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      type?: string | null,
      groupName: string,
      s3Path?: string | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseGroupsId?: string | null,
    } | null,
    timepoints?:  {
      __typename: "ModelTimepointConnection",
      items:  Array< {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    patientStudyPhaseId: string,
    patientStudyId: string,
    s3Path?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    groupPatientsId?: string | null,
  } | null,
};

export type UpdatePatientMutationVariables = {
  input: UpdatePatientInput,
  condition?: ModelPatientConditionInput | null,
};

export type UpdatePatientMutation = {
  updatePatient?:  {
    __typename: "Patient",
    id: string,
    groupId: string,
    group?:  {
      __typename: "Group",
      id: string,
      studyPhaseId: string,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      type?: string | null,
      groupName: string,
      s3Path?: string | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseGroupsId?: string | null,
    } | null,
    timepoints?:  {
      __typename: "ModelTimepointConnection",
      items:  Array< {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    patientStudyPhaseId: string,
    patientStudyId: string,
    s3Path?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    groupPatientsId?: string | null,
  } | null,
};

export type DeletePatientMutationVariables = {
  input: DeletePatientInput,
  condition?: ModelPatientConditionInput | null,
};

export type DeletePatientMutation = {
  deletePatient?:  {
    __typename: "Patient",
    id: string,
    groupId: string,
    group?:  {
      __typename: "Group",
      id: string,
      studyPhaseId: string,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      type?: string | null,
      groupName: string,
      s3Path?: string | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseGroupsId?: string | null,
    } | null,
    timepoints?:  {
      __typename: "ModelTimepointConnection",
      items:  Array< {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    patientStudyPhaseId: string,
    patientStudyId: string,
    s3Path?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    groupPatientsId?: string | null,
  } | null,
};

export type CreateTimepointMutationVariables = {
  input: CreateTimepointInput,
  condition?: ModelTimepointConditionInput | null,
};

export type CreateTimepointMutation = {
  createTimepoint?:  {
    __typename: "Timepoint",
    id: string,
    patientId: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      groupId: string,
      group?:  {
        __typename: "Group",
        id: string,
        studyPhaseId: string,
        type?: string | null,
        groupName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseGroupsId?: string | null,
      } | null,
      timepoints?:  {
        __typename: "ModelTimepointConnection",
        nextToken?: string | null,
      } | null,
      patientStudyPhaseId: string,
      patientStudyId: string,
      s3Path?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      groupPatientsId?: string | null,
    } | null,
    samples?:  {
      __typename: "ModelSampleConnection",
      items:  Array< {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    timepointName: string,
    s3Path?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    patientTimepointsId?: string | null,
  } | null,
};

export type UpdateTimepointMutationVariables = {
  input: UpdateTimepointInput,
  condition?: ModelTimepointConditionInput | null,
};

export type UpdateTimepointMutation = {
  updateTimepoint?:  {
    __typename: "Timepoint",
    id: string,
    patientId: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      groupId: string,
      group?:  {
        __typename: "Group",
        id: string,
        studyPhaseId: string,
        type?: string | null,
        groupName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseGroupsId?: string | null,
      } | null,
      timepoints?:  {
        __typename: "ModelTimepointConnection",
        nextToken?: string | null,
      } | null,
      patientStudyPhaseId: string,
      patientStudyId: string,
      s3Path?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      groupPatientsId?: string | null,
    } | null,
    samples?:  {
      __typename: "ModelSampleConnection",
      items:  Array< {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    timepointName: string,
    s3Path?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    patientTimepointsId?: string | null,
  } | null,
};

export type DeleteTimepointMutationVariables = {
  input: DeleteTimepointInput,
  condition?: ModelTimepointConditionInput | null,
};

export type DeleteTimepointMutation = {
  deleteTimepoint?:  {
    __typename: "Timepoint",
    id: string,
    patientId: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      groupId: string,
      group?:  {
        __typename: "Group",
        id: string,
        studyPhaseId: string,
        type?: string | null,
        groupName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseGroupsId?: string | null,
      } | null,
      timepoints?:  {
        __typename: "ModelTimepointConnection",
        nextToken?: string | null,
      } | null,
      patientStudyPhaseId: string,
      patientStudyId: string,
      s3Path?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      groupPatientsId?: string | null,
    } | null,
    samples?:  {
      __typename: "ModelSampleConnection",
      items:  Array< {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    timepointName: string,
    s3Path?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    patientTimepointsId?: string | null,
  } | null,
};

export type CreateSampleMutationVariables = {
  input: CreateSampleInput,
  condition?: ModelSampleConditionInput | null,
};

export type CreateSampleMutation = {
  createSample?:  {
    __typename: "Sample",
    id: string,
    timepointId?: string | null,
    timepoint?:  {
      __typename: "Timepoint",
      id: string,
      patientId: string,
      patient?:  {
        __typename: "Patient",
        id: string,
        groupId: string,
        patientStudyPhaseId: string,
        patientStudyId: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        groupPatientsId?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      timepointName: string,
      s3Path?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      patientTimepointsId?: string | null,
    } | null,
    studyPhaseId?: string | null,
    StudyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    BatchId?: string | null,
    Batch?:  {
      __typename: "Batch",
      id: string,
      batchName: string,
      batchAnnotations?: string | null,
      batchGroups?: string | null,
      annotations: string,
      controlSamples: string,
      filters: string,
      description?: string | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyBatchesId?: string | null,
      studyPhaseBatchesId?: string | null,
    } | null,
    metadataImmunopeptidomicsList?:  {
      __typename: "ModelMetadataImmunopeptidomicsConnection",
      items:  Array< {
        __typename: "MetadataImmunopeptidomics",
        id: string,
        sampleId: string,
        metaDataTemplateS3Path: string,
        species: string,
        subtypeOfAnalysis?: string | null,
        sampleType: string,
        qcStatus: QcStatus,
        glacierBackup: boolean,
        rawDataS3: boolean,
        tumorVolume?: number | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        isControl: boolean,
        barcodesMappedToControl?: Array< string | null > | null,
        barcode: string,
        s3Path: Array< string | null >,
        sampleMetadataImmunopeptidomicsListId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    MetadataNanoStringList?:  {
      __typename: "ModelMetadataNanoStringConnection",
      items:  Array< {
        __typename: "MetadataNanoString",
        id: string,
        sampleId: string,
        metaDataTemplateS3Path: string,
        subtypeOfAnalysis?: string | null,
        species: string,
        sampleType: string,
        qcStatus: QcStatus,
        glacierBackup: boolean,
        rawDataS3: boolean,
        tumorVolume?: number | null,
        dv200Percentage?: number | null,
        concNanoGramPerMicroL?: number | null,
        rnaMassNanoGram?: number | null,
        rnaVolumeMicroL?: number | null,
        rin?: number | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        isControl: boolean,
        barcodesMappedToControl?: Array< string | null > | null,
        barcode: string,
        s3Path: Array< string | null >,
        sampleMetadataNanoStringListId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    MetadataRnaSeqList?:  {
      __typename: "ModelMetadataRnaSeqConnection",
      items:  Array< {
        __typename: "MetadataRnaSeq",
        id: string,
        sampleId: string,
        metaDataTemplateS3Path: string,
        subtypeOfAnalysis?: string | null,
        species: string,
        sampleType: string,
        qcStatus: QcStatus,
        glacierBackup: boolean,
        rawDataS3: boolean,
        tumorVolume?: number | null,
        sequenceType: SequenceType,
        strandSpecific: string,
        UMI: boolean,
        adaptorsRemoved: boolean,
        adaptorsS3Path?: string | null,
        indexSequences?: Array< string | null > | null,
        concNanoGramPerMicroL?: number | null,
        rnaMassNanoGram?: number | null,
        rnaVolumeMicroL?: number | null,
        rin?: number | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        isControl: boolean,
        barcodesMappedToControl?: Array< string | null > | null,
        barcode: string,
        s3Path: Array< string | null >,
        sampleMetadataRnaSeqListId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    AuxiliaryAnalysislList?:  {
      __typename: "ModelAuxiliaryAnalysisConnection",
      items:  Array< {
        __typename: "AuxiliaryAnalysis",
        id: string,
        sampleId: string,
        auxiliaryFileS3Path: string,
        vendorId: string,
        specimenType: string,
        parameters: string,
        collectionDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        sampleAuxiliaryAnalysislListId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    domainSampleId: string,
    experimentSpecificVariables?: string | null,
    collectionDate?: string | null,
    processingDate?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyPhaseSamplesId?: string | null,
    timepointSamplesId?: string | null,
    batchSamplesId?: string | null,
  } | null,
};

export type UpdateSampleMutationVariables = {
  input: UpdateSampleInput,
  condition?: ModelSampleConditionInput | null,
};

export type UpdateSampleMutation = {
  updateSample?:  {
    __typename: "Sample",
    id: string,
    timepointId?: string | null,
    timepoint?:  {
      __typename: "Timepoint",
      id: string,
      patientId: string,
      patient?:  {
        __typename: "Patient",
        id: string,
        groupId: string,
        patientStudyPhaseId: string,
        patientStudyId: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        groupPatientsId?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      timepointName: string,
      s3Path?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      patientTimepointsId?: string | null,
    } | null,
    studyPhaseId?: string | null,
    StudyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    BatchId?: string | null,
    Batch?:  {
      __typename: "Batch",
      id: string,
      batchName: string,
      batchAnnotations?: string | null,
      batchGroups?: string | null,
      annotations: string,
      controlSamples: string,
      filters: string,
      description?: string | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyBatchesId?: string | null,
      studyPhaseBatchesId?: string | null,
    } | null,
    metadataImmunopeptidomicsList?:  {
      __typename: "ModelMetadataImmunopeptidomicsConnection",
      items:  Array< {
        __typename: "MetadataImmunopeptidomics",
        id: string,
        sampleId: string,
        metaDataTemplateS3Path: string,
        species: string,
        subtypeOfAnalysis?: string | null,
        sampleType: string,
        qcStatus: QcStatus,
        glacierBackup: boolean,
        rawDataS3: boolean,
        tumorVolume?: number | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        isControl: boolean,
        barcodesMappedToControl?: Array< string | null > | null,
        barcode: string,
        s3Path: Array< string | null >,
        sampleMetadataImmunopeptidomicsListId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    MetadataNanoStringList?:  {
      __typename: "ModelMetadataNanoStringConnection",
      items:  Array< {
        __typename: "MetadataNanoString",
        id: string,
        sampleId: string,
        metaDataTemplateS3Path: string,
        subtypeOfAnalysis?: string | null,
        species: string,
        sampleType: string,
        qcStatus: QcStatus,
        glacierBackup: boolean,
        rawDataS3: boolean,
        tumorVolume?: number | null,
        dv200Percentage?: number | null,
        concNanoGramPerMicroL?: number | null,
        rnaMassNanoGram?: number | null,
        rnaVolumeMicroL?: number | null,
        rin?: number | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        isControl: boolean,
        barcodesMappedToControl?: Array< string | null > | null,
        barcode: string,
        s3Path: Array< string | null >,
        sampleMetadataNanoStringListId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    MetadataRnaSeqList?:  {
      __typename: "ModelMetadataRnaSeqConnection",
      items:  Array< {
        __typename: "MetadataRnaSeq",
        id: string,
        sampleId: string,
        metaDataTemplateS3Path: string,
        subtypeOfAnalysis?: string | null,
        species: string,
        sampleType: string,
        qcStatus: QcStatus,
        glacierBackup: boolean,
        rawDataS3: boolean,
        tumorVolume?: number | null,
        sequenceType: SequenceType,
        strandSpecific: string,
        UMI: boolean,
        adaptorsRemoved: boolean,
        adaptorsS3Path?: string | null,
        indexSequences?: Array< string | null > | null,
        concNanoGramPerMicroL?: number | null,
        rnaMassNanoGram?: number | null,
        rnaVolumeMicroL?: number | null,
        rin?: number | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        isControl: boolean,
        barcodesMappedToControl?: Array< string | null > | null,
        barcode: string,
        s3Path: Array< string | null >,
        sampleMetadataRnaSeqListId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    AuxiliaryAnalysislList?:  {
      __typename: "ModelAuxiliaryAnalysisConnection",
      items:  Array< {
        __typename: "AuxiliaryAnalysis",
        id: string,
        sampleId: string,
        auxiliaryFileS3Path: string,
        vendorId: string,
        specimenType: string,
        parameters: string,
        collectionDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        sampleAuxiliaryAnalysislListId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    domainSampleId: string,
    experimentSpecificVariables?: string | null,
    collectionDate?: string | null,
    processingDate?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyPhaseSamplesId?: string | null,
    timepointSamplesId?: string | null,
    batchSamplesId?: string | null,
  } | null,
};

export type DeleteSampleMutationVariables = {
  input: DeleteSampleInput,
  condition?: ModelSampleConditionInput | null,
};

export type DeleteSampleMutation = {
  deleteSample?:  {
    __typename: "Sample",
    id: string,
    timepointId?: string | null,
    timepoint?:  {
      __typename: "Timepoint",
      id: string,
      patientId: string,
      patient?:  {
        __typename: "Patient",
        id: string,
        groupId: string,
        patientStudyPhaseId: string,
        patientStudyId: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        groupPatientsId?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      timepointName: string,
      s3Path?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      patientTimepointsId?: string | null,
    } | null,
    studyPhaseId?: string | null,
    StudyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    BatchId?: string | null,
    Batch?:  {
      __typename: "Batch",
      id: string,
      batchName: string,
      batchAnnotations?: string | null,
      batchGroups?: string | null,
      annotations: string,
      controlSamples: string,
      filters: string,
      description?: string | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyBatchesId?: string | null,
      studyPhaseBatchesId?: string | null,
    } | null,
    metadataImmunopeptidomicsList?:  {
      __typename: "ModelMetadataImmunopeptidomicsConnection",
      items:  Array< {
        __typename: "MetadataImmunopeptidomics",
        id: string,
        sampleId: string,
        metaDataTemplateS3Path: string,
        species: string,
        subtypeOfAnalysis?: string | null,
        sampleType: string,
        qcStatus: QcStatus,
        glacierBackup: boolean,
        rawDataS3: boolean,
        tumorVolume?: number | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        isControl: boolean,
        barcodesMappedToControl?: Array< string | null > | null,
        barcode: string,
        s3Path: Array< string | null >,
        sampleMetadataImmunopeptidomicsListId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    MetadataNanoStringList?:  {
      __typename: "ModelMetadataNanoStringConnection",
      items:  Array< {
        __typename: "MetadataNanoString",
        id: string,
        sampleId: string,
        metaDataTemplateS3Path: string,
        subtypeOfAnalysis?: string | null,
        species: string,
        sampleType: string,
        qcStatus: QcStatus,
        glacierBackup: boolean,
        rawDataS3: boolean,
        tumorVolume?: number | null,
        dv200Percentage?: number | null,
        concNanoGramPerMicroL?: number | null,
        rnaMassNanoGram?: number | null,
        rnaVolumeMicroL?: number | null,
        rin?: number | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        isControl: boolean,
        barcodesMappedToControl?: Array< string | null > | null,
        barcode: string,
        s3Path: Array< string | null >,
        sampleMetadataNanoStringListId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    MetadataRnaSeqList?:  {
      __typename: "ModelMetadataRnaSeqConnection",
      items:  Array< {
        __typename: "MetadataRnaSeq",
        id: string,
        sampleId: string,
        metaDataTemplateS3Path: string,
        subtypeOfAnalysis?: string | null,
        species: string,
        sampleType: string,
        qcStatus: QcStatus,
        glacierBackup: boolean,
        rawDataS3: boolean,
        tumorVolume?: number | null,
        sequenceType: SequenceType,
        strandSpecific: string,
        UMI: boolean,
        adaptorsRemoved: boolean,
        adaptorsS3Path?: string | null,
        indexSequences?: Array< string | null > | null,
        concNanoGramPerMicroL?: number | null,
        rnaMassNanoGram?: number | null,
        rnaVolumeMicroL?: number | null,
        rin?: number | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        isControl: boolean,
        barcodesMappedToControl?: Array< string | null > | null,
        barcode: string,
        s3Path: Array< string | null >,
        sampleMetadataRnaSeqListId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    AuxiliaryAnalysislList?:  {
      __typename: "ModelAuxiliaryAnalysisConnection",
      items:  Array< {
        __typename: "AuxiliaryAnalysis",
        id: string,
        sampleId: string,
        auxiliaryFileS3Path: string,
        vendorId: string,
        specimenType: string,
        parameters: string,
        collectionDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        sampleAuxiliaryAnalysislListId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    domainSampleId: string,
    experimentSpecificVariables?: string | null,
    collectionDate?: string | null,
    processingDate?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyPhaseSamplesId?: string | null,
    timepointSamplesId?: string | null,
    batchSamplesId?: string | null,
  } | null,
};

export type CreateBatchMutationVariables = {
  input: CreateBatchInput,
  condition?: ModelBatchConditionInput | null,
};

export type CreateBatchMutation = {
  createBatch?:  {
    __typename: "Batch",
    id: string,
    batchName: string,
    batchAnnotations?: string | null,
    batchGroups?: string | null,
    annotations: string,
    controlSamples: string,
    filters: string,
    description?: string | null,
    samples?:  {
      __typename: "ModelSampleConnection",
      items:  Array< {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pipelines?:  {
      __typename: "ModelPipelineConnection",
      items:  Array< {
        __typename: "Pipeline",
        id: string,
        name: string,
        status?: pipelineStatus | null,
        description?: string | null,
        s3Event?: string | null,
        s3ParamsFile?: string | null,
        s3Results?: string | null,
        visFileInfoS3path?: string | null,
        s3NfLog?: string | null,
        s3NfTasksLogs?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis?: string | null,
        parameters: string,
        studyId?: string | null,
        studyPhaseId?: string | null,
        scheduledPipeline: boolean,
        scheduledDateTime?: string | null,
        launchablePipelineId: string,
        batchId: string,
        runId?: string | null,
        userEmail: string,
        pipelineStarted?: string | null,
        pipelineCompleted?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPipelinesId?: string | null,
        studyPhasePipelinesId?: string | null,
        batchPipelinesId?: string | null,
        launchablePipelinesPipelinesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    studyId: string,
    study?:  {
      __typename: "Study",
      id: string,
      studyName: string,
      organizationId: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        organizationName: string,
        organizationCode?: string | null,
        active: boolean,
        notificationEmail: string,
        description: string,
        organizationAnswers?: string | null,
        url: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        created: string,
        updated: string,
      } | null,
      studyPhases?:  {
        __typename: "ModelStudyPhaseConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      description: string,
      studyCode: string,
      studyAnswers?: string | null,
      s3Path?: string | null,
      croGroups?: Array< string | null > | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      organizationStudiesId?: string | null,
    } | null,
    studyPhaseId?: string | null,
    studyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyBatchesId?: string | null,
    studyPhaseBatchesId?: string | null,
  } | null,
};

export type UpdateBatchMutationVariables = {
  input: UpdateBatchInput,
  condition?: ModelBatchConditionInput | null,
};

export type UpdateBatchMutation = {
  updateBatch?:  {
    __typename: "Batch",
    id: string,
    batchName: string,
    batchAnnotations?: string | null,
    batchGroups?: string | null,
    annotations: string,
    controlSamples: string,
    filters: string,
    description?: string | null,
    samples?:  {
      __typename: "ModelSampleConnection",
      items:  Array< {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pipelines?:  {
      __typename: "ModelPipelineConnection",
      items:  Array< {
        __typename: "Pipeline",
        id: string,
        name: string,
        status?: pipelineStatus | null,
        description?: string | null,
        s3Event?: string | null,
        s3ParamsFile?: string | null,
        s3Results?: string | null,
        visFileInfoS3path?: string | null,
        s3NfLog?: string | null,
        s3NfTasksLogs?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis?: string | null,
        parameters: string,
        studyId?: string | null,
        studyPhaseId?: string | null,
        scheduledPipeline: boolean,
        scheduledDateTime?: string | null,
        launchablePipelineId: string,
        batchId: string,
        runId?: string | null,
        userEmail: string,
        pipelineStarted?: string | null,
        pipelineCompleted?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPipelinesId?: string | null,
        studyPhasePipelinesId?: string | null,
        batchPipelinesId?: string | null,
        launchablePipelinesPipelinesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    studyId: string,
    study?:  {
      __typename: "Study",
      id: string,
      studyName: string,
      organizationId: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        organizationName: string,
        organizationCode?: string | null,
        active: boolean,
        notificationEmail: string,
        description: string,
        organizationAnswers?: string | null,
        url: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        created: string,
        updated: string,
      } | null,
      studyPhases?:  {
        __typename: "ModelStudyPhaseConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      description: string,
      studyCode: string,
      studyAnswers?: string | null,
      s3Path?: string | null,
      croGroups?: Array< string | null > | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      organizationStudiesId?: string | null,
    } | null,
    studyPhaseId?: string | null,
    studyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyBatchesId?: string | null,
    studyPhaseBatchesId?: string | null,
  } | null,
};

export type DeleteBatchMutationVariables = {
  input: DeleteBatchInput,
  condition?: ModelBatchConditionInput | null,
};

export type DeleteBatchMutation = {
  deleteBatch?:  {
    __typename: "Batch",
    id: string,
    batchName: string,
    batchAnnotations?: string | null,
    batchGroups?: string | null,
    annotations: string,
    controlSamples: string,
    filters: string,
    description?: string | null,
    samples?:  {
      __typename: "ModelSampleConnection",
      items:  Array< {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pipelines?:  {
      __typename: "ModelPipelineConnection",
      items:  Array< {
        __typename: "Pipeline",
        id: string,
        name: string,
        status?: pipelineStatus | null,
        description?: string | null,
        s3Event?: string | null,
        s3ParamsFile?: string | null,
        s3Results?: string | null,
        visFileInfoS3path?: string | null,
        s3NfLog?: string | null,
        s3NfTasksLogs?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis?: string | null,
        parameters: string,
        studyId?: string | null,
        studyPhaseId?: string | null,
        scheduledPipeline: boolean,
        scheduledDateTime?: string | null,
        launchablePipelineId: string,
        batchId: string,
        runId?: string | null,
        userEmail: string,
        pipelineStarted?: string | null,
        pipelineCompleted?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPipelinesId?: string | null,
        studyPhasePipelinesId?: string | null,
        batchPipelinesId?: string | null,
        launchablePipelinesPipelinesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    studyId: string,
    study?:  {
      __typename: "Study",
      id: string,
      studyName: string,
      organizationId: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        organizationName: string,
        organizationCode?: string | null,
        active: boolean,
        notificationEmail: string,
        description: string,
        organizationAnswers?: string | null,
        url: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        created: string,
        updated: string,
      } | null,
      studyPhases?:  {
        __typename: "ModelStudyPhaseConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      description: string,
      studyCode: string,
      studyAnswers?: string | null,
      s3Path?: string | null,
      croGroups?: Array< string | null > | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      organizationStudiesId?: string | null,
    } | null,
    studyPhaseId?: string | null,
    studyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyBatchesId?: string | null,
    studyPhaseBatchesId?: string | null,
  } | null,
};

export type CreatePipelineMutationVariables = {
  input: CreatePipelineInput,
  condition?: ModelPipelineConditionInput | null,
};

export type CreatePipelineMutation = {
  createPipeline?:  {
    __typename: "Pipeline",
    id: string,
    name: string,
    status?: pipelineStatus | null,
    description?: string | null,
    s3Event?: string | null,
    s3ParamsFile?: string | null,
    s3Results?: string | null,
    visFileInfoS3path?: string | null,
    s3NfLog?: string | null,
    s3NfTasksLogs?: Array< string | null > | null,
    analysisType: AnalysisType,
    subtypeOfAnalysis?: string | null,
    parameters: string,
    studyId?: string | null,
    study?:  {
      __typename: "Study",
      id: string,
      studyName: string,
      organizationId: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        organizationName: string,
        organizationCode?: string | null,
        active: boolean,
        notificationEmail: string,
        description: string,
        organizationAnswers?: string | null,
        url: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        created: string,
        updated: string,
      } | null,
      studyPhases?:  {
        __typename: "ModelStudyPhaseConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      description: string,
      studyCode: string,
      studyAnswers?: string | null,
      s3Path?: string | null,
      croGroups?: Array< string | null > | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      organizationStudiesId?: string | null,
    } | null,
    studyPhaseId?: string | null,
    studyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    scheduledPipeline: boolean,
    scheduledDateTime?: string | null,
    launchablePipelineId: string,
    launchablePipeline?:  {
      __typename: "LaunchablePipelines",
      id: string,
      pipelineName?: string | null,
      organizations?:  {
        __typename: "ModelOrganizationLaunchablePipelinesConnection",
        nextToken?: string | null,
      } | null,
      pipelineVersion?: string | null,
      parameters?: string | null,
      description?: string | null,
      inputFileInfo?: string | null,
      supportedAnalyses?: Array< AnalysisType | null > | null,
      supportedSubtypeOfAnalyses?: Array< string | null > | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      gitRepo?: string | null,
      workflowId?: string | null,
      created: string,
      updated: string,
    } | null,
    batchId: string,
    batch?:  {
      __typename: "Batch",
      id: string,
      batchName: string,
      batchAnnotations?: string | null,
      batchGroups?: string | null,
      annotations: string,
      controlSamples: string,
      filters: string,
      description?: string | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyBatchesId?: string | null,
      studyPhaseBatchesId?: string | null,
    } | null,
    runId?: string | null,
    userEmail: string,
    pipelineStarted?: string | null,
    pipelineCompleted?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyPipelinesId?: string | null,
    studyPhasePipelinesId?: string | null,
    batchPipelinesId?: string | null,
    launchablePipelinesPipelinesId?: string | null,
  } | null,
};

export type UpdatePipelineMutationVariables = {
  input: UpdatePipelineInput,
  condition?: ModelPipelineConditionInput | null,
};

export type UpdatePipelineMutation = {
  updatePipeline?:  {
    __typename: "Pipeline",
    id: string,
    name: string,
    status?: pipelineStatus | null,
    description?: string | null,
    s3Event?: string | null,
    s3ParamsFile?: string | null,
    s3Results?: string | null,
    visFileInfoS3path?: string | null,
    s3NfLog?: string | null,
    s3NfTasksLogs?: Array< string | null > | null,
    analysisType: AnalysisType,
    subtypeOfAnalysis?: string | null,
    parameters: string,
    studyId?: string | null,
    study?:  {
      __typename: "Study",
      id: string,
      studyName: string,
      organizationId: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        organizationName: string,
        organizationCode?: string | null,
        active: boolean,
        notificationEmail: string,
        description: string,
        organizationAnswers?: string | null,
        url: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        created: string,
        updated: string,
      } | null,
      studyPhases?:  {
        __typename: "ModelStudyPhaseConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      description: string,
      studyCode: string,
      studyAnswers?: string | null,
      s3Path?: string | null,
      croGroups?: Array< string | null > | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      organizationStudiesId?: string | null,
    } | null,
    studyPhaseId?: string | null,
    studyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    scheduledPipeline: boolean,
    scheduledDateTime?: string | null,
    launchablePipelineId: string,
    launchablePipeline?:  {
      __typename: "LaunchablePipelines",
      id: string,
      pipelineName?: string | null,
      organizations?:  {
        __typename: "ModelOrganizationLaunchablePipelinesConnection",
        nextToken?: string | null,
      } | null,
      pipelineVersion?: string | null,
      parameters?: string | null,
      description?: string | null,
      inputFileInfo?: string | null,
      supportedAnalyses?: Array< AnalysisType | null > | null,
      supportedSubtypeOfAnalyses?: Array< string | null > | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      gitRepo?: string | null,
      workflowId?: string | null,
      created: string,
      updated: string,
    } | null,
    batchId: string,
    batch?:  {
      __typename: "Batch",
      id: string,
      batchName: string,
      batchAnnotations?: string | null,
      batchGroups?: string | null,
      annotations: string,
      controlSamples: string,
      filters: string,
      description?: string | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyBatchesId?: string | null,
      studyPhaseBatchesId?: string | null,
    } | null,
    runId?: string | null,
    userEmail: string,
    pipelineStarted?: string | null,
    pipelineCompleted?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyPipelinesId?: string | null,
    studyPhasePipelinesId?: string | null,
    batchPipelinesId?: string | null,
    launchablePipelinesPipelinesId?: string | null,
  } | null,
};

export type DeletePipelineMutationVariables = {
  input: DeletePipelineInput,
  condition?: ModelPipelineConditionInput | null,
};

export type DeletePipelineMutation = {
  deletePipeline?:  {
    __typename: "Pipeline",
    id: string,
    name: string,
    status?: pipelineStatus | null,
    description?: string | null,
    s3Event?: string | null,
    s3ParamsFile?: string | null,
    s3Results?: string | null,
    visFileInfoS3path?: string | null,
    s3NfLog?: string | null,
    s3NfTasksLogs?: Array< string | null > | null,
    analysisType: AnalysisType,
    subtypeOfAnalysis?: string | null,
    parameters: string,
    studyId?: string | null,
    study?:  {
      __typename: "Study",
      id: string,
      studyName: string,
      organizationId: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        organizationName: string,
        organizationCode?: string | null,
        active: boolean,
        notificationEmail: string,
        description: string,
        organizationAnswers?: string | null,
        url: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        created: string,
        updated: string,
      } | null,
      studyPhases?:  {
        __typename: "ModelStudyPhaseConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      description: string,
      studyCode: string,
      studyAnswers?: string | null,
      s3Path?: string | null,
      croGroups?: Array< string | null > | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      organizationStudiesId?: string | null,
    } | null,
    studyPhaseId?: string | null,
    studyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    scheduledPipeline: boolean,
    scheduledDateTime?: string | null,
    launchablePipelineId: string,
    launchablePipeline?:  {
      __typename: "LaunchablePipelines",
      id: string,
      pipelineName?: string | null,
      organizations?:  {
        __typename: "ModelOrganizationLaunchablePipelinesConnection",
        nextToken?: string | null,
      } | null,
      pipelineVersion?: string | null,
      parameters?: string | null,
      description?: string | null,
      inputFileInfo?: string | null,
      supportedAnalyses?: Array< AnalysisType | null > | null,
      supportedSubtypeOfAnalyses?: Array< string | null > | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      gitRepo?: string | null,
      workflowId?: string | null,
      created: string,
      updated: string,
    } | null,
    batchId: string,
    batch?:  {
      __typename: "Batch",
      id: string,
      batchName: string,
      batchAnnotations?: string | null,
      batchGroups?: string | null,
      annotations: string,
      controlSamples: string,
      filters: string,
      description?: string | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyBatchesId?: string | null,
      studyPhaseBatchesId?: string | null,
    } | null,
    runId?: string | null,
    userEmail: string,
    pipelineStarted?: string | null,
    pipelineCompleted?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyPipelinesId?: string | null,
    studyPhasePipelinesId?: string | null,
    batchPipelinesId?: string | null,
    launchablePipelinesPipelinesId?: string | null,
  } | null,
};

export type CreateAuxiliaryAnalysisMutationVariables = {
  input: CreateAuxiliaryAnalysisInput,
  condition?: ModelAuxiliaryAnalysisConditionInput | null,
};

export type CreateAuxiliaryAnalysisMutation = {
  createAuxiliaryAnalysis?:  {
    __typename: "AuxiliaryAnalysis",
    id: string,
    sampleId: string,
    sample?:  {
      __typename: "Sample",
      id: string,
      timepointId?: string | null,
      timepoint?:  {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      StudyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      BatchId?: string | null,
      Batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      metadataImmunopeptidomicsList?:  {
        __typename: "ModelMetadataImmunopeptidomicsConnection",
        nextToken?: string | null,
      } | null,
      MetadataNanoStringList?:  {
        __typename: "ModelMetadataNanoStringConnection",
        nextToken?: string | null,
      } | null,
      MetadataRnaSeqList?:  {
        __typename: "ModelMetadataRnaSeqConnection",
        nextToken?: string | null,
      } | null,
      AuxiliaryAnalysislList?:  {
        __typename: "ModelAuxiliaryAnalysisConnection",
        nextToken?: string | null,
      } | null,
      domainSampleId: string,
      experimentSpecificVariables?: string | null,
      collectionDate?: string | null,
      processingDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseSamplesId?: string | null,
      timepointSamplesId?: string | null,
      batchSamplesId?: string | null,
    } | null,
    auxiliaryFileS3Path: string,
    vendorId: string,
    specimenType: string,
    parameters: string,
    collectionDate?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    sampleAuxiliaryAnalysislListId?: string | null,
  } | null,
};

export type UpdateAuxiliaryAnalysisMutationVariables = {
  input: UpdateAuxiliaryAnalysisInput,
  condition?: ModelAuxiliaryAnalysisConditionInput | null,
};

export type UpdateAuxiliaryAnalysisMutation = {
  updateAuxiliaryAnalysis?:  {
    __typename: "AuxiliaryAnalysis",
    id: string,
    sampleId: string,
    sample?:  {
      __typename: "Sample",
      id: string,
      timepointId?: string | null,
      timepoint?:  {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      StudyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      BatchId?: string | null,
      Batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      metadataImmunopeptidomicsList?:  {
        __typename: "ModelMetadataImmunopeptidomicsConnection",
        nextToken?: string | null,
      } | null,
      MetadataNanoStringList?:  {
        __typename: "ModelMetadataNanoStringConnection",
        nextToken?: string | null,
      } | null,
      MetadataRnaSeqList?:  {
        __typename: "ModelMetadataRnaSeqConnection",
        nextToken?: string | null,
      } | null,
      AuxiliaryAnalysislList?:  {
        __typename: "ModelAuxiliaryAnalysisConnection",
        nextToken?: string | null,
      } | null,
      domainSampleId: string,
      experimentSpecificVariables?: string | null,
      collectionDate?: string | null,
      processingDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseSamplesId?: string | null,
      timepointSamplesId?: string | null,
      batchSamplesId?: string | null,
    } | null,
    auxiliaryFileS3Path: string,
    vendorId: string,
    specimenType: string,
    parameters: string,
    collectionDate?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    sampleAuxiliaryAnalysislListId?: string | null,
  } | null,
};

export type DeleteAuxiliaryAnalysisMutationVariables = {
  input: DeleteAuxiliaryAnalysisInput,
  condition?: ModelAuxiliaryAnalysisConditionInput | null,
};

export type DeleteAuxiliaryAnalysisMutation = {
  deleteAuxiliaryAnalysis?:  {
    __typename: "AuxiliaryAnalysis",
    id: string,
    sampleId: string,
    sample?:  {
      __typename: "Sample",
      id: string,
      timepointId?: string | null,
      timepoint?:  {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      StudyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      BatchId?: string | null,
      Batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      metadataImmunopeptidomicsList?:  {
        __typename: "ModelMetadataImmunopeptidomicsConnection",
        nextToken?: string | null,
      } | null,
      MetadataNanoStringList?:  {
        __typename: "ModelMetadataNanoStringConnection",
        nextToken?: string | null,
      } | null,
      MetadataRnaSeqList?:  {
        __typename: "ModelMetadataRnaSeqConnection",
        nextToken?: string | null,
      } | null,
      AuxiliaryAnalysislList?:  {
        __typename: "ModelAuxiliaryAnalysisConnection",
        nextToken?: string | null,
      } | null,
      domainSampleId: string,
      experimentSpecificVariables?: string | null,
      collectionDate?: string | null,
      processingDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseSamplesId?: string | null,
      timepointSamplesId?: string | null,
      batchSamplesId?: string | null,
    } | null,
    auxiliaryFileS3Path: string,
    vendorId: string,
    specimenType: string,
    parameters: string,
    collectionDate?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    sampleAuxiliaryAnalysislListId?: string | null,
  } | null,
};

export type CreateMetadataImmunopeptidomicsMutationVariables = {
  input: CreateMetadataImmunopeptidomicsInput,
  condition?: ModelMetadataImmunopeptidomicsConditionInput | null,
};

export type CreateMetadataImmunopeptidomicsMutation = {
  createMetadataImmunopeptidomics?:  {
    __typename: "MetadataImmunopeptidomics",
    id: string,
    sampleId: string,
    sample?:  {
      __typename: "Sample",
      id: string,
      timepointId?: string | null,
      timepoint?:  {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      StudyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      BatchId?: string | null,
      Batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      metadataImmunopeptidomicsList?:  {
        __typename: "ModelMetadataImmunopeptidomicsConnection",
        nextToken?: string | null,
      } | null,
      MetadataNanoStringList?:  {
        __typename: "ModelMetadataNanoStringConnection",
        nextToken?: string | null,
      } | null,
      MetadataRnaSeqList?:  {
        __typename: "ModelMetadataRnaSeqConnection",
        nextToken?: string | null,
      } | null,
      AuxiliaryAnalysislList?:  {
        __typename: "ModelAuxiliaryAnalysisConnection",
        nextToken?: string | null,
      } | null,
      domainSampleId: string,
      experimentSpecificVariables?: string | null,
      collectionDate?: string | null,
      processingDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseSamplesId?: string | null,
      timepointSamplesId?: string | null,
      batchSamplesId?: string | null,
    } | null,
    treatment?:  {
      __typename: "ModelTreatmentConnection",
      items:  Array< {
        __typename: "Treatment",
        id: string,
        metadataImmunopeptidomicsId?: string | null,
        metadataNanoStringId?: string | null,
        metadataRnaSeqId?: string | null,
        treatmentName: string,
        dose: number,
        doseUnit: string,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        metadataImmunopeptidomicsTreatmentId?: string | null,
        metadataNanoStringTreatmentId?: string | null,
        metadataRnaSeqTreatmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    metaDataTemplateS3Path: string,
    species: string,
    subtypeOfAnalysis?: string | null,
    sampleType: string,
    qcStatus: QcStatus,
    glacierBackup: boolean,
    rawDataS3: boolean,
    tumorVolume?: number | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    isControl: boolean,
    barcodesMappedToControl?: Array< string | null > | null,
    barcode: string,
    s3Path: Array< string | null >,
    sampleMetadataImmunopeptidomicsListId?: string | null,
  } | null,
};

export type UpdateMetadataImmunopeptidomicsMutationVariables = {
  input: UpdateMetadataImmunopeptidomicsInput,
  condition?: ModelMetadataImmunopeptidomicsConditionInput | null,
};

export type UpdateMetadataImmunopeptidomicsMutation = {
  updateMetadataImmunopeptidomics?:  {
    __typename: "MetadataImmunopeptidomics",
    id: string,
    sampleId: string,
    sample?:  {
      __typename: "Sample",
      id: string,
      timepointId?: string | null,
      timepoint?:  {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      StudyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      BatchId?: string | null,
      Batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      metadataImmunopeptidomicsList?:  {
        __typename: "ModelMetadataImmunopeptidomicsConnection",
        nextToken?: string | null,
      } | null,
      MetadataNanoStringList?:  {
        __typename: "ModelMetadataNanoStringConnection",
        nextToken?: string | null,
      } | null,
      MetadataRnaSeqList?:  {
        __typename: "ModelMetadataRnaSeqConnection",
        nextToken?: string | null,
      } | null,
      AuxiliaryAnalysislList?:  {
        __typename: "ModelAuxiliaryAnalysisConnection",
        nextToken?: string | null,
      } | null,
      domainSampleId: string,
      experimentSpecificVariables?: string | null,
      collectionDate?: string | null,
      processingDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseSamplesId?: string | null,
      timepointSamplesId?: string | null,
      batchSamplesId?: string | null,
    } | null,
    treatment?:  {
      __typename: "ModelTreatmentConnection",
      items:  Array< {
        __typename: "Treatment",
        id: string,
        metadataImmunopeptidomicsId?: string | null,
        metadataNanoStringId?: string | null,
        metadataRnaSeqId?: string | null,
        treatmentName: string,
        dose: number,
        doseUnit: string,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        metadataImmunopeptidomicsTreatmentId?: string | null,
        metadataNanoStringTreatmentId?: string | null,
        metadataRnaSeqTreatmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    metaDataTemplateS3Path: string,
    species: string,
    subtypeOfAnalysis?: string | null,
    sampleType: string,
    qcStatus: QcStatus,
    glacierBackup: boolean,
    rawDataS3: boolean,
    tumorVolume?: number | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    isControl: boolean,
    barcodesMappedToControl?: Array< string | null > | null,
    barcode: string,
    s3Path: Array< string | null >,
    sampleMetadataImmunopeptidomicsListId?: string | null,
  } | null,
};

export type DeleteMetadataImmunopeptidomicsMutationVariables = {
  input: DeleteMetadataImmunopeptidomicsInput,
  condition?: ModelMetadataImmunopeptidomicsConditionInput | null,
};

export type DeleteMetadataImmunopeptidomicsMutation = {
  deleteMetadataImmunopeptidomics?:  {
    __typename: "MetadataImmunopeptidomics",
    id: string,
    sampleId: string,
    sample?:  {
      __typename: "Sample",
      id: string,
      timepointId?: string | null,
      timepoint?:  {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      StudyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      BatchId?: string | null,
      Batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      metadataImmunopeptidomicsList?:  {
        __typename: "ModelMetadataImmunopeptidomicsConnection",
        nextToken?: string | null,
      } | null,
      MetadataNanoStringList?:  {
        __typename: "ModelMetadataNanoStringConnection",
        nextToken?: string | null,
      } | null,
      MetadataRnaSeqList?:  {
        __typename: "ModelMetadataRnaSeqConnection",
        nextToken?: string | null,
      } | null,
      AuxiliaryAnalysislList?:  {
        __typename: "ModelAuxiliaryAnalysisConnection",
        nextToken?: string | null,
      } | null,
      domainSampleId: string,
      experimentSpecificVariables?: string | null,
      collectionDate?: string | null,
      processingDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseSamplesId?: string | null,
      timepointSamplesId?: string | null,
      batchSamplesId?: string | null,
    } | null,
    treatment?:  {
      __typename: "ModelTreatmentConnection",
      items:  Array< {
        __typename: "Treatment",
        id: string,
        metadataImmunopeptidomicsId?: string | null,
        metadataNanoStringId?: string | null,
        metadataRnaSeqId?: string | null,
        treatmentName: string,
        dose: number,
        doseUnit: string,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        metadataImmunopeptidomicsTreatmentId?: string | null,
        metadataNanoStringTreatmentId?: string | null,
        metadataRnaSeqTreatmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    metaDataTemplateS3Path: string,
    species: string,
    subtypeOfAnalysis?: string | null,
    sampleType: string,
    qcStatus: QcStatus,
    glacierBackup: boolean,
    rawDataS3: boolean,
    tumorVolume?: number | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    isControl: boolean,
    barcodesMappedToControl?: Array< string | null > | null,
    barcode: string,
    s3Path: Array< string | null >,
    sampleMetadataImmunopeptidomicsListId?: string | null,
  } | null,
};

export type CreateMetadataNanoStringMutationVariables = {
  input: CreateMetadataNanoStringInput,
  condition?: ModelMetadataNanoStringConditionInput | null,
};

export type CreateMetadataNanoStringMutation = {
  createMetadataNanoString?:  {
    __typename: "MetadataNanoString",
    id: string,
    sampleId: string,
    sample?:  {
      __typename: "Sample",
      id: string,
      timepointId?: string | null,
      timepoint?:  {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      StudyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      BatchId?: string | null,
      Batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      metadataImmunopeptidomicsList?:  {
        __typename: "ModelMetadataImmunopeptidomicsConnection",
        nextToken?: string | null,
      } | null,
      MetadataNanoStringList?:  {
        __typename: "ModelMetadataNanoStringConnection",
        nextToken?: string | null,
      } | null,
      MetadataRnaSeqList?:  {
        __typename: "ModelMetadataRnaSeqConnection",
        nextToken?: string | null,
      } | null,
      AuxiliaryAnalysislList?:  {
        __typename: "ModelAuxiliaryAnalysisConnection",
        nextToken?: string | null,
      } | null,
      domainSampleId: string,
      experimentSpecificVariables?: string | null,
      collectionDate?: string | null,
      processingDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseSamplesId?: string | null,
      timepointSamplesId?: string | null,
      batchSamplesId?: string | null,
    } | null,
    treatment?:  {
      __typename: "ModelTreatmentConnection",
      items:  Array< {
        __typename: "Treatment",
        id: string,
        metadataImmunopeptidomicsId?: string | null,
        metadataNanoStringId?: string | null,
        metadataRnaSeqId?: string | null,
        treatmentName: string,
        dose: number,
        doseUnit: string,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        metadataImmunopeptidomicsTreatmentId?: string | null,
        metadataNanoStringTreatmentId?: string | null,
        metadataRnaSeqTreatmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    metaDataTemplateS3Path: string,
    subtypeOfAnalysis?: string | null,
    species: string,
    sampleType: string,
    qcStatus: QcStatus,
    glacierBackup: boolean,
    rawDataS3: boolean,
    tumorVolume?: number | null,
    dv200Percentage?: number | null,
    concNanoGramPerMicroL?: number | null,
    rnaMassNanoGram?: number | null,
    rnaVolumeMicroL?: number | null,
    rin?: number | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    isControl: boolean,
    barcodesMappedToControl?: Array< string | null > | null,
    barcode: string,
    s3Path: Array< string | null >,
    sampleMetadataNanoStringListId?: string | null,
  } | null,
};

export type UpdateMetadataNanoStringMutationVariables = {
  input: UpdateMetadataNanoStringInput,
  condition?: ModelMetadataNanoStringConditionInput | null,
};

export type UpdateMetadataNanoStringMutation = {
  updateMetadataNanoString?:  {
    __typename: "MetadataNanoString",
    id: string,
    sampleId: string,
    sample?:  {
      __typename: "Sample",
      id: string,
      timepointId?: string | null,
      timepoint?:  {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      StudyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      BatchId?: string | null,
      Batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      metadataImmunopeptidomicsList?:  {
        __typename: "ModelMetadataImmunopeptidomicsConnection",
        nextToken?: string | null,
      } | null,
      MetadataNanoStringList?:  {
        __typename: "ModelMetadataNanoStringConnection",
        nextToken?: string | null,
      } | null,
      MetadataRnaSeqList?:  {
        __typename: "ModelMetadataRnaSeqConnection",
        nextToken?: string | null,
      } | null,
      AuxiliaryAnalysislList?:  {
        __typename: "ModelAuxiliaryAnalysisConnection",
        nextToken?: string | null,
      } | null,
      domainSampleId: string,
      experimentSpecificVariables?: string | null,
      collectionDate?: string | null,
      processingDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseSamplesId?: string | null,
      timepointSamplesId?: string | null,
      batchSamplesId?: string | null,
    } | null,
    treatment?:  {
      __typename: "ModelTreatmentConnection",
      items:  Array< {
        __typename: "Treatment",
        id: string,
        metadataImmunopeptidomicsId?: string | null,
        metadataNanoStringId?: string | null,
        metadataRnaSeqId?: string | null,
        treatmentName: string,
        dose: number,
        doseUnit: string,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        metadataImmunopeptidomicsTreatmentId?: string | null,
        metadataNanoStringTreatmentId?: string | null,
        metadataRnaSeqTreatmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    metaDataTemplateS3Path: string,
    subtypeOfAnalysis?: string | null,
    species: string,
    sampleType: string,
    qcStatus: QcStatus,
    glacierBackup: boolean,
    rawDataS3: boolean,
    tumorVolume?: number | null,
    dv200Percentage?: number | null,
    concNanoGramPerMicroL?: number | null,
    rnaMassNanoGram?: number | null,
    rnaVolumeMicroL?: number | null,
    rin?: number | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    isControl: boolean,
    barcodesMappedToControl?: Array< string | null > | null,
    barcode: string,
    s3Path: Array< string | null >,
    sampleMetadataNanoStringListId?: string | null,
  } | null,
};

export type DeleteMetadataNanoStringMutationVariables = {
  input: DeleteMetadataNanoStringInput,
  condition?: ModelMetadataNanoStringConditionInput | null,
};

export type DeleteMetadataNanoStringMutation = {
  deleteMetadataNanoString?:  {
    __typename: "MetadataNanoString",
    id: string,
    sampleId: string,
    sample?:  {
      __typename: "Sample",
      id: string,
      timepointId?: string | null,
      timepoint?:  {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      StudyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      BatchId?: string | null,
      Batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      metadataImmunopeptidomicsList?:  {
        __typename: "ModelMetadataImmunopeptidomicsConnection",
        nextToken?: string | null,
      } | null,
      MetadataNanoStringList?:  {
        __typename: "ModelMetadataNanoStringConnection",
        nextToken?: string | null,
      } | null,
      MetadataRnaSeqList?:  {
        __typename: "ModelMetadataRnaSeqConnection",
        nextToken?: string | null,
      } | null,
      AuxiliaryAnalysislList?:  {
        __typename: "ModelAuxiliaryAnalysisConnection",
        nextToken?: string | null,
      } | null,
      domainSampleId: string,
      experimentSpecificVariables?: string | null,
      collectionDate?: string | null,
      processingDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseSamplesId?: string | null,
      timepointSamplesId?: string | null,
      batchSamplesId?: string | null,
    } | null,
    treatment?:  {
      __typename: "ModelTreatmentConnection",
      items:  Array< {
        __typename: "Treatment",
        id: string,
        metadataImmunopeptidomicsId?: string | null,
        metadataNanoStringId?: string | null,
        metadataRnaSeqId?: string | null,
        treatmentName: string,
        dose: number,
        doseUnit: string,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        metadataImmunopeptidomicsTreatmentId?: string | null,
        metadataNanoStringTreatmentId?: string | null,
        metadataRnaSeqTreatmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    metaDataTemplateS3Path: string,
    subtypeOfAnalysis?: string | null,
    species: string,
    sampleType: string,
    qcStatus: QcStatus,
    glacierBackup: boolean,
    rawDataS3: boolean,
    tumorVolume?: number | null,
    dv200Percentage?: number | null,
    concNanoGramPerMicroL?: number | null,
    rnaMassNanoGram?: number | null,
    rnaVolumeMicroL?: number | null,
    rin?: number | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    isControl: boolean,
    barcodesMappedToControl?: Array< string | null > | null,
    barcode: string,
    s3Path: Array< string | null >,
    sampleMetadataNanoStringListId?: string | null,
  } | null,
};

export type CreateMetadataRnaSeqMutationVariables = {
  input: CreateMetadataRnaSeqInput,
  condition?: ModelMetadataRnaSeqConditionInput | null,
};

export type CreateMetadataRnaSeqMutation = {
  createMetadataRnaSeq?:  {
    __typename: "MetadataRnaSeq",
    id: string,
    sampleId: string,
    sample?:  {
      __typename: "Sample",
      id: string,
      timepointId?: string | null,
      timepoint?:  {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      StudyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      BatchId?: string | null,
      Batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      metadataImmunopeptidomicsList?:  {
        __typename: "ModelMetadataImmunopeptidomicsConnection",
        nextToken?: string | null,
      } | null,
      MetadataNanoStringList?:  {
        __typename: "ModelMetadataNanoStringConnection",
        nextToken?: string | null,
      } | null,
      MetadataRnaSeqList?:  {
        __typename: "ModelMetadataRnaSeqConnection",
        nextToken?: string | null,
      } | null,
      AuxiliaryAnalysislList?:  {
        __typename: "ModelAuxiliaryAnalysisConnection",
        nextToken?: string | null,
      } | null,
      domainSampleId: string,
      experimentSpecificVariables?: string | null,
      collectionDate?: string | null,
      processingDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseSamplesId?: string | null,
      timepointSamplesId?: string | null,
      batchSamplesId?: string | null,
    } | null,
    treatment?:  {
      __typename: "ModelTreatmentConnection",
      items:  Array< {
        __typename: "Treatment",
        id: string,
        metadataImmunopeptidomicsId?: string | null,
        metadataNanoStringId?: string | null,
        metadataRnaSeqId?: string | null,
        treatmentName: string,
        dose: number,
        doseUnit: string,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        metadataImmunopeptidomicsTreatmentId?: string | null,
        metadataNanoStringTreatmentId?: string | null,
        metadataRnaSeqTreatmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    metaDataTemplateS3Path: string,
    subtypeOfAnalysis?: string | null,
    species: string,
    sampleType: string,
    qcStatus: QcStatus,
    glacierBackup: boolean,
    rawDataS3: boolean,
    tumorVolume?: number | null,
    sequenceType: SequenceType,
    strandSpecific: string,
    UMI: boolean,
    adaptorsRemoved: boolean,
    adaptorsS3Path?: string | null,
    indexSequences?: Array< string | null > | null,
    concNanoGramPerMicroL?: number | null,
    rnaMassNanoGram?: number | null,
    rnaVolumeMicroL?: number | null,
    rin?: number | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    isControl: boolean,
    barcodesMappedToControl?: Array< string | null > | null,
    barcode: string,
    s3Path: Array< string | null >,
    sampleMetadataRnaSeqListId?: string | null,
  } | null,
};

export type UpdateMetadataRnaSeqMutationVariables = {
  input: UpdateMetadataRnaSeqInput,
  condition?: ModelMetadataRnaSeqConditionInput | null,
};

export type UpdateMetadataRnaSeqMutation = {
  updateMetadataRnaSeq?:  {
    __typename: "MetadataRnaSeq",
    id: string,
    sampleId: string,
    sample?:  {
      __typename: "Sample",
      id: string,
      timepointId?: string | null,
      timepoint?:  {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      StudyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      BatchId?: string | null,
      Batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      metadataImmunopeptidomicsList?:  {
        __typename: "ModelMetadataImmunopeptidomicsConnection",
        nextToken?: string | null,
      } | null,
      MetadataNanoStringList?:  {
        __typename: "ModelMetadataNanoStringConnection",
        nextToken?: string | null,
      } | null,
      MetadataRnaSeqList?:  {
        __typename: "ModelMetadataRnaSeqConnection",
        nextToken?: string | null,
      } | null,
      AuxiliaryAnalysislList?:  {
        __typename: "ModelAuxiliaryAnalysisConnection",
        nextToken?: string | null,
      } | null,
      domainSampleId: string,
      experimentSpecificVariables?: string | null,
      collectionDate?: string | null,
      processingDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseSamplesId?: string | null,
      timepointSamplesId?: string | null,
      batchSamplesId?: string | null,
    } | null,
    treatment?:  {
      __typename: "ModelTreatmentConnection",
      items:  Array< {
        __typename: "Treatment",
        id: string,
        metadataImmunopeptidomicsId?: string | null,
        metadataNanoStringId?: string | null,
        metadataRnaSeqId?: string | null,
        treatmentName: string,
        dose: number,
        doseUnit: string,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        metadataImmunopeptidomicsTreatmentId?: string | null,
        metadataNanoStringTreatmentId?: string | null,
        metadataRnaSeqTreatmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    metaDataTemplateS3Path: string,
    subtypeOfAnalysis?: string | null,
    species: string,
    sampleType: string,
    qcStatus: QcStatus,
    glacierBackup: boolean,
    rawDataS3: boolean,
    tumorVolume?: number | null,
    sequenceType: SequenceType,
    strandSpecific: string,
    UMI: boolean,
    adaptorsRemoved: boolean,
    adaptorsS3Path?: string | null,
    indexSequences?: Array< string | null > | null,
    concNanoGramPerMicroL?: number | null,
    rnaMassNanoGram?: number | null,
    rnaVolumeMicroL?: number | null,
    rin?: number | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    isControl: boolean,
    barcodesMappedToControl?: Array< string | null > | null,
    barcode: string,
    s3Path: Array< string | null >,
    sampleMetadataRnaSeqListId?: string | null,
  } | null,
};

export type DeleteMetadataRnaSeqMutationVariables = {
  input: DeleteMetadataRnaSeqInput,
  condition?: ModelMetadataRnaSeqConditionInput | null,
};

export type DeleteMetadataRnaSeqMutation = {
  deleteMetadataRnaSeq?:  {
    __typename: "MetadataRnaSeq",
    id: string,
    sampleId: string,
    sample?:  {
      __typename: "Sample",
      id: string,
      timepointId?: string | null,
      timepoint?:  {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      StudyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      BatchId?: string | null,
      Batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      metadataImmunopeptidomicsList?:  {
        __typename: "ModelMetadataImmunopeptidomicsConnection",
        nextToken?: string | null,
      } | null,
      MetadataNanoStringList?:  {
        __typename: "ModelMetadataNanoStringConnection",
        nextToken?: string | null,
      } | null,
      MetadataRnaSeqList?:  {
        __typename: "ModelMetadataRnaSeqConnection",
        nextToken?: string | null,
      } | null,
      AuxiliaryAnalysislList?:  {
        __typename: "ModelAuxiliaryAnalysisConnection",
        nextToken?: string | null,
      } | null,
      domainSampleId: string,
      experimentSpecificVariables?: string | null,
      collectionDate?: string | null,
      processingDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseSamplesId?: string | null,
      timepointSamplesId?: string | null,
      batchSamplesId?: string | null,
    } | null,
    treatment?:  {
      __typename: "ModelTreatmentConnection",
      items:  Array< {
        __typename: "Treatment",
        id: string,
        metadataImmunopeptidomicsId?: string | null,
        metadataNanoStringId?: string | null,
        metadataRnaSeqId?: string | null,
        treatmentName: string,
        dose: number,
        doseUnit: string,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        metadataImmunopeptidomicsTreatmentId?: string | null,
        metadataNanoStringTreatmentId?: string | null,
        metadataRnaSeqTreatmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    metaDataTemplateS3Path: string,
    subtypeOfAnalysis?: string | null,
    species: string,
    sampleType: string,
    qcStatus: QcStatus,
    glacierBackup: boolean,
    rawDataS3: boolean,
    tumorVolume?: number | null,
    sequenceType: SequenceType,
    strandSpecific: string,
    UMI: boolean,
    adaptorsRemoved: boolean,
    adaptorsS3Path?: string | null,
    indexSequences?: Array< string | null > | null,
    concNanoGramPerMicroL?: number | null,
    rnaMassNanoGram?: number | null,
    rnaVolumeMicroL?: number | null,
    rin?: number | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    isControl: boolean,
    barcodesMappedToControl?: Array< string | null > | null,
    barcode: string,
    s3Path: Array< string | null >,
    sampleMetadataRnaSeqListId?: string | null,
  } | null,
};

export type CreateTreatmentMutationVariables = {
  input: CreateTreatmentInput,
  condition?: ModelTreatmentConditionInput | null,
};

export type CreateTreatmentMutation = {
  createTreatment?:  {
    __typename: "Treatment",
    id: string,
    metadataImmunopeptidomicsId?: string | null,
    metadataImmunopeptidomics?:  {
      __typename: "MetadataImmunopeptidomics",
      id: string,
      sampleId: string,
      sample?:  {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null,
      treatment?:  {
        __typename: "ModelTreatmentConnection",
        nextToken?: string | null,
      } | null,
      metaDataTemplateS3Path: string,
      species: string,
      subtypeOfAnalysis?: string | null,
      sampleType: string,
      qcStatus: QcStatus,
      glacierBackup: boolean,
      rawDataS3: boolean,
      tumorVolume?: number | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      isControl: boolean,
      barcodesMappedToControl?: Array< string | null > | null,
      barcode: string,
      s3Path: Array< string | null >,
      sampleMetadataImmunopeptidomicsListId?: string | null,
    } | null,
    metadataNanoStringId?: string | null,
    metadataNanoString?:  {
      __typename: "MetadataNanoString",
      id: string,
      sampleId: string,
      sample?:  {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null,
      treatment?:  {
        __typename: "ModelTreatmentConnection",
        nextToken?: string | null,
      } | null,
      metaDataTemplateS3Path: string,
      subtypeOfAnalysis?: string | null,
      species: string,
      sampleType: string,
      qcStatus: QcStatus,
      glacierBackup: boolean,
      rawDataS3: boolean,
      tumorVolume?: number | null,
      dv200Percentage?: number | null,
      concNanoGramPerMicroL?: number | null,
      rnaMassNanoGram?: number | null,
      rnaVolumeMicroL?: number | null,
      rin?: number | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      isControl: boolean,
      barcodesMappedToControl?: Array< string | null > | null,
      barcode: string,
      s3Path: Array< string | null >,
      sampleMetadataNanoStringListId?: string | null,
    } | null,
    metadataRnaSeqId?: string | null,
    metadataRnaSeq?:  {
      __typename: "MetadataRnaSeq",
      id: string,
      sampleId: string,
      sample?:  {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null,
      treatment?:  {
        __typename: "ModelTreatmentConnection",
        nextToken?: string | null,
      } | null,
      metaDataTemplateS3Path: string,
      subtypeOfAnalysis?: string | null,
      species: string,
      sampleType: string,
      qcStatus: QcStatus,
      glacierBackup: boolean,
      rawDataS3: boolean,
      tumorVolume?: number | null,
      sequenceType: SequenceType,
      strandSpecific: string,
      UMI: boolean,
      adaptorsRemoved: boolean,
      adaptorsS3Path?: string | null,
      indexSequences?: Array< string | null > | null,
      concNanoGramPerMicroL?: number | null,
      rnaMassNanoGram?: number | null,
      rnaVolumeMicroL?: number | null,
      rin?: number | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      isControl: boolean,
      barcodesMappedToControl?: Array< string | null > | null,
      barcode: string,
      s3Path: Array< string | null >,
      sampleMetadataRnaSeqListId?: string | null,
    } | null,
    treatmentName: string,
    dose: number,
    doseUnit: string,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    metadataImmunopeptidomicsTreatmentId?: string | null,
    metadataNanoStringTreatmentId?: string | null,
    metadataRnaSeqTreatmentId?: string | null,
  } | null,
};

export type UpdateTreatmentMutationVariables = {
  input: UpdateTreatmentInput,
  condition?: ModelTreatmentConditionInput | null,
};

export type UpdateTreatmentMutation = {
  updateTreatment?:  {
    __typename: "Treatment",
    id: string,
    metadataImmunopeptidomicsId?: string | null,
    metadataImmunopeptidomics?:  {
      __typename: "MetadataImmunopeptidomics",
      id: string,
      sampleId: string,
      sample?:  {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null,
      treatment?:  {
        __typename: "ModelTreatmentConnection",
        nextToken?: string | null,
      } | null,
      metaDataTemplateS3Path: string,
      species: string,
      subtypeOfAnalysis?: string | null,
      sampleType: string,
      qcStatus: QcStatus,
      glacierBackup: boolean,
      rawDataS3: boolean,
      tumorVolume?: number | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      isControl: boolean,
      barcodesMappedToControl?: Array< string | null > | null,
      barcode: string,
      s3Path: Array< string | null >,
      sampleMetadataImmunopeptidomicsListId?: string | null,
    } | null,
    metadataNanoStringId?: string | null,
    metadataNanoString?:  {
      __typename: "MetadataNanoString",
      id: string,
      sampleId: string,
      sample?:  {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null,
      treatment?:  {
        __typename: "ModelTreatmentConnection",
        nextToken?: string | null,
      } | null,
      metaDataTemplateS3Path: string,
      subtypeOfAnalysis?: string | null,
      species: string,
      sampleType: string,
      qcStatus: QcStatus,
      glacierBackup: boolean,
      rawDataS3: boolean,
      tumorVolume?: number | null,
      dv200Percentage?: number | null,
      concNanoGramPerMicroL?: number | null,
      rnaMassNanoGram?: number | null,
      rnaVolumeMicroL?: number | null,
      rin?: number | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      isControl: boolean,
      barcodesMappedToControl?: Array< string | null > | null,
      barcode: string,
      s3Path: Array< string | null >,
      sampleMetadataNanoStringListId?: string | null,
    } | null,
    metadataRnaSeqId?: string | null,
    metadataRnaSeq?:  {
      __typename: "MetadataRnaSeq",
      id: string,
      sampleId: string,
      sample?:  {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null,
      treatment?:  {
        __typename: "ModelTreatmentConnection",
        nextToken?: string | null,
      } | null,
      metaDataTemplateS3Path: string,
      subtypeOfAnalysis?: string | null,
      species: string,
      sampleType: string,
      qcStatus: QcStatus,
      glacierBackup: boolean,
      rawDataS3: boolean,
      tumorVolume?: number | null,
      sequenceType: SequenceType,
      strandSpecific: string,
      UMI: boolean,
      adaptorsRemoved: boolean,
      adaptorsS3Path?: string | null,
      indexSequences?: Array< string | null > | null,
      concNanoGramPerMicroL?: number | null,
      rnaMassNanoGram?: number | null,
      rnaVolumeMicroL?: number | null,
      rin?: number | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      isControl: boolean,
      barcodesMappedToControl?: Array< string | null > | null,
      barcode: string,
      s3Path: Array< string | null >,
      sampleMetadataRnaSeqListId?: string | null,
    } | null,
    treatmentName: string,
    dose: number,
    doseUnit: string,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    metadataImmunopeptidomicsTreatmentId?: string | null,
    metadataNanoStringTreatmentId?: string | null,
    metadataRnaSeqTreatmentId?: string | null,
  } | null,
};

export type DeleteTreatmentMutationVariables = {
  input: DeleteTreatmentInput,
  condition?: ModelTreatmentConditionInput | null,
};

export type DeleteTreatmentMutation = {
  deleteTreatment?:  {
    __typename: "Treatment",
    id: string,
    metadataImmunopeptidomicsId?: string | null,
    metadataImmunopeptidomics?:  {
      __typename: "MetadataImmunopeptidomics",
      id: string,
      sampleId: string,
      sample?:  {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null,
      treatment?:  {
        __typename: "ModelTreatmentConnection",
        nextToken?: string | null,
      } | null,
      metaDataTemplateS3Path: string,
      species: string,
      subtypeOfAnalysis?: string | null,
      sampleType: string,
      qcStatus: QcStatus,
      glacierBackup: boolean,
      rawDataS3: boolean,
      tumorVolume?: number | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      isControl: boolean,
      barcodesMappedToControl?: Array< string | null > | null,
      barcode: string,
      s3Path: Array< string | null >,
      sampleMetadataImmunopeptidomicsListId?: string | null,
    } | null,
    metadataNanoStringId?: string | null,
    metadataNanoString?:  {
      __typename: "MetadataNanoString",
      id: string,
      sampleId: string,
      sample?:  {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null,
      treatment?:  {
        __typename: "ModelTreatmentConnection",
        nextToken?: string | null,
      } | null,
      metaDataTemplateS3Path: string,
      subtypeOfAnalysis?: string | null,
      species: string,
      sampleType: string,
      qcStatus: QcStatus,
      glacierBackup: boolean,
      rawDataS3: boolean,
      tumorVolume?: number | null,
      dv200Percentage?: number | null,
      concNanoGramPerMicroL?: number | null,
      rnaMassNanoGram?: number | null,
      rnaVolumeMicroL?: number | null,
      rin?: number | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      isControl: boolean,
      barcodesMappedToControl?: Array< string | null > | null,
      barcode: string,
      s3Path: Array< string | null >,
      sampleMetadataNanoStringListId?: string | null,
    } | null,
    metadataRnaSeqId?: string | null,
    metadataRnaSeq?:  {
      __typename: "MetadataRnaSeq",
      id: string,
      sampleId: string,
      sample?:  {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null,
      treatment?:  {
        __typename: "ModelTreatmentConnection",
        nextToken?: string | null,
      } | null,
      metaDataTemplateS3Path: string,
      subtypeOfAnalysis?: string | null,
      species: string,
      sampleType: string,
      qcStatus: QcStatus,
      glacierBackup: boolean,
      rawDataS3: boolean,
      tumorVolume?: number | null,
      sequenceType: SequenceType,
      strandSpecific: string,
      UMI: boolean,
      adaptorsRemoved: boolean,
      adaptorsS3Path?: string | null,
      indexSequences?: Array< string | null > | null,
      concNanoGramPerMicroL?: number | null,
      rnaMassNanoGram?: number | null,
      rnaVolumeMicroL?: number | null,
      rin?: number | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      isControl: boolean,
      barcodesMappedToControl?: Array< string | null > | null,
      barcode: string,
      s3Path: Array< string | null >,
      sampleMetadataRnaSeqListId?: string | null,
    } | null,
    treatmentName: string,
    dose: number,
    doseUnit: string,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    metadataImmunopeptidomicsTreatmentId?: string | null,
    metadataNanoStringTreatmentId?: string | null,
    metadataRnaSeqTreatmentId?: string | null,
  } | null,
};

export type CreateLaunchablePipelinesMutationVariables = {
  input: CreateLaunchablePipelinesInput,
  condition?: ModelLaunchablePipelinesConditionInput | null,
};

export type CreateLaunchablePipelinesMutation = {
  createLaunchablePipelines?:  {
    __typename: "LaunchablePipelines",
    id: string,
    pipelineName?: string | null,
    organizations?:  {
      __typename: "ModelOrganizationLaunchablePipelinesConnection",
      items:  Array< {
        __typename: "OrganizationLaunchablePipelines",
        id: string,
        organizationId: string,
        launchablePipelinesId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pipelineVersion?: string | null,
    parameters?: string | null,
    description?: string | null,
    inputFileInfo?: string | null,
    supportedAnalyses?: Array< AnalysisType | null > | null,
    supportedSubtypeOfAnalyses?: Array< string | null > | null,
    pipelines?:  {
      __typename: "ModelPipelineConnection",
      items:  Array< {
        __typename: "Pipeline",
        id: string,
        name: string,
        status?: pipelineStatus | null,
        description?: string | null,
        s3Event?: string | null,
        s3ParamsFile?: string | null,
        s3Results?: string | null,
        visFileInfoS3path?: string | null,
        s3NfLog?: string | null,
        s3NfTasksLogs?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis?: string | null,
        parameters: string,
        studyId?: string | null,
        studyPhaseId?: string | null,
        scheduledPipeline: boolean,
        scheduledDateTime?: string | null,
        launchablePipelineId: string,
        batchId: string,
        runId?: string | null,
        userEmail: string,
        pipelineStarted?: string | null,
        pipelineCompleted?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPipelinesId?: string | null,
        studyPhasePipelinesId?: string | null,
        batchPipelinesId?: string | null,
        launchablePipelinesPipelinesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    gitRepo?: string | null,
    workflowId?: string | null,
    created: string,
    updated: string,
  } | null,
};

export type UpdateLaunchablePipelinesMutationVariables = {
  input: UpdateLaunchablePipelinesInput,
  condition?: ModelLaunchablePipelinesConditionInput | null,
};

export type UpdateLaunchablePipelinesMutation = {
  updateLaunchablePipelines?:  {
    __typename: "LaunchablePipelines",
    id: string,
    pipelineName?: string | null,
    organizations?:  {
      __typename: "ModelOrganizationLaunchablePipelinesConnection",
      items:  Array< {
        __typename: "OrganizationLaunchablePipelines",
        id: string,
        organizationId: string,
        launchablePipelinesId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pipelineVersion?: string | null,
    parameters?: string | null,
    description?: string | null,
    inputFileInfo?: string | null,
    supportedAnalyses?: Array< AnalysisType | null > | null,
    supportedSubtypeOfAnalyses?: Array< string | null > | null,
    pipelines?:  {
      __typename: "ModelPipelineConnection",
      items:  Array< {
        __typename: "Pipeline",
        id: string,
        name: string,
        status?: pipelineStatus | null,
        description?: string | null,
        s3Event?: string | null,
        s3ParamsFile?: string | null,
        s3Results?: string | null,
        visFileInfoS3path?: string | null,
        s3NfLog?: string | null,
        s3NfTasksLogs?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis?: string | null,
        parameters: string,
        studyId?: string | null,
        studyPhaseId?: string | null,
        scheduledPipeline: boolean,
        scheduledDateTime?: string | null,
        launchablePipelineId: string,
        batchId: string,
        runId?: string | null,
        userEmail: string,
        pipelineStarted?: string | null,
        pipelineCompleted?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPipelinesId?: string | null,
        studyPhasePipelinesId?: string | null,
        batchPipelinesId?: string | null,
        launchablePipelinesPipelinesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    gitRepo?: string | null,
    workflowId?: string | null,
    created: string,
    updated: string,
  } | null,
};

export type DeleteLaunchablePipelinesMutationVariables = {
  input: DeleteLaunchablePipelinesInput,
  condition?: ModelLaunchablePipelinesConditionInput | null,
};

export type DeleteLaunchablePipelinesMutation = {
  deleteLaunchablePipelines?:  {
    __typename: "LaunchablePipelines",
    id: string,
    pipelineName?: string | null,
    organizations?:  {
      __typename: "ModelOrganizationLaunchablePipelinesConnection",
      items:  Array< {
        __typename: "OrganizationLaunchablePipelines",
        id: string,
        organizationId: string,
        launchablePipelinesId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pipelineVersion?: string | null,
    parameters?: string | null,
    description?: string | null,
    inputFileInfo?: string | null,
    supportedAnalyses?: Array< AnalysisType | null > | null,
    supportedSubtypeOfAnalyses?: Array< string | null > | null,
    pipelines?:  {
      __typename: "ModelPipelineConnection",
      items:  Array< {
        __typename: "Pipeline",
        id: string,
        name: string,
        status?: pipelineStatus | null,
        description?: string | null,
        s3Event?: string | null,
        s3ParamsFile?: string | null,
        s3Results?: string | null,
        visFileInfoS3path?: string | null,
        s3NfLog?: string | null,
        s3NfTasksLogs?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis?: string | null,
        parameters: string,
        studyId?: string | null,
        studyPhaseId?: string | null,
        scheduledPipeline: boolean,
        scheduledDateTime?: string | null,
        launchablePipelineId: string,
        batchId: string,
        runId?: string | null,
        userEmail: string,
        pipelineStarted?: string | null,
        pipelineCompleted?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPipelinesId?: string | null,
        studyPhasePipelinesId?: string | null,
        batchPipelinesId?: string | null,
        launchablePipelinesPipelinesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    gitRepo?: string | null,
    workflowId?: string | null,
    created: string,
    updated: string,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    email?: string | null,
    organization?: string | null,
    gitToken?: string | null,
    gitName?: string | null,
    gitURL?: string | null,
    readGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    owner?: string | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    email?: string | null,
    organization?: string | null,
    gitToken?: string | null,
    gitName?: string | null,
    gitURL?: string | null,
    readGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    owner?: string | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    email?: string | null,
    organization?: string | null,
    gitToken?: string | null,
    gitName?: string | null,
    gitURL?: string | null,
    readGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    owner?: string | null,
  } | null,
};

export type CreateUploadLogMutationVariables = {
  input: CreateUploadLogInput,
  condition?: ModelUploadLogConditionInput | null,
};

export type CreateUploadLogMutation = {
  createUploadLog?:  {
    __typename: "UploadLog",
    id: string,
    uploadS3Path?: string | null,
    status?: string | null,
    readGroups?: Array< string | null > | null,
    created?: string | null,
    updated?: string | null,
  } | null,
};

export type UpdateUploadLogMutationVariables = {
  input: UpdateUploadLogInput,
  condition?: ModelUploadLogConditionInput | null,
};

export type UpdateUploadLogMutation = {
  updateUploadLog?:  {
    __typename: "UploadLog",
    id: string,
    uploadS3Path?: string | null,
    status?: string | null,
    readGroups?: Array< string | null > | null,
    created?: string | null,
    updated?: string | null,
  } | null,
};

export type DeleteUploadLogMutationVariables = {
  input: DeleteUploadLogInput,
  condition?: ModelUploadLogConditionInput | null,
};

export type DeleteUploadLogMutation = {
  deleteUploadLog?:  {
    __typename: "UploadLog",
    id: string,
    uploadS3Path?: string | null,
    status?: string | null,
    readGroups?: Array< string | null > | null,
    created?: string | null,
    updated?: string | null,
  } | null,
};

export type CreateVisualizationsMutationVariables = {
  input: CreateVisualizationsInput,
  condition?: ModelVisualizationsConditionInput | null,
};

export type CreateVisualizationsMutation = {
  createVisualizations?:  {
    __typename: "Visualizations",
    id: string,
    visualizationName?: string | null,
    description?: string | null,
    version?: string | null,
    deploymentPath?: string | null,
    validationSchema?: string | null,
    allowedExtensions?: Array< string | null > | null,
    supportedAnalyses?: Array< string | null > | null,
    created: string,
    updated: string,
  } | null,
};

export type UpdateVisualizationsMutationVariables = {
  input: UpdateVisualizationsInput,
  condition?: ModelVisualizationsConditionInput | null,
};

export type UpdateVisualizationsMutation = {
  updateVisualizations?:  {
    __typename: "Visualizations",
    id: string,
    visualizationName?: string | null,
    description?: string | null,
    version?: string | null,
    deploymentPath?: string | null,
    validationSchema?: string | null,
    allowedExtensions?: Array< string | null > | null,
    supportedAnalyses?: Array< string | null > | null,
    created: string,
    updated: string,
  } | null,
};

export type DeleteVisualizationsMutationVariables = {
  input: DeleteVisualizationsInput,
  condition?: ModelVisualizationsConditionInput | null,
};

export type DeleteVisualizationsMutation = {
  deleteVisualizations?:  {
    __typename: "Visualizations",
    id: string,
    visualizationName?: string | null,
    description?: string | null,
    version?: string | null,
    deploymentPath?: string | null,
    validationSchema?: string | null,
    allowedExtensions?: Array< string | null > | null,
    supportedAnalyses?: Array< string | null > | null,
    created: string,
    updated: string,
  } | null,
};

export type CreateVisualizationSessionMutationVariables = {
  input: CreateVisualizationSessionInput,
  condition?: ModelVisualizationSessionConditionInput | null,
};

export type CreateVisualizationSessionMutation = {
  createVisualizationSession?:  {
    __typename: "VisualizationSession",
    id: string,
    statuses?: string | null,
    data?: string | null,
    description?: string | null,
    created: string,
    updated: string,
    owner?: string | null,
  } | null,
};

export type UpdateVisualizationSessionMutationVariables = {
  input: UpdateVisualizationSessionInput,
  condition?: ModelVisualizationSessionConditionInput | null,
};

export type UpdateVisualizationSessionMutation = {
  updateVisualizationSession?:  {
    __typename: "VisualizationSession",
    id: string,
    statuses?: string | null,
    data?: string | null,
    description?: string | null,
    created: string,
    updated: string,
    owner?: string | null,
  } | null,
};

export type DeleteVisualizationSessionMutationVariables = {
  input: DeleteVisualizationSessionInput,
  condition?: ModelVisualizationSessionConditionInput | null,
};

export type DeleteVisualizationSessionMutation = {
  deleteVisualizationSession?:  {
    __typename: "VisualizationSession",
    id: string,
    statuses?: string | null,
    data?: string | null,
    description?: string | null,
    created: string,
    updated: string,
    owner?: string | null,
  } | null,
};

export type CreateDataLabEC2InstancesMutationVariables = {
  input: CreateDataLabEC2InstancesInput,
  condition?: ModelDataLabEC2InstancesConditionInput | null,
};

export type CreateDataLabEC2InstancesMutation = {
  createDataLabEC2Instances?:  {
    __typename: "DataLabEC2Instances",
    id: string,
    creationTimestamp?: string | null,
    stoppedTimestamp?: string | null,
    startTimestamp?: string | null,
    terminatedTimestamp?: string | null,
    stoppedTimePeriod?: number | null,
    runningTimePeriod?: number | null,
    userEmail: string,
    status?: DataLabEC2InstancesStatus | null,
    type?: DataLabEC2InstancesType | null,
    dataLabInstanceSessions?:  {
      __typename: "ModelDataLabEC2InstanceSessionConnection",
      items:  Array< {
        __typename: "DataLabEC2InstanceSession",
        id: string,
        dataLabInstanceId: string,
        s3Path?: string | null,
        instanceLaunchEvent?: string | null,
        status?: DataLabEC2InstanceSessionStatus | null,
        title?: string | null,
        description?: string | null,
        created: string,
        updated: string,
        dataLabEC2InstancesDataLabInstanceSessionsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    dataLabInstanceStatusChanges?:  {
      __typename: "ModelDataLabEC2InstanceStatusChangeConnection",
      items:  Array< {
        __typename: "DataLabEC2InstanceStatusChange",
        id: string,
        dataLabInstanceId: string,
        organizationId?: string | null,
        previousStatus?: DataLabEC2InstancesStatus | null,
        newStatus?: DataLabEC2InstancesStatus | null,
        initiatedBy?: string | null,
        action?: string | null,
        performedBy?: string | null,
        additionalNotes?: string | null,
        created: string,
        updated: string,
        dataLabEC2InstancesDataLabInstanceStatusChangesId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    organizationId?: string | null,
    organizationName?: string | null,
    instanceUrl?: string | null,
    instanceId?: string | null,
    infrastructureRelatedEntities?: string | null,
    instanceLaunchEvent?: string | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    owner?: string | null,
  } | null,
};

export type UpdateDataLabEC2InstancesMutationVariables = {
  input: UpdateDataLabEC2InstancesInput,
  condition?: ModelDataLabEC2InstancesConditionInput | null,
};

export type UpdateDataLabEC2InstancesMutation = {
  updateDataLabEC2Instances?:  {
    __typename: "DataLabEC2Instances",
    id: string,
    creationTimestamp?: string | null,
    stoppedTimestamp?: string | null,
    startTimestamp?: string | null,
    terminatedTimestamp?: string | null,
    stoppedTimePeriod?: number | null,
    runningTimePeriod?: number | null,
    userEmail: string,
    status?: DataLabEC2InstancesStatus | null,
    type?: DataLabEC2InstancesType | null,
    dataLabInstanceSessions?:  {
      __typename: "ModelDataLabEC2InstanceSessionConnection",
      items:  Array< {
        __typename: "DataLabEC2InstanceSession",
        id: string,
        dataLabInstanceId: string,
        s3Path?: string | null,
        instanceLaunchEvent?: string | null,
        status?: DataLabEC2InstanceSessionStatus | null,
        title?: string | null,
        description?: string | null,
        created: string,
        updated: string,
        dataLabEC2InstancesDataLabInstanceSessionsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    dataLabInstanceStatusChanges?:  {
      __typename: "ModelDataLabEC2InstanceStatusChangeConnection",
      items:  Array< {
        __typename: "DataLabEC2InstanceStatusChange",
        id: string,
        dataLabInstanceId: string,
        organizationId?: string | null,
        previousStatus?: DataLabEC2InstancesStatus | null,
        newStatus?: DataLabEC2InstancesStatus | null,
        initiatedBy?: string | null,
        action?: string | null,
        performedBy?: string | null,
        additionalNotes?: string | null,
        created: string,
        updated: string,
        dataLabEC2InstancesDataLabInstanceStatusChangesId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    organizationId?: string | null,
    organizationName?: string | null,
    instanceUrl?: string | null,
    instanceId?: string | null,
    infrastructureRelatedEntities?: string | null,
    instanceLaunchEvent?: string | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    owner?: string | null,
  } | null,
};

export type DeleteDataLabEC2InstancesMutationVariables = {
  input: DeleteDataLabEC2InstancesInput,
  condition?: ModelDataLabEC2InstancesConditionInput | null,
};

export type DeleteDataLabEC2InstancesMutation = {
  deleteDataLabEC2Instances?:  {
    __typename: "DataLabEC2Instances",
    id: string,
    creationTimestamp?: string | null,
    stoppedTimestamp?: string | null,
    startTimestamp?: string | null,
    terminatedTimestamp?: string | null,
    stoppedTimePeriod?: number | null,
    runningTimePeriod?: number | null,
    userEmail: string,
    status?: DataLabEC2InstancesStatus | null,
    type?: DataLabEC2InstancesType | null,
    dataLabInstanceSessions?:  {
      __typename: "ModelDataLabEC2InstanceSessionConnection",
      items:  Array< {
        __typename: "DataLabEC2InstanceSession",
        id: string,
        dataLabInstanceId: string,
        s3Path?: string | null,
        instanceLaunchEvent?: string | null,
        status?: DataLabEC2InstanceSessionStatus | null,
        title?: string | null,
        description?: string | null,
        created: string,
        updated: string,
        dataLabEC2InstancesDataLabInstanceSessionsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    dataLabInstanceStatusChanges?:  {
      __typename: "ModelDataLabEC2InstanceStatusChangeConnection",
      items:  Array< {
        __typename: "DataLabEC2InstanceStatusChange",
        id: string,
        dataLabInstanceId: string,
        organizationId?: string | null,
        previousStatus?: DataLabEC2InstancesStatus | null,
        newStatus?: DataLabEC2InstancesStatus | null,
        initiatedBy?: string | null,
        action?: string | null,
        performedBy?: string | null,
        additionalNotes?: string | null,
        created: string,
        updated: string,
        dataLabEC2InstancesDataLabInstanceStatusChangesId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    organizationId?: string | null,
    organizationName?: string | null,
    instanceUrl?: string | null,
    instanceId?: string | null,
    infrastructureRelatedEntities?: string | null,
    instanceLaunchEvent?: string | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    owner?: string | null,
  } | null,
};

export type CreateDataLabEC2InstanceSessionMutationVariables = {
  input: CreateDataLabEC2InstanceSessionInput,
  condition?: ModelDataLabEC2InstanceSessionConditionInput | null,
};

export type CreateDataLabEC2InstanceSessionMutation = {
  createDataLabEC2InstanceSession?:  {
    __typename: "DataLabEC2InstanceSession",
    id: string,
    dataLabInstanceId: string,
    dataLabInstance?:  {
      __typename: "DataLabEC2Instances",
      id: string,
      creationTimestamp?: string | null,
      stoppedTimestamp?: string | null,
      startTimestamp?: string | null,
      terminatedTimestamp?: string | null,
      stoppedTimePeriod?: number | null,
      runningTimePeriod?: number | null,
      userEmail: string,
      status?: DataLabEC2InstancesStatus | null,
      type?: DataLabEC2InstancesType | null,
      dataLabInstanceSessions?:  {
        __typename: "ModelDataLabEC2InstanceSessionConnection",
        nextToken?: string | null,
      } | null,
      dataLabInstanceStatusChanges?:  {
        __typename: "ModelDataLabEC2InstanceStatusChangeConnection",
        nextToken?: string | null,
      } | null,
      organizationId?: string | null,
      organizationName?: string | null,
      instanceUrl?: string | null,
      instanceId?: string | null,
      infrastructureRelatedEntities?: string | null,
      instanceLaunchEvent?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null,
    s3Path?: string | null,
    instanceLaunchEvent?: string | null,
    status?: DataLabEC2InstanceSessionStatus | null,
    title?: string | null,
    description?: string | null,
    created: string,
    updated: string,
    dataLabEC2InstancesDataLabInstanceSessionsId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateDataLabEC2InstanceSessionMutationVariables = {
  input: UpdateDataLabEC2InstanceSessionInput,
  condition?: ModelDataLabEC2InstanceSessionConditionInput | null,
};

export type UpdateDataLabEC2InstanceSessionMutation = {
  updateDataLabEC2InstanceSession?:  {
    __typename: "DataLabEC2InstanceSession",
    id: string,
    dataLabInstanceId: string,
    dataLabInstance?:  {
      __typename: "DataLabEC2Instances",
      id: string,
      creationTimestamp?: string | null,
      stoppedTimestamp?: string | null,
      startTimestamp?: string | null,
      terminatedTimestamp?: string | null,
      stoppedTimePeriod?: number | null,
      runningTimePeriod?: number | null,
      userEmail: string,
      status?: DataLabEC2InstancesStatus | null,
      type?: DataLabEC2InstancesType | null,
      dataLabInstanceSessions?:  {
        __typename: "ModelDataLabEC2InstanceSessionConnection",
        nextToken?: string | null,
      } | null,
      dataLabInstanceStatusChanges?:  {
        __typename: "ModelDataLabEC2InstanceStatusChangeConnection",
        nextToken?: string | null,
      } | null,
      organizationId?: string | null,
      organizationName?: string | null,
      instanceUrl?: string | null,
      instanceId?: string | null,
      infrastructureRelatedEntities?: string | null,
      instanceLaunchEvent?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null,
    s3Path?: string | null,
    instanceLaunchEvent?: string | null,
    status?: DataLabEC2InstanceSessionStatus | null,
    title?: string | null,
    description?: string | null,
    created: string,
    updated: string,
    dataLabEC2InstancesDataLabInstanceSessionsId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteDataLabEC2InstanceSessionMutationVariables = {
  input: DeleteDataLabEC2InstanceSessionInput,
  condition?: ModelDataLabEC2InstanceSessionConditionInput | null,
};

export type DeleteDataLabEC2InstanceSessionMutation = {
  deleteDataLabEC2InstanceSession?:  {
    __typename: "DataLabEC2InstanceSession",
    id: string,
    dataLabInstanceId: string,
    dataLabInstance?:  {
      __typename: "DataLabEC2Instances",
      id: string,
      creationTimestamp?: string | null,
      stoppedTimestamp?: string | null,
      startTimestamp?: string | null,
      terminatedTimestamp?: string | null,
      stoppedTimePeriod?: number | null,
      runningTimePeriod?: number | null,
      userEmail: string,
      status?: DataLabEC2InstancesStatus | null,
      type?: DataLabEC2InstancesType | null,
      dataLabInstanceSessions?:  {
        __typename: "ModelDataLabEC2InstanceSessionConnection",
        nextToken?: string | null,
      } | null,
      dataLabInstanceStatusChanges?:  {
        __typename: "ModelDataLabEC2InstanceStatusChangeConnection",
        nextToken?: string | null,
      } | null,
      organizationId?: string | null,
      organizationName?: string | null,
      instanceUrl?: string | null,
      instanceId?: string | null,
      infrastructureRelatedEntities?: string | null,
      instanceLaunchEvent?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null,
    s3Path?: string | null,
    instanceLaunchEvent?: string | null,
    status?: DataLabEC2InstanceSessionStatus | null,
    title?: string | null,
    description?: string | null,
    created: string,
    updated: string,
    dataLabEC2InstancesDataLabInstanceSessionsId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateDataLabEC2InstanceStatusChangeMutationVariables = {
  input: CreateDataLabEC2InstanceStatusChangeInput,
  condition?: ModelDataLabEC2InstanceStatusChangeConditionInput | null,
};

export type CreateDataLabEC2InstanceStatusChangeMutation = {
  createDataLabEC2InstanceStatusChange?:  {
    __typename: "DataLabEC2InstanceStatusChange",
    id: string,
    dataLabInstanceId: string,
    dataLabInstance?:  {
      __typename: "DataLabEC2Instances",
      id: string,
      creationTimestamp?: string | null,
      stoppedTimestamp?: string | null,
      startTimestamp?: string | null,
      terminatedTimestamp?: string | null,
      stoppedTimePeriod?: number | null,
      runningTimePeriod?: number | null,
      userEmail: string,
      status?: DataLabEC2InstancesStatus | null,
      type?: DataLabEC2InstancesType | null,
      dataLabInstanceSessions?:  {
        __typename: "ModelDataLabEC2InstanceSessionConnection",
        nextToken?: string | null,
      } | null,
      dataLabInstanceStatusChanges?:  {
        __typename: "ModelDataLabEC2InstanceStatusChangeConnection",
        nextToken?: string | null,
      } | null,
      organizationId?: string | null,
      organizationName?: string | null,
      instanceUrl?: string | null,
      instanceId?: string | null,
      infrastructureRelatedEntities?: string | null,
      instanceLaunchEvent?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null,
    organizationId?: string | null,
    previousStatus?: DataLabEC2InstancesStatus | null,
    newStatus?: DataLabEC2InstancesStatus | null,
    initiatedBy?: string | null,
    action?: string | null,
    performedBy?: string | null,
    additionalNotes?: string | null,
    created: string,
    updated: string,
    dataLabEC2InstancesDataLabInstanceStatusChangesId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateDataLabEC2InstanceStatusChangeMutationVariables = {
  input: UpdateDataLabEC2InstanceStatusChangeInput,
  condition?: ModelDataLabEC2InstanceStatusChangeConditionInput | null,
};

export type UpdateDataLabEC2InstanceStatusChangeMutation = {
  updateDataLabEC2InstanceStatusChange?:  {
    __typename: "DataLabEC2InstanceStatusChange",
    id: string,
    dataLabInstanceId: string,
    dataLabInstance?:  {
      __typename: "DataLabEC2Instances",
      id: string,
      creationTimestamp?: string | null,
      stoppedTimestamp?: string | null,
      startTimestamp?: string | null,
      terminatedTimestamp?: string | null,
      stoppedTimePeriod?: number | null,
      runningTimePeriod?: number | null,
      userEmail: string,
      status?: DataLabEC2InstancesStatus | null,
      type?: DataLabEC2InstancesType | null,
      dataLabInstanceSessions?:  {
        __typename: "ModelDataLabEC2InstanceSessionConnection",
        nextToken?: string | null,
      } | null,
      dataLabInstanceStatusChanges?:  {
        __typename: "ModelDataLabEC2InstanceStatusChangeConnection",
        nextToken?: string | null,
      } | null,
      organizationId?: string | null,
      organizationName?: string | null,
      instanceUrl?: string | null,
      instanceId?: string | null,
      infrastructureRelatedEntities?: string | null,
      instanceLaunchEvent?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null,
    organizationId?: string | null,
    previousStatus?: DataLabEC2InstancesStatus | null,
    newStatus?: DataLabEC2InstancesStatus | null,
    initiatedBy?: string | null,
    action?: string | null,
    performedBy?: string | null,
    additionalNotes?: string | null,
    created: string,
    updated: string,
    dataLabEC2InstancesDataLabInstanceStatusChangesId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteDataLabEC2InstanceStatusChangeMutationVariables = {
  input: DeleteDataLabEC2InstanceStatusChangeInput,
  condition?: ModelDataLabEC2InstanceStatusChangeConditionInput | null,
};

export type DeleteDataLabEC2InstanceStatusChangeMutation = {
  deleteDataLabEC2InstanceStatusChange?:  {
    __typename: "DataLabEC2InstanceStatusChange",
    id: string,
    dataLabInstanceId: string,
    dataLabInstance?:  {
      __typename: "DataLabEC2Instances",
      id: string,
      creationTimestamp?: string | null,
      stoppedTimestamp?: string | null,
      startTimestamp?: string | null,
      terminatedTimestamp?: string | null,
      stoppedTimePeriod?: number | null,
      runningTimePeriod?: number | null,
      userEmail: string,
      status?: DataLabEC2InstancesStatus | null,
      type?: DataLabEC2InstancesType | null,
      dataLabInstanceSessions?:  {
        __typename: "ModelDataLabEC2InstanceSessionConnection",
        nextToken?: string | null,
      } | null,
      dataLabInstanceStatusChanges?:  {
        __typename: "ModelDataLabEC2InstanceStatusChangeConnection",
        nextToken?: string | null,
      } | null,
      organizationId?: string | null,
      organizationName?: string | null,
      instanceUrl?: string | null,
      instanceId?: string | null,
      infrastructureRelatedEntities?: string | null,
      instanceLaunchEvent?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null,
    organizationId?: string | null,
    previousStatus?: DataLabEC2InstancesStatus | null,
    newStatus?: DataLabEC2InstancesStatus | null,
    initiatedBy?: string | null,
    action?: string | null,
    performedBy?: string | null,
    additionalNotes?: string | null,
    created: string,
    updated: string,
    dataLabEC2InstancesDataLabInstanceStatusChangesId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateUploadBundleMutationVariables = {
  input: CreateUploadBundleInput,
  condition?: ModelUploadBundleConditionInput | null,
};

export type CreateUploadBundleMutation = {
  createUploadBundle?:  {
    __typename: "UploadBundle",
    id: string,
    studyPhaseId?: string | null,
    studyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    bundleLog?: string | null,
    validationErrors?: string | null,
    status?: string | null,
    userEmail?: string | null,
    uploadBundleAnalysisSession?:  {
      __typename: "UploadBundleAnalysisSession",
      id: string,
      uploadSampleAnalysisSession?:  {
        __typename: "ModelUploadSampleAnalysisSessionConnection",
        nextToken?: string | null,
      } | null,
      metadataFileS3Path?: string | null,
      step?: string | null,
      listOfRawFileNames?: Array< string | null > | null,
      analysisType: AnalysisType,
      subtypeOfAnalysis: string,
      studyPhaseId: string,
      metadataHelperLambdaResponseS3Path?: string | null,
      barcodeToFilesToUploadS3Path?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null,
    uploadSamples?:  {
      __typename: "ModelUploadSampleConnection",
      items:  Array< {
        __typename: "UploadSample",
        id: string,
        uploadBundleId: string,
        sampleId: string,
        barcode: string,
        sampleLog?: string | null,
        writeMetadata: boolean,
        status?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        uploadBundleUploadSamplesId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    uploadFiles?:  {
      __typename: "ModelUploadFileConnection",
      items:  Array< {
        __typename: "UploadFile",
        id: string,
        uploadSampleId?: string | null,
        uploadBundleId?: string | null,
        multipartUploadId?: string | null,
        status?: UploadFileStatus | null,
        toSkipUpload: boolean,
        fileName: string,
        fileType: string,
        s3Path: string,
        size?: number | null,
        fileLog?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        uploadBundleUploadFilesId?: string | null,
        uploadSampleUploadFilesId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    adminGroups?: Array< string | null > | null,
    completionTime?: string | null,
    created: string,
    updated: string,
    studyPhaseUploadBundlesId?: string | null,
    uploadBundleUploadBundleAnalysisSessionId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateUploadBundleMutationVariables = {
  input: UpdateUploadBundleInput,
  condition?: ModelUploadBundleConditionInput | null,
};

export type UpdateUploadBundleMutation = {
  updateUploadBundle?:  {
    __typename: "UploadBundle",
    id: string,
    studyPhaseId?: string | null,
    studyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    bundleLog?: string | null,
    validationErrors?: string | null,
    status?: string | null,
    userEmail?: string | null,
    uploadBundleAnalysisSession?:  {
      __typename: "UploadBundleAnalysisSession",
      id: string,
      uploadSampleAnalysisSession?:  {
        __typename: "ModelUploadSampleAnalysisSessionConnection",
        nextToken?: string | null,
      } | null,
      metadataFileS3Path?: string | null,
      step?: string | null,
      listOfRawFileNames?: Array< string | null > | null,
      analysisType: AnalysisType,
      subtypeOfAnalysis: string,
      studyPhaseId: string,
      metadataHelperLambdaResponseS3Path?: string | null,
      barcodeToFilesToUploadS3Path?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null,
    uploadSamples?:  {
      __typename: "ModelUploadSampleConnection",
      items:  Array< {
        __typename: "UploadSample",
        id: string,
        uploadBundleId: string,
        sampleId: string,
        barcode: string,
        sampleLog?: string | null,
        writeMetadata: boolean,
        status?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        uploadBundleUploadSamplesId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    uploadFiles?:  {
      __typename: "ModelUploadFileConnection",
      items:  Array< {
        __typename: "UploadFile",
        id: string,
        uploadSampleId?: string | null,
        uploadBundleId?: string | null,
        multipartUploadId?: string | null,
        status?: UploadFileStatus | null,
        toSkipUpload: boolean,
        fileName: string,
        fileType: string,
        s3Path: string,
        size?: number | null,
        fileLog?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        uploadBundleUploadFilesId?: string | null,
        uploadSampleUploadFilesId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    adminGroups?: Array< string | null > | null,
    completionTime?: string | null,
    created: string,
    updated: string,
    studyPhaseUploadBundlesId?: string | null,
    uploadBundleUploadBundleAnalysisSessionId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteUploadBundleMutationVariables = {
  input: DeleteUploadBundleInput,
  condition?: ModelUploadBundleConditionInput | null,
};

export type DeleteUploadBundleMutation = {
  deleteUploadBundle?:  {
    __typename: "UploadBundle",
    id: string,
    studyPhaseId?: string | null,
    studyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    bundleLog?: string | null,
    validationErrors?: string | null,
    status?: string | null,
    userEmail?: string | null,
    uploadBundleAnalysisSession?:  {
      __typename: "UploadBundleAnalysisSession",
      id: string,
      uploadSampleAnalysisSession?:  {
        __typename: "ModelUploadSampleAnalysisSessionConnection",
        nextToken?: string | null,
      } | null,
      metadataFileS3Path?: string | null,
      step?: string | null,
      listOfRawFileNames?: Array< string | null > | null,
      analysisType: AnalysisType,
      subtypeOfAnalysis: string,
      studyPhaseId: string,
      metadataHelperLambdaResponseS3Path?: string | null,
      barcodeToFilesToUploadS3Path?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null,
    uploadSamples?:  {
      __typename: "ModelUploadSampleConnection",
      items:  Array< {
        __typename: "UploadSample",
        id: string,
        uploadBundleId: string,
        sampleId: string,
        barcode: string,
        sampleLog?: string | null,
        writeMetadata: boolean,
        status?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        uploadBundleUploadSamplesId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    uploadFiles?:  {
      __typename: "ModelUploadFileConnection",
      items:  Array< {
        __typename: "UploadFile",
        id: string,
        uploadSampleId?: string | null,
        uploadBundleId?: string | null,
        multipartUploadId?: string | null,
        status?: UploadFileStatus | null,
        toSkipUpload: boolean,
        fileName: string,
        fileType: string,
        s3Path: string,
        size?: number | null,
        fileLog?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        uploadBundleUploadFilesId?: string | null,
        uploadSampleUploadFilesId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    adminGroups?: Array< string | null > | null,
    completionTime?: string | null,
    created: string,
    updated: string,
    studyPhaseUploadBundlesId?: string | null,
    uploadBundleUploadBundleAnalysisSessionId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateUploadBundleAnalysisSessionMutationVariables = {
  input: CreateUploadBundleAnalysisSessionInput,
  condition?: ModelUploadBundleAnalysisSessionConditionInput | null,
};

export type CreateUploadBundleAnalysisSessionMutation = {
  createUploadBundleAnalysisSession?:  {
    __typename: "UploadBundleAnalysisSession",
    id: string,
    uploadSampleAnalysisSession?:  {
      __typename: "ModelUploadSampleAnalysisSessionConnection",
      items:  Array< {
        __typename: "UploadSampleAnalysisSession",
        id: string,
        uploadBundleAnalysisSessionId: string,
        sampleId: string,
        rowFromMetaDataFileMappedToSample: string,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        uploadBundleAnalysisSessionUploadSampleAnalysisSessionId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    metadataFileS3Path?: string | null,
    step?: string | null,
    listOfRawFileNames?: Array< string | null > | null,
    analysisType: AnalysisType,
    subtypeOfAnalysis: string,
    studyPhaseId: string,
    metadataHelperLambdaResponseS3Path?: string | null,
    barcodeToFilesToUploadS3Path?: string | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    owner?: string | null,
  } | null,
};

export type UpdateUploadBundleAnalysisSessionMutationVariables = {
  input: UpdateUploadBundleAnalysisSessionInput,
  condition?: ModelUploadBundleAnalysisSessionConditionInput | null,
};

export type UpdateUploadBundleAnalysisSessionMutation = {
  updateUploadBundleAnalysisSession?:  {
    __typename: "UploadBundleAnalysisSession",
    id: string,
    uploadSampleAnalysisSession?:  {
      __typename: "ModelUploadSampleAnalysisSessionConnection",
      items:  Array< {
        __typename: "UploadSampleAnalysisSession",
        id: string,
        uploadBundleAnalysisSessionId: string,
        sampleId: string,
        rowFromMetaDataFileMappedToSample: string,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        uploadBundleAnalysisSessionUploadSampleAnalysisSessionId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    metadataFileS3Path?: string | null,
    step?: string | null,
    listOfRawFileNames?: Array< string | null > | null,
    analysisType: AnalysisType,
    subtypeOfAnalysis: string,
    studyPhaseId: string,
    metadataHelperLambdaResponseS3Path?: string | null,
    barcodeToFilesToUploadS3Path?: string | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    owner?: string | null,
  } | null,
};

export type DeleteUploadBundleAnalysisSessionMutationVariables = {
  input: DeleteUploadBundleAnalysisSessionInput,
  condition?: ModelUploadBundleAnalysisSessionConditionInput | null,
};

export type DeleteUploadBundleAnalysisSessionMutation = {
  deleteUploadBundleAnalysisSession?:  {
    __typename: "UploadBundleAnalysisSession",
    id: string,
    uploadSampleAnalysisSession?:  {
      __typename: "ModelUploadSampleAnalysisSessionConnection",
      items:  Array< {
        __typename: "UploadSampleAnalysisSession",
        id: string,
        uploadBundleAnalysisSessionId: string,
        sampleId: string,
        rowFromMetaDataFileMappedToSample: string,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        uploadBundleAnalysisSessionUploadSampleAnalysisSessionId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    metadataFileS3Path?: string | null,
    step?: string | null,
    listOfRawFileNames?: Array< string | null > | null,
    analysisType: AnalysisType,
    subtypeOfAnalysis: string,
    studyPhaseId: string,
    metadataHelperLambdaResponseS3Path?: string | null,
    barcodeToFilesToUploadS3Path?: string | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    owner?: string | null,
  } | null,
};

export type CreateUploadSampleMutationVariables = {
  input: CreateUploadSampleInput,
  condition?: ModelUploadSampleConditionInput | null,
};

export type CreateUploadSampleMutation = {
  createUploadSample?:  {
    __typename: "UploadSample",
    id: string,
    uploadBundleId: string,
    uploadBundle?:  {
      __typename: "UploadBundle",
      id: string,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      bundleLog?: string | null,
      validationErrors?: string | null,
      status?: string | null,
      userEmail?: string | null,
      uploadBundleAnalysisSession?:  {
        __typename: "UploadBundleAnalysisSession",
        id: string,
        metadataFileS3Path?: string | null,
        step?: string | null,
        listOfRawFileNames?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis: string,
        studyPhaseId: string,
        metadataHelperLambdaResponseS3Path?: string | null,
        barcodeToFilesToUploadS3Path?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        owner?: string | null,
      } | null,
      uploadSamples?:  {
        __typename: "ModelUploadSampleConnection",
        nextToken?: string | null,
      } | null,
      uploadFiles?:  {
        __typename: "ModelUploadFileConnection",
        nextToken?: string | null,
      } | null,
      adminGroups?: Array< string | null > | null,
      completionTime?: string | null,
      created: string,
      updated: string,
      studyPhaseUploadBundlesId?: string | null,
      uploadBundleUploadBundleAnalysisSessionId?: string | null,
      owner?: string | null,
    } | null,
    sampleId: string,
    barcode: string,
    sampleLog?: string | null,
    writeMetadata: boolean,
    status?: string | null,
    uploadFiles?:  {
      __typename: "ModelUploadFileConnection",
      items:  Array< {
        __typename: "UploadFile",
        id: string,
        uploadSampleId?: string | null,
        uploadBundleId?: string | null,
        multipartUploadId?: string | null,
        status?: UploadFileStatus | null,
        toSkipUpload: boolean,
        fileName: string,
        fileType: string,
        s3Path: string,
        size?: number | null,
        fileLog?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        uploadBundleUploadFilesId?: string | null,
        uploadSampleUploadFilesId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    uploadBundleUploadSamplesId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateUploadSampleMutationVariables = {
  input: UpdateUploadSampleInput,
  condition?: ModelUploadSampleConditionInput | null,
};

export type UpdateUploadSampleMutation = {
  updateUploadSample?:  {
    __typename: "UploadSample",
    id: string,
    uploadBundleId: string,
    uploadBundle?:  {
      __typename: "UploadBundle",
      id: string,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      bundleLog?: string | null,
      validationErrors?: string | null,
      status?: string | null,
      userEmail?: string | null,
      uploadBundleAnalysisSession?:  {
        __typename: "UploadBundleAnalysisSession",
        id: string,
        metadataFileS3Path?: string | null,
        step?: string | null,
        listOfRawFileNames?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis: string,
        studyPhaseId: string,
        metadataHelperLambdaResponseS3Path?: string | null,
        barcodeToFilesToUploadS3Path?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        owner?: string | null,
      } | null,
      uploadSamples?:  {
        __typename: "ModelUploadSampleConnection",
        nextToken?: string | null,
      } | null,
      uploadFiles?:  {
        __typename: "ModelUploadFileConnection",
        nextToken?: string | null,
      } | null,
      adminGroups?: Array< string | null > | null,
      completionTime?: string | null,
      created: string,
      updated: string,
      studyPhaseUploadBundlesId?: string | null,
      uploadBundleUploadBundleAnalysisSessionId?: string | null,
      owner?: string | null,
    } | null,
    sampleId: string,
    barcode: string,
    sampleLog?: string | null,
    writeMetadata: boolean,
    status?: string | null,
    uploadFiles?:  {
      __typename: "ModelUploadFileConnection",
      items:  Array< {
        __typename: "UploadFile",
        id: string,
        uploadSampleId?: string | null,
        uploadBundleId?: string | null,
        multipartUploadId?: string | null,
        status?: UploadFileStatus | null,
        toSkipUpload: boolean,
        fileName: string,
        fileType: string,
        s3Path: string,
        size?: number | null,
        fileLog?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        uploadBundleUploadFilesId?: string | null,
        uploadSampleUploadFilesId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    uploadBundleUploadSamplesId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteUploadSampleMutationVariables = {
  input: DeleteUploadSampleInput,
  condition?: ModelUploadSampleConditionInput | null,
};

export type DeleteUploadSampleMutation = {
  deleteUploadSample?:  {
    __typename: "UploadSample",
    id: string,
    uploadBundleId: string,
    uploadBundle?:  {
      __typename: "UploadBundle",
      id: string,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      bundleLog?: string | null,
      validationErrors?: string | null,
      status?: string | null,
      userEmail?: string | null,
      uploadBundleAnalysisSession?:  {
        __typename: "UploadBundleAnalysisSession",
        id: string,
        metadataFileS3Path?: string | null,
        step?: string | null,
        listOfRawFileNames?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis: string,
        studyPhaseId: string,
        metadataHelperLambdaResponseS3Path?: string | null,
        barcodeToFilesToUploadS3Path?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        owner?: string | null,
      } | null,
      uploadSamples?:  {
        __typename: "ModelUploadSampleConnection",
        nextToken?: string | null,
      } | null,
      uploadFiles?:  {
        __typename: "ModelUploadFileConnection",
        nextToken?: string | null,
      } | null,
      adminGroups?: Array< string | null > | null,
      completionTime?: string | null,
      created: string,
      updated: string,
      studyPhaseUploadBundlesId?: string | null,
      uploadBundleUploadBundleAnalysisSessionId?: string | null,
      owner?: string | null,
    } | null,
    sampleId: string,
    barcode: string,
    sampleLog?: string | null,
    writeMetadata: boolean,
    status?: string | null,
    uploadFiles?:  {
      __typename: "ModelUploadFileConnection",
      items:  Array< {
        __typename: "UploadFile",
        id: string,
        uploadSampleId?: string | null,
        uploadBundleId?: string | null,
        multipartUploadId?: string | null,
        status?: UploadFileStatus | null,
        toSkipUpload: boolean,
        fileName: string,
        fileType: string,
        s3Path: string,
        size?: number | null,
        fileLog?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        uploadBundleUploadFilesId?: string | null,
        uploadSampleUploadFilesId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    uploadBundleUploadSamplesId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateUploadSampleAnalysisSessionMutationVariables = {
  input: CreateUploadSampleAnalysisSessionInput,
  condition?: ModelUploadSampleAnalysisSessionConditionInput | null,
};

export type CreateUploadSampleAnalysisSessionMutation = {
  createUploadSampleAnalysisSession?:  {
    __typename: "UploadSampleAnalysisSession",
    id: string,
    uploadBundleAnalysisSessionId: string,
    uploadBundleAnalysisSession?:  {
      __typename: "UploadBundleAnalysisSession",
      id: string,
      uploadSampleAnalysisSession?:  {
        __typename: "ModelUploadSampleAnalysisSessionConnection",
        nextToken?: string | null,
      } | null,
      metadataFileS3Path?: string | null,
      step?: string | null,
      listOfRawFileNames?: Array< string | null > | null,
      analysisType: AnalysisType,
      subtypeOfAnalysis: string,
      studyPhaseId: string,
      metadataHelperLambdaResponseS3Path?: string | null,
      barcodeToFilesToUploadS3Path?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null,
    sampleId: string,
    rowFromMetaDataFileMappedToSample: string,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    uploadBundleAnalysisSessionUploadSampleAnalysisSessionId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateUploadSampleAnalysisSessionMutationVariables = {
  input: UpdateUploadSampleAnalysisSessionInput,
  condition?: ModelUploadSampleAnalysisSessionConditionInput | null,
};

export type UpdateUploadSampleAnalysisSessionMutation = {
  updateUploadSampleAnalysisSession?:  {
    __typename: "UploadSampleAnalysisSession",
    id: string,
    uploadBundleAnalysisSessionId: string,
    uploadBundleAnalysisSession?:  {
      __typename: "UploadBundleAnalysisSession",
      id: string,
      uploadSampleAnalysisSession?:  {
        __typename: "ModelUploadSampleAnalysisSessionConnection",
        nextToken?: string | null,
      } | null,
      metadataFileS3Path?: string | null,
      step?: string | null,
      listOfRawFileNames?: Array< string | null > | null,
      analysisType: AnalysisType,
      subtypeOfAnalysis: string,
      studyPhaseId: string,
      metadataHelperLambdaResponseS3Path?: string | null,
      barcodeToFilesToUploadS3Path?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null,
    sampleId: string,
    rowFromMetaDataFileMappedToSample: string,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    uploadBundleAnalysisSessionUploadSampleAnalysisSessionId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteUploadSampleAnalysisSessionMutationVariables = {
  input: DeleteUploadSampleAnalysisSessionInput,
  condition?: ModelUploadSampleAnalysisSessionConditionInput | null,
};

export type DeleteUploadSampleAnalysisSessionMutation = {
  deleteUploadSampleAnalysisSession?:  {
    __typename: "UploadSampleAnalysisSession",
    id: string,
    uploadBundleAnalysisSessionId: string,
    uploadBundleAnalysisSession?:  {
      __typename: "UploadBundleAnalysisSession",
      id: string,
      uploadSampleAnalysisSession?:  {
        __typename: "ModelUploadSampleAnalysisSessionConnection",
        nextToken?: string | null,
      } | null,
      metadataFileS3Path?: string | null,
      step?: string | null,
      listOfRawFileNames?: Array< string | null > | null,
      analysisType: AnalysisType,
      subtypeOfAnalysis: string,
      studyPhaseId: string,
      metadataHelperLambdaResponseS3Path?: string | null,
      barcodeToFilesToUploadS3Path?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null,
    sampleId: string,
    rowFromMetaDataFileMappedToSample: string,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    uploadBundleAnalysisSessionUploadSampleAnalysisSessionId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateUploadFileMutationVariables = {
  input: CreateUploadFileInput,
  condition?: ModelUploadFileConditionInput | null,
};

export type CreateUploadFileMutation = {
  createUploadFile?:  {
    __typename: "UploadFile",
    id: string,
    uploadSampleId?: string | null,
    uploadSample?:  {
      __typename: "UploadSample",
      id: string,
      uploadBundleId: string,
      uploadBundle?:  {
        __typename: "UploadBundle",
        id: string,
        studyPhaseId?: string | null,
        bundleLog?: string | null,
        validationErrors?: string | null,
        status?: string | null,
        userEmail?: string | null,
        adminGroups?: Array< string | null > | null,
        completionTime?: string | null,
        created: string,
        updated: string,
        studyPhaseUploadBundlesId?: string | null,
        uploadBundleUploadBundleAnalysisSessionId?: string | null,
        owner?: string | null,
      } | null,
      sampleId: string,
      barcode: string,
      sampleLog?: string | null,
      writeMetadata: boolean,
      status?: string | null,
      uploadFiles?:  {
        __typename: "ModelUploadFileConnection",
        nextToken?: string | null,
      } | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      uploadBundleUploadSamplesId?: string | null,
      owner?: string | null,
    } | null,
    uploadBundleId?: string | null,
    uploadBundle?:  {
      __typename: "UploadBundle",
      id: string,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      bundleLog?: string | null,
      validationErrors?: string | null,
      status?: string | null,
      userEmail?: string | null,
      uploadBundleAnalysisSession?:  {
        __typename: "UploadBundleAnalysisSession",
        id: string,
        metadataFileS3Path?: string | null,
        step?: string | null,
        listOfRawFileNames?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis: string,
        studyPhaseId: string,
        metadataHelperLambdaResponseS3Path?: string | null,
        barcodeToFilesToUploadS3Path?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        owner?: string | null,
      } | null,
      uploadSamples?:  {
        __typename: "ModelUploadSampleConnection",
        nextToken?: string | null,
      } | null,
      uploadFiles?:  {
        __typename: "ModelUploadFileConnection",
        nextToken?: string | null,
      } | null,
      adminGroups?: Array< string | null > | null,
      completionTime?: string | null,
      created: string,
      updated: string,
      studyPhaseUploadBundlesId?: string | null,
      uploadBundleUploadBundleAnalysisSessionId?: string | null,
      owner?: string | null,
    } | null,
    multipartUploadId?: string | null,
    status?: UploadFileStatus | null,
    toSkipUpload: boolean,
    fileName: string,
    fileType: string,
    s3Path: string,
    size?: number | null,
    fileLog?: string | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    uploadBundleUploadFilesId?: string | null,
    uploadSampleUploadFilesId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateUploadFileMutationVariables = {
  input: UpdateUploadFileInput,
  condition?: ModelUploadFileConditionInput | null,
};

export type UpdateUploadFileMutation = {
  updateUploadFile?:  {
    __typename: "UploadFile",
    id: string,
    uploadSampleId?: string | null,
    uploadSample?:  {
      __typename: "UploadSample",
      id: string,
      uploadBundleId: string,
      uploadBundle?:  {
        __typename: "UploadBundle",
        id: string,
        studyPhaseId?: string | null,
        bundleLog?: string | null,
        validationErrors?: string | null,
        status?: string | null,
        userEmail?: string | null,
        adminGroups?: Array< string | null > | null,
        completionTime?: string | null,
        created: string,
        updated: string,
        studyPhaseUploadBundlesId?: string | null,
        uploadBundleUploadBundleAnalysisSessionId?: string | null,
        owner?: string | null,
      } | null,
      sampleId: string,
      barcode: string,
      sampleLog?: string | null,
      writeMetadata: boolean,
      status?: string | null,
      uploadFiles?:  {
        __typename: "ModelUploadFileConnection",
        nextToken?: string | null,
      } | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      uploadBundleUploadSamplesId?: string | null,
      owner?: string | null,
    } | null,
    uploadBundleId?: string | null,
    uploadBundle?:  {
      __typename: "UploadBundle",
      id: string,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      bundleLog?: string | null,
      validationErrors?: string | null,
      status?: string | null,
      userEmail?: string | null,
      uploadBundleAnalysisSession?:  {
        __typename: "UploadBundleAnalysisSession",
        id: string,
        metadataFileS3Path?: string | null,
        step?: string | null,
        listOfRawFileNames?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis: string,
        studyPhaseId: string,
        metadataHelperLambdaResponseS3Path?: string | null,
        barcodeToFilesToUploadS3Path?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        owner?: string | null,
      } | null,
      uploadSamples?:  {
        __typename: "ModelUploadSampleConnection",
        nextToken?: string | null,
      } | null,
      uploadFiles?:  {
        __typename: "ModelUploadFileConnection",
        nextToken?: string | null,
      } | null,
      adminGroups?: Array< string | null > | null,
      completionTime?: string | null,
      created: string,
      updated: string,
      studyPhaseUploadBundlesId?: string | null,
      uploadBundleUploadBundleAnalysisSessionId?: string | null,
      owner?: string | null,
    } | null,
    multipartUploadId?: string | null,
    status?: UploadFileStatus | null,
    toSkipUpload: boolean,
    fileName: string,
    fileType: string,
    s3Path: string,
    size?: number | null,
    fileLog?: string | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    uploadBundleUploadFilesId?: string | null,
    uploadSampleUploadFilesId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteUploadFileMutationVariables = {
  input: DeleteUploadFileInput,
  condition?: ModelUploadFileConditionInput | null,
};

export type DeleteUploadFileMutation = {
  deleteUploadFile?:  {
    __typename: "UploadFile",
    id: string,
    uploadSampleId?: string | null,
    uploadSample?:  {
      __typename: "UploadSample",
      id: string,
      uploadBundleId: string,
      uploadBundle?:  {
        __typename: "UploadBundle",
        id: string,
        studyPhaseId?: string | null,
        bundleLog?: string | null,
        validationErrors?: string | null,
        status?: string | null,
        userEmail?: string | null,
        adminGroups?: Array< string | null > | null,
        completionTime?: string | null,
        created: string,
        updated: string,
        studyPhaseUploadBundlesId?: string | null,
        uploadBundleUploadBundleAnalysisSessionId?: string | null,
        owner?: string | null,
      } | null,
      sampleId: string,
      barcode: string,
      sampleLog?: string | null,
      writeMetadata: boolean,
      status?: string | null,
      uploadFiles?:  {
        __typename: "ModelUploadFileConnection",
        nextToken?: string | null,
      } | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      uploadBundleUploadSamplesId?: string | null,
      owner?: string | null,
    } | null,
    uploadBundleId?: string | null,
    uploadBundle?:  {
      __typename: "UploadBundle",
      id: string,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      bundleLog?: string | null,
      validationErrors?: string | null,
      status?: string | null,
      userEmail?: string | null,
      uploadBundleAnalysisSession?:  {
        __typename: "UploadBundleAnalysisSession",
        id: string,
        metadataFileS3Path?: string | null,
        step?: string | null,
        listOfRawFileNames?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis: string,
        studyPhaseId: string,
        metadataHelperLambdaResponseS3Path?: string | null,
        barcodeToFilesToUploadS3Path?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        owner?: string | null,
      } | null,
      uploadSamples?:  {
        __typename: "ModelUploadSampleConnection",
        nextToken?: string | null,
      } | null,
      uploadFiles?:  {
        __typename: "ModelUploadFileConnection",
        nextToken?: string | null,
      } | null,
      adminGroups?: Array< string | null > | null,
      completionTime?: string | null,
      created: string,
      updated: string,
      studyPhaseUploadBundlesId?: string | null,
      uploadBundleUploadBundleAnalysisSessionId?: string | null,
      owner?: string | null,
    } | null,
    multipartUploadId?: string | null,
    status?: UploadFileStatus | null,
    toSkipUpload: boolean,
    fileName: string,
    fileType: string,
    s3Path: string,
    size?: number | null,
    fileLog?: string | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    uploadBundleUploadFilesId?: string | null,
    uploadSampleUploadFilesId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateOrganizationLaunchablePipelinesMutationVariables = {
  input: CreateOrganizationLaunchablePipelinesInput,
  condition?: ModelOrganizationLaunchablePipelinesConditionInput | null,
};

export type CreateOrganizationLaunchablePipelinesMutation = {
  createOrganizationLaunchablePipelines?:  {
    __typename: "OrganizationLaunchablePipelines",
    id: string,
    organizationId: string,
    launchablePipelinesId: string,
    organization:  {
      __typename: "Organization",
      id: string,
      organizationName: string,
      organizationCode?: string | null,
      active: boolean,
      studies?:  {
        __typename: "ModelStudyConnection",
        nextToken?: string | null,
      } | null,
      launchablePipelines?:  {
        __typename: "ModelOrganizationLaunchablePipelinesConnection",
        nextToken?: string | null,
      } | null,
      notificationEmail: string,
      description: string,
      organizationAnswers?: string | null,
      url: string,
      s3Path?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      created: string,
      updated: string,
    },
    launchablePipelines:  {
      __typename: "LaunchablePipelines",
      id: string,
      pipelineName?: string | null,
      organizations?:  {
        __typename: "ModelOrganizationLaunchablePipelinesConnection",
        nextToken?: string | null,
      } | null,
      pipelineVersion?: string | null,
      parameters?: string | null,
      description?: string | null,
      inputFileInfo?: string | null,
      supportedAnalyses?: Array< AnalysisType | null > | null,
      supportedSubtypeOfAnalyses?: Array< string | null > | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      gitRepo?: string | null,
      workflowId?: string | null,
      created: string,
      updated: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateOrganizationLaunchablePipelinesMutationVariables = {
  input: UpdateOrganizationLaunchablePipelinesInput,
  condition?: ModelOrganizationLaunchablePipelinesConditionInput | null,
};

export type UpdateOrganizationLaunchablePipelinesMutation = {
  updateOrganizationLaunchablePipelines?:  {
    __typename: "OrganizationLaunchablePipelines",
    id: string,
    organizationId: string,
    launchablePipelinesId: string,
    organization:  {
      __typename: "Organization",
      id: string,
      organizationName: string,
      organizationCode?: string | null,
      active: boolean,
      studies?:  {
        __typename: "ModelStudyConnection",
        nextToken?: string | null,
      } | null,
      launchablePipelines?:  {
        __typename: "ModelOrganizationLaunchablePipelinesConnection",
        nextToken?: string | null,
      } | null,
      notificationEmail: string,
      description: string,
      organizationAnswers?: string | null,
      url: string,
      s3Path?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      created: string,
      updated: string,
    },
    launchablePipelines:  {
      __typename: "LaunchablePipelines",
      id: string,
      pipelineName?: string | null,
      organizations?:  {
        __typename: "ModelOrganizationLaunchablePipelinesConnection",
        nextToken?: string | null,
      } | null,
      pipelineVersion?: string | null,
      parameters?: string | null,
      description?: string | null,
      inputFileInfo?: string | null,
      supportedAnalyses?: Array< AnalysisType | null > | null,
      supportedSubtypeOfAnalyses?: Array< string | null > | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      gitRepo?: string | null,
      workflowId?: string | null,
      created: string,
      updated: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteOrganizationLaunchablePipelinesMutationVariables = {
  input: DeleteOrganizationLaunchablePipelinesInput,
  condition?: ModelOrganizationLaunchablePipelinesConditionInput | null,
};

export type DeleteOrganizationLaunchablePipelinesMutation = {
  deleteOrganizationLaunchablePipelines?:  {
    __typename: "OrganizationLaunchablePipelines",
    id: string,
    organizationId: string,
    launchablePipelinesId: string,
    organization:  {
      __typename: "Organization",
      id: string,
      organizationName: string,
      organizationCode?: string | null,
      active: boolean,
      studies?:  {
        __typename: "ModelStudyConnection",
        nextToken?: string | null,
      } | null,
      launchablePipelines?:  {
        __typename: "ModelOrganizationLaunchablePipelinesConnection",
        nextToken?: string | null,
      } | null,
      notificationEmail: string,
      description: string,
      organizationAnswers?: string | null,
      url: string,
      s3Path?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      created: string,
      updated: string,
    },
    launchablePipelines:  {
      __typename: "LaunchablePipelines",
      id: string,
      pipelineName?: string | null,
      organizations?:  {
        __typename: "ModelOrganizationLaunchablePipelinesConnection",
        nextToken?: string | null,
      } | null,
      pipelineVersion?: string | null,
      parameters?: string | null,
      description?: string | null,
      inputFileInfo?: string | null,
      supportedAnalyses?: Array< AnalysisType | null > | null,
      supportedSubtypeOfAnalyses?: Array< string | null > | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      gitRepo?: string | null,
      workflowId?: string | null,
      created: string,
      updated: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type QueryS3UploadPathsAndValidationSchemaQueryVariables = {
  event: string,
};

export type QueryS3UploadPathsAndValidationSchemaQuery = {
  queryS3UploadPathsAndValidationSchema: string,
};

export type QueryTriggerMetaDataUploadQueryVariables = {
  event: string,
};

export type QueryTriggerMetaDataUploadQuery = {
  queryTriggerMetaDataUpload: string,
};

export type GeneratePlotlyJWTQueryVariables = {
  event: string,
};

export type GeneratePlotlyJWTQuery = {
  generatePlotlyJWT?: string | null,
};

export type GetOrganizationQueryVariables = {
  id: string,
};

export type GetOrganizationQuery = {
  getOrganization?:  {
    __typename: "Organization",
    id: string,
    organizationName: string,
    organizationCode?: string | null,
    active: boolean,
    studies?:  {
      __typename: "ModelStudyConnection",
      items:  Array< {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    launchablePipelines?:  {
      __typename: "ModelOrganizationLaunchablePipelinesConnection",
      items:  Array< {
        __typename: "OrganizationLaunchablePipelines",
        id: string,
        organizationId: string,
        launchablePipelinesId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    notificationEmail: string,
    description: string,
    organizationAnswers?: string | null,
    url: string,
    s3Path?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    created: string,
    updated: string,
  } | null,
};

export type ListOrganizationsQueryVariables = {
  id?: string | null,
  filter?: ModelOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOrganizationsQuery = {
  listOrganizations?:  {
    __typename: "ModelOrganizationConnection",
    items:  Array< {
      __typename: "Organization",
      id: string,
      organizationName: string,
      organizationCode?: string | null,
      active: boolean,
      studies?:  {
        __typename: "ModelStudyConnection",
        nextToken?: string | null,
      } | null,
      launchablePipelines?:  {
        __typename: "ModelOrganizationLaunchablePipelinesConnection",
        nextToken?: string | null,
      } | null,
      notificationEmail: string,
      description: string,
      organizationAnswers?: string | null,
      url: string,
      s3Path?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      created: string,
      updated: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetStudyQueryVariables = {
  id: string,
};

export type GetStudyQuery = {
  getStudy?:  {
    __typename: "Study",
    id: string,
    studyName: string,
    organizationId: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      organizationName: string,
      organizationCode?: string | null,
      active: boolean,
      studies?:  {
        __typename: "ModelStudyConnection",
        nextToken?: string | null,
      } | null,
      launchablePipelines?:  {
        __typename: "ModelOrganizationLaunchablePipelinesConnection",
        nextToken?: string | null,
      } | null,
      notificationEmail: string,
      description: string,
      organizationAnswers?: string | null,
      url: string,
      s3Path?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      created: string,
      updated: string,
    } | null,
    studyPhases?:  {
      __typename: "ModelStudyPhaseConnection",
      items:  Array< {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    batches?:  {
      __typename: "ModelBatchConnection",
      items:  Array< {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pipelines?:  {
      __typename: "ModelPipelineConnection",
      items:  Array< {
        __typename: "Pipeline",
        id: string,
        name: string,
        status?: pipelineStatus | null,
        description?: string | null,
        s3Event?: string | null,
        s3ParamsFile?: string | null,
        s3Results?: string | null,
        visFileInfoS3path?: string | null,
        s3NfLog?: string | null,
        s3NfTasksLogs?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis?: string | null,
        parameters: string,
        studyId?: string | null,
        studyPhaseId?: string | null,
        scheduledPipeline: boolean,
        scheduledDateTime?: string | null,
        launchablePipelineId: string,
        batchId: string,
        runId?: string | null,
        userEmail: string,
        pipelineStarted?: string | null,
        pipelineCompleted?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPipelinesId?: string | null,
        studyPhasePipelinesId?: string | null,
        batchPipelinesId?: string | null,
        launchablePipelinesPipelinesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    description: string,
    studyCode: string,
    studyAnswers?: string | null,
    s3Path?: string | null,
    croGroups?: Array< string | null > | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    organizationStudiesId?: string | null,
  } | null,
};

export type ListStudiesQueryVariables = {
  id?: string | null,
  filter?: ModelStudyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListStudiesQuery = {
  listStudies?:  {
    __typename: "ModelStudyConnection",
    items:  Array< {
      __typename: "Study",
      id: string,
      studyName: string,
      organizationId: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        organizationName: string,
        organizationCode?: string | null,
        active: boolean,
        notificationEmail: string,
        description: string,
        organizationAnswers?: string | null,
        url: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        created: string,
        updated: string,
      } | null,
      studyPhases?:  {
        __typename: "ModelStudyPhaseConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      description: string,
      studyCode: string,
      studyAnswers?: string | null,
      s3Path?: string | null,
      croGroups?: Array< string | null > | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      organizationStudiesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetStudyPhaseQueryVariables = {
  id: string,
};

export type GetStudyPhaseQuery = {
  getStudyPhase?:  {
    __typename: "StudyPhase",
    id: string,
    studyId: string,
    study?:  {
      __typename: "Study",
      id: string,
      studyName: string,
      organizationId: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        organizationName: string,
        organizationCode?: string | null,
        active: boolean,
        notificationEmail: string,
        description: string,
        organizationAnswers?: string | null,
        url: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        created: string,
        updated: string,
      } | null,
      studyPhases?:  {
        __typename: "ModelStudyPhaseConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      description: string,
      studyCode: string,
      studyAnswers?: string | null,
      s3Path?: string | null,
      croGroups?: Array< string | null > | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      organizationStudiesId?: string | null,
    } | null,
    metaDataValidationSchemaTemplateId: string,
    metaDataValidationSchemaTemplate?:  {
      __typename: "MetaDataValidationSchemaTemplate",
      id: string,
      studyPhases?:  {
        __typename: "ModelStudyPhaseConnection",
        nextToken?: string | null,
      } | null,
      version: string,
      description?: string | null,
      templateFiles: string,
      validationSchemaTemplate: string,
      created: string,
      updated: string,
    } | null,
    groups?:  {
      __typename: "ModelGroupConnection",
      items:  Array< {
        __typename: "Group",
        id: string,
        studyPhaseId: string,
        type?: string | null,
        groupName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseGroupsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    samples?:  {
      __typename: "ModelSampleConnection",
      items:  Array< {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    batches?:  {
      __typename: "ModelBatchConnection",
      items:  Array< {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    uploadBundles?:  {
      __typename: "ModelUploadBundleConnection",
      items:  Array< {
        __typename: "UploadBundle",
        id: string,
        studyPhaseId?: string | null,
        bundleLog?: string | null,
        validationErrors?: string | null,
        status?: string | null,
        userEmail?: string | null,
        adminGroups?: Array< string | null > | null,
        completionTime?: string | null,
        created: string,
        updated: string,
        studyPhaseUploadBundlesId?: string | null,
        uploadBundleUploadBundleAnalysisSessionId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pipelines?:  {
      __typename: "ModelPipelineConnection",
      items:  Array< {
        __typename: "Pipeline",
        id: string,
        name: string,
        status?: pipelineStatus | null,
        description?: string | null,
        s3Event?: string | null,
        s3ParamsFile?: string | null,
        s3Results?: string | null,
        visFileInfoS3path?: string | null,
        s3NfLog?: string | null,
        s3NfTasksLogs?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis?: string | null,
        parameters: string,
        studyId?: string | null,
        studyPhaseId?: string | null,
        scheduledPipeline: boolean,
        scheduledDateTime?: string | null,
        launchablePipelineId: string,
        batchId: string,
        runId?: string | null,
        userEmail: string,
        pipelineStarted?: string | null,
        pipelineCompleted?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPipelinesId?: string | null,
        studyPhasePipelinesId?: string | null,
        batchPipelinesId?: string | null,
        launchablePipelinesPipelinesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    validationSchema?: string | null,
    description: string,
    studyPhaseName: string,
    studyPhaseAnswers?: string | null,
    experimentSpecificVariables?: string | null,
    sampleTypes?: Array< string | null > | null,
    specimens?: Array< string | null > | null,
    species?: Array< string | null > | null,
    s3Path?: string | null,
    groupNames?: Array< string | null > | null,
    labAssayTechnologies?: Array< string | null > | null,
    phase: Phase,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    croGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyStudyPhasesId?: string | null,
    metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
  } | null,
};

export type ListStudyPhasesQueryVariables = {
  id?: string | null,
  filter?: ModelStudyPhaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListStudyPhasesQuery = {
  listStudyPhases?:  {
    __typename: "ModelStudyPhaseConnection",
    items:  Array< {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEntityQuestionsQueryVariables = {
  id: string,
};

export type GetEntityQuestionsQuery = {
  getEntityQuestions?:  {
    __typename: "EntityQuestions",
    id: string,
    entityType?: EntityType | null,
    questions?: string | null,
    created: string,
    updated: string,
  } | null,
};

export type ListEntityQuestionsQueryVariables = {
  id?: string | null,
  filter?: ModelEntityQuestionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListEntityQuestionsQuery = {
  listEntityQuestions?:  {
    __typename: "ModelEntityQuestionsConnection",
    items:  Array< {
      __typename: "EntityQuestions",
      id: string,
      entityType?: EntityType | null,
      questions?: string | null,
      created: string,
      updated: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMetaDataValidationSchemaTemplateQueryVariables = {
  id: string,
};

export type GetMetaDataValidationSchemaTemplateQuery = {
  getMetaDataValidationSchemaTemplate?:  {
    __typename: "MetaDataValidationSchemaTemplate",
    id: string,
    studyPhases?:  {
      __typename: "ModelStudyPhaseConnection",
      items:  Array< {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    version: string,
    description?: string | null,
    templateFiles: string,
    validationSchemaTemplate: string,
    created: string,
    updated: string,
  } | null,
};

export type ListMetaDataValidationSchemaTemplatesQueryVariables = {
  id?: string | null,
  filter?: ModelMetaDataValidationSchemaTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListMetaDataValidationSchemaTemplatesQuery = {
  listMetaDataValidationSchemaTemplates?:  {
    __typename: "ModelMetaDataValidationSchemaTemplateConnection",
    items:  Array< {
      __typename: "MetaDataValidationSchemaTemplate",
      id: string,
      studyPhases?:  {
        __typename: "ModelStudyPhaseConnection",
        nextToken?: string | null,
      } | null,
      version: string,
      description?: string | null,
      templateFiles: string,
      validationSchemaTemplate: string,
      created: string,
      updated: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGroupQueryVariables = {
  id: string,
};

export type GetGroupQuery = {
  getGroup?:  {
    __typename: "Group",
    id: string,
    studyPhaseId: string,
    studyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    type?: string | null,
    groupName: string,
    s3Path?: string | null,
    patients?:  {
      __typename: "ModelPatientConnection",
      items:  Array< {
        __typename: "Patient",
        id: string,
        groupId: string,
        patientStudyPhaseId: string,
        patientStudyId: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        groupPatientsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyPhaseGroupsId?: string | null,
  } | null,
};

export type ListGroupsQueryVariables = {
  id?: string | null,
  filter?: ModelGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListGroupsQuery = {
  listGroups?:  {
    __typename: "ModelGroupConnection",
    items:  Array< {
      __typename: "Group",
      id: string,
      studyPhaseId: string,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      type?: string | null,
      groupName: string,
      s3Path?: string | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseGroupsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPatientQueryVariables = {
  id: string,
};

export type GetPatientQuery = {
  getPatient?:  {
    __typename: "Patient",
    id: string,
    groupId: string,
    group?:  {
      __typename: "Group",
      id: string,
      studyPhaseId: string,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      type?: string | null,
      groupName: string,
      s3Path?: string | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseGroupsId?: string | null,
    } | null,
    timepoints?:  {
      __typename: "ModelTimepointConnection",
      items:  Array< {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    patientStudyPhaseId: string,
    patientStudyId: string,
    s3Path?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    groupPatientsId?: string | null,
  } | null,
};

export type ListPatientsQueryVariables = {
  id?: string | null,
  filter?: ModelPatientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPatientsQuery = {
  listPatients?:  {
    __typename: "ModelPatientConnection",
    items:  Array< {
      __typename: "Patient",
      id: string,
      groupId: string,
      group?:  {
        __typename: "Group",
        id: string,
        studyPhaseId: string,
        type?: string | null,
        groupName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseGroupsId?: string | null,
      } | null,
      timepoints?:  {
        __typename: "ModelTimepointConnection",
        nextToken?: string | null,
      } | null,
      patientStudyPhaseId: string,
      patientStudyId: string,
      s3Path?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      groupPatientsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTimepointQueryVariables = {
  id: string,
};

export type GetTimepointQuery = {
  getTimepoint?:  {
    __typename: "Timepoint",
    id: string,
    patientId: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      groupId: string,
      group?:  {
        __typename: "Group",
        id: string,
        studyPhaseId: string,
        type?: string | null,
        groupName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseGroupsId?: string | null,
      } | null,
      timepoints?:  {
        __typename: "ModelTimepointConnection",
        nextToken?: string | null,
      } | null,
      patientStudyPhaseId: string,
      patientStudyId: string,
      s3Path?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      groupPatientsId?: string | null,
    } | null,
    samples?:  {
      __typename: "ModelSampleConnection",
      items:  Array< {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    timepointName: string,
    s3Path?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    patientTimepointsId?: string | null,
  } | null,
};

export type ListTimepointsQueryVariables = {
  id?: string | null,
  filter?: ModelTimepointFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTimepointsQuery = {
  listTimepoints?:  {
    __typename: "ModelTimepointConnection",
    items:  Array< {
      __typename: "Timepoint",
      id: string,
      patientId: string,
      patient?:  {
        __typename: "Patient",
        id: string,
        groupId: string,
        patientStudyPhaseId: string,
        patientStudyId: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        groupPatientsId?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      timepointName: string,
      s3Path?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      patientTimepointsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSampleQueryVariables = {
  id: string,
};

export type GetSampleQuery = {
  getSample?:  {
    __typename: "Sample",
    id: string,
    timepointId?: string | null,
    timepoint?:  {
      __typename: "Timepoint",
      id: string,
      patientId: string,
      patient?:  {
        __typename: "Patient",
        id: string,
        groupId: string,
        patientStudyPhaseId: string,
        patientStudyId: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        groupPatientsId?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      timepointName: string,
      s3Path?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      patientTimepointsId?: string | null,
    } | null,
    studyPhaseId?: string | null,
    StudyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    BatchId?: string | null,
    Batch?:  {
      __typename: "Batch",
      id: string,
      batchName: string,
      batchAnnotations?: string | null,
      batchGroups?: string | null,
      annotations: string,
      controlSamples: string,
      filters: string,
      description?: string | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyBatchesId?: string | null,
      studyPhaseBatchesId?: string | null,
    } | null,
    metadataImmunopeptidomicsList?:  {
      __typename: "ModelMetadataImmunopeptidomicsConnection",
      items:  Array< {
        __typename: "MetadataImmunopeptidomics",
        id: string,
        sampleId: string,
        metaDataTemplateS3Path: string,
        species: string,
        subtypeOfAnalysis?: string | null,
        sampleType: string,
        qcStatus: QcStatus,
        glacierBackup: boolean,
        rawDataS3: boolean,
        tumorVolume?: number | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        isControl: boolean,
        barcodesMappedToControl?: Array< string | null > | null,
        barcode: string,
        s3Path: Array< string | null >,
        sampleMetadataImmunopeptidomicsListId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    MetadataNanoStringList?:  {
      __typename: "ModelMetadataNanoStringConnection",
      items:  Array< {
        __typename: "MetadataNanoString",
        id: string,
        sampleId: string,
        metaDataTemplateS3Path: string,
        subtypeOfAnalysis?: string | null,
        species: string,
        sampleType: string,
        qcStatus: QcStatus,
        glacierBackup: boolean,
        rawDataS3: boolean,
        tumorVolume?: number | null,
        dv200Percentage?: number | null,
        concNanoGramPerMicroL?: number | null,
        rnaMassNanoGram?: number | null,
        rnaVolumeMicroL?: number | null,
        rin?: number | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        isControl: boolean,
        barcodesMappedToControl?: Array< string | null > | null,
        barcode: string,
        s3Path: Array< string | null >,
        sampleMetadataNanoStringListId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    MetadataRnaSeqList?:  {
      __typename: "ModelMetadataRnaSeqConnection",
      items:  Array< {
        __typename: "MetadataRnaSeq",
        id: string,
        sampleId: string,
        metaDataTemplateS3Path: string,
        subtypeOfAnalysis?: string | null,
        species: string,
        sampleType: string,
        qcStatus: QcStatus,
        glacierBackup: boolean,
        rawDataS3: boolean,
        tumorVolume?: number | null,
        sequenceType: SequenceType,
        strandSpecific: string,
        UMI: boolean,
        adaptorsRemoved: boolean,
        adaptorsS3Path?: string | null,
        indexSequences?: Array< string | null > | null,
        concNanoGramPerMicroL?: number | null,
        rnaMassNanoGram?: number | null,
        rnaVolumeMicroL?: number | null,
        rin?: number | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        isControl: boolean,
        barcodesMappedToControl?: Array< string | null > | null,
        barcode: string,
        s3Path: Array< string | null >,
        sampleMetadataRnaSeqListId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    AuxiliaryAnalysislList?:  {
      __typename: "ModelAuxiliaryAnalysisConnection",
      items:  Array< {
        __typename: "AuxiliaryAnalysis",
        id: string,
        sampleId: string,
        auxiliaryFileS3Path: string,
        vendorId: string,
        specimenType: string,
        parameters: string,
        collectionDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        sampleAuxiliaryAnalysislListId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    domainSampleId: string,
    experimentSpecificVariables?: string | null,
    collectionDate?: string | null,
    processingDate?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyPhaseSamplesId?: string | null,
    timepointSamplesId?: string | null,
    batchSamplesId?: string | null,
  } | null,
};

export type ListSamplesQueryVariables = {
  id?: string | null,
  filter?: ModelSampleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListSamplesQuery = {
  listSamples?:  {
    __typename: "ModelSampleConnection",
    items:  Array< {
      __typename: "Sample",
      id: string,
      timepointId?: string | null,
      timepoint?:  {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      StudyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      BatchId?: string | null,
      Batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      metadataImmunopeptidomicsList?:  {
        __typename: "ModelMetadataImmunopeptidomicsConnection",
        nextToken?: string | null,
      } | null,
      MetadataNanoStringList?:  {
        __typename: "ModelMetadataNanoStringConnection",
        nextToken?: string | null,
      } | null,
      MetadataRnaSeqList?:  {
        __typename: "ModelMetadataRnaSeqConnection",
        nextToken?: string | null,
      } | null,
      AuxiliaryAnalysislList?:  {
        __typename: "ModelAuxiliaryAnalysisConnection",
        nextToken?: string | null,
      } | null,
      domainSampleId: string,
      experimentSpecificVariables?: string | null,
      collectionDate?: string | null,
      processingDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseSamplesId?: string | null,
      timepointSamplesId?: string | null,
      batchSamplesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBatchQueryVariables = {
  id: string,
};

export type GetBatchQuery = {
  getBatch?:  {
    __typename: "Batch",
    id: string,
    batchName: string,
    batchAnnotations?: string | null,
    batchGroups?: string | null,
    annotations: string,
    controlSamples: string,
    filters: string,
    description?: string | null,
    samples?:  {
      __typename: "ModelSampleConnection",
      items:  Array< {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pipelines?:  {
      __typename: "ModelPipelineConnection",
      items:  Array< {
        __typename: "Pipeline",
        id: string,
        name: string,
        status?: pipelineStatus | null,
        description?: string | null,
        s3Event?: string | null,
        s3ParamsFile?: string | null,
        s3Results?: string | null,
        visFileInfoS3path?: string | null,
        s3NfLog?: string | null,
        s3NfTasksLogs?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis?: string | null,
        parameters: string,
        studyId?: string | null,
        studyPhaseId?: string | null,
        scheduledPipeline: boolean,
        scheduledDateTime?: string | null,
        launchablePipelineId: string,
        batchId: string,
        runId?: string | null,
        userEmail: string,
        pipelineStarted?: string | null,
        pipelineCompleted?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPipelinesId?: string | null,
        studyPhasePipelinesId?: string | null,
        batchPipelinesId?: string | null,
        launchablePipelinesPipelinesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    studyId: string,
    study?:  {
      __typename: "Study",
      id: string,
      studyName: string,
      organizationId: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        organizationName: string,
        organizationCode?: string | null,
        active: boolean,
        notificationEmail: string,
        description: string,
        organizationAnswers?: string | null,
        url: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        created: string,
        updated: string,
      } | null,
      studyPhases?:  {
        __typename: "ModelStudyPhaseConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      description: string,
      studyCode: string,
      studyAnswers?: string | null,
      s3Path?: string | null,
      croGroups?: Array< string | null > | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      organizationStudiesId?: string | null,
    } | null,
    studyPhaseId?: string | null,
    studyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyBatchesId?: string | null,
    studyPhaseBatchesId?: string | null,
  } | null,
};

export type ListBatchesQueryVariables = {
  id?: string | null,
  filter?: ModelBatchFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListBatchesQuery = {
  listBatches?:  {
    __typename: "ModelBatchConnection",
    items:  Array< {
      __typename: "Batch",
      id: string,
      batchName: string,
      batchAnnotations?: string | null,
      batchGroups?: string | null,
      annotations: string,
      controlSamples: string,
      filters: string,
      description?: string | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyBatchesId?: string | null,
      studyPhaseBatchesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPipelineQueryVariables = {
  id: string,
};

export type GetPipelineQuery = {
  getPipeline?:  {
    __typename: "Pipeline",
    id: string,
    name: string,
    status?: pipelineStatus | null,
    description?: string | null,
    s3Event?: string | null,
    s3ParamsFile?: string | null,
    s3Results?: string | null,
    visFileInfoS3path?: string | null,
    s3NfLog?: string | null,
    s3NfTasksLogs?: Array< string | null > | null,
    analysisType: AnalysisType,
    subtypeOfAnalysis?: string | null,
    parameters: string,
    studyId?: string | null,
    study?:  {
      __typename: "Study",
      id: string,
      studyName: string,
      organizationId: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        organizationName: string,
        organizationCode?: string | null,
        active: boolean,
        notificationEmail: string,
        description: string,
        organizationAnswers?: string | null,
        url: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        created: string,
        updated: string,
      } | null,
      studyPhases?:  {
        __typename: "ModelStudyPhaseConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      description: string,
      studyCode: string,
      studyAnswers?: string | null,
      s3Path?: string | null,
      croGroups?: Array< string | null > | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      organizationStudiesId?: string | null,
    } | null,
    studyPhaseId?: string | null,
    studyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    scheduledPipeline: boolean,
    scheduledDateTime?: string | null,
    launchablePipelineId: string,
    launchablePipeline?:  {
      __typename: "LaunchablePipelines",
      id: string,
      pipelineName?: string | null,
      organizations?:  {
        __typename: "ModelOrganizationLaunchablePipelinesConnection",
        nextToken?: string | null,
      } | null,
      pipelineVersion?: string | null,
      parameters?: string | null,
      description?: string | null,
      inputFileInfo?: string | null,
      supportedAnalyses?: Array< AnalysisType | null > | null,
      supportedSubtypeOfAnalyses?: Array< string | null > | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      gitRepo?: string | null,
      workflowId?: string | null,
      created: string,
      updated: string,
    } | null,
    batchId: string,
    batch?:  {
      __typename: "Batch",
      id: string,
      batchName: string,
      batchAnnotations?: string | null,
      batchGroups?: string | null,
      annotations: string,
      controlSamples: string,
      filters: string,
      description?: string | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyBatchesId?: string | null,
      studyPhaseBatchesId?: string | null,
    } | null,
    runId?: string | null,
    userEmail: string,
    pipelineStarted?: string | null,
    pipelineCompleted?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyPipelinesId?: string | null,
    studyPhasePipelinesId?: string | null,
    batchPipelinesId?: string | null,
    launchablePipelinesPipelinesId?: string | null,
  } | null,
};

export type ListPipelinesQueryVariables = {
  id?: string | null,
  filter?: ModelPipelineFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPipelinesQuery = {
  listPipelines?:  {
    __typename: "ModelPipelineConnection",
    items:  Array< {
      __typename: "Pipeline",
      id: string,
      name: string,
      status?: pipelineStatus | null,
      description?: string | null,
      s3Event?: string | null,
      s3ParamsFile?: string | null,
      s3Results?: string | null,
      visFileInfoS3path?: string | null,
      s3NfLog?: string | null,
      s3NfTasksLogs?: Array< string | null > | null,
      analysisType: AnalysisType,
      subtypeOfAnalysis?: string | null,
      parameters: string,
      studyId?: string | null,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      scheduledPipeline: boolean,
      scheduledDateTime?: string | null,
      launchablePipelineId: string,
      launchablePipeline?:  {
        __typename: "LaunchablePipelines",
        id: string,
        pipelineName?: string | null,
        pipelineVersion?: string | null,
        parameters?: string | null,
        description?: string | null,
        inputFileInfo?: string | null,
        supportedAnalyses?: Array< AnalysisType | null > | null,
        supportedSubtypeOfAnalyses?: Array< string | null > | null,
        gitRepo?: string | null,
        workflowId?: string | null,
        created: string,
        updated: string,
      } | null,
      batchId: string,
      batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      runId?: string | null,
      userEmail: string,
      pipelineStarted?: string | null,
      pipelineCompleted?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPipelinesId?: string | null,
      studyPhasePipelinesId?: string | null,
      batchPipelinesId?: string | null,
      launchablePipelinesPipelinesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAuxiliaryAnalysisQueryVariables = {
  id: string,
};

export type GetAuxiliaryAnalysisQuery = {
  getAuxiliaryAnalysis?:  {
    __typename: "AuxiliaryAnalysis",
    id: string,
    sampleId: string,
    sample?:  {
      __typename: "Sample",
      id: string,
      timepointId?: string | null,
      timepoint?:  {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      StudyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      BatchId?: string | null,
      Batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      metadataImmunopeptidomicsList?:  {
        __typename: "ModelMetadataImmunopeptidomicsConnection",
        nextToken?: string | null,
      } | null,
      MetadataNanoStringList?:  {
        __typename: "ModelMetadataNanoStringConnection",
        nextToken?: string | null,
      } | null,
      MetadataRnaSeqList?:  {
        __typename: "ModelMetadataRnaSeqConnection",
        nextToken?: string | null,
      } | null,
      AuxiliaryAnalysislList?:  {
        __typename: "ModelAuxiliaryAnalysisConnection",
        nextToken?: string | null,
      } | null,
      domainSampleId: string,
      experimentSpecificVariables?: string | null,
      collectionDate?: string | null,
      processingDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseSamplesId?: string | null,
      timepointSamplesId?: string | null,
      batchSamplesId?: string | null,
    } | null,
    auxiliaryFileS3Path: string,
    vendorId: string,
    specimenType: string,
    parameters: string,
    collectionDate?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    sampleAuxiliaryAnalysislListId?: string | null,
  } | null,
};

export type ListAuxiliaryAnalysesQueryVariables = {
  id?: string | null,
  filter?: ModelAuxiliaryAnalysisFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAuxiliaryAnalysesQuery = {
  listAuxiliaryAnalyses?:  {
    __typename: "ModelAuxiliaryAnalysisConnection",
    items:  Array< {
      __typename: "AuxiliaryAnalysis",
      id: string,
      sampleId: string,
      sample?:  {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null,
      auxiliaryFileS3Path: string,
      vendorId: string,
      specimenType: string,
      parameters: string,
      collectionDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      sampleAuxiliaryAnalysislListId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMetadataImmunopeptidomicsQueryVariables = {
  id: string,
};

export type GetMetadataImmunopeptidomicsQuery = {
  getMetadataImmunopeptidomics?:  {
    __typename: "MetadataImmunopeptidomics",
    id: string,
    sampleId: string,
    sample?:  {
      __typename: "Sample",
      id: string,
      timepointId?: string | null,
      timepoint?:  {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      StudyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      BatchId?: string | null,
      Batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      metadataImmunopeptidomicsList?:  {
        __typename: "ModelMetadataImmunopeptidomicsConnection",
        nextToken?: string | null,
      } | null,
      MetadataNanoStringList?:  {
        __typename: "ModelMetadataNanoStringConnection",
        nextToken?: string | null,
      } | null,
      MetadataRnaSeqList?:  {
        __typename: "ModelMetadataRnaSeqConnection",
        nextToken?: string | null,
      } | null,
      AuxiliaryAnalysislList?:  {
        __typename: "ModelAuxiliaryAnalysisConnection",
        nextToken?: string | null,
      } | null,
      domainSampleId: string,
      experimentSpecificVariables?: string | null,
      collectionDate?: string | null,
      processingDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseSamplesId?: string | null,
      timepointSamplesId?: string | null,
      batchSamplesId?: string | null,
    } | null,
    treatment?:  {
      __typename: "ModelTreatmentConnection",
      items:  Array< {
        __typename: "Treatment",
        id: string,
        metadataImmunopeptidomicsId?: string | null,
        metadataNanoStringId?: string | null,
        metadataRnaSeqId?: string | null,
        treatmentName: string,
        dose: number,
        doseUnit: string,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        metadataImmunopeptidomicsTreatmentId?: string | null,
        metadataNanoStringTreatmentId?: string | null,
        metadataRnaSeqTreatmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    metaDataTemplateS3Path: string,
    species: string,
    subtypeOfAnalysis?: string | null,
    sampleType: string,
    qcStatus: QcStatus,
    glacierBackup: boolean,
    rawDataS3: boolean,
    tumorVolume?: number | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    isControl: boolean,
    barcodesMappedToControl?: Array< string | null > | null,
    barcode: string,
    s3Path: Array< string | null >,
    sampleMetadataImmunopeptidomicsListId?: string | null,
  } | null,
};

export type ListMetadataImmunopeptidomicsQueryVariables = {
  id?: string | null,
  filter?: ModelMetadataImmunopeptidomicsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListMetadataImmunopeptidomicsQuery = {
  listMetadataImmunopeptidomics?:  {
    __typename: "ModelMetadataImmunopeptidomicsConnection",
    items:  Array< {
      __typename: "MetadataImmunopeptidomics",
      id: string,
      sampleId: string,
      sample?:  {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null,
      treatment?:  {
        __typename: "ModelTreatmentConnection",
        nextToken?: string | null,
      } | null,
      metaDataTemplateS3Path: string,
      species: string,
      subtypeOfAnalysis?: string | null,
      sampleType: string,
      qcStatus: QcStatus,
      glacierBackup: boolean,
      rawDataS3: boolean,
      tumorVolume?: number | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      isControl: boolean,
      barcodesMappedToControl?: Array< string | null > | null,
      barcode: string,
      s3Path: Array< string | null >,
      sampleMetadataImmunopeptidomicsListId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMetadataNanoStringQueryVariables = {
  id: string,
};

export type GetMetadataNanoStringQuery = {
  getMetadataNanoString?:  {
    __typename: "MetadataNanoString",
    id: string,
    sampleId: string,
    sample?:  {
      __typename: "Sample",
      id: string,
      timepointId?: string | null,
      timepoint?:  {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      StudyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      BatchId?: string | null,
      Batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      metadataImmunopeptidomicsList?:  {
        __typename: "ModelMetadataImmunopeptidomicsConnection",
        nextToken?: string | null,
      } | null,
      MetadataNanoStringList?:  {
        __typename: "ModelMetadataNanoStringConnection",
        nextToken?: string | null,
      } | null,
      MetadataRnaSeqList?:  {
        __typename: "ModelMetadataRnaSeqConnection",
        nextToken?: string | null,
      } | null,
      AuxiliaryAnalysislList?:  {
        __typename: "ModelAuxiliaryAnalysisConnection",
        nextToken?: string | null,
      } | null,
      domainSampleId: string,
      experimentSpecificVariables?: string | null,
      collectionDate?: string | null,
      processingDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseSamplesId?: string | null,
      timepointSamplesId?: string | null,
      batchSamplesId?: string | null,
    } | null,
    treatment?:  {
      __typename: "ModelTreatmentConnection",
      items:  Array< {
        __typename: "Treatment",
        id: string,
        metadataImmunopeptidomicsId?: string | null,
        metadataNanoStringId?: string | null,
        metadataRnaSeqId?: string | null,
        treatmentName: string,
        dose: number,
        doseUnit: string,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        metadataImmunopeptidomicsTreatmentId?: string | null,
        metadataNanoStringTreatmentId?: string | null,
        metadataRnaSeqTreatmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    metaDataTemplateS3Path: string,
    subtypeOfAnalysis?: string | null,
    species: string,
    sampleType: string,
    qcStatus: QcStatus,
    glacierBackup: boolean,
    rawDataS3: boolean,
    tumorVolume?: number | null,
    dv200Percentage?: number | null,
    concNanoGramPerMicroL?: number | null,
    rnaMassNanoGram?: number | null,
    rnaVolumeMicroL?: number | null,
    rin?: number | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    isControl: boolean,
    barcodesMappedToControl?: Array< string | null > | null,
    barcode: string,
    s3Path: Array< string | null >,
    sampleMetadataNanoStringListId?: string | null,
  } | null,
};

export type ListMetadataNanoStringsQueryVariables = {
  id?: string | null,
  filter?: ModelMetadataNanoStringFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListMetadataNanoStringsQuery = {
  listMetadataNanoStrings?:  {
    __typename: "ModelMetadataNanoStringConnection",
    items:  Array< {
      __typename: "MetadataNanoString",
      id: string,
      sampleId: string,
      sample?:  {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null,
      treatment?:  {
        __typename: "ModelTreatmentConnection",
        nextToken?: string | null,
      } | null,
      metaDataTemplateS3Path: string,
      subtypeOfAnalysis?: string | null,
      species: string,
      sampleType: string,
      qcStatus: QcStatus,
      glacierBackup: boolean,
      rawDataS3: boolean,
      tumorVolume?: number | null,
      dv200Percentage?: number | null,
      concNanoGramPerMicroL?: number | null,
      rnaMassNanoGram?: number | null,
      rnaVolumeMicroL?: number | null,
      rin?: number | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      isControl: boolean,
      barcodesMappedToControl?: Array< string | null > | null,
      barcode: string,
      s3Path: Array< string | null >,
      sampleMetadataNanoStringListId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMetadataRnaSeqQueryVariables = {
  id: string,
};

export type GetMetadataRnaSeqQuery = {
  getMetadataRnaSeq?:  {
    __typename: "MetadataRnaSeq",
    id: string,
    sampleId: string,
    sample?:  {
      __typename: "Sample",
      id: string,
      timepointId?: string | null,
      timepoint?:  {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      StudyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      BatchId?: string | null,
      Batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      metadataImmunopeptidomicsList?:  {
        __typename: "ModelMetadataImmunopeptidomicsConnection",
        nextToken?: string | null,
      } | null,
      MetadataNanoStringList?:  {
        __typename: "ModelMetadataNanoStringConnection",
        nextToken?: string | null,
      } | null,
      MetadataRnaSeqList?:  {
        __typename: "ModelMetadataRnaSeqConnection",
        nextToken?: string | null,
      } | null,
      AuxiliaryAnalysislList?:  {
        __typename: "ModelAuxiliaryAnalysisConnection",
        nextToken?: string | null,
      } | null,
      domainSampleId: string,
      experimentSpecificVariables?: string | null,
      collectionDate?: string | null,
      processingDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseSamplesId?: string | null,
      timepointSamplesId?: string | null,
      batchSamplesId?: string | null,
    } | null,
    treatment?:  {
      __typename: "ModelTreatmentConnection",
      items:  Array< {
        __typename: "Treatment",
        id: string,
        metadataImmunopeptidomicsId?: string | null,
        metadataNanoStringId?: string | null,
        metadataRnaSeqId?: string | null,
        treatmentName: string,
        dose: number,
        doseUnit: string,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        metadataImmunopeptidomicsTreatmentId?: string | null,
        metadataNanoStringTreatmentId?: string | null,
        metadataRnaSeqTreatmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    metaDataTemplateS3Path: string,
    subtypeOfAnalysis?: string | null,
    species: string,
    sampleType: string,
    qcStatus: QcStatus,
    glacierBackup: boolean,
    rawDataS3: boolean,
    tumorVolume?: number | null,
    sequenceType: SequenceType,
    strandSpecific: string,
    UMI: boolean,
    adaptorsRemoved: boolean,
    adaptorsS3Path?: string | null,
    indexSequences?: Array< string | null > | null,
    concNanoGramPerMicroL?: number | null,
    rnaMassNanoGram?: number | null,
    rnaVolumeMicroL?: number | null,
    rin?: number | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    isControl: boolean,
    barcodesMappedToControl?: Array< string | null > | null,
    barcode: string,
    s3Path: Array< string | null >,
    sampleMetadataRnaSeqListId?: string | null,
  } | null,
};

export type ListMetadataRnaSeqsQueryVariables = {
  id?: string | null,
  filter?: ModelMetadataRnaSeqFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListMetadataRnaSeqsQuery = {
  listMetadataRnaSeqs?:  {
    __typename: "ModelMetadataRnaSeqConnection",
    items:  Array< {
      __typename: "MetadataRnaSeq",
      id: string,
      sampleId: string,
      sample?:  {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null,
      treatment?:  {
        __typename: "ModelTreatmentConnection",
        nextToken?: string | null,
      } | null,
      metaDataTemplateS3Path: string,
      subtypeOfAnalysis?: string | null,
      species: string,
      sampleType: string,
      qcStatus: QcStatus,
      glacierBackup: boolean,
      rawDataS3: boolean,
      tumorVolume?: number | null,
      sequenceType: SequenceType,
      strandSpecific: string,
      UMI: boolean,
      adaptorsRemoved: boolean,
      adaptorsS3Path?: string | null,
      indexSequences?: Array< string | null > | null,
      concNanoGramPerMicroL?: number | null,
      rnaMassNanoGram?: number | null,
      rnaVolumeMicroL?: number | null,
      rin?: number | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      isControl: boolean,
      barcodesMappedToControl?: Array< string | null > | null,
      barcode: string,
      s3Path: Array< string | null >,
      sampleMetadataRnaSeqListId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTreatmentQueryVariables = {
  id: string,
};

export type GetTreatmentQuery = {
  getTreatment?:  {
    __typename: "Treatment",
    id: string,
    metadataImmunopeptidomicsId?: string | null,
    metadataImmunopeptidomics?:  {
      __typename: "MetadataImmunopeptidomics",
      id: string,
      sampleId: string,
      sample?:  {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null,
      treatment?:  {
        __typename: "ModelTreatmentConnection",
        nextToken?: string | null,
      } | null,
      metaDataTemplateS3Path: string,
      species: string,
      subtypeOfAnalysis?: string | null,
      sampleType: string,
      qcStatus: QcStatus,
      glacierBackup: boolean,
      rawDataS3: boolean,
      tumorVolume?: number | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      isControl: boolean,
      barcodesMappedToControl?: Array< string | null > | null,
      barcode: string,
      s3Path: Array< string | null >,
      sampleMetadataImmunopeptidomicsListId?: string | null,
    } | null,
    metadataNanoStringId?: string | null,
    metadataNanoString?:  {
      __typename: "MetadataNanoString",
      id: string,
      sampleId: string,
      sample?:  {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null,
      treatment?:  {
        __typename: "ModelTreatmentConnection",
        nextToken?: string | null,
      } | null,
      metaDataTemplateS3Path: string,
      subtypeOfAnalysis?: string | null,
      species: string,
      sampleType: string,
      qcStatus: QcStatus,
      glacierBackup: boolean,
      rawDataS3: boolean,
      tumorVolume?: number | null,
      dv200Percentage?: number | null,
      concNanoGramPerMicroL?: number | null,
      rnaMassNanoGram?: number | null,
      rnaVolumeMicroL?: number | null,
      rin?: number | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      isControl: boolean,
      barcodesMappedToControl?: Array< string | null > | null,
      barcode: string,
      s3Path: Array< string | null >,
      sampleMetadataNanoStringListId?: string | null,
    } | null,
    metadataRnaSeqId?: string | null,
    metadataRnaSeq?:  {
      __typename: "MetadataRnaSeq",
      id: string,
      sampleId: string,
      sample?:  {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null,
      treatment?:  {
        __typename: "ModelTreatmentConnection",
        nextToken?: string | null,
      } | null,
      metaDataTemplateS3Path: string,
      subtypeOfAnalysis?: string | null,
      species: string,
      sampleType: string,
      qcStatus: QcStatus,
      glacierBackup: boolean,
      rawDataS3: boolean,
      tumorVolume?: number | null,
      sequenceType: SequenceType,
      strandSpecific: string,
      UMI: boolean,
      adaptorsRemoved: boolean,
      adaptorsS3Path?: string | null,
      indexSequences?: Array< string | null > | null,
      concNanoGramPerMicroL?: number | null,
      rnaMassNanoGram?: number | null,
      rnaVolumeMicroL?: number | null,
      rin?: number | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      isControl: boolean,
      barcodesMappedToControl?: Array< string | null > | null,
      barcode: string,
      s3Path: Array< string | null >,
      sampleMetadataRnaSeqListId?: string | null,
    } | null,
    treatmentName: string,
    dose: number,
    doseUnit: string,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    metadataImmunopeptidomicsTreatmentId?: string | null,
    metadataNanoStringTreatmentId?: string | null,
    metadataRnaSeqTreatmentId?: string | null,
  } | null,
};

export type ListTreatmentsQueryVariables = {
  id?: string | null,
  filter?: ModelTreatmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTreatmentsQuery = {
  listTreatments?:  {
    __typename: "ModelTreatmentConnection",
    items:  Array< {
      __typename: "Treatment",
      id: string,
      metadataImmunopeptidomicsId?: string | null,
      metadataImmunopeptidomics?:  {
        __typename: "MetadataImmunopeptidomics",
        id: string,
        sampleId: string,
        metaDataTemplateS3Path: string,
        species: string,
        subtypeOfAnalysis?: string | null,
        sampleType: string,
        qcStatus: QcStatus,
        glacierBackup: boolean,
        rawDataS3: boolean,
        tumorVolume?: number | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        isControl: boolean,
        barcodesMappedToControl?: Array< string | null > | null,
        barcode: string,
        s3Path: Array< string | null >,
        sampleMetadataImmunopeptidomicsListId?: string | null,
      } | null,
      metadataNanoStringId?: string | null,
      metadataNanoString?:  {
        __typename: "MetadataNanoString",
        id: string,
        sampleId: string,
        metaDataTemplateS3Path: string,
        subtypeOfAnalysis?: string | null,
        species: string,
        sampleType: string,
        qcStatus: QcStatus,
        glacierBackup: boolean,
        rawDataS3: boolean,
        tumorVolume?: number | null,
        dv200Percentage?: number | null,
        concNanoGramPerMicroL?: number | null,
        rnaMassNanoGram?: number | null,
        rnaVolumeMicroL?: number | null,
        rin?: number | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        isControl: boolean,
        barcodesMappedToControl?: Array< string | null > | null,
        barcode: string,
        s3Path: Array< string | null >,
        sampleMetadataNanoStringListId?: string | null,
      } | null,
      metadataRnaSeqId?: string | null,
      metadataRnaSeq?:  {
        __typename: "MetadataRnaSeq",
        id: string,
        sampleId: string,
        metaDataTemplateS3Path: string,
        subtypeOfAnalysis?: string | null,
        species: string,
        sampleType: string,
        qcStatus: QcStatus,
        glacierBackup: boolean,
        rawDataS3: boolean,
        tumorVolume?: number | null,
        sequenceType: SequenceType,
        strandSpecific: string,
        UMI: boolean,
        adaptorsRemoved: boolean,
        adaptorsS3Path?: string | null,
        indexSequences?: Array< string | null > | null,
        concNanoGramPerMicroL?: number | null,
        rnaMassNanoGram?: number | null,
        rnaVolumeMicroL?: number | null,
        rin?: number | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        isControl: boolean,
        barcodesMappedToControl?: Array< string | null > | null,
        barcode: string,
        s3Path: Array< string | null >,
        sampleMetadataRnaSeqListId?: string | null,
      } | null,
      treatmentName: string,
      dose: number,
      doseUnit: string,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      metadataImmunopeptidomicsTreatmentId?: string | null,
      metadataNanoStringTreatmentId?: string | null,
      metadataRnaSeqTreatmentId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLaunchablePipelinesQueryVariables = {
  id: string,
};

export type GetLaunchablePipelinesQuery = {
  getLaunchablePipelines?:  {
    __typename: "LaunchablePipelines",
    id: string,
    pipelineName?: string | null,
    organizations?:  {
      __typename: "ModelOrganizationLaunchablePipelinesConnection",
      items:  Array< {
        __typename: "OrganizationLaunchablePipelines",
        id: string,
        organizationId: string,
        launchablePipelinesId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pipelineVersion?: string | null,
    parameters?: string | null,
    description?: string | null,
    inputFileInfo?: string | null,
    supportedAnalyses?: Array< AnalysisType | null > | null,
    supportedSubtypeOfAnalyses?: Array< string | null > | null,
    pipelines?:  {
      __typename: "ModelPipelineConnection",
      items:  Array< {
        __typename: "Pipeline",
        id: string,
        name: string,
        status?: pipelineStatus | null,
        description?: string | null,
        s3Event?: string | null,
        s3ParamsFile?: string | null,
        s3Results?: string | null,
        visFileInfoS3path?: string | null,
        s3NfLog?: string | null,
        s3NfTasksLogs?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis?: string | null,
        parameters: string,
        studyId?: string | null,
        studyPhaseId?: string | null,
        scheduledPipeline: boolean,
        scheduledDateTime?: string | null,
        launchablePipelineId: string,
        batchId: string,
        runId?: string | null,
        userEmail: string,
        pipelineStarted?: string | null,
        pipelineCompleted?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPipelinesId?: string | null,
        studyPhasePipelinesId?: string | null,
        batchPipelinesId?: string | null,
        launchablePipelinesPipelinesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    gitRepo?: string | null,
    workflowId?: string | null,
    created: string,
    updated: string,
  } | null,
};

export type ListLaunchablePipelinesQueryVariables = {
  id?: string | null,
  filter?: ModelLaunchablePipelinesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListLaunchablePipelinesQuery = {
  listLaunchablePipelines?:  {
    __typename: "ModelLaunchablePipelinesConnection",
    items:  Array< {
      __typename: "LaunchablePipelines",
      id: string,
      pipelineName?: string | null,
      organizations?:  {
        __typename: "ModelOrganizationLaunchablePipelinesConnection",
        nextToken?: string | null,
      } | null,
      pipelineVersion?: string | null,
      parameters?: string | null,
      description?: string | null,
      inputFileInfo?: string | null,
      supportedAnalyses?: Array< AnalysisType | null > | null,
      supportedSubtypeOfAnalyses?: Array< string | null > | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      gitRepo?: string | null,
      workflowId?: string | null,
      created: string,
      updated: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    email?: string | null,
    organization?: string | null,
    gitToken?: string | null,
    gitName?: string | null,
    gitURL?: string | null,
    readGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    owner?: string | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      email?: string | null,
      organization?: string | null,
      gitToken?: string | null,
      gitName?: string | null,
      gitURL?: string | null,
      readGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUploadLogQueryVariables = {
  id: string,
};

export type GetUploadLogQuery = {
  getUploadLog?:  {
    __typename: "UploadLog",
    id: string,
    uploadS3Path?: string | null,
    status?: string | null,
    readGroups?: Array< string | null > | null,
    created?: string | null,
    updated?: string | null,
  } | null,
};

export type ListUploadLogsQueryVariables = {
  id?: string | null,
  filter?: ModelUploadLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUploadLogsQuery = {
  listUploadLogs?:  {
    __typename: "ModelUploadLogConnection",
    items:  Array< {
      __typename: "UploadLog",
      id: string,
      uploadS3Path?: string | null,
      status?: string | null,
      readGroups?: Array< string | null > | null,
      created?: string | null,
      updated?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetVisualizationsQueryVariables = {
  id: string,
};

export type GetVisualizationsQuery = {
  getVisualizations?:  {
    __typename: "Visualizations",
    id: string,
    visualizationName?: string | null,
    description?: string | null,
    version?: string | null,
    deploymentPath?: string | null,
    validationSchema?: string | null,
    allowedExtensions?: Array< string | null > | null,
    supportedAnalyses?: Array< string | null > | null,
    created: string,
    updated: string,
  } | null,
};

export type ListVisualizationsQueryVariables = {
  id?: string | null,
  filter?: ModelVisualizationsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListVisualizationsQuery = {
  listVisualizations?:  {
    __typename: "ModelVisualizationsConnection",
    items:  Array< {
      __typename: "Visualizations",
      id: string,
      visualizationName?: string | null,
      description?: string | null,
      version?: string | null,
      deploymentPath?: string | null,
      validationSchema?: string | null,
      allowedExtensions?: Array< string | null > | null,
      supportedAnalyses?: Array< string | null > | null,
      created: string,
      updated: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetVisualizationSessionQueryVariables = {
  id: string,
};

export type GetVisualizationSessionQuery = {
  getVisualizationSession?:  {
    __typename: "VisualizationSession",
    id: string,
    statuses?: string | null,
    data?: string | null,
    description?: string | null,
    created: string,
    updated: string,
    owner?: string | null,
  } | null,
};

export type ListVisualizationSessionsQueryVariables = {
  filter?: ModelVisualizationSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVisualizationSessionsQuery = {
  listVisualizationSessions?:  {
    __typename: "ModelVisualizationSessionConnection",
    items:  Array< {
      __typename: "VisualizationSession",
      id: string,
      statuses?: string | null,
      data?: string | null,
      description?: string | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDataLabEC2InstancesQueryVariables = {
  id: string,
};

export type GetDataLabEC2InstancesQuery = {
  getDataLabEC2Instances?:  {
    __typename: "DataLabEC2Instances",
    id: string,
    creationTimestamp?: string | null,
    stoppedTimestamp?: string | null,
    startTimestamp?: string | null,
    terminatedTimestamp?: string | null,
    stoppedTimePeriod?: number | null,
    runningTimePeriod?: number | null,
    userEmail: string,
    status?: DataLabEC2InstancesStatus | null,
    type?: DataLabEC2InstancesType | null,
    dataLabInstanceSessions?:  {
      __typename: "ModelDataLabEC2InstanceSessionConnection",
      items:  Array< {
        __typename: "DataLabEC2InstanceSession",
        id: string,
        dataLabInstanceId: string,
        s3Path?: string | null,
        instanceLaunchEvent?: string | null,
        status?: DataLabEC2InstanceSessionStatus | null,
        title?: string | null,
        description?: string | null,
        created: string,
        updated: string,
        dataLabEC2InstancesDataLabInstanceSessionsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    dataLabInstanceStatusChanges?:  {
      __typename: "ModelDataLabEC2InstanceStatusChangeConnection",
      items:  Array< {
        __typename: "DataLabEC2InstanceStatusChange",
        id: string,
        dataLabInstanceId: string,
        organizationId?: string | null,
        previousStatus?: DataLabEC2InstancesStatus | null,
        newStatus?: DataLabEC2InstancesStatus | null,
        initiatedBy?: string | null,
        action?: string | null,
        performedBy?: string | null,
        additionalNotes?: string | null,
        created: string,
        updated: string,
        dataLabEC2InstancesDataLabInstanceStatusChangesId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    organizationId?: string | null,
    organizationName?: string | null,
    instanceUrl?: string | null,
    instanceId?: string | null,
    infrastructureRelatedEntities?: string | null,
    instanceLaunchEvent?: string | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    owner?: string | null,
  } | null,
};

export type ListDataLabEC2InstancesQueryVariables = {
  filter?: ModelDataLabEC2InstancesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDataLabEC2InstancesQuery = {
  listDataLabEC2Instances?:  {
    __typename: "ModelDataLabEC2InstancesConnection",
    items:  Array< {
      __typename: "DataLabEC2Instances",
      id: string,
      creationTimestamp?: string | null,
      stoppedTimestamp?: string | null,
      startTimestamp?: string | null,
      terminatedTimestamp?: string | null,
      stoppedTimePeriod?: number | null,
      runningTimePeriod?: number | null,
      userEmail: string,
      status?: DataLabEC2InstancesStatus | null,
      type?: DataLabEC2InstancesType | null,
      dataLabInstanceSessions?:  {
        __typename: "ModelDataLabEC2InstanceSessionConnection",
        nextToken?: string | null,
      } | null,
      dataLabInstanceStatusChanges?:  {
        __typename: "ModelDataLabEC2InstanceStatusChangeConnection",
        nextToken?: string | null,
      } | null,
      organizationId?: string | null,
      organizationName?: string | null,
      instanceUrl?: string | null,
      instanceId?: string | null,
      infrastructureRelatedEntities?: string | null,
      instanceLaunchEvent?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDataLabEC2InstanceSessionQueryVariables = {
  id: string,
};

export type GetDataLabEC2InstanceSessionQuery = {
  getDataLabEC2InstanceSession?:  {
    __typename: "DataLabEC2InstanceSession",
    id: string,
    dataLabInstanceId: string,
    dataLabInstance?:  {
      __typename: "DataLabEC2Instances",
      id: string,
      creationTimestamp?: string | null,
      stoppedTimestamp?: string | null,
      startTimestamp?: string | null,
      terminatedTimestamp?: string | null,
      stoppedTimePeriod?: number | null,
      runningTimePeriod?: number | null,
      userEmail: string,
      status?: DataLabEC2InstancesStatus | null,
      type?: DataLabEC2InstancesType | null,
      dataLabInstanceSessions?:  {
        __typename: "ModelDataLabEC2InstanceSessionConnection",
        nextToken?: string | null,
      } | null,
      dataLabInstanceStatusChanges?:  {
        __typename: "ModelDataLabEC2InstanceStatusChangeConnection",
        nextToken?: string | null,
      } | null,
      organizationId?: string | null,
      organizationName?: string | null,
      instanceUrl?: string | null,
      instanceId?: string | null,
      infrastructureRelatedEntities?: string | null,
      instanceLaunchEvent?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null,
    s3Path?: string | null,
    instanceLaunchEvent?: string | null,
    status?: DataLabEC2InstanceSessionStatus | null,
    title?: string | null,
    description?: string | null,
    created: string,
    updated: string,
    dataLabEC2InstancesDataLabInstanceSessionsId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListDataLabEC2InstanceSessionsQueryVariables = {
  filter?: ModelDataLabEC2InstanceSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDataLabEC2InstanceSessionsQuery = {
  listDataLabEC2InstanceSessions?:  {
    __typename: "ModelDataLabEC2InstanceSessionConnection",
    items:  Array< {
      __typename: "DataLabEC2InstanceSession",
      id: string,
      dataLabInstanceId: string,
      dataLabInstance?:  {
        __typename: "DataLabEC2Instances",
        id: string,
        creationTimestamp?: string | null,
        stoppedTimestamp?: string | null,
        startTimestamp?: string | null,
        terminatedTimestamp?: string | null,
        stoppedTimePeriod?: number | null,
        runningTimePeriod?: number | null,
        userEmail: string,
        status?: DataLabEC2InstancesStatus | null,
        type?: DataLabEC2InstancesType | null,
        organizationId?: string | null,
        organizationName?: string | null,
        instanceUrl?: string | null,
        instanceId?: string | null,
        infrastructureRelatedEntities?: string | null,
        instanceLaunchEvent?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        owner?: string | null,
      } | null,
      s3Path?: string | null,
      instanceLaunchEvent?: string | null,
      status?: DataLabEC2InstanceSessionStatus | null,
      title?: string | null,
      description?: string | null,
      created: string,
      updated: string,
      dataLabEC2InstancesDataLabInstanceSessionsId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDataLabEC2InstanceStatusChangeQueryVariables = {
  id: string,
};

export type GetDataLabEC2InstanceStatusChangeQuery = {
  getDataLabEC2InstanceStatusChange?:  {
    __typename: "DataLabEC2InstanceStatusChange",
    id: string,
    dataLabInstanceId: string,
    dataLabInstance?:  {
      __typename: "DataLabEC2Instances",
      id: string,
      creationTimestamp?: string | null,
      stoppedTimestamp?: string | null,
      startTimestamp?: string | null,
      terminatedTimestamp?: string | null,
      stoppedTimePeriod?: number | null,
      runningTimePeriod?: number | null,
      userEmail: string,
      status?: DataLabEC2InstancesStatus | null,
      type?: DataLabEC2InstancesType | null,
      dataLabInstanceSessions?:  {
        __typename: "ModelDataLabEC2InstanceSessionConnection",
        nextToken?: string | null,
      } | null,
      dataLabInstanceStatusChanges?:  {
        __typename: "ModelDataLabEC2InstanceStatusChangeConnection",
        nextToken?: string | null,
      } | null,
      organizationId?: string | null,
      organizationName?: string | null,
      instanceUrl?: string | null,
      instanceId?: string | null,
      infrastructureRelatedEntities?: string | null,
      instanceLaunchEvent?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null,
    organizationId?: string | null,
    previousStatus?: DataLabEC2InstancesStatus | null,
    newStatus?: DataLabEC2InstancesStatus | null,
    initiatedBy?: string | null,
    action?: string | null,
    performedBy?: string | null,
    additionalNotes?: string | null,
    created: string,
    updated: string,
    dataLabEC2InstancesDataLabInstanceStatusChangesId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListDataLabEC2InstanceStatusChangesQueryVariables = {
  filter?: ModelDataLabEC2InstanceStatusChangeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDataLabEC2InstanceStatusChangesQuery = {
  listDataLabEC2InstanceStatusChanges?:  {
    __typename: "ModelDataLabEC2InstanceStatusChangeConnection",
    items:  Array< {
      __typename: "DataLabEC2InstanceStatusChange",
      id: string,
      dataLabInstanceId: string,
      dataLabInstance?:  {
        __typename: "DataLabEC2Instances",
        id: string,
        creationTimestamp?: string | null,
        stoppedTimestamp?: string | null,
        startTimestamp?: string | null,
        terminatedTimestamp?: string | null,
        stoppedTimePeriod?: number | null,
        runningTimePeriod?: number | null,
        userEmail: string,
        status?: DataLabEC2InstancesStatus | null,
        type?: DataLabEC2InstancesType | null,
        organizationId?: string | null,
        organizationName?: string | null,
        instanceUrl?: string | null,
        instanceId?: string | null,
        infrastructureRelatedEntities?: string | null,
        instanceLaunchEvent?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        owner?: string | null,
      } | null,
      organizationId?: string | null,
      previousStatus?: DataLabEC2InstancesStatus | null,
      newStatus?: DataLabEC2InstancesStatus | null,
      initiatedBy?: string | null,
      action?: string | null,
      performedBy?: string | null,
      additionalNotes?: string | null,
      created: string,
      updated: string,
      dataLabEC2InstancesDataLabInstanceStatusChangesId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUploadBundleQueryVariables = {
  id: string,
};

export type GetUploadBundleQuery = {
  getUploadBundle?:  {
    __typename: "UploadBundle",
    id: string,
    studyPhaseId?: string | null,
    studyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    bundleLog?: string | null,
    validationErrors?: string | null,
    status?: string | null,
    userEmail?: string | null,
    uploadBundleAnalysisSession?:  {
      __typename: "UploadBundleAnalysisSession",
      id: string,
      uploadSampleAnalysisSession?:  {
        __typename: "ModelUploadSampleAnalysisSessionConnection",
        nextToken?: string | null,
      } | null,
      metadataFileS3Path?: string | null,
      step?: string | null,
      listOfRawFileNames?: Array< string | null > | null,
      analysisType: AnalysisType,
      subtypeOfAnalysis: string,
      studyPhaseId: string,
      metadataHelperLambdaResponseS3Path?: string | null,
      barcodeToFilesToUploadS3Path?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null,
    uploadSamples?:  {
      __typename: "ModelUploadSampleConnection",
      items:  Array< {
        __typename: "UploadSample",
        id: string,
        uploadBundleId: string,
        sampleId: string,
        barcode: string,
        sampleLog?: string | null,
        writeMetadata: boolean,
        status?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        uploadBundleUploadSamplesId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    uploadFiles?:  {
      __typename: "ModelUploadFileConnection",
      items:  Array< {
        __typename: "UploadFile",
        id: string,
        uploadSampleId?: string | null,
        uploadBundleId?: string | null,
        multipartUploadId?: string | null,
        status?: UploadFileStatus | null,
        toSkipUpload: boolean,
        fileName: string,
        fileType: string,
        s3Path: string,
        size?: number | null,
        fileLog?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        uploadBundleUploadFilesId?: string | null,
        uploadSampleUploadFilesId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    adminGroups?: Array< string | null > | null,
    completionTime?: string | null,
    created: string,
    updated: string,
    studyPhaseUploadBundlesId?: string | null,
    uploadBundleUploadBundleAnalysisSessionId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListUploadBundlesQueryVariables = {
  filter?: ModelUploadBundleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUploadBundlesQuery = {
  listUploadBundles?:  {
    __typename: "ModelUploadBundleConnection",
    items:  Array< {
      __typename: "UploadBundle",
      id: string,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      bundleLog?: string | null,
      validationErrors?: string | null,
      status?: string | null,
      userEmail?: string | null,
      uploadBundleAnalysisSession?:  {
        __typename: "UploadBundleAnalysisSession",
        id: string,
        metadataFileS3Path?: string | null,
        step?: string | null,
        listOfRawFileNames?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis: string,
        studyPhaseId: string,
        metadataHelperLambdaResponseS3Path?: string | null,
        barcodeToFilesToUploadS3Path?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        owner?: string | null,
      } | null,
      uploadSamples?:  {
        __typename: "ModelUploadSampleConnection",
        nextToken?: string | null,
      } | null,
      uploadFiles?:  {
        __typename: "ModelUploadFileConnection",
        nextToken?: string | null,
      } | null,
      adminGroups?: Array< string | null > | null,
      completionTime?: string | null,
      created: string,
      updated: string,
      studyPhaseUploadBundlesId?: string | null,
      uploadBundleUploadBundleAnalysisSessionId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUploadBundleAnalysisSessionQueryVariables = {
  id: string,
};

export type GetUploadBundleAnalysisSessionQuery = {
  getUploadBundleAnalysisSession?:  {
    __typename: "UploadBundleAnalysisSession",
    id: string,
    uploadSampleAnalysisSession?:  {
      __typename: "ModelUploadSampleAnalysisSessionConnection",
      items:  Array< {
        __typename: "UploadSampleAnalysisSession",
        id: string,
        uploadBundleAnalysisSessionId: string,
        sampleId: string,
        rowFromMetaDataFileMappedToSample: string,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        uploadBundleAnalysisSessionUploadSampleAnalysisSessionId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    metadataFileS3Path?: string | null,
    step?: string | null,
    listOfRawFileNames?: Array< string | null > | null,
    analysisType: AnalysisType,
    subtypeOfAnalysis: string,
    studyPhaseId: string,
    metadataHelperLambdaResponseS3Path?: string | null,
    barcodeToFilesToUploadS3Path?: string | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    owner?: string | null,
  } | null,
};

export type ListUploadBundleAnalysisSessionsQueryVariables = {
  filter?: ModelUploadBundleAnalysisSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUploadBundleAnalysisSessionsQuery = {
  listUploadBundleAnalysisSessions?:  {
    __typename: "ModelUploadBundleAnalysisSessionConnection",
    items:  Array< {
      __typename: "UploadBundleAnalysisSession",
      id: string,
      uploadSampleAnalysisSession?:  {
        __typename: "ModelUploadSampleAnalysisSessionConnection",
        nextToken?: string | null,
      } | null,
      metadataFileS3Path?: string | null,
      step?: string | null,
      listOfRawFileNames?: Array< string | null > | null,
      analysisType: AnalysisType,
      subtypeOfAnalysis: string,
      studyPhaseId: string,
      metadataHelperLambdaResponseS3Path?: string | null,
      barcodeToFilesToUploadS3Path?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUploadSampleQueryVariables = {
  id: string,
};

export type GetUploadSampleQuery = {
  getUploadSample?:  {
    __typename: "UploadSample",
    id: string,
    uploadBundleId: string,
    uploadBundle?:  {
      __typename: "UploadBundle",
      id: string,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      bundleLog?: string | null,
      validationErrors?: string | null,
      status?: string | null,
      userEmail?: string | null,
      uploadBundleAnalysisSession?:  {
        __typename: "UploadBundleAnalysisSession",
        id: string,
        metadataFileS3Path?: string | null,
        step?: string | null,
        listOfRawFileNames?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis: string,
        studyPhaseId: string,
        metadataHelperLambdaResponseS3Path?: string | null,
        barcodeToFilesToUploadS3Path?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        owner?: string | null,
      } | null,
      uploadSamples?:  {
        __typename: "ModelUploadSampleConnection",
        nextToken?: string | null,
      } | null,
      uploadFiles?:  {
        __typename: "ModelUploadFileConnection",
        nextToken?: string | null,
      } | null,
      adminGroups?: Array< string | null > | null,
      completionTime?: string | null,
      created: string,
      updated: string,
      studyPhaseUploadBundlesId?: string | null,
      uploadBundleUploadBundleAnalysisSessionId?: string | null,
      owner?: string | null,
    } | null,
    sampleId: string,
    barcode: string,
    sampleLog?: string | null,
    writeMetadata: boolean,
    status?: string | null,
    uploadFiles?:  {
      __typename: "ModelUploadFileConnection",
      items:  Array< {
        __typename: "UploadFile",
        id: string,
        uploadSampleId?: string | null,
        uploadBundleId?: string | null,
        multipartUploadId?: string | null,
        status?: UploadFileStatus | null,
        toSkipUpload: boolean,
        fileName: string,
        fileType: string,
        s3Path: string,
        size?: number | null,
        fileLog?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        uploadBundleUploadFilesId?: string | null,
        uploadSampleUploadFilesId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    uploadBundleUploadSamplesId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListUploadSamplesQueryVariables = {
  filter?: ModelUploadSampleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUploadSamplesQuery = {
  listUploadSamples?:  {
    __typename: "ModelUploadSampleConnection",
    items:  Array< {
      __typename: "UploadSample",
      id: string,
      uploadBundleId: string,
      uploadBundle?:  {
        __typename: "UploadBundle",
        id: string,
        studyPhaseId?: string | null,
        bundleLog?: string | null,
        validationErrors?: string | null,
        status?: string | null,
        userEmail?: string | null,
        adminGroups?: Array< string | null > | null,
        completionTime?: string | null,
        created: string,
        updated: string,
        studyPhaseUploadBundlesId?: string | null,
        uploadBundleUploadBundleAnalysisSessionId?: string | null,
        owner?: string | null,
      } | null,
      sampleId: string,
      barcode: string,
      sampleLog?: string | null,
      writeMetadata: boolean,
      status?: string | null,
      uploadFiles?:  {
        __typename: "ModelUploadFileConnection",
        nextToken?: string | null,
      } | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      uploadBundleUploadSamplesId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUploadSampleAnalysisSessionQueryVariables = {
  id: string,
};

export type GetUploadSampleAnalysisSessionQuery = {
  getUploadSampleAnalysisSession?:  {
    __typename: "UploadSampleAnalysisSession",
    id: string,
    uploadBundleAnalysisSessionId: string,
    uploadBundleAnalysisSession?:  {
      __typename: "UploadBundleAnalysisSession",
      id: string,
      uploadSampleAnalysisSession?:  {
        __typename: "ModelUploadSampleAnalysisSessionConnection",
        nextToken?: string | null,
      } | null,
      metadataFileS3Path?: string | null,
      step?: string | null,
      listOfRawFileNames?: Array< string | null > | null,
      analysisType: AnalysisType,
      subtypeOfAnalysis: string,
      studyPhaseId: string,
      metadataHelperLambdaResponseS3Path?: string | null,
      barcodeToFilesToUploadS3Path?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null,
    sampleId: string,
    rowFromMetaDataFileMappedToSample: string,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    uploadBundleAnalysisSessionUploadSampleAnalysisSessionId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListUploadSampleAnalysisSessionsQueryVariables = {
  filter?: ModelUploadSampleAnalysisSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUploadSampleAnalysisSessionsQuery = {
  listUploadSampleAnalysisSessions?:  {
    __typename: "ModelUploadSampleAnalysisSessionConnection",
    items:  Array< {
      __typename: "UploadSampleAnalysisSession",
      id: string,
      uploadBundleAnalysisSessionId: string,
      uploadBundleAnalysisSession?:  {
        __typename: "UploadBundleAnalysisSession",
        id: string,
        metadataFileS3Path?: string | null,
        step?: string | null,
        listOfRawFileNames?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis: string,
        studyPhaseId: string,
        metadataHelperLambdaResponseS3Path?: string | null,
        barcodeToFilesToUploadS3Path?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        owner?: string | null,
      } | null,
      sampleId: string,
      rowFromMetaDataFileMappedToSample: string,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      uploadBundleAnalysisSessionUploadSampleAnalysisSessionId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUploadFileQueryVariables = {
  id: string,
};

export type GetUploadFileQuery = {
  getUploadFile?:  {
    __typename: "UploadFile",
    id: string,
    uploadSampleId?: string | null,
    uploadSample?:  {
      __typename: "UploadSample",
      id: string,
      uploadBundleId: string,
      uploadBundle?:  {
        __typename: "UploadBundle",
        id: string,
        studyPhaseId?: string | null,
        bundleLog?: string | null,
        validationErrors?: string | null,
        status?: string | null,
        userEmail?: string | null,
        adminGroups?: Array< string | null > | null,
        completionTime?: string | null,
        created: string,
        updated: string,
        studyPhaseUploadBundlesId?: string | null,
        uploadBundleUploadBundleAnalysisSessionId?: string | null,
        owner?: string | null,
      } | null,
      sampleId: string,
      barcode: string,
      sampleLog?: string | null,
      writeMetadata: boolean,
      status?: string | null,
      uploadFiles?:  {
        __typename: "ModelUploadFileConnection",
        nextToken?: string | null,
      } | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      uploadBundleUploadSamplesId?: string | null,
      owner?: string | null,
    } | null,
    uploadBundleId?: string | null,
    uploadBundle?:  {
      __typename: "UploadBundle",
      id: string,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      bundleLog?: string | null,
      validationErrors?: string | null,
      status?: string | null,
      userEmail?: string | null,
      uploadBundleAnalysisSession?:  {
        __typename: "UploadBundleAnalysisSession",
        id: string,
        metadataFileS3Path?: string | null,
        step?: string | null,
        listOfRawFileNames?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis: string,
        studyPhaseId: string,
        metadataHelperLambdaResponseS3Path?: string | null,
        barcodeToFilesToUploadS3Path?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        owner?: string | null,
      } | null,
      uploadSamples?:  {
        __typename: "ModelUploadSampleConnection",
        nextToken?: string | null,
      } | null,
      uploadFiles?:  {
        __typename: "ModelUploadFileConnection",
        nextToken?: string | null,
      } | null,
      adminGroups?: Array< string | null > | null,
      completionTime?: string | null,
      created: string,
      updated: string,
      studyPhaseUploadBundlesId?: string | null,
      uploadBundleUploadBundleAnalysisSessionId?: string | null,
      owner?: string | null,
    } | null,
    multipartUploadId?: string | null,
    status?: UploadFileStatus | null,
    toSkipUpload: boolean,
    fileName: string,
    fileType: string,
    s3Path: string,
    size?: number | null,
    fileLog?: string | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    uploadBundleUploadFilesId?: string | null,
    uploadSampleUploadFilesId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListUploadFilesQueryVariables = {
  filter?: ModelUploadFileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUploadFilesQuery = {
  listUploadFiles?:  {
    __typename: "ModelUploadFileConnection",
    items:  Array< {
      __typename: "UploadFile",
      id: string,
      uploadSampleId?: string | null,
      uploadSample?:  {
        __typename: "UploadSample",
        id: string,
        uploadBundleId: string,
        sampleId: string,
        barcode: string,
        sampleLog?: string | null,
        writeMetadata: boolean,
        status?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        uploadBundleUploadSamplesId?: string | null,
        owner?: string | null,
      } | null,
      uploadBundleId?: string | null,
      uploadBundle?:  {
        __typename: "UploadBundle",
        id: string,
        studyPhaseId?: string | null,
        bundleLog?: string | null,
        validationErrors?: string | null,
        status?: string | null,
        userEmail?: string | null,
        adminGroups?: Array< string | null > | null,
        completionTime?: string | null,
        created: string,
        updated: string,
        studyPhaseUploadBundlesId?: string | null,
        uploadBundleUploadBundleAnalysisSessionId?: string | null,
        owner?: string | null,
      } | null,
      multipartUploadId?: string | null,
      status?: UploadFileStatus | null,
      toSkipUpload: boolean,
      fileName: string,
      fileType: string,
      s3Path: string,
      size?: number | null,
      fileLog?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      uploadBundleUploadFilesId?: string | null,
      uploadSampleUploadFilesId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrganizationLaunchablePipelinesQueryVariables = {
  id: string,
};

export type GetOrganizationLaunchablePipelinesQuery = {
  getOrganizationLaunchablePipelines?:  {
    __typename: "OrganizationLaunchablePipelines",
    id: string,
    organizationId: string,
    launchablePipelinesId: string,
    organization:  {
      __typename: "Organization",
      id: string,
      organizationName: string,
      organizationCode?: string | null,
      active: boolean,
      studies?:  {
        __typename: "ModelStudyConnection",
        nextToken?: string | null,
      } | null,
      launchablePipelines?:  {
        __typename: "ModelOrganizationLaunchablePipelinesConnection",
        nextToken?: string | null,
      } | null,
      notificationEmail: string,
      description: string,
      organizationAnswers?: string | null,
      url: string,
      s3Path?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      created: string,
      updated: string,
    },
    launchablePipelines:  {
      __typename: "LaunchablePipelines",
      id: string,
      pipelineName?: string | null,
      organizations?:  {
        __typename: "ModelOrganizationLaunchablePipelinesConnection",
        nextToken?: string | null,
      } | null,
      pipelineVersion?: string | null,
      parameters?: string | null,
      description?: string | null,
      inputFileInfo?: string | null,
      supportedAnalyses?: Array< AnalysisType | null > | null,
      supportedSubtypeOfAnalyses?: Array< string | null > | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      gitRepo?: string | null,
      workflowId?: string | null,
      created: string,
      updated: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListOrganizationLaunchablePipelinesQueryVariables = {
  filter?: ModelOrganizationLaunchablePipelinesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrganizationLaunchablePipelinesQuery = {
  listOrganizationLaunchablePipelines?:  {
    __typename: "ModelOrganizationLaunchablePipelinesConnection",
    items:  Array< {
      __typename: "OrganizationLaunchablePipelines",
      id: string,
      organizationId: string,
      launchablePipelinesId: string,
      organization:  {
        __typename: "Organization",
        id: string,
        organizationName: string,
        organizationCode?: string | null,
        active: boolean,
        notificationEmail: string,
        description: string,
        organizationAnswers?: string | null,
        url: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        created: string,
        updated: string,
      },
      launchablePipelines:  {
        __typename: "LaunchablePipelines",
        id: string,
        pipelineName?: string | null,
        pipelineVersion?: string | null,
        parameters?: string | null,
        description?: string | null,
        inputFileInfo?: string | null,
        supportedAnalyses?: Array< AnalysisType | null > | null,
        supportedSubtypeOfAnalyses?: Array< string | null > | null,
        gitRepo?: string | null,
        workflowId?: string | null,
        created: string,
        updated: string,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type StudiesByOrganizationQueryVariables = {
  organizationId: string,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStudyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type StudiesByOrganizationQuery = {
  studiesByOrganization?:  {
    __typename: "ModelStudyConnection",
    items:  Array< {
      __typename: "Study",
      id: string,
      studyName: string,
      organizationId: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        organizationName: string,
        organizationCode?: string | null,
        active: boolean,
        notificationEmail: string,
        description: string,
        organizationAnswers?: string | null,
        url: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        created: string,
        updated: string,
      } | null,
      studyPhases?:  {
        __typename: "ModelStudyPhaseConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      description: string,
      studyCode: string,
      studyAnswers?: string | null,
      s3Path?: string | null,
      croGroups?: Array< string | null > | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      organizationStudiesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type StudyPhasesByStudyQueryVariables = {
  studyId: string,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStudyPhaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type StudyPhasesByStudyQuery = {
  studyPhasesByStudy?:  {
    __typename: "ModelStudyPhaseConnection",
    items:  Array< {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type QuestionsByEntityTypeQueryVariables = {
  entityType: EntityType,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEntityQuestionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type QuestionsByEntityTypeQuery = {
  questionsByEntityType?:  {
    __typename: "ModelEntityQuestionsConnection",
    items:  Array< {
      __typename: "EntityQuestions",
      id: string,
      entityType?: EntityType | null,
      questions?: string | null,
      created: string,
      updated: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GroupsByStudyPhaseQueryVariables = {
  studyPhaseId: string,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GroupsByStudyPhaseQuery = {
  groupsByStudyPhase?:  {
    __typename: "ModelGroupConnection",
    items:  Array< {
      __typename: "Group",
      id: string,
      studyPhaseId: string,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      type?: string | null,
      groupName: string,
      s3Path?: string | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseGroupsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PatientsByGroupQueryVariables = {
  groupId: string,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPatientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PatientsByGroupQuery = {
  patientsByGroup?:  {
    __typename: "ModelPatientConnection",
    items:  Array< {
      __typename: "Patient",
      id: string,
      groupId: string,
      group?:  {
        __typename: "Group",
        id: string,
        studyPhaseId: string,
        type?: string | null,
        groupName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseGroupsId?: string | null,
      } | null,
      timepoints?:  {
        __typename: "ModelTimepointConnection",
        nextToken?: string | null,
      } | null,
      patientStudyPhaseId: string,
      patientStudyId: string,
      s3Path?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      groupPatientsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TimepointsByPatientQueryVariables = {
  patientId: string,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTimepointFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TimepointsByPatientQuery = {
  timepointsByPatient?:  {
    __typename: "ModelTimepointConnection",
    items:  Array< {
      __typename: "Timepoint",
      id: string,
      patientId: string,
      patient?:  {
        __typename: "Patient",
        id: string,
        groupId: string,
        patientStudyPhaseId: string,
        patientStudyId: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        groupPatientsId?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      timepointName: string,
      s3Path?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      patientTimepointsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SamplesByTimepointQueryVariables = {
  timepointId: string,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSampleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SamplesByTimepointQuery = {
  samplesByTimepoint?:  {
    __typename: "ModelSampleConnection",
    items:  Array< {
      __typename: "Sample",
      id: string,
      timepointId?: string | null,
      timepoint?:  {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      StudyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      BatchId?: string | null,
      Batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      metadataImmunopeptidomicsList?:  {
        __typename: "ModelMetadataImmunopeptidomicsConnection",
        nextToken?: string | null,
      } | null,
      MetadataNanoStringList?:  {
        __typename: "ModelMetadataNanoStringConnection",
        nextToken?: string | null,
      } | null,
      MetadataRnaSeqList?:  {
        __typename: "ModelMetadataRnaSeqConnection",
        nextToken?: string | null,
      } | null,
      AuxiliaryAnalysislList?:  {
        __typename: "ModelAuxiliaryAnalysisConnection",
        nextToken?: string | null,
      } | null,
      domainSampleId: string,
      experimentSpecificVariables?: string | null,
      collectionDate?: string | null,
      processingDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseSamplesId?: string | null,
      timepointSamplesId?: string | null,
      batchSamplesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SamplesByStudyPhaseQueryVariables = {
  studyPhaseId: string,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSampleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SamplesByStudyPhaseQuery = {
  samplesByStudyPhase?:  {
    __typename: "ModelSampleConnection",
    items:  Array< {
      __typename: "Sample",
      id: string,
      timepointId?: string | null,
      timepoint?:  {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      StudyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      BatchId?: string | null,
      Batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      metadataImmunopeptidomicsList?:  {
        __typename: "ModelMetadataImmunopeptidomicsConnection",
        nextToken?: string | null,
      } | null,
      MetadataNanoStringList?:  {
        __typename: "ModelMetadataNanoStringConnection",
        nextToken?: string | null,
      } | null,
      MetadataRnaSeqList?:  {
        __typename: "ModelMetadataRnaSeqConnection",
        nextToken?: string | null,
      } | null,
      AuxiliaryAnalysislList?:  {
        __typename: "ModelAuxiliaryAnalysisConnection",
        nextToken?: string | null,
      } | null,
      domainSampleId: string,
      experimentSpecificVariables?: string | null,
      collectionDate?: string | null,
      processingDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseSamplesId?: string | null,
      timepointSamplesId?: string | null,
      batchSamplesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SamplesByBatchQueryVariables = {
  BatchId: string,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSampleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SamplesByBatchQuery = {
  samplesByBatch?:  {
    __typename: "ModelSampleConnection",
    items:  Array< {
      __typename: "Sample",
      id: string,
      timepointId?: string | null,
      timepoint?:  {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      StudyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      BatchId?: string | null,
      Batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      metadataImmunopeptidomicsList?:  {
        __typename: "ModelMetadataImmunopeptidomicsConnection",
        nextToken?: string | null,
      } | null,
      MetadataNanoStringList?:  {
        __typename: "ModelMetadataNanoStringConnection",
        nextToken?: string | null,
      } | null,
      MetadataRnaSeqList?:  {
        __typename: "ModelMetadataRnaSeqConnection",
        nextToken?: string | null,
      } | null,
      AuxiliaryAnalysislList?:  {
        __typename: "ModelAuxiliaryAnalysisConnection",
        nextToken?: string | null,
      } | null,
      domainSampleId: string,
      experimentSpecificVariables?: string | null,
      collectionDate?: string | null,
      processingDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseSamplesId?: string | null,
      timepointSamplesId?: string | null,
      batchSamplesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SampleByDomainSampleIdQueryVariables = {
  domainSampleId: string,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSampleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SampleByDomainSampleIdQuery = {
  sampleByDomainSampleId?:  {
    __typename: "ModelSampleConnection",
    items:  Array< {
      __typename: "Sample",
      id: string,
      timepointId?: string | null,
      timepoint?:  {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      StudyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      BatchId?: string | null,
      Batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      metadataImmunopeptidomicsList?:  {
        __typename: "ModelMetadataImmunopeptidomicsConnection",
        nextToken?: string | null,
      } | null,
      MetadataNanoStringList?:  {
        __typename: "ModelMetadataNanoStringConnection",
        nextToken?: string | null,
      } | null,
      MetadataRnaSeqList?:  {
        __typename: "ModelMetadataRnaSeqConnection",
        nextToken?: string | null,
      } | null,
      AuxiliaryAnalysislList?:  {
        __typename: "ModelAuxiliaryAnalysisConnection",
        nextToken?: string | null,
      } | null,
      domainSampleId: string,
      experimentSpecificVariables?: string | null,
      collectionDate?: string | null,
      processingDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseSamplesId?: string | null,
      timepointSamplesId?: string | null,
      batchSamplesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BatchesByStudyQueryVariables = {
  studyId: string,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBatchFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BatchesByStudyQuery = {
  batchesByStudy?:  {
    __typename: "ModelBatchConnection",
    items:  Array< {
      __typename: "Batch",
      id: string,
      batchName: string,
      batchAnnotations?: string | null,
      batchGroups?: string | null,
      annotations: string,
      controlSamples: string,
      filters: string,
      description?: string | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyBatchesId?: string | null,
      studyPhaseBatchesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BatchesByStudyPhaseQueryVariables = {
  studyPhaseId: string,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBatchFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BatchesByStudyPhaseQuery = {
  batchesByStudyPhase?:  {
    __typename: "ModelBatchConnection",
    items:  Array< {
      __typename: "Batch",
      id: string,
      batchName: string,
      batchAnnotations?: string | null,
      batchGroups?: string | null,
      annotations: string,
      controlSamples: string,
      filters: string,
      description?: string | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyBatchesId?: string | null,
      studyPhaseBatchesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PipelinesByStudyQueryVariables = {
  studyId: string,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPipelineFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PipelinesByStudyQuery = {
  pipelinesByStudy?:  {
    __typename: "ModelPipelineConnection",
    items:  Array< {
      __typename: "Pipeline",
      id: string,
      name: string,
      status?: pipelineStatus | null,
      description?: string | null,
      s3Event?: string | null,
      s3ParamsFile?: string | null,
      s3Results?: string | null,
      visFileInfoS3path?: string | null,
      s3NfLog?: string | null,
      s3NfTasksLogs?: Array< string | null > | null,
      analysisType: AnalysisType,
      subtypeOfAnalysis?: string | null,
      parameters: string,
      studyId?: string | null,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      scheduledPipeline: boolean,
      scheduledDateTime?: string | null,
      launchablePipelineId: string,
      launchablePipeline?:  {
        __typename: "LaunchablePipelines",
        id: string,
        pipelineName?: string | null,
        pipelineVersion?: string | null,
        parameters?: string | null,
        description?: string | null,
        inputFileInfo?: string | null,
        supportedAnalyses?: Array< AnalysisType | null > | null,
        supportedSubtypeOfAnalyses?: Array< string | null > | null,
        gitRepo?: string | null,
        workflowId?: string | null,
        created: string,
        updated: string,
      } | null,
      batchId: string,
      batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      runId?: string | null,
      userEmail: string,
      pipelineStarted?: string | null,
      pipelineCompleted?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPipelinesId?: string | null,
      studyPhasePipelinesId?: string | null,
      batchPipelinesId?: string | null,
      launchablePipelinesPipelinesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PipelinesByStudyPhaseQueryVariables = {
  studyPhaseId: string,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPipelineFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PipelinesByStudyPhaseQuery = {
  pipelinesByStudyPhase?:  {
    __typename: "ModelPipelineConnection",
    items:  Array< {
      __typename: "Pipeline",
      id: string,
      name: string,
      status?: pipelineStatus | null,
      description?: string | null,
      s3Event?: string | null,
      s3ParamsFile?: string | null,
      s3Results?: string | null,
      visFileInfoS3path?: string | null,
      s3NfLog?: string | null,
      s3NfTasksLogs?: Array< string | null > | null,
      analysisType: AnalysisType,
      subtypeOfAnalysis?: string | null,
      parameters: string,
      studyId?: string | null,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      scheduledPipeline: boolean,
      scheduledDateTime?: string | null,
      launchablePipelineId: string,
      launchablePipeline?:  {
        __typename: "LaunchablePipelines",
        id: string,
        pipelineName?: string | null,
        pipelineVersion?: string | null,
        parameters?: string | null,
        description?: string | null,
        inputFileInfo?: string | null,
        supportedAnalyses?: Array< AnalysisType | null > | null,
        supportedSubtypeOfAnalyses?: Array< string | null > | null,
        gitRepo?: string | null,
        workflowId?: string | null,
        created: string,
        updated: string,
      } | null,
      batchId: string,
      batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      runId?: string | null,
      userEmail: string,
      pipelineStarted?: string | null,
      pipelineCompleted?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPipelinesId?: string | null,
      studyPhasePipelinesId?: string | null,
      batchPipelinesId?: string | null,
      launchablePipelinesPipelinesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PipelinesByBLaunchablePipelinesQueryVariables = {
  launchablePipelineId: string,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPipelineFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PipelinesByBLaunchablePipelinesQuery = {
  pipelinesByBLaunchablePipelines?:  {
    __typename: "ModelPipelineConnection",
    items:  Array< {
      __typename: "Pipeline",
      id: string,
      name: string,
      status?: pipelineStatus | null,
      description?: string | null,
      s3Event?: string | null,
      s3ParamsFile?: string | null,
      s3Results?: string | null,
      visFileInfoS3path?: string | null,
      s3NfLog?: string | null,
      s3NfTasksLogs?: Array< string | null > | null,
      analysisType: AnalysisType,
      subtypeOfAnalysis?: string | null,
      parameters: string,
      studyId?: string | null,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      scheduledPipeline: boolean,
      scheduledDateTime?: string | null,
      launchablePipelineId: string,
      launchablePipeline?:  {
        __typename: "LaunchablePipelines",
        id: string,
        pipelineName?: string | null,
        pipelineVersion?: string | null,
        parameters?: string | null,
        description?: string | null,
        inputFileInfo?: string | null,
        supportedAnalyses?: Array< AnalysisType | null > | null,
        supportedSubtypeOfAnalyses?: Array< string | null > | null,
        gitRepo?: string | null,
        workflowId?: string | null,
        created: string,
        updated: string,
      } | null,
      batchId: string,
      batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      runId?: string | null,
      userEmail: string,
      pipelineStarted?: string | null,
      pipelineCompleted?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPipelinesId?: string | null,
      studyPhasePipelinesId?: string | null,
      batchPipelinesId?: string | null,
      launchablePipelinesPipelinesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PipelinesByBatchQueryVariables = {
  batchId: string,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPipelineFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PipelinesByBatchQuery = {
  pipelinesByBatch?:  {
    __typename: "ModelPipelineConnection",
    items:  Array< {
      __typename: "Pipeline",
      id: string,
      name: string,
      status?: pipelineStatus | null,
      description?: string | null,
      s3Event?: string | null,
      s3ParamsFile?: string | null,
      s3Results?: string | null,
      visFileInfoS3path?: string | null,
      s3NfLog?: string | null,
      s3NfTasksLogs?: Array< string | null > | null,
      analysisType: AnalysisType,
      subtypeOfAnalysis?: string | null,
      parameters: string,
      studyId?: string | null,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      scheduledPipeline: boolean,
      scheduledDateTime?: string | null,
      launchablePipelineId: string,
      launchablePipeline?:  {
        __typename: "LaunchablePipelines",
        id: string,
        pipelineName?: string | null,
        pipelineVersion?: string | null,
        parameters?: string | null,
        description?: string | null,
        inputFileInfo?: string | null,
        supportedAnalyses?: Array< AnalysisType | null > | null,
        supportedSubtypeOfAnalyses?: Array< string | null > | null,
        gitRepo?: string | null,
        workflowId?: string | null,
        created: string,
        updated: string,
      } | null,
      batchId: string,
      batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      runId?: string | null,
      userEmail: string,
      pipelineStarted?: string | null,
      pipelineCompleted?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPipelinesId?: string | null,
      studyPhasePipelinesId?: string | null,
      batchPipelinesId?: string | null,
      launchablePipelinesPipelinesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PipelineByRunIdQueryVariables = {
  runId: string,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPipelineFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PipelineByRunIdQuery = {
  pipelineByRunId?:  {
    __typename: "ModelPipelineConnection",
    items:  Array< {
      __typename: "Pipeline",
      id: string,
      name: string,
      status?: pipelineStatus | null,
      description?: string | null,
      s3Event?: string | null,
      s3ParamsFile?: string | null,
      s3Results?: string | null,
      visFileInfoS3path?: string | null,
      s3NfLog?: string | null,
      s3NfTasksLogs?: Array< string | null > | null,
      analysisType: AnalysisType,
      subtypeOfAnalysis?: string | null,
      parameters: string,
      studyId?: string | null,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      scheduledPipeline: boolean,
      scheduledDateTime?: string | null,
      launchablePipelineId: string,
      launchablePipeline?:  {
        __typename: "LaunchablePipelines",
        id: string,
        pipelineName?: string | null,
        pipelineVersion?: string | null,
        parameters?: string | null,
        description?: string | null,
        inputFileInfo?: string | null,
        supportedAnalyses?: Array< AnalysisType | null > | null,
        supportedSubtypeOfAnalyses?: Array< string | null > | null,
        gitRepo?: string | null,
        workflowId?: string | null,
        created: string,
        updated: string,
      } | null,
      batchId: string,
      batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      runId?: string | null,
      userEmail: string,
      pipelineStarted?: string | null,
      pipelineCompleted?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPipelinesId?: string | null,
      studyPhasePipelinesId?: string | null,
      batchPipelinesId?: string | null,
      launchablePipelinesPipelinesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AuxiliaryAnalysisBySampleQueryVariables = {
  sampleId: string,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAuxiliaryAnalysisFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AuxiliaryAnalysisBySampleQuery = {
  auxiliaryAnalysisBySample?:  {
    __typename: "ModelAuxiliaryAnalysisConnection",
    items:  Array< {
      __typename: "AuxiliaryAnalysis",
      id: string,
      sampleId: string,
      sample?:  {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null,
      auxiliaryFileS3Path: string,
      vendorId: string,
      specimenType: string,
      parameters: string,
      collectionDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      sampleAuxiliaryAnalysislListId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MetadataImmunopeptidomicsBySampleQueryVariables = {
  sampleId: string,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMetadataImmunopeptidomicsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MetadataImmunopeptidomicsBySampleQuery = {
  MetadataImmunopeptidomicsBySample?:  {
    __typename: "ModelMetadataImmunopeptidomicsConnection",
    items:  Array< {
      __typename: "MetadataImmunopeptidomics",
      id: string,
      sampleId: string,
      sample?:  {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null,
      treatment?:  {
        __typename: "ModelTreatmentConnection",
        nextToken?: string | null,
      } | null,
      metaDataTemplateS3Path: string,
      species: string,
      subtypeOfAnalysis?: string | null,
      sampleType: string,
      qcStatus: QcStatus,
      glacierBackup: boolean,
      rawDataS3: boolean,
      tumorVolume?: number | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      isControl: boolean,
      barcodesMappedToControl?: Array< string | null > | null,
      barcode: string,
      s3Path: Array< string | null >,
      sampleMetadataImmunopeptidomicsListId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MetadataNanoStringBySampleQueryVariables = {
  sampleId: string,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMetadataNanoStringFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MetadataNanoStringBySampleQuery = {
  MetadataNanoStringBySample?:  {
    __typename: "ModelMetadataNanoStringConnection",
    items:  Array< {
      __typename: "MetadataNanoString",
      id: string,
      sampleId: string,
      sample?:  {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null,
      treatment?:  {
        __typename: "ModelTreatmentConnection",
        nextToken?: string | null,
      } | null,
      metaDataTemplateS3Path: string,
      subtypeOfAnalysis?: string | null,
      species: string,
      sampleType: string,
      qcStatus: QcStatus,
      glacierBackup: boolean,
      rawDataS3: boolean,
      tumorVolume?: number | null,
      dv200Percentage?: number | null,
      concNanoGramPerMicroL?: number | null,
      rnaMassNanoGram?: number | null,
      rnaVolumeMicroL?: number | null,
      rin?: number | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      isControl: boolean,
      barcodesMappedToControl?: Array< string | null > | null,
      barcode: string,
      s3Path: Array< string | null >,
      sampleMetadataNanoStringListId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MetadataRnaSeqBySampleQueryVariables = {
  sampleId: string,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMetadataRnaSeqFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MetadataRnaSeqBySampleQuery = {
  MetadataRnaSeqBySample?:  {
    __typename: "ModelMetadataRnaSeqConnection",
    items:  Array< {
      __typename: "MetadataRnaSeq",
      id: string,
      sampleId: string,
      sample?:  {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null,
      treatment?:  {
        __typename: "ModelTreatmentConnection",
        nextToken?: string | null,
      } | null,
      metaDataTemplateS3Path: string,
      subtypeOfAnalysis?: string | null,
      species: string,
      sampleType: string,
      qcStatus: QcStatus,
      glacierBackup: boolean,
      rawDataS3: boolean,
      tumorVolume?: number | null,
      sequenceType: SequenceType,
      strandSpecific: string,
      UMI: boolean,
      adaptorsRemoved: boolean,
      adaptorsS3Path?: string | null,
      indexSequences?: Array< string | null > | null,
      concNanoGramPerMicroL?: number | null,
      rnaMassNanoGram?: number | null,
      rnaVolumeMicroL?: number | null,
      rin?: number | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      isControl: boolean,
      barcodesMappedToControl?: Array< string | null > | null,
      barcode: string,
      s3Path: Array< string | null >,
      sampleMetadataRnaSeqListId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TreatmentsByMetadataImmunopeptidomicsQueryVariables = {
  metadataImmunopeptidomicsId: string,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTreatmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TreatmentsByMetadataImmunopeptidomicsQuery = {
  treatmentsByMetadataImmunopeptidomics?:  {
    __typename: "ModelTreatmentConnection",
    items:  Array< {
      __typename: "Treatment",
      id: string,
      metadataImmunopeptidomicsId?: string | null,
      metadataImmunopeptidomics?:  {
        __typename: "MetadataImmunopeptidomics",
        id: string,
        sampleId: string,
        metaDataTemplateS3Path: string,
        species: string,
        subtypeOfAnalysis?: string | null,
        sampleType: string,
        qcStatus: QcStatus,
        glacierBackup: boolean,
        rawDataS3: boolean,
        tumorVolume?: number | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        isControl: boolean,
        barcodesMappedToControl?: Array< string | null > | null,
        barcode: string,
        s3Path: Array< string | null >,
        sampleMetadataImmunopeptidomicsListId?: string | null,
      } | null,
      metadataNanoStringId?: string | null,
      metadataNanoString?:  {
        __typename: "MetadataNanoString",
        id: string,
        sampleId: string,
        metaDataTemplateS3Path: string,
        subtypeOfAnalysis?: string | null,
        species: string,
        sampleType: string,
        qcStatus: QcStatus,
        glacierBackup: boolean,
        rawDataS3: boolean,
        tumorVolume?: number | null,
        dv200Percentage?: number | null,
        concNanoGramPerMicroL?: number | null,
        rnaMassNanoGram?: number | null,
        rnaVolumeMicroL?: number | null,
        rin?: number | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        isControl: boolean,
        barcodesMappedToControl?: Array< string | null > | null,
        barcode: string,
        s3Path: Array< string | null >,
        sampleMetadataNanoStringListId?: string | null,
      } | null,
      metadataRnaSeqId?: string | null,
      metadataRnaSeq?:  {
        __typename: "MetadataRnaSeq",
        id: string,
        sampleId: string,
        metaDataTemplateS3Path: string,
        subtypeOfAnalysis?: string | null,
        species: string,
        sampleType: string,
        qcStatus: QcStatus,
        glacierBackup: boolean,
        rawDataS3: boolean,
        tumorVolume?: number | null,
        sequenceType: SequenceType,
        strandSpecific: string,
        UMI: boolean,
        adaptorsRemoved: boolean,
        adaptorsS3Path?: string | null,
        indexSequences?: Array< string | null > | null,
        concNanoGramPerMicroL?: number | null,
        rnaMassNanoGram?: number | null,
        rnaVolumeMicroL?: number | null,
        rin?: number | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        isControl: boolean,
        barcodesMappedToControl?: Array< string | null > | null,
        barcode: string,
        s3Path: Array< string | null >,
        sampleMetadataRnaSeqListId?: string | null,
      } | null,
      treatmentName: string,
      dose: number,
      doseUnit: string,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      metadataImmunopeptidomicsTreatmentId?: string | null,
      metadataNanoStringTreatmentId?: string | null,
      metadataRnaSeqTreatmentId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TreatmentsByMetadataNanoStringQueryVariables = {
  metadataNanoStringId: string,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTreatmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TreatmentsByMetadataNanoStringQuery = {
  treatmentsByMetadataNanoString?:  {
    __typename: "ModelTreatmentConnection",
    items:  Array< {
      __typename: "Treatment",
      id: string,
      metadataImmunopeptidomicsId?: string | null,
      metadataImmunopeptidomics?:  {
        __typename: "MetadataImmunopeptidomics",
        id: string,
        sampleId: string,
        metaDataTemplateS3Path: string,
        species: string,
        subtypeOfAnalysis?: string | null,
        sampleType: string,
        qcStatus: QcStatus,
        glacierBackup: boolean,
        rawDataS3: boolean,
        tumorVolume?: number | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        isControl: boolean,
        barcodesMappedToControl?: Array< string | null > | null,
        barcode: string,
        s3Path: Array< string | null >,
        sampleMetadataImmunopeptidomicsListId?: string | null,
      } | null,
      metadataNanoStringId?: string | null,
      metadataNanoString?:  {
        __typename: "MetadataNanoString",
        id: string,
        sampleId: string,
        metaDataTemplateS3Path: string,
        subtypeOfAnalysis?: string | null,
        species: string,
        sampleType: string,
        qcStatus: QcStatus,
        glacierBackup: boolean,
        rawDataS3: boolean,
        tumorVolume?: number | null,
        dv200Percentage?: number | null,
        concNanoGramPerMicroL?: number | null,
        rnaMassNanoGram?: number | null,
        rnaVolumeMicroL?: number | null,
        rin?: number | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        isControl: boolean,
        barcodesMappedToControl?: Array< string | null > | null,
        barcode: string,
        s3Path: Array< string | null >,
        sampleMetadataNanoStringListId?: string | null,
      } | null,
      metadataRnaSeqId?: string | null,
      metadataRnaSeq?:  {
        __typename: "MetadataRnaSeq",
        id: string,
        sampleId: string,
        metaDataTemplateS3Path: string,
        subtypeOfAnalysis?: string | null,
        species: string,
        sampleType: string,
        qcStatus: QcStatus,
        glacierBackup: boolean,
        rawDataS3: boolean,
        tumorVolume?: number | null,
        sequenceType: SequenceType,
        strandSpecific: string,
        UMI: boolean,
        adaptorsRemoved: boolean,
        adaptorsS3Path?: string | null,
        indexSequences?: Array< string | null > | null,
        concNanoGramPerMicroL?: number | null,
        rnaMassNanoGram?: number | null,
        rnaVolumeMicroL?: number | null,
        rin?: number | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        isControl: boolean,
        barcodesMappedToControl?: Array< string | null > | null,
        barcode: string,
        s3Path: Array< string | null >,
        sampleMetadataRnaSeqListId?: string | null,
      } | null,
      treatmentName: string,
      dose: number,
      doseUnit: string,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      metadataImmunopeptidomicsTreatmentId?: string | null,
      metadataNanoStringTreatmentId?: string | null,
      metadataRnaSeqTreatmentId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TreatmentsByMetadataRnaSeqQueryVariables = {
  metadataRnaSeqId: string,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTreatmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TreatmentsByMetadataRnaSeqQuery = {
  treatmentsByMetadataRnaSeq?:  {
    __typename: "ModelTreatmentConnection",
    items:  Array< {
      __typename: "Treatment",
      id: string,
      metadataImmunopeptidomicsId?: string | null,
      metadataImmunopeptidomics?:  {
        __typename: "MetadataImmunopeptidomics",
        id: string,
        sampleId: string,
        metaDataTemplateS3Path: string,
        species: string,
        subtypeOfAnalysis?: string | null,
        sampleType: string,
        qcStatus: QcStatus,
        glacierBackup: boolean,
        rawDataS3: boolean,
        tumorVolume?: number | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        isControl: boolean,
        barcodesMappedToControl?: Array< string | null > | null,
        barcode: string,
        s3Path: Array< string | null >,
        sampleMetadataImmunopeptidomicsListId?: string | null,
      } | null,
      metadataNanoStringId?: string | null,
      metadataNanoString?:  {
        __typename: "MetadataNanoString",
        id: string,
        sampleId: string,
        metaDataTemplateS3Path: string,
        subtypeOfAnalysis?: string | null,
        species: string,
        sampleType: string,
        qcStatus: QcStatus,
        glacierBackup: boolean,
        rawDataS3: boolean,
        tumorVolume?: number | null,
        dv200Percentage?: number | null,
        concNanoGramPerMicroL?: number | null,
        rnaMassNanoGram?: number | null,
        rnaVolumeMicroL?: number | null,
        rin?: number | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        isControl: boolean,
        barcodesMappedToControl?: Array< string | null > | null,
        barcode: string,
        s3Path: Array< string | null >,
        sampleMetadataNanoStringListId?: string | null,
      } | null,
      metadataRnaSeqId?: string | null,
      metadataRnaSeq?:  {
        __typename: "MetadataRnaSeq",
        id: string,
        sampleId: string,
        metaDataTemplateS3Path: string,
        subtypeOfAnalysis?: string | null,
        species: string,
        sampleType: string,
        qcStatus: QcStatus,
        glacierBackup: boolean,
        rawDataS3: boolean,
        tumorVolume?: number | null,
        sequenceType: SequenceType,
        strandSpecific: string,
        UMI: boolean,
        adaptorsRemoved: boolean,
        adaptorsS3Path?: string | null,
        indexSequences?: Array< string | null > | null,
        concNanoGramPerMicroL?: number | null,
        rnaMassNanoGram?: number | null,
        rnaVolumeMicroL?: number | null,
        rin?: number | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        isControl: boolean,
        barcodesMappedToControl?: Array< string | null > | null,
        barcode: string,
        s3Path: Array< string | null >,
        sampleMetadataRnaSeqListId?: string | null,
      } | null,
      treatmentName: string,
      dose: number,
      doseUnit: string,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      metadataImmunopeptidomicsTreatmentId?: string | null,
      metadataNanoStringTreatmentId?: string | null,
      metadataRnaSeqTreatmentId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type LaunchablePipelinesByPipelineNameAndVersionQueryVariables = {
  pipelineName: string,
  pipelineVersion?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLaunchablePipelinesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LaunchablePipelinesByPipelineNameAndVersionQuery = {
  launchablePipelinesByPipelineNameAndVersion?:  {
    __typename: "ModelLaunchablePipelinesConnection",
    items:  Array< {
      __typename: "LaunchablePipelines",
      id: string,
      pipelineName?: string | null,
      organizations?:  {
        __typename: "ModelOrganizationLaunchablePipelinesConnection",
        nextToken?: string | null,
      } | null,
      pipelineVersion?: string | null,
      parameters?: string | null,
      description?: string | null,
      inputFileInfo?: string | null,
      supportedAnalyses?: Array< AnalysisType | null > | null,
      supportedSubtypeOfAnalyses?: Array< string | null > | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      gitRepo?: string | null,
      workflowId?: string | null,
      created: string,
      updated: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersByOrganizationQueryVariables = {
  organization: string,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByOrganizationQuery = {
  usersByOrganization?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      email?: string | null,
      organization?: string | null,
      gitToken?: string | null,
      gitName?: string | null,
      gitURL?: string | null,
      readGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DataLabEC2InstancesByTypeQueryVariables = {
  type: DataLabEC2InstancesType,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDataLabEC2InstancesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DataLabEC2InstancesByTypeQuery = {
  dataLabEC2InstancesByType?:  {
    __typename: "ModelDataLabEC2InstancesConnection",
    items:  Array< {
      __typename: "DataLabEC2Instances",
      id: string,
      creationTimestamp?: string | null,
      stoppedTimestamp?: string | null,
      startTimestamp?: string | null,
      terminatedTimestamp?: string | null,
      stoppedTimePeriod?: number | null,
      runningTimePeriod?: number | null,
      userEmail: string,
      status?: DataLabEC2InstancesStatus | null,
      type?: DataLabEC2InstancesType | null,
      dataLabInstanceSessions?:  {
        __typename: "ModelDataLabEC2InstanceSessionConnection",
        nextToken?: string | null,
      } | null,
      dataLabInstanceStatusChanges?:  {
        __typename: "ModelDataLabEC2InstanceStatusChangeConnection",
        nextToken?: string | null,
      } | null,
      organizationId?: string | null,
      organizationName?: string | null,
      instanceUrl?: string | null,
      instanceId?: string | null,
      infrastructureRelatedEntities?: string | null,
      instanceLaunchEvent?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DataLabInstanceSessionsByDataLabInstanceQueryVariables = {
  dataLabInstanceId: string,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDataLabEC2InstanceSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DataLabInstanceSessionsByDataLabInstanceQuery = {
  dataLabInstanceSessionsByDataLabInstance?:  {
    __typename: "ModelDataLabEC2InstanceSessionConnection",
    items:  Array< {
      __typename: "DataLabEC2InstanceSession",
      id: string,
      dataLabInstanceId: string,
      dataLabInstance?:  {
        __typename: "DataLabEC2Instances",
        id: string,
        creationTimestamp?: string | null,
        stoppedTimestamp?: string | null,
        startTimestamp?: string | null,
        terminatedTimestamp?: string | null,
        stoppedTimePeriod?: number | null,
        runningTimePeriod?: number | null,
        userEmail: string,
        status?: DataLabEC2InstancesStatus | null,
        type?: DataLabEC2InstancesType | null,
        organizationId?: string | null,
        organizationName?: string | null,
        instanceUrl?: string | null,
        instanceId?: string | null,
        infrastructureRelatedEntities?: string | null,
        instanceLaunchEvent?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        owner?: string | null,
      } | null,
      s3Path?: string | null,
      instanceLaunchEvent?: string | null,
      status?: DataLabEC2InstanceSessionStatus | null,
      title?: string | null,
      description?: string | null,
      created: string,
      updated: string,
      dataLabEC2InstancesDataLabInstanceSessionsId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DataLabInstanceStatusChangesByDataLabInstanceQueryVariables = {
  dataLabInstanceId: string,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDataLabEC2InstanceStatusChangeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DataLabInstanceStatusChangesByDataLabInstanceQuery = {
  dataLabInstanceStatusChangesByDataLabInstance?:  {
    __typename: "ModelDataLabEC2InstanceStatusChangeConnection",
    items:  Array< {
      __typename: "DataLabEC2InstanceStatusChange",
      id: string,
      dataLabInstanceId: string,
      dataLabInstance?:  {
        __typename: "DataLabEC2Instances",
        id: string,
        creationTimestamp?: string | null,
        stoppedTimestamp?: string | null,
        startTimestamp?: string | null,
        terminatedTimestamp?: string | null,
        stoppedTimePeriod?: number | null,
        runningTimePeriod?: number | null,
        userEmail: string,
        status?: DataLabEC2InstancesStatus | null,
        type?: DataLabEC2InstancesType | null,
        organizationId?: string | null,
        organizationName?: string | null,
        instanceUrl?: string | null,
        instanceId?: string | null,
        infrastructureRelatedEntities?: string | null,
        instanceLaunchEvent?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        owner?: string | null,
      } | null,
      organizationId?: string | null,
      previousStatus?: DataLabEC2InstancesStatus | null,
      newStatus?: DataLabEC2InstancesStatus | null,
      initiatedBy?: string | null,
      action?: string | null,
      performedBy?: string | null,
      additionalNotes?: string | null,
      created: string,
      updated: string,
      dataLabEC2InstancesDataLabInstanceStatusChangesId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UploadBundlesByStudyPhaseQueryVariables = {
  studyPhaseId: string,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUploadBundleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UploadBundlesByStudyPhaseQuery = {
  uploadBundlesByStudyPhase?:  {
    __typename: "ModelUploadBundleConnection",
    items:  Array< {
      __typename: "UploadBundle",
      id: string,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      bundleLog?: string | null,
      validationErrors?: string | null,
      status?: string | null,
      userEmail?: string | null,
      uploadBundleAnalysisSession?:  {
        __typename: "UploadBundleAnalysisSession",
        id: string,
        metadataFileS3Path?: string | null,
        step?: string | null,
        listOfRawFileNames?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis: string,
        studyPhaseId: string,
        metadataHelperLambdaResponseS3Path?: string | null,
        barcodeToFilesToUploadS3Path?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        owner?: string | null,
      } | null,
      uploadSamples?:  {
        __typename: "ModelUploadSampleConnection",
        nextToken?: string | null,
      } | null,
      uploadFiles?:  {
        __typename: "ModelUploadFileConnection",
        nextToken?: string | null,
      } | null,
      adminGroups?: Array< string | null > | null,
      completionTime?: string | null,
      created: string,
      updated: string,
      studyPhaseUploadBundlesId?: string | null,
      uploadBundleUploadBundleAnalysisSessionId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UploadSamplesByUploadBundleQueryVariables = {
  uploadBundleId: string,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUploadSampleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UploadSamplesByUploadBundleQuery = {
  uploadSamplesByUploadBundle?:  {
    __typename: "ModelUploadSampleConnection",
    items:  Array< {
      __typename: "UploadSample",
      id: string,
      uploadBundleId: string,
      uploadBundle?:  {
        __typename: "UploadBundle",
        id: string,
        studyPhaseId?: string | null,
        bundleLog?: string | null,
        validationErrors?: string | null,
        status?: string | null,
        userEmail?: string | null,
        adminGroups?: Array< string | null > | null,
        completionTime?: string | null,
        created: string,
        updated: string,
        studyPhaseUploadBundlesId?: string | null,
        uploadBundleUploadBundleAnalysisSessionId?: string | null,
        owner?: string | null,
      } | null,
      sampleId: string,
      barcode: string,
      sampleLog?: string | null,
      writeMetadata: boolean,
      status?: string | null,
      uploadFiles?:  {
        __typename: "ModelUploadFileConnection",
        nextToken?: string | null,
      } | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      uploadBundleUploadSamplesId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UploadSampleAnalysisSessionByUploadBundleAnalysisSessionQueryVariables = {
  uploadBundleAnalysisSessionId: string,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUploadSampleAnalysisSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UploadSampleAnalysisSessionByUploadBundleAnalysisSessionQuery = {
  uploadSampleAnalysisSessionByUploadBundleAnalysisSession?:  {
    __typename: "ModelUploadSampleAnalysisSessionConnection",
    items:  Array< {
      __typename: "UploadSampleAnalysisSession",
      id: string,
      uploadBundleAnalysisSessionId: string,
      uploadBundleAnalysisSession?:  {
        __typename: "UploadBundleAnalysisSession",
        id: string,
        metadataFileS3Path?: string | null,
        step?: string | null,
        listOfRawFileNames?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis: string,
        studyPhaseId: string,
        metadataHelperLambdaResponseS3Path?: string | null,
        barcodeToFilesToUploadS3Path?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        owner?: string | null,
      } | null,
      sampleId: string,
      rowFromMetaDataFileMappedToSample: string,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      uploadBundleAnalysisSessionUploadSampleAnalysisSessionId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UploadFilesByUploadSampleQueryVariables = {
  uploadSampleId: string,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUploadFileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UploadFilesByUploadSampleQuery = {
  uploadFilesByUploadSample?:  {
    __typename: "ModelUploadFileConnection",
    items:  Array< {
      __typename: "UploadFile",
      id: string,
      uploadSampleId?: string | null,
      uploadSample?:  {
        __typename: "UploadSample",
        id: string,
        uploadBundleId: string,
        sampleId: string,
        barcode: string,
        sampleLog?: string | null,
        writeMetadata: boolean,
        status?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        uploadBundleUploadSamplesId?: string | null,
        owner?: string | null,
      } | null,
      uploadBundleId?: string | null,
      uploadBundle?:  {
        __typename: "UploadBundle",
        id: string,
        studyPhaseId?: string | null,
        bundleLog?: string | null,
        validationErrors?: string | null,
        status?: string | null,
        userEmail?: string | null,
        adminGroups?: Array< string | null > | null,
        completionTime?: string | null,
        created: string,
        updated: string,
        studyPhaseUploadBundlesId?: string | null,
        uploadBundleUploadBundleAnalysisSessionId?: string | null,
        owner?: string | null,
      } | null,
      multipartUploadId?: string | null,
      status?: UploadFileStatus | null,
      toSkipUpload: boolean,
      fileName: string,
      fileType: string,
      s3Path: string,
      size?: number | null,
      fileLog?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      uploadBundleUploadFilesId?: string | null,
      uploadSampleUploadFilesId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UploadFilesByUploadBundleQueryVariables = {
  uploadBundleId: string,
  created?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUploadFileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UploadFilesByUploadBundleQuery = {
  uploadFilesByUploadBundle?:  {
    __typename: "ModelUploadFileConnection",
    items:  Array< {
      __typename: "UploadFile",
      id: string,
      uploadSampleId?: string | null,
      uploadSample?:  {
        __typename: "UploadSample",
        id: string,
        uploadBundleId: string,
        sampleId: string,
        barcode: string,
        sampleLog?: string | null,
        writeMetadata: boolean,
        status?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        uploadBundleUploadSamplesId?: string | null,
        owner?: string | null,
      } | null,
      uploadBundleId?: string | null,
      uploadBundle?:  {
        __typename: "UploadBundle",
        id: string,
        studyPhaseId?: string | null,
        bundleLog?: string | null,
        validationErrors?: string | null,
        status?: string | null,
        userEmail?: string | null,
        adminGroups?: Array< string | null > | null,
        completionTime?: string | null,
        created: string,
        updated: string,
        studyPhaseUploadBundlesId?: string | null,
        uploadBundleUploadBundleAnalysisSessionId?: string | null,
        owner?: string | null,
      } | null,
      multipartUploadId?: string | null,
      status?: UploadFileStatus | null,
      toSkipUpload: boolean,
      fileName: string,
      fileType: string,
      s3Path: string,
      size?: number | null,
      fileLog?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      uploadBundleUploadFilesId?: string | null,
      uploadSampleUploadFilesId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrganizationLaunchablePipelinesByOrganizationIdQueryVariables = {
  organizationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrganizationLaunchablePipelinesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrganizationLaunchablePipelinesByOrganizationIdQuery = {
  organizationLaunchablePipelinesByOrganizationId?:  {
    __typename: "ModelOrganizationLaunchablePipelinesConnection",
    items:  Array< {
      __typename: "OrganizationLaunchablePipelines",
      id: string,
      organizationId: string,
      launchablePipelinesId: string,
      organization:  {
        __typename: "Organization",
        id: string,
        organizationName: string,
        organizationCode?: string | null,
        active: boolean,
        notificationEmail: string,
        description: string,
        organizationAnswers?: string | null,
        url: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        created: string,
        updated: string,
      },
      launchablePipelines:  {
        __typename: "LaunchablePipelines",
        id: string,
        pipelineName?: string | null,
        pipelineVersion?: string | null,
        parameters?: string | null,
        description?: string | null,
        inputFileInfo?: string | null,
        supportedAnalyses?: Array< AnalysisType | null > | null,
        supportedSubtypeOfAnalyses?: Array< string | null > | null,
        gitRepo?: string | null,
        workflowId?: string | null,
        created: string,
        updated: string,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrganizationLaunchablePipelinesByLaunchablePipelinesIdQueryVariables = {
  launchablePipelinesId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrganizationLaunchablePipelinesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrganizationLaunchablePipelinesByLaunchablePipelinesIdQuery = {
  organizationLaunchablePipelinesByLaunchablePipelinesId?:  {
    __typename: "ModelOrganizationLaunchablePipelinesConnection",
    items:  Array< {
      __typename: "OrganizationLaunchablePipelines",
      id: string,
      organizationId: string,
      launchablePipelinesId: string,
      organization:  {
        __typename: "Organization",
        id: string,
        organizationName: string,
        organizationCode?: string | null,
        active: boolean,
        notificationEmail: string,
        description: string,
        organizationAnswers?: string | null,
        url: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        created: string,
        updated: string,
      },
      launchablePipelines:  {
        __typename: "LaunchablePipelines",
        id: string,
        pipelineName?: string | null,
        pipelineVersion?: string | null,
        parameters?: string | null,
        description?: string | null,
        inputFileInfo?: string | null,
        supportedAnalyses?: Array< AnalysisType | null > | null,
        supportedSubtypeOfAnalyses?: Array< string | null > | null,
        gitRepo?: string | null,
        workflowId?: string | null,
        created: string,
        updated: string,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateOrganizationSubscriptionVariables = {
  filter?: ModelSubscriptionOrganizationFilterInput | null,
};

export type OnCreateOrganizationSubscription = {
  onCreateOrganization?:  {
    __typename: "Organization",
    id: string,
    organizationName: string,
    organizationCode?: string | null,
    active: boolean,
    studies?:  {
      __typename: "ModelStudyConnection",
      items:  Array< {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    launchablePipelines?:  {
      __typename: "ModelOrganizationLaunchablePipelinesConnection",
      items:  Array< {
        __typename: "OrganizationLaunchablePipelines",
        id: string,
        organizationId: string,
        launchablePipelinesId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    notificationEmail: string,
    description: string,
    organizationAnswers?: string | null,
    url: string,
    s3Path?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    created: string,
    updated: string,
  } | null,
};

export type OnUpdateOrganizationSubscriptionVariables = {
  filter?: ModelSubscriptionOrganizationFilterInput | null,
};

export type OnUpdateOrganizationSubscription = {
  onUpdateOrganization?:  {
    __typename: "Organization",
    id: string,
    organizationName: string,
    organizationCode?: string | null,
    active: boolean,
    studies?:  {
      __typename: "ModelStudyConnection",
      items:  Array< {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    launchablePipelines?:  {
      __typename: "ModelOrganizationLaunchablePipelinesConnection",
      items:  Array< {
        __typename: "OrganizationLaunchablePipelines",
        id: string,
        organizationId: string,
        launchablePipelinesId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    notificationEmail: string,
    description: string,
    organizationAnswers?: string | null,
    url: string,
    s3Path?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    created: string,
    updated: string,
  } | null,
};

export type OnDeleteOrganizationSubscriptionVariables = {
  filter?: ModelSubscriptionOrganizationFilterInput | null,
};

export type OnDeleteOrganizationSubscription = {
  onDeleteOrganization?:  {
    __typename: "Organization",
    id: string,
    organizationName: string,
    organizationCode?: string | null,
    active: boolean,
    studies?:  {
      __typename: "ModelStudyConnection",
      items:  Array< {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    launchablePipelines?:  {
      __typename: "ModelOrganizationLaunchablePipelinesConnection",
      items:  Array< {
        __typename: "OrganizationLaunchablePipelines",
        id: string,
        organizationId: string,
        launchablePipelinesId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    notificationEmail: string,
    description: string,
    organizationAnswers?: string | null,
    url: string,
    s3Path?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    created: string,
    updated: string,
  } | null,
};

export type OnCreateStudySubscriptionVariables = {
  filter?: ModelSubscriptionStudyFilterInput | null,
};

export type OnCreateStudySubscription = {
  onCreateStudy?:  {
    __typename: "Study",
    id: string,
    studyName: string,
    organizationId: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      organizationName: string,
      organizationCode?: string | null,
      active: boolean,
      studies?:  {
        __typename: "ModelStudyConnection",
        nextToken?: string | null,
      } | null,
      launchablePipelines?:  {
        __typename: "ModelOrganizationLaunchablePipelinesConnection",
        nextToken?: string | null,
      } | null,
      notificationEmail: string,
      description: string,
      organizationAnswers?: string | null,
      url: string,
      s3Path?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      created: string,
      updated: string,
    } | null,
    studyPhases?:  {
      __typename: "ModelStudyPhaseConnection",
      items:  Array< {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    batches?:  {
      __typename: "ModelBatchConnection",
      items:  Array< {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pipelines?:  {
      __typename: "ModelPipelineConnection",
      items:  Array< {
        __typename: "Pipeline",
        id: string,
        name: string,
        status?: pipelineStatus | null,
        description?: string | null,
        s3Event?: string | null,
        s3ParamsFile?: string | null,
        s3Results?: string | null,
        visFileInfoS3path?: string | null,
        s3NfLog?: string | null,
        s3NfTasksLogs?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis?: string | null,
        parameters: string,
        studyId?: string | null,
        studyPhaseId?: string | null,
        scheduledPipeline: boolean,
        scheduledDateTime?: string | null,
        launchablePipelineId: string,
        batchId: string,
        runId?: string | null,
        userEmail: string,
        pipelineStarted?: string | null,
        pipelineCompleted?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPipelinesId?: string | null,
        studyPhasePipelinesId?: string | null,
        batchPipelinesId?: string | null,
        launchablePipelinesPipelinesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    description: string,
    studyCode: string,
    studyAnswers?: string | null,
    s3Path?: string | null,
    croGroups?: Array< string | null > | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    organizationStudiesId?: string | null,
  } | null,
};

export type OnUpdateStudySubscriptionVariables = {
  filter?: ModelSubscriptionStudyFilterInput | null,
};

export type OnUpdateStudySubscription = {
  onUpdateStudy?:  {
    __typename: "Study",
    id: string,
    studyName: string,
    organizationId: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      organizationName: string,
      organizationCode?: string | null,
      active: boolean,
      studies?:  {
        __typename: "ModelStudyConnection",
        nextToken?: string | null,
      } | null,
      launchablePipelines?:  {
        __typename: "ModelOrganizationLaunchablePipelinesConnection",
        nextToken?: string | null,
      } | null,
      notificationEmail: string,
      description: string,
      organizationAnswers?: string | null,
      url: string,
      s3Path?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      created: string,
      updated: string,
    } | null,
    studyPhases?:  {
      __typename: "ModelStudyPhaseConnection",
      items:  Array< {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    batches?:  {
      __typename: "ModelBatchConnection",
      items:  Array< {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pipelines?:  {
      __typename: "ModelPipelineConnection",
      items:  Array< {
        __typename: "Pipeline",
        id: string,
        name: string,
        status?: pipelineStatus | null,
        description?: string | null,
        s3Event?: string | null,
        s3ParamsFile?: string | null,
        s3Results?: string | null,
        visFileInfoS3path?: string | null,
        s3NfLog?: string | null,
        s3NfTasksLogs?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis?: string | null,
        parameters: string,
        studyId?: string | null,
        studyPhaseId?: string | null,
        scheduledPipeline: boolean,
        scheduledDateTime?: string | null,
        launchablePipelineId: string,
        batchId: string,
        runId?: string | null,
        userEmail: string,
        pipelineStarted?: string | null,
        pipelineCompleted?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPipelinesId?: string | null,
        studyPhasePipelinesId?: string | null,
        batchPipelinesId?: string | null,
        launchablePipelinesPipelinesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    description: string,
    studyCode: string,
    studyAnswers?: string | null,
    s3Path?: string | null,
    croGroups?: Array< string | null > | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    organizationStudiesId?: string | null,
  } | null,
};

export type OnDeleteStudySubscriptionVariables = {
  filter?: ModelSubscriptionStudyFilterInput | null,
};

export type OnDeleteStudySubscription = {
  onDeleteStudy?:  {
    __typename: "Study",
    id: string,
    studyName: string,
    organizationId: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      organizationName: string,
      organizationCode?: string | null,
      active: boolean,
      studies?:  {
        __typename: "ModelStudyConnection",
        nextToken?: string | null,
      } | null,
      launchablePipelines?:  {
        __typename: "ModelOrganizationLaunchablePipelinesConnection",
        nextToken?: string | null,
      } | null,
      notificationEmail: string,
      description: string,
      organizationAnswers?: string | null,
      url: string,
      s3Path?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      created: string,
      updated: string,
    } | null,
    studyPhases?:  {
      __typename: "ModelStudyPhaseConnection",
      items:  Array< {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    batches?:  {
      __typename: "ModelBatchConnection",
      items:  Array< {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pipelines?:  {
      __typename: "ModelPipelineConnection",
      items:  Array< {
        __typename: "Pipeline",
        id: string,
        name: string,
        status?: pipelineStatus | null,
        description?: string | null,
        s3Event?: string | null,
        s3ParamsFile?: string | null,
        s3Results?: string | null,
        visFileInfoS3path?: string | null,
        s3NfLog?: string | null,
        s3NfTasksLogs?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis?: string | null,
        parameters: string,
        studyId?: string | null,
        studyPhaseId?: string | null,
        scheduledPipeline: boolean,
        scheduledDateTime?: string | null,
        launchablePipelineId: string,
        batchId: string,
        runId?: string | null,
        userEmail: string,
        pipelineStarted?: string | null,
        pipelineCompleted?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPipelinesId?: string | null,
        studyPhasePipelinesId?: string | null,
        batchPipelinesId?: string | null,
        launchablePipelinesPipelinesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    description: string,
    studyCode: string,
    studyAnswers?: string | null,
    s3Path?: string | null,
    croGroups?: Array< string | null > | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    organizationStudiesId?: string | null,
  } | null,
};

export type OnCreateStudyPhaseSubscriptionVariables = {
  filter?: ModelSubscriptionStudyPhaseFilterInput | null,
};

export type OnCreateStudyPhaseSubscription = {
  onCreateStudyPhase?:  {
    __typename: "StudyPhase",
    id: string,
    studyId: string,
    study?:  {
      __typename: "Study",
      id: string,
      studyName: string,
      organizationId: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        organizationName: string,
        organizationCode?: string | null,
        active: boolean,
        notificationEmail: string,
        description: string,
        organizationAnswers?: string | null,
        url: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        created: string,
        updated: string,
      } | null,
      studyPhases?:  {
        __typename: "ModelStudyPhaseConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      description: string,
      studyCode: string,
      studyAnswers?: string | null,
      s3Path?: string | null,
      croGroups?: Array< string | null > | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      organizationStudiesId?: string | null,
    } | null,
    metaDataValidationSchemaTemplateId: string,
    metaDataValidationSchemaTemplate?:  {
      __typename: "MetaDataValidationSchemaTemplate",
      id: string,
      studyPhases?:  {
        __typename: "ModelStudyPhaseConnection",
        nextToken?: string | null,
      } | null,
      version: string,
      description?: string | null,
      templateFiles: string,
      validationSchemaTemplate: string,
      created: string,
      updated: string,
    } | null,
    groups?:  {
      __typename: "ModelGroupConnection",
      items:  Array< {
        __typename: "Group",
        id: string,
        studyPhaseId: string,
        type?: string | null,
        groupName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseGroupsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    samples?:  {
      __typename: "ModelSampleConnection",
      items:  Array< {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    batches?:  {
      __typename: "ModelBatchConnection",
      items:  Array< {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    uploadBundles?:  {
      __typename: "ModelUploadBundleConnection",
      items:  Array< {
        __typename: "UploadBundle",
        id: string,
        studyPhaseId?: string | null,
        bundleLog?: string | null,
        validationErrors?: string | null,
        status?: string | null,
        userEmail?: string | null,
        adminGroups?: Array< string | null > | null,
        completionTime?: string | null,
        created: string,
        updated: string,
        studyPhaseUploadBundlesId?: string | null,
        uploadBundleUploadBundleAnalysisSessionId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pipelines?:  {
      __typename: "ModelPipelineConnection",
      items:  Array< {
        __typename: "Pipeline",
        id: string,
        name: string,
        status?: pipelineStatus | null,
        description?: string | null,
        s3Event?: string | null,
        s3ParamsFile?: string | null,
        s3Results?: string | null,
        visFileInfoS3path?: string | null,
        s3NfLog?: string | null,
        s3NfTasksLogs?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis?: string | null,
        parameters: string,
        studyId?: string | null,
        studyPhaseId?: string | null,
        scheduledPipeline: boolean,
        scheduledDateTime?: string | null,
        launchablePipelineId: string,
        batchId: string,
        runId?: string | null,
        userEmail: string,
        pipelineStarted?: string | null,
        pipelineCompleted?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPipelinesId?: string | null,
        studyPhasePipelinesId?: string | null,
        batchPipelinesId?: string | null,
        launchablePipelinesPipelinesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    validationSchema?: string | null,
    description: string,
    studyPhaseName: string,
    studyPhaseAnswers?: string | null,
    experimentSpecificVariables?: string | null,
    sampleTypes?: Array< string | null > | null,
    specimens?: Array< string | null > | null,
    species?: Array< string | null > | null,
    s3Path?: string | null,
    groupNames?: Array< string | null > | null,
    labAssayTechnologies?: Array< string | null > | null,
    phase: Phase,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    croGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyStudyPhasesId?: string | null,
    metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
  } | null,
};

export type OnUpdateStudyPhaseSubscriptionVariables = {
  filter?: ModelSubscriptionStudyPhaseFilterInput | null,
};

export type OnUpdateStudyPhaseSubscription = {
  onUpdateStudyPhase?:  {
    __typename: "StudyPhase",
    id: string,
    studyId: string,
    study?:  {
      __typename: "Study",
      id: string,
      studyName: string,
      organizationId: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        organizationName: string,
        organizationCode?: string | null,
        active: boolean,
        notificationEmail: string,
        description: string,
        organizationAnswers?: string | null,
        url: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        created: string,
        updated: string,
      } | null,
      studyPhases?:  {
        __typename: "ModelStudyPhaseConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      description: string,
      studyCode: string,
      studyAnswers?: string | null,
      s3Path?: string | null,
      croGroups?: Array< string | null > | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      organizationStudiesId?: string | null,
    } | null,
    metaDataValidationSchemaTemplateId: string,
    metaDataValidationSchemaTemplate?:  {
      __typename: "MetaDataValidationSchemaTemplate",
      id: string,
      studyPhases?:  {
        __typename: "ModelStudyPhaseConnection",
        nextToken?: string | null,
      } | null,
      version: string,
      description?: string | null,
      templateFiles: string,
      validationSchemaTemplate: string,
      created: string,
      updated: string,
    } | null,
    groups?:  {
      __typename: "ModelGroupConnection",
      items:  Array< {
        __typename: "Group",
        id: string,
        studyPhaseId: string,
        type?: string | null,
        groupName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseGroupsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    samples?:  {
      __typename: "ModelSampleConnection",
      items:  Array< {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    batches?:  {
      __typename: "ModelBatchConnection",
      items:  Array< {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    uploadBundles?:  {
      __typename: "ModelUploadBundleConnection",
      items:  Array< {
        __typename: "UploadBundle",
        id: string,
        studyPhaseId?: string | null,
        bundleLog?: string | null,
        validationErrors?: string | null,
        status?: string | null,
        userEmail?: string | null,
        adminGroups?: Array< string | null > | null,
        completionTime?: string | null,
        created: string,
        updated: string,
        studyPhaseUploadBundlesId?: string | null,
        uploadBundleUploadBundleAnalysisSessionId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pipelines?:  {
      __typename: "ModelPipelineConnection",
      items:  Array< {
        __typename: "Pipeline",
        id: string,
        name: string,
        status?: pipelineStatus | null,
        description?: string | null,
        s3Event?: string | null,
        s3ParamsFile?: string | null,
        s3Results?: string | null,
        visFileInfoS3path?: string | null,
        s3NfLog?: string | null,
        s3NfTasksLogs?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis?: string | null,
        parameters: string,
        studyId?: string | null,
        studyPhaseId?: string | null,
        scheduledPipeline: boolean,
        scheduledDateTime?: string | null,
        launchablePipelineId: string,
        batchId: string,
        runId?: string | null,
        userEmail: string,
        pipelineStarted?: string | null,
        pipelineCompleted?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPipelinesId?: string | null,
        studyPhasePipelinesId?: string | null,
        batchPipelinesId?: string | null,
        launchablePipelinesPipelinesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    validationSchema?: string | null,
    description: string,
    studyPhaseName: string,
    studyPhaseAnswers?: string | null,
    experimentSpecificVariables?: string | null,
    sampleTypes?: Array< string | null > | null,
    specimens?: Array< string | null > | null,
    species?: Array< string | null > | null,
    s3Path?: string | null,
    groupNames?: Array< string | null > | null,
    labAssayTechnologies?: Array< string | null > | null,
    phase: Phase,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    croGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyStudyPhasesId?: string | null,
    metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
  } | null,
};

export type OnDeleteStudyPhaseSubscriptionVariables = {
  filter?: ModelSubscriptionStudyPhaseFilterInput | null,
};

export type OnDeleteStudyPhaseSubscription = {
  onDeleteStudyPhase?:  {
    __typename: "StudyPhase",
    id: string,
    studyId: string,
    study?:  {
      __typename: "Study",
      id: string,
      studyName: string,
      organizationId: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        organizationName: string,
        organizationCode?: string | null,
        active: boolean,
        notificationEmail: string,
        description: string,
        organizationAnswers?: string | null,
        url: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        created: string,
        updated: string,
      } | null,
      studyPhases?:  {
        __typename: "ModelStudyPhaseConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      description: string,
      studyCode: string,
      studyAnswers?: string | null,
      s3Path?: string | null,
      croGroups?: Array< string | null > | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      organizationStudiesId?: string | null,
    } | null,
    metaDataValidationSchemaTemplateId: string,
    metaDataValidationSchemaTemplate?:  {
      __typename: "MetaDataValidationSchemaTemplate",
      id: string,
      studyPhases?:  {
        __typename: "ModelStudyPhaseConnection",
        nextToken?: string | null,
      } | null,
      version: string,
      description?: string | null,
      templateFiles: string,
      validationSchemaTemplate: string,
      created: string,
      updated: string,
    } | null,
    groups?:  {
      __typename: "ModelGroupConnection",
      items:  Array< {
        __typename: "Group",
        id: string,
        studyPhaseId: string,
        type?: string | null,
        groupName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseGroupsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    samples?:  {
      __typename: "ModelSampleConnection",
      items:  Array< {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    batches?:  {
      __typename: "ModelBatchConnection",
      items:  Array< {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    uploadBundles?:  {
      __typename: "ModelUploadBundleConnection",
      items:  Array< {
        __typename: "UploadBundle",
        id: string,
        studyPhaseId?: string | null,
        bundleLog?: string | null,
        validationErrors?: string | null,
        status?: string | null,
        userEmail?: string | null,
        adminGroups?: Array< string | null > | null,
        completionTime?: string | null,
        created: string,
        updated: string,
        studyPhaseUploadBundlesId?: string | null,
        uploadBundleUploadBundleAnalysisSessionId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pipelines?:  {
      __typename: "ModelPipelineConnection",
      items:  Array< {
        __typename: "Pipeline",
        id: string,
        name: string,
        status?: pipelineStatus | null,
        description?: string | null,
        s3Event?: string | null,
        s3ParamsFile?: string | null,
        s3Results?: string | null,
        visFileInfoS3path?: string | null,
        s3NfLog?: string | null,
        s3NfTasksLogs?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis?: string | null,
        parameters: string,
        studyId?: string | null,
        studyPhaseId?: string | null,
        scheduledPipeline: boolean,
        scheduledDateTime?: string | null,
        launchablePipelineId: string,
        batchId: string,
        runId?: string | null,
        userEmail: string,
        pipelineStarted?: string | null,
        pipelineCompleted?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPipelinesId?: string | null,
        studyPhasePipelinesId?: string | null,
        batchPipelinesId?: string | null,
        launchablePipelinesPipelinesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    validationSchema?: string | null,
    description: string,
    studyPhaseName: string,
    studyPhaseAnswers?: string | null,
    experimentSpecificVariables?: string | null,
    sampleTypes?: Array< string | null > | null,
    specimens?: Array< string | null > | null,
    species?: Array< string | null > | null,
    s3Path?: string | null,
    groupNames?: Array< string | null > | null,
    labAssayTechnologies?: Array< string | null > | null,
    phase: Phase,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    croGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyStudyPhasesId?: string | null,
    metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
  } | null,
};

export type OnCreateEntityQuestionsSubscriptionVariables = {
  filter?: ModelSubscriptionEntityQuestionsFilterInput | null,
};

export type OnCreateEntityQuestionsSubscription = {
  onCreateEntityQuestions?:  {
    __typename: "EntityQuestions",
    id: string,
    entityType?: EntityType | null,
    questions?: string | null,
    created: string,
    updated: string,
  } | null,
};

export type OnUpdateEntityQuestionsSubscriptionVariables = {
  filter?: ModelSubscriptionEntityQuestionsFilterInput | null,
};

export type OnUpdateEntityQuestionsSubscription = {
  onUpdateEntityQuestions?:  {
    __typename: "EntityQuestions",
    id: string,
    entityType?: EntityType | null,
    questions?: string | null,
    created: string,
    updated: string,
  } | null,
};

export type OnDeleteEntityQuestionsSubscriptionVariables = {
  filter?: ModelSubscriptionEntityQuestionsFilterInput | null,
};

export type OnDeleteEntityQuestionsSubscription = {
  onDeleteEntityQuestions?:  {
    __typename: "EntityQuestions",
    id: string,
    entityType?: EntityType | null,
    questions?: string | null,
    created: string,
    updated: string,
  } | null,
};

export type OnCreateMetaDataValidationSchemaTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionMetaDataValidationSchemaTemplateFilterInput | null,
};

export type OnCreateMetaDataValidationSchemaTemplateSubscription = {
  onCreateMetaDataValidationSchemaTemplate?:  {
    __typename: "MetaDataValidationSchemaTemplate",
    id: string,
    studyPhases?:  {
      __typename: "ModelStudyPhaseConnection",
      items:  Array< {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    version: string,
    description?: string | null,
    templateFiles: string,
    validationSchemaTemplate: string,
    created: string,
    updated: string,
  } | null,
};

export type OnUpdateMetaDataValidationSchemaTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionMetaDataValidationSchemaTemplateFilterInput | null,
};

export type OnUpdateMetaDataValidationSchemaTemplateSubscription = {
  onUpdateMetaDataValidationSchemaTemplate?:  {
    __typename: "MetaDataValidationSchemaTemplate",
    id: string,
    studyPhases?:  {
      __typename: "ModelStudyPhaseConnection",
      items:  Array< {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    version: string,
    description?: string | null,
    templateFiles: string,
    validationSchemaTemplate: string,
    created: string,
    updated: string,
  } | null,
};

export type OnDeleteMetaDataValidationSchemaTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionMetaDataValidationSchemaTemplateFilterInput | null,
};

export type OnDeleteMetaDataValidationSchemaTemplateSubscription = {
  onDeleteMetaDataValidationSchemaTemplate?:  {
    __typename: "MetaDataValidationSchemaTemplate",
    id: string,
    studyPhases?:  {
      __typename: "ModelStudyPhaseConnection",
      items:  Array< {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    version: string,
    description?: string | null,
    templateFiles: string,
    validationSchemaTemplate: string,
    created: string,
    updated: string,
  } | null,
};

export type OnCreateGroupSubscriptionVariables = {
  filter?: ModelSubscriptionGroupFilterInput | null,
};

export type OnCreateGroupSubscription = {
  onCreateGroup?:  {
    __typename: "Group",
    id: string,
    studyPhaseId: string,
    studyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    type?: string | null,
    groupName: string,
    s3Path?: string | null,
    patients?:  {
      __typename: "ModelPatientConnection",
      items:  Array< {
        __typename: "Patient",
        id: string,
        groupId: string,
        patientStudyPhaseId: string,
        patientStudyId: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        groupPatientsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyPhaseGroupsId?: string | null,
  } | null,
};

export type OnUpdateGroupSubscriptionVariables = {
  filter?: ModelSubscriptionGroupFilterInput | null,
};

export type OnUpdateGroupSubscription = {
  onUpdateGroup?:  {
    __typename: "Group",
    id: string,
    studyPhaseId: string,
    studyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    type?: string | null,
    groupName: string,
    s3Path?: string | null,
    patients?:  {
      __typename: "ModelPatientConnection",
      items:  Array< {
        __typename: "Patient",
        id: string,
        groupId: string,
        patientStudyPhaseId: string,
        patientStudyId: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        groupPatientsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyPhaseGroupsId?: string | null,
  } | null,
};

export type OnDeleteGroupSubscriptionVariables = {
  filter?: ModelSubscriptionGroupFilterInput | null,
};

export type OnDeleteGroupSubscription = {
  onDeleteGroup?:  {
    __typename: "Group",
    id: string,
    studyPhaseId: string,
    studyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    type?: string | null,
    groupName: string,
    s3Path?: string | null,
    patients?:  {
      __typename: "ModelPatientConnection",
      items:  Array< {
        __typename: "Patient",
        id: string,
        groupId: string,
        patientStudyPhaseId: string,
        patientStudyId: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        groupPatientsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyPhaseGroupsId?: string | null,
  } | null,
};

export type OnCreatePatientSubscriptionVariables = {
  filter?: ModelSubscriptionPatientFilterInput | null,
};

export type OnCreatePatientSubscription = {
  onCreatePatient?:  {
    __typename: "Patient",
    id: string,
    groupId: string,
    group?:  {
      __typename: "Group",
      id: string,
      studyPhaseId: string,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      type?: string | null,
      groupName: string,
      s3Path?: string | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseGroupsId?: string | null,
    } | null,
    timepoints?:  {
      __typename: "ModelTimepointConnection",
      items:  Array< {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    patientStudyPhaseId: string,
    patientStudyId: string,
    s3Path?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    groupPatientsId?: string | null,
  } | null,
};

export type OnUpdatePatientSubscriptionVariables = {
  filter?: ModelSubscriptionPatientFilterInput | null,
};

export type OnUpdatePatientSubscription = {
  onUpdatePatient?:  {
    __typename: "Patient",
    id: string,
    groupId: string,
    group?:  {
      __typename: "Group",
      id: string,
      studyPhaseId: string,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      type?: string | null,
      groupName: string,
      s3Path?: string | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseGroupsId?: string | null,
    } | null,
    timepoints?:  {
      __typename: "ModelTimepointConnection",
      items:  Array< {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    patientStudyPhaseId: string,
    patientStudyId: string,
    s3Path?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    groupPatientsId?: string | null,
  } | null,
};

export type OnDeletePatientSubscriptionVariables = {
  filter?: ModelSubscriptionPatientFilterInput | null,
};

export type OnDeletePatientSubscription = {
  onDeletePatient?:  {
    __typename: "Patient",
    id: string,
    groupId: string,
    group?:  {
      __typename: "Group",
      id: string,
      studyPhaseId: string,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      type?: string | null,
      groupName: string,
      s3Path?: string | null,
      patients?:  {
        __typename: "ModelPatientConnection",
        nextToken?: string | null,
      } | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseGroupsId?: string | null,
    } | null,
    timepoints?:  {
      __typename: "ModelTimepointConnection",
      items:  Array< {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    patientStudyPhaseId: string,
    patientStudyId: string,
    s3Path?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    groupPatientsId?: string | null,
  } | null,
};

export type OnCreateTimepointSubscriptionVariables = {
  filter?: ModelSubscriptionTimepointFilterInput | null,
};

export type OnCreateTimepointSubscription = {
  onCreateTimepoint?:  {
    __typename: "Timepoint",
    id: string,
    patientId: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      groupId: string,
      group?:  {
        __typename: "Group",
        id: string,
        studyPhaseId: string,
        type?: string | null,
        groupName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseGroupsId?: string | null,
      } | null,
      timepoints?:  {
        __typename: "ModelTimepointConnection",
        nextToken?: string | null,
      } | null,
      patientStudyPhaseId: string,
      patientStudyId: string,
      s3Path?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      groupPatientsId?: string | null,
    } | null,
    samples?:  {
      __typename: "ModelSampleConnection",
      items:  Array< {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    timepointName: string,
    s3Path?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    patientTimepointsId?: string | null,
  } | null,
};

export type OnUpdateTimepointSubscriptionVariables = {
  filter?: ModelSubscriptionTimepointFilterInput | null,
};

export type OnUpdateTimepointSubscription = {
  onUpdateTimepoint?:  {
    __typename: "Timepoint",
    id: string,
    patientId: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      groupId: string,
      group?:  {
        __typename: "Group",
        id: string,
        studyPhaseId: string,
        type?: string | null,
        groupName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseGroupsId?: string | null,
      } | null,
      timepoints?:  {
        __typename: "ModelTimepointConnection",
        nextToken?: string | null,
      } | null,
      patientStudyPhaseId: string,
      patientStudyId: string,
      s3Path?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      groupPatientsId?: string | null,
    } | null,
    samples?:  {
      __typename: "ModelSampleConnection",
      items:  Array< {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    timepointName: string,
    s3Path?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    patientTimepointsId?: string | null,
  } | null,
};

export type OnDeleteTimepointSubscriptionVariables = {
  filter?: ModelSubscriptionTimepointFilterInput | null,
};

export type OnDeleteTimepointSubscription = {
  onDeleteTimepoint?:  {
    __typename: "Timepoint",
    id: string,
    patientId: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      groupId: string,
      group?:  {
        __typename: "Group",
        id: string,
        studyPhaseId: string,
        type?: string | null,
        groupName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseGroupsId?: string | null,
      } | null,
      timepoints?:  {
        __typename: "ModelTimepointConnection",
        nextToken?: string | null,
      } | null,
      patientStudyPhaseId: string,
      patientStudyId: string,
      s3Path?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      groupPatientsId?: string | null,
    } | null,
    samples?:  {
      __typename: "ModelSampleConnection",
      items:  Array< {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    timepointName: string,
    s3Path?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    patientTimepointsId?: string | null,
  } | null,
};

export type OnCreateSampleSubscriptionVariables = {
  filter?: ModelSubscriptionSampleFilterInput | null,
};

export type OnCreateSampleSubscription = {
  onCreateSample?:  {
    __typename: "Sample",
    id: string,
    timepointId?: string | null,
    timepoint?:  {
      __typename: "Timepoint",
      id: string,
      patientId: string,
      patient?:  {
        __typename: "Patient",
        id: string,
        groupId: string,
        patientStudyPhaseId: string,
        patientStudyId: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        groupPatientsId?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      timepointName: string,
      s3Path?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      patientTimepointsId?: string | null,
    } | null,
    studyPhaseId?: string | null,
    StudyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    BatchId?: string | null,
    Batch?:  {
      __typename: "Batch",
      id: string,
      batchName: string,
      batchAnnotations?: string | null,
      batchGroups?: string | null,
      annotations: string,
      controlSamples: string,
      filters: string,
      description?: string | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyBatchesId?: string | null,
      studyPhaseBatchesId?: string | null,
    } | null,
    metadataImmunopeptidomicsList?:  {
      __typename: "ModelMetadataImmunopeptidomicsConnection",
      items:  Array< {
        __typename: "MetadataImmunopeptidomics",
        id: string,
        sampleId: string,
        metaDataTemplateS3Path: string,
        species: string,
        subtypeOfAnalysis?: string | null,
        sampleType: string,
        qcStatus: QcStatus,
        glacierBackup: boolean,
        rawDataS3: boolean,
        tumorVolume?: number | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        isControl: boolean,
        barcodesMappedToControl?: Array< string | null > | null,
        barcode: string,
        s3Path: Array< string | null >,
        sampleMetadataImmunopeptidomicsListId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    MetadataNanoStringList?:  {
      __typename: "ModelMetadataNanoStringConnection",
      items:  Array< {
        __typename: "MetadataNanoString",
        id: string,
        sampleId: string,
        metaDataTemplateS3Path: string,
        subtypeOfAnalysis?: string | null,
        species: string,
        sampleType: string,
        qcStatus: QcStatus,
        glacierBackup: boolean,
        rawDataS3: boolean,
        tumorVolume?: number | null,
        dv200Percentage?: number | null,
        concNanoGramPerMicroL?: number | null,
        rnaMassNanoGram?: number | null,
        rnaVolumeMicroL?: number | null,
        rin?: number | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        isControl: boolean,
        barcodesMappedToControl?: Array< string | null > | null,
        barcode: string,
        s3Path: Array< string | null >,
        sampleMetadataNanoStringListId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    MetadataRnaSeqList?:  {
      __typename: "ModelMetadataRnaSeqConnection",
      items:  Array< {
        __typename: "MetadataRnaSeq",
        id: string,
        sampleId: string,
        metaDataTemplateS3Path: string,
        subtypeOfAnalysis?: string | null,
        species: string,
        sampleType: string,
        qcStatus: QcStatus,
        glacierBackup: boolean,
        rawDataS3: boolean,
        tumorVolume?: number | null,
        sequenceType: SequenceType,
        strandSpecific: string,
        UMI: boolean,
        adaptorsRemoved: boolean,
        adaptorsS3Path?: string | null,
        indexSequences?: Array< string | null > | null,
        concNanoGramPerMicroL?: number | null,
        rnaMassNanoGram?: number | null,
        rnaVolumeMicroL?: number | null,
        rin?: number | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        isControl: boolean,
        barcodesMappedToControl?: Array< string | null > | null,
        barcode: string,
        s3Path: Array< string | null >,
        sampleMetadataRnaSeqListId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    AuxiliaryAnalysislList?:  {
      __typename: "ModelAuxiliaryAnalysisConnection",
      items:  Array< {
        __typename: "AuxiliaryAnalysis",
        id: string,
        sampleId: string,
        auxiliaryFileS3Path: string,
        vendorId: string,
        specimenType: string,
        parameters: string,
        collectionDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        sampleAuxiliaryAnalysislListId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    domainSampleId: string,
    experimentSpecificVariables?: string | null,
    collectionDate?: string | null,
    processingDate?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyPhaseSamplesId?: string | null,
    timepointSamplesId?: string | null,
    batchSamplesId?: string | null,
  } | null,
};

export type OnUpdateSampleSubscriptionVariables = {
  filter?: ModelSubscriptionSampleFilterInput | null,
};

export type OnUpdateSampleSubscription = {
  onUpdateSample?:  {
    __typename: "Sample",
    id: string,
    timepointId?: string | null,
    timepoint?:  {
      __typename: "Timepoint",
      id: string,
      patientId: string,
      patient?:  {
        __typename: "Patient",
        id: string,
        groupId: string,
        patientStudyPhaseId: string,
        patientStudyId: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        groupPatientsId?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      timepointName: string,
      s3Path?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      patientTimepointsId?: string | null,
    } | null,
    studyPhaseId?: string | null,
    StudyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    BatchId?: string | null,
    Batch?:  {
      __typename: "Batch",
      id: string,
      batchName: string,
      batchAnnotations?: string | null,
      batchGroups?: string | null,
      annotations: string,
      controlSamples: string,
      filters: string,
      description?: string | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyBatchesId?: string | null,
      studyPhaseBatchesId?: string | null,
    } | null,
    metadataImmunopeptidomicsList?:  {
      __typename: "ModelMetadataImmunopeptidomicsConnection",
      items:  Array< {
        __typename: "MetadataImmunopeptidomics",
        id: string,
        sampleId: string,
        metaDataTemplateS3Path: string,
        species: string,
        subtypeOfAnalysis?: string | null,
        sampleType: string,
        qcStatus: QcStatus,
        glacierBackup: boolean,
        rawDataS3: boolean,
        tumorVolume?: number | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        isControl: boolean,
        barcodesMappedToControl?: Array< string | null > | null,
        barcode: string,
        s3Path: Array< string | null >,
        sampleMetadataImmunopeptidomicsListId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    MetadataNanoStringList?:  {
      __typename: "ModelMetadataNanoStringConnection",
      items:  Array< {
        __typename: "MetadataNanoString",
        id: string,
        sampleId: string,
        metaDataTemplateS3Path: string,
        subtypeOfAnalysis?: string | null,
        species: string,
        sampleType: string,
        qcStatus: QcStatus,
        glacierBackup: boolean,
        rawDataS3: boolean,
        tumorVolume?: number | null,
        dv200Percentage?: number | null,
        concNanoGramPerMicroL?: number | null,
        rnaMassNanoGram?: number | null,
        rnaVolumeMicroL?: number | null,
        rin?: number | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        isControl: boolean,
        barcodesMappedToControl?: Array< string | null > | null,
        barcode: string,
        s3Path: Array< string | null >,
        sampleMetadataNanoStringListId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    MetadataRnaSeqList?:  {
      __typename: "ModelMetadataRnaSeqConnection",
      items:  Array< {
        __typename: "MetadataRnaSeq",
        id: string,
        sampleId: string,
        metaDataTemplateS3Path: string,
        subtypeOfAnalysis?: string | null,
        species: string,
        sampleType: string,
        qcStatus: QcStatus,
        glacierBackup: boolean,
        rawDataS3: boolean,
        tumorVolume?: number | null,
        sequenceType: SequenceType,
        strandSpecific: string,
        UMI: boolean,
        adaptorsRemoved: boolean,
        adaptorsS3Path?: string | null,
        indexSequences?: Array< string | null > | null,
        concNanoGramPerMicroL?: number | null,
        rnaMassNanoGram?: number | null,
        rnaVolumeMicroL?: number | null,
        rin?: number | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        isControl: boolean,
        barcodesMappedToControl?: Array< string | null > | null,
        barcode: string,
        s3Path: Array< string | null >,
        sampleMetadataRnaSeqListId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    AuxiliaryAnalysislList?:  {
      __typename: "ModelAuxiliaryAnalysisConnection",
      items:  Array< {
        __typename: "AuxiliaryAnalysis",
        id: string,
        sampleId: string,
        auxiliaryFileS3Path: string,
        vendorId: string,
        specimenType: string,
        parameters: string,
        collectionDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        sampleAuxiliaryAnalysislListId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    domainSampleId: string,
    experimentSpecificVariables?: string | null,
    collectionDate?: string | null,
    processingDate?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyPhaseSamplesId?: string | null,
    timepointSamplesId?: string | null,
    batchSamplesId?: string | null,
  } | null,
};

export type OnDeleteSampleSubscriptionVariables = {
  filter?: ModelSubscriptionSampleFilterInput | null,
};

export type OnDeleteSampleSubscription = {
  onDeleteSample?:  {
    __typename: "Sample",
    id: string,
    timepointId?: string | null,
    timepoint?:  {
      __typename: "Timepoint",
      id: string,
      patientId: string,
      patient?:  {
        __typename: "Patient",
        id: string,
        groupId: string,
        patientStudyPhaseId: string,
        patientStudyId: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        groupPatientsId?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      timepointName: string,
      s3Path?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      patientTimepointsId?: string | null,
    } | null,
    studyPhaseId?: string | null,
    StudyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    BatchId?: string | null,
    Batch?:  {
      __typename: "Batch",
      id: string,
      batchName: string,
      batchAnnotations?: string | null,
      batchGroups?: string | null,
      annotations: string,
      controlSamples: string,
      filters: string,
      description?: string | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyBatchesId?: string | null,
      studyPhaseBatchesId?: string | null,
    } | null,
    metadataImmunopeptidomicsList?:  {
      __typename: "ModelMetadataImmunopeptidomicsConnection",
      items:  Array< {
        __typename: "MetadataImmunopeptidomics",
        id: string,
        sampleId: string,
        metaDataTemplateS3Path: string,
        species: string,
        subtypeOfAnalysis?: string | null,
        sampleType: string,
        qcStatus: QcStatus,
        glacierBackup: boolean,
        rawDataS3: boolean,
        tumorVolume?: number | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        isControl: boolean,
        barcodesMappedToControl?: Array< string | null > | null,
        barcode: string,
        s3Path: Array< string | null >,
        sampleMetadataImmunopeptidomicsListId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    MetadataNanoStringList?:  {
      __typename: "ModelMetadataNanoStringConnection",
      items:  Array< {
        __typename: "MetadataNanoString",
        id: string,
        sampleId: string,
        metaDataTemplateS3Path: string,
        subtypeOfAnalysis?: string | null,
        species: string,
        sampleType: string,
        qcStatus: QcStatus,
        glacierBackup: boolean,
        rawDataS3: boolean,
        tumorVolume?: number | null,
        dv200Percentage?: number | null,
        concNanoGramPerMicroL?: number | null,
        rnaMassNanoGram?: number | null,
        rnaVolumeMicroL?: number | null,
        rin?: number | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        isControl: boolean,
        barcodesMappedToControl?: Array< string | null > | null,
        barcode: string,
        s3Path: Array< string | null >,
        sampleMetadataNanoStringListId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    MetadataRnaSeqList?:  {
      __typename: "ModelMetadataRnaSeqConnection",
      items:  Array< {
        __typename: "MetadataRnaSeq",
        id: string,
        sampleId: string,
        metaDataTemplateS3Path: string,
        subtypeOfAnalysis?: string | null,
        species: string,
        sampleType: string,
        qcStatus: QcStatus,
        glacierBackup: boolean,
        rawDataS3: boolean,
        tumorVolume?: number | null,
        sequenceType: SequenceType,
        strandSpecific: string,
        UMI: boolean,
        adaptorsRemoved: boolean,
        adaptorsS3Path?: string | null,
        indexSequences?: Array< string | null > | null,
        concNanoGramPerMicroL?: number | null,
        rnaMassNanoGram?: number | null,
        rnaVolumeMicroL?: number | null,
        rin?: number | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        isControl: boolean,
        barcodesMappedToControl?: Array< string | null > | null,
        barcode: string,
        s3Path: Array< string | null >,
        sampleMetadataRnaSeqListId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    AuxiliaryAnalysislList?:  {
      __typename: "ModelAuxiliaryAnalysisConnection",
      items:  Array< {
        __typename: "AuxiliaryAnalysis",
        id: string,
        sampleId: string,
        auxiliaryFileS3Path: string,
        vendorId: string,
        specimenType: string,
        parameters: string,
        collectionDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        sampleAuxiliaryAnalysislListId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    domainSampleId: string,
    experimentSpecificVariables?: string | null,
    collectionDate?: string | null,
    processingDate?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyPhaseSamplesId?: string | null,
    timepointSamplesId?: string | null,
    batchSamplesId?: string | null,
  } | null,
};

export type OnCreateBatchSubscriptionVariables = {
  filter?: ModelSubscriptionBatchFilterInput | null,
};

export type OnCreateBatchSubscription = {
  onCreateBatch?:  {
    __typename: "Batch",
    id: string,
    batchName: string,
    batchAnnotations?: string | null,
    batchGroups?: string | null,
    annotations: string,
    controlSamples: string,
    filters: string,
    description?: string | null,
    samples?:  {
      __typename: "ModelSampleConnection",
      items:  Array< {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pipelines?:  {
      __typename: "ModelPipelineConnection",
      items:  Array< {
        __typename: "Pipeline",
        id: string,
        name: string,
        status?: pipelineStatus | null,
        description?: string | null,
        s3Event?: string | null,
        s3ParamsFile?: string | null,
        s3Results?: string | null,
        visFileInfoS3path?: string | null,
        s3NfLog?: string | null,
        s3NfTasksLogs?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis?: string | null,
        parameters: string,
        studyId?: string | null,
        studyPhaseId?: string | null,
        scheduledPipeline: boolean,
        scheduledDateTime?: string | null,
        launchablePipelineId: string,
        batchId: string,
        runId?: string | null,
        userEmail: string,
        pipelineStarted?: string | null,
        pipelineCompleted?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPipelinesId?: string | null,
        studyPhasePipelinesId?: string | null,
        batchPipelinesId?: string | null,
        launchablePipelinesPipelinesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    studyId: string,
    study?:  {
      __typename: "Study",
      id: string,
      studyName: string,
      organizationId: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        organizationName: string,
        organizationCode?: string | null,
        active: boolean,
        notificationEmail: string,
        description: string,
        organizationAnswers?: string | null,
        url: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        created: string,
        updated: string,
      } | null,
      studyPhases?:  {
        __typename: "ModelStudyPhaseConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      description: string,
      studyCode: string,
      studyAnswers?: string | null,
      s3Path?: string | null,
      croGroups?: Array< string | null > | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      organizationStudiesId?: string | null,
    } | null,
    studyPhaseId?: string | null,
    studyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyBatchesId?: string | null,
    studyPhaseBatchesId?: string | null,
  } | null,
};

export type OnUpdateBatchSubscriptionVariables = {
  filter?: ModelSubscriptionBatchFilterInput | null,
};

export type OnUpdateBatchSubscription = {
  onUpdateBatch?:  {
    __typename: "Batch",
    id: string,
    batchName: string,
    batchAnnotations?: string | null,
    batchGroups?: string | null,
    annotations: string,
    controlSamples: string,
    filters: string,
    description?: string | null,
    samples?:  {
      __typename: "ModelSampleConnection",
      items:  Array< {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pipelines?:  {
      __typename: "ModelPipelineConnection",
      items:  Array< {
        __typename: "Pipeline",
        id: string,
        name: string,
        status?: pipelineStatus | null,
        description?: string | null,
        s3Event?: string | null,
        s3ParamsFile?: string | null,
        s3Results?: string | null,
        visFileInfoS3path?: string | null,
        s3NfLog?: string | null,
        s3NfTasksLogs?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis?: string | null,
        parameters: string,
        studyId?: string | null,
        studyPhaseId?: string | null,
        scheduledPipeline: boolean,
        scheduledDateTime?: string | null,
        launchablePipelineId: string,
        batchId: string,
        runId?: string | null,
        userEmail: string,
        pipelineStarted?: string | null,
        pipelineCompleted?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPipelinesId?: string | null,
        studyPhasePipelinesId?: string | null,
        batchPipelinesId?: string | null,
        launchablePipelinesPipelinesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    studyId: string,
    study?:  {
      __typename: "Study",
      id: string,
      studyName: string,
      organizationId: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        organizationName: string,
        organizationCode?: string | null,
        active: boolean,
        notificationEmail: string,
        description: string,
        organizationAnswers?: string | null,
        url: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        created: string,
        updated: string,
      } | null,
      studyPhases?:  {
        __typename: "ModelStudyPhaseConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      description: string,
      studyCode: string,
      studyAnswers?: string | null,
      s3Path?: string | null,
      croGroups?: Array< string | null > | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      organizationStudiesId?: string | null,
    } | null,
    studyPhaseId?: string | null,
    studyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyBatchesId?: string | null,
    studyPhaseBatchesId?: string | null,
  } | null,
};

export type OnDeleteBatchSubscriptionVariables = {
  filter?: ModelSubscriptionBatchFilterInput | null,
};

export type OnDeleteBatchSubscription = {
  onDeleteBatch?:  {
    __typename: "Batch",
    id: string,
    batchName: string,
    batchAnnotations?: string | null,
    batchGroups?: string | null,
    annotations: string,
    controlSamples: string,
    filters: string,
    description?: string | null,
    samples?:  {
      __typename: "ModelSampleConnection",
      items:  Array< {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pipelines?:  {
      __typename: "ModelPipelineConnection",
      items:  Array< {
        __typename: "Pipeline",
        id: string,
        name: string,
        status?: pipelineStatus | null,
        description?: string | null,
        s3Event?: string | null,
        s3ParamsFile?: string | null,
        s3Results?: string | null,
        visFileInfoS3path?: string | null,
        s3NfLog?: string | null,
        s3NfTasksLogs?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis?: string | null,
        parameters: string,
        studyId?: string | null,
        studyPhaseId?: string | null,
        scheduledPipeline: boolean,
        scheduledDateTime?: string | null,
        launchablePipelineId: string,
        batchId: string,
        runId?: string | null,
        userEmail: string,
        pipelineStarted?: string | null,
        pipelineCompleted?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPipelinesId?: string | null,
        studyPhasePipelinesId?: string | null,
        batchPipelinesId?: string | null,
        launchablePipelinesPipelinesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    studyId: string,
    study?:  {
      __typename: "Study",
      id: string,
      studyName: string,
      organizationId: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        organizationName: string,
        organizationCode?: string | null,
        active: boolean,
        notificationEmail: string,
        description: string,
        organizationAnswers?: string | null,
        url: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        created: string,
        updated: string,
      } | null,
      studyPhases?:  {
        __typename: "ModelStudyPhaseConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      description: string,
      studyCode: string,
      studyAnswers?: string | null,
      s3Path?: string | null,
      croGroups?: Array< string | null > | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      organizationStudiesId?: string | null,
    } | null,
    studyPhaseId?: string | null,
    studyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyBatchesId?: string | null,
    studyPhaseBatchesId?: string | null,
  } | null,
};

export type OnCreatePipelineSubscriptionVariables = {
  filter?: ModelSubscriptionPipelineFilterInput | null,
};

export type OnCreatePipelineSubscription = {
  onCreatePipeline?:  {
    __typename: "Pipeline",
    id: string,
    name: string,
    status?: pipelineStatus | null,
    description?: string | null,
    s3Event?: string | null,
    s3ParamsFile?: string | null,
    s3Results?: string | null,
    visFileInfoS3path?: string | null,
    s3NfLog?: string | null,
    s3NfTasksLogs?: Array< string | null > | null,
    analysisType: AnalysisType,
    subtypeOfAnalysis?: string | null,
    parameters: string,
    studyId?: string | null,
    study?:  {
      __typename: "Study",
      id: string,
      studyName: string,
      organizationId: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        organizationName: string,
        organizationCode?: string | null,
        active: boolean,
        notificationEmail: string,
        description: string,
        organizationAnswers?: string | null,
        url: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        created: string,
        updated: string,
      } | null,
      studyPhases?:  {
        __typename: "ModelStudyPhaseConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      description: string,
      studyCode: string,
      studyAnswers?: string | null,
      s3Path?: string | null,
      croGroups?: Array< string | null > | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      organizationStudiesId?: string | null,
    } | null,
    studyPhaseId?: string | null,
    studyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    scheduledPipeline: boolean,
    scheduledDateTime?: string | null,
    launchablePipelineId: string,
    launchablePipeline?:  {
      __typename: "LaunchablePipelines",
      id: string,
      pipelineName?: string | null,
      organizations?:  {
        __typename: "ModelOrganizationLaunchablePipelinesConnection",
        nextToken?: string | null,
      } | null,
      pipelineVersion?: string | null,
      parameters?: string | null,
      description?: string | null,
      inputFileInfo?: string | null,
      supportedAnalyses?: Array< AnalysisType | null > | null,
      supportedSubtypeOfAnalyses?: Array< string | null > | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      gitRepo?: string | null,
      workflowId?: string | null,
      created: string,
      updated: string,
    } | null,
    batchId: string,
    batch?:  {
      __typename: "Batch",
      id: string,
      batchName: string,
      batchAnnotations?: string | null,
      batchGroups?: string | null,
      annotations: string,
      controlSamples: string,
      filters: string,
      description?: string | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyBatchesId?: string | null,
      studyPhaseBatchesId?: string | null,
    } | null,
    runId?: string | null,
    userEmail: string,
    pipelineStarted?: string | null,
    pipelineCompleted?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyPipelinesId?: string | null,
    studyPhasePipelinesId?: string | null,
    batchPipelinesId?: string | null,
    launchablePipelinesPipelinesId?: string | null,
  } | null,
};

export type OnUpdatePipelineSubscriptionVariables = {
  filter?: ModelSubscriptionPipelineFilterInput | null,
};

export type OnUpdatePipelineSubscription = {
  onUpdatePipeline?:  {
    __typename: "Pipeline",
    id: string,
    name: string,
    status?: pipelineStatus | null,
    description?: string | null,
    s3Event?: string | null,
    s3ParamsFile?: string | null,
    s3Results?: string | null,
    visFileInfoS3path?: string | null,
    s3NfLog?: string | null,
    s3NfTasksLogs?: Array< string | null > | null,
    analysisType: AnalysisType,
    subtypeOfAnalysis?: string | null,
    parameters: string,
    studyId?: string | null,
    study?:  {
      __typename: "Study",
      id: string,
      studyName: string,
      organizationId: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        organizationName: string,
        organizationCode?: string | null,
        active: boolean,
        notificationEmail: string,
        description: string,
        organizationAnswers?: string | null,
        url: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        created: string,
        updated: string,
      } | null,
      studyPhases?:  {
        __typename: "ModelStudyPhaseConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      description: string,
      studyCode: string,
      studyAnswers?: string | null,
      s3Path?: string | null,
      croGroups?: Array< string | null > | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      organizationStudiesId?: string | null,
    } | null,
    studyPhaseId?: string | null,
    studyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    scheduledPipeline: boolean,
    scheduledDateTime?: string | null,
    launchablePipelineId: string,
    launchablePipeline?:  {
      __typename: "LaunchablePipelines",
      id: string,
      pipelineName?: string | null,
      organizations?:  {
        __typename: "ModelOrganizationLaunchablePipelinesConnection",
        nextToken?: string | null,
      } | null,
      pipelineVersion?: string | null,
      parameters?: string | null,
      description?: string | null,
      inputFileInfo?: string | null,
      supportedAnalyses?: Array< AnalysisType | null > | null,
      supportedSubtypeOfAnalyses?: Array< string | null > | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      gitRepo?: string | null,
      workflowId?: string | null,
      created: string,
      updated: string,
    } | null,
    batchId: string,
    batch?:  {
      __typename: "Batch",
      id: string,
      batchName: string,
      batchAnnotations?: string | null,
      batchGroups?: string | null,
      annotations: string,
      controlSamples: string,
      filters: string,
      description?: string | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyBatchesId?: string | null,
      studyPhaseBatchesId?: string | null,
    } | null,
    runId?: string | null,
    userEmail: string,
    pipelineStarted?: string | null,
    pipelineCompleted?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyPipelinesId?: string | null,
    studyPhasePipelinesId?: string | null,
    batchPipelinesId?: string | null,
    launchablePipelinesPipelinesId?: string | null,
  } | null,
};

export type OnDeletePipelineSubscriptionVariables = {
  filter?: ModelSubscriptionPipelineFilterInput | null,
};

export type OnDeletePipelineSubscription = {
  onDeletePipeline?:  {
    __typename: "Pipeline",
    id: string,
    name: string,
    status?: pipelineStatus | null,
    description?: string | null,
    s3Event?: string | null,
    s3ParamsFile?: string | null,
    s3Results?: string | null,
    visFileInfoS3path?: string | null,
    s3NfLog?: string | null,
    s3NfTasksLogs?: Array< string | null > | null,
    analysisType: AnalysisType,
    subtypeOfAnalysis?: string | null,
    parameters: string,
    studyId?: string | null,
    study?:  {
      __typename: "Study",
      id: string,
      studyName: string,
      organizationId: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        organizationName: string,
        organizationCode?: string | null,
        active: boolean,
        notificationEmail: string,
        description: string,
        organizationAnswers?: string | null,
        url: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        created: string,
        updated: string,
      } | null,
      studyPhases?:  {
        __typename: "ModelStudyPhaseConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      description: string,
      studyCode: string,
      studyAnswers?: string | null,
      s3Path?: string | null,
      croGroups?: Array< string | null > | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      organizationStudiesId?: string | null,
    } | null,
    studyPhaseId?: string | null,
    studyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    scheduledPipeline: boolean,
    scheduledDateTime?: string | null,
    launchablePipelineId: string,
    launchablePipeline?:  {
      __typename: "LaunchablePipelines",
      id: string,
      pipelineName?: string | null,
      organizations?:  {
        __typename: "ModelOrganizationLaunchablePipelinesConnection",
        nextToken?: string | null,
      } | null,
      pipelineVersion?: string | null,
      parameters?: string | null,
      description?: string | null,
      inputFileInfo?: string | null,
      supportedAnalyses?: Array< AnalysisType | null > | null,
      supportedSubtypeOfAnalyses?: Array< string | null > | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      gitRepo?: string | null,
      workflowId?: string | null,
      created: string,
      updated: string,
    } | null,
    batchId: string,
    batch?:  {
      __typename: "Batch",
      id: string,
      batchName: string,
      batchAnnotations?: string | null,
      batchGroups?: string | null,
      annotations: string,
      controlSamples: string,
      filters: string,
      description?: string | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyBatchesId?: string | null,
      studyPhaseBatchesId?: string | null,
    } | null,
    runId?: string | null,
    userEmail: string,
    pipelineStarted?: string | null,
    pipelineCompleted?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    studyPipelinesId?: string | null,
    studyPhasePipelinesId?: string | null,
    batchPipelinesId?: string | null,
    launchablePipelinesPipelinesId?: string | null,
  } | null,
};

export type OnCreateAuxiliaryAnalysisSubscriptionVariables = {
  filter?: ModelSubscriptionAuxiliaryAnalysisFilterInput | null,
};

export type OnCreateAuxiliaryAnalysisSubscription = {
  onCreateAuxiliaryAnalysis?:  {
    __typename: "AuxiliaryAnalysis",
    id: string,
    sampleId: string,
    sample?:  {
      __typename: "Sample",
      id: string,
      timepointId?: string | null,
      timepoint?:  {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      StudyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      BatchId?: string | null,
      Batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      metadataImmunopeptidomicsList?:  {
        __typename: "ModelMetadataImmunopeptidomicsConnection",
        nextToken?: string | null,
      } | null,
      MetadataNanoStringList?:  {
        __typename: "ModelMetadataNanoStringConnection",
        nextToken?: string | null,
      } | null,
      MetadataRnaSeqList?:  {
        __typename: "ModelMetadataRnaSeqConnection",
        nextToken?: string | null,
      } | null,
      AuxiliaryAnalysislList?:  {
        __typename: "ModelAuxiliaryAnalysisConnection",
        nextToken?: string | null,
      } | null,
      domainSampleId: string,
      experimentSpecificVariables?: string | null,
      collectionDate?: string | null,
      processingDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseSamplesId?: string | null,
      timepointSamplesId?: string | null,
      batchSamplesId?: string | null,
    } | null,
    auxiliaryFileS3Path: string,
    vendorId: string,
    specimenType: string,
    parameters: string,
    collectionDate?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    sampleAuxiliaryAnalysislListId?: string | null,
  } | null,
};

export type OnUpdateAuxiliaryAnalysisSubscriptionVariables = {
  filter?: ModelSubscriptionAuxiliaryAnalysisFilterInput | null,
};

export type OnUpdateAuxiliaryAnalysisSubscription = {
  onUpdateAuxiliaryAnalysis?:  {
    __typename: "AuxiliaryAnalysis",
    id: string,
    sampleId: string,
    sample?:  {
      __typename: "Sample",
      id: string,
      timepointId?: string | null,
      timepoint?:  {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      StudyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      BatchId?: string | null,
      Batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      metadataImmunopeptidomicsList?:  {
        __typename: "ModelMetadataImmunopeptidomicsConnection",
        nextToken?: string | null,
      } | null,
      MetadataNanoStringList?:  {
        __typename: "ModelMetadataNanoStringConnection",
        nextToken?: string | null,
      } | null,
      MetadataRnaSeqList?:  {
        __typename: "ModelMetadataRnaSeqConnection",
        nextToken?: string | null,
      } | null,
      AuxiliaryAnalysislList?:  {
        __typename: "ModelAuxiliaryAnalysisConnection",
        nextToken?: string | null,
      } | null,
      domainSampleId: string,
      experimentSpecificVariables?: string | null,
      collectionDate?: string | null,
      processingDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseSamplesId?: string | null,
      timepointSamplesId?: string | null,
      batchSamplesId?: string | null,
    } | null,
    auxiliaryFileS3Path: string,
    vendorId: string,
    specimenType: string,
    parameters: string,
    collectionDate?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    sampleAuxiliaryAnalysislListId?: string | null,
  } | null,
};

export type OnDeleteAuxiliaryAnalysisSubscriptionVariables = {
  filter?: ModelSubscriptionAuxiliaryAnalysisFilterInput | null,
};

export type OnDeleteAuxiliaryAnalysisSubscription = {
  onDeleteAuxiliaryAnalysis?:  {
    __typename: "AuxiliaryAnalysis",
    id: string,
    sampleId: string,
    sample?:  {
      __typename: "Sample",
      id: string,
      timepointId?: string | null,
      timepoint?:  {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      StudyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      BatchId?: string | null,
      Batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      metadataImmunopeptidomicsList?:  {
        __typename: "ModelMetadataImmunopeptidomicsConnection",
        nextToken?: string | null,
      } | null,
      MetadataNanoStringList?:  {
        __typename: "ModelMetadataNanoStringConnection",
        nextToken?: string | null,
      } | null,
      MetadataRnaSeqList?:  {
        __typename: "ModelMetadataRnaSeqConnection",
        nextToken?: string | null,
      } | null,
      AuxiliaryAnalysislList?:  {
        __typename: "ModelAuxiliaryAnalysisConnection",
        nextToken?: string | null,
      } | null,
      domainSampleId: string,
      experimentSpecificVariables?: string | null,
      collectionDate?: string | null,
      processingDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseSamplesId?: string | null,
      timepointSamplesId?: string | null,
      batchSamplesId?: string | null,
    } | null,
    auxiliaryFileS3Path: string,
    vendorId: string,
    specimenType: string,
    parameters: string,
    collectionDate?: string | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    sampleAuxiliaryAnalysislListId?: string | null,
  } | null,
};

export type OnCreateMetadataImmunopeptidomicsSubscriptionVariables = {
  filter?: ModelSubscriptionMetadataImmunopeptidomicsFilterInput | null,
};

export type OnCreateMetadataImmunopeptidomicsSubscription = {
  onCreateMetadataImmunopeptidomics?:  {
    __typename: "MetadataImmunopeptidomics",
    id: string,
    sampleId: string,
    sample?:  {
      __typename: "Sample",
      id: string,
      timepointId?: string | null,
      timepoint?:  {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      StudyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      BatchId?: string | null,
      Batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      metadataImmunopeptidomicsList?:  {
        __typename: "ModelMetadataImmunopeptidomicsConnection",
        nextToken?: string | null,
      } | null,
      MetadataNanoStringList?:  {
        __typename: "ModelMetadataNanoStringConnection",
        nextToken?: string | null,
      } | null,
      MetadataRnaSeqList?:  {
        __typename: "ModelMetadataRnaSeqConnection",
        nextToken?: string | null,
      } | null,
      AuxiliaryAnalysislList?:  {
        __typename: "ModelAuxiliaryAnalysisConnection",
        nextToken?: string | null,
      } | null,
      domainSampleId: string,
      experimentSpecificVariables?: string | null,
      collectionDate?: string | null,
      processingDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseSamplesId?: string | null,
      timepointSamplesId?: string | null,
      batchSamplesId?: string | null,
    } | null,
    treatment?:  {
      __typename: "ModelTreatmentConnection",
      items:  Array< {
        __typename: "Treatment",
        id: string,
        metadataImmunopeptidomicsId?: string | null,
        metadataNanoStringId?: string | null,
        metadataRnaSeqId?: string | null,
        treatmentName: string,
        dose: number,
        doseUnit: string,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        metadataImmunopeptidomicsTreatmentId?: string | null,
        metadataNanoStringTreatmentId?: string | null,
        metadataRnaSeqTreatmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    metaDataTemplateS3Path: string,
    species: string,
    subtypeOfAnalysis?: string | null,
    sampleType: string,
    qcStatus: QcStatus,
    glacierBackup: boolean,
    rawDataS3: boolean,
    tumorVolume?: number | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    isControl: boolean,
    barcodesMappedToControl?: Array< string | null > | null,
    barcode: string,
    s3Path: Array< string | null >,
    sampleMetadataImmunopeptidomicsListId?: string | null,
  } | null,
};

export type OnUpdateMetadataImmunopeptidomicsSubscriptionVariables = {
  filter?: ModelSubscriptionMetadataImmunopeptidomicsFilterInput | null,
};

export type OnUpdateMetadataImmunopeptidomicsSubscription = {
  onUpdateMetadataImmunopeptidomics?:  {
    __typename: "MetadataImmunopeptidomics",
    id: string,
    sampleId: string,
    sample?:  {
      __typename: "Sample",
      id: string,
      timepointId?: string | null,
      timepoint?:  {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      StudyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      BatchId?: string | null,
      Batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      metadataImmunopeptidomicsList?:  {
        __typename: "ModelMetadataImmunopeptidomicsConnection",
        nextToken?: string | null,
      } | null,
      MetadataNanoStringList?:  {
        __typename: "ModelMetadataNanoStringConnection",
        nextToken?: string | null,
      } | null,
      MetadataRnaSeqList?:  {
        __typename: "ModelMetadataRnaSeqConnection",
        nextToken?: string | null,
      } | null,
      AuxiliaryAnalysislList?:  {
        __typename: "ModelAuxiliaryAnalysisConnection",
        nextToken?: string | null,
      } | null,
      domainSampleId: string,
      experimentSpecificVariables?: string | null,
      collectionDate?: string | null,
      processingDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseSamplesId?: string | null,
      timepointSamplesId?: string | null,
      batchSamplesId?: string | null,
    } | null,
    treatment?:  {
      __typename: "ModelTreatmentConnection",
      items:  Array< {
        __typename: "Treatment",
        id: string,
        metadataImmunopeptidomicsId?: string | null,
        metadataNanoStringId?: string | null,
        metadataRnaSeqId?: string | null,
        treatmentName: string,
        dose: number,
        doseUnit: string,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        metadataImmunopeptidomicsTreatmentId?: string | null,
        metadataNanoStringTreatmentId?: string | null,
        metadataRnaSeqTreatmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    metaDataTemplateS3Path: string,
    species: string,
    subtypeOfAnalysis?: string | null,
    sampleType: string,
    qcStatus: QcStatus,
    glacierBackup: boolean,
    rawDataS3: boolean,
    tumorVolume?: number | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    isControl: boolean,
    barcodesMappedToControl?: Array< string | null > | null,
    barcode: string,
    s3Path: Array< string | null >,
    sampleMetadataImmunopeptidomicsListId?: string | null,
  } | null,
};

export type OnDeleteMetadataImmunopeptidomicsSubscriptionVariables = {
  filter?: ModelSubscriptionMetadataImmunopeptidomicsFilterInput | null,
};

export type OnDeleteMetadataImmunopeptidomicsSubscription = {
  onDeleteMetadataImmunopeptidomics?:  {
    __typename: "MetadataImmunopeptidomics",
    id: string,
    sampleId: string,
    sample?:  {
      __typename: "Sample",
      id: string,
      timepointId?: string | null,
      timepoint?:  {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      StudyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      BatchId?: string | null,
      Batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      metadataImmunopeptidomicsList?:  {
        __typename: "ModelMetadataImmunopeptidomicsConnection",
        nextToken?: string | null,
      } | null,
      MetadataNanoStringList?:  {
        __typename: "ModelMetadataNanoStringConnection",
        nextToken?: string | null,
      } | null,
      MetadataRnaSeqList?:  {
        __typename: "ModelMetadataRnaSeqConnection",
        nextToken?: string | null,
      } | null,
      AuxiliaryAnalysislList?:  {
        __typename: "ModelAuxiliaryAnalysisConnection",
        nextToken?: string | null,
      } | null,
      domainSampleId: string,
      experimentSpecificVariables?: string | null,
      collectionDate?: string | null,
      processingDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseSamplesId?: string | null,
      timepointSamplesId?: string | null,
      batchSamplesId?: string | null,
    } | null,
    treatment?:  {
      __typename: "ModelTreatmentConnection",
      items:  Array< {
        __typename: "Treatment",
        id: string,
        metadataImmunopeptidomicsId?: string | null,
        metadataNanoStringId?: string | null,
        metadataRnaSeqId?: string | null,
        treatmentName: string,
        dose: number,
        doseUnit: string,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        metadataImmunopeptidomicsTreatmentId?: string | null,
        metadataNanoStringTreatmentId?: string | null,
        metadataRnaSeqTreatmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    metaDataTemplateS3Path: string,
    species: string,
    subtypeOfAnalysis?: string | null,
    sampleType: string,
    qcStatus: QcStatus,
    glacierBackup: boolean,
    rawDataS3: boolean,
    tumorVolume?: number | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    isControl: boolean,
    barcodesMappedToControl?: Array< string | null > | null,
    barcode: string,
    s3Path: Array< string | null >,
    sampleMetadataImmunopeptidomicsListId?: string | null,
  } | null,
};

export type OnCreateMetadataNanoStringSubscriptionVariables = {
  filter?: ModelSubscriptionMetadataNanoStringFilterInput | null,
};

export type OnCreateMetadataNanoStringSubscription = {
  onCreateMetadataNanoString?:  {
    __typename: "MetadataNanoString",
    id: string,
    sampleId: string,
    sample?:  {
      __typename: "Sample",
      id: string,
      timepointId?: string | null,
      timepoint?:  {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      StudyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      BatchId?: string | null,
      Batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      metadataImmunopeptidomicsList?:  {
        __typename: "ModelMetadataImmunopeptidomicsConnection",
        nextToken?: string | null,
      } | null,
      MetadataNanoStringList?:  {
        __typename: "ModelMetadataNanoStringConnection",
        nextToken?: string | null,
      } | null,
      MetadataRnaSeqList?:  {
        __typename: "ModelMetadataRnaSeqConnection",
        nextToken?: string | null,
      } | null,
      AuxiliaryAnalysislList?:  {
        __typename: "ModelAuxiliaryAnalysisConnection",
        nextToken?: string | null,
      } | null,
      domainSampleId: string,
      experimentSpecificVariables?: string | null,
      collectionDate?: string | null,
      processingDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseSamplesId?: string | null,
      timepointSamplesId?: string | null,
      batchSamplesId?: string | null,
    } | null,
    treatment?:  {
      __typename: "ModelTreatmentConnection",
      items:  Array< {
        __typename: "Treatment",
        id: string,
        metadataImmunopeptidomicsId?: string | null,
        metadataNanoStringId?: string | null,
        metadataRnaSeqId?: string | null,
        treatmentName: string,
        dose: number,
        doseUnit: string,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        metadataImmunopeptidomicsTreatmentId?: string | null,
        metadataNanoStringTreatmentId?: string | null,
        metadataRnaSeqTreatmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    metaDataTemplateS3Path: string,
    subtypeOfAnalysis?: string | null,
    species: string,
    sampleType: string,
    qcStatus: QcStatus,
    glacierBackup: boolean,
    rawDataS3: boolean,
    tumorVolume?: number | null,
    dv200Percentage?: number | null,
    concNanoGramPerMicroL?: number | null,
    rnaMassNanoGram?: number | null,
    rnaVolumeMicroL?: number | null,
    rin?: number | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    isControl: boolean,
    barcodesMappedToControl?: Array< string | null > | null,
    barcode: string,
    s3Path: Array< string | null >,
    sampleMetadataNanoStringListId?: string | null,
  } | null,
};

export type OnUpdateMetadataNanoStringSubscriptionVariables = {
  filter?: ModelSubscriptionMetadataNanoStringFilterInput | null,
};

export type OnUpdateMetadataNanoStringSubscription = {
  onUpdateMetadataNanoString?:  {
    __typename: "MetadataNanoString",
    id: string,
    sampleId: string,
    sample?:  {
      __typename: "Sample",
      id: string,
      timepointId?: string | null,
      timepoint?:  {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      StudyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      BatchId?: string | null,
      Batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      metadataImmunopeptidomicsList?:  {
        __typename: "ModelMetadataImmunopeptidomicsConnection",
        nextToken?: string | null,
      } | null,
      MetadataNanoStringList?:  {
        __typename: "ModelMetadataNanoStringConnection",
        nextToken?: string | null,
      } | null,
      MetadataRnaSeqList?:  {
        __typename: "ModelMetadataRnaSeqConnection",
        nextToken?: string | null,
      } | null,
      AuxiliaryAnalysislList?:  {
        __typename: "ModelAuxiliaryAnalysisConnection",
        nextToken?: string | null,
      } | null,
      domainSampleId: string,
      experimentSpecificVariables?: string | null,
      collectionDate?: string | null,
      processingDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseSamplesId?: string | null,
      timepointSamplesId?: string | null,
      batchSamplesId?: string | null,
    } | null,
    treatment?:  {
      __typename: "ModelTreatmentConnection",
      items:  Array< {
        __typename: "Treatment",
        id: string,
        metadataImmunopeptidomicsId?: string | null,
        metadataNanoStringId?: string | null,
        metadataRnaSeqId?: string | null,
        treatmentName: string,
        dose: number,
        doseUnit: string,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        metadataImmunopeptidomicsTreatmentId?: string | null,
        metadataNanoStringTreatmentId?: string | null,
        metadataRnaSeqTreatmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    metaDataTemplateS3Path: string,
    subtypeOfAnalysis?: string | null,
    species: string,
    sampleType: string,
    qcStatus: QcStatus,
    glacierBackup: boolean,
    rawDataS3: boolean,
    tumorVolume?: number | null,
    dv200Percentage?: number | null,
    concNanoGramPerMicroL?: number | null,
    rnaMassNanoGram?: number | null,
    rnaVolumeMicroL?: number | null,
    rin?: number | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    isControl: boolean,
    barcodesMappedToControl?: Array< string | null > | null,
    barcode: string,
    s3Path: Array< string | null >,
    sampleMetadataNanoStringListId?: string | null,
  } | null,
};

export type OnDeleteMetadataNanoStringSubscriptionVariables = {
  filter?: ModelSubscriptionMetadataNanoStringFilterInput | null,
};

export type OnDeleteMetadataNanoStringSubscription = {
  onDeleteMetadataNanoString?:  {
    __typename: "MetadataNanoString",
    id: string,
    sampleId: string,
    sample?:  {
      __typename: "Sample",
      id: string,
      timepointId?: string | null,
      timepoint?:  {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      StudyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      BatchId?: string | null,
      Batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      metadataImmunopeptidomicsList?:  {
        __typename: "ModelMetadataImmunopeptidomicsConnection",
        nextToken?: string | null,
      } | null,
      MetadataNanoStringList?:  {
        __typename: "ModelMetadataNanoStringConnection",
        nextToken?: string | null,
      } | null,
      MetadataRnaSeqList?:  {
        __typename: "ModelMetadataRnaSeqConnection",
        nextToken?: string | null,
      } | null,
      AuxiliaryAnalysislList?:  {
        __typename: "ModelAuxiliaryAnalysisConnection",
        nextToken?: string | null,
      } | null,
      domainSampleId: string,
      experimentSpecificVariables?: string | null,
      collectionDate?: string | null,
      processingDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseSamplesId?: string | null,
      timepointSamplesId?: string | null,
      batchSamplesId?: string | null,
    } | null,
    treatment?:  {
      __typename: "ModelTreatmentConnection",
      items:  Array< {
        __typename: "Treatment",
        id: string,
        metadataImmunopeptidomicsId?: string | null,
        metadataNanoStringId?: string | null,
        metadataRnaSeqId?: string | null,
        treatmentName: string,
        dose: number,
        doseUnit: string,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        metadataImmunopeptidomicsTreatmentId?: string | null,
        metadataNanoStringTreatmentId?: string | null,
        metadataRnaSeqTreatmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    metaDataTemplateS3Path: string,
    subtypeOfAnalysis?: string | null,
    species: string,
    sampleType: string,
    qcStatus: QcStatus,
    glacierBackup: boolean,
    rawDataS3: boolean,
    tumorVolume?: number | null,
    dv200Percentage?: number | null,
    concNanoGramPerMicroL?: number | null,
    rnaMassNanoGram?: number | null,
    rnaVolumeMicroL?: number | null,
    rin?: number | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    isControl: boolean,
    barcodesMappedToControl?: Array< string | null > | null,
    barcode: string,
    s3Path: Array< string | null >,
    sampleMetadataNanoStringListId?: string | null,
  } | null,
};

export type OnCreateMetadataRnaSeqSubscriptionVariables = {
  filter?: ModelSubscriptionMetadataRnaSeqFilterInput | null,
};

export type OnCreateMetadataRnaSeqSubscription = {
  onCreateMetadataRnaSeq?:  {
    __typename: "MetadataRnaSeq",
    id: string,
    sampleId: string,
    sample?:  {
      __typename: "Sample",
      id: string,
      timepointId?: string | null,
      timepoint?:  {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      StudyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      BatchId?: string | null,
      Batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      metadataImmunopeptidomicsList?:  {
        __typename: "ModelMetadataImmunopeptidomicsConnection",
        nextToken?: string | null,
      } | null,
      MetadataNanoStringList?:  {
        __typename: "ModelMetadataNanoStringConnection",
        nextToken?: string | null,
      } | null,
      MetadataRnaSeqList?:  {
        __typename: "ModelMetadataRnaSeqConnection",
        nextToken?: string | null,
      } | null,
      AuxiliaryAnalysislList?:  {
        __typename: "ModelAuxiliaryAnalysisConnection",
        nextToken?: string | null,
      } | null,
      domainSampleId: string,
      experimentSpecificVariables?: string | null,
      collectionDate?: string | null,
      processingDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseSamplesId?: string | null,
      timepointSamplesId?: string | null,
      batchSamplesId?: string | null,
    } | null,
    treatment?:  {
      __typename: "ModelTreatmentConnection",
      items:  Array< {
        __typename: "Treatment",
        id: string,
        metadataImmunopeptidomicsId?: string | null,
        metadataNanoStringId?: string | null,
        metadataRnaSeqId?: string | null,
        treatmentName: string,
        dose: number,
        doseUnit: string,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        metadataImmunopeptidomicsTreatmentId?: string | null,
        metadataNanoStringTreatmentId?: string | null,
        metadataRnaSeqTreatmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    metaDataTemplateS3Path: string,
    subtypeOfAnalysis?: string | null,
    species: string,
    sampleType: string,
    qcStatus: QcStatus,
    glacierBackup: boolean,
    rawDataS3: boolean,
    tumorVolume?: number | null,
    sequenceType: SequenceType,
    strandSpecific: string,
    UMI: boolean,
    adaptorsRemoved: boolean,
    adaptorsS3Path?: string | null,
    indexSequences?: Array< string | null > | null,
    concNanoGramPerMicroL?: number | null,
    rnaMassNanoGram?: number | null,
    rnaVolumeMicroL?: number | null,
    rin?: number | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    isControl: boolean,
    barcodesMappedToControl?: Array< string | null > | null,
    barcode: string,
    s3Path: Array< string | null >,
    sampleMetadataRnaSeqListId?: string | null,
  } | null,
};

export type OnUpdateMetadataRnaSeqSubscriptionVariables = {
  filter?: ModelSubscriptionMetadataRnaSeqFilterInput | null,
};

export type OnUpdateMetadataRnaSeqSubscription = {
  onUpdateMetadataRnaSeq?:  {
    __typename: "MetadataRnaSeq",
    id: string,
    sampleId: string,
    sample?:  {
      __typename: "Sample",
      id: string,
      timepointId?: string | null,
      timepoint?:  {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      StudyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      BatchId?: string | null,
      Batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      metadataImmunopeptidomicsList?:  {
        __typename: "ModelMetadataImmunopeptidomicsConnection",
        nextToken?: string | null,
      } | null,
      MetadataNanoStringList?:  {
        __typename: "ModelMetadataNanoStringConnection",
        nextToken?: string | null,
      } | null,
      MetadataRnaSeqList?:  {
        __typename: "ModelMetadataRnaSeqConnection",
        nextToken?: string | null,
      } | null,
      AuxiliaryAnalysislList?:  {
        __typename: "ModelAuxiliaryAnalysisConnection",
        nextToken?: string | null,
      } | null,
      domainSampleId: string,
      experimentSpecificVariables?: string | null,
      collectionDate?: string | null,
      processingDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseSamplesId?: string | null,
      timepointSamplesId?: string | null,
      batchSamplesId?: string | null,
    } | null,
    treatment?:  {
      __typename: "ModelTreatmentConnection",
      items:  Array< {
        __typename: "Treatment",
        id: string,
        metadataImmunopeptidomicsId?: string | null,
        metadataNanoStringId?: string | null,
        metadataRnaSeqId?: string | null,
        treatmentName: string,
        dose: number,
        doseUnit: string,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        metadataImmunopeptidomicsTreatmentId?: string | null,
        metadataNanoStringTreatmentId?: string | null,
        metadataRnaSeqTreatmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    metaDataTemplateS3Path: string,
    subtypeOfAnalysis?: string | null,
    species: string,
    sampleType: string,
    qcStatus: QcStatus,
    glacierBackup: boolean,
    rawDataS3: boolean,
    tumorVolume?: number | null,
    sequenceType: SequenceType,
    strandSpecific: string,
    UMI: boolean,
    adaptorsRemoved: boolean,
    adaptorsS3Path?: string | null,
    indexSequences?: Array< string | null > | null,
    concNanoGramPerMicroL?: number | null,
    rnaMassNanoGram?: number | null,
    rnaVolumeMicroL?: number | null,
    rin?: number | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    isControl: boolean,
    barcodesMappedToControl?: Array< string | null > | null,
    barcode: string,
    s3Path: Array< string | null >,
    sampleMetadataRnaSeqListId?: string | null,
  } | null,
};

export type OnDeleteMetadataRnaSeqSubscriptionVariables = {
  filter?: ModelSubscriptionMetadataRnaSeqFilterInput | null,
};

export type OnDeleteMetadataRnaSeqSubscription = {
  onDeleteMetadataRnaSeq?:  {
    __typename: "MetadataRnaSeq",
    id: string,
    sampleId: string,
    sample?:  {
      __typename: "Sample",
      id: string,
      timepointId?: string | null,
      timepoint?:  {
        __typename: "Timepoint",
        id: string,
        patientId: string,
        timepointName: string,
        s3Path?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        patientTimepointsId?: string | null,
      } | null,
      studyPhaseId?: string | null,
      StudyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      BatchId?: string | null,
      Batch?:  {
        __typename: "Batch",
        id: string,
        batchName: string,
        batchAnnotations?: string | null,
        batchGroups?: string | null,
        annotations: string,
        controlSamples: string,
        filters: string,
        description?: string | null,
        studyId: string,
        studyPhaseId?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyBatchesId?: string | null,
        studyPhaseBatchesId?: string | null,
      } | null,
      metadataImmunopeptidomicsList?:  {
        __typename: "ModelMetadataImmunopeptidomicsConnection",
        nextToken?: string | null,
      } | null,
      MetadataNanoStringList?:  {
        __typename: "ModelMetadataNanoStringConnection",
        nextToken?: string | null,
      } | null,
      MetadataRnaSeqList?:  {
        __typename: "ModelMetadataRnaSeqConnection",
        nextToken?: string | null,
      } | null,
      AuxiliaryAnalysislList?:  {
        __typename: "ModelAuxiliaryAnalysisConnection",
        nextToken?: string | null,
      } | null,
      domainSampleId: string,
      experimentSpecificVariables?: string | null,
      collectionDate?: string | null,
      processingDate?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyPhaseSamplesId?: string | null,
      timepointSamplesId?: string | null,
      batchSamplesId?: string | null,
    } | null,
    treatment?:  {
      __typename: "ModelTreatmentConnection",
      items:  Array< {
        __typename: "Treatment",
        id: string,
        metadataImmunopeptidomicsId?: string | null,
        metadataNanoStringId?: string | null,
        metadataRnaSeqId?: string | null,
        treatmentName: string,
        dose: number,
        doseUnit: string,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        metadataImmunopeptidomicsTreatmentId?: string | null,
        metadataNanoStringTreatmentId?: string | null,
        metadataRnaSeqTreatmentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    metaDataTemplateS3Path: string,
    subtypeOfAnalysis?: string | null,
    species: string,
    sampleType: string,
    qcStatus: QcStatus,
    glacierBackup: boolean,
    rawDataS3: boolean,
    tumorVolume?: number | null,
    sequenceType: SequenceType,
    strandSpecific: string,
    UMI: boolean,
    adaptorsRemoved: boolean,
    adaptorsS3Path?: string | null,
    indexSequences?: Array< string | null > | null,
    concNanoGramPerMicroL?: number | null,
    rnaMassNanoGram?: number | null,
    rnaVolumeMicroL?: number | null,
    rin?: number | null,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    isControl: boolean,
    barcodesMappedToControl?: Array< string | null > | null,
    barcode: string,
    s3Path: Array< string | null >,
    sampleMetadataRnaSeqListId?: string | null,
  } | null,
};

export type OnCreateTreatmentSubscriptionVariables = {
  filter?: ModelSubscriptionTreatmentFilterInput | null,
};

export type OnCreateTreatmentSubscription = {
  onCreateTreatment?:  {
    __typename: "Treatment",
    id: string,
    metadataImmunopeptidomicsId?: string | null,
    metadataImmunopeptidomics?:  {
      __typename: "MetadataImmunopeptidomics",
      id: string,
      sampleId: string,
      sample?:  {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null,
      treatment?:  {
        __typename: "ModelTreatmentConnection",
        nextToken?: string | null,
      } | null,
      metaDataTemplateS3Path: string,
      species: string,
      subtypeOfAnalysis?: string | null,
      sampleType: string,
      qcStatus: QcStatus,
      glacierBackup: boolean,
      rawDataS3: boolean,
      tumorVolume?: number | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      isControl: boolean,
      barcodesMappedToControl?: Array< string | null > | null,
      barcode: string,
      s3Path: Array< string | null >,
      sampleMetadataImmunopeptidomicsListId?: string | null,
    } | null,
    metadataNanoStringId?: string | null,
    metadataNanoString?:  {
      __typename: "MetadataNanoString",
      id: string,
      sampleId: string,
      sample?:  {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null,
      treatment?:  {
        __typename: "ModelTreatmentConnection",
        nextToken?: string | null,
      } | null,
      metaDataTemplateS3Path: string,
      subtypeOfAnalysis?: string | null,
      species: string,
      sampleType: string,
      qcStatus: QcStatus,
      glacierBackup: boolean,
      rawDataS3: boolean,
      tumorVolume?: number | null,
      dv200Percentage?: number | null,
      concNanoGramPerMicroL?: number | null,
      rnaMassNanoGram?: number | null,
      rnaVolumeMicroL?: number | null,
      rin?: number | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      isControl: boolean,
      barcodesMappedToControl?: Array< string | null > | null,
      barcode: string,
      s3Path: Array< string | null >,
      sampleMetadataNanoStringListId?: string | null,
    } | null,
    metadataRnaSeqId?: string | null,
    metadataRnaSeq?:  {
      __typename: "MetadataRnaSeq",
      id: string,
      sampleId: string,
      sample?:  {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null,
      treatment?:  {
        __typename: "ModelTreatmentConnection",
        nextToken?: string | null,
      } | null,
      metaDataTemplateS3Path: string,
      subtypeOfAnalysis?: string | null,
      species: string,
      sampleType: string,
      qcStatus: QcStatus,
      glacierBackup: boolean,
      rawDataS3: boolean,
      tumorVolume?: number | null,
      sequenceType: SequenceType,
      strandSpecific: string,
      UMI: boolean,
      adaptorsRemoved: boolean,
      adaptorsS3Path?: string | null,
      indexSequences?: Array< string | null > | null,
      concNanoGramPerMicroL?: number | null,
      rnaMassNanoGram?: number | null,
      rnaVolumeMicroL?: number | null,
      rin?: number | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      isControl: boolean,
      barcodesMappedToControl?: Array< string | null > | null,
      barcode: string,
      s3Path: Array< string | null >,
      sampleMetadataRnaSeqListId?: string | null,
    } | null,
    treatmentName: string,
    dose: number,
    doseUnit: string,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    metadataImmunopeptidomicsTreatmentId?: string | null,
    metadataNanoStringTreatmentId?: string | null,
    metadataRnaSeqTreatmentId?: string | null,
  } | null,
};

export type OnUpdateTreatmentSubscriptionVariables = {
  filter?: ModelSubscriptionTreatmentFilterInput | null,
};

export type OnUpdateTreatmentSubscription = {
  onUpdateTreatment?:  {
    __typename: "Treatment",
    id: string,
    metadataImmunopeptidomicsId?: string | null,
    metadataImmunopeptidomics?:  {
      __typename: "MetadataImmunopeptidomics",
      id: string,
      sampleId: string,
      sample?:  {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null,
      treatment?:  {
        __typename: "ModelTreatmentConnection",
        nextToken?: string | null,
      } | null,
      metaDataTemplateS3Path: string,
      species: string,
      subtypeOfAnalysis?: string | null,
      sampleType: string,
      qcStatus: QcStatus,
      glacierBackup: boolean,
      rawDataS3: boolean,
      tumorVolume?: number | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      isControl: boolean,
      barcodesMappedToControl?: Array< string | null > | null,
      barcode: string,
      s3Path: Array< string | null >,
      sampleMetadataImmunopeptidomicsListId?: string | null,
    } | null,
    metadataNanoStringId?: string | null,
    metadataNanoString?:  {
      __typename: "MetadataNanoString",
      id: string,
      sampleId: string,
      sample?:  {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null,
      treatment?:  {
        __typename: "ModelTreatmentConnection",
        nextToken?: string | null,
      } | null,
      metaDataTemplateS3Path: string,
      subtypeOfAnalysis?: string | null,
      species: string,
      sampleType: string,
      qcStatus: QcStatus,
      glacierBackup: boolean,
      rawDataS3: boolean,
      tumorVolume?: number | null,
      dv200Percentage?: number | null,
      concNanoGramPerMicroL?: number | null,
      rnaMassNanoGram?: number | null,
      rnaVolumeMicroL?: number | null,
      rin?: number | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      isControl: boolean,
      barcodesMappedToControl?: Array< string | null > | null,
      barcode: string,
      s3Path: Array< string | null >,
      sampleMetadataNanoStringListId?: string | null,
    } | null,
    metadataRnaSeqId?: string | null,
    metadataRnaSeq?:  {
      __typename: "MetadataRnaSeq",
      id: string,
      sampleId: string,
      sample?:  {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null,
      treatment?:  {
        __typename: "ModelTreatmentConnection",
        nextToken?: string | null,
      } | null,
      metaDataTemplateS3Path: string,
      subtypeOfAnalysis?: string | null,
      species: string,
      sampleType: string,
      qcStatus: QcStatus,
      glacierBackup: boolean,
      rawDataS3: boolean,
      tumorVolume?: number | null,
      sequenceType: SequenceType,
      strandSpecific: string,
      UMI: boolean,
      adaptorsRemoved: boolean,
      adaptorsS3Path?: string | null,
      indexSequences?: Array< string | null > | null,
      concNanoGramPerMicroL?: number | null,
      rnaMassNanoGram?: number | null,
      rnaVolumeMicroL?: number | null,
      rin?: number | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      isControl: boolean,
      barcodesMappedToControl?: Array< string | null > | null,
      barcode: string,
      s3Path: Array< string | null >,
      sampleMetadataRnaSeqListId?: string | null,
    } | null,
    treatmentName: string,
    dose: number,
    doseUnit: string,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    metadataImmunopeptidomicsTreatmentId?: string | null,
    metadataNanoStringTreatmentId?: string | null,
    metadataRnaSeqTreatmentId?: string | null,
  } | null,
};

export type OnDeleteTreatmentSubscriptionVariables = {
  filter?: ModelSubscriptionTreatmentFilterInput | null,
};

export type OnDeleteTreatmentSubscription = {
  onDeleteTreatment?:  {
    __typename: "Treatment",
    id: string,
    metadataImmunopeptidomicsId?: string | null,
    metadataImmunopeptidomics?:  {
      __typename: "MetadataImmunopeptidomics",
      id: string,
      sampleId: string,
      sample?:  {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null,
      treatment?:  {
        __typename: "ModelTreatmentConnection",
        nextToken?: string | null,
      } | null,
      metaDataTemplateS3Path: string,
      species: string,
      subtypeOfAnalysis?: string | null,
      sampleType: string,
      qcStatus: QcStatus,
      glacierBackup: boolean,
      rawDataS3: boolean,
      tumorVolume?: number | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      isControl: boolean,
      barcodesMappedToControl?: Array< string | null > | null,
      barcode: string,
      s3Path: Array< string | null >,
      sampleMetadataImmunopeptidomicsListId?: string | null,
    } | null,
    metadataNanoStringId?: string | null,
    metadataNanoString?:  {
      __typename: "MetadataNanoString",
      id: string,
      sampleId: string,
      sample?:  {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null,
      treatment?:  {
        __typename: "ModelTreatmentConnection",
        nextToken?: string | null,
      } | null,
      metaDataTemplateS3Path: string,
      subtypeOfAnalysis?: string | null,
      species: string,
      sampleType: string,
      qcStatus: QcStatus,
      glacierBackup: boolean,
      rawDataS3: boolean,
      tumorVolume?: number | null,
      dv200Percentage?: number | null,
      concNanoGramPerMicroL?: number | null,
      rnaMassNanoGram?: number | null,
      rnaVolumeMicroL?: number | null,
      rin?: number | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      isControl: boolean,
      barcodesMappedToControl?: Array< string | null > | null,
      barcode: string,
      s3Path: Array< string | null >,
      sampleMetadataNanoStringListId?: string | null,
    } | null,
    metadataRnaSeqId?: string | null,
    metadataRnaSeq?:  {
      __typename: "MetadataRnaSeq",
      id: string,
      sampleId: string,
      sample?:  {
        __typename: "Sample",
        id: string,
        timepointId?: string | null,
        studyPhaseId?: string | null,
        BatchId?: string | null,
        domainSampleId: string,
        experimentSpecificVariables?: string | null,
        collectionDate?: string | null,
        processingDate?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPhaseSamplesId?: string | null,
        timepointSamplesId?: string | null,
        batchSamplesId?: string | null,
      } | null,
      treatment?:  {
        __typename: "ModelTreatmentConnection",
        nextToken?: string | null,
      } | null,
      metaDataTemplateS3Path: string,
      subtypeOfAnalysis?: string | null,
      species: string,
      sampleType: string,
      qcStatus: QcStatus,
      glacierBackup: boolean,
      rawDataS3: boolean,
      tumorVolume?: number | null,
      sequenceType: SequenceType,
      strandSpecific: string,
      UMI: boolean,
      adaptorsRemoved: boolean,
      adaptorsS3Path?: string | null,
      indexSequences?: Array< string | null > | null,
      concNanoGramPerMicroL?: number | null,
      rnaMassNanoGram?: number | null,
      rnaVolumeMicroL?: number | null,
      rin?: number | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      isControl: boolean,
      barcodesMappedToControl?: Array< string | null > | null,
      barcode: string,
      s3Path: Array< string | null >,
      sampleMetadataRnaSeqListId?: string | null,
    } | null,
    treatmentName: string,
    dose: number,
    doseUnit: string,
    readGroups?: Array< string | null > | null,
    writeGroups?: Array< string | null > | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    metadataImmunopeptidomicsTreatmentId?: string | null,
    metadataNanoStringTreatmentId?: string | null,
    metadataRnaSeqTreatmentId?: string | null,
  } | null,
};

export type OnCreateLaunchablePipelinesSubscriptionVariables = {
  filter?: ModelSubscriptionLaunchablePipelinesFilterInput | null,
};

export type OnCreateLaunchablePipelinesSubscription = {
  onCreateLaunchablePipelines?:  {
    __typename: "LaunchablePipelines",
    id: string,
    pipelineName?: string | null,
    organizations?:  {
      __typename: "ModelOrganizationLaunchablePipelinesConnection",
      items:  Array< {
        __typename: "OrganizationLaunchablePipelines",
        id: string,
        organizationId: string,
        launchablePipelinesId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pipelineVersion?: string | null,
    parameters?: string | null,
    description?: string | null,
    inputFileInfo?: string | null,
    supportedAnalyses?: Array< AnalysisType | null > | null,
    supportedSubtypeOfAnalyses?: Array< string | null > | null,
    pipelines?:  {
      __typename: "ModelPipelineConnection",
      items:  Array< {
        __typename: "Pipeline",
        id: string,
        name: string,
        status?: pipelineStatus | null,
        description?: string | null,
        s3Event?: string | null,
        s3ParamsFile?: string | null,
        s3Results?: string | null,
        visFileInfoS3path?: string | null,
        s3NfLog?: string | null,
        s3NfTasksLogs?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis?: string | null,
        parameters: string,
        studyId?: string | null,
        studyPhaseId?: string | null,
        scheduledPipeline: boolean,
        scheduledDateTime?: string | null,
        launchablePipelineId: string,
        batchId: string,
        runId?: string | null,
        userEmail: string,
        pipelineStarted?: string | null,
        pipelineCompleted?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPipelinesId?: string | null,
        studyPhasePipelinesId?: string | null,
        batchPipelinesId?: string | null,
        launchablePipelinesPipelinesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    gitRepo?: string | null,
    workflowId?: string | null,
    created: string,
    updated: string,
  } | null,
};

export type OnUpdateLaunchablePipelinesSubscriptionVariables = {
  filter?: ModelSubscriptionLaunchablePipelinesFilterInput | null,
};

export type OnUpdateLaunchablePipelinesSubscription = {
  onUpdateLaunchablePipelines?:  {
    __typename: "LaunchablePipelines",
    id: string,
    pipelineName?: string | null,
    organizations?:  {
      __typename: "ModelOrganizationLaunchablePipelinesConnection",
      items:  Array< {
        __typename: "OrganizationLaunchablePipelines",
        id: string,
        organizationId: string,
        launchablePipelinesId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pipelineVersion?: string | null,
    parameters?: string | null,
    description?: string | null,
    inputFileInfo?: string | null,
    supportedAnalyses?: Array< AnalysisType | null > | null,
    supportedSubtypeOfAnalyses?: Array< string | null > | null,
    pipelines?:  {
      __typename: "ModelPipelineConnection",
      items:  Array< {
        __typename: "Pipeline",
        id: string,
        name: string,
        status?: pipelineStatus | null,
        description?: string | null,
        s3Event?: string | null,
        s3ParamsFile?: string | null,
        s3Results?: string | null,
        visFileInfoS3path?: string | null,
        s3NfLog?: string | null,
        s3NfTasksLogs?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis?: string | null,
        parameters: string,
        studyId?: string | null,
        studyPhaseId?: string | null,
        scheduledPipeline: boolean,
        scheduledDateTime?: string | null,
        launchablePipelineId: string,
        batchId: string,
        runId?: string | null,
        userEmail: string,
        pipelineStarted?: string | null,
        pipelineCompleted?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPipelinesId?: string | null,
        studyPhasePipelinesId?: string | null,
        batchPipelinesId?: string | null,
        launchablePipelinesPipelinesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    gitRepo?: string | null,
    workflowId?: string | null,
    created: string,
    updated: string,
  } | null,
};

export type OnDeleteLaunchablePipelinesSubscriptionVariables = {
  filter?: ModelSubscriptionLaunchablePipelinesFilterInput | null,
};

export type OnDeleteLaunchablePipelinesSubscription = {
  onDeleteLaunchablePipelines?:  {
    __typename: "LaunchablePipelines",
    id: string,
    pipelineName?: string | null,
    organizations?:  {
      __typename: "ModelOrganizationLaunchablePipelinesConnection",
      items:  Array< {
        __typename: "OrganizationLaunchablePipelines",
        id: string,
        organizationId: string,
        launchablePipelinesId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pipelineVersion?: string | null,
    parameters?: string | null,
    description?: string | null,
    inputFileInfo?: string | null,
    supportedAnalyses?: Array< AnalysisType | null > | null,
    supportedSubtypeOfAnalyses?: Array< string | null > | null,
    pipelines?:  {
      __typename: "ModelPipelineConnection",
      items:  Array< {
        __typename: "Pipeline",
        id: string,
        name: string,
        status?: pipelineStatus | null,
        description?: string | null,
        s3Event?: string | null,
        s3ParamsFile?: string | null,
        s3Results?: string | null,
        visFileInfoS3path?: string | null,
        s3NfLog?: string | null,
        s3NfTasksLogs?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis?: string | null,
        parameters: string,
        studyId?: string | null,
        studyPhaseId?: string | null,
        scheduledPipeline: boolean,
        scheduledDateTime?: string | null,
        launchablePipelineId: string,
        batchId: string,
        runId?: string | null,
        userEmail: string,
        pipelineStarted?: string | null,
        pipelineCompleted?: string | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyPipelinesId?: string | null,
        studyPhasePipelinesId?: string | null,
        batchPipelinesId?: string | null,
        launchablePipelinesPipelinesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    gitRepo?: string | null,
    workflowId?: string | null,
    created: string,
    updated: string,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  owner?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    email?: string | null,
    organization?: string | null,
    gitToken?: string | null,
    gitName?: string | null,
    gitURL?: string | null,
    readGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    email?: string | null,
    organization?: string | null,
    gitToken?: string | null,
    gitName?: string | null,
    gitURL?: string | null,
    readGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    email?: string | null,
    organization?: string | null,
    gitToken?: string | null,
    gitName?: string | null,
    gitURL?: string | null,
    readGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    owner?: string | null,
  } | null,
};

export type OnCreateUploadLogSubscriptionVariables = {
  filter?: ModelSubscriptionUploadLogFilterInput | null,
};

export type OnCreateUploadLogSubscription = {
  onCreateUploadLog?:  {
    __typename: "UploadLog",
    id: string,
    uploadS3Path?: string | null,
    status?: string | null,
    readGroups?: Array< string | null > | null,
    created?: string | null,
    updated?: string | null,
  } | null,
};

export type OnUpdateUploadLogSubscriptionVariables = {
  filter?: ModelSubscriptionUploadLogFilterInput | null,
};

export type OnUpdateUploadLogSubscription = {
  onUpdateUploadLog?:  {
    __typename: "UploadLog",
    id: string,
    uploadS3Path?: string | null,
    status?: string | null,
    readGroups?: Array< string | null > | null,
    created?: string | null,
    updated?: string | null,
  } | null,
};

export type OnDeleteUploadLogSubscriptionVariables = {
  filter?: ModelSubscriptionUploadLogFilterInput | null,
};

export type OnDeleteUploadLogSubscription = {
  onDeleteUploadLog?:  {
    __typename: "UploadLog",
    id: string,
    uploadS3Path?: string | null,
    status?: string | null,
    readGroups?: Array< string | null > | null,
    created?: string | null,
    updated?: string | null,
  } | null,
};

export type OnCreateVisualizationsSubscriptionVariables = {
  filter?: ModelSubscriptionVisualizationsFilterInput | null,
};

export type OnCreateVisualizationsSubscription = {
  onCreateVisualizations?:  {
    __typename: "Visualizations",
    id: string,
    visualizationName?: string | null,
    description?: string | null,
    version?: string | null,
    deploymentPath?: string | null,
    validationSchema?: string | null,
    allowedExtensions?: Array< string | null > | null,
    supportedAnalyses?: Array< string | null > | null,
    created: string,
    updated: string,
  } | null,
};

export type OnUpdateVisualizationsSubscriptionVariables = {
  filter?: ModelSubscriptionVisualizationsFilterInput | null,
};

export type OnUpdateVisualizationsSubscription = {
  onUpdateVisualizations?:  {
    __typename: "Visualizations",
    id: string,
    visualizationName?: string | null,
    description?: string | null,
    version?: string | null,
    deploymentPath?: string | null,
    validationSchema?: string | null,
    allowedExtensions?: Array< string | null > | null,
    supportedAnalyses?: Array< string | null > | null,
    created: string,
    updated: string,
  } | null,
};

export type OnDeleteVisualizationsSubscriptionVariables = {
  filter?: ModelSubscriptionVisualizationsFilterInput | null,
};

export type OnDeleteVisualizationsSubscription = {
  onDeleteVisualizations?:  {
    __typename: "Visualizations",
    id: string,
    visualizationName?: string | null,
    description?: string | null,
    version?: string | null,
    deploymentPath?: string | null,
    validationSchema?: string | null,
    allowedExtensions?: Array< string | null > | null,
    supportedAnalyses?: Array< string | null > | null,
    created: string,
    updated: string,
  } | null,
};

export type OnCreateVisualizationSessionSubscriptionVariables = {
  filter?: ModelSubscriptionVisualizationSessionFilterInput | null,
  owner?: string | null,
};

export type OnCreateVisualizationSessionSubscription = {
  onCreateVisualizationSession?:  {
    __typename: "VisualizationSession",
    id: string,
    statuses?: string | null,
    data?: string | null,
    description?: string | null,
    created: string,
    updated: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateVisualizationSessionSubscriptionVariables = {
  filter?: ModelSubscriptionVisualizationSessionFilterInput | null,
  owner?: string | null,
};

export type OnUpdateVisualizationSessionSubscription = {
  onUpdateVisualizationSession?:  {
    __typename: "VisualizationSession",
    id: string,
    statuses?: string | null,
    data?: string | null,
    description?: string | null,
    created: string,
    updated: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteVisualizationSessionSubscriptionVariables = {
  filter?: ModelSubscriptionVisualizationSessionFilterInput | null,
  owner?: string | null,
};

export type OnDeleteVisualizationSessionSubscription = {
  onDeleteVisualizationSession?:  {
    __typename: "VisualizationSession",
    id: string,
    statuses?: string | null,
    data?: string | null,
    description?: string | null,
    created: string,
    updated: string,
    owner?: string | null,
  } | null,
};

export type OnCreateDataLabEC2InstancesSubscriptionVariables = {
  filter?: ModelSubscriptionDataLabEC2InstancesFilterInput | null,
  owner?: string | null,
};

export type OnCreateDataLabEC2InstancesSubscription = {
  onCreateDataLabEC2Instances?:  {
    __typename: "DataLabEC2Instances",
    id: string,
    creationTimestamp?: string | null,
    stoppedTimestamp?: string | null,
    startTimestamp?: string | null,
    terminatedTimestamp?: string | null,
    stoppedTimePeriod?: number | null,
    runningTimePeriod?: number | null,
    userEmail: string,
    status?: DataLabEC2InstancesStatus | null,
    type?: DataLabEC2InstancesType | null,
    dataLabInstanceSessions?:  {
      __typename: "ModelDataLabEC2InstanceSessionConnection",
      items:  Array< {
        __typename: "DataLabEC2InstanceSession",
        id: string,
        dataLabInstanceId: string,
        s3Path?: string | null,
        instanceLaunchEvent?: string | null,
        status?: DataLabEC2InstanceSessionStatus | null,
        title?: string | null,
        description?: string | null,
        created: string,
        updated: string,
        dataLabEC2InstancesDataLabInstanceSessionsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    dataLabInstanceStatusChanges?:  {
      __typename: "ModelDataLabEC2InstanceStatusChangeConnection",
      items:  Array< {
        __typename: "DataLabEC2InstanceStatusChange",
        id: string,
        dataLabInstanceId: string,
        organizationId?: string | null,
        previousStatus?: DataLabEC2InstancesStatus | null,
        newStatus?: DataLabEC2InstancesStatus | null,
        initiatedBy?: string | null,
        action?: string | null,
        performedBy?: string | null,
        additionalNotes?: string | null,
        created: string,
        updated: string,
        dataLabEC2InstancesDataLabInstanceStatusChangesId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    organizationId?: string | null,
    organizationName?: string | null,
    instanceUrl?: string | null,
    instanceId?: string | null,
    infrastructureRelatedEntities?: string | null,
    instanceLaunchEvent?: string | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateDataLabEC2InstancesSubscriptionVariables = {
  filter?: ModelSubscriptionDataLabEC2InstancesFilterInput | null,
  owner?: string | null,
};

export type OnUpdateDataLabEC2InstancesSubscription = {
  onUpdateDataLabEC2Instances?:  {
    __typename: "DataLabEC2Instances",
    id: string,
    creationTimestamp?: string | null,
    stoppedTimestamp?: string | null,
    startTimestamp?: string | null,
    terminatedTimestamp?: string | null,
    stoppedTimePeriod?: number | null,
    runningTimePeriod?: number | null,
    userEmail: string,
    status?: DataLabEC2InstancesStatus | null,
    type?: DataLabEC2InstancesType | null,
    dataLabInstanceSessions?:  {
      __typename: "ModelDataLabEC2InstanceSessionConnection",
      items:  Array< {
        __typename: "DataLabEC2InstanceSession",
        id: string,
        dataLabInstanceId: string,
        s3Path?: string | null,
        instanceLaunchEvent?: string | null,
        status?: DataLabEC2InstanceSessionStatus | null,
        title?: string | null,
        description?: string | null,
        created: string,
        updated: string,
        dataLabEC2InstancesDataLabInstanceSessionsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    dataLabInstanceStatusChanges?:  {
      __typename: "ModelDataLabEC2InstanceStatusChangeConnection",
      items:  Array< {
        __typename: "DataLabEC2InstanceStatusChange",
        id: string,
        dataLabInstanceId: string,
        organizationId?: string | null,
        previousStatus?: DataLabEC2InstancesStatus | null,
        newStatus?: DataLabEC2InstancesStatus | null,
        initiatedBy?: string | null,
        action?: string | null,
        performedBy?: string | null,
        additionalNotes?: string | null,
        created: string,
        updated: string,
        dataLabEC2InstancesDataLabInstanceStatusChangesId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    organizationId?: string | null,
    organizationName?: string | null,
    instanceUrl?: string | null,
    instanceId?: string | null,
    infrastructureRelatedEntities?: string | null,
    instanceLaunchEvent?: string | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteDataLabEC2InstancesSubscriptionVariables = {
  filter?: ModelSubscriptionDataLabEC2InstancesFilterInput | null,
  owner?: string | null,
};

export type OnDeleteDataLabEC2InstancesSubscription = {
  onDeleteDataLabEC2Instances?:  {
    __typename: "DataLabEC2Instances",
    id: string,
    creationTimestamp?: string | null,
    stoppedTimestamp?: string | null,
    startTimestamp?: string | null,
    terminatedTimestamp?: string | null,
    stoppedTimePeriod?: number | null,
    runningTimePeriod?: number | null,
    userEmail: string,
    status?: DataLabEC2InstancesStatus | null,
    type?: DataLabEC2InstancesType | null,
    dataLabInstanceSessions?:  {
      __typename: "ModelDataLabEC2InstanceSessionConnection",
      items:  Array< {
        __typename: "DataLabEC2InstanceSession",
        id: string,
        dataLabInstanceId: string,
        s3Path?: string | null,
        instanceLaunchEvent?: string | null,
        status?: DataLabEC2InstanceSessionStatus | null,
        title?: string | null,
        description?: string | null,
        created: string,
        updated: string,
        dataLabEC2InstancesDataLabInstanceSessionsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    dataLabInstanceStatusChanges?:  {
      __typename: "ModelDataLabEC2InstanceStatusChangeConnection",
      items:  Array< {
        __typename: "DataLabEC2InstanceStatusChange",
        id: string,
        dataLabInstanceId: string,
        organizationId?: string | null,
        previousStatus?: DataLabEC2InstancesStatus | null,
        newStatus?: DataLabEC2InstancesStatus | null,
        initiatedBy?: string | null,
        action?: string | null,
        performedBy?: string | null,
        additionalNotes?: string | null,
        created: string,
        updated: string,
        dataLabEC2InstancesDataLabInstanceStatusChangesId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    organizationId?: string | null,
    organizationName?: string | null,
    instanceUrl?: string | null,
    instanceId?: string | null,
    infrastructureRelatedEntities?: string | null,
    instanceLaunchEvent?: string | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    owner?: string | null,
  } | null,
};

export type OnCreateDataLabEC2InstanceSessionSubscriptionVariables = {
  filter?: ModelSubscriptionDataLabEC2InstanceSessionFilterInput | null,
  owner?: string | null,
};

export type OnCreateDataLabEC2InstanceSessionSubscription = {
  onCreateDataLabEC2InstanceSession?:  {
    __typename: "DataLabEC2InstanceSession",
    id: string,
    dataLabInstanceId: string,
    dataLabInstance?:  {
      __typename: "DataLabEC2Instances",
      id: string,
      creationTimestamp?: string | null,
      stoppedTimestamp?: string | null,
      startTimestamp?: string | null,
      terminatedTimestamp?: string | null,
      stoppedTimePeriod?: number | null,
      runningTimePeriod?: number | null,
      userEmail: string,
      status?: DataLabEC2InstancesStatus | null,
      type?: DataLabEC2InstancesType | null,
      dataLabInstanceSessions?:  {
        __typename: "ModelDataLabEC2InstanceSessionConnection",
        nextToken?: string | null,
      } | null,
      dataLabInstanceStatusChanges?:  {
        __typename: "ModelDataLabEC2InstanceStatusChangeConnection",
        nextToken?: string | null,
      } | null,
      organizationId?: string | null,
      organizationName?: string | null,
      instanceUrl?: string | null,
      instanceId?: string | null,
      infrastructureRelatedEntities?: string | null,
      instanceLaunchEvent?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null,
    s3Path?: string | null,
    instanceLaunchEvent?: string | null,
    status?: DataLabEC2InstanceSessionStatus | null,
    title?: string | null,
    description?: string | null,
    created: string,
    updated: string,
    dataLabEC2InstancesDataLabInstanceSessionsId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateDataLabEC2InstanceSessionSubscriptionVariables = {
  filter?: ModelSubscriptionDataLabEC2InstanceSessionFilterInput | null,
  owner?: string | null,
};

export type OnUpdateDataLabEC2InstanceSessionSubscription = {
  onUpdateDataLabEC2InstanceSession?:  {
    __typename: "DataLabEC2InstanceSession",
    id: string,
    dataLabInstanceId: string,
    dataLabInstance?:  {
      __typename: "DataLabEC2Instances",
      id: string,
      creationTimestamp?: string | null,
      stoppedTimestamp?: string | null,
      startTimestamp?: string | null,
      terminatedTimestamp?: string | null,
      stoppedTimePeriod?: number | null,
      runningTimePeriod?: number | null,
      userEmail: string,
      status?: DataLabEC2InstancesStatus | null,
      type?: DataLabEC2InstancesType | null,
      dataLabInstanceSessions?:  {
        __typename: "ModelDataLabEC2InstanceSessionConnection",
        nextToken?: string | null,
      } | null,
      dataLabInstanceStatusChanges?:  {
        __typename: "ModelDataLabEC2InstanceStatusChangeConnection",
        nextToken?: string | null,
      } | null,
      organizationId?: string | null,
      organizationName?: string | null,
      instanceUrl?: string | null,
      instanceId?: string | null,
      infrastructureRelatedEntities?: string | null,
      instanceLaunchEvent?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null,
    s3Path?: string | null,
    instanceLaunchEvent?: string | null,
    status?: DataLabEC2InstanceSessionStatus | null,
    title?: string | null,
    description?: string | null,
    created: string,
    updated: string,
    dataLabEC2InstancesDataLabInstanceSessionsId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteDataLabEC2InstanceSessionSubscriptionVariables = {
  filter?: ModelSubscriptionDataLabEC2InstanceSessionFilterInput | null,
  owner?: string | null,
};

export type OnDeleteDataLabEC2InstanceSessionSubscription = {
  onDeleteDataLabEC2InstanceSession?:  {
    __typename: "DataLabEC2InstanceSession",
    id: string,
    dataLabInstanceId: string,
    dataLabInstance?:  {
      __typename: "DataLabEC2Instances",
      id: string,
      creationTimestamp?: string | null,
      stoppedTimestamp?: string | null,
      startTimestamp?: string | null,
      terminatedTimestamp?: string | null,
      stoppedTimePeriod?: number | null,
      runningTimePeriod?: number | null,
      userEmail: string,
      status?: DataLabEC2InstancesStatus | null,
      type?: DataLabEC2InstancesType | null,
      dataLabInstanceSessions?:  {
        __typename: "ModelDataLabEC2InstanceSessionConnection",
        nextToken?: string | null,
      } | null,
      dataLabInstanceStatusChanges?:  {
        __typename: "ModelDataLabEC2InstanceStatusChangeConnection",
        nextToken?: string | null,
      } | null,
      organizationId?: string | null,
      organizationName?: string | null,
      instanceUrl?: string | null,
      instanceId?: string | null,
      infrastructureRelatedEntities?: string | null,
      instanceLaunchEvent?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null,
    s3Path?: string | null,
    instanceLaunchEvent?: string | null,
    status?: DataLabEC2InstanceSessionStatus | null,
    title?: string | null,
    description?: string | null,
    created: string,
    updated: string,
    dataLabEC2InstancesDataLabInstanceSessionsId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateDataLabEC2InstanceStatusChangeSubscriptionVariables = {
  filter?: ModelSubscriptionDataLabEC2InstanceStatusChangeFilterInput | null,
  owner?: string | null,
};

export type OnCreateDataLabEC2InstanceStatusChangeSubscription = {
  onCreateDataLabEC2InstanceStatusChange?:  {
    __typename: "DataLabEC2InstanceStatusChange",
    id: string,
    dataLabInstanceId: string,
    dataLabInstance?:  {
      __typename: "DataLabEC2Instances",
      id: string,
      creationTimestamp?: string | null,
      stoppedTimestamp?: string | null,
      startTimestamp?: string | null,
      terminatedTimestamp?: string | null,
      stoppedTimePeriod?: number | null,
      runningTimePeriod?: number | null,
      userEmail: string,
      status?: DataLabEC2InstancesStatus | null,
      type?: DataLabEC2InstancesType | null,
      dataLabInstanceSessions?:  {
        __typename: "ModelDataLabEC2InstanceSessionConnection",
        nextToken?: string | null,
      } | null,
      dataLabInstanceStatusChanges?:  {
        __typename: "ModelDataLabEC2InstanceStatusChangeConnection",
        nextToken?: string | null,
      } | null,
      organizationId?: string | null,
      organizationName?: string | null,
      instanceUrl?: string | null,
      instanceId?: string | null,
      infrastructureRelatedEntities?: string | null,
      instanceLaunchEvent?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null,
    organizationId?: string | null,
    previousStatus?: DataLabEC2InstancesStatus | null,
    newStatus?: DataLabEC2InstancesStatus | null,
    initiatedBy?: string | null,
    action?: string | null,
    performedBy?: string | null,
    additionalNotes?: string | null,
    created: string,
    updated: string,
    dataLabEC2InstancesDataLabInstanceStatusChangesId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateDataLabEC2InstanceStatusChangeSubscriptionVariables = {
  filter?: ModelSubscriptionDataLabEC2InstanceStatusChangeFilterInput | null,
  owner?: string | null,
};

export type OnUpdateDataLabEC2InstanceStatusChangeSubscription = {
  onUpdateDataLabEC2InstanceStatusChange?:  {
    __typename: "DataLabEC2InstanceStatusChange",
    id: string,
    dataLabInstanceId: string,
    dataLabInstance?:  {
      __typename: "DataLabEC2Instances",
      id: string,
      creationTimestamp?: string | null,
      stoppedTimestamp?: string | null,
      startTimestamp?: string | null,
      terminatedTimestamp?: string | null,
      stoppedTimePeriod?: number | null,
      runningTimePeriod?: number | null,
      userEmail: string,
      status?: DataLabEC2InstancesStatus | null,
      type?: DataLabEC2InstancesType | null,
      dataLabInstanceSessions?:  {
        __typename: "ModelDataLabEC2InstanceSessionConnection",
        nextToken?: string | null,
      } | null,
      dataLabInstanceStatusChanges?:  {
        __typename: "ModelDataLabEC2InstanceStatusChangeConnection",
        nextToken?: string | null,
      } | null,
      organizationId?: string | null,
      organizationName?: string | null,
      instanceUrl?: string | null,
      instanceId?: string | null,
      infrastructureRelatedEntities?: string | null,
      instanceLaunchEvent?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null,
    organizationId?: string | null,
    previousStatus?: DataLabEC2InstancesStatus | null,
    newStatus?: DataLabEC2InstancesStatus | null,
    initiatedBy?: string | null,
    action?: string | null,
    performedBy?: string | null,
    additionalNotes?: string | null,
    created: string,
    updated: string,
    dataLabEC2InstancesDataLabInstanceStatusChangesId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteDataLabEC2InstanceStatusChangeSubscriptionVariables = {
  filter?: ModelSubscriptionDataLabEC2InstanceStatusChangeFilterInput | null,
  owner?: string | null,
};

export type OnDeleteDataLabEC2InstanceStatusChangeSubscription = {
  onDeleteDataLabEC2InstanceStatusChange?:  {
    __typename: "DataLabEC2InstanceStatusChange",
    id: string,
    dataLabInstanceId: string,
    dataLabInstance?:  {
      __typename: "DataLabEC2Instances",
      id: string,
      creationTimestamp?: string | null,
      stoppedTimestamp?: string | null,
      startTimestamp?: string | null,
      terminatedTimestamp?: string | null,
      stoppedTimePeriod?: number | null,
      runningTimePeriod?: number | null,
      userEmail: string,
      status?: DataLabEC2InstancesStatus | null,
      type?: DataLabEC2InstancesType | null,
      dataLabInstanceSessions?:  {
        __typename: "ModelDataLabEC2InstanceSessionConnection",
        nextToken?: string | null,
      } | null,
      dataLabInstanceStatusChanges?:  {
        __typename: "ModelDataLabEC2InstanceStatusChangeConnection",
        nextToken?: string | null,
      } | null,
      organizationId?: string | null,
      organizationName?: string | null,
      instanceUrl?: string | null,
      instanceId?: string | null,
      infrastructureRelatedEntities?: string | null,
      instanceLaunchEvent?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null,
    organizationId?: string | null,
    previousStatus?: DataLabEC2InstancesStatus | null,
    newStatus?: DataLabEC2InstancesStatus | null,
    initiatedBy?: string | null,
    action?: string | null,
    performedBy?: string | null,
    additionalNotes?: string | null,
    created: string,
    updated: string,
    dataLabEC2InstancesDataLabInstanceStatusChangesId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateUploadBundleSubscriptionVariables = {
  filter?: ModelSubscriptionUploadBundleFilterInput | null,
  owner?: string | null,
};

export type OnCreateUploadBundleSubscription = {
  onCreateUploadBundle?:  {
    __typename: "UploadBundle",
    id: string,
    studyPhaseId?: string | null,
    studyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    bundleLog?: string | null,
    validationErrors?: string | null,
    status?: string | null,
    userEmail?: string | null,
    uploadBundleAnalysisSession?:  {
      __typename: "UploadBundleAnalysisSession",
      id: string,
      uploadSampleAnalysisSession?:  {
        __typename: "ModelUploadSampleAnalysisSessionConnection",
        nextToken?: string | null,
      } | null,
      metadataFileS3Path?: string | null,
      step?: string | null,
      listOfRawFileNames?: Array< string | null > | null,
      analysisType: AnalysisType,
      subtypeOfAnalysis: string,
      studyPhaseId: string,
      metadataHelperLambdaResponseS3Path?: string | null,
      barcodeToFilesToUploadS3Path?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null,
    uploadSamples?:  {
      __typename: "ModelUploadSampleConnection",
      items:  Array< {
        __typename: "UploadSample",
        id: string,
        uploadBundleId: string,
        sampleId: string,
        barcode: string,
        sampleLog?: string | null,
        writeMetadata: boolean,
        status?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        uploadBundleUploadSamplesId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    uploadFiles?:  {
      __typename: "ModelUploadFileConnection",
      items:  Array< {
        __typename: "UploadFile",
        id: string,
        uploadSampleId?: string | null,
        uploadBundleId?: string | null,
        multipartUploadId?: string | null,
        status?: UploadFileStatus | null,
        toSkipUpload: boolean,
        fileName: string,
        fileType: string,
        s3Path: string,
        size?: number | null,
        fileLog?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        uploadBundleUploadFilesId?: string | null,
        uploadSampleUploadFilesId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    adminGroups?: Array< string | null > | null,
    completionTime?: string | null,
    created: string,
    updated: string,
    studyPhaseUploadBundlesId?: string | null,
    uploadBundleUploadBundleAnalysisSessionId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateUploadBundleSubscriptionVariables = {
  filter?: ModelSubscriptionUploadBundleFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUploadBundleSubscription = {
  onUpdateUploadBundle?:  {
    __typename: "UploadBundle",
    id: string,
    studyPhaseId?: string | null,
    studyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    bundleLog?: string | null,
    validationErrors?: string | null,
    status?: string | null,
    userEmail?: string | null,
    uploadBundleAnalysisSession?:  {
      __typename: "UploadBundleAnalysisSession",
      id: string,
      uploadSampleAnalysisSession?:  {
        __typename: "ModelUploadSampleAnalysisSessionConnection",
        nextToken?: string | null,
      } | null,
      metadataFileS3Path?: string | null,
      step?: string | null,
      listOfRawFileNames?: Array< string | null > | null,
      analysisType: AnalysisType,
      subtypeOfAnalysis: string,
      studyPhaseId: string,
      metadataHelperLambdaResponseS3Path?: string | null,
      barcodeToFilesToUploadS3Path?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null,
    uploadSamples?:  {
      __typename: "ModelUploadSampleConnection",
      items:  Array< {
        __typename: "UploadSample",
        id: string,
        uploadBundleId: string,
        sampleId: string,
        barcode: string,
        sampleLog?: string | null,
        writeMetadata: boolean,
        status?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        uploadBundleUploadSamplesId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    uploadFiles?:  {
      __typename: "ModelUploadFileConnection",
      items:  Array< {
        __typename: "UploadFile",
        id: string,
        uploadSampleId?: string | null,
        uploadBundleId?: string | null,
        multipartUploadId?: string | null,
        status?: UploadFileStatus | null,
        toSkipUpload: boolean,
        fileName: string,
        fileType: string,
        s3Path: string,
        size?: number | null,
        fileLog?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        uploadBundleUploadFilesId?: string | null,
        uploadSampleUploadFilesId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    adminGroups?: Array< string | null > | null,
    completionTime?: string | null,
    created: string,
    updated: string,
    studyPhaseUploadBundlesId?: string | null,
    uploadBundleUploadBundleAnalysisSessionId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteUploadBundleSubscriptionVariables = {
  filter?: ModelSubscriptionUploadBundleFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUploadBundleSubscription = {
  onDeleteUploadBundle?:  {
    __typename: "UploadBundle",
    id: string,
    studyPhaseId?: string | null,
    studyPhase?:  {
      __typename: "StudyPhase",
      id: string,
      studyId: string,
      study?:  {
        __typename: "Study",
        id: string,
        studyName: string,
        organizationId: string,
        description: string,
        studyCode: string,
        studyAnswers?: string | null,
        s3Path?: string | null,
        croGroups?: Array< string | null > | null,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        organizationStudiesId?: string | null,
      } | null,
      metaDataValidationSchemaTemplateId: string,
      metaDataValidationSchemaTemplate?:  {
        __typename: "MetaDataValidationSchemaTemplate",
        id: string,
        version: string,
        description?: string | null,
        templateFiles: string,
        validationSchemaTemplate: string,
        created: string,
        updated: string,
      } | null,
      groups?:  {
        __typename: "ModelGroupConnection",
        nextToken?: string | null,
      } | null,
      samples?:  {
        __typename: "ModelSampleConnection",
        nextToken?: string | null,
      } | null,
      batches?:  {
        __typename: "ModelBatchConnection",
        nextToken?: string | null,
      } | null,
      uploadBundles?:  {
        __typename: "ModelUploadBundleConnection",
        nextToken?: string | null,
      } | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      validationSchema?: string | null,
      description: string,
      studyPhaseName: string,
      studyPhaseAnswers?: string | null,
      experimentSpecificVariables?: string | null,
      sampleTypes?: Array< string | null > | null,
      specimens?: Array< string | null > | null,
      species?: Array< string | null > | null,
      s3Path?: string | null,
      groupNames?: Array< string | null > | null,
      labAssayTechnologies?: Array< string | null > | null,
      phase: Phase,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      adminGroups?: Array< string | null > | null,
      croGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      studyStudyPhasesId?: string | null,
      metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
    } | null,
    bundleLog?: string | null,
    validationErrors?: string | null,
    status?: string | null,
    userEmail?: string | null,
    uploadBundleAnalysisSession?:  {
      __typename: "UploadBundleAnalysisSession",
      id: string,
      uploadSampleAnalysisSession?:  {
        __typename: "ModelUploadSampleAnalysisSessionConnection",
        nextToken?: string | null,
      } | null,
      metadataFileS3Path?: string | null,
      step?: string | null,
      listOfRawFileNames?: Array< string | null > | null,
      analysisType: AnalysisType,
      subtypeOfAnalysis: string,
      studyPhaseId: string,
      metadataHelperLambdaResponseS3Path?: string | null,
      barcodeToFilesToUploadS3Path?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null,
    uploadSamples?:  {
      __typename: "ModelUploadSampleConnection",
      items:  Array< {
        __typename: "UploadSample",
        id: string,
        uploadBundleId: string,
        sampleId: string,
        barcode: string,
        sampleLog?: string | null,
        writeMetadata: boolean,
        status?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        uploadBundleUploadSamplesId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    uploadFiles?:  {
      __typename: "ModelUploadFileConnection",
      items:  Array< {
        __typename: "UploadFile",
        id: string,
        uploadSampleId?: string | null,
        uploadBundleId?: string | null,
        multipartUploadId?: string | null,
        status?: UploadFileStatus | null,
        toSkipUpload: boolean,
        fileName: string,
        fileType: string,
        s3Path: string,
        size?: number | null,
        fileLog?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        uploadBundleUploadFilesId?: string | null,
        uploadSampleUploadFilesId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    adminGroups?: Array< string | null > | null,
    completionTime?: string | null,
    created: string,
    updated: string,
    studyPhaseUploadBundlesId?: string | null,
    uploadBundleUploadBundleAnalysisSessionId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateUploadBundleAnalysisSessionSubscriptionVariables = {
  filter?: ModelSubscriptionUploadBundleAnalysisSessionFilterInput | null,
  owner?: string | null,
};

export type OnCreateUploadBundleAnalysisSessionSubscription = {
  onCreateUploadBundleAnalysisSession?:  {
    __typename: "UploadBundleAnalysisSession",
    id: string,
    uploadSampleAnalysisSession?:  {
      __typename: "ModelUploadSampleAnalysisSessionConnection",
      items:  Array< {
        __typename: "UploadSampleAnalysisSession",
        id: string,
        uploadBundleAnalysisSessionId: string,
        sampleId: string,
        rowFromMetaDataFileMappedToSample: string,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        uploadBundleAnalysisSessionUploadSampleAnalysisSessionId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    metadataFileS3Path?: string | null,
    step?: string | null,
    listOfRawFileNames?: Array< string | null > | null,
    analysisType: AnalysisType,
    subtypeOfAnalysis: string,
    studyPhaseId: string,
    metadataHelperLambdaResponseS3Path?: string | null,
    barcodeToFilesToUploadS3Path?: string | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateUploadBundleAnalysisSessionSubscriptionVariables = {
  filter?: ModelSubscriptionUploadBundleAnalysisSessionFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUploadBundleAnalysisSessionSubscription = {
  onUpdateUploadBundleAnalysisSession?:  {
    __typename: "UploadBundleAnalysisSession",
    id: string,
    uploadSampleAnalysisSession?:  {
      __typename: "ModelUploadSampleAnalysisSessionConnection",
      items:  Array< {
        __typename: "UploadSampleAnalysisSession",
        id: string,
        uploadBundleAnalysisSessionId: string,
        sampleId: string,
        rowFromMetaDataFileMappedToSample: string,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        uploadBundleAnalysisSessionUploadSampleAnalysisSessionId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    metadataFileS3Path?: string | null,
    step?: string | null,
    listOfRawFileNames?: Array< string | null > | null,
    analysisType: AnalysisType,
    subtypeOfAnalysis: string,
    studyPhaseId: string,
    metadataHelperLambdaResponseS3Path?: string | null,
    barcodeToFilesToUploadS3Path?: string | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteUploadBundleAnalysisSessionSubscriptionVariables = {
  filter?: ModelSubscriptionUploadBundleAnalysisSessionFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUploadBundleAnalysisSessionSubscription = {
  onDeleteUploadBundleAnalysisSession?:  {
    __typename: "UploadBundleAnalysisSession",
    id: string,
    uploadSampleAnalysisSession?:  {
      __typename: "ModelUploadSampleAnalysisSessionConnection",
      items:  Array< {
        __typename: "UploadSampleAnalysisSession",
        id: string,
        uploadBundleAnalysisSessionId: string,
        sampleId: string,
        rowFromMetaDataFileMappedToSample: string,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        uploadBundleAnalysisSessionUploadSampleAnalysisSessionId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    metadataFileS3Path?: string | null,
    step?: string | null,
    listOfRawFileNames?: Array< string | null > | null,
    analysisType: AnalysisType,
    subtypeOfAnalysis: string,
    studyPhaseId: string,
    metadataHelperLambdaResponseS3Path?: string | null,
    barcodeToFilesToUploadS3Path?: string | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    owner?: string | null,
  } | null,
};

export type OnCreateUploadSampleSubscriptionVariables = {
  filter?: ModelSubscriptionUploadSampleFilterInput | null,
  owner?: string | null,
};

export type OnCreateUploadSampleSubscription = {
  onCreateUploadSample?:  {
    __typename: "UploadSample",
    id: string,
    uploadBundleId: string,
    uploadBundle?:  {
      __typename: "UploadBundle",
      id: string,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      bundleLog?: string | null,
      validationErrors?: string | null,
      status?: string | null,
      userEmail?: string | null,
      uploadBundleAnalysisSession?:  {
        __typename: "UploadBundleAnalysisSession",
        id: string,
        metadataFileS3Path?: string | null,
        step?: string | null,
        listOfRawFileNames?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis: string,
        studyPhaseId: string,
        metadataHelperLambdaResponseS3Path?: string | null,
        barcodeToFilesToUploadS3Path?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        owner?: string | null,
      } | null,
      uploadSamples?:  {
        __typename: "ModelUploadSampleConnection",
        nextToken?: string | null,
      } | null,
      uploadFiles?:  {
        __typename: "ModelUploadFileConnection",
        nextToken?: string | null,
      } | null,
      adminGroups?: Array< string | null > | null,
      completionTime?: string | null,
      created: string,
      updated: string,
      studyPhaseUploadBundlesId?: string | null,
      uploadBundleUploadBundleAnalysisSessionId?: string | null,
      owner?: string | null,
    } | null,
    sampleId: string,
    barcode: string,
    sampleLog?: string | null,
    writeMetadata: boolean,
    status?: string | null,
    uploadFiles?:  {
      __typename: "ModelUploadFileConnection",
      items:  Array< {
        __typename: "UploadFile",
        id: string,
        uploadSampleId?: string | null,
        uploadBundleId?: string | null,
        multipartUploadId?: string | null,
        status?: UploadFileStatus | null,
        toSkipUpload: boolean,
        fileName: string,
        fileType: string,
        s3Path: string,
        size?: number | null,
        fileLog?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        uploadBundleUploadFilesId?: string | null,
        uploadSampleUploadFilesId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    uploadBundleUploadSamplesId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateUploadSampleSubscriptionVariables = {
  filter?: ModelSubscriptionUploadSampleFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUploadSampleSubscription = {
  onUpdateUploadSample?:  {
    __typename: "UploadSample",
    id: string,
    uploadBundleId: string,
    uploadBundle?:  {
      __typename: "UploadBundle",
      id: string,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      bundleLog?: string | null,
      validationErrors?: string | null,
      status?: string | null,
      userEmail?: string | null,
      uploadBundleAnalysisSession?:  {
        __typename: "UploadBundleAnalysisSession",
        id: string,
        metadataFileS3Path?: string | null,
        step?: string | null,
        listOfRawFileNames?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis: string,
        studyPhaseId: string,
        metadataHelperLambdaResponseS3Path?: string | null,
        barcodeToFilesToUploadS3Path?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        owner?: string | null,
      } | null,
      uploadSamples?:  {
        __typename: "ModelUploadSampleConnection",
        nextToken?: string | null,
      } | null,
      uploadFiles?:  {
        __typename: "ModelUploadFileConnection",
        nextToken?: string | null,
      } | null,
      adminGroups?: Array< string | null > | null,
      completionTime?: string | null,
      created: string,
      updated: string,
      studyPhaseUploadBundlesId?: string | null,
      uploadBundleUploadBundleAnalysisSessionId?: string | null,
      owner?: string | null,
    } | null,
    sampleId: string,
    barcode: string,
    sampleLog?: string | null,
    writeMetadata: boolean,
    status?: string | null,
    uploadFiles?:  {
      __typename: "ModelUploadFileConnection",
      items:  Array< {
        __typename: "UploadFile",
        id: string,
        uploadSampleId?: string | null,
        uploadBundleId?: string | null,
        multipartUploadId?: string | null,
        status?: UploadFileStatus | null,
        toSkipUpload: boolean,
        fileName: string,
        fileType: string,
        s3Path: string,
        size?: number | null,
        fileLog?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        uploadBundleUploadFilesId?: string | null,
        uploadSampleUploadFilesId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    uploadBundleUploadSamplesId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteUploadSampleSubscriptionVariables = {
  filter?: ModelSubscriptionUploadSampleFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUploadSampleSubscription = {
  onDeleteUploadSample?:  {
    __typename: "UploadSample",
    id: string,
    uploadBundleId: string,
    uploadBundle?:  {
      __typename: "UploadBundle",
      id: string,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      bundleLog?: string | null,
      validationErrors?: string | null,
      status?: string | null,
      userEmail?: string | null,
      uploadBundleAnalysisSession?:  {
        __typename: "UploadBundleAnalysisSession",
        id: string,
        metadataFileS3Path?: string | null,
        step?: string | null,
        listOfRawFileNames?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis: string,
        studyPhaseId: string,
        metadataHelperLambdaResponseS3Path?: string | null,
        barcodeToFilesToUploadS3Path?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        owner?: string | null,
      } | null,
      uploadSamples?:  {
        __typename: "ModelUploadSampleConnection",
        nextToken?: string | null,
      } | null,
      uploadFiles?:  {
        __typename: "ModelUploadFileConnection",
        nextToken?: string | null,
      } | null,
      adminGroups?: Array< string | null > | null,
      completionTime?: string | null,
      created: string,
      updated: string,
      studyPhaseUploadBundlesId?: string | null,
      uploadBundleUploadBundleAnalysisSessionId?: string | null,
      owner?: string | null,
    } | null,
    sampleId: string,
    barcode: string,
    sampleLog?: string | null,
    writeMetadata: boolean,
    status?: string | null,
    uploadFiles?:  {
      __typename: "ModelUploadFileConnection",
      items:  Array< {
        __typename: "UploadFile",
        id: string,
        uploadSampleId?: string | null,
        uploadBundleId?: string | null,
        multipartUploadId?: string | null,
        status?: UploadFileStatus | null,
        toSkipUpload: boolean,
        fileName: string,
        fileType: string,
        s3Path: string,
        size?: number | null,
        fileLog?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        uploadBundleUploadFilesId?: string | null,
        uploadSampleUploadFilesId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    uploadBundleUploadSamplesId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateUploadSampleAnalysisSessionSubscriptionVariables = {
  filter?: ModelSubscriptionUploadSampleAnalysisSessionFilterInput | null,
  owner?: string | null,
};

export type OnCreateUploadSampleAnalysisSessionSubscription = {
  onCreateUploadSampleAnalysisSession?:  {
    __typename: "UploadSampleAnalysisSession",
    id: string,
    uploadBundleAnalysisSessionId: string,
    uploadBundleAnalysisSession?:  {
      __typename: "UploadBundleAnalysisSession",
      id: string,
      uploadSampleAnalysisSession?:  {
        __typename: "ModelUploadSampleAnalysisSessionConnection",
        nextToken?: string | null,
      } | null,
      metadataFileS3Path?: string | null,
      step?: string | null,
      listOfRawFileNames?: Array< string | null > | null,
      analysisType: AnalysisType,
      subtypeOfAnalysis: string,
      studyPhaseId: string,
      metadataHelperLambdaResponseS3Path?: string | null,
      barcodeToFilesToUploadS3Path?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null,
    sampleId: string,
    rowFromMetaDataFileMappedToSample: string,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    uploadBundleAnalysisSessionUploadSampleAnalysisSessionId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateUploadSampleAnalysisSessionSubscriptionVariables = {
  filter?: ModelSubscriptionUploadSampleAnalysisSessionFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUploadSampleAnalysisSessionSubscription = {
  onUpdateUploadSampleAnalysisSession?:  {
    __typename: "UploadSampleAnalysisSession",
    id: string,
    uploadBundleAnalysisSessionId: string,
    uploadBundleAnalysisSession?:  {
      __typename: "UploadBundleAnalysisSession",
      id: string,
      uploadSampleAnalysisSession?:  {
        __typename: "ModelUploadSampleAnalysisSessionConnection",
        nextToken?: string | null,
      } | null,
      metadataFileS3Path?: string | null,
      step?: string | null,
      listOfRawFileNames?: Array< string | null > | null,
      analysisType: AnalysisType,
      subtypeOfAnalysis: string,
      studyPhaseId: string,
      metadataHelperLambdaResponseS3Path?: string | null,
      barcodeToFilesToUploadS3Path?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null,
    sampleId: string,
    rowFromMetaDataFileMappedToSample: string,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    uploadBundleAnalysisSessionUploadSampleAnalysisSessionId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteUploadSampleAnalysisSessionSubscriptionVariables = {
  filter?: ModelSubscriptionUploadSampleAnalysisSessionFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUploadSampleAnalysisSessionSubscription = {
  onDeleteUploadSampleAnalysisSession?:  {
    __typename: "UploadSampleAnalysisSession",
    id: string,
    uploadBundleAnalysisSessionId: string,
    uploadBundleAnalysisSession?:  {
      __typename: "UploadBundleAnalysisSession",
      id: string,
      uploadSampleAnalysisSession?:  {
        __typename: "ModelUploadSampleAnalysisSessionConnection",
        nextToken?: string | null,
      } | null,
      metadataFileS3Path?: string | null,
      step?: string | null,
      listOfRawFileNames?: Array< string | null > | null,
      analysisType: AnalysisType,
      subtypeOfAnalysis: string,
      studyPhaseId: string,
      metadataHelperLambdaResponseS3Path?: string | null,
      barcodeToFilesToUploadS3Path?: string | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      owner?: string | null,
    } | null,
    sampleId: string,
    rowFromMetaDataFileMappedToSample: string,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    uploadBundleAnalysisSessionUploadSampleAnalysisSessionId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateUploadFileSubscriptionVariables = {
  filter?: ModelSubscriptionUploadFileFilterInput | null,
  owner?: string | null,
};

export type OnCreateUploadFileSubscription = {
  onCreateUploadFile?:  {
    __typename: "UploadFile",
    id: string,
    uploadSampleId?: string | null,
    uploadSample?:  {
      __typename: "UploadSample",
      id: string,
      uploadBundleId: string,
      uploadBundle?:  {
        __typename: "UploadBundle",
        id: string,
        studyPhaseId?: string | null,
        bundleLog?: string | null,
        validationErrors?: string | null,
        status?: string | null,
        userEmail?: string | null,
        adminGroups?: Array< string | null > | null,
        completionTime?: string | null,
        created: string,
        updated: string,
        studyPhaseUploadBundlesId?: string | null,
        uploadBundleUploadBundleAnalysisSessionId?: string | null,
        owner?: string | null,
      } | null,
      sampleId: string,
      barcode: string,
      sampleLog?: string | null,
      writeMetadata: boolean,
      status?: string | null,
      uploadFiles?:  {
        __typename: "ModelUploadFileConnection",
        nextToken?: string | null,
      } | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      uploadBundleUploadSamplesId?: string | null,
      owner?: string | null,
    } | null,
    uploadBundleId?: string | null,
    uploadBundle?:  {
      __typename: "UploadBundle",
      id: string,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      bundleLog?: string | null,
      validationErrors?: string | null,
      status?: string | null,
      userEmail?: string | null,
      uploadBundleAnalysisSession?:  {
        __typename: "UploadBundleAnalysisSession",
        id: string,
        metadataFileS3Path?: string | null,
        step?: string | null,
        listOfRawFileNames?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis: string,
        studyPhaseId: string,
        metadataHelperLambdaResponseS3Path?: string | null,
        barcodeToFilesToUploadS3Path?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        owner?: string | null,
      } | null,
      uploadSamples?:  {
        __typename: "ModelUploadSampleConnection",
        nextToken?: string | null,
      } | null,
      uploadFiles?:  {
        __typename: "ModelUploadFileConnection",
        nextToken?: string | null,
      } | null,
      adminGroups?: Array< string | null > | null,
      completionTime?: string | null,
      created: string,
      updated: string,
      studyPhaseUploadBundlesId?: string | null,
      uploadBundleUploadBundleAnalysisSessionId?: string | null,
      owner?: string | null,
    } | null,
    multipartUploadId?: string | null,
    status?: UploadFileStatus | null,
    toSkipUpload: boolean,
    fileName: string,
    fileType: string,
    s3Path: string,
    size?: number | null,
    fileLog?: string | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    uploadBundleUploadFilesId?: string | null,
    uploadSampleUploadFilesId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateUploadFileSubscriptionVariables = {
  filter?: ModelSubscriptionUploadFileFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUploadFileSubscription = {
  onUpdateUploadFile?:  {
    __typename: "UploadFile",
    id: string,
    uploadSampleId?: string | null,
    uploadSample?:  {
      __typename: "UploadSample",
      id: string,
      uploadBundleId: string,
      uploadBundle?:  {
        __typename: "UploadBundle",
        id: string,
        studyPhaseId?: string | null,
        bundleLog?: string | null,
        validationErrors?: string | null,
        status?: string | null,
        userEmail?: string | null,
        adminGroups?: Array< string | null > | null,
        completionTime?: string | null,
        created: string,
        updated: string,
        studyPhaseUploadBundlesId?: string | null,
        uploadBundleUploadBundleAnalysisSessionId?: string | null,
        owner?: string | null,
      } | null,
      sampleId: string,
      barcode: string,
      sampleLog?: string | null,
      writeMetadata: boolean,
      status?: string | null,
      uploadFiles?:  {
        __typename: "ModelUploadFileConnection",
        nextToken?: string | null,
      } | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      uploadBundleUploadSamplesId?: string | null,
      owner?: string | null,
    } | null,
    uploadBundleId?: string | null,
    uploadBundle?:  {
      __typename: "UploadBundle",
      id: string,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      bundleLog?: string | null,
      validationErrors?: string | null,
      status?: string | null,
      userEmail?: string | null,
      uploadBundleAnalysisSession?:  {
        __typename: "UploadBundleAnalysisSession",
        id: string,
        metadataFileS3Path?: string | null,
        step?: string | null,
        listOfRawFileNames?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis: string,
        studyPhaseId: string,
        metadataHelperLambdaResponseS3Path?: string | null,
        barcodeToFilesToUploadS3Path?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        owner?: string | null,
      } | null,
      uploadSamples?:  {
        __typename: "ModelUploadSampleConnection",
        nextToken?: string | null,
      } | null,
      uploadFiles?:  {
        __typename: "ModelUploadFileConnection",
        nextToken?: string | null,
      } | null,
      adminGroups?: Array< string | null > | null,
      completionTime?: string | null,
      created: string,
      updated: string,
      studyPhaseUploadBundlesId?: string | null,
      uploadBundleUploadBundleAnalysisSessionId?: string | null,
      owner?: string | null,
    } | null,
    multipartUploadId?: string | null,
    status?: UploadFileStatus | null,
    toSkipUpload: boolean,
    fileName: string,
    fileType: string,
    s3Path: string,
    size?: number | null,
    fileLog?: string | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    uploadBundleUploadFilesId?: string | null,
    uploadSampleUploadFilesId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteUploadFileSubscriptionVariables = {
  filter?: ModelSubscriptionUploadFileFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUploadFileSubscription = {
  onDeleteUploadFile?:  {
    __typename: "UploadFile",
    id: string,
    uploadSampleId?: string | null,
    uploadSample?:  {
      __typename: "UploadSample",
      id: string,
      uploadBundleId: string,
      uploadBundle?:  {
        __typename: "UploadBundle",
        id: string,
        studyPhaseId?: string | null,
        bundleLog?: string | null,
        validationErrors?: string | null,
        status?: string | null,
        userEmail?: string | null,
        adminGroups?: Array< string | null > | null,
        completionTime?: string | null,
        created: string,
        updated: string,
        studyPhaseUploadBundlesId?: string | null,
        uploadBundleUploadBundleAnalysisSessionId?: string | null,
        owner?: string | null,
      } | null,
      sampleId: string,
      barcode: string,
      sampleLog?: string | null,
      writeMetadata: boolean,
      status?: string | null,
      uploadFiles?:  {
        __typename: "ModelUploadFileConnection",
        nextToken?: string | null,
      } | null,
      adminGroups?: Array< string | null > | null,
      created: string,
      updated: string,
      uploadBundleUploadSamplesId?: string | null,
      owner?: string | null,
    } | null,
    uploadBundleId?: string | null,
    uploadBundle?:  {
      __typename: "UploadBundle",
      id: string,
      studyPhaseId?: string | null,
      studyPhase?:  {
        __typename: "StudyPhase",
        id: string,
        studyId: string,
        metaDataValidationSchemaTemplateId: string,
        validationSchema?: string | null,
        description: string,
        studyPhaseName: string,
        studyPhaseAnswers?: string | null,
        experimentSpecificVariables?: string | null,
        sampleTypes?: Array< string | null > | null,
        specimens?: Array< string | null > | null,
        species?: Array< string | null > | null,
        s3Path?: string | null,
        groupNames?: Array< string | null > | null,
        labAssayTechnologies?: Array< string | null > | null,
        phase: Phase,
        readGroups?: Array< string | null > | null,
        writeGroups?: Array< string | null > | null,
        adminGroups?: Array< string | null > | null,
        croGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        studyStudyPhasesId?: string | null,
        metaDataValidationSchemaTemplateStudyPhasesId?: string | null,
      } | null,
      bundleLog?: string | null,
      validationErrors?: string | null,
      status?: string | null,
      userEmail?: string | null,
      uploadBundleAnalysisSession?:  {
        __typename: "UploadBundleAnalysisSession",
        id: string,
        metadataFileS3Path?: string | null,
        step?: string | null,
        listOfRawFileNames?: Array< string | null > | null,
        analysisType: AnalysisType,
        subtypeOfAnalysis: string,
        studyPhaseId: string,
        metadataHelperLambdaResponseS3Path?: string | null,
        barcodeToFilesToUploadS3Path?: string | null,
        adminGroups?: Array< string | null > | null,
        created: string,
        updated: string,
        owner?: string | null,
      } | null,
      uploadSamples?:  {
        __typename: "ModelUploadSampleConnection",
        nextToken?: string | null,
      } | null,
      uploadFiles?:  {
        __typename: "ModelUploadFileConnection",
        nextToken?: string | null,
      } | null,
      adminGroups?: Array< string | null > | null,
      completionTime?: string | null,
      created: string,
      updated: string,
      studyPhaseUploadBundlesId?: string | null,
      uploadBundleUploadBundleAnalysisSessionId?: string | null,
      owner?: string | null,
    } | null,
    multipartUploadId?: string | null,
    status?: UploadFileStatus | null,
    toSkipUpload: boolean,
    fileName: string,
    fileType: string,
    s3Path: string,
    size?: number | null,
    fileLog?: string | null,
    adminGroups?: Array< string | null > | null,
    created: string,
    updated: string,
    uploadBundleUploadFilesId?: string | null,
    uploadSampleUploadFilesId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateOrganizationLaunchablePipelinesSubscriptionVariables = {
  filter?: ModelSubscriptionOrganizationLaunchablePipelinesFilterInput | null,
};

export type OnCreateOrganizationLaunchablePipelinesSubscription = {
  onCreateOrganizationLaunchablePipelines?:  {
    __typename: "OrganizationLaunchablePipelines",
    id: string,
    organizationId: string,
    launchablePipelinesId: string,
    organization:  {
      __typename: "Organization",
      id: string,
      organizationName: string,
      organizationCode?: string | null,
      active: boolean,
      studies?:  {
        __typename: "ModelStudyConnection",
        nextToken?: string | null,
      } | null,
      launchablePipelines?:  {
        __typename: "ModelOrganizationLaunchablePipelinesConnection",
        nextToken?: string | null,
      } | null,
      notificationEmail: string,
      description: string,
      organizationAnswers?: string | null,
      url: string,
      s3Path?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      created: string,
      updated: string,
    },
    launchablePipelines:  {
      __typename: "LaunchablePipelines",
      id: string,
      pipelineName?: string | null,
      organizations?:  {
        __typename: "ModelOrganizationLaunchablePipelinesConnection",
        nextToken?: string | null,
      } | null,
      pipelineVersion?: string | null,
      parameters?: string | null,
      description?: string | null,
      inputFileInfo?: string | null,
      supportedAnalyses?: Array< AnalysisType | null > | null,
      supportedSubtypeOfAnalyses?: Array< string | null > | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      gitRepo?: string | null,
      workflowId?: string | null,
      created: string,
      updated: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateOrganizationLaunchablePipelinesSubscriptionVariables = {
  filter?: ModelSubscriptionOrganizationLaunchablePipelinesFilterInput | null,
};

export type OnUpdateOrganizationLaunchablePipelinesSubscription = {
  onUpdateOrganizationLaunchablePipelines?:  {
    __typename: "OrganizationLaunchablePipelines",
    id: string,
    organizationId: string,
    launchablePipelinesId: string,
    organization:  {
      __typename: "Organization",
      id: string,
      organizationName: string,
      organizationCode?: string | null,
      active: boolean,
      studies?:  {
        __typename: "ModelStudyConnection",
        nextToken?: string | null,
      } | null,
      launchablePipelines?:  {
        __typename: "ModelOrganizationLaunchablePipelinesConnection",
        nextToken?: string | null,
      } | null,
      notificationEmail: string,
      description: string,
      organizationAnswers?: string | null,
      url: string,
      s3Path?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      created: string,
      updated: string,
    },
    launchablePipelines:  {
      __typename: "LaunchablePipelines",
      id: string,
      pipelineName?: string | null,
      organizations?:  {
        __typename: "ModelOrganizationLaunchablePipelinesConnection",
        nextToken?: string | null,
      } | null,
      pipelineVersion?: string | null,
      parameters?: string | null,
      description?: string | null,
      inputFileInfo?: string | null,
      supportedAnalyses?: Array< AnalysisType | null > | null,
      supportedSubtypeOfAnalyses?: Array< string | null > | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      gitRepo?: string | null,
      workflowId?: string | null,
      created: string,
      updated: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteOrganizationLaunchablePipelinesSubscriptionVariables = {
  filter?: ModelSubscriptionOrganizationLaunchablePipelinesFilterInput | null,
};

export type OnDeleteOrganizationLaunchablePipelinesSubscription = {
  onDeleteOrganizationLaunchablePipelines?:  {
    __typename: "OrganizationLaunchablePipelines",
    id: string,
    organizationId: string,
    launchablePipelinesId: string,
    organization:  {
      __typename: "Organization",
      id: string,
      organizationName: string,
      organizationCode?: string | null,
      active: boolean,
      studies?:  {
        __typename: "ModelStudyConnection",
        nextToken?: string | null,
      } | null,
      launchablePipelines?:  {
        __typename: "ModelOrganizationLaunchablePipelinesConnection",
        nextToken?: string | null,
      } | null,
      notificationEmail: string,
      description: string,
      organizationAnswers?: string | null,
      url: string,
      s3Path?: string | null,
      readGroups?: Array< string | null > | null,
      writeGroups?: Array< string | null > | null,
      created: string,
      updated: string,
    },
    launchablePipelines:  {
      __typename: "LaunchablePipelines",
      id: string,
      pipelineName?: string | null,
      organizations?:  {
        __typename: "ModelOrganizationLaunchablePipelinesConnection",
        nextToken?: string | null,
      } | null,
      pipelineVersion?: string | null,
      parameters?: string | null,
      description?: string | null,
      inputFileInfo?: string | null,
      supportedAnalyses?: Array< AnalysisType | null > | null,
      supportedSubtypeOfAnalyses?: Array< string | null > | null,
      pipelines?:  {
        __typename: "ModelPipelineConnection",
        nextToken?: string | null,
      } | null,
      gitRepo?: string | null,
      workflowId?: string | null,
      created: string,
      updated: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};
