
import { defineComponent } from 'vue';
import {
  API, Auth, Hub, graphqlOperation,
} from 'aws-amplify';
// eslint-disable-next-line no-unused-vars
import { sendNotificationEmail } from '@/utils';
import * as mutations from '@/graphql/mutations';

export default defineComponent({
  name: 'Login',
  data() {
    return {
      signUp: false,
      listener: null as unknown,
      signUpTab: null,
      hubListenerCancelToken: null as unknown,
    };
  },
  async mounted() {
    this.setEventListenerToSignInButton();
    let authCredentials: any;
    try {
      authCredentials = await Auth.currentCredentials();
      if (authCredentials?.authenticated) {
        this.$router.push('/');
      }
    } catch (error) {
      console.error(error);
    }
    this.listener = (data: any):any => {
      switch (data.payload.event) {
        case 'signIn':
          localStorage.setItem('timeSinceActive', Date.now().toString());
          if (this.signUp) {
            let cognitoUserId: string = '';
            let userEmail: string = '';
            try {
              if ('attributes' in data.payload.data) {
                cognitoUserId = data.payload.data.username;
                userEmail = data.payload.data.attributes.email;
              } else {
                cognitoUserId = data.payload.data.userSub;
                userEmail = data.payload.data.user.username;
              }
            } catch (error) {
              console.error('Error when setting username and email');
              console.log('data :>> ', data);
              console.error(error);
            }
            console.log('data :>> ', data);
            console.log('cognitoUserId :>> ', cognitoUserId);
            console.log('userEmail :>> ', userEmail);
            // sendNotificationEmail(`A new user has signed in. User's email: '${userEmail}'`, ['filip.gunic@m2m.bio'], true);
            this.$store.dispatch('setNewUserData', { id: cognitoUserId, email: userEmail });
            this.createUserObj(cognitoUserId, userEmail);
            this.$store.dispatch('setShowingAddCustomOrg', true);
            this.signUp = false;
          } else this.$router.push('/');
          break;
        case 'signUp':
          this.signUp = true;
          break;
        default:
          break;
      }
    };
    this.hubListenerCancelToken = Hub.listen('auth', this.listener as any);
  },
  methods: {
    createUserObj(id : string, email: string) {
      try {
        const userVariables = {
          id,
          email,
        };
        console.log('userVariables :>> ', userVariables);
        API.graphql(graphqlOperation(mutations.createUser, { input: userVariables }));
      } catch (error) {
        console.error(error);
      }
    },
    setEventListenerToSignInButton() {
      setTimeout(() => {
        for (const btn of document.querySelectorAll('button')) {
          if (btn?.textContent?.includes('Sign in')) {
            btn.addEventListener('click', () => {
              setTimeout(() => this.addHelpTextBellowHeading(), 900);
            });
          }
        }
      }, 500);
    },
    addHelpTextBellowHeading() {
      const parent = this.scanForParentElement();
      if (!parent) {
        setTimeout(() => this.scanForParentElement(), 500);
      }
    },
    scanForParentElement() {
      let parent: HTMLElement | null = null;
      for (const a of document.querySelectorAll('h3')) {
        if (a?.textContent?.includes(' Setup TOTP ')) {
          parent = a;
          const el = document.createElement('span');
          el.innerHTML = 'Please scan this QR code with an authentication application like Authy. Thank you';
          a?.parentNode?.insertBefore(el, a.nextSibling);
        }
      }
      return parent;
    },
  },
  computed: {
  },
});
