// hash-worker-pool.ts

// Type for a hashing job in the queue
interface HashJob {
    id: number; // unique ID for tracking
    partBuffer: ArrayBuffer; // the data to hash
    // eslint-disable-next-line no-unused-vars
    resolve: (value: string) => void; // promise resolve for success
    // eslint-disable-next-line no-unused-vars
    reject: (reason?: any) => void; // promise reject for error
  }
  
export class HashWorkerPool {
    private workers: Worker[] = [];
    private queue: HashJob[] = [];
    private nextJobId = 1; // simple incremental ID
    private idleWorkers: number[] = []; // which worker indices are free
    private jobMap = new Map<number, HashJob>(); // jobId -> HashJob
  
    constructor(
      // eslint-disable-next-line no-unused-vars
      private workerUrl: URL, // e.g. URL to compiled 'hash-worker.js'
      // eslint-disable-next-line no-unused-vars
      private poolSize = 2, // number of worker instances
    ) {
      this.initWorkers();
    }
  
    private initWorkers() {
      for (let i = 0; i < this.poolSize; i += 1) {
        const worker = new Worker(this.workerUrl, { type: 'module' });
  
        // Listen for messages
        worker.onmessage = (e: MessageEvent) => {
          const { jobId, checksumSHA256, error } = e.data;
          this.onWorkerMessage(i, jobId, checksumSHA256, error);
        };
  
        // We store the worker in an array
        this.workers.push(worker);
        // Initially, all are idle
        this.idleWorkers.push(i);
      }
    }
  
    private onWorkerMessage(
      workerIndex: number,
      jobId: number,
      checksumSHA256?: string,
      error?: string,
    ) {
      // The worker is now idle again
      this.idleWorkers.push(workerIndex);
  
      const job = this.jobMap.get(jobId);
      if (!job) {
        // Shouldn't happen, but just in case
        console.warn(`No job found for jobId=${jobId}`);
        return;
      }
      this.jobMap.delete(jobId);
  
      if (error) {
        job.reject(error);
      } else if (checksumSHA256) {
        job.resolve(checksumSHA256);
      }
  
      // Try to process the next job in the queue
      this.processQueue();
    }
  
    // The function you call from outside to queue a job
    public enqueueHashJob(partBuffer: ArrayBuffer): Promise<string> {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line no-plusplus
        const jobId = this.nextJobId++;
        const job: HashJob = {
          id: jobId, partBuffer, resolve, reject, 
        };
  
        // Store in the queue
        this.queue.push(job);
  
        // Try to process immediately if a worker is free
        this.processQueue();
      });
    }
  
    private processQueue() {
      // While we have idle workers AND jobs waiting:
      while (this.idleWorkers.length > 0 && this.queue.length > 0) {
        const workerIndex = this.idleWorkers.pop() as number;
        const job = this.queue.shift() as HashJob;
  
        this.jobMap.set(job.id, job);
  
        // Transfer the ArrayBuffer to reduce copying
        this.workers[workerIndex].postMessage(
          { jobId: job.id, partBuffer: job.partBuffer },
          [job.partBuffer],
        );
      }
    }
  
    // Graceful shutdown
    public terminateAll() {
      for (const w of this.workers) {
        w.terminate();
      }
      this.workers = [];
    }
}
