
import {
  listItems, formatDateToHumanReadableFull, remapPipelines,
} from '@/utils';
import { defineComponent, PropType } from 'vue';
// eslint-disable-next-line import/no-extraneous-dependencies, no-unused-vars
import { GraphQLResult } from '@aws-amplify/api';
// eslint-disable-next-line no-unused-vars
import {
  // eslint-disable-next-line no-unused-vars
  BatchTemplate, PipelineTemplate, StudyTemplate, StudyPhaseTemplate,
} from '@/models/customModels';
import * as customQueries from '@/graphql/customQueries';
import { AnalysisType, PipelineStatus, Visualizations } from '@/models';
import MiniStudyTableForVizSelect from '@/components/Study/MiniStudyTableForVizSelect.vue';
import MiniStudyPhaseTableForVizSelect from '@/components/StudyPhase/MiniStudyPhaseTableForVizSelect.vue';

const CryptoJS = require('crypto-js');

export default defineComponent({
  name: 'Launch Visualization',
  props: {
    batchesToVisualize: { type: Object as PropType<BatchTemplate[]>, required: false },
  },
  components: {
    MiniStudyTableForVizSelect,
    MiniStudyPhaseTableForVizSelect,
  },
  data() {
    return {
      loading: false as boolean,
      loadingVizDD: false as boolean,
      showing: false as boolean,
      pipelines: [] as unknown as PipelineTemplate[],
      selectedPipelines: [] as unknown as PipelineTemplate[],
      allVisualizations: [] as unknown as Visualizations[],
      visualizationOptions: [] as unknown as Visualizations[],
      selectedVisualization: null as unknown as Visualizations | null,
      selectedStudy: null as unknown as StudyTemplate | null,
      selectedStudyPhase: null as unknown as StudyPhaseTemplate | null,
    };
  },
  async mounted() {
    this.allVisualizations = await this.loadVisualizations();
  },
  unmounted() {
    if (this.$store.state.showingLaunchVisualizationDialog) this.$store.dispatch('setShowingLaunchVisualizationDialog', false);
  },
  methods: {
    async main(batches: BatchTemplate[]): Promise<void> {
      this.loading = true;
      this.pipelines = remapPipelines(await this.loadPipelines(batches));
      this.loading = false;
    },
    async loadPipelines(batches: BatchTemplate[]): Promise<PipelineTemplate[]> {
      return (await Promise.all(batches.map((batch) => listItems(customQueries.pipelinesByBatchForVisualizationBatch, { batchId: batch.id, filter: { or: [{ status: { eq: PipelineStatus.FINISHED } }, { status: { eq: PipelineStatus.COMPLETED } }] } }))) as Array<PipelineTemplate[]>).flat();
    },
    async loadVisualizations(): Promise<Visualizations[]> {
      return listItems(customQueries.listVisualizations);
    },
    generateVisualizationCipher(pipelines: PipelineTemplate[], selectedViz: Visualizations): string {
      console.log('pipelines in generateVisualizationCipher:>> ', pipelines);
      const selectedPipelinesExtracted = pipelines.map((pipeline) => ({
        id: pipeline.id,
        pipelineOutputS3Path: pipeline.s3Results,
        runId: pipeline.runId,
        study: pipeline.study,
        studyPhase: pipeline.studyPhase,
      }));
      const secretKey = process.env.VUE_APP_ROUTE_ENCRYPTION_KEY;
      const message = JSON.stringify({
        pipelines: selectedPipelinesExtracted,
        visualization: { // TODO: Hardcoded
          id: selectedViz.id,
          deploymentPath: selectedViz.deploymentPath,
          visualizationName: selectedViz.visualizationName,
        },
      });
      return CryptoJS.AES.encrypt(message, secretKey).toString().replace(/\//g, '!');
    },
    async visualize() {
      if (!this.selectedVisualization) {
        console.error('Visualization bad in visualize');
        return;
      }
      if (this.batchesToVisualize) {
        const cipherText = this.generateVisualizationCipher(this.selectedPipelines, this.selectedVisualization);
        console.log('cipherText :>> ', cipherText);
        this.$router.push({ path: `/${this.$route.params.organizationName}/${this.$route.params.organizationId}/visualize/${cipherText}` });
      } else {
        const selectedPipelinesExtracted = this.selectedPipelines.map((pipeline) => ({
          id: pipeline.id,
          pipelineOutputS3Path: pipeline.s3Results,
          runId: pipeline.runId,
        }));
        this.$emit('visualizationAdded', { visualization: this.selectedVisualization, pipelines: selectedPipelinesExtracted });
      }
      this.hideDialog();
    },
    openVisualizationForLocalTesting() {
      try {
        const localTestingMockViz: Object = {
          deploymentPath: 'http://localhost:8080/',
          id: '1234Testing',
          supportedAnalyses: [AnalysisType[this.selectedPipelines.at(0)!.analysisType as keyof typeof AnalysisType]],
          visualizationName: 'LocalTest',
        };
        this.selectedVisualization = localTestingMockViz as Visualizations;
        this.visualize();
      } catch (error) {
        console.error(error);
      }
    },
    async studySelected(study: StudyTemplate) {
      console.log('study in studySelected :>> ', study);
      if (!study) {
        console.error('Error. Study is bad in studySelected');
        return;
      }
      this.selectedStudy = study;
      this.pipelines = remapPipelines(await this.pipelinesByStudy(study.id as string));
      console.log('this.pipelines for study :>> ', this.pipelines);
    },
    async pipelinesByStudy(studyId: string): Promise<PipelineTemplate[]> {
      return listItems(customQueries.pipelinesByStudySmaller, { studyId });
    },
    async studyPhaseSelected(studyPhase: StudyPhaseTemplate) {
      console.log('studyPhase :>> ', studyPhase);
      this.selectedStudyPhase = studyPhase;
      this.pipelines = remapPipelines(await this.pipelinesByStudyPhase(studyPhase.id as string));
      console.log('this.pipelines for studyPhase :>> ', this.pipelines);
    },
    async pipelinesByStudyPhase(studyPhaseId: string): Promise<PipelineTemplate[]> {
      return listItems(customQueries.pipelinesByStudyPhaseSmaller, { studyPhaseId });
    },
    checkIfValidSubtypesExists(): boolean {
      const possibleSubtypes = Array.from(new Set(this.selectedPipelines.map((pipeline) => pipeline.subtypeOfAnalysis))) as string[];
      console.log('possibleSubtypes :>> ', possibleSubtypes);
      if (possibleSubtypes.length > 0) {
        this.visualizationOptions = this.allVisualizations.filter((viz) => possibleSubtypes.every((vizType) => viz.supportedAnalyses?.includes(vizType)));
        if (this.visualizationOptions.length === 1) {
          this.selectedVisualization = this.visualizationOptions[0];
          console.log('this.selectedVisualization :>> ', this.selectedVisualization);
          this.loadingVizDD = false;
          return true;
        }
      }
      return false;
    },
    checkIfValidTypesExists() {
      console.log('this.selectedPipelines :>> ', this.selectedPipelines);
      const possibleVizTypes: string[] = Array.from(new Set(this.selectedPipelines.map((pipeline) => pipeline.analysisType))) as string[];

      console.log('possibleVizTypes :>> ', possibleVizTypes);
      if (possibleVizTypes.length === 0) {
        this.selectedVisualization = null;
        return;
      }
      console.log('this.allVisualizations :>> ', this.allVisualizations);
      this.visualizationOptions = this.allVisualizations.filter((viz) => possibleVizTypes.every((vizType) => viz.supportedAnalyses?.includes(vizType)));
      console.log('this.visualizationOptions :>> ', this.visualizationOptions);
      if (this.visualizationOptions.length === 1) {
        this.selectedVisualization = this.visualizationOptions[0];
      } else {
        this.selectedVisualization = null;
      }
      console.log('this.selectedVisualization :>> ', this.selectedVisualization);
      this.loadingVizDD = false;
    },
    async back() {
      if (this.selectedStudyPhase) {
        this.selectedStudyPhase = null;
        this.pipelines = remapPipelines(await this.pipelinesByStudy(this.selectedStudy?.id as string));
        return;
      }
      if (this.selectedStudy) {
        this.selectedStudy = null;
      }
    },
    getAdditionalPipelineTableHeaderMessage() {
      if (this.selectedStudy) return ` from Study: ${this.selectedStudy.studyName}`;
      if (this.selectedStudyPhase) return ` from Study Phase: ${this.selectedStudyPhase?.studyPhaseName}`;
      return '';
    },
    formatDateToHumanReadableFull(date: Date): string {
      return formatDateToHumanReadableFull(date);
    },
    hideDialog() {
      this.loading = false;
      this.loadingVizDD = false;
      this.pipelines = [];
      this.selectedPipelines = [];
      this.visualizationOptions = [];
      this.selectedVisualization = null;
      this.selectedStudy = null;
      this.selectedStudyPhase = null;
      this.$store.dispatch('setShowingLaunchVisualizationDialog', false);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingLaunchVisualizationDialog': async function () {
      this.showing = this.$store.state.showingLaunchVisualizationDialog;
      if (this.$store.state.showingLaunchVisualizationDialog) {
        console.log('this.batchesToVisualize :>> ', this.batchesToVisualize);
        if (this.batchesToVisualize) this.main(this.batchesToVisualize);
      }
    },
    selectedPipelines() {
      this.loadingVizDD = true;
      const pipelineSelectionLimit = 5;
      if (this.selectedPipelines.length > pipelineSelectionLimit) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Selected too many pipelines',
          detail: `You cannot select more thant ${pipelineSelectionLimit} pipelines to visualize.`,
          life: 5000,
        });
        this.selectedPipelines = this.selectedPipelines.slice(0, pipelineSelectionLimit);
        return;
      }
      if (this.checkIfValidSubtypesExists()) return;
      this.checkIfValidTypesExists();
    },
  },
});
