export const schema = {
    "models": {
        "Organization": {
            "name": "Organization",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "organizationName": {
                    "name": "organizationName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "organizationCode": {
                    "name": "organizationCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "active": {
                    "name": "active",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "studies": {
                    "name": "studies",
                    "isArray": true,
                    "type": {
                        "model": "Study"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "organizationStudiesId"
                        ]
                    }
                },
                "launchablePipelines": {
                    "name": "launchablePipelines",
                    "isArray": true,
                    "type": {
                        "model": "OrganizationLaunchablePipelines"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "organization"
                        ]
                    }
                },
                "notificationEmail": {
                    "name": "notificationEmail",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "organizationAnswers": {
                    "name": "organizationAnswers",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "url": {
                    "name": "url",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "gitUrl": {
                    "name": "gitUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "s3Path": {
                    "name": "s3Path",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "readGroups": {
                    "name": "readGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "writeGroups": {
                    "name": "writeGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "created": {
                    "name": "created",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updated": {
                    "name": "updated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Organizations",
            "attributes": [
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "created",
                            "updatedAt": "updated"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "id"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "M2MAdmin",
                                    "M2MAdmin-backend"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "writeGroups",
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ],
                                "groupField": "groups"
                            }
                        ]
                    }
                }
            ]
        },
        "Study": {
            "name": "Study",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "studyName": {
                    "name": "studyName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "organizationId": {
                    "name": "organizationId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "organization": {
                    "name": "organization",
                    "isArray": false,
                    "type": {
                        "model": "Organization"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "organizationId"
                        ]
                    }
                },
                "studyPhases": {
                    "name": "studyPhases",
                    "isArray": true,
                    "type": {
                        "model": "StudyPhase"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "studyStudyPhasesId"
                        ]
                    }
                },
                "batches": {
                    "name": "batches",
                    "isArray": true,
                    "type": {
                        "model": "Batch"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "studyBatchesId"
                        ]
                    }
                },
                "pipelines": {
                    "name": "pipelines",
                    "isArray": true,
                    "type": {
                        "model": "Pipeline"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "studyPipelinesId"
                        ]
                    }
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "studyCode": {
                    "name": "studyCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "studyAnswers": {
                    "name": "studyAnswers",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "s3Path": {
                    "name": "s3Path",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "croGroups": {
                    "name": "croGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readGroups": {
                    "name": "readGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "writeGroups": {
                    "name": "writeGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "adminGroups": {
                    "name": "adminGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "created": {
                    "name": "created",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updated": {
                    "name": "updated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "organizationStudiesId": {
                    "name": "organizationStudiesId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Studies",
            "attributes": [
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "created",
                            "updatedAt": "updated"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "id"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byOrganization",
                        "queryField": "studiesByOrganization",
                        "fields": [
                            "organizationId",
                            "created"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "M2MAdmin",
                                    "M2MAdmin-backend"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "croGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "writeGroups",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "adminGroups",
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "groupField": "groups"
                            }
                        ]
                    }
                }
            ]
        },
        "StudyPhase": {
            "name": "StudyPhase",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "studyId": {
                    "name": "studyId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "study": {
                    "name": "study",
                    "isArray": false,
                    "type": {
                        "model": "Study"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "studyId"
                        ]
                    }
                },
                "metaDataValidationSchemaTemplateId": {
                    "name": "metaDataValidationSchemaTemplateId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "metaDataValidationSchemaTemplate": {
                    "name": "metaDataValidationSchemaTemplate",
                    "isArray": false,
                    "type": {
                        "model": "MetaDataValidationSchemaTemplate"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "metaDataValidationSchemaTemplateId"
                        ]
                    }
                },
                "groups": {
                    "name": "groups",
                    "isArray": true,
                    "type": {
                        "model": "Group"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "studyPhaseGroupsId"
                        ]
                    }
                },
                "samples": {
                    "name": "samples",
                    "isArray": true,
                    "type": {
                        "model": "Sample"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "studyPhaseSamplesId"
                        ]
                    }
                },
                "batches": {
                    "name": "batches",
                    "isArray": true,
                    "type": {
                        "model": "Batch"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "studyPhaseBatchesId"
                        ]
                    }
                },
                "uploadBundles": {
                    "name": "uploadBundles",
                    "isArray": true,
                    "type": {
                        "model": "UploadBundle"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "studyPhaseUploadBundlesId"
                        ]
                    }
                },
                "pipelines": {
                    "name": "pipelines",
                    "isArray": true,
                    "type": {
                        "model": "Pipeline"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "studyPhasePipelinesId"
                        ]
                    }
                },
                "validationSchema": {
                    "name": "validationSchema",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "studyPhaseName": {
                    "name": "studyPhaseName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "studyPhaseAnswers": {
                    "name": "studyPhaseAnswers",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "experimentSpecificVariables": {
                    "name": "experimentSpecificVariables",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "sampleTypes": {
                    "name": "sampleTypes",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "specimens": {
                    "name": "specimens",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "species": {
                    "name": "species",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "s3Path": {
                    "name": "s3Path",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "groupNames": {
                    "name": "groupNames",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "labAssayTechnologies": {
                    "name": "labAssayTechnologies",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "phase": {
                    "name": "phase",
                    "isArray": false,
                    "type": {
                        "enum": "Phase"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "readGroups": {
                    "name": "readGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "writeGroups": {
                    "name": "writeGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "adminGroups": {
                    "name": "adminGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "croGroups": {
                    "name": "croGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "created": {
                    "name": "created",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updated": {
                    "name": "updated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "studyStudyPhasesId": {
                    "name": "studyStudyPhasesId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "metaDataValidationSchemaTemplateStudyPhasesId": {
                    "name": "metaDataValidationSchemaTemplateStudyPhasesId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "StudyPhases",
            "attributes": [
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "created",
                            "updatedAt": "updated"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "id"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byStudy",
                        "queryField": "studyPhasesByStudy",
                        "fields": [
                            "studyId",
                            "created"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "M2MAdmin",
                                    "M2MAdmin-backend"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "croGroups",
                                "operations": [
                                    "read",
                                    "update"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "writeGroups",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "adminGroups",
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "groupField": "groups"
                            }
                        ]
                    }
                }
            ]
        },
        "EntityQuestions": {
            "name": "EntityQuestions",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "entityType": {
                    "name": "entityType",
                    "isArray": false,
                    "type": {
                        "enum": "EntityType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "questions": {
                    "name": "questions",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "created": {
                    "name": "created",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updated": {
                    "name": "updated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "EntityQuestions",
            "attributes": [
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "created",
                            "updatedAt": "updated"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "id"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byEntityType",
                        "queryField": "questionsByEntityType",
                        "fields": [
                            "entityType",
                            "created"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "M2MAdmin",
                                    "M2MAdmin-backend"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "allow": "private",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "MetaDataValidationSchemaTemplate": {
            "name": "MetaDataValidationSchemaTemplate",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "studyPhases": {
                    "name": "studyPhases",
                    "isArray": true,
                    "type": {
                        "model": "StudyPhase"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "metaDataValidationSchemaTemplateStudyPhasesId"
                        ]
                    }
                },
                "version": {
                    "name": "version",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "templateFiles": {
                    "name": "templateFiles",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": true,
                    "attributes": []
                },
                "validationSchemaTemplate": {
                    "name": "validationSchemaTemplate",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": true,
                    "attributes": []
                },
                "created": {
                    "name": "created",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updated": {
                    "name": "updated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "MetaDataValidationSchemaTemplates",
            "attributes": [
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "created",
                            "updatedAt": "updated"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "id"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "M2MAdmin",
                                    "M2MAdmin-backend"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "allow": "private",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Group": {
            "name": "Group",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "studyPhaseId": {
                    "name": "studyPhaseId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "studyPhase": {
                    "name": "studyPhase",
                    "isArray": false,
                    "type": {
                        "model": "StudyPhase"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "studyPhaseId"
                        ]
                    }
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "groupName": {
                    "name": "groupName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "s3Path": {
                    "name": "s3Path",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "patients": {
                    "name": "patients",
                    "isArray": true,
                    "type": {
                        "model": "Patient"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "groupPatientsId"
                        ]
                    }
                },
                "readGroups": {
                    "name": "readGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "writeGroups": {
                    "name": "writeGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "adminGroups": {
                    "name": "adminGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "created": {
                    "name": "created",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updated": {
                    "name": "updated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "studyPhaseGroupsId": {
                    "name": "studyPhaseGroupsId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Groups",
            "attributes": [
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "created",
                            "updatedAt": "updated"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "id"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byStudyPhase",
                        "queryField": "groupsByStudyPhase",
                        "fields": [
                            "studyPhaseId",
                            "created"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "M2MAdmin",
                                    "M2MAdmin-backend"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "writeGroups",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "adminGroups",
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "groupField": "groups"
                            }
                        ]
                    }
                }
            ]
        },
        "Patient": {
            "name": "Patient",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "groupId": {
                    "name": "groupId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "group": {
                    "name": "group",
                    "isArray": false,
                    "type": {
                        "model": "Group"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "groupId"
                        ]
                    }
                },
                "timepoints": {
                    "name": "timepoints",
                    "isArray": true,
                    "type": {
                        "model": "Timepoint"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "patientTimepointsId"
                        ]
                    }
                },
                "patientStudyPhaseId": {
                    "name": "patientStudyPhaseId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "patientStudyId": {
                    "name": "patientStudyId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "s3Path": {
                    "name": "s3Path",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "readGroups": {
                    "name": "readGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "writeGroups": {
                    "name": "writeGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "adminGroups": {
                    "name": "adminGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "created": {
                    "name": "created",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updated": {
                    "name": "updated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "groupPatientsId": {
                    "name": "groupPatientsId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Patients",
            "attributes": [
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "created",
                            "updatedAt": "updated"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "id"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByGroup",
                        "queryField": "patientsByGroup",
                        "fields": [
                            "groupId",
                            "created"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "M2MAdmin",
                                    "M2MAdmin-backend"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "writeGroups",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "adminGroups",
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "groupField": "groups"
                            }
                        ]
                    }
                }
            ]
        },
        "Timepoint": {
            "name": "Timepoint",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "patientId": {
                    "name": "patientId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "patient": {
                    "name": "patient",
                    "isArray": false,
                    "type": {
                        "model": "Patient"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "patientId"
                        ]
                    }
                },
                "samples": {
                    "name": "samples",
                    "isArray": true,
                    "type": {
                        "model": "Sample"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "timepointSamplesId"
                        ]
                    }
                },
                "timepointName": {
                    "name": "timepointName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "s3Path": {
                    "name": "s3Path",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "readGroups": {
                    "name": "readGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "writeGroups": {
                    "name": "writeGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "adminGroups": {
                    "name": "adminGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "created": {
                    "name": "created",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updated": {
                    "name": "updated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "patientTimepointsId": {
                    "name": "patientTimepointsId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Timepoints",
            "attributes": [
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "created",
                            "updatedAt": "updated"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "id"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byPatient",
                        "queryField": "timepointsByPatient",
                        "fields": [
                            "patientId",
                            "created"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "M2MAdmin",
                                    "M2MAdmin-backend"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "writeGroups",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "adminGroups",
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "groupField": "groups"
                            }
                        ]
                    }
                }
            ]
        },
        "Sample": {
            "name": "Sample",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "timepointId": {
                    "name": "timepointId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "timepoint": {
                    "name": "timepoint",
                    "isArray": false,
                    "type": {
                        "model": "Timepoint"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "timepointId"
                        ]
                    }
                },
                "studyPhaseId": {
                    "name": "studyPhaseId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "StudyPhase": {
                    "name": "StudyPhase",
                    "isArray": false,
                    "type": {
                        "model": "StudyPhase"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "studyPhaseId"
                        ]
                    }
                },
                "BatchId": {
                    "name": "BatchId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "Batch": {
                    "name": "Batch",
                    "isArray": false,
                    "type": {
                        "model": "Batch"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "BatchId"
                        ]
                    }
                },
                "metadataImmunopeptidomicsList": {
                    "name": "metadataImmunopeptidomicsList",
                    "isArray": true,
                    "type": {
                        "model": "MetadataImmunopeptidomics"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "sampleMetadataImmunopeptidomicsListId"
                        ]
                    }
                },
                "MetadataNanoStringList": {
                    "name": "MetadataNanoStringList",
                    "isArray": true,
                    "type": {
                        "model": "MetadataNanoString"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "sampleMetadataNanoStringListId"
                        ]
                    }
                },
                "MetadataRnaSeqList": {
                    "name": "MetadataRnaSeqList",
                    "isArray": true,
                    "type": {
                        "model": "MetadataRnaSeq"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "sampleMetadataRnaSeqListId"
                        ]
                    }
                },
                "AuxiliaryAnalysislList": {
                    "name": "AuxiliaryAnalysislList",
                    "isArray": true,
                    "type": {
                        "model": "AuxiliaryAnalysis"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "sampleAuxiliaryAnalysislListId"
                        ]
                    }
                },
                "domainSampleId": {
                    "name": "domainSampleId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "experimentSpecificVariables": {
                    "name": "experimentSpecificVariables",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "collectionDate": {
                    "name": "collectionDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "processingDate": {
                    "name": "processingDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "readGroups": {
                    "name": "readGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "writeGroups": {
                    "name": "writeGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "adminGroups": {
                    "name": "adminGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "created": {
                    "name": "created",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updated": {
                    "name": "updated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "studyPhaseSamplesId": {
                    "name": "studyPhaseSamplesId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "timepointSamplesId": {
                    "name": "timepointSamplesId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "batchSamplesId": {
                    "name": "batchSamplesId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Samples",
            "attributes": [
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "created",
                            "updatedAt": "updated"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "id"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTimepoint",
                        "queryField": "samplesByTimepoint",
                        "fields": [
                            "timepointId",
                            "created"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byStudyPhase",
                        "queryField": "samplesByStudyPhase",
                        "fields": [
                            "studyPhaseId",
                            "created"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byBatch",
                        "queryField": "samplesByBatch",
                        "fields": [
                            "BatchId",
                            "created"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "bySampleId",
                        "queryField": "sampleByDomainSampleId",
                        "fields": [
                            "domainSampleId",
                            "created"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "M2MAdmin",
                                    "M2MAdmin-backend"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "writeGroups",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "adminGroups",
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "groupField": "groups"
                            }
                        ]
                    }
                }
            ]
        },
        "Batch": {
            "name": "Batch",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "batchName": {
                    "name": "batchName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "batchAnnotations": {
                    "name": "batchAnnotations",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "batchGroups": {
                    "name": "batchGroups",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "annotations": {
                    "name": "annotations",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "controlSamples": {
                    "name": "controlSamples",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "filters": {
                    "name": "filters",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "samples": {
                    "name": "samples",
                    "isArray": true,
                    "type": {
                        "model": "Sample"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "batchSamplesId"
                        ]
                    }
                },
                "pipelines": {
                    "name": "pipelines",
                    "isArray": true,
                    "type": {
                        "model": "Pipeline"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "batchPipelinesId"
                        ]
                    }
                },
                "studyId": {
                    "name": "studyId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "study": {
                    "name": "study",
                    "isArray": false,
                    "type": {
                        "model": "Study"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "studyId"
                        ]
                    }
                },
                "studyPhaseId": {
                    "name": "studyPhaseId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "studyPhase": {
                    "name": "studyPhase",
                    "isArray": false,
                    "type": {
                        "model": "StudyPhase"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "studyPhaseId"
                        ]
                    }
                },
                "readGroups": {
                    "name": "readGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "writeGroups": {
                    "name": "writeGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "adminGroups": {
                    "name": "adminGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "created": {
                    "name": "created",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updated": {
                    "name": "updated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "studyBatchesId": {
                    "name": "studyBatchesId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "studyPhaseBatchesId": {
                    "name": "studyPhaseBatchesId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Batches",
            "attributes": [
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "created",
                            "updatedAt": "updated"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "id"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byStudy",
                        "queryField": "batchesByStudy",
                        "fields": [
                            "studyId",
                            "created"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byStudyPhase",
                        "queryField": "batchesByStudyPhase",
                        "fields": [
                            "studyPhaseId",
                            "created"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "M2MAdmin",
                                    "M2MAdmin-backend"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "writeGroups",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "adminGroups",
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "groupField": "groups"
                            }
                        ]
                    }
                }
            ]
        },
        "Pipeline": {
            "name": "Pipeline",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "PipelineStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "s3Event": {
                    "name": "s3Event",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "s3ParamsFile": {
                    "name": "s3ParamsFile",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "s3Results": {
                    "name": "s3Results",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "visFileInfoS3path": {
                    "name": "visFileInfoS3path",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "s3NfLog": {
                    "name": "s3NfLog",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "s3NfTasksLogs": {
                    "name": "s3NfTasksLogs",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "analysisType": {
                    "name": "analysisType",
                    "isArray": false,
                    "type": {
                        "enum": "AnalysisType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "subtypeOfAnalysis": {
                    "name": "subtypeOfAnalysis",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "parameters": {
                    "name": "parameters",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": true,
                    "attributes": []
                },
                "studyId": {
                    "name": "studyId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "study": {
                    "name": "study",
                    "isArray": false,
                    "type": {
                        "model": "Study"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "studyId"
                        ]
                    }
                },
                "studyPhaseId": {
                    "name": "studyPhaseId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "studyPhase": {
                    "name": "studyPhase",
                    "isArray": false,
                    "type": {
                        "model": "StudyPhase"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "studyPhaseId"
                        ]
                    }
                },
                "scheduledPipeline": {
                    "name": "scheduledPipeline",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "scheduledDateTime": {
                    "name": "scheduledDateTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "launchablePipelineId": {
                    "name": "launchablePipelineId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "launchablePipeline": {
                    "name": "launchablePipeline",
                    "isArray": false,
                    "type": {
                        "model": "LaunchablePipelines"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "launchablePipelineId"
                        ]
                    }
                },
                "batchId": {
                    "name": "batchId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "batch": {
                    "name": "batch",
                    "isArray": false,
                    "type": {
                        "model": "Batch"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "batchId"
                        ]
                    }
                },
                "runId": {
                    "name": "runId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "userEmail": {
                    "name": "userEmail",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "pipelineStarted": {
                    "name": "pipelineStarted",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "pipelineCompleted": {
                    "name": "pipelineCompleted",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "readGroups": {
                    "name": "readGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "writeGroups": {
                    "name": "writeGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "adminGroups": {
                    "name": "adminGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "created": {
                    "name": "created",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updated": {
                    "name": "updated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "studyPipelinesId": {
                    "name": "studyPipelinesId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "studyPhasePipelinesId": {
                    "name": "studyPhasePipelinesId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "batchPipelinesId": {
                    "name": "batchPipelinesId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "launchablePipelinesPipelinesId": {
                    "name": "launchablePipelinesPipelinesId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Pipelines",
            "attributes": [
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "created",
                            "updatedAt": "updated"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "id"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byStudy",
                        "queryField": "pipelinesByStudy",
                        "fields": [
                            "studyId",
                            "created"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byStudyPhase",
                        "queryField": "pipelinesByStudyPhase",
                        "fields": [
                            "studyPhaseId",
                            "created"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byLaunchablePipelines",
                        "queryField": "pipelinesByBLaunchablePipelines",
                        "fields": [
                            "launchablePipelineId",
                            "created"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byBatch",
                        "queryField": "pipelinesByBatch",
                        "fields": [
                            "batchId",
                            "created"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byRunId",
                        "queryField": "pipelineByRunId",
                        "fields": [
                            "runId",
                            "created"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "M2MAdmin",
                                    "M2MAdmin-backend"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "writeGroups",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "adminGroups",
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "groupField": "groups"
                            }
                        ]
                    }
                }
            ]
        },
        "AuxiliaryAnalysis": {
            "name": "AuxiliaryAnalysis",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "sampleId": {
                    "name": "sampleId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "sample": {
                    "name": "sample",
                    "isArray": false,
                    "type": {
                        "model": "Sample"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "sampleId"
                        ]
                    }
                },
                "auxiliaryFileS3Path": {
                    "name": "auxiliaryFileS3Path",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "vendorId": {
                    "name": "vendorId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "specimenType": {
                    "name": "specimenType",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "parameters": {
                    "name": "parameters",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": true,
                    "attributes": []
                },
                "collectionDate": {
                    "name": "collectionDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "readGroups": {
                    "name": "readGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "writeGroups": {
                    "name": "writeGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "adminGroups": {
                    "name": "adminGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "created": {
                    "name": "created",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updated": {
                    "name": "updated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "sampleAuxiliaryAnalysislListId": {
                    "name": "sampleAuxiliaryAnalysislListId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "AuxiliaryAnalyses",
            "attributes": [
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "created",
                            "updatedAt": "updated"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "id"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "bySample",
                        "queryField": "auxiliaryAnalysisBySample",
                        "fields": [
                            "sampleId",
                            "created"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "M2MAdmin",
                                    "M2MAdmin-backend"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "writeGroups",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "adminGroups",
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "groupField": "groups"
                            }
                        ]
                    }
                }
            ]
        },
        "MetadataImmunopeptidomics": {
            "name": "MetadataImmunopeptidomics",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "sampleId": {
                    "name": "sampleId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "sample": {
                    "name": "sample",
                    "isArray": false,
                    "type": {
                        "model": "Sample"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "sampleId"
                        ]
                    }
                },
                "treatment": {
                    "name": "treatment",
                    "isArray": true,
                    "type": {
                        "model": "Treatment"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "metadataImmunopeptidomicsTreatmentId"
                        ]
                    }
                },
                "metaDataTemplateS3Path": {
                    "name": "metaDataTemplateS3Path",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "species": {
                    "name": "species",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "subtypeOfAnalysis": {
                    "name": "subtypeOfAnalysis",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "sampleType": {
                    "name": "sampleType",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "qcStatus": {
                    "name": "qcStatus",
                    "isArray": false,
                    "type": {
                        "enum": "QcStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "glacierBackup": {
                    "name": "glacierBackup",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "rawDataS3": {
                    "name": "rawDataS3",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "tumorVolume": {
                    "name": "tumorVolume",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "readGroups": {
                    "name": "readGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "writeGroups": {
                    "name": "writeGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "adminGroups": {
                    "name": "adminGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "created": {
                    "name": "created",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updated": {
                    "name": "updated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "isControl": {
                    "name": "isControl",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "barcodesMappedToControl": {
                    "name": "barcodesMappedToControl",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "barcode": {
                    "name": "barcode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "s3Path": {
                    "name": "s3Path",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "sampleMetadataImmunopeptidomicsListId": {
                    "name": "sampleMetadataImmunopeptidomicsListId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "MetadataImmunopeptidomics",
            "attributes": [
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "created",
                            "updatedAt": "updated"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "id"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "bySample",
                        "queryField": "MetadataImmunopeptidomicsBySample",
                        "fields": [
                            "sampleId",
                            "created"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "M2MAdmin",
                                    "M2MAdmin-backend"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "writeGroups",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "adminGroups",
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "groupField": "groups"
                            }
                        ]
                    }
                }
            ]
        },
        "MetadataNanoString": {
            "name": "MetadataNanoString",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "sampleId": {
                    "name": "sampleId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "sample": {
                    "name": "sample",
                    "isArray": false,
                    "type": {
                        "model": "Sample"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "sampleId"
                        ]
                    }
                },
                "treatment": {
                    "name": "treatment",
                    "isArray": true,
                    "type": {
                        "model": "Treatment"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "metadataNanoStringTreatmentId"
                        ]
                    }
                },
                "metaDataTemplateS3Path": {
                    "name": "metaDataTemplateS3Path",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "subtypeOfAnalysis": {
                    "name": "subtypeOfAnalysis",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "species": {
                    "name": "species",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "sampleType": {
                    "name": "sampleType",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "qcStatus": {
                    "name": "qcStatus",
                    "isArray": false,
                    "type": {
                        "enum": "QcStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "glacierBackup": {
                    "name": "glacierBackup",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "rawDataS3": {
                    "name": "rawDataS3",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "tumorVolume": {
                    "name": "tumorVolume",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "dv200Percentage": {
                    "name": "dv200Percentage",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "concNanoGramPerMicroL": {
                    "name": "concNanoGramPerMicroL",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "rnaMassNanoGram": {
                    "name": "rnaMassNanoGram",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "rnaVolumeMicroL": {
                    "name": "rnaVolumeMicroL",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "rin": {
                    "name": "rin",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "readGroups": {
                    "name": "readGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "writeGroups": {
                    "name": "writeGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "adminGroups": {
                    "name": "adminGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "created": {
                    "name": "created",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updated": {
                    "name": "updated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "isControl": {
                    "name": "isControl",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "barcodesMappedToControl": {
                    "name": "barcodesMappedToControl",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "barcode": {
                    "name": "barcode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "s3Path": {
                    "name": "s3Path",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "sampleMetadataNanoStringListId": {
                    "name": "sampleMetadataNanoStringListId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "MetadataNanoStrings",
            "attributes": [
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "created",
                            "updatedAt": "updated"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "id"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "bySample",
                        "queryField": "MetadataNanoStringBySample",
                        "fields": [
                            "sampleId",
                            "created"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "M2MAdmin",
                                    "M2MAdmin-backend"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "writeGroups",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "adminGroups",
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "groupField": "groups"
                            }
                        ]
                    }
                }
            ]
        },
        "MetadataRnaSeq": {
            "name": "MetadataRnaSeq",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "sampleId": {
                    "name": "sampleId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "sample": {
                    "name": "sample",
                    "isArray": false,
                    "type": {
                        "model": "Sample"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "sampleId"
                        ]
                    }
                },
                "treatment": {
                    "name": "treatment",
                    "isArray": true,
                    "type": {
                        "model": "Treatment"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "metadataRnaSeqTreatmentId"
                        ]
                    }
                },
                "metaDataTemplateS3Path": {
                    "name": "metaDataTemplateS3Path",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "subtypeOfAnalysis": {
                    "name": "subtypeOfAnalysis",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "species": {
                    "name": "species",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "sampleType": {
                    "name": "sampleType",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "qcStatus": {
                    "name": "qcStatus",
                    "isArray": false,
                    "type": {
                        "enum": "QcStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "glacierBackup": {
                    "name": "glacierBackup",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "rawDataS3": {
                    "name": "rawDataS3",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "tumorVolume": {
                    "name": "tumorVolume",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "sequenceType": {
                    "name": "sequenceType",
                    "isArray": false,
                    "type": {
                        "enum": "SequenceType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "strandSpecific": {
                    "name": "strandSpecific",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "UMI": {
                    "name": "UMI",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "adaptorsRemoved": {
                    "name": "adaptorsRemoved",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "adaptorsS3Path": {
                    "name": "adaptorsS3Path",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "indexSequences": {
                    "name": "indexSequences",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "concNanoGramPerMicroL": {
                    "name": "concNanoGramPerMicroL",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "rnaMassNanoGram": {
                    "name": "rnaMassNanoGram",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "rnaVolumeMicroL": {
                    "name": "rnaVolumeMicroL",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "rin": {
                    "name": "rin",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "readGroups": {
                    "name": "readGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "writeGroups": {
                    "name": "writeGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "adminGroups": {
                    "name": "adminGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "created": {
                    "name": "created",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updated": {
                    "name": "updated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "isControl": {
                    "name": "isControl",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "barcodesMappedToControl": {
                    "name": "barcodesMappedToControl",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "barcode": {
                    "name": "barcode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "s3Path": {
                    "name": "s3Path",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "sampleMetadataRnaSeqListId": {
                    "name": "sampleMetadataRnaSeqListId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "MetadataRnaSeqs",
            "attributes": [
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "created",
                            "updatedAt": "updated"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "id"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "bySample",
                        "queryField": "MetadataRnaSeqBySample",
                        "fields": [
                            "sampleId",
                            "created"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "M2MAdmin",
                                    "M2MAdmin-backend"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "writeGroups",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "adminGroups",
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "groupField": "groups"
                            }
                        ]
                    }
                }
            ]
        },
        "Treatment": {
            "name": "Treatment",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "metadataImmunopeptidomicsId": {
                    "name": "metadataImmunopeptidomicsId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "metadataImmunopeptidomics": {
                    "name": "metadataImmunopeptidomics",
                    "isArray": false,
                    "type": {
                        "model": "MetadataImmunopeptidomics"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "metadataImmunopeptidomicsId"
                        ]
                    }
                },
                "metadataNanoStringId": {
                    "name": "metadataNanoStringId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "metadataNanoString": {
                    "name": "metadataNanoString",
                    "isArray": false,
                    "type": {
                        "model": "MetadataNanoString"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "metadataNanoStringId"
                        ]
                    }
                },
                "metadataRnaSeqId": {
                    "name": "metadataRnaSeqId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "metadataRnaSeq": {
                    "name": "metadataRnaSeq",
                    "isArray": false,
                    "type": {
                        "model": "MetadataRnaSeq"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "metadataRnaSeqId"
                        ]
                    }
                },
                "treatmentName": {
                    "name": "treatmentName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "dose": {
                    "name": "dose",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "doseUnit": {
                    "name": "doseUnit",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "readGroups": {
                    "name": "readGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "writeGroups": {
                    "name": "writeGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "adminGroups": {
                    "name": "adminGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "created": {
                    "name": "created",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updated": {
                    "name": "updated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "metadataImmunopeptidomicsTreatmentId": {
                    "name": "metadataImmunopeptidomicsTreatmentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "metadataNanoStringTreatmentId": {
                    "name": "metadataNanoStringTreatmentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "metadataRnaSeqTreatmentId": {
                    "name": "metadataRnaSeqTreatmentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Treatments",
            "attributes": [
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "created",
                            "updatedAt": "updated"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "id"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byMetadataImmunopeptidomicsId",
                        "queryField": "treatmentsByMetadataImmunopeptidomics",
                        "fields": [
                            "metadataImmunopeptidomicsId",
                            "created"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byMetadataNanoStringId",
                        "queryField": "treatmentsByMetadataNanoString",
                        "fields": [
                            "metadataNanoStringId",
                            "created"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byMetadataRnaSeq",
                        "queryField": "treatmentsByMetadataRnaSeq",
                        "fields": [
                            "metadataRnaSeqId",
                            "created"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "M2MAdmin",
                                    "M2MAdmin-backend"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "writeGroups",
                                "operations": [
                                    "read",
                                    "create",
                                    "update"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "adminGroups",
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "groupField": "groups"
                            }
                        ]
                    }
                }
            ]
        },
        "LaunchablePipelines": {
            "name": "LaunchablePipelines",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "pipelineName": {
                    "name": "pipelineName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "organizations": {
                    "name": "organizations",
                    "isArray": true,
                    "type": {
                        "model": "OrganizationLaunchablePipelines"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "launchablePipelines"
                        ]
                    }
                },
                "pipelineVersion": {
                    "name": "pipelineVersion",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "parameters": {
                    "name": "parameters",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "inputFileInfo": {
                    "name": "inputFileInfo",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "supportedAnalyses": {
                    "name": "supportedAnalyses",
                    "isArray": true,
                    "type": {
                        "enum": "AnalysisType"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "supportedSubtypeOfAnalyses": {
                    "name": "supportedSubtypeOfAnalyses",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "pipelines": {
                    "name": "pipelines",
                    "isArray": true,
                    "type": {
                        "model": "Pipeline"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "launchablePipelinesPipelinesId"
                        ]
                    }
                },
                "gitRepo": {
                    "name": "gitRepo",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "workflowId": {
                    "name": "workflowId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "created": {
                    "name": "created",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updated": {
                    "name": "updated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "LaunchablePipelines",
            "attributes": [
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "created",
                            "updatedAt": "updated"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "id"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byPipelineNameAndVersion",
                        "queryField": "launchablePipelinesByPipelineNameAndVersion",
                        "fields": [
                            "pipelineName",
                            "pipelineVersion"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "M2MAdmin",
                                    "M2MAdmin-backend"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "allow": "private",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "User": {
            "name": "User",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "email": {
                    "name": "email",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "organization": {
                    "name": "organization",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "gitToken": {
                    "name": "gitToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "gitName": {
                    "name": "gitName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "gitURL": {
                    "name": "gitURL",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "readGroups": {
                    "name": "readGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "created": {
                    "name": "created",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updated": {
                    "name": "updated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Users",
            "attributes": [
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "created",
                            "updatedAt": "updated"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byOrganization",
                        "queryField": "usersByOrganization",
                        "fields": [
                            "organization",
                            "created"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "M2MAdmin",
                                    "M2MAdmin-backend"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ],
                                "identityClaim": "cognito:username"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            }
                        ]
                    }
                }
            ]
        },
        "UploadLog": {
            "name": "UploadLog",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "uploadS3Path": {
                    "name": "uploadS3Path",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "readGroups": {
                    "name": "readGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "created": {
                    "name": "created",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "updated": {
                    "name": "updated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "UploadLogs",
            "attributes": [
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "created",
                            "updatedAt": "updated"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "id"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "M2MAdmin",
                                    "M2MAdmin-backend"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            }
                        ]
                    }
                }
            ]
        },
        "Visualizations": {
            "name": "Visualizations",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "visualizationName": {
                    "name": "visualizationName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "version": {
                    "name": "version",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "deploymentPath": {
                    "name": "deploymentPath",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "validationSchema": {
                    "name": "validationSchema",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "allowedExtensions": {
                    "name": "allowedExtensions",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "supportedAnalyses": {
                    "name": "supportedAnalyses",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "created": {
                    "name": "created",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updated": {
                    "name": "updated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Visualizations",
            "attributes": [
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "created",
                            "updatedAt": "updated"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "id"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "M2MAdmin",
                                    "M2MAdmin-backend"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "allow": "private",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "VisualizationSession": {
            "name": "VisualizationSession",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "statuses": {
                    "name": "statuses",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "data": {
                    "name": "data",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "created": {
                    "name": "created",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updated": {
                    "name": "updated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "VisualizationSessions",
            "attributes": [
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "created",
                            "updatedAt": "updated"
                        }
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "M2MAdmin",
                                    "M2MAdmin-backend"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "identityClaim": "cognito:username"
                            }
                        ]
                    }
                }
            ]
        },
        "DataLabEC2Instances": {
            "name": "DataLabEC2Instances",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "creationTimestamp": {
                    "name": "creationTimestamp",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "stoppedTimestamp": {
                    "name": "stoppedTimestamp",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "startTimestamp": {
                    "name": "startTimestamp",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "terminatedTimestamp": {
                    "name": "terminatedTimestamp",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "stoppedTimePeriod": {
                    "name": "stoppedTimePeriod",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "runningTimePeriod": {
                    "name": "runningTimePeriod",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "userEmail": {
                    "name": "userEmail",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "DataLabEc2InstancesStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "DataLabEc2InstancesType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "dataLabInstanceSessions": {
                    "name": "dataLabInstanceSessions",
                    "isArray": true,
                    "type": {
                        "model": "DataLabEC2InstanceSession"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "dataLabEC2InstancesDataLabInstanceSessionsId"
                        ]
                    }
                },
                "dataLabInstanceStatusChanges": {
                    "name": "dataLabInstanceStatusChanges",
                    "isArray": true,
                    "type": {
                        "model": "DataLabEC2InstanceStatusChange"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "dataLabEC2InstancesDataLabInstanceStatusChangesId"
                        ]
                    }
                },
                "organizationId": {
                    "name": "organizationId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "organizationName": {
                    "name": "organizationName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "instanceUrl": {
                    "name": "instanceUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "instanceId": {
                    "name": "instanceId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "infrastructureRelatedEntities": {
                    "name": "infrastructureRelatedEntities",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "instanceLaunchEvent": {
                    "name": "instanceLaunchEvent",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "adminGroups": {
                    "name": "adminGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "created": {
                    "name": "created",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updated": {
                    "name": "updated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "DataLabEC2Instances",
            "attributes": [
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "created",
                            "updatedAt": "updated"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byType",
                        "queryField": "dataLabEC2InstancesByType",
                        "fields": [
                            "type",
                            "created"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "M2MAdmin",
                                    "M2MAdmin-backend"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "identityClaim": "cognito:username"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "adminGroups",
                                "operations": [
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "groupField": "groups"
                            }
                        ]
                    }
                }
            ]
        },
        "DataLabEC2InstanceSession": {
            "name": "DataLabEC2InstanceSession",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "dataLabInstanceId": {
                    "name": "dataLabInstanceId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "dataLabInstance": {
                    "name": "dataLabInstance",
                    "isArray": false,
                    "type": {
                        "model": "DataLabEC2Instances"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "dataLabInstanceId"
                        ]
                    }
                },
                "s3Path": {
                    "name": "s3Path",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "instanceLaunchEvent": {
                    "name": "instanceLaunchEvent",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "DataLabEc2InstanceSessionStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "created": {
                    "name": "created",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updated": {
                    "name": "updated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "dataLabEC2InstancesDataLabInstanceSessionsId": {
                    "name": "dataLabEC2InstancesDataLabInstanceSessionsId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "DataLabEC2InstanceSessions",
            "attributes": [
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "created",
                            "updatedAt": "updated"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byDataLabInstance",
                        "queryField": "dataLabInstanceSessionsByDataLabInstance",
                        "fields": [
                            "dataLabInstanceId",
                            "created"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "M2MAdmin",
                                    "M2MAdmin-backend"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "identityClaim": "cognito:username"
                            }
                        ]
                    }
                }
            ]
        },
        "DataLabEC2InstanceStatusChange": {
            "name": "DataLabEC2InstanceStatusChange",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "dataLabInstanceId": {
                    "name": "dataLabInstanceId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "dataLabInstance": {
                    "name": "dataLabInstance",
                    "isArray": false,
                    "type": {
                        "model": "DataLabEC2Instances"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "dataLabInstanceId"
                        ]
                    }
                },
                "organizationId": {
                    "name": "organizationId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "previousStatus": {
                    "name": "previousStatus",
                    "isArray": false,
                    "type": {
                        "enum": "DataLabEc2InstancesStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "newStatus": {
                    "name": "newStatus",
                    "isArray": false,
                    "type": {
                        "enum": "DataLabEc2InstancesStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "initiatedBy": {
                    "name": "initiatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "action": {
                    "name": "action",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "performedBy": {
                    "name": "performedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "additionalNotes": {
                    "name": "additionalNotes",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "created": {
                    "name": "created",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updated": {
                    "name": "updated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "dataLabEC2InstancesDataLabInstanceStatusChangesId": {
                    "name": "dataLabEC2InstancesDataLabInstanceStatusChangesId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "DataLabEC2InstanceStatusChanges",
            "attributes": [
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "created",
                            "updatedAt": "updated"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byDataLabInstance",
                        "queryField": "dataLabInstanceStatusChangesByDataLabInstance",
                        "fields": [
                            "dataLabInstanceId",
                            "created"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "M2MAdmin",
                                    "M2MAdmin-backend"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read"
                                ],
                                "identityClaim": "cognito:username"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "adminGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            }
                        ]
                    }
                }
            ]
        },
        "UploadBundle": {
            "name": "UploadBundle",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "studyPhaseId": {
                    "name": "studyPhaseId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "studyPhase": {
                    "name": "studyPhase",
                    "isArray": false,
                    "type": {
                        "model": "StudyPhase"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "studyPhaseId"
                        ]
                    }
                },
                "bundleLog": {
                    "name": "bundleLog",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "validationErrors": {
                    "name": "validationErrors",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "userEmail": {
                    "name": "userEmail",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "uploadBundleAnalysisSession": {
                    "name": "uploadBundleAnalysisSession",
                    "isArray": false,
                    "type": {
                        "model": "UploadBundleAnalysisSession"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": [
                            "id"
                        ],
                        "targetNames": [
                            "uploadBundleUploadBundleAnalysisSessionId"
                        ]
                    }
                },
                "uploadSamples": {
                    "name": "uploadSamples",
                    "isArray": true,
                    "type": {
                        "model": "UploadSample"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "uploadBundleUploadSamplesId"
                        ]
                    }
                },
                "uploadFiles": {
                    "name": "uploadFiles",
                    "isArray": true,
                    "type": {
                        "model": "UploadFile"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "uploadBundleUploadFilesId"
                        ]
                    }
                },
                "adminGroups": {
                    "name": "adminGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readGroups": {
                    "name": "readGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "completionTime": {
                    "name": "completionTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "created": {
                    "name": "created",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updated": {
                    "name": "updated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "studyPhaseUploadBundlesId": {
                    "name": "studyPhaseUploadBundlesId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "uploadBundleUploadBundleAnalysisSessionId": {
                    "name": "uploadBundleUploadBundleAnalysisSessionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "UploadBundles",
            "attributes": [
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "created",
                            "updatedAt": "updated"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byStudyPhase",
                        "queryField": "uploadBundlesByStudyPhase",
                        "fields": [
                            "studyPhaseId",
                            "created"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "M2MAdmin",
                                    "M2MAdmin-backend"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "identityClaim": "cognito:username"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "adminGroups",
                                "operations": [
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            }
                        ]
                    }
                }
            ]
        },
        "UploadBundleAnalysisSession": {
            "name": "UploadBundleAnalysisSession",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "uploadBundleId": {
                    "name": "uploadBundleId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "uploadBundle": {
                    "name": "uploadBundle",
                    "isArray": false,
                    "type": {
                        "model": "UploadBundle"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "uploadBundleId"
                        ]
                    }
                },
                "uploadSampleAnalysisSession": {
                    "name": "uploadSampleAnalysisSession",
                    "isArray": true,
                    "type": {
                        "model": "UploadSampleAnalysisSession"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "uploadBundleAnalysisSessionUploadSampleAnalysisSessionId"
                        ]
                    }
                },
                "metadataFileS3Path": {
                    "name": "metadataFileS3Path",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "step": {
                    "name": "step",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "listOfRawFileNames": {
                    "name": "listOfRawFileNames",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "analysisType": {
                    "name": "analysisType",
                    "isArray": false,
                    "type": {
                        "enum": "AnalysisType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "subtypeOfAnalysis": {
                    "name": "subtypeOfAnalysis",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "studyPhaseId": {
                    "name": "studyPhaseId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "metadataHelperLambdaResponseS3Path": {
                    "name": "metadataHelperLambdaResponseS3Path",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "barcodeToFilesToUploadS3Path": {
                    "name": "barcodeToFilesToUploadS3Path",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "adminGroups": {
                    "name": "adminGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readGroups": {
                    "name": "readGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "created": {
                    "name": "created",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updated": {
                    "name": "updated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "UploadBundleAnalysisSessions",
            "attributes": [
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "created",
                            "updatedAt": "updated"
                        }
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "M2MAdmin",
                                    "M2MAdmin-backend"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "identityClaim": "cognito:username"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "adminGroups",
                                "operations": [
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            }
                        ]
                    }
                }
            ]
        },
        "UploadSample": {
            "name": "UploadSample",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "uploadBundleId": {
                    "name": "uploadBundleId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "uploadBundle": {
                    "name": "uploadBundle",
                    "isArray": false,
                    "type": {
                        "model": "UploadBundle"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "uploadBundleId"
                        ]
                    }
                },
                "sampleId": {
                    "name": "sampleId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "barcode": {
                    "name": "barcode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "sampleLog": {
                    "name": "sampleLog",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "writeMetadata": {
                    "name": "writeMetadata",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "uploadFiles": {
                    "name": "uploadFiles",
                    "isArray": true,
                    "type": {
                        "model": "UploadFile"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "uploadSampleUploadFilesId"
                        ]
                    }
                },
                "adminGroups": {
                    "name": "adminGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readGroups": {
                    "name": "readGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "created": {
                    "name": "created",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updated": {
                    "name": "updated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "uploadBundleUploadSamplesId": {
                    "name": "uploadBundleUploadSamplesId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "UploadSamples",
            "attributes": [
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "created",
                            "updatedAt": "updated"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUploadBundle",
                        "queryField": "uploadSamplesByUploadBundle",
                        "fields": [
                            "uploadBundleId",
                            "created"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "M2MAdmin",
                                    "M2MAdmin-backend"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "identityClaim": "cognito:username"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "adminGroups",
                                "operations": [
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            }
                        ]
                    }
                }
            ]
        },
        "UploadSampleAnalysisSession": {
            "name": "UploadSampleAnalysisSession",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "uploadBundleAnalysisSessionId": {
                    "name": "uploadBundleAnalysisSessionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "uploadBundleAnalysisSession": {
                    "name": "uploadBundleAnalysisSession",
                    "isArray": false,
                    "type": {
                        "model": "UploadBundleAnalysisSession"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "uploadBundleAnalysisSessionId"
                        ]
                    }
                },
                "sampleId": {
                    "name": "sampleId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "rowFromMetaDataFileMappedToSample": {
                    "name": "rowFromMetaDataFileMappedToSample",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": true,
                    "attributes": []
                },
                "adminGroups": {
                    "name": "adminGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readGroups": {
                    "name": "readGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "created": {
                    "name": "created",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updated": {
                    "name": "updated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "uploadBundleAnalysisSessionUploadSampleAnalysisSessionId": {
                    "name": "uploadBundleAnalysisSessionUploadSampleAnalysisSessionId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "UploadSampleAnalysisSessions",
            "attributes": [
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "created",
                            "updatedAt": "updated"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUploadBundleAnalysisSession",
                        "queryField": "uploadSampleAnalysisSessionByUploadBundleAnalysisSession",
                        "fields": [
                            "uploadBundleAnalysisSessionId",
                            "created"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "M2MAdmin",
                                    "M2MAdmin-backend"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "identityClaim": "cognito:username"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "adminGroups",
                                "operations": [
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            }
                        ]
                    }
                }
            ]
        },
        "UploadFile": {
            "name": "UploadFile",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "uploadSampleId": {
                    "name": "uploadSampleId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "uploadSample": {
                    "name": "uploadSample",
                    "isArray": false,
                    "type": {
                        "model": "UploadSample"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "uploadSampleId"
                        ]
                    }
                },
                "uploadBundleId": {
                    "name": "uploadBundleId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "uploadBundle": {
                    "name": "uploadBundle",
                    "isArray": false,
                    "type": {
                        "model": "UploadBundle"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "uploadBundleId"
                        ]
                    }
                },
                "multipartUploadId": {
                    "name": "multipartUploadId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "UploadFileStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "toSkipUpload": {
                    "name": "toSkipUpload",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "fileName": {
                    "name": "fileName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "fileType": {
                    "name": "fileType",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "s3Path": {
                    "name": "s3Path",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "size": {
                    "name": "size",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "fileLog": {
                    "name": "fileLog",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "adminGroups": {
                    "name": "adminGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "readGroups": {
                    "name": "readGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "created": {
                    "name": "created",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "updated": {
                    "name": "updated",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "uploadBundleUploadFilesId": {
                    "name": "uploadBundleUploadFilesId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "uploadSampleUploadFilesId": {
                    "name": "uploadSampleUploadFilesId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "UploadFiles",
            "attributes": [
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "model",
                    "properties": {
                        "timestamps": {
                            "createdAt": "created",
                            "updatedAt": "updated"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUploadSample",
                        "queryField": "uploadFilesByUploadSample",
                        "fields": [
                            "uploadSampleId",
                            "created"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUploadBundle",
                        "queryField": "uploadFilesByUploadBundle",
                        "fields": [
                            "uploadBundleId",
                            "created"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "M2MAdmin",
                                    "M2MAdmin-backend"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "owner",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "identityClaim": "cognito:username"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "adminGroups",
                                "operations": [
                                    "read",
                                    "update",
                                    "delete"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            }
                        ]
                    }
                }
            ]
        },
        "OrganizationLaunchablePipelines": {
            "name": "OrganizationLaunchablePipelines",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "organizationId": {
                    "name": "organizationId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "launchablePipelinesId": {
                    "name": "launchablePipelinesId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "organization": {
                    "name": "organization",
                    "isArray": false,
                    "type": {
                        "model": "Organization"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "organizationId"
                        ]
                    }
                },
                "launchablePipelines": {
                    "name": "launchablePipelines",
                    "isArray": false,
                    "type": {
                        "model": "LaunchablePipelines"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "launchablePipelinesId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "OrganizationLaunchablePipelines",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byOrganization",
                        "fields": [
                            "organizationId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byLaunchablePipelines",
                        "fields": [
                            "launchablePipelinesId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "M2MAdmin",
                                    "M2MAdmin-backend"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "readGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "writeGroups",
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ],
                                "groupField": "groups"
                            }
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "M2MAdmin",
                                    "M2MAdmin-backend"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "allow": "private",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        }
    },
    "enums": {
        "Phase": {
            "name": "Phase",
            "values": [
                "PRE_CLINICAL",
                "PHASE_1",
                "PHASE_2",
                "PHASE_3"
            ]
        },
        "EntityType": {
            "name": "EntityType",
            "values": [
                "ORGANIZATION",
                "STUDY",
                "STUDY_PHASE"
            ]
        },
        "AnalysisType": {
            "name": "AnalysisType",
            "values": [
                "RNASEQ",
                "IMMUNOPEPTIDOMICS",
                "NANOSTRING"
            ]
        },
        "PipelineStatus": {
            "name": "PipelineStatus",
            "values": [
                "INITIATED",
                "SCHEDULED",
                "SCHEDULED_FAILED",
                "RUNNING",
                "COMPLETED",
                "CANCELLED",
                "CANCELLED_FAILED",
                "ABORTED",
                "ABORTED_FAILED",
                "FINISHED",
                "FAILED",
                "ERROR"
            ]
        },
        "QcStatus": {
            "name": "QcStatus",
            "values": [
                "AWAITING",
                "PASSED",
                "FAILED"
            ]
        },
        "SequenceType": {
            "name": "SequenceType",
            "values": [
                "PAIRED_END",
                "SINGLE_END"
            ]
        },
        "DataLabEc2InstancesStatus": {
            "name": "DataLabEc2InstancesStatus",
            "values": [
                "INITIATED",
                "STOPPED",
                "RUNNING",
                "TERMINATED",
                "SETTING_UP",
                "FAILED"
            ]
        },
        "DataLabEc2InstancesType": {
            "name": "DataLabEc2InstancesType",
            "values": [
                "RSTUDIO",
                "JUPYTER"
            ]
        },
        "DataLabEc2InstanceSessionStatus": {
            "name": "DataLabEc2InstanceSessionStatus",
            "values": [
                "LOADING",
                "LOADING_FAILED",
                "LOADED",
                "STAND_BY",
                "SAVING",
                "SAVING_FAILED",
                "SAVED"
            ]
        },
        "UploadFileStatus": {
            "name": "UploadFileStatus",
            "values": [
                "PENDING",
                "UPLOADING",
                "COMPLETED",
                "FAILED",
                "MISSING",
                "OMITTED"
            ]
        }
    },
    "nonModels": {
        "ModelUploadFileConnection": {
            "name": "ModelUploadFileConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "model": "UploadFile"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ModelBatchConnection": {
            "name": "ModelBatchConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "model": "Batch"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ModelPipelineConnection": {
            "name": "ModelPipelineConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "model": "Pipeline"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ModelStudyConnection": {
            "name": "ModelStudyConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "model": "Study"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ModelStudyPhaseConnection": {
            "name": "ModelStudyPhaseConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "model": "StudyPhase"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ModelAuxiliaryAnalysisConnection": {
            "name": "ModelAuxiliaryAnalysisConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "model": "AuxiliaryAnalysis"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ModelEntityQuestionsConnection": {
            "name": "ModelEntityQuestionsConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "model": "EntityQuestions"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ModelGroupConnection": {
            "name": "ModelGroupConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "model": "Group"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ModelLaunchablePipelinesConnection": {
            "name": "ModelLaunchablePipelinesConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "model": "LaunchablePipelines"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ModelMetaDataValidationSchemaTemplateConnection": {
            "name": "ModelMetaDataValidationSchemaTemplateConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "model": "MetaDataValidationSchemaTemplate"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ModelMetadataImmunopeptidomicsConnection": {
            "name": "ModelMetadataImmunopeptidomicsConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "model": "MetadataImmunopeptidomics"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ModelMetadataNanoStringConnection": {
            "name": "ModelMetadataNanoStringConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "model": "MetadataNanoString"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ModelMetadataRnaSeqConnection": {
            "name": "ModelMetadataRnaSeqConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "model": "MetadataRnaSeq"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ModelOrganizationConnection": {
            "name": "ModelOrganizationConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "model": "Organization"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ModelPatientConnection": {
            "name": "ModelPatientConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "model": "Patient"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ModelSampleConnection": {
            "name": "ModelSampleConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "model": "Sample"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ModelTimepointConnection": {
            "name": "ModelTimepointConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "model": "Timepoint"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ModelTreatmentConnection": {
            "name": "ModelTreatmentConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "model": "Treatment"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ModelUploadLogConnection": {
            "name": "ModelUploadLogConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "model": "UploadLog"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ModelUserConnection": {
            "name": "ModelUserConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "model": "User"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ModelVisualizationSessionConnection": {
            "name": "ModelVisualizationSessionConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "model": "VisualizationSession"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ModelVisualizationsConnection": {
            "name": "ModelVisualizationsConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "model": "Visualizations"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        }
    },
    "codegenVersion": "3.4.4",
    "version": "caf7817b8d5abbb92d63f4f6481fd3d7"
};