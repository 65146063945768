
import {
  validateInputName,
  getOrgNameForClass,
  formatISOStringCustom,
  formatListForView,
  listItems,
  formatDateToHumanReadableDate,
} from '@/utils';
import { defineComponent } from 'vue';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { API, graphqlOperation } from 'aws-amplify';
import * as customQueries from '@/graphql/customQueries';
import * as customMutations from '@/graphql/customMutations';

import * as customModels from '@/models/customModels';
// eslint-disable-next-line import/no-extraneous-dependencies, no-unused-vars
import { GraphQLResult } from '@aws-amplify/api';
import {
  MetaDataValidationSchemaTemplate,
  Study,
  StudyPhase,
  Sample,
} from '@/models';
import BatchSamplesAnnotationGrouping from '@/components/Batch/BatchSamplesAnnotationGrouping.vue';

export default defineComponent({
  name: 'Batch Dialog',
  components: { BatchSamplesAnnotationGrouping },
  emits: {
    newBatch: (newBatch: any) => {
      if (newBatch) {
        return true;
      }
      console.error('Invalid newBatch event payload!');
      return false;
    },
  },
  props: {
    batchForUpdate: { type: StudyPhase, required: false },
    studyPhases: { type: Object, required: false },
    studyId: { type: String, required: true },
    studyPhaseId: { type: String, required: false },
  },
  data() {
    return {
      filters: {
        domainSampleId: { value: '', matchMode: FilterMatchMode.CONTAINS },
        barcode: { value: '', matchMode: FilterMatchMode.CONTAINS },
      },
      controlSampleFilters: {
        domainSampleId: { value: '', matchMode: FilterMatchMode.CONTAINS },
        createdDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        barcode: { value: '', matchMode: FilterMatchMode.CONTAINS },
      },
      nonControlSampleFilters: {
        domainSampleId: { value: '', matchMode: FilterMatchMode.CONTAINS },
        createdDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        barcode: { value: '', matchMode: FilterMatchMode.CONTAINS },
        availableAnalysisTypes: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        availableAnalysisSubTypes: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      },
      annotationFilters: {
        domainSampleId: { value: '', matchMode: FilterMatchMode.CONTAINS },
        barcode: { value: '', matchMode: FilterMatchMode.CONTAINS },
      },
      possibleAnalysisTypes: ['Nanostring', 'RNASeq', 'Immunopeptidomics'],
      possibleAnalysisSubtypes: [] as string[],
      loading: false as boolean,
      showing: false as boolean,
      activeTabIndex: 0,
      activeOrgName: '',
      batchName: null as unknown as string | null,
      description: null as unknown as string | undefined,
      availableMetadataTemplates: [] as MetaDataValidationSchemaTemplate[],
      selectedMetadataTemplate:
        null as unknown as MetaDataValidationSchemaTemplate,
      parentStudy: null as unknown as Study,
      samples: [] as Sample[],
      controlSamples: [] as any,
      selectedSamples: [] as Sample[],
      columnTypeOptions: [
        { name: 'Categorical', value: 'string' },
        { name: 'Continuous', value: 'numeric' },
      ],
      columnType: { name: 'Categorical', value: 'string' },
      annotationName: '' as string,
      annotationColumns: [] as any,
      annotationColumn: '' as string,
      batchTemplate: {} as customModels.BatchTemplate,
      batchGroups: [] as any,
      newBatch: {} as any,
      batchView: false as boolean,
      controlSamplesShow: false as boolean,
      selectedControlSamples: [] as any,
      analysisTypes: {
        MetadataNanoStringList: 'nanaostring',
        MetadataRnaSeqList: 'rnaseq',
        metadataImmunopeptidomicsList: 'immunopeptidomics',
      } as unknown as {},
      mandatorySamplesMap: new Map<string, object>(),
      selectedSamplesMap: new Map<string, object>(),

    };
  },
  methods: {
    async main() {
      this.loading = true;
      try {
        if (!this.studyId) throw new Error('studyId not available');
        this.activeOrgName = this.$route.params.organizationName as string;
        [this.samples, this.controlSamples] = await this.makeSamples(this.studyId, this.studyPhaseId);
        console.log('this.samples :>> ', this.samples);
        console.log('this.controlSamples :>> ', this.controlSamples);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error(error);
      }
    },
    async makeSamples(studyId: string, studyPhaseId: string | undefined) {
      // Some text to force rebuild
      let samples: Sample[] = this.remapSamples((!studyPhaseId) ? await this.getSamplesFromStudy(studyId) : await this.getSamplesFromStudyPhase(studyPhaseId));
      samples = this.filterSamplesFromFailedUpload(samples);
      samples = this.extractAnalysisTypesInSamples(samples);
      const controlSamples: any[] = this.remapSamples(this.addGroupsAndBarcodesToSamplesAndMakeControlSamples(this.extractAnalysisTypesInSamples(samples)));
      this.possibleAnalysisSubtypes = this.extractPossibleSubtypes([samples, controlSamples].flat());
      return [samples, controlSamples];
    },
    extractPossibleSubtypes(samples: any[]): string[] {
      return Array.from(new Set(samples.map((sample: {availableAnalysisSubTypes: string[]}) => sample.availableAnalysisSubTypes).flat())).filter(Boolean);
    },
    filterSamplesFromFailedUpload(samples: Sample[]): Sample[] {
      return samples.filter((sample) => sample.created !== sample.updated);
    },
    async getSamplesFromStudy(studyId: string): Promise<Sample[]> {
      const studyPhasesFromStudy: StudyPhase[] = (!this.studyPhases) ? await listItems(customQueries.studyPhasesByStudySimple, { studyId }) : this.studyPhases as StudyPhase[];
      const samplesFromStudyPhasesPromises: Promise<Sample>[] = studyPhasesFromStudy.map((studyPhase) => listItems(customQueries.samplesByStudyPhaseForBatch, { studyPhaseId: studyPhase.id })) as unknown as Promise<Sample>[];
      const samplesFromStudyPhases = (await Promise.all(samplesFromStudyPhasesPromises) as unknown as Sample[][]).flat();
      return samplesFromStudyPhases;
    },
    async getSamplesFromStudyPhase(studyPhaseId: string): Promise<Sample[]> {
      return listItems(customQueries.samplesByStudyPhaseForBatch, { studyPhaseId });
    },
    addGroupsAndBarcodesToSamplesAndMakeControlSamples(samples: Sample[]): any[] {
      const controlSamples: any[] = [];
      samples.forEach((sample: any) => {
        // eslint-disable-next-line no-param-reassign
        sample.groups = [];
        // eslint-disable-next-line no-param-reassign
        sample.barcodes = [];
        const controlStatus = [] as any;
        for (const key of Object.keys(this.analysisTypes)) {
          sample[key].items.forEach((metadata: any) => {
            // eslint-disable-next-line no-param-reassign
            metadata.analysisType = (this.analysisTypes as any)[key];
            // eslint-disable-next-line no-param-reassign
            metadata.sampleId = sample.id;
            // eslint-disable-next-line no-param-reassign
            metadata.domainSampleId = sample.domainSampleId;
            controlStatus.push(metadata.isControl);
            if (metadata.isControl) {
              if (metadata.barcodesMappedToControl) {
                controlSamples.push(metadata);
              }
            } else {
              sample.barcodes.push(metadata.barcode);
            }
          });
        }
      });
      return controlSamples;
    },
    remapSamples(samples: Sample[]): Sample[] {
      return samples.map((sample) => {
        const s = sample;
        if (s.created) (s as any).createdDate = new Date(s.created as string);
        return s;
      });
    },
    onCellEditComplete(event: any) {
      const { data, newValue, field } = event;
      data[field] = newValue;
      console.log('edit complete');
    },
    addAnnotation(annotationName: string, columnType: any) {
      this.annotationColumns.push({
        name: annotationName,
        columnType: columnType.name,
        dType: columnType.value,
      });
      this.selectedSamples.forEach((sample: any) => {
        console.log(sample);
        // eslint-disable-next-line no-param-reassign
        sample[annotationName] = '';
      });
      this.annotationName = '';
      if (columnType.name === 'Categorical') {
        this.annotationFilters[annotationName as keyof typeof this.filters] = {
          value: '',
          matchMode: FilterMatchMode.CONTAINS,
        };
      } else {
        this.annotationFilters[annotationName as keyof typeof this.filters] = {
          value: '',
          matchMode: FilterMatchMode.GREATER_THAN,
        };
      }

      console.log(this.annotationColumns);
      console.log(this.filters);
    },
    removeAnnotation(annotationColumn: any) {
      console.log(annotationColumn);
      this.annotationColumns!.forEach((annotation: any, index: number) => {
        if (annotation.name === annotationColumn.name) { this.annotationColumns.splice(index, 1); }
      });
      console.log(this.annotationColumns);
      this.annotationColumn = '';
    },
    returnBatchGroups(batchGroups: any) {
      this.batchGroups = batchGroups;
    },
    async createBatch() {
      try {
        this.loading = true;
        if (!this.studyId) {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Internal Error!',
            life: 3000,
          });
          this.loading = false;
          throw new Error('studyId is bad in createBatch');
        }
        if (!this.batchName) {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Batch Name cannot be empty!',
            life: 3000,
          });
          this.loading = false;
          return;
        }
        if (Object.keys(this.selectedSamples).length === 0) {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Batch cannot be empty! Select samples.',
            life: 3000,
          });
          this.loading = false;
          return;
        }

        const batchAnnotations = this.reformatAnnotations(this.selectedSamples);
        let newBatchTemplate: customModels.BatchTemplate = {
          batchName: this.batchName,
          description: this.description,
          batchAnnotations: JSON.stringify(batchAnnotations),
          batchGroups: JSON.stringify(this.batchGroups),
          annotations: JSON.stringify(this.annotationColumns),
          controlSamples: JSON.stringify(this.selectedControlSamples),
          filters: JSON.stringify(this.annotationFilters),
        };
        newBatchTemplate = this.addParentIdToNewBatchTemplate(newBatchTemplate, this.studyId, this.studyPhaseId);
        newBatchTemplate = await this.addGroupsToNewBatchTemplate(newBatchTemplate, this.studyId, this.studyPhaseId);
        // eslint-disable-next-line no-unused-vars
        const createdBatch: GraphQLResult<any> = await API.graphql(
          graphqlOperation(customMutations.createBatch, { input: newBatchTemplate }),
        );
        this.newBatch = newBatchTemplate;
        this.$emit('newBatch', this.newBatch as any);
        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Batch created successfully!',
          life: 3000,
        });
        this.loading = false;
        this.hideDialog();
      } catch (error) {
        console.error(error);
        this.loading = false;
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to create Batch!',
          life: 3000,
        });
      }
      console.log('this.batchTemplate :>> ', this.batchTemplate);
    },
    addParentIdToNewBatchTemplate(newBatchTemplate: customModels.BatchTemplate, studyId: string, studyPhaseId: string | undefined): customModels.BatchTemplate {
      const modifiedNewBatchTemplate = newBatchTemplate;
      modifiedNewBatchTemplate.studyId = studyId;
      modifiedNewBatchTemplate.studyBatchesId = studyId;
      if (studyPhaseId) {
        modifiedNewBatchTemplate.studyPhaseId = studyPhaseId;
        modifiedNewBatchTemplate.studyPhaseBatchesId = studyPhaseId;
      }
      return modifiedNewBatchTemplate;
    },
    async addGroupsToNewBatchTemplate(newBatchTemplate: customModels.BatchTemplate, studyId: string, studyPhaseId: string | undefined): Promise<customModels.BatchTemplate> {
      const modifiedNewBatchTemplate = newBatchTemplate;
      if (!studyPhaseId) {
        const study: Study = (await API.graphql(graphqlOperation(customQueries.getStudyForOrgId, { id: studyId })) as { data: any}).data.getStudy as Study;
        console.log('study :>> ', study);
        if (!study) throw new Error('Study is bad in addGroupsToNewBatchTemplate');
        modifiedNewBatchTemplate.readGroups = [`S/${study.id}/User`, `ORG/${study.organization!.id}/Admin`];
        modifiedNewBatchTemplate.writeGroups = [`S/${study.id}/Admin`, `ORG/${study.organization!.id}/Admin`];
        modifiedNewBatchTemplate.adminGroups = [`S/${study.id}/Admin`, `ORG/${study.organization!.id}/Admin`];
      } else {
        const studyPhase: StudyPhase = (await API.graphql(graphqlOperation(customQueries.getStudyPhaseForStudyAndOrgId, { id: studyPhaseId })) as { data: any}).data.getStudyPhase as StudyPhase;
        if (!studyPhase) throw new Error('Study phase is bad in addGroupsToNewBatchTemplate');
        modifiedNewBatchTemplate.readGroups = [`SP/${studyPhase.id}/User`, `S/${studyPhase.study!.id}/User`, `ORG/${studyPhase.study!.organization!.id}/Admin`];
        modifiedNewBatchTemplate.writeGroups = [`SP/${studyPhase.id}/Admin`, `S/${studyPhase.study!.id}/Admin`, `ORG/${studyPhase.study!.organization!.id}/Admin`];
        modifiedNewBatchTemplate.adminGroups = [`SP/${studyPhase.id}/Admin`, `S/${studyPhase.study!.id}/Admin`, `ORG/${studyPhase.study!.organization!.id}/Admin`];
      }
      return modifiedNewBatchTemplate;
    },
    reformatAnnotations(selectedSamples: any) {
      console.log('this.analysisTypes :>> ', this.analysisTypes);
      selectedSamples.forEach((sample: any) => {
        console.log('sample :>> ', sample);
        // eslint-disable-next-line no-param-reassign
        sample.annotations = [];
        this.annotationColumns.forEach((annotation: any) => {
          sample.annotations.push({
            columnType: annotation.columnType, type: annotation.dType, name: annotation.name, value: sample[annotation.name],
          });
          // eslint-disable-next-line no-param-reassign
          delete sample[annotation.name];
        });
        for (const key of Object.keys(this.analysisTypes)) {
          try {
            // eslint-disable-next-line no-param-reassign
            sample[key] = sample[key].items;
          } catch (error) {
            console.error(error);
          }
        }
      });
      console.log('selectedSamples :>> ', selectedSamples);
      return selectedSamples;
    },
    controlSamplesHandle() {
      if (this.controlSamplesShow) {
        this.controlSamplesShow = false;
        this.selectedControlSamples = [];
        this.selectedSamples = [];
      } else {
        this.controlSamplesShow = true;
      }
    },
    extractAnalysisTypesInSamples(samples: Sample[]): Sample[] {
      // eslint-disable-next-line no-return-assign
      const remappedSamples = samples.map((sample: Sample) => {
        // eslint-disable-next-line no-param-reassign
        (sample as any).availableAnalysisTypes = this.extractAnalysisTypesInSingleSample(sample);
        // eslint-disable-next-line no-param-reassign
        (sample as any).availableAnalysisSubTypes = this.extractAnalysisSubtypeInSingleSample(sample);
        return sample;
      });
      return remappedSamples;
    },
    extractAnalysisTypesInSingleSample(sample: Sample): string[] {
      const availableAnalysisTypes: string[] = [];
      if (sample.MetadataNanoStringList && (sample.MetadataNanoStringList as any).items.length > 0) availableAnalysisTypes.push('Nanostring');
      if (sample.MetadataRnaSeqList && (sample.MetadataRnaSeqList as any).items.length > 0) availableAnalysisTypes.push('RNASeq');
      if (sample.metadataImmunopeptidomicsList && (sample.metadataImmunopeptidomicsList as any).items.length > 0) availableAnalysisTypes.push('Immunopeptidomics');
      return availableAnalysisTypes;
    },
    extractAnalysisSubtypeInSingleSample(sample: Sample): string[] {
      const availableAnalysisSubtypes: any[] = [];
      if (sample.MetadataNanoStringList && (sample.MetadataNanoStringList as any).items.length > 0) availableAnalysisSubtypes.push(...Array.from(new Set((sample.MetadataNanoStringList as any).items.map((analysis: any) => analysis.subtypeOfAnalysis))));
      if (sample.MetadataRnaSeqList && (sample.MetadataRnaSeqList as any).items.length > 0) availableAnalysisSubtypes.push(...Array.from(new Set((sample.MetadataRnaSeqList as any).items.map((analysis: any) => analysis.subtypeOfAnalysis))));
      if (sample.metadataImmunopeptidomicsList && (sample.metadataImmunopeptidomicsList as any).items.length > 0) availableAnalysisSubtypes.push(...Array.from(new Set((sample.metadataImmunopeptidomicsList as any).items.map((analysis: any) => analysis.subtypeOfAnalysis))));
      return availableAnalysisSubtypes.filter(Boolean);
    },
    validateBatchName() {
      return validateInputName(this.batchName! as string);
    },
    async getOrgNameForClassLocal() {
      this.activeOrgName = await getOrgNameForClass();
    },
    formatListForView(list: string[]) {
      return formatListForView(list);
    },
    hideDialog() {
      this.loading = false;
      this.activeTabIndex = 0;
      this.batchName = null;
      this.description = undefined;
      this.$store.dispatch('setShowingBatchDialog', false);
      this.samples = [];
      this.controlSamples = [];
      this.selectedSamples = [];
      this.selectedControlSamples = [];
    },
    formatISOStringCustom(date: string) {
      return formatISOStringCustom(date);
    },
    formatDateToHumanReadableDate(date: Date): string {
      return formatDateToHumanReadableDate(date);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingBatchDialog': async function () {
      this.showing = this.$store.state.showingBatchDialog;
      // if (this.showing) this.main();
      if (this.showing) this.main();
    },
    // eslint-disable-next-line func-names
    async selectedControlSamples() {
      this.mandatorySamplesMap.clear();
      this.selectedSamplesMap.clear();
      // this.selectedSamples = [];
      let selectedSamples: any[] = [];
      this.selectedControlSamples.forEach((control: any) => {
        console.log(control);
        this.samples.forEach((sample: any) => {
          const barcodeOverlap = sample.barcodes.filter((value: any) => control.barcodesMappedToControl.includes(value));
          if (barcodeOverlap.length > 0) {
            selectedSamples = [...selectedSamples, sample];
            this.mandatorySamplesMap.set(sample.id, sample);
            this.selectedSamplesMap.set(sample.id, sample);
          }
          if (control.sampleId === sample.id) {
            selectedSamples = [...selectedSamples, sample];
            this.mandatorySamplesMap.set(sample.id, sample);
            this.selectedSamplesMap.set(sample.id, sample);
          }
        });
      });
      this.selectedSamples = selectedSamples;
    },
    async selectedSamples() {
      const missingSamples: any[] = [];
      const mandatoryKeys = Array.from(this.mandatorySamplesMap.keys());

      this.selectedSamples.forEach((sample: any) => {
        if (this.mandatorySamplesMap.has(sample.id)) {
          // this.selectedSamplesMap.delete(sample.id);
          const index = mandatoryKeys.indexOf(sample.id, 0);
          if (index > -1) {
            mandatoryKeys.splice(index, 1);
          }
        }
      });
      if ((mandatoryKeys.length > 0)) { // } && (this.mandatorySamplesMap.size >= mandatoryKeys.length)) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Sample barcode is mapped to control Sample! Must be selected.',
          life: 6000,
        });
        for (const entry of this.selectedSamplesMap.entries()) {
          if (mandatoryKeys.indexOf(entry[0], 0) > -1) {
            missingSamples.push(entry[1]);
          }
          // missingSamples.push(entry[1]);
          // console.log(entry[0], entry[1]);
        }
        // this.selectedSamplesMap = this.mandatorySamplesMap;
        this.selectedSamples = this.selectedSamples.concat(missingSamples);
        console.log(this.selectedSamples.length);
      }
    },
  },
});
